const streetTypes = [
    'DISTACCO DI PIAZZA',
    'GRANDE RACCORDO ANULARE',
    'AREA PEDONALE',
    'BOCCA DE',
    'CALLE LARGA',
    'CASE SPARSE',
    'CASELLA POSTALE',
    'CENTRO ABITATO',
    'CUPA VICINALE',
    'FONDAMENTE NUOVE',
    'LISTA VECCHIA',
    'MERCATI GENERALI',
    'NUCLEO ABITATO',
    'PASSAGGIO PEDONALE',
    'PASSAGGIO PRIVATO',
    'PERCORSO CICLABILE',
    'PERCORSO CICLOPEDONALE',
    'PERCORSO PEDONALE',
    'PISTA CICLABILE',
    'RECINTO PRIVATO',
    "RIO TERA'",
    'RUGA GIUFFA',
    'RUGA VECCHIA',
    'SCALA PRIVATA',
    'SCALO MERCI',
    'STRADA ANTICA',
    'STRADA COMUNALE',
    'STRADA CONSORTILE',
    'STRADA NUOVA',
    'STRADA PANORAMICA',
    'STRADA PODERALE',
    'STRADA PRIVATA',
    'STRADA PROVINCIALE',
    'STRADA REGIONALE',
    'STRADA STATALE',
    'STRADA VECCHIA',
    'STRADA VICINALE',
    'STRADELLO PRIVATO',
    'TRAVERSA NUOVA',
    'TRAVERSA PRIVATA',
    'TRAVERSA VICINALE',
    'VIA ANTICA',
    'VIA BELVEDERE',
    'VIA CENTRALE',
    'VIA CIECA',
    'VIA COMUNALE',
    'VIA INTERNA',
    'VIA NAZIONALE',
    'VIA NUOVA',
    'VIA PANORAMICA',
    'VIA PRIVATA',
    'VIA PROVINCIALE',
    'VIA STATALE',
    'VIA STRETTA',
    'VIA VECCHIA',
    'VIA VICINALE',
    'VIALE BELVEDERE',
    'VIALE PRIVATO',
    'VICO CHIUSO',
    'VICO CIECO',
    'VICO PRIVATO',
    'VICO STORTO',
    'VICOLETTO CIECO',
    'VICOLO CHIUSO',
    'VICOLO CIECO',
    'VICOLO DIETRO',
    'VICOLO LARGO',
    'VICOLO LUNGO',
    'VICOLO NUOVO',
    'VICOLO PRIVATO',
    'VICOLO ROTTO',
    'VICOLO STORTO',
    'VICOLO VECCHIO',
    'ZONA ARTIGIANALE',
    'ZONA INDUSTRIALE',
    'ALPE',
    'ACCESSO',
    'ALTO',
    'ALVEO',
    'ALZAIA',
    'ANDRONA',
    'ANGIPORTO',
    'ANGOLO',
    'ARCHIVOLTO',
    'ARCO',
    'AUTOSTRADA',
    'BAIA',
    'BALUARDO',
    'BANCHINA',
    'BARBARIA',
    'BASTIONI',
    'BELVEDERE',
    'BIRI',
    'BIVIO',
    'BORGATA',
    'BORGHETTO',
    'BORGO',
    'BORGOLOCO',
    'BRIGATA',
    "CA'",
    'CAL',
    'CALA',
    'CALATA',
    'CALLE',
    'CALLESELLE',
    'CALLESELLO',
    'CALTO',
    'CAMPAZZO',
    'CAMPIELLO',
    'CAMPO',
    'CANALE',
    'CANTO',
    'CANTON',
    'CANTONE',
    'CASA',
    'CASALE',
    'CASCINA',
    'CASE',
    'CASELLA',
    'CAVALCAVIA',
    'CAVONCELLO',
    'CAVONE',
    'CHIASSETTO',
    'CHIASSINO',
    'CHIASSO',
    'CHIASSUOLO',
    'CIRCONDARIALE',
    'CIRCONVALLAZIONE',
    'COLLEGAMENTO',
    'COMPLANARE',
    "CONTRA'",
    'CONTRADA',
    'CORSETTO',
    'CORSIA',
    'CORSO',
    'CORTE',
    'CORTICELLA',
    'CORTILE',
    'CORTINA',
    'COSTA',
    'COSTARELLA',
    'CROCEVIA',
    'CROSERA',
    'CUPA',
    'DARSENA',
    'DISCESA',
    'EMICICLO',
    'ERTA',
    'ESEDRA',
    'FONDACO',
    'FONDAMENTA',
    'FONDO',
    'FORNICE',
    'FORO',
    'FOSSA',
    'FOSSO',
    'FRAZIONE',
    'GALLERIA',
    'GIARDINI',
    'GIARDINO',
    'GIRO',
    'GRADELLE',
    'GRADINATA',
    'GRADINI',
    'GRADONI',
    'INTERRATO',
    'ISOLA',
    'LARGHETTO',
    'LARGO',
    'LEA',
    "LEVA'",
    'LISTA',
    'LITORANEA',
    "LOCALITA'",
    'LOGGE',
    'LOGGETTA',
    'LUNGADDA',
    'LUNGADIGE',
    'LUNGAGNO',
    'LUNGARGINE',
    'LUNGARNO',
    'LUNGO',
    'LUNGOBISAGNO',
    'LUNGOCASTELLANO',
    'LUNGOCELANO',
    'LUNGOCRATI',
    'LUNGODORA',
    'LUNGOFERMULLA',
    'LUNGOFIUME',
    'LUNGOFOGLIA',
    'LUNGOFRIGIDO',
    'LUNGOGESSO',
    'LUNGOGLIO',
    'LUNGOLAGO',
    'LUNGOLARIO',
    'LUNGOLONA',
    'LUNGOMALLERO',
    'LUNGOMALONE',
    'LUNGOMARE',
    'LUNGOMAZARO',
    'LUNGOMELLA',
    'LUNGOMERA',
    'LUNGOMINCIO',
    'LUNGOMONTE',
    'LUNGONERA',
    'LUNGOPARCO',
    'LUNGOPESA',
    'LUNGOPO',
    'LUNGORIO',
    'LUNGOSABATO',
    'LUNGOSILE',
    'LUNGOSTURA',
    'LUNGOTANARO',
    'LUNGOTARTARO',
    'LUNGOTEVERE',
    'LUNGOTORRENTE',
    'LUNGOTRONTO',
    'MARITTIMA',
    'MARZARIA',
    'MASSERIA',
    'MERCATO',
    'MERCERIE',
    'MOLO',
    'MONTE',
    'MURA',
    'MURAZZI',
    'NUCLEO',
    'PALUDO',
    'PARCHEGGIO',
    'PARCO',
    'PASSAGGIO',
    'PASSANTE',
    'PASSEGGIATA',
    'PASSEGGIO',
    'PASSO',
    'PENDICE',
    'PENDINO',
    'PENDIO',
    'PENNINATA',
    'PESCARIA',
    'PIAGGIA',
    'PIANO',
    'PIAZZA',
    'PIAZZALE',
    'PIAZZALETTO',
    'PIAZZATA',
    'PIAZZETTA',
    'PIAZZOLA',
    'PIAZZOLO',
    'PISCINA',
    'PODERE',
    'PONTE',
    'PONTILE',
    'PORTA',
    'PORTICATO',
    'PORTICHETTI',
    'PORTICI',
    'PORTICO',
    'PORTO',
    'POSTIERLA',
    'PRATO',
    'PRESSO',
    'PROLUNGAMENTO',
    'QUADRATO',
    'QUADRIVIO',
    'QUARTIERE',
    'RACCORDO',
    'RAMO',
    'RAMPA',
    'RAMPANTE',
    'RAMPARI',
    'RAMPE',
    'RECINTO',
    'REGIONE',
    'RESIDENZA',
    'RIALTO',
    'RIGASTE',
    'RIO',
    'RIONE',
    'RIPA',
    'RIPARTO',
    'RIVA',
    'RIVALE',
    'RIVIERA',
    'RONCO',
    'ROTABILE',
    'ROTATORIA',
    'ROTONDA',
    'RUA',
    'RUE',
    'RUGA',
    'RUGHETTA',
    'SACCA',
    'SAGRATO',
    'SALA',
    'SALITA',
    'SALIZADA',
    'SALIZZADE',
    'SBARCATOIO',
    'SCALA',
    'SCALE',
    'SCALETTA',
    'SCALETTE',
    'SCALI',
    'SCALINATA',
    'SCALONE',
    'SCESA',
    'SDRUCCIOLO',
    'SECCO',
    'SELCIATO',
    'SENTIERO',
    'SESTIERE',
    'SILOS',
    'SITO',
    'SLARGO',
    'SOPPORTICO',
    'SOPRAELEVATA',
    'SOTTOPASSAGGIO',
    'SOTTOPASSO',
    'SOTTOPORTICO',
    'SOTTOVIA',
    'SOVRAPASSO',
    'SPADARIA',
    'SPALTO',
    'SPIANATA',
    'SPIAZZO',
    'STAZIONE',
    'STRADA',
    'STRADALE',
    'STRADELLA',
    'STRADELLO',
    'STRADETTA',
    'STRADINA',
    'STRADONE',
    'STRETTA',
    'STRETTO',
    'STRETTOIA',
    'STRETTOLA',
    'SUBBORGO',
    'SUPPORTICO',
    'SVINCOLO',
    'TANGENZIALE',
    'TETTI',
    'TONDO',
    'TRAFORO',
    'TRAGHETTO',
    'TRATTO',
    'TRATTURO',
    'TRAVERSA',
    'TRAZZERA',
    'TRESANDA',
    'VAGLIO',
    'VALLONE',
    'VANELLA',
    'VARCO',
    'VARIE',
    'VIA',
    'VIADOTTO',
    'VIALE',
    'VIALETTO',
    'VICO',
    'VICOLETTO',
    'VICOLO',
    'VIETTA',
    'VILLA',
    'VILLAGGIO',
    'VIOTTOLO',
    'VIUZZA',
    'VIUZZO',
    "VO'",
    'VOCABOLO',
    'VOLTA',
    'VOLTE',
    'VOLTO',
    'VOLTONE',
    'ZONA',
]

export default streetTypes