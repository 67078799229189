import { StatiRichiestaReclamo } from '../../api/getStatiRichiestaReclamoAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getStatiRichiestaReclamo =
    (idStato = 201): AppAction<StatiRichiestaReclamo> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const statiReclamo = await container.api.getStatiRichiestaReclamoAPI(idStato)

            return statiReclamo
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.errorMessage,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getStatiRichiestaReclamo
