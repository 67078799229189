import { Box, BoxExtendedProps, Text } from 'grommet'
import { TipiEventi } from '../models/Enums'
import { InvoiceModel } from '../models/models'

const EventBox = ({
    title,
    description,
    day,
    payload,
    bookmarkColor = 'brand',
    type,
    icon,
    ...rest
}: EventBoxProps) => {
    return (
        <Box
            elevation="small"
            border={{
                side: 'right',
                size: 'large',
                color: bookmarkColor,
            }}
            round='small'
            {...rest}
        >
                <Box
                    pad='small'
                    gap="small"
                    direction="column"
                >
                    <Box direction="row" align='center' gap="medium">
                        <Box direction='row' fill='horizontal' justify='between'>

                            <Text size="small">
                                {title}
                            </Text>
                            { type === TipiEventi.Bolletta && icon }
                        </Box>
                    </Box>
                    <Box direction='column'>
                        {description.map((it, idx) =>
                            <Text size="small" weight="bold" key={idx}>
                                {it}
                            </Text>
                        )}
                    </Box>
                </Box>
        </Box>
    )
}

type EventBoxProps = {
    title: string
    description: string[]
    day: string
    payload?: InvoiceModel
    bookmarkColor?: string
    type?: number
    icon?: JSX.Element
} & BoxExtendedProps

export default EventBox
