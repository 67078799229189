import { Box, Spinner } from 'grommet'
import { useEffect } from 'react'
import useI18n from '../hooks/useI18n'
import useAppQuery from '../hooks/useAppQuery'
import { useAppSelector } from '../services/storeProvider'
import { useNavigate } from 'react-router'
import SupplySelectorPage from '../components/SupplySelectorPage'
import loadSupplies from '../services/store/actions/loadSupplies'

const Forniture = () => {
    const strings = useI18n()
    const navigate = useNavigate()
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const user = useAppSelector((state) => state.user.clientInfo)

    useEffect(() => {
        querySupplies(user.codiceUtente)
    }, [querySupplies, user.codiceUtente])

    return (
        <Box flex direction={'row'} align="start">
                <Box flex>
                    <Box flex direction="row" wrap>
                        <Box
                            margin={{ horizontal: 'small' }}
                            //width="70%"
                            flex={{ grow: 1 }}
                            width={{ min: 'medium' }}
                            gap="small"
                            pad={'small'}
                        >
                            <SupplySelectorPage 
                                isLoading={isLoadingSupplies}
                                title={strings.leMieForniture}
                                fclose={() => {
                                    navigate('/home')
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
        </Box>
    )
}

export default Forniture
