import { Box, BoxExtendedProps, Heading, Spinner } from 'grommet'
import { useNavigate } from 'react-router'
import useAssets from '../hooks/useAssets'
import useI18n from '../hooks/useI18n'
import { SET_SUPPLY } from '../services/store/actions/types'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import SupplyBox from './SupplyBox'
import { AllRoutes, RoutesEnum } from '../Navigation'
import { ComponentWithLoader } from '../models/models'

const SupplySelectorPage = ({
    title,
    fclose,
    isLoading,
    ...rest
}: SupplySelectorPageProps) => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const assets = useAssets()
    const supply = useAppSelector((state) => state.supply)

    return (
        <Box flex>
            <Box flex width={{ min: 'small' }} gap="small" pad={'small'}>
                <Box direction="row" justify="between" align="center">
                    <Box direction="row" gap="small" align="center">
                        <Heading level={3}>{title}</Heading>
                        {isLoading && <Spinner />}
                    </Box>
                </Box>
                <Box flex direction="row" wrap>
                    <Box
                        flex
                        width={{ min: 'medium' }}
                        margin={{ right: 'small' }}
                    >
                        {supply.supplies
                            .slice(0, supply.lenSupplies)
                            .map((it, idx) => (
                                <SupplyBox
                                    key={idx}
                                    title={`${
                                        it.tipoServizio === 'E'
                                            ? strings.pod
                                            : strings.pdr
                                    } ${it.pod}`}
                                    width={{ min: 'medium' }}
                                    margin={{ bottom: 'small' }}
                                    description={`${it.tipoVia} ${it.via} ${it.numeroCivico}, ${it.comune} ${it.provincia}`}
                                    bookmarkColor={
                                        supply.addresses.find(
                                            (element) =>
                                                element.puntiGas.indexOf(
                                                    it.puntoGas,
                                                ) > -1,
                                        )?.color
                                    }
                                    state={it?.idStatoFornitura}
                                    icon={
                                        it?.tipoServizio === 'E' ? (
                                            <assets.EnergyIcon />
                                        ) : it?.tipoServizio === 'GPL' ? (
                                            <assets.GplIcon />
                                        ) : (
                                            <assets.GasIcon />
                                        )
                                    }
                                    onClick={() => {
                                        dispatch({
                                            type: SET_SUPPLY,
                                            payload: it,
                                        })
                                        navigate(
                                            `/${AllRoutes[RoutesEnum.SUPPLY]}`,
                                        )
                                    }}
                                />
                            ))}
                    </Box>
                    <Box
                        flex
                        width={{ min: 'medium' }}
                        margin={{ right: 'small' }}
                    >
                        {supply.supplies
                            .slice(supply.lenSupplies, supply.supplies.length)
                            .map((it, idx) => (
                                <SupplyBox
                                    key={idx}
                                    title={`${
                                        it.tipoServizio === 'E'
                                            ? strings.pod
                                            : strings.pdr
                                    } ${it.pod}`}
                                    width={{ min: 'medium' }}
                                    margin={{ bottom: 'small' }}
                                    description={`${it.tipoVia} ${it.via} ${it.numeroCivico}, ${it.comune} ${it.provincia}`}
                                    bookmarkColor={
                                        supply.addresses.find(
                                            (element) =>
                                                element.puntiGas.indexOf(
                                                    it.puntoGas,
                                                ) > -1,
                                        )?.color
                                    }
                                    state={it?.idStatoFornitura}
                                    icon={
                                        it?.tipoServizio === 'E' ? (
                                            <assets.EnergyIcon />
                                        ) : it?.tipoServizio === 'GPL' ? (
                                            <assets.GplIcon />
                                        ) : (
                                            <assets.GasIcon />
                                        )
                                    }
                                    onClick={() => {
                                        dispatch({
                                            type: SET_SUPPLY,
                                            payload: it,
                                        })
                                        navigate(
                                            `/${AllRoutes[RoutesEnum.SUPPLY]}`,
                                        )
                                    }}
                                />
                            ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

type SupplySelectorPageProps = {
    title: string
    fclose: () => void
    // supplies: Array<SupplyModel>
} & BoxExtendedProps &
    ComponentWithLoader

export default SupplySelectorPage
