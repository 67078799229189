import { Plan } from 'grommet-icons'
import { useNavigate } from 'react-router'
import useI18n from '../../hooks/useI18n'
import useAssets from '../../hooks/useAssets'
import FooterBar from './FooterBar'
import useCurrentPath from '../../hooks/useCurrentPath'
import React from 'react'
import { AllRoutes, RoutesEnum } from '../../Navigation'
import useClientInfo from '../../hooks/useClientInfo'
import useIsOperator from '../../hooks/useIsOperator'

const FooterNavbar = () => {
    const strings = useI18n()
    const navigate = useNavigate()
    const cAssets = useAssets()
    const currentPath = useCurrentPath()
    const isOperator = useIsOperator()
    const [selectedClient] = useClientInfo()

    const menuItems = [
        {
            label: strings.home,
            id: 'home',
            icon: <cAssets.HomeIcon />,
        },
        {
            label: strings.forniture,
            id: 'supplies',
            children: [AllRoutes[RoutesEnum.SUPPLY]],
            icon: <cAssets.FornitureIcon />,
        },
        {
            label: strings.bollette,
            id: 'bills',
            children: [AllRoutes[RoutesEnum.BILL]],
            icon: <cAssets.BolletteIcon />,
        },
        {
            label: strings.richieste,
            id: 'requests',
            children: [
                AllRoutes[RoutesEnum.CAMBIO_RECAPITO],
                AllRoutes[RoutesEnum.RID_PAYMENT],
                AllRoutes[RoutesEnum.BILL_BY_EMAIL],
            ],
            icon: <cAssets.RequestsIcon />,
        },
        {
            label: strings.calendario,
            id: 'planner',
            icon: <Plan />,
        },
    ]

    const menuItemsOperator = [
        {
            label: strings.home,
            id: 'home',
            icon: <cAssets.HomeIcon />,
        },
        {
            label: strings.elencoRichieste,
            id: 'requestList',
            icon: <cAssets.RequestsIcon />,
        },
    ]
    const menuMapping = (menu: Array<(typeof menuItems)[0]>) => {
        return menu.map((it) => ({
            label: it.label,
            id: it.id,
            icon: React.cloneElement(it.icon, {
                color: currentPath === it.id ? 'brand' : 'menuitems-colorless',
                size: '20px',
            }),
            active:
                currentPath === it.id ||
                Boolean(it.children?.includes(currentPath || '')),
        }))
    }

    return (
        <FooterBar
            height="60px"
            onClick={(id) => {
                navigate(`/${id}`)
            }}
            menu={isOperator && !selectedClient ? menuMapping(menuItemsOperator) : menuMapping(menuItems)}
        />
    )
}

export default FooterNavbar
