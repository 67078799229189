import { Box, BoxExtendedProps } from 'grommet'
import { useNavigate } from 'react-router'
import useI18n from '../hooks/useI18n'
import { useAppSelector } from '../services/storeProvider'
import { getNavLinkTipoRichiesta } from '../utilities'
import RequestBox from './RequestBox'

const RequestSelectorPage = ({
    title,
    icon,
    fclose,
    requests,
    address,
    addressColor,
    pod,
    requestsContainerTitle,
    ...rest
}: RequestSelectorPageProps) => {
    const strings = useI18n()
    const navigate = useNavigate()
    const loggedUser = useAppSelector((state) => state.user.loggedUser)

    return (
        <Box gap="small">
            <RequestBox
                title={strings.faiUnaRichiesta}
                icon={icon}
                onBackPress={fclose}
                address={address}
                addressColor={addressColor}
                pod={pod}
                requestsContainerTitle={requestsContainerTitle}
                requests={requests}
                requestButtonLabel={strings.richiedi}
                onRequestPress={(id) => {
                    navigate(
                        String(getNavLinkTipoRichiesta(+id, loggedUser.roles)),
                    )
                }}
                overflow="auto"
            />
        </Box>
    )
}

type RequestSelectorPageProps = {
    title: string
    icon: JSX.Element
    fclose: () => void
    requests: Array<{
        icon: JSX.Element
        activeIcon: JSX.Element
        title: string
        id: string
    }>
    address: string
    addressColor: string
    pod: string
    requestsContainerTitle: string
} & BoxExtendedProps

export default RequestSelectorPage
function getState() {
    throw new Error('Function not implemented.')
}
