import { Box, BoxExtendedProps, Grid, Text } from 'grommet'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import useTheme from '../hooks/useTheme'
import { getDescStatoRichiesta, getIconTipoRichiesta } from '../utilities'
import BCard from './BCard'
import StateRequestButton from './StateRequestButton'

const SupplyRequestBox = ({
    name,
    title,
    utente,
    isFornitura,
    description,
    icon,
    idStatoRichiesta,
    dataRichiesta,
    idTipoRichiesta,
    ...rest
}: SupplyRequestBoxProps) => {
    const strings = useI18n()
    const size = useSize()

    const getFornitureOrUserInfo = (isFornitura: boolean) => {
        return isFornitura ? (
            <Box direction="row" align="center">
                {size !== 'small' && icon}
                <Box direction="column">
                    <Text size="small" weight="bold">
                        {title}
                    </Text>
                    <Text color="grey" size="xsmall">
                        {description}
                    </Text>
                </Box>
            </Box>
        ) : (
            <Box
                direction="row"
                align="center"
                gap="small"
                width={{ min: '210px' }}>
                <Text size="small" weight="bold">
                    {utente}
                </Text>
            </Box>
        )
    }

    const getMobileContent = () => (
        <Box align="center" direction="row" justify="between">
            <Box direction="row" gap="small" align="start" fill>
                {icon}
                <Box direction="column" justify="around" fill>
                    <Box direction="row" align="center" gap="medium">
                        <Text size="medium" weight="bold">
                            {name}
                        </Text>
                    </Box>
                    {getFornitureOrUserInfo(isFornitura)}
                </Box>
            </Box>
            <Box
                direction="column"
                align="center"
                width={{ min: 'fit-content' }}>
                <Text size="small" weight="bold">
                    {dataRichiesta}
                </Text>
                <StateRequestButton
                    align="center"
                    title={getDescStatoRichiesta(idStatoRichiesta, strings)}
                    state={idStatoRichiesta}
                />
            </Box>
        </Box>
    )

    const getFullContent = () => (
        <Grid
            columns={[
                ['small', 'flex'],
                ['small', 'flex'],
                ['xsmall', 'flex'],
                ['xsmall', 'auto'],
            ]}
            gap="small"
            fill
            align="center">
            <Box direction="row" gap="small" align="center">
                <Box direction="row" gap="small">
                    {getIconTipoRichiesta(idTipoRichiesta, 'brand')}
                    <Text size="medium" wordBreak="break-word" weight="bold">
                        {name}
                    </Text>
                </Box>
            </Box>
            {getFornitureOrUserInfo(isFornitura)}
            <Box direction="column" justify="between">
                <Text size="small">{strings.dataRichiesta}</Text>
                <Text size="small" weight="bold">
                    {dataRichiesta}
                </Text>
            </Box>
            <StateRequestButton
                align="center"
                title={getDescStatoRichiesta(idStatoRichiesta, strings)}
                state={idStatoRichiesta}
            />
        </Grid>
    )

    return (
        <Box {...rest}>
            <BCard
                pad={{ vertical: 'small', horizontal: 'medium' }}
                justify="center"
                elevation={rest.elevation}>
                {size === 'small' ? getMobileContent() : getFullContent()}
            </BCard>
        </Box>
    )
}

type SupplyRequestBoxProps = {
    name: string
    title: string
    utente: string
    isFornitura: boolean
    description: string
    icon: JSX.Element
    idStatoRichiesta: number
    idTipoRichiesta: number
    dataRichiesta: string
} & BoxExtendedProps

export default SupplyRequestBox
