import { Box, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useI18n from '../hooks/useI18n'
import { validateFatturaViaMailType } from '../services/api/validateFatturaViaMailAPI'
import { keepAlive } from '../services/store/actions'
import validateFatturaViaMail from '../services/store/actions/validateFatturaViaMail'
import { useAppDispatch } from '../services/storeProvider'


const MailConfirm = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const [getMessage,setMessage] = useState('')
    const IDRichiesta = searchParams.get('IDRichiesta')
    const CodiceUtente = searchParams.get('CodiceUtente')
    const Email = searchParams.get('Email')


    const loadData = async (submit:validateFatturaViaMailType) => {
        try {

            await dispatch(keepAlive())

            const ret = await dispatch(validateFatturaViaMail(submit))

            setMessage(ret.message)

        } catch (e) {
            setMessage(strings.APP_MSG_EMAIL_VALIDATION_ERROR)
        }
    }

    useEffect(() => {
        const submit = {
            IDRichiesta: +IDRichiesta!,
            CodiceUtente: CodiceUtente!,
            Email: Email!
        }
        loadData(submit)
    }, [])

    return (
        <Box >
            {(!getMessage || getMessage === "")?(
                <Box background={'background-back'} >
                    <Box justify="center" align="center" width="100vw" height="100vh">
                        <Spinner />
                    </Box>
                </Box>
            ):(
            <Box
                //fill={'vertical'}
                width="100vw"
                height="100vh"
                align="center"
                justify="center">
                <Box
                    animation={{ type: 'fadeIn' }}
                    fill
                    align="center"
                    justify="center">
                    <Box
                        background={{ opacity: 0.8, color: 'background-front' }}
                        elevation="small"
                        round="xsmall"
                        pad="large">
                        <Box margin={{ bottom: 'small' }} justify="center" align="center">
                            <Text size={'xlarge'} >
                                {getMessage.substring(3)==="APP_MSG_EMAIL_VALIDATION_SUCCESS"||
                                getMessage==="APP_MSG_EMAIL_VALIDATION_SUCCESS"||
                                getMessage.includes("SUCCESS")?
                                strings.APP_MSG_EMAIL_VALIDATION_SUCCESS:strings.APP_MSG_EMAIL_VALIDATION_ERROR}
                            </Text>
                            {getMessage.substring(3)==="APP_MSG_EMAIL_VALIDATION_SUCCESS"||
                                getMessage==="APP_MSG_EMAIL_VALIDATION_SUCCESS"||
                                getMessage.includes("SUCCESS")?
                                (<Box margin={{ top: 'small' }}>
                                    <Text size={'xlarge'} weight={'bolder'}>
                                        {Email}
                                    </Text>
                                </Box>):<></>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            )}
        </Box>
    )
}

export default MailConfirm
