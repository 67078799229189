import { AxiosError } from 'axios'
import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type RichiediTokenType = {
    CodiceUtente: string,
    CodiceFiscale: string
}

const richiediTokenRegistrazioneAPI =
    async (reg: RichiediTokenType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        
        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Utente/RichiediTokenRegistrazione',{
                    CodiceUtente:reg.CodiceUtente,
                    CodiceFiscale:reg.CodiceFiscale
            })

            if (res.status == HttpStates.OK) {

                return true
            }
            else {

                return false
            }          
        } catch (e) {
            
            return Promise.reject(e)
        }
    }

export default richiediTokenRegistrazioneAPI
