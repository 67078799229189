import { SET_NOTIFICATION } from './types'


export type NotificationParams = {
    type: 'error' | 'warn' | 'info'
    show: boolean
    message: string
}

const setNotification = (args: NotificationParams) => {
    return {
        type: SET_NOTIFICATION,
        payload: {
            ...args,
        },
    }
}

export default setNotification
