import { AutoLoginModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const autologinCryptedToken =
    (userCode: string): AppAction<AutoLoginModel> =>
    async (dispatch, _, container) => {

        try {
            const data = await container.api.autologinCryptedTokenAPI(
                userCode,
            )

            if (!data) {
                throw Error()
            }

            return data
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default autologinCryptedToken
