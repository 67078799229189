import { Box, BoxExtendedProps, Text, Tip } from 'grommet'
import { CircleInformation, DownloadOption, UploadOption } from 'grommet-icons'
import get from 'lodash.get'
import useAssets from '../hooks/useAssets'
import useTheme from '../hooks/useTheme'
import { StatiFornitura } from '../models/Enums'
import BCard from './BCard'
import { getDescStatoFornitura } from '../utilities'
import useI18n from '../hooks/useI18n'

const SupplyBox = ({
    title,
    description,
    icon,
    bookmarkColor = 'brand',
    direct,
    state,
    disabled,
    disabledMessage,
    ...rest
}: SupplyBoxProps) => {
    const { global } = useTheme()
    const strings = useI18n()
    const { BookmarkIcon } = useAssets()

    let stateBackground : string
    switch (state) {
        case StatiFornitura.Attiva:
            stateBackground = 'ok'
            break
        case StatiFornitura.InCessazione:
        case StatiFornitura.Sospesa:
            stateBackground = 'warn'
            break
        default:
            stateBackground = 'ko'
            break
    }


    return (
        <Box
            {...rest}
            onClick={disabled ? undefined : () => rest.onClick?.()}
            style={{...(disabled && {cursor: 'not-allowed'})}}
        >
            <Box style={{ position: 'relative' }}>
                {/* <BookmarkIcon
                    color={get(global?.colors, bookmarkColor)?.toString()}
                    style={{ position: 'absolute', top: 0, right: 40 }}
                /> */}

                {state &&
                    <Tip
                        content={
                            <Text weight="bold">{getDescStatoFornitura(Number(state), strings)}</Text>
                        }
                        dropProps={{ align: { left: 'right' } }}
                    >
                        <Box
                            width='20px'
                            height='20px'
                            round={{ corner: 'bottom', size: 'small'}}
                            background={stateBackground}
                            style={{ position: 'absolute', top: 0, right: 40 }}
                        />
                    </Tip>
                }

                <Box
                    style={{ position: 'absolute', top: 35, right: 38 }}

                >
                    {direct === undefined
                        ? undefined
                        : direct
                            ? <UploadOption color='lightgrey' />
                            : <DownloadOption color='lightgrey' />
                    }
                    {disabled && disabledMessage &&
                        <Tip
                            content={
                                <Box pad="small" gap="small" width={{ max: 'small' }}>
                                    <Text weight="bold">{disabledMessage}</Text>

                                </Box>
                            }
                            dropProps={{ align: { left: 'right' } }}
                        >
                            <CircleInformation/>
                        </Tip>
                    }
                </Box>

            </Box>
            <BCard pad='small' elevation={rest.elevation} bgcolor={disabled ? 'lightergrey' : undefined}>
                <Box align="center" gap="medium" direction="row">
                    {icon}
                    <Box direction="column">
                        <Box direction="row" align='center' gap="medium">
                            <Text size="small" weight="bold">
                                {title}
                            </Text>
                        </Box>
                        <Text color="textGrey" size="xsmall">
                            {description}
                        </Text>
                    </Box>
                </Box>
            </BCard>
        </Box>
    )

}

type SupplyBoxProps = {
    title: string
    description: string
    icon: JSX.Element
    bookmarkColor?: string
    direct?: boolean
    state?: string | number
    disabled?: boolean
    disabledMessage?: string
} & BoxExtendedProps

export default SupplyBox
