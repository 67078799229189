import { IOCServices } from '..'

const loadSuppliesRecontractedAPI = async (
    codiceUtente: string,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    try {
        const res = await rest.get(
            `/Fornitura/GetFornitureCambioMercato/${codiceUtente}`,
        )

        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadSuppliesRecontractedAPI
