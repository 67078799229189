import { DettaglioReclamoOperatore } from '../../api/loadDettaglioReclamoOperatoreAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadDettaglioReclamoOperatore =
    ({
        idReclamo,
    }: {
        idReclamo: number
    }): AppAction<DettaglioReclamoOperatore> =>
    async (dispatch, _, container) => {
        try {
            const claims = await container.api.loadDettaglioReclamoOperatoreAPI(
                idReclamo,
            )
            return claims
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadDettaglioReclamoOperatore
