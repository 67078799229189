import { InvoiceFiltersModel, InvoiceModel } from '../../../models/models'
import { getInitialDateRange } from '../../../utilities'
import { formatNoTimeZoneDate } from '../../../components/BDateInput'

import {
    REMOVE_INVOICES_FILTERS,
    SET_INVOICES,
    SET_MONTHYEAR_PLANNER,
} from '../actions/types'
const [today, fromDate] = getInitialDateRange()

interface InvoiceInitialState {
    invoices: Array<InvoiceModel>
    monthyearPlanner: string
}

export const INITIAL_STATE: InvoiceInitialState = {
    invoices: [],
    monthyearPlanner: ''
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_INVOICES:
            return {
                ...state,
                invoices: payload
            }
        case REMOVE_INVOICES_FILTERS:
            return {
                ...INITIAL_STATE
            }
        case SET_MONTHYEAR_PLANNER:
            return {
                ...state,
                monthyearPlanner: payload
            }
        default: {
            return state
        }
    }
}



