import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { FatturaViaMailModel } from '../../models/models'

const insertRichiestaFatturaViaMailAPI = async (
    item: FatturaViaMailModel,
    isOperator: boolean,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const restID = container.restID
    const name = container.store.getState().user.clientInfo.nominativo ?? ''

    try {
        const res = await restID.post(
            '/Richiesta/InsertRichiestaFatturaEmail',
            {
                ...item,
                Nominativo: name,
                FromFrontEnd: true,
                isOperator,
            },
        )
        if (res.status === HttpStates.OK) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default insertRichiestaFatturaViaMailAPI
