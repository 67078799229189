import React from "react";
import { useEffect, useRef, useState } from "react";
import { JsxChild, JsxElement } from "typescript";
import { SportelloModel } from '../models/models'
import MarkerComponent from "./MarkerComponent";
interface MapProps  {
    centX: number
    centY: number,
    children:React.ReactNode
}

const MapComponent  = (
    {
        centX,
        centY,
        children
    }: MapProps

) =>{

const ref = useRef<HTMLDivElement>(null);
const [map, setMap] = useState<google.maps.Map>();
useEffect(() => {

  if (ref.current && !map) {
    setMap(new window.google.maps.Map(ref.current,
        {
            zoom: 5,
            center: { lat: centX!, lng: centY! }
        }
    ));
  }

}, []);

return (
    <>
      <div ref={ref} style={{width: "50%", height: "50vh"}} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, map); //{map});
        }
      })}
    </>
  );


//)
}

export default MapComponent
