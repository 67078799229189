import { IOCServices } from '..'

const uploadAllegatoReclamoAPI = async (
    reclamoId: number,
    files: File[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID
    try {
        const formData = new FormData()
        for (const file of files) {
            formData.append('allegati', file)
        }
        const res = await rest.post(
            `/Reclami/PostAllegatiReclamo?idReclamo=${reclamoId}`,
            formData,
        )
        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default uploadAllegatoReclamoAPI
