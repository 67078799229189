import { useState, useCallback } from 'react'
import { AppAction, useAppDispatch } from '../services/storeProvider'

function useAppQuery<P, T>(
    action: (args: P) => AppAction<T>,
): [
    boolean,
    (args?: P) => Promise<T>,
    React.Dispatch<React.SetStateAction<T>>,
] {
    const dispatch = useAppDispatch()
    const [isLoading, setLoading] = useState(false)

    const reset = () => setLoading(false)

    const call = useCallback(async (args?: P) => {
        setLoading(true)
        try {
            if (!args) {
                return await dispatch((action as () => AppAction<T>)())
            }

            return await dispatch(action(args))
        } catch (e) {
            throw e
        } finally {
            setLoading(false)
        }
    }, [action, dispatch])

    return [isLoading, call, reset]
}

export default useAppQuery
