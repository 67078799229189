import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RIDModel } from '../../models/models'

const insertRichiestaDomiciliazioneAPI = async (
    auto: RIDModel,
    isOperator: boolean,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const restID = container.restID

    try {
        const res = await restID.post(
            `/Richiesta/InsertRichiestaDomiciliazioneBancaria`,
            {
                CodiceUtente: auto.codiceUtente,
                PuntoGas: auto.codiceUtenza,
                POD: auto.podPdr,
                Modalita: auto.tipoRichiesta,
                SDD: auto.sDD,
                PartitaIVARich: auto.partitaIvaRich,
                CodiceFiscaleRich: auto.codfiscRich,
                NominativoLegRapp: auto.nominativoLegRapp,
                CodiceFiscaleLegRapp: auto.codiceFiscaleLegRapp,
                RagioneSociale: auto.nominativo,
                PartitaIVA: auto.partitaIVA,
                CodiceFiscale: auto.codiceFiscale,
                TipoVia: auto.streetType,
                NumCivico: auto.number,
                Barrato: auto.barrato,
                Provincia: auto.province,
                Comune: auto.cityIstat,
                ComuneDesc: auto.city,
                CAP: auto.cap,
                MetodoInvio: auto.modalitaInvio,
                Cellulare: '',
                Email: auto.email,
                IBAN: auto.iban,
                IsOperator: isOperator,
            },
        )

        if (res.status === HttpStates.OK) {
            return res
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default insertRichiestaDomiciliazioneAPI
