import { Roles } from '../../../models/Enums'
import { ClientModel, LoggedUserModel } from '../../../models/models'
import {
    LOGIN,
    LOGOUT,
    AUTH,
    SET_INDIRIZZO,
    SET_DATANASCITA,
    SET_PWDCOMPLEXITYERROR,
    SET_NEEDDATA_REGISTRATION,
    SET_CODICEUTENTE_REGISTRATION,
    SET_CLIENT_INFO,
    LOGIN_CLIENT,
    REMOVE_CLIENT_INFO
} from '../actions/types'

interface UserInitialState {
    clientInfo: ClientModel,
    loggedUser: LoggedUserModel,
    loggedIn: boolean
}

/* eslint-disable import/no-anonymous-default-export */
export const INITIAL_STATE: UserInitialState = {
    loggedIn: false,
    clientInfo: {
        id: '',
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        cellulare: '',
        fax: '',
        pec:'',
        codiceUtente: '',
        nominativo: '',
        codiceFiscale: '',
        partitaIVA: '',
        luogoNascita: '',
        dataNascita: '',
        dataNascitaAPI: '',
        tipoViaResidenza: '',
        viaResidenza: '',
        nrCivicoResidenza: '',
        comuneResidenza: '',
        provinciaResidenza: '',
        capResidenza: '',
        indirizzo: '',
        privacy1: '',
        privacy2: '',
        privacy3: '',
        orig_telefono: '',
        orig_cellulare: '',
        orig_email: '',
        orig_fax: '',
        orig_pec:'',
        orig_privacy1: '',
        orig_privacy2: '',
        orig_privacy3: '',
        isAnagModified: false,
        isPrivacyModified: false,
        personaGiuFis: '',
        isPwdComplexityError: false,
        needDataRegistration: false,
        codiceUtenteRegistration: '',
        idTipoUtente: 1,
    },
    loggedUser: {
        username: '',
        email: '',
        userId: '',
        id: '',
        name: '',
        roles: [Roles.NON_LOGGATO],
    },
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case LOGIN:
            return {
                ...state,
                loggedIn: true,
                loggedUser: payload as LoggedUserModel
            }
        case LOGIN_CLIENT:
            return {
                ...state,
                loggedIn: true,
                loggedUser: payload.loggedUser as LoggedUserModel,
                clientInfo: payload.clientInfo as ClientModel,
            }
        case SET_INDIRIZZO:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    indirizzo: payload
                }
            }
        case SET_DATANASCITA:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    dataNascita: payload
                }
            }
        case AUTH:
            const loggedIn = payload !== false
            if (!loggedIn) {
                return {
                    ...INITIAL_STATE,
                }
            }
            return {
                ...state,
                loggedIn,
                loggedUser: {
                    username: payload.username,
                    email: payload.email,
                    userId: payload.codiceUtente,
                    id: payload.id,
                    name: payload.nominativo,
                    roles: payload.roles,
                }
            }
        case LOGOUT:
            return {
                ...INITIAL_STATE,
            }

        case SET_CLIENT_INFO:
            return {
                ...state,
                clientInfo: payload as ClientModel,
            }
        case REMOVE_CLIENT_INFO: {
             return {
                 ...state,
                 clientInfo: {...INITIAL_STATE.clientInfo},
             }
        }
        case SET_PWDCOMPLEXITYERROR:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    isPwdComplexityError: payload
                }
            }
        case SET_NEEDDATA_REGISTRATION:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    needDataRegistration: payload
                }
            }
        case SET_CODICEUTENTE_REGISTRATION:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    codiceUtenteRegistration: payload
                }
            }

        default: {
            return state
        }
    }
}
