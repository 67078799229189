import { IOCServices } from '..'

export type MotiviAnnullamentoReclamo = {
    idMotivoAnnullamento: number
    motiviAnnullamento: string
}[]

const getMotiviAnnullamentoReclamoAPI = async (
    container?: IOCServices,
): Promise<MotiviAnnullamentoReclamo> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get(`/Reclami/GetMotiviAnnullamento`)
        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getMotiviAnnullamentoReclamoAPI
