import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { useAppSelector } from '../storeProvider'

export type LoginType = {
    username: string,
    password: string
}

const loginAPI =
    async ({ username, password}: LoginType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID
        const restAPI = container.rest

        try {
            const formData = new FormData()
            formData.append('Username', username)
            formData.append('Password', password)
            formData.append('grant_type', 'password')
            
            const res = await restID.post(
                '/connect/token',
                formData
            )
            if (res.status == HttpStates.OK) {
                const data = res.data
                restAPI.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
                restID.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
                return data
            }
            else {
                return
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default loginAPI
