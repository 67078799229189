import { Box, Button, Text, Heading, Spinner } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { getUtenteNonRegistrato, keepAlive, login, validaTokenRegistrazione } from '../services/store/actions'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useI18n from '../hooks/useI18n'
import { useSearchParams } from 'react-router-dom'
import { ValidateTokenType } from '../services/api/validaTokenRegistrazioneAPI'
import RegistrationForm from '../components/forms/RegistrationForm'
import registrazioneUtenteEmail from '../services/store/actions/registrazioneUtenteEmail'
import { ParamModel, RegistrationModel } from '../models/models'
import useAssets from '../hooks/useAssets'
import { getValidStringValue } from '../utilities'
import insertRichiestaPrivacyRegistration from '../services/store/actions/insertRichiestaPrivacyRegistration'
import useSize from '../hooks/useSize'
import confirmTokenRegistrazione from '../services/store/actions/confirmTokenRegistrazione'
import useAppQuery from '../hooks/useAppQuery'
import loadPublicParams from '../services/store/actions/loadPublicParams'
import { SET_PUBLIC_PARAMS } from '../services/store/actions/types'
import { BooleanValueInt, paramEnum } from '../models/Enums'

const Registrazione = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [loaded,setLoaded] = useState(false)
    const [getState, setState] = useState(false)
    const [isLoadingParams, queryParams] = useAppQuery(loadPublicParams)
    const [getUser,setUser] = useState<RegistrationModel>()
    const [getUs,setUs] = useState<RegistrationModel>()
    const main = useAppSelector((state) => state.main)
    const location = useLocation()
    const state = location.state as { path?: string }
    const IDRichiesta = searchParams.get('IDRichiesta')
    const CodiceUtente = searchParams.get('CodiceUtente')
    const Token = searchParams.get('Token')
    const assets = useAssets()
    const size = useSize()

    const loadData = async (submit:ValidateTokenType) => {
        try {

            let us = undefined
            await dispatch(keepAlive())
            const publicParam = await queryParams()
            dispatch({ type: SET_PUBLIC_PARAMS, payload: publicParam })
            const ret = await dispatch(confirmTokenRegistrazione(submit))

            if (ret !== undefined) {
                us=await dispatch(getUtenteNonRegistrato(ret.codiceUtente, ret.codiceFiscale))

                setUser(
                    {
                        CodiceUtente: us?.codiceUtente,
                        Nominativo: us?.nominativo,
                        CodiceFiscale: us?.codiceFiscale,
                        PartitaIVA: us?.partitaIVA,
                        Telefono: us?.telefono,
                        Cellulare: us?.cellulare,
                        Email: ret?.email,
                        Privacy1: us?.privacy1,
                        Privacy2: us?.privacy2,
                        Privacy3: us?.privacy3,
                        IDTipoUtente: us?.idTipoUtente,
                        IDStatoUtente: 1,
                        UserName: '',
                        Password: '',
                        TokenReg: Token!,
                        IDRichiesta: +IDRichiesta!,
                        Registra1: '',
                        Registra2: '',
                        Registra3: '',
                    })

                setState(true)
                if (us) {
                    setLoaded(true)
                }
            }
            else {
                setState(false)
                setLoaded(true)
            }

        } catch (e) {

            setLoaded(true)
            setState(false)
        }
    }

    useEffect(() => {
        const submit = {
            IdRichiesta: IDRichiesta!,
            CodiceUtente: CodiceUtente!,
            TokenReg: Token!
        }
        loadData(submit)
    }, [])

    return (
        <Box direction="row"
                justify="start"
                >
                <Box
                    width="30%"
                    background={"url("+assets.loginside+")"}
                    align='center'
                    justify='center'
                    pad="small"
                    height="100vh"
                    style={{
                        position: 'sticky',
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                    }}
                    >
                    <Box

                        width="medium"
                        height="medium"
                        background={{


                            repeat: "no-repeat",
                            size: "contain",
                            image:"url("+assets.logo+")"

                        }}
                    >
                    </Box>
                </Box>
                {loaded?(<Box

                        pad={{horizontal: "2%",top:"1%", bottom:"2%"}}

                        style={{height: getState?"fit-content":"100vh"}}
                        width="100%"
                        animation={
                            ['fadeIn', 'slideLeft']
                        }
                    >
                    {getState?(

                    <Box
                        >

                        <RegistrationForm
                                loading={false}
                                onSubmit={async (values) => {
                                        try {

                                            values.Privacy1 = getValidStringValue(values.Privacy1)
                                            values.Privacy2 = getValidStringValue(values.Privacy2)
                                            values.Privacy3 = getValidStringValue(values.Privacy3)
                                            values.Registra1 = getValidStringValue(values.Registra1)
                                            values.Registra2 = getValidStringValue(values.Registra2)
                                            values.Registra3 = getValidStringValue(values.Registra3)

                                            const ret = await dispatch(registrazioneUtenteEmail(values))
                                            if (ret) {
                                                await dispatch(login({username:values.UserName,password:values.Password}))
                                                const privacyParam = main.publicParam.find((it: ParamModel) => it.idTipoParametroAzienda === paramEnum.RichiestaPrivacyRegistrazione)?.valore ?? 0
                                                if (privacyParam === BooleanValueInt.true) {
                                                    await dispatch(insertRichiestaPrivacyRegistration(values))
                                                }

                                                navigate(
                                                    state?.path ?? '/home',
                                                )
                                            }

                                        } catch (e) {
                                            console.log(e)
                                        }

                                    }
                                }
                                onBlur={(values) => {

                                }}
                                user={getUser}
                                disabled={false}
                        />

                    </Box>
                    ):
                    (
                        <Box

                            align="center"
                            justify="center"
                            flex>
                            <Heading size={'medium'}>{strings.unsuccessfulValidation}</Heading>
                            <Button
                                hoverIndicator
                                onClick={() => navigate('/login')}>
                                <Box pad="xsmall" direction="row" align="center" gap="small">
                                    <Text>
                                        {strings.backToLoginButton}
                                    </Text>
                                </Box>
                            </Button>
                        </Box>
                    )
                    }
                    </Box>):(
                        <Box background={'background-back'}
                        justify="center" align="center" flex
                        >

                        <Box>
                            <Spinner />
                        </Box>
                    </Box>

                    )
                }
            </Box>
    )

}

export default Registrazione
