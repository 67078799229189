import { Button, ButtonExtendedProps, Text } from 'grommet'

const BTextButton = ({label, ...props}: ButtonExtendedProps) => {
    return (
        <Button plain {...props} color="brand">
            <Text size={props.size || 'xsmall'}>{label}</Text>
        </Button>
    )
}

export default BTextButton
