import { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Heading, Spinner } from 'grommet'
import { Home, Phone, User } from 'grommet-icons'
import BTextInput from '../BTextInput'
import useI18n from '../../hooks/useI18n'
import { SupplyModel, UserModel } from '../../models/models'
import BSeparator from '../BSeparator'
import { useAppSelector } from '../../services/storeProvider'
import { BE_TipiFatturazione, StatiFornitura } from '../../models/Enums'
import useTheme from '../../hooks/useTheme'

const DatiContrattoForm = ({
    loading = false,
    onSubmit,
    onBlur,
    user,
    supply,
    showSide
}: DatiContrattoFormProps) => {
    const strings = useI18n()

    const [reveal, setReveal] = useState(false)
    const [revealConfirm, setRevealConfirm] = useState(false)
    const [userToEdit, setUserToEdit] = useState<UserModel>()
    const main = useAppSelector((state) => state.main)
    const theme = useTheme()

    const Schema = Yup.object().shape({
        telefono: Yup.string().notRequired(),
    })

    const getDescStatoFornitura = (id: number | string) => {
        switch(id) {

            case StatiFornitura.NonDefinita:
                return strings.NonDefinita;
            case StatiFornitura.Attiva:
                return strings.Attiva;
            case StatiFornitura.Cessata:
                return strings.Cessata;
            case StatiFornitura.Sospesa:
                return strings.Sospesa;
            case StatiFornitura.InCessazione:
                return strings.InCessazione;
            case StatiFornitura.RichiestaDisdetta:
                return strings.RichiestaDisdetta;
            case StatiFornitura.NonFatturabile:
                return strings.NonFatturabile;
            case StatiFornitura.Errata:
                return strings.Errata;
            default:
                return '-'
        }
    }

    const getDescTipoFatt = (id: number | string) => {
        switch(id) {

            case BE_TipiFatturazione.Sintetica:
                return strings.sintetica;
            case BE_TipiFatturazione.Dettagliata:
                return strings.dettagliata;
            default:
                return ''
        }
    }

    const getDescStatoFattOnline = (id: boolean) => {
        if (id) {
            return strings.attivo.toUpperCase()
        }

        return strings.nonAttivo.toUpperCase()
    }

    return (
        <Formik
            initialValues={{
                codiceUtente: user?.codiceUtente || '',
                puntoGas: supply?.puntoGas || '',
                comune: supply?.comune || '',
                tipoVia: supply?.tipoVia || '',
                via: supply?.via || '',
                numeroCivico: supply?.numeroCivico || '',
                cap: supply?.cap || '',
                provincia: supply?.provincia || '',
                idStatoFornitura: supply?.idStatoFornitura || '',
                tipoFornitura: supply?.tipoFornitura || '',
                esponenteCivico: supply?.esponenteCivico || '',
                dataLettura: supply?.dataLettura || '',
                lettura: supply?.lettura,
                letturaF1: supply?.letturaF1,
                letturaF2: supply?.letturaF2,
                letturaF3: supply?.letturaF3,
                letturaERF1: supply?.letturaERF1,
                letturaERF2: supply?.letturaERF2,
                letturaERF3: supply?.letturaERF3,
                letturaEPF1: supply?.letturaEPF1,
                letturaEPF2: supply?.letturaEPF2,
                letturaEPF3: supply?.letturaEPF3,
                letturaEIF1: supply?.letturaEIF1,
                letturaEIF2: supply?.letturaEIF2,
                letturaEIF3: supply?.letturaEIF3,
                letturaPF1: supply?.letturaPF1,
                letturaPF2: supply?.letturaPF2,
                letturaPF3: supply?.letturaPF3,
                consumoPresuntoAnnuo: supply?.consumoPresuntoAnnuo || '',
                pod: supply?.pod || '',
                email1: supply?.email1 || '',
                email2: supply?.email2 || '',
                email3: supply?.email3 || '',
                email4: supply?.email4 || '',
                piano: supply?.piano || '',
                interno: supply?.interno || '',
                scala: supply?.scala || '',
                servizioMail: supply?.servizioMail,
                idTipoFatturazione: supply?.idTipoFatturazione || '',
                metodoPagamento: supply?.metodoPagamento || '',
                periodoFatturazione: supply?.periodoFatturazione || '',
                tipoUso: supply?.tipoUso || '',
                codiceTipoUso:supply.codiceTipoUso || '',
                codOfferta: supply?.codOfferta || '',
                cognomeNomeRecapito: supply?.cognomeNomeRecapito || '',
                tipoViaRecapito: supply?.tipoViaRecapito || '',
                viaRecapito: supply?.viaRecapito || '',
                nrCivicoRecapito: supply?.nrCivicoRecapito || '',
                esponenteCivicoRecapito: supply?.esponenteCivicoRecapito || '',
                scalaRecapito: supply?.scalaRecapito || '',
                pianoRecapito: supply?.pianoRecapito || '',
                internoRecapito: supply?.internoRecapito || '',
                provinciaRecapito: supply?.provinciaRecapito || '',
                comuneRecapito: supply?.comuneRecapito || '',
                capRecapito: supply?.capRecapito || '',
                matricolaContatore: supply?.matricolaContatore || '',
                indirizzo: `${supply?.tipoVia} ${supply?.via} ${supply?.numeroCivico}, ${supply?.comune} (${supply?.provincia}) - ${supply?.cap}`,
                indirizzoRecapito: `${supply?.tipoViaRecapito} ${supply?.viaRecapito} ${supply?.nrCivicoRecapito}, ${supply?.comuneRecapito} (${supply?.provinciaRecapito}) - ${supply?.capRecapito}`,
                statoFornitura: `${getDescStatoFornitura(supply?.idStatoFornitura)}`,
                tipoServizio: `${supply?.tipoServizio=='G'?strings.G:supply?.tipoServizio=='GPL'?strings.GPL:strings.E}`,
                aliquotaIVA: supply?.aliquotaIVA || '',
                tipoFatturazione: `${getDescTipoFatt(supply?.idTipoFatturazione)}`,
                descFattOnline: `${getDescStatoFattOnline(supply?.servizioMail)}`,
                statoDomiciliazione: supply?.statoDomiciliazione || '',
                ibanPaese: supply?.ibanPaese || '',
                descrizioneBanca: supply?.descrizioneBanca || '',
                matricolaCorrettore: supply?.matricolaCorrettore || '',
                letturaCorrettore: supply?.letturaCorrettore,
                isOpen: false,
                fromDate: supply?.fromDate || '',
                toDate: supply?.toDate || '',
                dataFineOfferta: supply?.dataFineOfferta || ''
            }}
            enableReinitialize
            validationSchema={Schema}
            validateOnBlur={false}
            validateOnChange={false}
            onBlur={onBlur}
            onSubmit={onSubmit}>

            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {

                return (
                    <Box>
                        <Heading level={3} margin={{bottom:'10px'}}>{strings.datiFornitura}</Heading>
                        <BSeparator size="2px" margin={{bottom:'10px'}} />
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="codOfferta"
                                label={strings.nomeOfferta}
                                placeholder={strings.phNomeOfferta}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="matricolaContatore"
                                label={strings.matricolaContatore}
                                placeholder={strings.phMatricolaContatore}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="indirizzo"
                                label={strings.indirizzo}
                                placeholder={strings.phIndirizzo}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="tipoServizio"
                                label={strings.tipoServizio}
                                placeholder={strings.phTipoServizio}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="tipoUso"
                                label={strings.tipoUso}
                                placeholder={strings.phTipoUso}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="statoFornitura"
                                label={strings.statoFornitura}
                                placeholder={strings.phStatoFornitura}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Heading level={3} margin={{bottom:'10px'}}>{strings.datiAggiuntivi}</Heading>
                        <BSeparator size="2px" margin={{bottom:'10px'}} />
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="pod"
                                label={strings.pod_pdr}
                                placeholder={strings.phPodPdr}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="consumoPresuntoAnnuo"
                                label={strings.consumoPresuntoAnnuo}
                                placeholder={strings.phConsumoPresuntoAnnuo}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="aliquotaIVA"
                                label={strings.aliquotaIVA}
                                placeholder={strings.phAliquotaIVA}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="periodoFatturazione"
                                label={strings.periodoFatturazione}
                                placeholder={strings.phPeriodoFatturazione}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="tipoFatturazione"
                                label={strings.tipoFatturazione}
                                placeholder={strings.phTipoFatturazione}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Heading level={3} margin={{bottom:'10px'}}>{strings.datiSpedizione}</Heading>
                        <BSeparator size="2px" margin={{bottom:'10px'}} />
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="descFattOnline"
                                label={strings.FatturaOnline}
                                placeholder={strings.phServizioFatturaOnline}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="email1"
                                label={strings.email1}
                                placeholder={strings.phEmail1}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="email2"
                                label={strings.email2}
                                placeholder={strings.phEmail2}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="email3"
                                label={strings.email3}
                                placeholder={strings.phEmail3}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="email4"
                                label={strings.email4}
                                placeholder={strings.phEmail4}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="cognomeNomeRecapito"
                                label={strings.cognomeNomeRecapito}
                                placeholder={strings.phCognomeNomeRecapito}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="indirizzoRecapito"
                                label={strings.indirizzoRecapito}
                                placeholder={strings.phIndirizzoRecapito}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Heading level={3} margin={{bottom:'10px'}}>{strings.datiDiPagamento}</Heading>
                        <BSeparator size="2px" margin={{bottom:'10px'}} />
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="metodoPagamento"
                                label={strings.metodoPagamento}
                                placeholder={strings.phMetodoDiPagamento}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="statoDomiciliazione"
                                label={strings.statoDomiciliazione}
                                placeholder={strings.phStatoDomiciliazione}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="ibanPaese"
                                label={strings.ibanPaese}
                                placeholder={strings.phIbanPaese}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="descrizioneBanca"
                                label={strings.descrizioneBanca}
                                placeholder={strings.phDescrizioneBanca}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        {showSide ? (<Button
                            //disabled={values.codiceUtente === '' || !isValid}
                            //width="20%"
                            //size="medium"
                            style={{
                                width:"100%",
                                //minWidth:"10%",
                                height:"50px",
                                marginTop: "10px",
                                color:theme.global?.colors?.['textWhite']?.toString()
                            }}

                            onClick={async () => {
                                try {
                                    if (await validateForm()) {
                                        handleSubmit()
                                    }
                                } catch (e) {

                                }
                            }}
                            icon={
                                loading ? (
                                    <Spinner size="small" />
                                ) : (
                                    <Home color="textWhite"/>
                                )
                            }
                            label={strings.bthome}
                            primary

                        />) : undefined}
                    </Box>
                )
            }}
        </Formik>
    )
}

interface DatiContrattoFormProps {
    loading: boolean
    onSubmit: (supply: SupplyModel) => void | Promise<SupplyModel> | Promise<void>
    onBlur: () => any
    user?: UserModel
    supply: SupplyModel
    showSide?: boolean

}

export default DatiContrattoForm
