import { useState } from 'react'
import {
    Box,
    BoxExtendedProps,
    Button,
    ColumnConfig,
    DataTable,
    DataTableProps,
    Layer,
    Spinner,
    Text,
} from 'grommet'
import { Allegato } from '../models/models'
import useI18n from '../hooks/useI18n'
import BLightButton from './BLightButton'
import { Download, Trash } from 'grommet-icons'
import { formatDateString } from '../utilities'

type FilesTableProps = {
    boxProps?: BoxExtendedProps
    files: Allegato[]
    onDownload?: (allegato: Allegato) => Promise<void | boolean>
    onRemove?: (allegato: Allegato) => Promise<void | boolean>
} & DataTableProps<Allegato>

const FilesTable = ({
    boxProps = {},
    files = [],
    onDownload,
    onRemove,
    ...dataTableProps
}: FilesTableProps) => {
    const strings = useI18n()
    const [showRemoveFile, setShowRemoveFile] = useState<Allegato>()
    const [isDownloading, setDownloading] = useState(-1)
    const [isRemoving, setRemoving] = useState(-1)
    const columns: ColumnConfig<Allegato>[] = [
        {
            property: 'idAllegato',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.fileId}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.idAllegato}
                </Text>
            ),
        },
        {
            property: 'nomeFile',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.fileName}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.nomeFile}
                </Text>
            ),
        },
        {
            property: 'codiceUtente',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.operatore}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.codiceUtente}
                </Text>
            ),
        },
        {
            property: 'dataInserimento',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.date}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {formatDateString(item?.dataInserimento, 'yyyy-MM-dd')}
                </Text>
            ),
        },
        {
            property: 'crud',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {''}
                </Text>
            ),
            render: (item) => (
                <Box direction="row" gap="small" justify="end">
                    {onDownload && (
                        <BLightButton
                            disabled={isDownloading === item.idAllegato}
                            size="small"
                            label={
                                isDownloading === item.idAllegato
                                    ? ''
                                    : strings.download
                            }
                            icon={
                                isDownloading === item.idAllegato ? (
                                    <Spinner />
                                ) : (
                                    <Download />
                                )
                            }
                            onClick={async () => {
                                try {
                                    setDownloading(item.idAllegato)
                                    await onDownload(item)
                                } finally {
                                    setDownloading(-1)
                                }
                            }}
                        />
                    )}
                    {onRemove && (
                        <BLightButton
                            disabled={isRemoving === item.idAllegato}
                            size="small"
                            label={
                                isRemoving === item.idAllegato
                                    ? ''
                                    : strings.remove
                            }
                            icon={
                                isRemoving === item.idAllegato ? (
                                    <Spinner size="small" />
                                ) : (
                                    <Trash color="remove" />
                                )
                            }
                            onClick={() => setShowRemoveFile(item)}
                        />
                    )}
                </Box>
            ),
        },
    ]
    return (
        <Box background={'background-front'} {...boxProps}>
            <DataTable
                background={'background-front'}
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                verticalAlign={'middle'}
                columns={columns}
                data={files}
                {...dataTableProps}
            />
            {!!showRemoveFile && (
                <Layer
                    position="center"
                    onClickOutside={() => setShowRemoveFile(void null)}
                    onEsc={() => setShowRemoveFile(void null)}
                >
                    <Box pad="medium" gap="medium">
                        <Text weight={'bold'} size="large">
                            {strings.deletionConfirmMessage}
                        </Text>
                        <Box direction="row-reverse" align="center" gap="small">
                            <Button
                                color={'remove'}
                                primary
                                icon={isRemoving === showRemoveFile?.idAllegato ? <Spinner /> : <Trash />}
                                label={strings.deleteFile}
                                onClick={async () => {
                                    try {
                                        setRemoving(showRemoveFile?.idAllegato)
                                        await onRemove?.(showRemoveFile)
                                        setShowRemoveFile(void null)
                                        setRemoving(-1)
                                    } finally {
                                        setRemoving(-1)
                                        setShowRemoveFile(void null)
                                    }
                                }}
                            />
                            <Button
                                secondary
                                label={strings.discardDelete}
                                onClick={() => setShowRemoveFile(void null)}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default FilesTable
