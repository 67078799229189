import { Box, Button, Text, Heading, Spinner } from 'grommet'
import { useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router'
import LoginForm from '../components/forms/LoginForm'
import RecoveryForm from '../components/forms/RecoveryForm'
import {
    login,
    recoverPassword,
    recoverUsername,
    richiediPinUtente,
} from '../services/store/actions'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useI18n from '../hooks/useI18n'
import RequestRegistrationForm from '../components/forms/RequestRegistrationForm'
import useAssets from '../hooks/useAssets'
import useSize from '../hooks/useSize'
import { SET_REDIRECT_LOADING } from '../services/store/actions/types'
import { ParamModel } from '../models/models'
import { BooleanValueInt, paramEnum, TipiUtente } from '../models/Enums'
import autologin from '../services/store/actions/autologin'
import BSpinner from '../components/BSpinner'
import richiediPinUtenteEmptyMail from '../services/store/actions/richiediPinUtenteEmptyMail'
import useAuth from '../hooks/useAuth'
import { getCompanyInformation } from '../utilities'

interface ILoginProps {
    forceSignup?: boolean
}

const Login = ({ forceSignup = false }: ILoginProps) => {
    const dispatch = useAppDispatch()
    const { isLoading, loggedIn } = useAuth()
    const [showRecovery, setShowRecovery] = useState(false)
    const [showRegister, setShowRegister] = useState(forceSignup)

    const user = useAppSelector((state) => state.user.clientInfo)
    const main = useAppSelector((state) => state.main)
    const strings = useI18n()
    const navigate = useNavigate()
    const location = useLocation()
    const assets = useAssets()
    const state = location.state as { path?: string }
    const size = useSize()

    const openInSameTab = (url:any) => {
        // 👇️ setting target to _blank with window.open
        window.location.replace(url)
    };

    if (loggedIn) {
        return (
            <Navigate to={state?.path ?? '/home'} state={{ from: location }} />
        )
    }

    if (main.redirectLoading) {
        return (
                assets.useLogoAsLanding?(<Box
                    fill="vertical"
                    align="center"
                    justify="center"
                    background={`url(${size === 'small' ? assets.logintop : assets.loginside})`}
                    pad="small"
                >
                    <Box
                        width={size === 'small' ? 'small' : "medium"}
                        height={size === 'small' ? 'small' : "medium"}
                        background={{
                            repeat: 'no-repeat',
                            size: 'contain',
                            image: "url(" + assets.logo + ")",
                        }}
                    />
                    <BSpinner/>
                </Box>)
            :
                (<Box fill="vertical" align="center" justify="center">
                    <Spinner />
                </Box>)
            )
    }

    return (
        <Box
            direction={size === 'small' ? 'column' : 'row'}
            justify="start"
            fill='vertical'

        >
            <Box
                width="30%"
                background={`url(${size === 'small' ? assets.logintop : assets.loginside})`}
                align="center"
                justify="center"
                pad="small"
                fill={size === 'small' ? 'horizontal': 'vertical'}
                height={size === 'small' ? 'small' : undefined}
                margin={size === 'small' ? { bottom: 'medium' } : undefined}
            >
                <Box
                    width={size === 'small' ? 'small' : "medium"}
                    height={size === 'small' ? 'small' : "medium"}
                    background={{
                        repeat: 'no-repeat',
                        size: 'contain',
                        image: `url(${assets.logo})`,
                    }}
                />
            </Box>
            <Box overflow={{vertical: 'auto'}} flex={{grow: 1}}>
                <Box
                    flex
                    background={{ opacity: 0.97, color: 'background-front' }}
                    round="xsmall"
                    justify='around'
                    align='center'
                    height={{min: '500px'}}
                >
                    <Box margin={{ bottom: 'medium' }}>
                        <Heading margin="0" size="medium">
                            {strings.AppNameLogin}
                        </Heading>
                        <Text weight={'lighter'} size="xxlarge" alignSelf={size === 'small' ? 'center': undefined}>
                            {strings.welcomeBack}
                        </Text>
                    </Box>
                    <Box
                        fill='horizontal'
                    >
                        <Box
                            flex
                            direction="row"
                            gap="large"
                            justify="center"
                            align="center"
                            fill='horizontal'
                        >
                            {!showRecovery && !showRegister && !user.needDataRegistration && (
                                <Box
                                    width='medium'
                                    height={{ min: 'small' }}
                                    animation={
                                        !showRecovery
                                            ? ['fadeIn', 'slideRight']
                                            : ['fadeOut', 'slideLeft']
                                    }>
                                    <LoginForm
                                        loading={isLoading}
                                        onRegisterClick={() => {

                                            setShowRegister(!showRegister)
                                        }}
                                        onSubmit={async (values) => {
                                            try {
                                                const autoLoginParam = main.publicParam.find((it: ParamModel) => it.idTipoParametroAzienda === paramEnum.WEB_Autologin_Abilita)?.valore ?? 0
                                                const isAutologin = (autoLoginParam === BooleanValueInt.true)

                                                if (isAutologin) {
                                                    const companyInformation = getCompanyInformation()
                                                    const companyId = companyInformation.id
                                                    const autoret = await dispatch(autologin({Username: values.username, Password: values.password, IDAzienda: companyId}))

                                                    if (autoret !== undefined) {

                                                        if (autoret.idTipoUtente === TipiUtente.UtenteWeb) {
                                                            await dispatch(login({username: values.username, password: values.password}))
                                                            dispatch({
                                                                type: SET_REDIRECT_LOADING,
                                                                payload: false,
                                                            })
                                                            navigate(state?.path ?? '/home')
                                                        }
                                                        else {

                                                            dispatch({
                                                                type: SET_REDIRECT_LOADING,
                                                                payload: true,
                                                            })
                                                            openInSameTab(autoret.redirectURL+'?Token='+autoret.loginToken)

                                                        }

                                                    }

                                                }
                                                else {
                                                    await dispatch(login(values))
                                                }


                                                navigate(state?.path ?? '/home')
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                            {showRecovery && !showRegister && !user.needDataRegistration && (
                                <Box
                                    width='medium'
                                    height={{ min: 'small' }}
                                    animation={
                                        showRecovery
                                            ? ['fadeIn', 'slideLeft']
                                            : ['fadeOut', 'slideRight']
                                    }>
                                    <RecoveryForm
                                        loading={isLoading}
                                        onSubmit={async (values) => {
                                            try {
                                                const data = { codiceUtente: values.codiceUtente }
                                                await dispatch(
                                                    values.type === 'username'
                                                        ? recoverUsername(data)
                                                        : recoverPassword(data)
                                                )
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                            {((!showRecovery && showRegister) || (user.needDataRegistration)) && (
                                <Box
                                    width='medium'
                                    height={{ min: 'small' }}
                                    animation={
                                        showRegister || user.needDataRegistration
                                            ? ['fadeIn', 'slideLeft']
                                            : ['fadeOut', 'slideRight']
                                    }>
                                    <RequestRegistrationForm
                                        loading={isLoading}
                                        onSubmit={async (values) => {
                                            try {


                                                await dispatch(
                                                    richiediPinUtente({
                                                        CodiceUtente:
                                                            values.CodiceUtente,
                                                        Codice:
                                                            values.Codice,
                                                        Cellulare:
                                                            values.Cellulare,
                                                        Email:
                                                            values.Email
                                                    })

                                                )




                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                        onSubmitStep={async (values) => {
                                            try {


                                                await dispatch(
                                                    richiediPinUtenteEmptyMail({
                                                        CodiceUtente:
                                                            values.CodiceUtente,
                                                        Codice:
                                                            values.Codice,
                                                        Cellulare:
                                                            values.Cellulare,
                                                        Email:
                                                            values.Email
                                                    })

                                                )




                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                        <Box margin={{ vertical: 'small' }}>
                            <Button
                                secondary
                                hoverIndicator
                                onClick={() => {
                                    setShowRecovery(
                                        showRecovery == false && showRegister == true
                                            ? false
                                            : !showRecovery,
                                    )

                                    setShowRegister(false)
                                }}
                            >
                                {!showRecovery && !showRegister
                                    ?
                                        <>
                                            {strings.cantLogin}
                                            <Text size="medium" weight={'bold'}>
                                                {strings.recoverCredential}
                                            </Text>
                                        </>
                                    : showRegister
                                        ?
                                            <>
                                                {strings.alreadyHasAccount}
                                                <Text size="medium" weight={'bold'}>
                                                    {strings.signin}
                                                </Text>
                                            </>
                                        :
                                            <Text size="medium" weight={'bold'}>
                                                {strings.backToLoginButton}
                                            </Text>
                                }
                            </Button>
                        </Box>



                </Box>
            </Box>

        </Box>

    )
}

export default Login
