import { Box, Button, ButtonExtendedProps } from 'grommet'
import { Previous } from 'grommet-icons'

const BBackButton = (props: ButtonExtendedProps) => {
    return (
        <Box round="xsmall" elevation="small" justify="center" align="center">
            <Button
                secondary
                icon={<Previous color="brand" size={props.size} />}
                {...props}
            />
        </Box>
    )
}

export default BBackButton
