import { AppAction } from '../../storeProvider'
import { getStringFromCode, isOperatorOrAgent } from '../../../utilities'
import setNotification from './setNotification'
import { RichiestaAssistenzaModel, SupplyModel } from '../../../models/models'

const insertRichiestaAssistenza =
    (auto: RichiestaAssistenzaModel, props: SupplyModel): AppAction<any> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        const isOperator = isOperatorOrAgent(getState().user.loggedUser.roles)
        try {
            await container.api.insertRichiestaAssistenzaAPI(
                auto,
                props,
                isOperator,
            )
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return true
        } catch (e: any) {
            const index = (e.response?.data?.value ?? e.message).indexOf('|')
            const error =
                index >= 0
                    ? (e.response?.data?.value ?? e.message).substring(
                          index + 1,
                      )
                    : e.response?.data?.value ?? e.message

            const errorDesc = getStringFromCode(
                error,
                getState().main.stringhe,
                i18n,
                lan,
            )
            dispatch(
                setNotification({
                    type: 'error',
                    message: errorDesc,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaAssistenza
