import { combineReducers } from 'redux'
import user from './reducers/user'
import common from './reducers/common'
import main from './reducers/main'
import contract from './reducers/contract'
import config from './reducers/config'
import supply from './reducers/supply'
import invoice from './reducers/invoice'
import request from './reducers/request'

const state = {
    main,
    user,
    common,
    contract,
    config,
    supply,
    invoice,
    request
}

const rootReducer = combineReducers(state)

export type AppReducer = typeof rootReducer
export type AppState = ReturnType<AppReducer>

export default rootReducer
