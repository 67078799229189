import { IOCServices } from '..'
import { DettaglioReclamo } from './loadDettaglioReclamoOperatoreAPI'

const insertReclamoAPI = async (
    claim: DettaglioReclamo,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    try {
        const path = '/Reclami/PostReclamo'
        const res = await container.restID.post(path, claim, {
            formSerializer: {
                indexes: null,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default insertReclamoAPI
