import {
    Avatar,
    Box,
    BoxExtendedProps,
    Button,
    Text,
} from 'grommet'
import useSize from '../../hooks/useSize'
import useTheme from '../../hooks/useTheme'
import useI18n from '../../hooks/useI18n'
import { useAppDispatch } from '../../services/storeProvider'
import { logout } from '../../services/store/actions'
import UserSelected from '../UserSelected'
import useClientInfo from '../../hooks/useClientInfo'
const Sidebar = ({
    menu,
    onClick,
    onProfileClick,
    header,
    name,
    disabled,
    profileButtonLabel,
    ...rest
}: SidebarProps & BoxExtendedProps) => {
    const size = useSize()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const strings = useI18n()
    const [selectedClient] = useClientInfo()

    const AppSidebar = (otherProps: BoxExtendedProps) => (
        <Box
            gap={size === 'large' ? 'medium' : undefined}
            align="center"
            direction="column"
            pad={{ vertical: 'medium' }}
            background={'sidebar'}
            height={'100vh'}
            justify="between"
            {...rest}
            {...otherProps}
        >
            <Box
                direction="column"
                align="center"
                gap={size === 'large' ? 'medium' : 'small'}
                fill>
                {size !== 'small' && header}

                <Box
                    // flex='grow'
                    gap={size === 'large' ? 'large' : 'medium'}
                    margin={{ vertical: 'small' }}
                    height="medium"
                    // alignSelf='center'
                >
                    {menu.map((it) => (
                        <Box
                            key={it.id}
                            onClick={() => onClick(it.id)}
                            direction="row"
                            justify="between"
                            align="center"
                            gap={size === 'large' ? 'large' : 'small'}
                        >
                            <Box
                                direction="row"
                                align="center"
                                gap={size === 'large' ? 'large' : 'small'}
                            >
                                {it.icon}
                                <Text color={it.active ? 'brand' : undefined}>
                                    {it.label}
                                </Text>
                            </Box>
                            <Box
                                width={'8px'}
                                height={'8px'}
                                round="full"
                                background={it.active ? 'brand' : 'transparent'}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box
                width="full"
                gap="medium"
                height={{ min: 'fit-content' }}
            >
                {selectedClient && <UserSelected client={selectedClient} onProfileClick={onProfileClick}/>}
                <Box
                    background={'background-front'}
                    // round={{ corner: 'right', size: 'medium' }}
                    pad={size === 'large' ? 'small' : 'xsmall'}
                    gap={size === 'large' ? 'small' : 'xsmall'}
                    direction="row"
                    align="center">
                    {!disabled ? (
                        <Button plain onClick={onProfileClick}>
                            <Avatar
                                round="small"
                                background={'brand'}
                                alignSelf="start"
                                size={'medium'}>
                                {name[0]?.toUpperCase() ?? '-'}
                            </Avatar>
                        </Button>
                    ) : (
                        <Avatar
                            round="small"
                            background={'brand'}
                            alignSelf="start"
                            size={'medium'}>
                            {name[0]?.toUpperCase() ?? '-'}
                        </Avatar>
                    )}
                    <Box>
                        <Text size="small" weight={'bold'}>
                            {name}
                        </Text>
                        {!disabled && (
                            <Button
                                plain
                                color="brand"
                                onClick={onProfileClick}>
                                <Text size="small">{profileButtonLabel}</Text>
                            </Button>
                        )}
                        <Button
                            plain
                            color="brand"
                            onClick={async () => await dispatch(logout())}>
                            <Text
                                size="small"
                                color={theme.global?.colors?.[
                                    'ko'
                                ]?.toString()}>
                                {strings.logoutButton}
                            </Text>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
    return <AppSidebar />
}

type SidebarProps = {
    menu: Array<{
        id: string
        children?: string[]
        label: string
        icon: JSX.Element
        active: boolean
    }>
    disabled?: boolean,
    header?: JSX.Element
    onClick: (id: string) => void
    onProfileClick: () => void
    name: string
    profileButtonLabel: string
}

export default Sidebar
