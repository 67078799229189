import { useEffect } from 'react'
import { paramEnum } from '../models/Enums'
import { loadPublicParams } from '../services/store/actions'
import { useAppSelector } from '../services/storeProvider'
import useAppQuery from './useAppQuery'

const useIsEnabledClaims = () => {
    const [, queryParams] = useAppQuery(loadPublicParams)
    const publicParam = useAppSelector((state) => state.main.publicParam)
    const claimsParam = publicParam.filter(
        (it) =>
            +it.idTipoParametroAzienda === paramEnum.WEB_AbilitaGestioneReclami,
    )
    const isClaimsEabled = claimsParam?.[0]?.valore === '1'

    useEffect(() => {
        queryParams()
    }, [queryParams])
    return isClaimsEabled
}

export default useIsEnabledClaims
