import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'

export type recoverPasswordType = {
    codiceUtente: string
}

const recoverPasswordAPI =
    async ({ codiceUtente }: recoverPasswordType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const restID = container.restID

        try {
            const res = await restID.post(
                '/Utente/RecuperaPasswordNew',{
                    CodiceUtente: codiceUtente,
                    FromFrontend: true
            })
            return res.status === HttpStates.OK

        } catch (e) {
            return Promise.reject(e)
        }
    }

export default recoverPasswordAPI
