import { useCallback, useEffect } from 'react'
import {
    Box,
    Button,
    grommet,
    Grommet,
    Layer,
    Notification,
    Spinner,
    StatusType,
} from 'grommet'
import { deepMerge } from 'grommet/utils'
import ioc from './services'
import { ContainerProvider } from './services'
import { Provider } from 'react-redux'
import Navigation from './Navigation'
import { useThemeLoader } from './hooks/useTheme'
import { useAppDispatch, useAppSelector } from './services/storeProvider'
import useI18n from './hooks/useI18n'
import { setNotification } from './services/store/actions'
import setModal from './services/store/actions/setModal'
import { FormClose } from 'grommet-icons'
import useAssets from './hooks/useAssets'

function UserNextApp() {
    const strings = useI18n()
    const [isLoadingTheme, theme] = useThemeLoader()

    const {
        show: showNotification,
        type: notificationType,
        message: notificationMessage,
    } = useAppSelector((state) => state.common.notification)
    const {
        show: showModal,
        type: modalType,
        message: modalMessage,
    } = useAppSelector((state) => state.common.modal)
    const dispatch = useAppDispatch()
    const assets = useAssets()
    const configureManifestJSON = useCallback(() => {
        const manifestJSON = {
            short_name: strings.AppName,
            name: `${strings.AppName} web`,
            icons: [
                {
                    src: assets.favicon,
                    type: 'image/x-icon',
                    sizes: '64x64 32x32 24x24 16x16',
                },
                {
                    src: assets.logo,
                    type: 'image/png',
                    sizes: '192x192',
                },
                {
                    src: assets.logo,
                    type: 'image/png',
                    sizes: '512x512',
                },
            ],
            display: 'standalone',
            theme_color: '#000000',
            background_color: '#ffffff',
        }
        const stringManifest = JSON.stringify(manifestJSON)
        const blob = new Blob([stringManifest], { type: 'application/json' })
        const manifestURL = URL.createObjectURL(blob)
        document
            .querySelector('#customer-manifest')
            ?.setAttribute('href', manifestURL)
        document
            .querySelector("link[rel~='icon']")
            ?.setAttribute('href', assets.favicon)
        document.title = strings.AppName
        document
            .querySelector('meta[name="description"]')
            ?.setAttribute('content', `${strings.AppName} Web App`)
    }, [assets.favicon, assets.logo, strings.AppName])

    useEffect(() => {
        configureManifestJSON()
    }, [configureManifestJSON])

    const getNotificationTitle = (type: string) => {
        switch (type) {
            case 'error':
                return strings.ERROR
            case 'warn':
                return strings.WARNING
            case 'info':
                return strings.INFO
            default:
                return ''
        }
    }

    const getNotificationStatus = (type: string): StatusType => {
        switch (type) {
            case 'error':
                return 'critical'
            case 'warn':
                return 'warning'
            case 'info':
                return 'normal'
            default:
                return 'unknown'
        }
    }

    const getModalTitle = (type: string) => {
        switch (type) {
            case 'error':
                return strings.ERROR
            case 'warn':
                return strings.WARNING
            case 'info':
                return strings.INFO
            default:
                return ''
        }
    }

    const getModalColor = (type: string) => {
        switch (type) {
            case 'error':
                return theme.global?.colors?.['ko']?.toString()
            case 'warn':
                return theme.global?.colors?.['warn']?.toString()
            case 'info':
                return theme.global?.colors?.['brand']?.toString()
            default:
                return ''
        }
    }

    if (isLoadingTheme) {
        return (
            <Grommet full>
                <Box fill align="center" justify="center">
                    <Spinner />
                </Box>
            </Grommet>
        )
    }

    return (
        <Grommet
            background={theme.global?.colors?.['background-back']}
            full
            theme={deepMerge(grommet, theme)}
        >
            {showNotification && (
                <Notification
                    toast
                    title={getNotificationTitle(notificationType)}
                    status={getNotificationStatus(notificationType)}
                    message={notificationMessage}
                    onClose={() =>
                        dispatch(
                            setNotification({
                                type: notificationType,
                                message: notificationMessage,
                                show: false,
                            }),
                        )
                    }
                />
            )}
            {showModal && (
                <Layer
                    position="center"
                    onClickOutside={() =>
                        dispatch(
                            setModal({
                                type: modalType,
                                message: modalMessage,
                                show: false,
                            }),
                        )
                    }
                    onEsc={() =>
                        dispatch(
                            setModal({
                                type: modalType,
                                message: modalMessage,
                                show: false,
                            }),
                        )
                    }
                >
                    <Box
                        pad="medium"
                        gap="small"
                        width={{ min: 'small' }}
                        height={{ min: 'small' }}
                        fill
                    >
                        <Box
                            height="30px"
                            round="small"
                            pad="small"
                            style={{
                                backgroundColor: getModalColor(modalType),
                                color: theme.global?.colors?.[
                                    'textWhite'
                                ]?.toString(),
                            }}
                            align="start"
                            justify="center"
                            //color={getModalColor(modalType)}
                        >
                            {getModalTitle(modalType)}
                        </Box>
                        <Box>{modalMessage}</Box>
                        <Box
                            width="100%"
                            direction="row-reverse"
                            margin={{ top: '30px' }}
                        >
                            <Button
                                color={getModalColor(modalType)}
                                style={{
                                    color: theme.global?.colors?.[
                                        'textWhite'
                                    ]?.toString(),
                                    backgroundColor: getModalColor(modalType),
                                }}
                                label={strings.chiudi}
                                icon={
                                    <FormClose
                                        color={theme.global?.colors?.[
                                            'textWhite'
                                        ]?.toString()}
                                    />
                                }
                                onClick={() =>
                                    dispatch(
                                        setModal({
                                            type: modalType,
                                            message: modalMessage,
                                            show: false,
                                        }),
                                    )
                                }
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
            <Navigation />
        </Grommet>
    )
}

function App() {
    return (
        <ContainerProvider container={ioc}>
            <Provider store={ioc.store}>
                <UserNextApp />
            </Provider>
        </ContainerProvider>
    )
}

export default App
