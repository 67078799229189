import { useAppSelector } from '../services/storeProvider'
import { getStringFromCode } from '../utilities'
import useI18n from './useI18n'

const useStringFromCode = () => {
    const strings = useI18n()
    const lan = useAppSelector((state) => state.common.language)
    const dbStrings = useAppSelector((state) => state.main.stringhe)

    const getString = (code: string) => {
        return getStringFromCode(code, dbStrings, strings, lan)
    }
    return getString
}

export default useStringFromCode
