export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const SET_CONTRACTS = 'SET_CONTRACTS'
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO'

export const UPDATE_CONTRACT_WIZARD = 'UPDATE_CONTRACT_WIZARD'

export const API_VERSION = 'API_VERSION'

export const SET_CONFIG = 'SET_CONFIG'
export const SET_TOKENCLIENT = 'SET_TOKENCLIENT'
export const SET_TOKENROP = 'SET_TOKENROP'

//--

export const LOGIN = 'LOGIN'
export const LOGIN_CLIENT = 'LOGIN_CLIENT'
export const AUTH = 'AUTH'
export const LOGOUT = 'LOGOUT'
export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT'
export const SET_INDIRIZZO = 'SET_INDIRIZZO'
export const SET_DATANASCITA = 'SET_DATANASCITA'
export const SET_PWDCOMPLEXITYERROR = 'SET_PWDCOMPLEXITYERROR'
export const SET_NEEDDATA_REGISTRATION = 'SET_NEEDDATA_REGISTRATION'
export const SET_CODICEUTENTE_REGISTRATION = 'SET_CODICEUTENTE_REGISTRATION'
export const SET_REDIRECT_LOADING = 'SET_REDIRECT_LOADING'

export const SET_SUPPLIES = 'SET_SUPPLIES'
export const SET_REMINDERS = 'SET_REMINDERS'
export const SET_ADDRESSES = 'SET_ADDRESSES'
export const SET_LENADDRESSES = 'SET_LENADDRESSES'
export const SET_LENSUPPLIES = 'SET_LENSUPPLIES'
export const SET_SUPPLY = 'SET_SUPPLY'
export const SET_ADDRESS = 'SET_ADDRESS'
export const RESET_SUPPLY = 'RESET_SUPPLY'
export const SET_RECONTRACTING = 'SET_RECONTRACTING'

export const SET_INVOICES = 'SET_INVOICES'
export const REMOVE_INVOICES_FILTERS = 'REMOVE_INVOICES_FILTERS'
export const SET_MONTHYEAR_PLANNER = 'SET_MONTHYEAR_PLANNER'

export const SET_ABILITAZIONE = 'SET_ABILITAZIONE'
export const SET_TIPOINVIO = 'SET_TIPOINVIO'

export const SET_REQUESTS = 'SET_REQUESTS'
export const SET_REQUESTTYPES = 'SET_REQUESTTYPES'
export const SET_REQUESTS_FILTERS = 'SET_REQUESTS_FILTERS'
export const REMOVE_REQUESTS_FILTERS = 'REMOVE_REQUESTS_FILTERS'
export const SET_REQUESTS_DETAILS = 'SET_REQUEST_DETAILS'
export const SET_SELECTEDREQUEST = 'SET_SELECTEDREQUEST'
export const SET_SELECTEDREQUESTID = 'SET_SELECTEDREQUESTID'

export const SET_TP_PRIVACY1 = "SET_TP_PRIVACY1"
export const SET_TP_PRIVACY2 = "SET_TP_PRIVACY2"
export const SET_TP_PRIVACY3 = "SET_TP_PRIVACY3"
export const SET_TP_PRIVACY1SHORT = "SET_TP_PRIVACY1SHORT"
export const SET_TP_PRIVACY2SHORT = "SET_TP_PRIVACY2SHORT"
export const SET_TP_PRIVACY3SHORT = "SET_TP_PRIVACY3SHORT"
export const SET_TP_REGISTRA1 = "SET_TP_REGISTRA1"
export const SET_TP_REGISTRA2 = "SET_TP_REGISTRA2"
export const SET_TP_REGISTRA3 = "SET_TP_REGISTRA3"
export const SET_TP_COOKIE = "SET_TP_COOKIE"
export const SET_TP_OFFERCHANGE = "SET_TP_OFFERCHANGE"

export const SET_STRINGHE = "SET_STRINGHE"
export const SET_SUPPLIES_LOADED = "SET_SUPPLIES_LOADED"
// export const SET_USERNAME = "SET_USERNAME"
export const SET_CONSUMPTIONS = "SET_CONSUMPTIONS"
export const SET_MODAL = "SET_MODAL"
export const SET_PROVINCES_CITY = "SET_PROVINCES_CITY"
export const SET_SUPPORT_REQUESTS = 'SET_SUPPORT_REQUESTS'
export const SET_RANGES = "SET_RANGES"
export const SET_PUBLIC_PARAMS = "SET_PUBLIC_PARAMS"
export const SET_REMINDER_SEEN = "SET_REMINDER_SEEN"
export const SET_LOGGED_USER = 'SET_LOGGED_USER'
export const SET_CLIENT_INFO = 'SET_CLIENT_INFO'
export const REMOVE_CLIENT_INFO = 'REMOVE_CLIENT_INFO'
export const SET_READ_RECONTRACTING = 'SET_READ_RECONTRACTING'

