import { useState } from 'react'
import {
    Box,
    BoxExtendedProps,
} from 'grommet'
import styled, { keyframes } from 'styled-components'

const slideIn = () => {
    return keyframes`
        100% { transform: translateX(-90px); }
    `
}

const slideOut = () => {
    return keyframes`
        100% { transform: translateX(0px); }
    `
}

const growUp = () => {
    return keyframes`
        100% { width: fit-content); }
    `
}

const growDown = () => {
    return keyframes`
        100% { width: 0px };
    `
}


const SlidingBox = styled(Box)`
    animation: ${(props : {show: boolean}) => props.show ? slideIn() : slideOut()} 0.5s forwards
`
const StyledBox = styled(Box)`
    position: absolute;
    right: 0;
    height: 100%;
    animation: ${(props: { show: boolean }) => props.show ? growUp() : growDown()} 0.5s forwards
    opacity: ${(props: {show: boolean}) => props.show ? 1: 0};
    width: ${(props: {show: boolean}) => props.show ? 'fit-content': 0};
`

const BSlidingBox = ({ show, onClick,  main, sliding, ...rest }: BSlidingBoxProprs) => {
    return (
        <Box {...rest} justify='between'>
            <SlidingBox show={show} onClick={onClick} fill='horizontal'>
                {main}
            </SlidingBox>
            <StyledBox show={show}>
                {sliding}
            </StyledBox>
        </Box>
    )
}

type BSlidingBoxProprs = {
    main: JSX.Element,
    sliding: JSX.Element,
    show: boolean
    onClick?: Function
} & BoxExtendedProps

export default BSlidingBox
