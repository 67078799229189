const EnergyIcon = ({
    ss,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string, ss?: string }) => {
    return (
        <svg
            width={ss??"50"}
            height={ss??"50"}
            viewBox="0 0 260 260"
            fill="none"
            {...rest}>
            <g filter="url(#filter0_ii_175_8491)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.9385 118.603C63.095 118.603 61.847 115.015 64.0764 113.251L165.949 32.6013C168.209 30.8121 171.446 32.9293 170.713 35.7169L149.891 114.839C149.39 116.742 150.825 118.603 152.792 118.603H186.07C188.914 118.603 190.162 122.19 187.932 123.955L86.0594 204.604C83.7994 206.393 80.5625 204.276 81.2961 201.489L102.118 122.366C102.618 120.464 101.184 118.603 99.2166 118.603H65.9385Z"
                    fill="url(#paint0_linear_175_8491)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_ii_175_8491"
                    x="57.9326"
                    y="16.9409"
                    width="166.143"
                    height="223.324"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-5" dy="-15" />
                    <feGaussianBlur stdDeviation="36" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.854167 0 0 0 0 0.56375 0 0 0 0 0 0 0 0 0.6 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_175_8491"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="35" dy="35" />
                    <feGaussianBlur stdDeviation="27" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_175_8491"
                        result="effect2_innerShadow_175_8491"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_175_8491"
                    x1="94.0114"
                    y1="199.613"
                    x2="226.453"
                    y2="49.0608"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFDC08" />
                    <stop offset="1" stopColor="#FFA622" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default EnergyIcon
