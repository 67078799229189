import { Box, Collapsible, Layer, Text } from 'grommet'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useI18n from '../hooks/useI18n'
import { EventServiceModel } from '../models/models'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import { differenceInDays, parse, startOfDay } from 'date-fns'
import { FormDown, FormUp } from 'grommet-icons'
import BCard from '../components/BCard'
import BCloseButton from '../components/BCloseButton'
import ReminderBox from '../components/ReminderBox'
import useSize from '../hooks/useSize'
import { paramEnum, TipiEventi } from '../models/Enums'
import { stringDate } from '../utilities'
import useParameters from '../hooks/useParameters'
import Address from '../components/homeWidgets/Address'
import SupplyChart from '../components/homeWidgets/SupplyChart'
import Supplies from '../components/homeWidgets/Supplies'
import Expiring from '../components/homeWidgets/Expiring'
import { SET_REMINDER_SEEN } from '../services/store/actions/types'
import { loadSupplies } from '../services/store/actions'
import useAppQuery from '../hooks/useAppQuery'
const TODAY = new Date()

const Home = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const invoice = useAppSelector((state) => state.invoice)
    const main = useAppSelector((state) => state.main)
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const parameters = useParameters()
    const [showRangeEvents, setShowRangeEvents] = useState<boolean>(false)
    const [showInvoicesEvents, setShowInvoiceEvents] = useState<boolean>(false)
    const [showOffersEvents, setShowOffersEvents] = useState<boolean>(false)
    const [ranges, setRanges] = useState<Array<EventServiceModel>>([])
    const [invoices, setInvoices] = useState<Array<EventServiceModel>>([])
    const [offers, setOffers] = useState<Array<EventServiceModel>>([])
    const eventsAvailable = useMemo(
        () => showRangeEvents || showInvoicesEvents || showOffersEvents,
        [showInvoicesEvents, showOffersEvents, showRangeEvents],
    )
    const publicParam = useAppSelector((state) => state.main.publicParam)
    const reminderSeen = useCallback(() => {
        dispatch({
            type: SET_REMINDER_SEEN,
            payload: true,
        })
    }, [dispatch])

    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        querySupplies(user.codiceUtente)
    }, [querySupplies, user.codiceUtente])

    const rangeEvents = useMemo(
        () =>
            ranges
                .filter((i) => i.type === TipiEventi.Autolettura)
                .sort(function (a, b) {
                    if (a.day < b.day) {
                        return 1
                    }
                    if (a.day > b.day) {
                        return -1
                    }
                    return 0
                }),
        [ranges],
    )

    const invoiceEvents = useMemo(
        () =>
            invoices
                .filter((i) => i.type === TipiEventi.Bolletta)
                .sort(function (a, b) {
                    if (a.day < b.day) {
                        return 1
                    }
                    if (a.day > b.day) {
                        return -1
                    }
                    return 0
                }),
        [invoices],
    )

    const offerEvents = useMemo(
        () =>
            offers
                .filter((i) => i.type === TipiEventi.Offerta)
                .sort(function (a, b) {
                    if (a.day < b.day) {
                        return 1
                    }
                    if (a.day > b.day) {
                        return -1
                    }
                    return 0
                }),
        [offers],
    )

    const WEB_GGRange_PopupScadenze =
        publicParam.find(
            (it) =>
                it.idTipoParametroAzienda ===
                paramEnum.WEB_GGRange_PopupScadenze,
        )?.valore ?? -1

    useEffect(() => {
        const events: Array<EventServiceModel> = []
        let invoiceEvents = false
        let rangeEvents = false
        let offerEvents = false

        for (const it of invoice.invoices) {
            const date = startOfDay(new Date(it.dataScadenza))
            const dd = differenceInDays(TODAY, date)

            if (dd > 0 && dd <= +WEB_GGRange_PopupScadenze) {
                events.push({
                    type: TipiEventi.Bolletta,
                    title: `${strings.bollettaNr} ${it.nrFattura}`,
                    description: [
                        strings.laFattura
                            .concat(' ')
                            .concat(it.nrProvFattura)
                            .concat(' ')
                            .concat(strings.scadraIl)
                            .concat(' ')
                            .concat(stringDate(it.dataScadenza)),
                    ],
                    day: parse(it.dataScadenza, 'yyyy-MM-dd', new Date()),
                    serviceType: it.tipoServizio,
                    payload: it,
                })
                invoiceEvents = true
            }
        }

        for (const it of supply.supplies) {
            if (!it.isOpen || !it.toDate || !it.dataFineOfferta) {
                continue
            }

            if (it.isOpen && it.toDate) {
                const date = startOfDay(new Date(it.toDate))
                const dd = differenceInDays(TODAY, date)
                if (dd > 0 && dd <= +WEB_GGRange_PopupScadenze) {
                    events.push({
                        type: TipiEventi.Autolettura,
                        title: strings.Autolettura,
                        description: [
                            `${strings.rangePerUtenza} ${it.pod} ${
                                strings.scadraIl
                            } ${stringDate(it.toDate)}`,
                        ],
                        serviceType: it.tipoServizio,
                        day: parse(it.toDate, 'yyyy-MM-dd', new Date()),
                    })
                }
                rangeEvents = true
            }

            if (it.dataFineOfferta) {
                const date = startOfDay(new Date(it.dataFineOfferta))
                const dd = differenceInDays(TODAY, date)
                if (dd > 0 && dd <= +WEB_GGRange_PopupScadenze) {
                    events.push({
                        type: TipiEventi.Offerta,
                        title: strings.Offerta,
                        description: [
                            `${strings.latuaOfferta} ${it.codOfferta} ${
                                strings.perUtenza
                            } ${it.pod} ${strings.scadraIl} ${stringDate(
                                it.dataFineOfferta,
                            )}`,
                        ],
                        serviceType: it.tipoServizio,
                        day: parse(
                            it.dataFineOfferta,
                            'yyyy-MM-dd',
                            new Date(),
                        ),
                    })
                }
                offerEvents = true
            }
        }
        if (invoiceEvents && events.length > 0) {
            setInvoices(events)
            setShowInvoiceEvents(true)
        } else if (rangeEvents && events.length > 0) {
            setRanges(events)
            setShowRangeEvents(true)
        } else if (offerEvents && events.length > 0) {
            setOffers(events)
            setShowOffersEvents(true)
        }
    }, [
        WEB_GGRange_PopupScadenze,
        invoice.invoices,
        strings.Autolettura,
        strings.Offerta,
        strings.bollettaNr,
        strings.laFattura,
        strings.latuaOfferta,
        strings.perUtenza,
        strings.rangePerUtenza,
        strings.scadraIl,
        supply.ranges,
        supply.supplies,
    ])

    return (
        <Box flex direction="row" align="start">

            <Box pad={{ horizontal: 'medium' }} fill="horizontal">
                <Box
                    gap="small"
                    direction={size === 'large' ? 'row' : 'column'}
                >
                    <Address isLoading={isLoadingSupplies}  />
                    {parameters.supplyChartVisible && <SupplyChart />}
                </Box>
                <Box
                    gap="small"
                    direction={size === 'large' ? 'row' : 'column'}
                >
                    <Supplies isLoading={isLoadingSupplies}/>
                    <Expiring />
                </Box>
                {!main.reminderSeen && eventsAvailable && (
                    <Layer
                        position="center"
                        onClickOutside={() => reminderSeen()}
                        onEsc={() => reminderSeen()}
                    >
                        <Box
                            pad="medium"
                            gap="small"
                            width={'large'}
                            fill="vertical"
                        >
                            <Box gap="small">
                                <Box
                                    justify="between"
                                    direction="row"
                                    margin={{ bottom: '5px' }}
                                >
                                    <Text weight="bold" size="large">
                                        {strings.leTueScadenze}
                                    </Text>
                                    <BCloseButton
                                        size="small"
                                        onClick={() => reminderSeen()}
                                    />
                                </Box>
                                {rangeEvents.length > 0 && (
                                    <BCard
                                        pad="small"
                                        elevation="xsmall"
                                        flex={false}
                                    >
                                        <Box>
                                            <Box
                                                direction="row"
                                                align="center"
                                                gap="medium"
                                                onClick={() => {
                                                    setShowRangeEvents(
                                                        !showRangeEvents,
                                                    )
                                                    setShowInvoiceEvents(false)
                                                    setShowOffersEvents(false)
                                                }}
                                            >
                                                {showRangeEvents ? (
                                                    <FormUp size="medium" />
                                                ) : (
                                                    <FormDown size="medium" />
                                                )}
                                                <Text
                                                    size="xlarge"
                                                    weight="bolder"
                                                >
                                                    {strings.rangeAutolettura}
                                                </Text>
                                            </Box>
                                            <Collapsible open={showRangeEvents}>
                                                <Box
                                                    overflow="auto"
                                                    style={
                                                        size === 'small'
                                                            ? {
                                                                  minHeight:
                                                                      'small',
                                                                  maxHeight:
                                                                      '100px',
                                                              }
                                                            : {
                                                                  minHeight:
                                                                      'large',
                                                                  maxHeight:
                                                                      '200px',
                                                              }
                                                    }
                                                    width="100%"
                                                >
                                                    {rangeEvents.map(
                                                        (it, idx) => (
                                                            <ReminderBox
                                                                title={
                                                                    it
                                                                        .description[0]
                                                                }
                                                                tipoServizio={
                                                                    it.serviceType
                                                                }
                                                                style={{
                                                                    minHeight:
                                                                        '50px',
                                                                }}
                                                                key={idx}
                                                            />
                                                        ),
                                                    )}
                                                </Box>
                                            </Collapsible>
                                        </Box>
                                    </BCard>
                                )}

                                {invoiceEvents.length > 0 && (
                                    <BCard
                                        pad="small"
                                        elevation="xsmall"
                                        flex={false}
                                    >
                                        <Box>
                                            <Box
                                                direction="row"
                                                align="center"
                                                gap="medium"
                                                onClick={() => {
                                                    setShowInvoiceEvents(
                                                        !showInvoicesEvents,
                                                    )
                                                    setShowRangeEvents(false)
                                                    setShowOffersEvents(false)
                                                }}
                                            >
                                                {showInvoicesEvents ? (
                                                    <FormUp size="medium" />
                                                ) : (
                                                    <FormDown size="medium" />
                                                )}
                                                <Text
                                                    size="xlarge"
                                                    weight="bolder"
                                                >
                                                    {strings.bollette}
                                                </Text>
                                            </Box>
                                            <Collapsible
                                                open={showInvoicesEvents}
                                            >
                                                <Box
                                                    overflow="auto"
                                                    style={
                                                        size === 'small'
                                                            ? {
                                                                  minHeight:
                                                                      'small',
                                                                  maxHeight:
                                                                      '100px',
                                                              }
                                                            : {
                                                                  minHeight:
                                                                      'large',
                                                                  maxHeight:
                                                                      '200px',
                                                              }
                                                    }
                                                    width="100%"
                                                >
                                                    {invoiceEvents.map(
                                                        (it, idx) => (
                                                            <ReminderBox
                                                                key={idx}
                                                                title={
                                                                    it
                                                                        .description[0]
                                                                }
                                                                tipoServizio={
                                                                    it.serviceType
                                                                }
                                                                style={{
                                                                    minHeight:
                                                                        '50px',
                                                                }}
                                                            />
                                                        ),
                                                    )}
                                                </Box>
                                            </Collapsible>
                                        </Box>
                                    </BCard>
                                )}

                                {offerEvents.length > 0 && (
                                    <BCard
                                        pad="small"
                                        elevation="xsmall"
                                        flex={false}
                                    >
                                        <Box>
                                            <Box
                                                direction="row"
                                                align="center"
                                                gap="medium"
                                                onClick={() => {
                                                    setShowOffersEvents(
                                                        !showOffersEvents,
                                                    )
                                                    setShowRangeEvents(false)
                                                    setShowInvoiceEvents(false)
                                                }}
                                            >
                                                {showOffersEvents ? (
                                                    <FormUp size="medium" />
                                                ) : (
                                                    <FormDown size="medium" />
                                                )}
                                                <Text
                                                    size="xlarge"
                                                    weight="bolder"
                                                >
                                                    {strings.offers}
                                                </Text>
                                            </Box>
                                            <Collapsible
                                                open={showOffersEvents}
                                            >
                                                <Box
                                                    overflow="auto"
                                                    style={
                                                        size === 'small'
                                                            ? {
                                                                  minHeight:
                                                                      'small',
                                                                  maxHeight:
                                                                      '100px',
                                                              }
                                                            : {
                                                                  minHeight:
                                                                      'large',
                                                                  maxHeight:
                                                                      '200px',
                                                              }
                                                    }
                                                    width="100%"
                                                >
                                                    {offerEvents.map(
                                                        (it, idx) => (
                                                            <ReminderBox
                                                                key={idx}
                                                                title={
                                                                    it
                                                                        .description[0]
                                                                }
                                                                tipoServizio={
                                                                    it.serviceType
                                                                }
                                                                style={{
                                                                    minHeight:
                                                                        '50px',
                                                                }}
                                                            />
                                                        ),
                                                    )}
                                                </Box>
                                            </Collapsible>
                                        </Box>
                                    </BCard>
                                )}
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        </Box>
    )
}

export default Home
