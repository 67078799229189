import { AppAction } from '../../storeProvider'
import { LOGOUT, RESET_SUPPLY } from './types'

const logout =
    (): AppAction<void> => async (dispatch, _, container) => {
        container.jwt.saveTokenClient('')
        container.jwt.saveTokenROP('')
        container.localStore.deselectClient()
        dispatch({ type: LOGOUT })
        dispatch({ type: RESET_SUPPLY })

        return Promise.resolve()
    }

export default logout
