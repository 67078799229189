import { AnyAction } from 'redux'
import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IOCServices } from '.'
import rootReducer, { AppState } from './store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const storeProvider = (container: IOCServices) => {
    const mainStore = createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(thunk.withExtraArgument(container)),
        ),
    )

    return mainStore
}

export type AppStore = Store<AppState>
export type AppAction<P> = ThunkAction<
    Promise<P>,
    AppState,
    IOCServices,
    AnyAction
>
export type AppDispatch = ThunkDispatch<AppState, IOCServices, AnyAction>
export type AppAsyncAction<P> = (...args: any) => AppAction<P>
export const useAppDispatch = () =>
    useDispatch() as ThunkDispatch<AppState, IOCServices, AnyAction>
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default storeProvider
