import { Box, BoxExtendedProps, Text } from 'grommet'
import { ColorType } from 'grommet/utils'
import useI18n from '../hooks/useI18n'
import useIsAdmin from '../hooks/useIsAdmin'
import useIsOperator from '../hooks/useIsOperator'
import useSize from '../hooks/useSize'
import { TipiRichiesta, TipiVisualizzazioneRichiesta } from '../models/Enums'
import { useAppSelector } from '../services/storeProvider'
import { getNavLinkTipoRichiesta } from '../utilities'
import BCard from './BCard'
import BCloseButton from './BCloseButton'
import BRequest from './BRequest'

const RequestBox = ({
    title,
    icon,
    pod,
    address,
    addressColor,
    requestsContainerTitle,
    onBackPress,
    requests,
    requestButtonLabel,
    onRequestPress,
    ...rest
}: RequestBoxProps) => {
    const strings = useI18n()
    const size = useSize()
    const isOperator = useIsOperator()
    const isAdmin = useIsAdmin()
    const loggedUser = useAppSelector((state) => state.user.loggedUser)

    return (
        <BCard direction="row" align="start" pad={'large'} {...rest}>
            <>
                <Box>
                    <BCloseButton size="medium" onClick={onBackPress} />
                </Box>
                <Box flex gap="large">
                    <Text alignSelf="center" size="xlarge" weight={'bold'}>
                        {title}
                    </Text>
                    <Box align="start" gap="small">
                        <Box
                            elevation="xsmall"
                            pad={{ vertical: 'xsmall', horizontal: 'small' }}
                            round="xsmall"
                            direction="row"
                        >
                            {icon}
                            <Text alignSelf="center">{pod}</Text>
                        </Box>
                        <Box
                            background={addressColor}
                            pad={{ vertical: 'xsmall', horizontal: 'medium' }}
                            round="xsmall"
                        >
                            <Text>{address}</Text>
                        </Box>
                    </Box>
                    <Box align="start" gap="medium">
                        <Text size="xlarge" weight={'bold'}>
                            {requestsContainerTitle}
                        </Text>
                        <Box flex direction="row" wrap>
                            {requests.map((it) => {
                                if (
                                    (isOperator || isAdmin) &&
                                    Number(it.id) ===
                                        TipiRichiesta.GestionePrivacy
                                ) {
                                    return <span />
                                }

                                return (String(
                                    getNavLinkTipoRichiesta(
                                        +it.id,
                                        loggedUser.roles,
                                    ),
                                ) === '' ||
                                    String(
                                        getNavLinkTipoRichiesta(
                                            +it.id,
                                            loggedUser.roles,
                                        ),
                                    ) === undefined) &&
                                    String(
                                        getNavLinkTipoRichiesta(
                                            +it.id,
                                            loggedUser.roles,
                                        ),
                                    ) !==
                                        String(
                                            TipiVisualizzazioneRichiesta.NONMOSTRARE,
                                        ) ? (
                                    <BRequest
                                        flex
                                        key={it.id}
                                        justify="center"
                                        pad={
                                            size === 'small' ||
                                            size === 'medium'
                                                ? 'xsmall'
                                                : 'small'
                                        }
                                        bgcolor="lightgrey"
                                        ttip={strings.WIPFunctionality}
                                        margin={{
                                            vertical: 'small',
                                            right: 'medium',
                                        }}
                                        width={{ min: 'small' }}
                                        //width="small"
                                        background={undefined}
                                        title={it.title}
                                        icon={it.icon}
                                    />
                                ) : String(
                                      getNavLinkTipoRichiesta(
                                          +it.id,
                                          loggedUser.roles,
                                      ),
                                  ) !==
                                  String(
                                      TipiVisualizzazioneRichiesta.NONMOSTRARE,
                                  ) ? (
                                    <BRequest
                                        flex
                                        key={it.id}
                                        justify="center"
                                        pad={
                                            size === 'small' ||
                                            size === 'medium'
                                                ? 'xsmall'
                                                : 'small'
                                        }
                                        margin={{
                                            vertical: 'small',
                                            right: 'medium',
                                        }}
                                        width={{ min: 'small' }}
                                        //width="small"
                                        background={undefined}
                                        title={it.title}
                                        icon={it.icon}
                                        onClick={() => onRequestPress(it.id)}
                                    />
                                ) : undefined
                            })}
                        </Box>
                    </Box>
                </Box>
            </>
        </BCard>
    )
}

export type RequestBoxProps = {
    title: string
    icon: JSX.Element
    onBackPress: () => any
    address: string
    addressColor?: ColorType
    pod: string
    requestsContainerTitle: string
    requests: Array<{
        icon: JSX.Element
        activeIcon: JSX.Element
        title: string
        id: string
    }>
    requestButtonLabel: string
    onRequestPress: (id: string) => any
} & BoxExtendedProps

export default RequestBox
