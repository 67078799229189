import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import useI18n from '../../hooks/useI18n'
import BTextInput from '../BTextInput'
import { Box, Button, CheckBox, Spinner } from 'grommet'
import BSelect from '../BSelect'
import { useEffect, useState } from 'react'
import useAppQuery from '../../hooks/useAppQuery'
import getClaimsRequestTypes from '../../services/store/actions/getClaimsRequestTypes'
import getEnabledChannels from '../../services/store/actions/getEnabledChannels'
import getClaimsSubCatogories from '../../services/store/actions/getClaimsSubCatogories'
import getClaimsARERACategories from '../../services/store/actions/getClaimsARERACatogories'
import {
    formatDateFns,
    formatTimeFns,
    getAllowedClaimsExtensions,
    phoneRegExp,
    stringToDate,
    validateClaimFiles,
} from '../../utilities'
import { useAppSelector } from '../../services/storeProvider'
import BDateInput from '../BDateInput'
import BTimeInput from '../BTimeInput'
import { ClaimsSubCategories } from '../../models/models'
import BTextArea from '../BTextArea'
import { CanaliAbilitati, ClaimContactType } from '../../models/Enums'
import useIsOperator from '../../hooks/useIsOperator'
import useRequiredFields from '../../hooks/useRequiredFields'
import useIsAdmin from '../../hooks/useIsAdmin'
import useIsUser from '../../hooks/useIsUser'
import BFileInput from '../BFileInput'
import { DettaglioReclamo } from '../../services/api/loadDettaglioReclamoOperatoreAPI'

type SelectNumberType = { label: string; value: number }[]

const ReclamiForm = ({
    onSubmit,
    isLoading = false,
    initialValues,
}: ReclamiFormProps) => {
    const strings = useI18n()
    const [claimsTypes, setClaimsTypes] = useState<SelectNumberType>([])
    const [claimsSubTypes, setClaimsSubTypes] = useState<SelectNumberType>([])
    const [claimChannels, setClaimChannels] = useState<SelectNumberType>([])
    const [ARERACategories, setARERACategories] = useState<SelectNumberType>([])
    const [subCategories, setSubCategories] = useState<ClaimsSubCategories[]>(
        [],
    )
    const [loadingTypes, queryClaimTypes] = useAppQuery(getClaimsRequestTypes)
    const [loadingChannels, queryChannels] = useAppQuery(getEnabledChannels)
    const [loadingSubTypes, querySubTypes] = useAppQuery(getClaimsSubCatogories)
    const [loadingARERACategories, queryARERACategories] = useAppQuery(
        getClaimsARERACategories,
    )
    const selectedSupply =
        useAppSelector((state) => state.supply.selectedSupply?.puntoGas) || ''
    const codiceUtente =
        useAppSelector((state) => state.supply.selectedSupply?.codiceUtente) ||
        ''
    const allSupplies = useAppSelector((state) =>
        state.supply.supplies.map((it) => it.puntoGas),
    )
    const user = useAppSelector((state) => state.user.clientInfo)
    const isAdmin = useIsAdmin()
    const isOperator = useIsOperator()
    const isUser = useIsUser()
    const RequestClaimsSchema = useMemo(
        () =>
            Yup.object().shape({
                nominativoContatto: Yup.string().required(
                    strings.mandatoryField,
                ),
                idTipoContatto: Yup.number().required(strings.mandatoryField),
                descrizione: Yup.string().required(strings.mandatoryField),
                occupazione: Yup.string()
                    .nullable()
                    .when('contactType', {
                        is: ClaimContactType.RAPPRESENTANTE,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                idTipoRichiesta: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idSottoCategoria: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idCanale: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idCategoria: isOperator
                    ? Yup.number().positive(strings.mandatoryField).required()
                    : Yup.number(),
                telefonoChiamante: Yup.string()
                    .nullable()
                    .matches(phoneRegExp, strings.mandatoryField)
                    .min(7, strings.mandatoryField)
                    .notRequired(),
                emailMittente: Yup.string()
                    .nullable()
                    .email(strings.mandatoryField),
                telefonoPerRicontatto: Yup.string()
                    .nullable()
                    .matches(phoneRegExp, strings.mandatoryField)
                    .min(7, strings.mandatoryField)
                    .when('idCanale', {
                        is: CanaliAbilitati.PHONE,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                emailPerRicontatto: Yup.string()
                    .nullable()
                    .email(strings.mandatoryField)
                    .when('idCanale', {
                        is: CanaliAbilitati.EMAIL,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                dataRichiesta: Yup.string().required(strings.mandatoryField),
                oraRichiesta: Yup.string().required(strings.mandatoryField),
                allegati: Yup.mixed()
                    .notRequired()
                    .test(
                        'is-valid-type',
                        strings.fileUnsupported,
                        (value: FileList) => {
                            return validateClaimFiles(value, false)
                        },
                    ),
                applyToAll: Yup.boolean(),
            }),
        [isOperator, strings.fileUnsupported, strings.mandatoryField],
    )
    const isRequired = useRequiredFields(RequestClaimsSchema)
    const loadOptionsData = useCallback(async () => {
        const [types, cat, channels] = await Promise.all([
            queryClaimTypes(),
            querySubTypes(),
            queryChannels(),
        ])
        const typeOptions = types.map((it) => ({
            label: it.tipoRichiesta,
            value: it.idTipoRichiesta,
        }))
        const defaultCategoryOptions = isOperator ? [] : cat
        const categoryOptions = !initialValues?.idCategoria
            ? defaultCategoryOptions
            : cat.filter((it) => it.idCategoria === initialValues.idCategoria)
        const subTypes = categoryOptions.map((it) => ({
            label: it.sottocategoria,
            value: it.idSottoCategoriaUtility,
        }))
        const channelOptions = channels.map((it) => ({
            label: it.canale,
            value: it.idCanale,
        }))
        setClaimsTypes(typeOptions)
        setClaimsSubTypes(subTypes)
        setClaimChannels(channelOptions)
        setSubCategories(cat)
        if (isOperator) {
            const arera = await queryARERACategories()
            setARERACategories(
                arera.map((it) => ({
                    value: it.idCategoria,
                    label: it.descrizioneCategoria,
                })),
            )
        }
    }, [
        initialValues?.idCategoria,
        isOperator,
        queryARERACategories,
        queryChannels,
        queryClaimTypes,
        querySubTypes,
    ])

    useEffect(() => {
        loadOptionsData()
    }, [loadOptionsData])

    const getCurrentDate = (date = new Date()) => {
        return formatDateFns(date)
    }

    const getCurrentTime = (date = new Date()) => {
        return formatTimeFns(date)
    }

    const contactTypeList = useMemo(() => {
        if (isAdmin) {
            return [
                {
                    label: strings.contactTypeAgent,
                    value: 1,
                },
            ]
        }
        return [
            { label: strings.contactTypeOwner, value: 0 },
            {
                label: strings.contactTypeAgent,
                value: 1,
            },
        ]
    }, [isAdmin, strings.contactTypeAgent, strings.contactTypeOwner])

    const requestDate = !!initialValues?.dataRichiesta
        ? stringToDate(initialValues.oraRichiesta)
        : new Date()

    return (
        <Formik
            initialValues={{
                codiceUtente: codiceUtente ?? user.codiceUtente,
                nominativoContatto: user?.nominativo ?? '',
                idTipoContatto: isAdmin
                    ? ClaimContactType.RAPPRESENTANTE
                    : ClaimContactType.TITOLARE,
                descrizione: '',
                occupazione: isAdmin ? strings.amministratore : '',
                idCategoria: -1,
                idSottoCategoria: -1,
                idCanale: claimChannels?.[0]?.value ?? -1,
                idTipoRichiesta: -1,
                telefonoChiamante: user?.cellulare ?? user?.telefono ?? '',
                emailMittente: user?.email ?? '',
                telefonoPerRicontatto: user?.cellulare ?? user?.telefono ?? '',
                emailPerRicontatto: user?.email ?? '',
                dataRichiesta: getCurrentDate(requestDate),
                oraRichiesta: getCurrentTime(requestDate),
                applyToAll: false,
                ...initialValues,
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={RequestClaimsSchema}
            onSubmit={(values) => {
                const { applyToAll, ...rest } = values
                const category = isOperator
                    ? rest.idCategoria
                    : subCategories.find(
                          (it) =>
                              it.idSottoCategoriaUtility ===
                              rest.idSottoCategoria,
                      )?.idCategoria ?? -1
                const puntiGas =
                    initialValues?.puntiGas ?? applyToAll
                        ? allSupplies
                        : [selectedSupply]
                onSubmit({
                    puntiGas,
                    ...rest,
                    idCategoria: category,
                })
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
                setFieldValue,
            }) => {
                return (
                    <Box gap="small">
                        <Box direction="row" flex gap="small" align="flex-end">
                            {isOperator && (
                                <BSelect
                                    searchable
                                    mandatory={isRequired('idCategoria')}
                                    containerProps={{ flex: true }}
                                    label={strings.areraCategory}
                                    placeholder={strings.areraCategory}
                                    name="idCategoria"
                                    handleChange={(field: string) =>
                                        (e: any) => {
                                            const value =
                                                e.option?.value ?? e.value
                                            const subtypeMapper = (
                                                it: ClaimsSubCategories,
                                            ) => ({
                                                label: it.sottocategoria,
                                                value: it.idSottoCategoriaUtility,
                                            })
                                            const subtypeClaims = subCategories
                                                .filter(
                                                    (it) =>
                                                        it.idCategoria ===
                                                        value,
                                                )
                                                .map(subtypeMapper)
                                            setClaimsSubTypes(subtypeClaims)
                                            handleChange(field)(e)
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={ARERACategories}
                                    labelKey="label"
                                    icon={
                                        loadingARERACategories ? (
                                            <Spinner />
                                        ) : (
                                            true
                                        )
                                    }
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                            )}
                            <BSelect
                                searchable
                                mandatory={isRequired('idSottoCategoria')}
                                containerProps={{ flex: true }}
                                label={strings.category}
                                placeholder={strings.category}
                                name="idSottoCategoria"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimsSubTypes}
                                labelKey="label"
                                icon={loadingSubTypes ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                mandatory={isRequired('idCanale')}
                                disabled={!isOperator}
                                containerProps={{ flex: true }}
                                label={strings.canaleAbilitato}
                                placeholder={strings.canaleAbilitato}
                                name="idCanale"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimChannels}
                                labelKey="label"
                                icon={loadingChannels ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BSelect
                                searchable
                                mandatory={isRequired('idTipoRichiesta')}
                                containerProps={{ flex: true }}
                                label={strings.claimsRequestTypes}
                                placeholder={strings.claimsRequestTypes}
                                name="idTipoRichiesta"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimsTypes}
                                labelKey="label"
                                icon={loadingTypes ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            {!isUser && (
                                <BSelect
                                    searchable
                                    mandatory={isRequired('idTipoContatto')}
                                    containerProps={{ flex: true }}
                                    label={strings.contactType}
                                    placeholder={strings.contactType}
                                    name="idTipoContatto"
                                    handleChange={(field: string) =>
                                        (e: any) => {
                                            const value =
                                                e.option?.value ?? e.value
                                            if (
                                                value !==
                                                ClaimContactType.RAPPRESENTANTE
                                            ) {
                                                setFieldValue(
                                                    'occupazione',
                                                    isAdmin
                                                        ? strings.amministratore
                                                        : '',
                                                )
                                            }
                                            handleChange(field)(e)
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={contactTypeList}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                            )}
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired('nominativoContatto')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.contactName}
                                label={strings.contactName}
                                name="nominativoContatto"
                                disabled={
                                    values.idTipoContatto ===
                                        ClaimContactType.TITOLARE || isAdmin
                                }
                            />
                            {values.idTipoContatto ===
                                ClaimContactType.RAPPRESENTANTE && (
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    mandatory={isRequired('occupazione')}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.occupation}
                                    label={strings.occupation}
                                    name="occupazione"
                                    disabled={isAdmin}
                                />
                            )}
                        </Box>
                        {isOperator && (
                            <Box
                                direction="row"
                                flex
                                gap="small"
                                align="flex-start"
                            >
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    mandatory={isRequired(
                                        'emailMittente',
                                        values,
                                    )}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.emailSender}
                                    label={strings.emailSender}
                                    name="emailMittente"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    mandatory={isRequired(
                                        'telefonoChiamante',
                                        values,
                                    )}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.phoneSender}
                                    label={strings.phoneSender}
                                    name="telefonoChiamante"
                                />
                            </Box>
                        )}
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired(
                                    'emailPerRicontatto',
                                    values,
                                )}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.emailForContact}
                                label={strings.emailForContact}
                                name="emailPerRicontatto"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired(
                                    'telefonoPerRicontatto',
                                    values,
                                )}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.phoneForContact}
                                label={strings.phoneForContact}
                                name="telefonoPerRicontatto"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextArea
                                containerProps={{ flex: true }}
                                mandatory={isRequired('descrizione')}
                                resize={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.description}
                                label={strings.description}
                                name="descrizione"
                            />
                        </Box>
                        <Box direction="row" flex align="center">
                            <BDateInput
                                containerProps={{
                                    flex: true,
                                    margin: { right: 'small' },
                                }}
                                mandatory={isRequired('dataRichiesta')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.claimDate}
                                label={strings.claimDate}
                                name="dataRichiesta"
                                format="dd/mm/yyyy"
                                disabled
                            />
                            <BTimeInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired('oraRichiesta')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                label={strings.claimTime}
                                name="oraRichiesta"
                                disabled
                            />
                        </Box>
                        <BFileInput
                            accept={getAllowedClaimsExtensions()}
                            handleChange={handleChange}
                            errors={errors}
                            name="allegati"
                            values={values}
                        />
                        <Box>
                            <CheckBox
                                name={'applyToAll'}
                                checked={values.applyToAll}
                                onChange={handleChange}
                                label={strings.tutteLeForniture}
                            />
                        </Box>
                        <Box margin={{ vertical: 'small' }}>
                            <Button
                                disabled={isLoading}
                                onClick={async () => {
                                    try {
                                        const fields = await validateForm()
                                        const errors = Object.keys(fields)
                                        if (errors.length > 0) {
                                            console.log(errors)
                                            return
                                        }
                                        handleSubmit()
                                    } catch (e) {}
                                }}
                                icon={isLoading ? <Spinner /> : <span />}
                                label={strings.send}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

type ReclamiFormProps = {
    onSubmit: (values: DettaglioReclamo) => any
    isLoading: boolean
    initialValues?: DettaglioReclamo
}

export default ReclamiForm
