import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'

const getAnagraficaUtenteByCodiceUtenteAPI = async (userCode: string,container?: IOCServices) => {
    if (!container) {
        return Promise.reject('Container is null. API must be called from api service')
    }
    const rest = container.restID

    try {
        const res = await rest.get(`/Utente/GetAnagraficaUtenteByCodiceUtente/${userCode}`)
        if (res.status === HttpStates.OK) {
            const data = res.data
            return data
        }

    } catch (e) {
        return Promise.reject(e)
    }
}

export default getAnagraficaUtenteByCodiceUtenteAPI
