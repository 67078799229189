import { Box, Button, Layer, Spinner, Text } from 'grommet'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Alert, Checkmark, Close, Download, FormClose } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import useSize from '../hooks/useSize'
import { InvoiceModel, ParamModel, SupplyModel } from '../models/models'
import useTheme from '../hooks/useTheme'
import BBackButton from '../components/BBackButton'
import {
    getDescStatoPagamento,
    getDescTipoFattura,
    getDescTipoFatturaBE,
    stringDate,
} from '../utilities'
import {
    BE_TipiFatturazione,
    BooleanValueInt,
    paramEnum,
    PeriodiFatturazione,
    StatiPagamento,
    TipiFatturazione,
} from '../models/Enums'
import BillInfoBox from '../components/BillInfoBox'
import BRoundButton from '../components/BRoundButton'
import {
    loadInvoice,
    loadSupplies,
    getFatturaPDF,
    loadPublicParams,
} from '../services/store/actions'
import { useParams } from 'react-router-dom'
import { AllRoutes, RoutesEnum } from '../Navigation'
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns'
import useParameters from '../hooks/useParameters'

function downloadPDF(pdf: any, tipofatt: string, data: Date, numero: string) {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const year = format(data, 'yyyy')
    const month = format(data, 'MM')
    const day = format(data, 'dd')
    const fileName = `${year}_${tipofatt}_${year}${month}${day}_${numero}.pdf`

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
}

const dateFormat = 'dd-MM-yy'

const DettaglioBolletta = () => {
    const { id } = useParams()
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const parameters = useParameters()
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const [, queryParams] = useAppQuery(loadPublicParams)
    const [currSupply, setSupply] = useState<SupplyModel>()
    const [currInvoice, setInvoice] = useState<InvoiceModel>()
    const [isLoading, querySupplies] = useAppQuery(loadSupplies)
    const [isLoadingInvoice, queryInvoice] = useAppQuery(loadInvoice)
    const [showModal, setShowModal] = useState(false)
    const [showSintetica, setShowSintetica] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const loadData = useCallback(async () => {
        try {
            const invoice = await queryInvoice({
                codiceUtente:
                    supply?.selectedSupply?.codiceUtente ?? user.codiceUtente,
                invoiceNr: id,
            })
            const publicParam = await queryParams()
            const suppliesD = await querySupplies(user.codiceUtente)
            const supplyD = suppliesD.find(
                (it: SupplyModel) => it.puntoGas === invoice.puntoGas,
            )

            if (!supplyD) {
                return
            }

            setSupply(supplyD)
            setInvoice(invoice)

            const fatturazioneStartDate = startOfMonth(
                new Date(invoice.dataFattura),
            )
            setStartDate(format(fatturazioneStartDate, dateFormat))

            if (
                supplyD.periodoFatturazione === PeriodiFatturazione.Mensile ||
                supplyD.periodoFatturazione === PeriodiFatturazione.Bimestrale
            ) {
                const fatturazioneEndDate = endOfMonth(
                    addMonths(
                        fatturazioneStartDate,
                        supplyD.periodoFatturazione ===
                            PeriodiFatturazione.Mensile
                            ? 0
                            : 1,
                    ),
                )
                setEndDate(format(fatturazioneEndDate, dateFormat))
            }

            const valueBolletta20 = publicParam.find(
                (it: ParamModel) =>
                    it.idTipoParametroAzienda === paramEnum.Bolletta20,
            )

            setShowSintetica(
                valueBolletta20?.valore === BooleanValueInt.true &&
                    supply.selectedSupply?.idTipoFatturazione ===
                        TipiFatturazione.Sintetica,
            )
        } catch (e) {}
    }, [
        id,
        queryInvoice,
        queryParams,
        querySupplies,
        supply.selectedSupply?.idTipoFatturazione,
        user.codiceUtente,
    ])

    useEffect(() => {
        if (!user.codiceUtente || !supply.selectedSupply) {
            return
        }
        loadData()
    }, [user.codiceUtente, supply.selectedSupply, loadData])

    if (!currInvoice || isLoading || isLoadingInvoice) {
        return (
            <Box
                flex
                direction={size === 'small' ? 'column' : 'row'}
                align="start"
            >
                <Box fill background={'background-back'}>
                    <Box height="100vh" justify="center" align="center">
                        <Spinner />
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box flex direction={size === 'small' ? 'column' : 'row'} align="start">
            <Box flex>
                <Box pad="small" gap="medium">
                    <Box direction="row" justify="between" align="center">
                        <BBackButton
                            size="small"
                            onClick={() =>
                                navigate(`/${AllRoutes[RoutesEnum.BILLS]}`)
                            }
                        />
                    </Box>
                    <Box>
                        <Text weight="bold" size="large">
                            {strings.bolletta}
                        </Text>
                        <Text size="small" color="brand" weight="bold">
                            {strings.periodoRiferimentoDal}{' '}
                            {`${startDate} - ${endDate}`}
                        </Text>
                    </Box>
                    <Box flex direction="row" align="start" wrap gap="10vw">
                        <Box flex direction="row" width={{ min: 'small' }} wrap>
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.codiceUtente}
                                value={currInvoice?.codiceUtente ?? ''}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.nrFattura}
                                value={currInvoice?.nrFattura ?? ''}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.tipologia}
                                value={
                                    currInvoice?.tipoServizio === 'E'
                                        ? strings.E
                                        : currInvoice?.tipoServizio === 'GPL'
                                        ? strings.GPL
                                        : strings.G
                                }
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.tipoFatturazione}
                                value={getDescTipoFatturaBE(
                                    +currInvoice?.idTipoFatturazione!,
                                    strings,
                                )}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.consumo}
                                value={`${currInvoice?.consumoFatturato!.toFixed(
                                    0,
                                )} ${
                                    currInvoice?.tipoServizio === 'E'
                                        ? strings.KilowattOra
                                        : strings.MetroCubo
                                }`}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.pod_pdr}
                                value={currSupply?.pod!}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.tipoFattura}
                                value={currInvoice?.tipoFattura!}
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.dataScadenza}
                                //value="4324"
                                value={stringDate(currInvoice?.dataScadenza!)}
                            />
                        </Box>
                        <Box width={{ min: 'small' }} wrap>
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.indirizzo}
                                value={`${currSupply?.tipoVia} ${currSupply?.via} ${currSupply?.numeroCivico}, ${currSupply?.comune} (${currSupply?.provincia})`}
                                bookmark
                                bookmarkColor={
                                    supply.addresses.find((it) =>
                                        it.puntiGas.includes(
                                            currInvoice?.puntoGas!,
                                        ),
                                    )?.color
                                }
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                direction="row"
                                justify="between"
                                align="center"
                                pad="medium"
                                round="small"
                                title={strings.statoBolletta}
                                value={getDescStatoPagamento(
                                    currInvoice?.statoPagamento!,
                                    strings,
                                )}
                                footer={
                                    currInvoice?.statoPagamento ===
                                    StatiPagamento.Pagata ? (
                                        <Box
                                            round
                                            pad={'xsmall'}
                                            background="ok"
                                        >
                                            <Checkmark color="white" />
                                        </Box>
                                    ) : currInvoice?.statoPagamento ===
                                      StatiPagamento.PagataParzialmente ? (
                                        <Box
                                            round
                                            pad={'xsmall'}
                                            background="warn"
                                        >
                                            <Alert color="white" />
                                        </Box>
                                    ) : (
                                        <Box
                                            round
                                            pad={'xsmall'}
                                            background="ko"
                                        >
                                            <Close color="white" />
                                        </Box>
                                    )
                                }
                            />
                            <BillInfoBox
                                flex={{ grow: 0.5 }}
                                width={'medium'}
                                elevation="small"
                                pad="medium"
                                round="small"
                                title={strings.totaleBolletta}
                                value={`${currInvoice?.importoTotaleFattura.toFixed(
                                    2,
                                )} €`}
                            />
                            {parameters.downLoadPdfFatture && (
                                <Box
                                    flex={{ grow: 0.5 }}
                                    width={'medium'}
                                    elevation="small"
                                    pad="medium"
                                    round="small"
                                >
                                    <BRoundButton
                                        label={strings.ScaricaDoc}
                                        icon={<Download />}
                                        onClick={() => setShowModal(true)}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                {showModal && (
                    <Layer
                        position="center"
                        onClickOutside={() => setShowModal(false)}
                        onEsc={() => setShowModal(false)}
                    >
                        <Box
                            pad="medium"
                            gap="small"
                            width={{ min: 'small' }}
                            height={{ min: 'small' }}
                            fill
                        >
                            <Box
                                height="30px"
                                round="small"
                                pad="small"
                                style={{
                                    backgroundColor:
                                        theme.global?.colors?.[
                                            'brand'
                                        ]?.toString(),
                                    color: theme.global?.colors?.[
                                        'textWhite'
                                    ]?.toString(),
                                }}
                                align="start"
                                justify="center"
                            >
                                {`${strings.download} ${strings.fatturaNr} ${currInvoice?.nrFattura}`}
                            </Box>
                            <Box gap="medium" margin={{ top: 'small' }}>
                                {showSintetica && (
                                    <BRoundButton
                                        label={strings.sintetica}
                                        disabled={!currInvoice}
                                        onClick={async () => {
                                            if (!currInvoice) {
                                                return
                                            }
                                            try {
                                                const data = await dispatch(
                                                    getFatturaPDF({
                                                        nrFattura:
                                                            currInvoice!
                                                                .nrFattura,
                                                        tipoFatturazione:
                                                            BE_TipiFatturazione.Sintetica,
                                                    }),
                                                )
                                                downloadPDF(
                                                    data.value,
                                                    getDescTipoFattura(
                                                        TipiFatturazione.Sintetica,
                                                        strings,
                                                    ),
                                                    new Date(
                                                        currInvoice.dataFattura,
                                                    ),
                                                    currInvoice!.nrFattura,
                                                )
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />
                                )}
                                <BRoundButton
                                    disabled={!currInvoice}
                                    label={strings.dettagliata}
                                    onClick={async () => {
                                        if (!currInvoice) {
                                            return
                                        }
                                        try {
                                            const data = await dispatch(
                                                getFatturaPDF({
                                                    nrFattura:
                                                        currInvoice!.nrFattura,
                                                    tipoFatturazione:
                                                        BE_TipiFatturazione.Dettagliata,
                                                }),
                                            )
                                            downloadPDF(
                                                data.value,
                                                getDescTipoFattura(
                                                    TipiFatturazione.Dettagliata,
                                                    strings,
                                                ),
                                                new Date(
                                                    currInvoice.dataFattura,
                                                ),
                                                currInvoice!.nrFattura,
                                            )
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                            </Box>
                            <Box
                                width="100%"
                                direction="row-reverse"
                                margin={{ top: '30px' }}
                            >
                                <Button
                                    primary
                                    label={strings.chiudi}
                                    icon={<FormClose />}
                                    onClick={() => setShowModal(false)}
                                />
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        </Box>
    )
}

export default DettaglioBolletta
