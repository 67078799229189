import { FatturaViaMailModel } from '../../../models/models'
import { isOperatorOrAgent } from '../../../utilities'
import { AppAction } from '../../storeProvider'
import setModal from './setModal'
import setNotification from './setNotification'

const insertRichiestaFatturaViaMail =
    (item: FatturaViaMailModel): AppAction<any> =>
    async (dispatch, getState, container) => {
        const isOperator = isOperatorOrAgent(getState().user.loggedUser.roles)
        try {
            await container.api.insertRichiestaFatturaViaMailAPI(item, isOperator)
            const lan = getState().common.language
            const i18n = await container.strings(lan)

            dispatch(
                setModal({
                    type: 'info',
                    message:
                        item.Abilitazione
                            ? i18n.receiptNextByMail
                            : i18n.receiptNextCartacea,
                    show: true,
                }),
            )

            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaFatturaViaMail
