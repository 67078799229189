import {
    RequestFiltersModel,
    RequestListResponseModel,
} from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

export const REQUESTS_PER_PAGE = 20

const loadUsersRequests =
    (filters: RequestFiltersModel): AppAction<RequestListResponseModel> =>
    async (dispatch, _, container) => {
        try {
            const requests = await container.api.loadUsersRequestsAPI(filters)
            return requests
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadUsersRequests
