import {
    Box,
    BoxExtendedProps,
    ColumnConfig,
    DataTable,
    Text
} from 'grommet'
import useI18n from '../hooks/useI18n'
import { ClaimsUtility } from '../models/models'
import assets from '../services/customerSpecs/assets/defaultAssets'

export default function UtilitiesRichiestaReclamo({
    claimUtilities,
    ...boxProps
}: IClaimUtilitiesProps) {
    const strings = useI18n()

    const columns: ColumnConfig<ClaimsUtility>[] = [
        {
            property: 'codiceUtenza',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.puntoGas}
                </Text>
            ),
            render: (item: ClaimsUtility) => (
                <Text truncate="tip" size="small">
                    {item.puntoGas}
                </Text>
            ),
        },
        {
            property: 'pod',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.pod_pdr}
                </Text>
            ),
            render: (item: ClaimsUtility) => (
                <Text truncate="tip" size="small">
                    {item.pod}
                </Text>
            ),
        },
        {
            property: 'tipoServizio',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.tipoServizio}
                </Text>
            ),
            // manca il testo tipo gas ecc.. 
            render: (item: ClaimsUtility) =>
                item?.tipoServizio === 'E' ? (
                    <assets.EnergyIcon ss="30px" />
                ) : item?.tipoServizio === 'GPL' ? (
                    <assets.GplIcon ss="30px" />
                ) : (
                    <assets.GasIcon ss="30px" />
                ),
        },
    ]
    return (
        <Box background={'background-front'} {...boxProps}>
            <DataTable
                paginate={{
                    alignSelf: 'end',
                    size: 'small',
                    numberItems: claimUtilities.length,
                    margin: { top: 'xsmall' },
                }}
                background={'background-front'}
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                sortable
                fill
                step={5}
                columns={columns}
                data={claimUtilities}
            ></DataTable>
        </Box>
    )
}

export interface IClaimUtilitiesProps extends BoxExtendedProps {
    claimUtilities: ClaimsUtility[]
}
