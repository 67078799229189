import { Box, BoxExtendedProps, Button, Calendar, Text } from 'grommet'
import { FormNextLink, FormPreviousLink } from 'grommet-icons'
import get from 'lodash.get'
import { useNavigate } from 'react-router'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import useTheme from '../hooks/useTheme'
import { EventModel, EventsByDayModel } from '../models/models'
import { TipiEventi } from '../models/Enums'
import { useAppDispatch } from '../services/storeProvider'
import { formatDateFns } from '../utilities'
import BCard from './BCard'
import EventPlannedBox from './EventPlannedBox'
import { format, getMonth, getWeek, isMonday } from 'date-fns'
import { it } from 'date-fns/locale'
import './CalendarBox.css'
import { AllRoutes, RoutesEnum } from '../Navigation'

const TODAY = new Date()
const MAX_EVENTS_PER_DAYS = 3

const CalendarBox = ({
        showWeekNumber,
        events,
        selectedDay,
        onSelectedDayChange,
        onMonthChange,
        ...rest
}: CalendarBoxProps) => {
    const { global } = useTheme()
    const strings = useI18n()
    const size = useSize()
    const navigate = useNavigate()

    const getColorByEventType = (type: number) => {
        switch(type) {
            case TipiEventi.Bolletta:
                return get(global?.colors, 'fattura')?.toString()
            case TipiEventi.Autolettura:
                return get(global?.colors, 'autolettura')?.toString()
            default: get(global?.colors, 'brand')?.toString()
        }
    }

    const getEventList = (date: Date) => {
        const eventsByDay : EventModel[] = events[formatDateFns(date)]

        if (! eventsByDay) {
            return
        }

        if (eventsByDay.length <= MAX_EVENTS_PER_DAYS) {
            return (
                eventsByDay.map(it =>
                    <EventPlannedBox
                        title={it.title}
                        description={it.description}
                        type={it.type}
                        height="24px"
                        bookmarkColor={getColorByEventType(it.type)}
                        onClick={() => {
                            if (it.type === TipiEventi.Bolletta) {
                                if (!it.payload) {
                                    return
                                }
                                navigate(`/${AllRoutes[RoutesEnum.BILL]}/${it.payload?.nrFattura}`)
                            } else {
                                navigate(`/${AllRoutes[RoutesEnum.SELF_READING]}`)
                            }

                        }}
                    />
                )
            )
        }

        const eventList : JSX.Element[] = []
        let idx = 0
        for (const it of eventsByDay) {
            if (idx > MAX_EVENTS_PER_DAYS) break

            eventList.push(idx < MAX_EVENTS_PER_DAYS
                ?
                    <EventPlannedBox
                        key={idx}
                        title={it.title}
                        description={it.description}
                        type={it.type}
                        height="24px"
                        bookmarkColor={getColorByEventType(it.type)}
                        onClick={() => {
                            if (it.type === TipiEventi.Bolletta) {
                                if (!it.payload) {
                                    return
                                }
                                navigate(`/${AllRoutes[RoutesEnum.BILL]}/${it.payload?.nrFattura}`)
                            } else {
                                navigate(`/${AllRoutes[RoutesEnum.SELF_READING]}`)
                            }

                        }}
                    />
                :
                    <EventPlannedBox
                        key={idx}
                        title={`${strings.altri} ${eventsByDay.length - 3} ${strings.eventi.toLowerCase()}`}
                        description={[]}
                        height="24px"
                        bookmarkColor={getColorByEventType(it.type)}
                    />
            )
            ++idx
        }

        return eventList
    }


    return (
        <Box fill='vertical' {...rest} >
            <BCard fill='vertical' round="medium" bgcolor={get(global?.colors, 'bgPlanner')?.toString()}>
                <Calendar
                    header={({
                        date: currentDate,
                        onPreviousMonth,
                        onNextMonth,
                        previousInBound,
                        nextInBound,
                    }) => (
                        <Box direction="row" align="center" justify="between" gap='small' pad={{vertical: 'small'}}>
                            <Text size="medium" weight='bold' style={{textTransform: 'capitalize'}}>
                               {format(currentDate, "MMMM yyyy", {locale: it})}
                            </Text>
                            <Box direction="row" align="center" justify="between" gap='small'>
                                <Button disabled={!previousInBound} onClick={() => {
                                    onMonthChange?.(false)
                                    onPreviousMonth()
                                }}>
                                    <Box>
                                        <FormPreviousLink />
                                    </Box>
                                </Button>
                                <Button onClick={() => onSelectedDayChange(TODAY)}>
                                    <Text size="medium" weight='bold'>{strings.oggi}</Text>
                                </Button>
                                <Button disabled={!nextInBound} onClick={() => {
                                    onMonthChange?.(true)
                                    onNextMonth()
                                }}>
                                    <Box>
                                        <FormNextLink />
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    )}
                    date={formatDateFns(selectedDay)}
                    locale={it.code}
                    fill
                    firstDayOfWeek={1}
                    className='Calendar'
                >
                    {({ date, day, isSelected }) => {
                        const isCurrentMonth = getMonth(date) === getMonth(selectedDay)
                        return (
                            <Box
                                onClick={() => onSelectedDayChange(date)}
                                border='bottom'
                                fill
                            >
                                <Box direction='column' fill>
                                    <Box
                                        direction={size === 'small' ? 'column' : 'row'}
                                        pad={{ vertical: "small", horizontal:'xsmall' }}
                                        align="center"
                                        justify={size === 'small' ? 'start' : showWeekNumber && isMonday(date) ? "between" : 'end' }
                                    >
                                        {
                                            showWeekNumber && isMonday(date) && size !== 'small' &&
                                            <Text weight='lighter' size="medium">{`W${getWeek(date, {weekStartsOn: 1})}`}</Text>
                                        }
                                        <Box
                                            align="center"
                                            justify="center"
                                            round={isSelected? 'full' : undefined}
                                            background={isSelected ? 'brand' : undefined}
                                            width='32px'
                                            pad='4px'
                                        >
                                            <Text size="medium" weight='bold' color={!isCurrentMonth ? 'disabled' : undefined}>{day}</Text>
                                        </Box>
                                        {
                                            size === 'small' && getEventList(date)?.length &&
                                            <Text size="10px" weight='lighter' >
                                                {'\u2B24'}
                                            </Text>

                                        }
                                    </Box>
                                    {
                                        size !== 'small' &&
                                        <Box direction='column' gap='2px' pad='2px'>
                                            {getEventList(date)}
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        )
                    }}
                </Calendar>
            </BCard>
        </Box>
    )
}

type CalendarBoxProps = {
    events: EventsByDayModel
    selectedDay: Date
    showWeekNumber?: boolean
    onSelectedDayChange: (day: Date) => void
    onMonthChange?: (direction: boolean) => void
} & BoxExtendedProps

export default CalendarBox
