import { IOCServices } from '.'
import appApis from './api'

export type ApiReturnType<
    P extends (args: any) => (param: IOCServices) => Promise<any>,
> = ReturnType<ReturnType<P>>

export type ApiProviderService<
    P extends (args: any) => (param: IOCServices) => Promise<any>,
> = (api: ReturnType<P>) => ReturnType<typeof api>

const apiProvider = (container: IOCServices) => {
    const handler = {
        apply: async (object: any, _: any, args: any) => {
            return object(...args, container)
        },
    }

    const newApis: any = {}
    for (const api of Object.entries(appApis)) {
        const key = api[0]
        newApis[key] = new Proxy(api[1], handler)
    }
    
    return newApis
}

export default apiProvider
