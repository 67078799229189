import { matchRoutes, useLocation } from "react-router-dom"
import { AllRoutes } from '../Navigation'

const useCurrentPath = () => {
    const location = useLocation()
    const matches = matchRoutes(Object.values(AllRoutes).map(it => ({path: it})), location)

    if (! matches) {
        return
    }

    if (matches.length > 1) {
        throw new Error('To many routes associated to the current location.');
    }

    const { route } = matches[0]

    return route.path
}

export default useCurrentPath