import { ChangeAddressRequestModel } from '../../../models/models'
import { isOperatorOrAgent } from '../../../utilities'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const changeAddressRequest =
    (address: ChangeAddressRequestModel): AppAction<boolean> =>
    async (dispatch, getState, container) => {
        try {
            const lan = getState().common.language
            const isOperator = isOperatorOrAgent(getState().user.loggedUser.roles)
            const i18n = await container.strings(lan)
            const res = await container.api.changeAddressRequestAPI(address, isOperator)
            if (!res) {
                throw new Error(i18n.errorMessage)
            }
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            return false
        }
    }

export default changeAddressRequest
