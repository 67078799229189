import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RichiestaDisdettaModel } from '../../models/models'

const insertRichiestaDisdettaContrattoAPI =
    async (disdetta: RichiestaDisdettaModel, isOperator: boolean, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const restID = container.restID
        const name = container.store.getState().user.clientInfo.nominativo ?? ''

        try {
            const res = await restID.post(
                '/Richiesta/InsertRichiestaDisdettaContratto',{
                    CodiceUtente: disdetta.CodiceUtente,
                    PuntoGas: disdetta.PuntoGas,
                    UltimaLettura:disdetta.UltimaLettura,
                    DataUltimaLettura:disdetta.DataUltimaLettura,
                    DisdettaMotivo:disdetta.DisdettaMotivo,
                    ChiusuraContatore:disdetta.ChiusuraContatore,
                    TipoVia:disdetta.TipoVia,
                    Via:disdetta.Via,
                    NumeroCivico:disdetta.NumeroCivico,
                    Barrato:disdetta.Barrato,
                    Scala:disdetta.Scala,
                    Piano:disdetta.Piano,
                    Interno:disdetta.Interno,
                    Provincia:disdetta.Provincia,
                    Comune:disdetta.Comune,
                    Cap:disdetta.Cap,
                    Frazione:disdetta.Frazione,
                    UltimaLetturaF1:disdetta.UltimaLetturaF1,
                    UltimaLetturaF2:disdetta.UltimaLetturaF2,
                    UltimaLetturaF3:disdetta.UltimaLetturaF3,
                    Nominativo: name,
                    IsOperator: isOperator,
            })
            return res.status === HttpStates.OK
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default insertRichiestaDisdettaContrattoAPI
