import { SportelloModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadSportelli =
    (): AppAction<Array<SportelloModel>> => async (dispatch, _, container) => {
        try {
            const sportelli = await container.api.getSportelliAziendaAPI()
            return sportelli.value
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadSportelli
