import { ContractData, PersonalData } from '../../../models/models'
import { UPDATE_CONTRACT_WIZARD } from '../actions/types'

export type WizardSection =
    | 'CONTRACT'
    | 'PERSONAL_DATA'
    | 'UTILITY_DATA'
    | 'OFFER'
    | 'ATTACHMENTS'
    | 'REVIEW'

export type ContractStateKey = 'contractData' | 'personalData'

/* eslint-disable import/no-anonymous-default-export */
export const INITIAL_STATE: ContractState = {
    currentSection: 'CONTRACT',
    workingSection: 'CONTRACT',
    completed: [],
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case UPDATE_CONTRACT_WIZARD: {
            const { completedSection, nextSection, ...rest} = payload
            const newCompletedSection = !!completedSection ? [completedSection] : []
            return {
                ...state,
                ...rest,
                currentSection: nextSection || state.currentSection,
                workingSection: completedSection ? nextSection : state.workingSection,
                completed: [...newCompletedSection, ...state.completed],
            }
        }
        default: {
            return state
        }
    }
}

interface ContractState {
    workingSection: WizardSection,
    currentSection: WizardSection,
    completed: Array<WizardSection>
    contractData?: ContractData
    personalData?: PersonalData
}