import { Box, BoxExtendedProps, Text } from 'grommet'
import { CaretDownFill, CaretUpFill } from 'grommet-icons'

const BCaretCollapsible = ({
    isExpanded,
    expandString,
    hideString,
    reverse,
    selectedSize,
    ...rest
}: BCaretCollapsibleProprs) => {
    return (
        <Box direction="row" align="center" {...rest}>
            {!reverse && (isExpanded ? <CaretUpFill /> : <CaretDownFill />)}
            <Text
                weight="bold"
                size={selectedSize ? selectedSize : 'small'}
                style={{
                    textTransform: 'uppercase',
                }}
            >
                {isExpanded ? hideString : expandString}
            </Text>

            {reverse && (isExpanded ? <CaretUpFill /> : <CaretDownFill />)}
        </Box>
    )
}

type BCaretCollapsibleProprs = {
    isExpanded: boolean
    expandString: string
    hideString: string
    reverse?: boolean
    selectedSize?: 'small' | 'large' | 'xlarge'
} & BoxExtendedProps

export default BCaretCollapsible
