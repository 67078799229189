import { Axios } from 'axios'
import React from 'react'
import { AppAPITypes } from './api'
import apiProvider from './apiProvider'
import { IOCContainer } from './iocContainer'
import restApiProvider from './restApiProvider'
import restIdentityProvider from './restIdentityProvider'
import storeProvider, { AppStore } from './storeProvider'
import stringProvider, { StringProviderService } from './stringProvider'
import JWTProvider, { LocalJWTService } from './JWTProvider'
import customerProvider, { CustomerProviderService } from './customerProvider'
import localStoreProvider, { LocalStoreService } from './localStoreProvider'

export type IOCServices = {
    rest: Axios
    restID: Axios
    api: AppAPITypes
    store: AppStore
    strings: StringProviderService
    customers: CustomerProviderService
    jwt: LocalJWTService
    localStore: LocalStoreService
}

const ioc = new IOCContainer() as IOCContainer & IOCServices
ioc.put('strings', stringProvider)
ioc.put('rest', restApiProvider)
ioc.put('restID', restIdentityProvider)
ioc.put('api', apiProvider)
ioc.put('store', storeProvider)
ioc.put('jwt', JWTProvider)
ioc.put('customers', customerProvider)
ioc.put('localStore', localStoreProvider)

type ContainerProviderProps = {
    container: IOCContainer & IOCServices
}

type ExtendedContainerProps = {
    children: JSX.Element
} & ContainerProviderProps

export const ContainerContext = React.createContext<ContainerProviderProps>(
    void undefined as any,
)

export const ContainerProvider = (props: ExtendedContainerProps) => {
    return (
        <ContainerContext.Provider value={{ container: props.container }}>
            {props.children}
        </ContainerContext.Provider>
    )
}

export const useContainer = () => {
    const result = React.useContext<ContainerProviderProps>(ContainerContext)

    return result.container
}

export function withContainer<P extends {}>(
    BaseComponent: React.ComponentType<P>,
) {
    return (props: P) => {
        return (
            <ContainerContext.Consumer>
                {({ container }) => (
                    <BaseComponent container={container} {...props} />
                )}
            </ContainerContext.Consumer>
        )
    }
}

export default ioc
