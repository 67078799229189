import { useEffect, useState, useRef, useCallback } from 'react'
import { useContainer } from '../services'

const useParameters = () => {
    const container = useContainer()
    const customers = container.customers
    const [currentParameters, setCurrentParameters] = useState(
        customers.defaultCustomer.parameters,
    )
    const canLoadParameters = useRef(true)

    const loadParameters = useCallback(async () => {
        try {
            const id = container.customers.currentCustomerId
            const { parameters } = await container.customers.loadAssetsAsync(id)
            if (!canLoadParameters.current) {
                return
            }
            setCurrentParameters(parameters)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }, [container.customers])

    useEffect(() => {
        canLoadParameters.current = true
        loadParameters()
        return () => {
            canLoadParameters.current = false
        }
    }, [loadParameters])

    return currentParameters
}

export default useParameters
