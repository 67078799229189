import { Box, Spinner } from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import { ExtendedRequestModel, RequestDetailsModel } from '../models/models'
import '../components/BUpload.css'
import { useNavigate } from 'react-router'
import useAppQuery from '../hooks/useAppQuery'
import loadRequest from '../services/store/actions/loadRequestFromOperator'
import RequestDetailsBox from '../components/RequestDetailsBox'

import { useParams } from 'react-router-dom'
import EditRequestForm from '../components/forms/EditRequestForm'
import editRequest from '../services/store/actions/editRequest'
import { StatiRichiesta, TipiRichiesta } from '../models/Enums'
import ReclamoRequestDetails from '../components/ReclamoRequestDetails'
import useIsEnabledClaims from '../hooks/useIsEnabledClaims'

const RequestDetailsOperator = () => {
    const navigate = useNavigate()
    const { reqId } = useParams()
    const [isLoadingRequest, queryRequest] = useAppQuery(loadRequest)
    const [isMutatingRequest, mutateRequest] = useAppQuery(editRequest)
    const [request, setRequest] = useState<ExtendedRequestModel>()
    const isClaimsEnabled = useIsEnabledClaims()

    const loadData = useCallback(async () => {
        try {
            const request = await queryRequest(Number(reqId))
            setRequest(request.request)
        } catch (e) {}
    }, [queryRequest, reqId])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <Box>
            {isLoadingRequest || request === undefined ? (
                <Box fill background={'background-back'}>
                    <Box height="100vh" justify="center" align="center">
                        <Spinner />
                    </Box>
                </Box>
            ) : (
                <Box>
                    {request.idTipoRichiesta ===
                        TipiRichiesta.AssistenzaEReclami && isClaimsEnabled ? (
                        <ReclamoRequestDetails
                            backButton
                            onBackPress={() => navigate('/requestList')}
                            request={request}
                            operatorForm
                        />
                    ) : (
                        <>
                            <RequestDetailsBox
                                backButton
                                onBackPress={() => navigate('/requestList')}
                                request={request}
                            />
                            {request.cambioStatoEnabled && (
                                <Box
                                    pad="medium"
                                    flex="grow"
                                    background={'background-front'}
                                >
                                    <EditRequestForm
                                        onSubmit={async (values) => {
                                            const newReq = await mutateRequest(
                                                values,
                                            )
                                            setRequest(newReq.request)
                                        }}
                                        disabled={
                                            request.idStatoRichiesta ===
                                            StatiRichiesta.Evasa
                                        }
                                        isLoading={isMutatingRequest}
                                        initialValues={request}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default RequestDetailsOperator
