import { IOCServices } from '..'

export type ListinoPrezzi = {
    codiceUtente: string
    importo: string
    unitàDiMisura: string
}

const getListinoPrezziGPLAPI = async (   
    puntogas: string,
    codiceUtente:string,
    container?: IOCServices,
): Promise<ListinoPrezzi> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    try {
        const res = await rest.get(`/Fornitura/GetListinoPrezzoGPL/${puntogas}?codiceUtente=${codiceUtente}`)

        return res.data.value
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getListinoPrezziGPLAPI