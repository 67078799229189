import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'

const getTipiPaginaAPI = async (
    code: string,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    try {     
        const res = await rest.get(
            '/TipiPagina/GetTipiPagina/'.concat(code)
        )
        if (res.status == HttpStates.OK) {
            const data = res.data.value[0].valorePredefinitoIT
            return data
        }
        
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getTipiPaginaAPI
