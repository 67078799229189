import { TipiPagina } from '../../../models/Enums'
import { ConfirmTokenRegistrazioneType } from '../../api/confirmTokenRegistrazioneAPI'
import { AppAction } from '../../storeProvider'
import { SET_TP_COOKIE, SET_TP_PRIVACY1, SET_TP_PRIVACY1SHORT, SET_TP_PRIVACY2, SET_TP_PRIVACY2SHORT, SET_TP_PRIVACY3, SET_TP_PRIVACY3SHORT, SET_TP_REGISTRA1, SET_TP_REGISTRA2, SET_TP_REGISTRA3 } from './types'

const confirmTokenRegistrazione =
    (req: ConfirmTokenRegistrazioneType): AppAction<any> => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const objUser = await container.api.confirmTokenRegistrazioneAPI(req)

            const tp_privacy1 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy1)
            const tp_privacy2 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy2)
            const tp_privacy3 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy3)
            const tp_registra1 = await container.api.getTipiPaginaAPI(TipiPagina.Registra1)
            const tp_registra2 = await container.api.getTipiPaginaAPI(TipiPagina.Registra2)
            const tp_registra3 = await container.api.getTipiPaginaAPI(TipiPagina.Registra3)
            const tp_cookie = await container.api.getTipiPaginaAPI(TipiPagina.Cookie)
            const tp_privacy1Short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy1Short)
            const tp_privacy2Short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy2Short)
            const tp_privacy3Short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy3Short)

            dispatch({type: SET_TP_PRIVACY1, payload: tp_privacy1})
            dispatch({type: SET_TP_PRIVACY2, payload: tp_privacy2})
            dispatch({type: SET_TP_PRIVACY3, payload: tp_privacy3})
            dispatch({type: SET_TP_REGISTRA1, payload: tp_registra1})
            dispatch({type: SET_TP_REGISTRA2, payload: tp_registra2})
            dispatch({type: SET_TP_REGISTRA3, payload: tp_registra3})
            dispatch({type: SET_TP_COOKIE, payload: tp_cookie})
            dispatch({type: SET_TP_PRIVACY1SHORT, payload: tp_privacy1Short})
            dispatch({type: SET_TP_PRIVACY2SHORT, payload: tp_privacy2Short})
            dispatch({type: SET_TP_PRIVACY3SHORT, payload: tp_privacy3Short})

            return objUser
        } catch (e: any) {
            return undefined
        }
    }

export default confirmTokenRegistrazione
