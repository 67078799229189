import { Box, BoxExtendedProps, Text } from 'grommet'
import BCard from './BCard'

const BillBox = ({
    title,
    description,
    icon,
    price,
    isExpiring,
    ...rest
}: BillBoxProps) => {
    return (
        <Box {...rest} style={{flexShrink: 0}}>
            <BCard pad={"small"} >
                <Box direction="row"  justify="between" align="center">
                    <Box align="center"  gap="medium" direction="row">
                        {icon}
                        <Box>
                            <Text size="small" weight="bold">
                                {title}
                            </Text>
                            <Text
                                color={isExpiring ? 'red' : undefined}
                                size="xsmall"
                                weight={isExpiring ? 'normal' : 'lighter'}>
                                {description}
                            </Text>
                        </Box>
                    </Box>
                    <Text color={isExpiring ? 'red' : undefined} size="xsmall">
                        {price}
                    </Text>
                </Box>
            </BCard>
        </Box>
    )
}

type BillBoxProps = {
    title: string
    description: string
    icon: JSX.Element
    price: string
    isExpiring: boolean
} & BoxExtendedProps

export default BillBox
