import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'

const getAccountUtenteAPI = async (
    userCode: string,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get(`/Utente/GetAccountUtente/${userCode}`)
        if (res.status == HttpStates.OK) {
            const data = res.data.value
            return data
        }

    } catch (e) {
        return Promise.reject(e)
    }
}

export default getAccountUtenteAPI
