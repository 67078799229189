import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'

const getFatturaPDFAPI = async (
    fattura: string,
    tipoFatturazione: number,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {     
        const res = await rest.get(
            `/Fattura/GetFatturaPDF/${fattura}?TipoFatturazione=${tipoFatturazione}` 
        )
        if (res.status == HttpStates.OK) {
            const data = res.data
            return data
        }
        
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getFatturaPDFAPI
