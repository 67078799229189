export const HttpStates = {
    OK: 200,
    NoContent: 204,
    KO: 400,
    KOPWD: 409,
}

export const APIReturnTypes = {
    OK: 1,
    OKButNeedData: 2,
    KO: 9,
}

export const ContractTypes = {
    switch: 'SWITCH',
    replacement: 'REPLACEMENT',
    placing: 'PLACING',
    transfer: 'TRANSFER',
    switch_and_transfer: 'SWITCH_AND_TRANSFER',
}

export const PaymentTypes = {
    sepa: 'SEPA',
    postal: 'POSTAL',
}

export const ServiceType = {
    gas: 'Gas',
    electricity: 'Electricity',
}

export const DeliveryType = {
    email: 'EMAIL',
    mail: 'MAIL',
    mail_and_email: 'MAIL_AND_EMAIL',
}

export const SourceAddressType = {
    residence_address: 'RESIDENCE_ADDRESS',
    utility_address: 'UTILITY_ADDRESS',
    custom_address: 'CUSTOM_ADDRESS',
}

export const ClientType = {
    natural: 'NATURAL',
    business: 'BUSINESS',
    business_no_vat: 'BUSINESS_NO_VAT',
    public_administration: 'PUBLIC_ADMINISTRATION',
    condominium: 'CONDOMINIUM',
}

export const StipulationType = {
    agent: 'AGENT',
    user: 'USER',
}

export const SellingChannel = {
    door_to_door: 'DOOR_TO_DOOR',
    teleselling: 'TELESELLING',
    window: 'WINDOW',
}

export const ContractCategory = {
    multi_service: 'MULTI_SERVICE',
    multi_location: 'MULTI_LOCATION',
}

export const SigningMode = {
    manual: 'MANUAL',
    digital: 'DIGITAL',
}

export const ContractStatus = {
    signed: 'SIGNED',
    to_be_signed: 'TO_BE_SIGNED',
    discarded: 'DISCARDED',
    backoffice_validation: 'BACKOFFICE_VALIDATION',
    finalized: 'FINALIZED',
}

export const BooleanValue = {
    true: 'true',
    false: 'false',
}

export const BooleanValueInt = {
    true: '1',
    false: '0',
}

export const TipoInvio = {
    SoloMail: '0',
    MailEPosta: '1',
}

export const TipoServizio = {
    Tutti: '0',
    Gas: '1',
    Luce: '4',
    Gpl: '10',
}

export const StatoRichiesta = {
    InCorso: '0',
    Completate: '1',
}

export const StatiPagamento = {
    NonDefinito: 0,
    Pagata: 1,
    DaPagare: 2,
    PagataParzialmente: 3,
    Rateizzata: 4,
}

export const TipiFatturazione = {
    Sintetica: 1,
    Dettagliata: 2,
}

export const BE_TipiFatturazione = {
    Sintetica: 1,
    Dettagliata: 0,
}

export const TipiUtente = {
    UtenteWeb: 1,
    EveryOneElse: -1,
    Admin: 9,
    Operatore: 2,
}

export const paramEnum = {
    Bolletta20: 56,
    PrivacyAttivitaMarketingPromoInviti: 115,
    PrivacyAttivitaMarketing: 116,
    PrivacyAnalisiPreferenze: 117,
    RichiestaPrivacyRegistrazione: 119,
    AccountPrivacy1: 122,
    AccountPrivacy2: 123,
    AccountPrivacy3: 124,
    AbilitaAutoletturaEE: 125,
    WEB_PagamentoRID_AbilitaCheckRevoca: 201,
    WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta: 207,
    WEB_VisibilitaModificaRichiestaOperatore: 225,
    WEB_FatturaViaMail_AbilitaTipoInvioMisto: 229,
    WEB_Autologin_Abilita: 282,
    WEBSVC_LimiteDimensioniEmail: 519,
    WEB_AlertCambioOfferta: 540,
    WEB_NumeroCampiFatturaViaMail: 546,
    WEB_RichiestaRIDCambioStatoManuale: 547,
    WEB_VisibilitaCampiRidForm: 567,
    WEB_GGRange_PopupScadenze: 568,
    WEB_VisibilitaModalitaInvio: 569,
    WEB_AbilitaGestioneReclami: 570,
}

export const TipiRichiesta = {
    RegistrazioneUtente: 1,
    CambioRecapito: 7,
    DomiciliazioneBancaria: 8,
    AgevolImposte: 9,
    DisdettaContratto: 10,
    Rettifica: 12,
    PagamentoBolletta: 13,
    Informazioni: 14,
    Reclami: 15,
    Autolettura: 16,
    Preventivo: 18,
    ModificaDatiAnagrafici: 19,
    DatiCatastali: 20,
    ContattoGenerico: 50,
    FatturaOnline: 60,
    ContattoCommerciale: 70,
    CambioBollettazione: 80,
    InformazioniAltriUsi: 90,
    InformazioniGrandiClienti: 91,
    Repricing: 100,
    Voltura: 110,
    ResidenzaSuUtenza: 111,
    ModificaPotenza: 112,
    GestionePrivacy: 113,
    RichiediPINUtente: 114,
    RichiediPINNoMail: 115,
    ConfermaLinkReg: 116,
    PagamentoRicorrente: 117,
    PagamentoRicorrenteModificaEmail: 118,
    AutoletturaGPL: 121,
    AssistenzaEReclami: 122,
}

export const StatiRichiesta = {
    Nuova: 0,
    Bozza: 1,
    InLavorazione: 10,
    InEvasione: 20,
    Evasa: 30,
    NuovaDaValidare: 40,
    Confermata: 41,
    Completata: 45,
    ValidataBO: 50,
    InCompilazione: 60,
    Rifiutata: 90,
    Cancellata: 100,
    RigettataBO: 110,
    Assegnata: 120,
    Annullata: 130,
    InAttesaDiModulo: 140,
    ModuloNonCompleto: 150,
    ModuloOk: 160,
    Ricevuta: 200,
    Avviata: 201,
    Sospesa: 202,
    Inviata: 203,
    Inammissibile: 204,
    Ammissibile: 205,
    EsitoNegativo: 206,
    InammissibileNonAnnullata: 207,
    RichiestaAnnullatamentoSNC: 208,
    AnnullataSNC: 209,
    AnnullataDS: 210,
    Chiusa: 211,
}

export const TipiPagina = {
    Privacy1: 'GestionePrivacy_Privacy1.Text',
    Privacy2: 'GestionePrivacy_Privacy2.Text',
    Privacy3: 'GestionePrivacy_Privacy3.Text',
    Cookie: 'NormativaCookie.Text',
    Registra1: 'Guest_Registra_ClausoleGenerali.Text',
    Registra2: 'Guest_Registra_Specifica.Text',
    Registra3: 'Guest_Registra_Privacy.Text',
    Privacy1Short: 'GestionePrivacy_Privacy1Short.Text',
    Privacy2Short: 'GestionePrivacy_Privacy2Short.Text',
    Privacy3Short: 'GestionePrivacy_Privacy3Short.Text',
    CambioOfferta: 'PropostaCambioOfferta.Text',
}

export const PeriodiFatturazione = {
    Mensile: 'Mensile',
    Bimestrale: 'Bimestrale',
}

export const StatiFornitura = {
    NonDefinita: 0,
    Attiva: 1,
    Cessata: 2,
    Sospesa: 3,
    InCessazione: 4,
    RichiestaDisdetta: 5,
    NonFatturabile: 6,
    Errata: 7,
}

export const TipiFornitura = {
    NonDefinito: 0,
    MonoFascia: 1,
    MultiFascia: 3,
}

export const LivStatoRichiesta = {
    PresaInCarico: 1,
    InElaborazione: 2,
    Completata: 3,
    Rifiutata: 4,
}

export const TipiEventi = {
    Bolletta: 1,
    Autolettura: 2,
    Offerta: 3,
}

export const TipiStringa = {
    Registra1: 'LBL_CondizioniGeneraliIntroduzione',
    Registra2: 'LBL_ArtCodiceCivileSpecifica',
    Registra3: 'LBL_PrivacyConsensoDescrizione',
}

export enum TipiInformazioni {
    CITTA_SEDE = 'CittaSede',
    EMAIL_INFO = 'EMailInfo',
    EMAIL_LEGALE = 'EMailLegale',
    IBAN = 'IBAN',
    INDIRIZZO = 'Indirizzo',
    INDIRIZZO_SEDE = 'IndirizzoSede',
    INDIRIZZO_UFFICIO_COMMERCIALE = 'IndirizzoUfficioCommerciale',
    INDIRIZZO_WEB = 'IndirizzoWeb',
    LABEL_AZIENDA_DA_VISUALIZZARE = 'LabelAziendaDaVisualizzare',
    NUMERO_FAX = 'NumeroFax',
    NUMERO_VERDE = 'NumeroVerde',
    NUMERO_AUTOLETTURA = 'NumeroAutolettura',
    NUMERO_TELEFONO_SEDE_LEGALE = 'NumeroTelefonoSedeLegale',
    RAGIONE_SOCIALE = 'RagioneSociale',
    SPORTELLO_COMMERCIALE = 'SportelloCommerciale',
    TRATTAMENTO_DATI = 'TrattamentoDati',
    LOGO = 'Logo',
    LINK_NEGOZI = 'LinkNegozi',
    LINK_REGISTRATI = 'LinkRegistrati',
    LINK_LOGIN = 'LinkLogin',
    PARTITA_IVA = 'PartitaIVA',
    FORO_COMPETENTE = 'ForoCompetente',
}

export enum TipiVisualizzazioneRichiesta {
    NONMOSTRARE = '~',
}

export enum Roles {
    NON_LOGGATO = -3,
    AMMINISTRATORE = 1,
    OPERATORE = 2,
    UTENTE_WEB = 3,
    AGENTE = 8,
    CALL_CENTER = 9,
    AMMINISTRATORE_CONDOMINIO = 10,
    SYSOPLIGHT = 11,
    SUPERUSER = 14,
    SUPERVISORE = 19,
    MANAGER = 20,
    SUPERSYSOP = 23,
}

export enum Lingue {
    Italiano = 1,
}

export enum CanaliAbilitati {
    EMAIL = 2,
    MAIL = 3,
    PHONE = 4,
    WEB = 6,
}

export enum ClaimContactType {
    TITOLARE = 0,
    RAPPRESENTANTE = 1,
}
export const TipoUso = {
    Contatore:'Contatore',
    Fornitura:'Fornitura',
}