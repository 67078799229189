export class LocalStoreService {
    loadSelectedClient() {
        return  localStorage.getItem("UN_SelectedClient")
    }

    selectClient(clientId: string) {
        localStorage.setItem('UN_SelectedClient', clientId)
    }

    deselectClient() {
        localStorage.removeItem('UN_SelectedClient')
    }
}



const localStoreProvider = () => {
    return new LocalStoreService()
}

export default localStoreProvider

