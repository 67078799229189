import { RangeAutoletturaModel, SupplyModel } from '../../../models/models'
import { AppAction, AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_REMINDERS, SET_SUPPLIES, SET_SUPPLY } from './types'

const getFatturaPDF: AppAsyncAction<any> =
    (fattura: any) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            //Allineato a USER
            //Nel caso del dettagliato USER ragiona in maniera dinamica, se clicco sul bottone dettagliato (_D) e ho IDTipoFattur a 1 (Sintetico) allora il PDF Dettagliato verr� aperto con 0
            //se invece clicco sul bottone dettagliato ma ho IDTipoFattur a 0 (Dettagliato) il PDF Dettagliato non verr� pi� aperto con lo 0 ma con il 255
            if (fattura.tipoFatturazione === '0_S' || fattura.tipoFatturazione === '0_D') { fattura.tipoFatturazione = 255 }
            if (fattura.tipoFatturazione === '1_S') { fattura.tipoFatturazione = 255 }
            if (fattura.tipoFatturazione === '1_D') { fattura.tipoFatturazione = 0 }
            //
            const data = await container.api.getFatturaPDFAPI(fattura.nrFattura,fattura.tipoFatturazione)
            if (data) {
                return data
            }
            throw new Error
            
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getFatturaPDF
