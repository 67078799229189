import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import useTheme from '../hooks/useTheme'
import { Box, Button, Text } from 'grommet'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import useI18n from '../hooks/useI18n'

const BRichTextEditor = ({
    handleChange,
    name,
    values,
    disabled,
}: BRichTextEditorProps) => {
    const initialValue = values[name] || ''
    const draft = htmlToDraft(initialValue)
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createWithContent(contentState),
    )
    const [showHTML, setShowHTML] = useState(false)
    const theme = useTheme()
    const strings = useI18n()

    return (
        <Box>
            <Box elevation="small" pad="small">
                <Editor
                    editorState={editorState}
                    editorStyle={{
                        height: theme.global?.size?.small,
                        
                    }}
                    onEditorStateChange={(editorState) => {
                        setEditorState(editorState)
                    }}
                    onBlur={() => {
                        const html = draftToHtml(
                            convertToRaw(editorState.getCurrentContent()),
                        )
                        handleChange(name)({ name, target: { value: html } })
                    }}
                    readOnly={disabled}
                />
                {showHTML && (
                    <Box margin={{ top: 'medium' }}>
                        <Text>
                            {draftToHtml(
                                convertToRaw(editorState.getCurrentContent()),
                            )}
                        </Text>
                    </Box>
                )}
                <Button
                    margin="small"
                    pad="xsmall"
                    primary
                    size="small"
                    alignSelf="end"
                    label={showHTML ? strings.hideHtml : strings.showHtml}
                    onClick={() => setShowHTML(!showHTML)}
                />
            </Box>
        </Box>
    )
}

type BRichTextEditorProps = {
    name: string
    handleChange: Function
    values: { [name: string]: any }
    disabled: boolean
}

export default BRichTextEditor
