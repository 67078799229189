import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RegistrationModel, UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

const insertRichiestaPrivacyRegistrationAPI =
    async (user: RegistrationModel, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        
        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Richiesta/InsertRichiestaPrivacy',{
                    CodiceUtente: user.CodiceUtente,
                    Privacy1: user.Privacy1,
                    Privacy1Desc: user.Privacy1 == '1' ? 'ACCETTO' : 'NONACCETTO',
                    Privacy2: user.Privacy2,
                    Privacy2Desc: user.Privacy2 == '1' ? 'ACCETTO' : 'NONACCETTO',
                    Privacy3: user.Privacy3,
                    Privacy3Desc: user.Privacy3 == '1' ? 'ACCETTO' : 'NONACCETTO',
            })
            if (res.status == HttpStates.OK) {

                return true
            }
            else {

                return false
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default insertRichiestaPrivacyRegistrationAPI
