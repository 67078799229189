import { IOCServices } from '..'

const downloadAllegatoReclamoAPI = async (
    fileId: number,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID
    try {
        const res = await rest.get(
            `/Reclami/DownloadAllegatoReclamo?idAllegato=${fileId}`,
            { responseType: 'blob' },
        )
        const data = res.data
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default downloadAllegatoReclamoAPI
