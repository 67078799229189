import { validateFatturaViaMailType } from '../../api/validateFatturaViaMailAPI'
import { AppAction } from '../../storeProvider'

const validateFatturaViaMail =
    (item: validateFatturaViaMailType): AppAction<any> => async (dispatch, getState, container) => {
        try {

            const setData = await container.api.validateFatturaViaMailAPI(item)

            return setData
        } catch (e:any) {

            throw e


        }
    }

export default validateFatturaViaMail
