import { Box, Heading } from 'grommet'
import useI18n from '../../hooks/useI18n'
import useSize from '../../hooks/useSize'
import { useAppSelector } from '../../services/storeProvider'
import { stringDate } from '../../utilities'
import { StatiPagamento } from '../../models/Enums'
import BillBox from '../BillBox'
import assets from '../../services/customerSpecs/assets/defaultAssets'
import { AllRoutes, RoutesEnum } from '../../Navigation'
import { useNavigate } from 'react-router-dom'

const Expiring = () => {
    const strings = useI18n()
    const size = useSize()
    const supply = useAppSelector((state) => state.supply)
    const invoice = useAppSelector((state) => state.invoice)
    const navigate = useNavigate()

    return (
        <Box
            style={{ position: 'relative' }}
            width={{
                width: size === 'large' ? '70%' : '100%',
            }}
            gap="small"
            pad={'small'}
            fill="vertical"
        >
            <Box direction="row" gap="small" align="baseline">
                <Heading size="small">{strings.inScadenza}</Heading>
            </Box>
            <Box
                gap="medium"
                pad={{ horizontal: 'small', bottom: 'small' }}
                height={'50vh'}
                overflow="auto"
            >
                {invoice.invoices === undefined ||
                invoice.invoices.filter(
                    (i) =>
                        new Date(stringDate(i.dataScadenza)) < new Date() &&
                        i.statoPagamento !== StatiPagamento.Pagata &&
                        (supply.selectedAddress
                            ? supply.selectedAddress.puntiGas.indexOf(
                                  i.puntoGas,
                              ) > -1
                            : true),
                ).length === 0 ? (
                    <Box direction="row" align="baseline">
                        <Heading level={4} size="small">
                            {strings.noExpiredInvoices}
                        </Heading>
                    </Box>
                ) : (
                    invoice.invoices
                        .filter(
                            (i) =>
                                new Date(stringDate(i.dataScadenza)) <
                                    new Date() &&
                                i.statoPagamento !== StatiPagamento.Pagata &&
                                (supply.selectedAddress
                                    ? supply.selectedAddress.puntiGas.indexOf(
                                          i.puntoGas,
                                      ) > -1
                                    : true),
                        )
                        .sort(function (a, b) {
                            if (
                                new Date(stringDate(a.dataScadenza)) >
                                new Date(stringDate(b.dataScadenza))
                            ) {
                                return 1
                            }
                            if (
                                new Date(stringDate(a.dataScadenza)) <
                                new Date(stringDate(b.dataScadenza))
                            ) {
                                return -1
                            }
                            return 0
                        })
                        .map((it, idx) => (
                            <BillBox
                                key={idx}
                                title={`${strings.fatturaNr} ${it.nrFattura}`}
                                description={`${strings.scadenza} ${stringDate(
                                    it.dataScadenza,
                                )}`}
                                isExpiring
                                price={`${it.importoPagamento.toFixed(2)} €`}
                                icon={
                                    it.tipoServizio === 'E' ? (
                                        <assets.EnergyIcon />
                                    ) : it.tipoServizio === 'GPL' ? (
                                        <assets.GplIcon />
                                    ) : (
                                        <assets.GasIcon />
                                    )
                                }
                                onClick={() =>
                                    navigate(
                                        `/${AllRoutes[RoutesEnum.BILL]}/${
                                            it.nrFattura
                                        }`,
                                    )
                                }
                            />
                        ))
                )}
            </Box>
        </Box>
    )
}
export default Expiring
