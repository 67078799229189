import { Box, BoxExtendedProps, Text, Tip } from 'grommet'
import BCard from './BCard'

const BRequest = ({ title, icon, bgcolor, ttip, ...rest }: BRequestProps) => {
    return (
        <BCard pad={'small'} {...rest} background={bgcolor?bgcolor:rest.background}>
            {ttip && ttip!==""?(
            <Tip content={<Box color="textWhite">{ttip}</Box>}>
                <Box direction="row" justify="between" align="center" style={{minHeight: "50px"}}>
                    <Box align="center" gap="medium" direction="row">
                        {icon}
                        <Box>
                            <Text size="small" weight="bold">
                                {title}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Tip>):(
                <Box direction="row" justify={rest.justify || "between"} align={rest.align || "center"} style={{minHeight: "50px"}}>
                    <Box align="center" gap="medium" direction="row">
                        {icon}
                        <Box>
                            <Text size="small" weight="bold">
                                {title}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            )
            }
        </BCard>
    )
}

type BRequestProps = {
    title: string
    icon: JSX.Element
    bgcolor?: string
    ttip?: string
} & BoxExtendedProps

export default BRequest
