import { TipiRichiesta } from '../../../models/Enums'
import { RequestModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_REQUESTS } from './types'

const loadRequests =
    (codiceUtente: string): AppAction<Array<RequestModel>> =>
    async (dispatch, getState, container) => {
        const roles = getState().user.loggedUser.roles
        try {
            const requests = await container.api.loadRequestsAPI(
                codiceUtente,
                roles,
            )

            const requestTypes = getState().request.requestTypes.map(
                (it) => it.idTipoRichiesta,
            )

            const filteredReq = requests.filter(
                (it: RequestModel) =>
                    requestTypes.indexOf(it.idTipoRichiesta) > -1 ||
                    it.idTipoRichiesta === TipiRichiesta.GestionePrivacy,
            )

            const supplies = getState().supply.supplies
            for (const req of filteredReq) {
                //dettaglio
                if (req.puntoGas !== '0') {
                    let det = supplies.find(
                        (it) => it.puntoGas === req.puntoGas,
                    )
                    if (!det) {
                        det = await container.api.loadFornituraDetailsAPI(
                            codiceUtente,
                            req.puntoGas,
                            roles,
                        )
                    }
                    req.tipoServizio = det?.tipoServizio ?? ''
                    req.comune = det?.comune ?? ''
                    req.tipoVia = det?.tipoVia ?? ''
                    req.via = det?.via ?? ''
                    req.numeroCivico = det?.numeroCivico ?? ''
                    req.cap = det?.cap ?? ''
                    req.provincia = det?.provincia ?? ''
                }
            }

            dispatch({ type: SET_REQUESTS, payload: filteredReq })

            return filteredReq
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadRequests
