const GasIcon = ({
    ss,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string, ss?: string }) => {
    return (
        <svg
            width={ss??"50"}
            height={ss??"50"}
            viewBox="0 0 260 260"
            fill="none"
            {...rest}>
            <path
                d="M99.9797 80.1033C92.554 82.1285 50.7001 136.134 68.2519 176.638C76.3526 195.99 107.811 227.538 174.237 204.316C262.671 167.187 189.796 79.8044 181.663 82.1283C174.67 84.1264 180.129 98.7719 178.288 109.132C178.288 118.582 167.226 125.893 161.411 109.132C148.585 61.8768 117.532 42.2994 112.131 49.7251C106.731 57.1508 124.282 109.132 110.106 109.132C95.8015 109.132 107.405 78.0781 99.9797 80.1033Z"
                fill="url(#paint0_linear_175_8507)"
            />
            <path
                d="M163.86 48.266C162.62 49.243 162.112 53.3287 164.21 62.1855C165.816 68.966 169.919 73.0577 175.947 69.5584C187.548 63.1386 166.36 46.2972 163.86 48.266Z"
                fill="url(#paint1_linear_175_8507)"
            />
            <path
                d="M58.1964 133.807C59.7066 133.347 61.6608 129.723 62.9168 120.708C63.8782 113.807 61.5379 108.506 54.6513 109.581C41.5122 111.358 55.1528 134.736 58.1964 133.807Z"
                fill="url(#paint2_linear_175_8507)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_175_8507"
                    x1="178.963"
                    y1="70.6525"
                    x2="89.0001"
                    y2="237"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EC873F" />
                    <stop offset="1" stopColor="#CE4814" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_175_8507"
                    x1="176.806"
                    y1="38.5432"
                    x2="178.37"
                    y2="68.0819"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EC873F" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_175_8507"
                    x1="42.6061"
                    y1="138.175"
                    x2="51.8581"
                    y2="110.079"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="1" stopColor="#DD521C" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GasIcon
