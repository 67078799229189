import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import useI18n from '../../hooks/useI18n'
import { Box, Button, Spinner } from 'grommet'
import BSelect from '../BSelect'
import { useEffect, useState } from 'react'
import useAppQuery from '../../hooks/useAppQuery'
import BDateInput from '../BDateInput'
import { ExtendedRequestModel, RequestEditingModel } from '../../models/models'
import BTextArea from '../BTextArea'
import loadAgentsAndOperators from '../../services/store/actions/loadAgentsAndOperators'
import BTimeInput from '../BTimeInput'
import {
    formatDateFns,
    formatTimeFns,
    getRequestStatusList,
} from '../../utilities'
import BTextInput from '../BTextInput'

type SelectNumberType = { label: string; value: number }[]

const EditRequestForm = ({
    onSubmit,
    isLoading = false,
    disabled = false,
    initialValues,
}: EditRequestFormProps) => {
    const strings = useI18n()
    const dataRichiesta = new Date(
        `${initialValues.dataRichiesta2}T${initialValues.oraRichiesta}z`,
    )
    const [availableOperators, setAvailableOperators] =
        useState<SelectNumberType>([])
    const [loadingOperators, queryOperators] = useAppQuery(
        loadAgentsAndOperators,
    )

    const stateRequestList = useMemo(
        () => getRequestStatusList(strings),
        [strings],
    )

    const EditRequestSchema = useMemo(
        () =>
            Yup.object().shape({
                NoteOperatore: Yup.string().nullable(),
                Stato: Yup.number(),
                currentTime: Yup.string(),
                currentDate: Yup.string().when('currentTime', {
                    is: (value: string) => !!value,
                    then: Yup.string().required(strings.mandatoryField),
                }),
                IDUtenteIncaricato: Yup.string(),
                IDUtenteUltimaModifica: Yup.number(),
            }),
        [strings.mandatoryField],
    )

    const loadOptionsData = useCallback(async () => {
        const operatorsAndAgents = await queryOperators()
        setAvailableOperators(
            operatorsAndAgents.map((it) => ({
                label: it.nominativo,
                value: it.idUtente,
            })),
        )
    }, [queryOperators])

    useEffect(() => {
        loadOptionsData()
    }, [loadOptionsData])

    return (
        <Formik
            initialValues={{
                IDRichiesta: initialValues.idRichiesta,
                IDTipoRichiesta: initialValues.idTipoRichiesta,
                NoteOperatore: initialValues.noteOperatore,
                Stato: initialValues.idStatoRichiesta,
                currentDate: initialValues.dataRichiesta2
                    ? formatDateFns(dataRichiesta)
                    : undefined,
                currentTime: formatTimeFns(dataRichiesta),
                IDUtenteIncaricato: initialValues.idUtenteIncaricato,
                IDUtenteUltimaModifica: initialValues.idUtenteUltimaModifica,
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={EditRequestSchema}
            onSubmit={(values) => {
                const { currentTime, currentDate, ...rest } = values
                if (!currentDate) {
                    onSubmit({ ...rest })
                    return
                }
                const time = currentTime ? ` ${currentTime}` : ''
                const DataRichiesta2 = new Date(`${currentDate}${time}`)
                onSubmit({ ...rest, DataRichiesta2 })
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                return (
                    <Box flex="grow">
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.statoRichiesta}
                                placeholder={strings.statoRichiesta}
                                name="Stato"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={stateRequestList}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                                disabled={disabled}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.assignee}
                                placeholder={strings.assignee}
                                name="IDUtenteIncaricato"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={availableOperators}
                                icon={loadingOperators ? <Spinner /> : true}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                                disabled={disabled}
                            />
                            {!!initialValues.idUtenteIncaricante && (
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={{
                                        IDUtenteIncaricante:
                                            availableOperators.find(
                                                (it) =>
                                                    it.value ===
                                                    initialValues.idUtenteIncaricante,
                                            )?.label,
                                    }}
                                    errors={errors}
                                    placeholder={strings.assignedFrom}
                                    label={strings.assignedFrom}
                                    name="IDUtenteIncaricante"
                                    disabled
                                />
                            )}
                        </Box>
                        <Box direction="row" flex align="center">
                            <BDateInput
                                containerProps={{
                                    flex: true,
                                    margin: { right: 'small' },
                                }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                label={strings.nextContactDay}
                                name="currentDate"
                                format="dd/mm/yyyy"
                                disabled={disabled}
                            />
                            <BTimeInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                label={strings.nextContactTime}
                                name="currentTime"
                                disabled={disabled}
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextArea
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.notes}
                                label={strings.notes}
                                name="NoteOperatore"
                                disabled={disabled}
                            />
                        </Box>
                        <Box margin={{ vertical: 'small' }}>
                            <Button
                                disabled={isLoading || disabled}
                                onClick={async () => {
                                    try {
                                        const fields = await validateForm()
                                        const errors = Object.keys(fields)
                                        if (errors.length > 0) {
                                            return
                                        }
                                        handleSubmit()
                                    } catch (e) {}
                                }}
                                icon={isLoading ? <Spinner /> : <span />}
                                label={strings.send}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

type EditRequestFormProps = {
    onSubmit: (values: RequestEditingModel) => void
    initialValues: ExtendedRequestModel
    isLoading: boolean
    disabled?: boolean
}

export default EditRequestForm
