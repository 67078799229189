import { useMemo } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import useI18n from '../../hooks/useI18n'
import BTextInput from '../BTextInput'
import { Box, Button, Heading, Keyboard, Spinner } from 'grommet'
import { UsersFilters } from '../../services/api/loadUsersAPI'

const UsersFiltersForm = ({
    onSubmit,
    isLoading = false,
    initialValues,
}: UsersFiltersFormProps) => {
    const strings = useI18n()
    const RequestClaimsSchema = useMemo(
        () =>
            Yup.object().shape({
                codiceUtente: Yup.string(),
                puntoGas: Yup.string(),
                nominativo: Yup.string(),
                codiceFiscale: Yup.string(),
                piva: Yup.string(),
                podPdr: Yup.string(),
            }),
        [],
    )

    return (
        <>
            <Heading size="small">{strings.clientFilters}</Heading>
            <Formik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={RequestClaimsSchema}
                onSubmit={onSubmit}
                initialValues={
                    initialValues ?? {
                        codiceUtente: '',
                        puntoGas: '',
                        nominativo: '',
                        codiceFiscale: '',
                        piva: '',
                        podPdr: '',
                    }
                }
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    validateForm,
                }) => {
                    return (
                        <Keyboard onEnter={() => onSubmit(values)}>
                            <Box gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={
                                        strings.codiceUtentePlaceHolder
                                    }
                                    label={strings.codiceUtente}
                                    name="codiceUtente"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.codiceUtenza}
                                    label={strings.codiceUtenza}
                                    name="puntoGas"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.nominativo}
                                    label={strings.nominativo}
                                    name="nominativo"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.codiceFiscale}
                                    label={strings.codiceFiscale}
                                    name="codiceFiscale"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.partitaIVA}
                                    label={strings.partitaIVA}
                                    name="piva"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.pod_pdr}
                                    label={strings.pod_pdr}
                                    name="podpdr"
                                />
                                <Box margin={{ vertical: 'small' }}>
                                    <Button
                                        disabled={isLoading}
                                        onClick={async () => {
                                            try {
                                                const fields =
                                                    await validateForm()
                                                const errors =
                                                    Object.keys(fields)
                                                if (errors.length > 0) {
                                                    return
                                                }
                                                handleSubmit()
                                            } catch (e) {}
                                        }}
                                        icon={
                                            isLoading ? <Spinner /> : <span />
                                        }
                                        label={strings.sendFilters}
                                        primary
                                    />
                                </Box>
                            </Box>
                        </Keyboard>
                    )
                }}
            </Formik>
        </>
    )
}

type UsersFiltersFormProps = {
    onSubmit: (values: UsersFilters) => any
    isLoading: boolean
    initialValues?: UsersFilters
}

export default UsersFiltersForm
