import { useEffect, useState } from 'react'
import { Box, Text, BoxProps, MaskedInputProps, MaskedInput } from 'grommet'
import get from 'lodash.get'
import BErrorMessage from './BErrorMessage'

type BTimeInputProps = {
    handleChange: Function
    handleBlur?: Function
    errors: {}
    name: string
    renderIcon?: () => JSX.Element
    label?: string
    values: {}
    containerProps?: BoxProps
    mandatory?: boolean
    fast?: boolean
    disabled?: boolean
    disabledOutline?: boolean
} & MaskedInputProps

const BTimeInput = ({
    handleChange,
    handleBlur,
    errors,
    name,
    renderIcon,
    label,
    values,
    containerProps,
    mandatory,
    fast,
    disabled,
    disabledOutline,
    ...rest
}: BTimeInputProps) => {
    const initialValue = get(values, name) ?? ''
    const [isFocused, setFocused] = useState(false)
    const [value, setValue] = useState(initialValue)
    const textValue = values && fast ? value : initialValue
    useEffect(() => {
        if (fast) {
            setValue(initialValue)
        }
    }, [fast, initialValue])
    return (
        <Box justify="start" {...containerProps}>
            <Box direction="row">
                {label && <Text color={'primary_color'}>{label}</Text>}
                {mandatory && (
                    <Text weight="bold" size="small" margin={{ left: '5px' }}>
                        *
                    </Text>
                )}
            </Box>
            <Box
                flex
                direction="row"
                align="center"
                round={'8px'}
                border={
                    disabled && !disabledOutline
                        ? undefined
                        : { color: isFocused ? 'brand' : 'control-border' }
                }
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}>
                {disabled && !disabledOutline ? (
                    <Text margin={{ top: '20px' }} weight="bold" size="small">
                        {textValue}
                    </Text>
                ) : (
                    <MaskedInput
                        {...rest}
                        disabled={disabled}
                        onChange={(e) => {
                            if (handleChange && !fast) {
                                handleChange(name)(e)
                            }
                            if (fast) {
                                setValue(e.currentTarget.value)
                            }
                        }}
                        onBlur={(e) => {
                            if (handleBlur) {
                                handleBlur(name)(e)
                            }
                            if (fast) {
                                handleChange(name)(e)
                            }
                        }}
                        plain
                        value={textValue}
                        style={{ borderRadius: '8px 0 0 8px', padding: '12px' }}
                        mask={[
                            {
                                length: [2],
                                options: Array.from(
                                    { length: 23 },
                                    (_, k) => String(k + 1).padStart(2,'0'),
                                ),
                                regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                                placeholder: 'hh',
                            },
                            { fixed: ':' },
                            {
                                length: 2,
                                options: Array.from(
                                    { length: 59 },
                                    (_, k) => String(k + 1).padStart(2,'0'),
                                ),
                                regexp: /^[0-5][0-9]$|^[0-9]$/,
                                placeholder: 'mm',
                            },
                        ]}
                    />
                )}
                {!!renderIcon && !disabled && renderIcon()}
            </Box>
            <BErrorMessage name={name} errors={errors} />
        </Box>
    )
}

export default BTimeInput
