import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type setAutologinType = {
    Username: string,
    Password: string,
    IDAzienda: number
}

const autologinAPI =
    async (item: setAutologinType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Utente/Autologin',{
                    Username: item.Username,
                    Password: item.Password,
                    IDAzienda: item.IDAzienda
            })
            if (res.status == HttpStates.OK) {
                
                return res.data.content
            }
            else {

                return undefined
            }          
        } catch (e) {
            return undefined
        }
    }

export default autologinAPI
