import { useEffect, useState, useCallback } from 'react'
import { Box, Text } from 'grommet'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import { StatiRichiesta } from '../models/Enums'
import { ClaimResponseModel, RequestModel } from '../models/models'
import {
    editReclamoOperatore,
    loadDettaglioReclamo,
} from '../services/store/actions'
import EditReclamoOperatorForm from './forms/EditReclamoOperatoreForm'
import { DettaglioReclamoOperatore } from '../services/api/loadDettaglioReclamoOperatoreAPI'
import ReclamoResumeBox from './ReclamoResumeBox'
import loadDettaglioReclamoOperatore from '../services/store/actions/loadDettaglioReclamoOperatore'

const ReclamoRequestDetails = ({
    onBackPress,
    request,
    backButton,
    operatorForm,
}: ReclamoRequestDetailsProps) => {
    const [isSaving, mutateReclamoOperatore] = useAppQuery(editReclamoOperatore)
    const [isLoadingLightClaim, queryLightClaim] =
        useAppQuery(loadDettaglioReclamo)
    const [isLoadingFormClaim, queryFormClaim] = useAppQuery(
        loadDettaglioReclamoOperatore,
    )
    const [lightClaim, setLightClaim] = useState<ClaimResponseModel>()
    const [formClaim, setFormClaim] = useState<DettaglioReclamoOperatore>()
    const strings = useI18n()
    const isLoading = isLoadingFormClaim && isLoadingLightClaim

    const loadShortClaim = useCallback(async () => {
        if (!request?.idRichiesta || !request?.idReclamo) {
            return
        }
        const shortClaim = await queryLightClaim({
            idRichiesta: request?.idRichiesta,
            idReclamo: request?.idReclamo,
        })
        setLightClaim(shortClaim)
        return shortClaim
    }, [queryLightClaim, request?.idReclamo, request?.idRichiesta])

    const loadFormClaim = useCallback(async () => {
        if (!request?.idReclamo) {
            return
        }
        const longClaim = await queryFormClaim({
            idReclamo: request?.idReclamo,
        })
        setFormClaim(longClaim)
        return longClaim
    }, [queryFormClaim, request?.idReclamo])

    const loadInitialData = useCallback(async () => {
        loadShortClaim()
        loadFormClaim()
    }, [loadFormClaim, loadShortClaim])

    useEffect(() => {
        loadInitialData()
    }, [loadInitialData])

    return (
        <Box
            id="claim-operator-container"
            flex="grow"
            background={'background-front'}
        >
            <ReclamoResumeBox
                onBackPress={onBackPress}
                backButton={backButton}
                request={request}
                claim={lightClaim}
                isLoading={isLoading}
                showAttachmentsSection={!operatorForm}
            />
            {operatorForm && request && (
                <Box gap="medium" pad="medium">
                    <Text weight="bold" size="xlarge">
                        {strings.editClaim}
                    </Text>
                    <EditReclamoOperatorForm
                        initialValues={formClaim}
                        isLoading={isSaving}
                        onSubmit={async (values: DettaglioReclamoOperatore) => {
                            try {
                                const newFormClaim =
                                    await mutateReclamoOperatore(values)
                                setFormClaim(newFormClaim)
                                loadShortClaim()
                                document
                                    .getElementById('claim-operator-container')
                                    ?.scrollIntoView({ behavior: 'smooth' })
                            } catch (e) {
                                //
                            }
                        }}
                        disabled={
                            request.idStatoRichiesta === StatiRichiesta.Evasa
                        }
                    />
                </Box>
            )}
        </Box>
    )
}

export default ReclamoRequestDetails

type ReclamoRequestDetailsProps = {
    onBackPress: () => void
    request?: RequestModel
    backButton?: boolean
    operatorForm?: boolean
}
