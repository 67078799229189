import {
    SET_NOTIFICATION,
    START_LOADING,
    STOP_LOADING,
    SET_MODAL,
} from '../actions/types'

/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE: {
    loading: number,
    language: string,
    notification: {
        type: 'error'|'warn'|'info'
        message: string,
        show: boolean
    },
    modal: {
        type: 'error'|'warn'|'info'
        message: string,
        show: boolean
    },
} = {
    loading: 0,
    language: 'it',
    notification: {
        type: 'error',
        message: '',
        show: false,
    },
    modal: {
        type: 'error',
        message: '',
        show: false,
    },
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: {
                    ...payload,
                },
            }
        case SET_MODAL:
            return {
                ...state,
                modal: {
                    ...payload,
                },
            }
        case START_LOADING:
            return {
                ...state,
                loading: state.loading + 1,
            }
        case STOP_LOADING:
            return {
                ...state,
                loading: state.loading <= 0 ? 0 : state.loading - 1,
            }
        default: {
            return state
        }
    }
}
