import { Box, Text } from 'grommet'
import useAssets from '../hooks/useAssets'
import useTheme from '../hooks/useTheme'

import BBackButton from './BBackButton'
import BCard from './BCard'
import BCloseButton from './BCloseButton'
import BRoundButton from './BRoundButton'

const InfoBox = ({
    onDonePress,
    onBackPress,
    title,
    content,
    hideImg
}: InfoBoxProps) => {
    const { global } = useTheme()
    const cAssets = useAssets()
    return (
        <BCard direction="row">
            <>
                <Box>
                    <BCloseButton size="medium" onClick={() => onBackPress()} />
                </Box>
                <Box
                    margin={{ horizontal: 'medium' }}
                    flex
                    align="center"
                    gap="large">
                    <Text size="large" weight={'bold'}>
                        {title}
                    </Text>
                    <Box flex justify="center">
                        {content.split('\n').map((it, index) => (
                            it.startsWith('$$i')?<Text style={{marginLeft: "20px"}} key={index}>{it.replace('$$i','')}</Text>:<Text key={index}>{it.replace('$$i','')}</Text>
                        ))}
                        {hideImg === undefined || hideImg===false?<Box alignSelf="center">
                            <cAssets.BollettaIllustration
                                width={'100%'}
                                height={'100%'}
                                color={global?.colors?.brand?.toString()}
                            />
                        </Box>:undefined}
                    </Box>

                </Box>
            </>
        </BCard>
    )
}

type InfoBoxProps = {
    onDonePress: () => void
    onBackPress: () => void
    title: string
    content: string
    hideImg?: boolean
}

export default InfoBox
