import { TipiPagina } from '../../../models/Enums'
import { InvoiceFiltersModel, RequestDetailsModel } from '../../../models/models'
import { formatDate } from '../../../utilities'
import { setAutologinType } from '../../api/autologinAPI'
import { checkAutologinTokenValidityType } from '../../api/checkAutologinTokenValidityAPI'
import { AppAction, AppAsyncAction, useAppSelector } from '../../storeProvider'
import setNotification from './setNotification'

const autologin: AppAsyncAction<any> =
    (item: setAutologinType) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)

        try {
            const data = await container.api.autologinAPI(item)

            if (! data) {
                throw Error()
            }

            return data
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.loginFailedLabel,
                    show: true,
                }),
            )
            throw e
        }
    }

export default autologin
