import { UserModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

type UserType = {
    userCode: string
}

const getUserAdmin =
    ({ userCode }: UserType): AppAction<UserModel> =>
    async (dispatch, _, container) => {
        try {
            const users = await container.api.getUserAdminAPI(userCode)
            return users
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getUserAdmin
