import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type editPasswordType = {
    Password: string,
    RepeatPassword: string,
    Token: string
}

const editPasswordAPI =
    async (editItem: editPasswordType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        
        const restID = container.restID
        const restAPI = container.rest

        try {

            const res = await restID.post(
                '/Utente/SetPasswordGuest',{
                    Password: editItem.Password,
                    RepeatPassword: editItem.RepeatPassword,
                    Token: editItem.Token
            })
            if (res.status == HttpStates.OK) {

                return true
            }
            else {

                return false
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default editPasswordAPI
