import {
    Box,
    Button,
    Text,
    DropButton,
    DropButtonProps,
} from 'grommet'
import { useNavigate, useLocation } from 'react-router'
import {
    logout,
} from '../services/store/actions'
import { useAppDispatch } from '../services/storeProvider'
import useTheme, { useDimension } from '../hooks/useTheme'
import { Key, Logout, User } from 'grommet-icons'
import BSeparator from './BSeparator'
import useSize from '../hooks/useSize'

const BProfileButton = ({ user }: BProfileButtonProps) => {
    const dispatch = useAppDispatch()
    const size = useSize()
    const dim = useDimension(size)
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()

    const state = location.state as { path?: string }
    const rendermenuButton = (text: string, Icon: JSX.Element, onClick:()=> any) => {
        return (
            <Box direction="row" justify="center" align="center">
                <Button
                    plain
                    //color={theme.global?.colors?.menu?.toString()}
                    onClick={onClick}>
                    {({ disabled, hover, focus }) => (
                        <Box
                            flex
                            direction="row"
                            round="xsmall"
                            pad="small"
                            gap="small"
                            background={hover ? 'brand' : undefined}
                            justify="center"
                            align="center">
                            {Icon}
                            <Text>{text}</Text>
                        </Box>
                    )}
                </Button>
            </Box>
        )
    }

    const dropAlign: DropButtonProps['dropAlign'] = { top: 'bottom', right: 'right' }

    return (
        // <Box
        //     direction="row"
        //     justify="end"
        //     align="center"
        //     gap="small"
        //     pad="small">

        //     <Box
        //     onClick={async () => {
        //         try {

        //             navigate(
        //                 state?.path ?? '/user',
        //             )
        //         } catch (e) {
        //             console.log(e)
        //         }
        //     }}
        //     direction="row"
        //     justify="end"
        //     align="center"
        //     gap="small"
        //     pad="small"
        //     background={theme.global?.colors?.['background.front']?.toString()}
        //     >

        //         <Text
        //             weight={'bold'}
        //             size={size}
        //             style={{ textTransform: 'uppercase' }}
        //             margin={{ right: '10px' }}
        //             color={theme.global?.colors?.menu?.toString()}
        //         >
        //         {user}
        //         </Text>
        //         <Box
        //             direction="row">

        //             <User color={theme.global?.colors?.menu?.toString()} size={size} />

        //             <FormDown color={theme.global?.colors?.menu?.toString()} size={size} />

        //         </Box>
        //     </Box>
        // </Box>
        <Box direction="row" justify="end" gap="small">
            <User color={theme.global?.colors?.brand?.toString()} size="large" />
            <DropButton
                color={theme.global?.colors?.brand?.toString()}
                label={user}
                dropAlign={dropAlign}
                dropContent={
                    <Box direction="column">
                        {rendermenuButton('Vedi Profilo', <User />, ()=>{navigate('/user')})}
                        <BSeparator size="2px" margin={{ vertical: '5px' }} />
                        {rendermenuButton('Cambio Password', <Key />, ()=>{navigate('/cambioPassword')})}
                        {rendermenuButton('Logout', <Logout />, async () => {await dispatch(logout())})}

                    </Box>
                }></DropButton>
        </Box>
    )
}

type BProfileButtonProps = {
    user: string
}

export default BProfileButton
