import { AppAction } from "../../storeProvider"
import { getStringFromCode } from '../../../utilities'
import setNotification from './setNotification'
import { RIDModel, SignedModule, SupplyModel } from "../../../models/models"

const sendSignedModule =
(module: SignedModule): AppAction<any> => async (dispatch, getState, container) => {
    const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {

            if (module.file !== undefined && module.file.size < module.limit ) {
            const editData = await container.api.sendSignedModuleAPI(module)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return editData
            }
            else {
                return false
            }
        } catch (e: any) {

            const index = (e.response?.data?.value ?? e.message).indexOf('|')
            const error = index >= 0?(e.response?.data?.value ?? e.message).substring(index+1):(e.response?.data?.value ?? e.message)

            const errorDesc = getStringFromCode(error,getState().main.stringhe,i18n,lan)
            dispatch(
                setNotification({
                    type: 'error',
                    message: errorDesc,
                    show: true,
                }),
            )
            throw e
        }
}

export default sendSignedModule