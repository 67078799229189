import get from 'lodash.get'
import useTheme from '../hooks/useTheme'
const FornitureIcon = ({
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string }) => {
    const { global } = useTheme()
    const currentColor = get(global?.colors, color || '')
    return (
        <svg width="20" height="20" viewBox="0 0 19 20" fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9088 0C16.0528 0 18.1648 2.153 18.1648 5.357V14.553C18.1648 17.785 16.1178 19.887 12.9498 19.907L5.25676 19.91C2.11276 19.91 -0.000244141 17.757 -0.000244141 14.553V5.357C-0.000244141 2.124 2.04676 0.023 5.21476 0.004L12.9078 0H12.9088ZM12.9088 1.5L5.21976 1.504C2.89176 1.518 1.49976 2.958 1.49976 5.357V14.553C1.49976 16.968 2.90476 18.41 5.25576 18.41L12.9448 18.407C15.2728 18.393 16.6648 16.951 16.6648 14.553V5.357C16.6648 2.942 15.2608 1.5 12.9088 1.5ZM12.7159 13.4737C13.1299 13.4737 13.4659 13.8097 13.4659 14.2237C13.4659 14.6377 13.1299 14.9737 12.7159 14.9737H5.49586C5.08186 14.9737 4.74586 14.6377 4.74586 14.2237C4.74586 13.8097 5.08186 13.4737 5.49586 13.4737H12.7159ZM12.7159 9.2872C13.1299 9.2872 13.4659 9.6232 13.4659 10.0372C13.4659 10.4512 13.1299 10.7872 12.7159 10.7872H5.49586C5.08186 10.7872 4.74586 10.4512 4.74586 10.0372C4.74586 9.6232 5.08186 9.2872 5.49586 9.2872H12.7159ZM8.25056 5.1104C8.66456 5.1104 9.00056 5.4464 9.00056 5.8604C9.00056 6.2744 8.66456 6.6104 8.25056 6.6104H5.49556C5.08156 6.6104 4.74556 6.2744 4.74556 5.8604C4.74556 5.4464 5.08156 5.1104 5.49556 5.1104H8.25056Z"
                fill={currentColor?.toString() ?? '#303972'}
            />
        </svg>
    )
}

export default FornitureIcon
