import { Roles, TipiInformazioni } from '../../../models/Enums'
import {
    CityProvinceModel,
    CompanyInfoModel,
    LoggedUserModel,
    ParamModel,
    StringModel,
    TypeRequestModel,
} from '../../../models/models'
import {
    SET_ABILITAZIONE,
    SET_PROVINCES_CITY,
    SET_STRINGHE,
    SET_TIPOINVIO,
    SET_TP_PRIVACY1,
    SET_TP_PRIVACY2,
    SET_TP_PRIVACY3,
    SET_TP_REGISTRA1,
    SET_TP_REGISTRA2,
    SET_TP_REGISTRA3,
    SET_TP_PRIVACY1SHORT,
    SET_TP_PRIVACY2SHORT,
    SET_TP_PRIVACY3SHORT,
    SET_TP_COOKIE,
    SET_SUPPORT_REQUESTS,
    SET_PUBLIC_PARAMS,
    SET_COMPANY_INFO,
    SET_REDIRECT_LOADING,
    SET_REMINDER_SEEN,
    SET_LOGGED_USER,
    SET_TP_OFFERCHANGE,
    SET_READ_RECONTRACTING,
} from '../actions/types'

/* eslint-disable import/no-anonymous-default-export */
export const INITIAL_STATE: {
    FVM_abilitazione: string
    FVM_tipoinvio: string
    TP_Privacy1: string
    TP_Privacy2: string
    TP_Privacy3: string
    TP_Privacy1Short: string
    TP_Privacy2Short: string
    TP_Privacy3Short: string
    TP_Registra1: string
    TP_Registra2: string
    TP_Registra3: string
    TP_Cookie: string
    TP_OfferChange: string
    stringhe: Array<StringModel>
    provincesCities: Array<CityProvinceModel>
    typeRequests: Array<TypeRequestModel>
    publicParam: Array<ParamModel>
    companyInfo: { [key in TipiInformazioni]?: CompanyInfoModel }
    redirectLoading: boolean
    readingRecontracting : boolean
    reminderSeen: boolean
    loggedUser: LoggedUserModel
} = {
    FVM_abilitazione: 'true',
    FVM_tipoinvio: '0',
    TP_Privacy1: '',
    TP_Privacy2: '',
    TP_Privacy3: '',
    TP_Privacy1Short: '',
    TP_Privacy2Short: '',
    TP_Privacy3Short: '',
    TP_Registra1: '',
    TP_Registra2: '',
    TP_Registra3: '',
    TP_OfferChange: '',
    TP_Cookie: '',
    stringhe: [],
    provincesCities: [],
    typeRequests: [],
    publicParam: [],
    companyInfo: {},
    redirectLoading: false,
    reminderSeen: false,
    readingRecontracting: false,
    loggedUser: {
        username: '',
        email: '',
        userId: '',
        id: '',
        name: '',
        roles: [Roles.NON_LOGGATO],
    },
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_ABILITAZIONE:
            return {
                ...state,
                FVM_abilitazione: payload,
            }
        case SET_TIPOINVIO:
            return {
                ...state,
                FVM_tipoinvio: payload,
            }
        case SET_TP_PRIVACY1:
            return {
                ...state,
                TP_Privacy1: payload,
            }
        case SET_TP_PRIVACY2:
            return {
                ...state,
                TP_Privacy2: payload,
            }
        case SET_TP_PRIVACY3:
            return {
                ...state,
                TP_Privacy3: payload,
            }
        case SET_TP_PRIVACY1SHORT:
            return {
                ...state,
                TP_Privacy1Short: payload,
            }
        case SET_TP_PRIVACY2SHORT:
            return {
                ...state,
                TP_Privacy2Short: payload,
            }
        case SET_TP_PRIVACY3SHORT:
            return {
                ...state,
                TP_Privacy3Short: payload,
            }
        case SET_TP_REGISTRA1:
            return {
                ...state,
                TP_Registra1: payload,
            }
        case SET_TP_REGISTRA2:
            return {
                ...state,
                TP_Registra2: payload,
            }
        case SET_TP_REGISTRA3:
            return {
                ...state,
                TP_Registra3: payload,
            }
        case SET_TP_COOKIE:
            return {
                ...state,
                TP_Cookie: payload,
            }
        case SET_STRINGHE:
            return {
                ...state,
                stringhe: payload,
            }
        case SET_PROVINCES_CITY:
            return {
                ...state,
                provincesCities: payload,
            }
        case SET_SUPPORT_REQUESTS:
            return {
                ...state,
                typeRequests: payload,
            }
        case SET_PUBLIC_PARAMS:
            return {
                ...state,
                publicParam: payload,
            }
        case SET_COMPANY_INFO:
            return {
                ...state,
                companyInfo: payload,
            }
        case SET_REDIRECT_LOADING:
            return {
                ...state,
                redirectLoading: payload,
            }
        case SET_REMINDER_SEEN:
            return {
                ...state,
                reminderSeen: payload,
            }
        case SET_LOGGED_USER:
            return {
                ...state,
                loggedUser: payload as LoggedUserModel,
            }
        case SET_TP_OFFERCHANGE:
            return {
                ...state,
                TP_OfferChange: payload,
            }          
        case SET_READ_RECONTRACTING:
            return {
                ...state,
                readingRecontracting: payload,
            }
        default: {
            return state
        }
    }
}
