import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_TOKENROP,
    SET_STRINGHE,
    // SET_USERNAME,
    SET_REQUESTTYPES,
    LOGIN,
    LOGIN_CLIENT,
} from './types'
import { formatDate, isOperatorOrAgent } from '../../../utilities'
import { LoggedUserModel } from '../../../models/models'

const login: AppAsyncAction<any> =
    (values: { username: string; password: string }) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const login = await container.api.loginAPI(values)
            dispatch({
                type: SET_TOKENROP,
                payload: login.access_token,
            })

            const stringhe = await container.api.getStringheAPI()
            dispatch({ type: SET_STRINGHE, payload: stringhe })

            const reqtypes = await container.api.getTipiRichiestaAbilitatiAPI()
            dispatch({
                type: SET_REQUESTTYPES,
                payload: reqtypes,
            })

            container.jwt.saveTokenROP(login.access_token)
            const loggedUser : LoggedUserModel = await container.api.getLoggedUserAPI()

            if (! loggedUser) {
                throw Error
            }

            // dispatch({ type: SET_USERNAME, payload: loggedUser.username })

            if (isOperatorOrAgent(loggedUser.roles)) {
                dispatch({
                    type: LOGIN,
                    payload: loggedUser,
                })
                return
            }

            const { value : user } = await container.api.getAnagraficaUtenteAPI(loggedUser.userId)

            user.dataNascitaAPI = user.dataNascita

            const indirizzo = `${user.tipoViaResidenza} ${user.viaResidenza} ${user.nrCivicoResidenza} ${user.comuneResidenza} ${user.provinciaResidenza}`
            if (indirizzo.length > 0) {
                user.indirizzo = indirizzo
            }

            if (user.dataNascita?.length > 0) {
                const date = new Date(user.dataNascita)
                let dataNascita = formatDate(date)

                user.dataNascita = dataNascita
            }

            dispatch({
                type: LOGIN_CLIENT,
                payload: {
                    loggedUser,
                    clientInfo: user,
                }
            })

        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.loginFailedLabel,
                    show: true,
                }),
            )
            throw e
        }
    }

export default login
