import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { DettaglioReclamoOperatore } from '../../api/loadDettaglioReclamoOperatoreAPI'

const editReclamoOperatore =
    (claim: DettaglioReclamoOperatore): AppAction<DettaglioReclamoOperatore> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const newClaim = await container.api.editReclamoOperatoreAPI(claim)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return newClaim
        } catch {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.errorMessage,
                    show: true,
                }),
            )
            return claim
        }
    }

export default editReclamoOperatore
