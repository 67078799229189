import { Box, BoxExtendedProps, Text } from 'grommet'
import BCard from './BCard'
import PieChart from './PieChart'

const AddressBox = ({
    title,
    description1,
    description2,
    value,
    active,
    ...rest
}: AddressBoxProps) => {
    return (
        <BCard flex={false} {...rest} border={active?({ color: 'text', size: 'medium' }):undefined}>
            <Box
                align="center"
                justify="between"
                direction="row"
                pad={{ left: 'small' }}>
                <Box>
                    <Text weight="bold">{title}</Text>
                    <Text size="xsmall" weight="lighter" truncate="tip">
                        {description1}
                    </Text>
                    <Text size="xsmall" weight="lighter" truncate="tip">
                        {description2}
                    </Text>
                </Box>
                <PieChart
                    color="white"
                    value={value}
                    width="80px"
                    height="40px"
                    pad={{top: '4px'}}
                    textProps={{ size: 'xsmall' }}
                />
            </Box>
        </BCard>
    )
}

type AddressBoxProps = {
    title: string
    description1: string
    description2: string
    active: boolean
    value: number
} & BoxExtendedProps

export default AddressBox
