import { MotiviAnnullamentoReclamo } from '../../api/getMotiviAnnullamentoReclamoAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getMotiviAnnullamentoReclamo =
    (): AppAction<MotiviAnnullamentoReclamo> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const motiviAnnullamento =
                await container.api.getMotiviAnnullamentoReclamoAPI()

            return motiviAnnullamento
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.errorMessage,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getMotiviAnnullamentoReclamo
