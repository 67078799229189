import { IOCServices } from '..'
import { HttpStates, TipiInformazioni } from '../../models/Enums'
import { CompanyInfoModel } from '../../models/models'
import { getCompanyInformation } from '../../utilities'

const getCompanyInformationAPI =
    async (container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const rest = container.restID
        try {
            const companyInformation = getCompanyInformation()
            const companyId = companyInformation.id
            const res = await rest.get(`/Azienda/GetInformazioniAzienda?id=${companyId}`)
            if (res.status === HttpStates.OK) {
                const data : CompanyInfoModel[] = res.data.value
                const info: { [key in TipiInformazioni]?: CompanyInfoModel } = {}
                for (const it of data) {
                    info[it.tipoInformazione] = it
                }
                return info
            }
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default getCompanyInformationAPI
