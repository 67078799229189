import it from './customerSpecs/messages/defaultIt'
import { IOCServices } from '.'

export type AppStringsType = typeof it
export type StringProviderService = (locale: string) => Promise<AppStringsType>

const stringProvider = (container: IOCServices) => {
    const t = async (locale: 'it' | 'en') => {
        const id = container.customers.currentCustomerId
        const { messages } = await container.customers.loadAssetsAsync(id)
        switch (locale) {
            case 'it':
                return messages.it
            case 'en':
                return messages.en
        }
    }
    return t
}

export default stringProvider