import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'
import { isOperatorOrAgent } from '../../utilities'
const loadFornituraDetailsAPI = async (
    codiceUtente: string,
    puntogas: string,
    roles: Roles[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Fornitura/GetFornituraDettaglio/${puntogas}`
        if (isOperator) {
            api = `/Fornitura/GetFornituraDettaglioAsOperatore/${codiceUtente}?PuntoGas=${puntogas}`
        }
        const res = await rest.get(api)
        if (res.status == HttpStates.OK) {
            const data = res.data.value
            return data
        }

    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadFornituraDetailsAPI
