import { HttpStates } from '../../../models/Enums'
import { RegistrationModel } from '../../../models/models'
import { getStringFromCode } from '../../../utilities'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_PWDCOMPLEXITYERROR } from './types'

const registrazioneUtenteEmail =
    (reg: RegistrationModel): AppAction<any> => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const editData = await container.api.registrazioneUtenteEmailAPI(reg)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.registrationSuccessful,
                    show: true,
                }),
            )

            return editData
        } catch (e: any) {
            const code = e.response?.status
            const errorCode = e.response?.data?.value

            if (code === HttpStates.KO && errorCode.search("GDPR") > -1) {
                dispatch({
                    type: SET_PWDCOMPLEXITYERROR,
                    payload: true,
                })
            }
            else {
                dispatch({
                    type: SET_PWDCOMPLEXITYERROR,
                    payload: false,
                })
                const error = e.response?.data?.value ?? e.message
                const errorDesc = getStringFromCode(error,getState().main.stringhe,i18n,lan)
                dispatch(
                    setNotification({
                        type: 'error',
                        message: errorDesc,
                        show: true,
                    }),
                )

            }

            return false


        }
    }

export default registrazioneUtenteEmail
