import {
    RequestEditingModel,
    RequestWithDetailsModel,
} from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const editRequest =
    (request: RequestEditingModel): AppAction<RequestWithDetailsModel> =>
    async (dispatch, getState, container) => {
        try {
            const lan = getState().common.language
            const i18n = await container.strings(lan)
            const req = await container.api.editRequestAPI(request)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return req
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default editRequest
