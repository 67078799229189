import { Box } from "grommet"
import useAssets from "../hooks/useAssets"

const GplIcon = ({
    ss
}: { ss?: string }) => {
const assets = useAssets()
return (
<Box
        width={ss??'50px'}
        height={ss??'50px'}

        background={{
            repeat: 'no-repeat',
            size: 'contain',
            image: "url(" + assets.GplIconData + ")"
        }}
/>
)
}

export default GplIcon