import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_TOKENROP,
    SET_STRINGHE,
    // SET_USERNAME,
    SET_REQUESTTYPES,
    SET_CLIENT_INFO,
} from './types'
import { formatDate } from '../../../utilities'

const loginCrypted: AppAsyncAction<any> =
    (values: { username: string; password: string }) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const login = await container.api.loginCryptedAPI(values)
            dispatch({
                type: SET_TOKENROP,
                payload: login.access_token,
            })

            container.jwt.saveTokenROP(login.access_token)
            const code = await container.api.getCodiceUtenteAPI()

            const user = await container.api.getAnagraficaUtenteAPI(code.value)

            user.value.dataNascitaAPI = user.value.dataNascita

            const indirizzo = `${user.value.tipoViaResidenza} ${user.value.viaResidenza} ${user.value.nrCivicoResidenza} ${user.value.comuneResidenza} ${user.value.provinciaResidenza}`

            if (indirizzo.length > 0) {
                user.value.indirizzo = indirizzo
            }

            if (user.value.dataNascita?.length > 0) {
                const date = new Date(user.value.dataNascita)
                let dataNascita = formatDate(date)

                user.value.dataNascita = dataNascita
            }

            user.value.UserName = values.username
            const stringhe = await container.api.getStringheAPI()

            dispatch({ type: SET_STRINGHE, payload: stringhe })

            // dispatch({ type: SET_USERNAME, payload: values.username })

            const reqtypes = await container.api.getTipiRichiestaAbilitatiAPI()
            dispatch({
                type: SET_REQUESTTYPES,
                payload: reqtypes,
            })

            dispatch({
                type: SET_CLIENT_INFO,
                payload: user.value,
            })
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.loginFailedLabel,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loginCrypted
