import { Box, Text, FileInput, Form, FormField, Collapsible, Spinner } from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import {
    CircleInformation,
    DownloadOption,
    FormCheckmark,
    UploadOption,
} from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import {
    BooleanValueInt,
    LivStatoRichiesta,
    paramEnum,
    TipiRichiesta,
    TipiUtente,
} from '../models/Enums'
import { RequestDetailsModel, RequestModel } from '../models/models'
import getRIDModule from '../services/store/actions/getRIDModule'
import sendSignedModule from '../services/store/actions/sendSignedModule'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import {
    downloadPDF,
    getDescTipoRichiesta,
    getStateLivRequest,
} from '../utilities'
import BillInfoBox from './BillInfoBox'
import BRoundButton from './BRoundButton'
import useSize from '../hooks/useSize'
import './BUpload.css'
import getSignedModule from '../services/store/actions/getSignedPDF'
import BCloseButton from './BCloseButton'
import BBackButton from './BBackButton'
import useAppQuery from '../hooks/useAppQuery'
import { loadRequestDetails } from '../services/store/actions'

const RequestDetailsBox = ({
    onBackPress,
    backButton = false,
    request,
}: RequestDetailsBoxProps) => {
    const strings = useI18n()
    const size = useSize()
    const [isLoadingDetails, loadDetails] = useAppQuery(loadRequestDetails)
    const [details, setDetails] = useState<Array<RequestDetailsModel>>([])
    const [fileError, setFileError] = useState(false)
    const [existsSignedDoc, setExistsSignedDoc] = useState(false)
    const [isUploadExpanded, setUploadExpanded] = useState(false)
    const [postOrPut, setPostOrPut] = useState(strings.sostituisci)
    const dispatch = useAppDispatch()
    const [fileValue, setFileValue] = useState({
        fileInput: [],
    })
    const main = useAppSelector((state) => state.main)
    const supply = useAppSelector((state) => state.supply)
    const liv = getStateLivRequest(request?.idStatoRichiesta)
    const pod = supply.supplies.find(
        (f) => f.puntoGas === request?.puntoGas,
    )?.pod
    const descRequest = getDescTipoRichiesta(request?.idTipoRichiesta, strings)
    const publicParam = main.publicParam
    const WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta,
        )[0]?.valore ?? 0

    const WEB_RichiestaRIDCambioStatoManuale =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEB_RichiestaRIDCambioStatoManuale,
        )[0]?.valore ?? 0

    const WEBSVC_LimiteDimensioniEmail =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEBSVC_LimiteDimensioniEmail,
        )[0]?.valore ?? 0

    const WEB_NumeroCampiFatturaViaMail =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEB_NumeroCampiFatturaViaMail,
        )[0]?.valore ?? 4

    const loadData = useCallback(async () => {
        try {
            const details = await loadDetails(request?.idRichiesta)
            setDetails(details)
            if (
                request?.idTipoRichiesta ===
                TipiRichiesta.DomiciliazioneBancaria
            ) {
                const data = await dispatch(
                    getSignedModule({
                        idRichiesta: request.idRichiesta,
                        codiceUtente: request.codiceUtente,
                    }),
                )

                setExistsSignedDoc(data !== undefined && data.length > 0)
                if (data !== undefined && data.length === 0) {
                    setUploadExpanded(true)
                    setPostOrPut(strings.load)
                } else {
                    setUploadExpanded(false)
                    setPostOrPut(strings.sostituisci)
                }
            }
        } catch (e) {}
    }, [
        dispatch,
        loadDetails,
        request?.codiceUtente,
        request?.idRichiesta,
        request?.idTipoRichiesta,
        strings.load,
        strings.sostituisci,
    ])

    useEffect(() => {
        loadData()
    }, [loadData, request])

    if (isLoadingDetails) {
        return (
            <Box fill background={'background-front'}>
                <Box pad="medium" gap="medium">
                    <Box direction="row" gap="medium" align="center">
                        {backButton ? (
                            <BBackButton
                                onClick={() => onBackPress()}
                                size="small"
                            />
                        ) : (
                            <BCloseButton
                                onClick={() => onBackPress()}
                                size="medium"
                            />
                        )}
                        <Box>
                            <Text size="xlarge" weight={'bold'}>
                                {descRequest}
                            </Text>
                        </Box>
                        <Spinner />
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box flex="grow" background={'background-front'}>
            <Box pad="medium" gap="medium">
                <Box direction="row" gap="medium" align="center">
                    {backButton ? (
                        <BBackButton
                            onClick={() => onBackPress()}
                            size="small"
                        />
                    ) : (
                        <BCloseButton
                            onClick={() => onBackPress()}
                            size="medium"
                        />
                    )}
                    <Box>
                        <Text size="xlarge" weight={'bold'}>
                            {descRequest}
                        </Text>
                    </Box>
                </Box>
                <Box
                    justify="between"
                    direction="row"
                    margin={{ top: 'medium' }}
                >
                    <Box justify="center" direction="column" gap="small">
                        <Text weight="bold" size="medium">
                            {pod ? strings.fornitura : strings.codiceUtente}
                        </Text>
                        <Text weight="bold" size="small">
                            {pod ? pod : request?.codiceUtente}
                        </Text>
                    </Box>
                    {request?.idTipoUtente === TipiUtente.Operatore && (
                        <Box justify="center" direction="column" gap="small">
                            <Text weight={'bold'}>
                                {strings.utenteInserimento}
                            </Text>
                            <Text
                                style={{ textTransform: 'uppercase' }}
                                size="small"
                                weight={'bold'}
                            >
                                {request?.idTipoUtente === TipiUtente.Operatore
                                    ? strings.operatore
                                    : ''}
                            </Text>
                        </Box>
                    )}
                    <Box direction="column">
                        <Text weight="bold" size="medium">
                            {strings.dataRichiesta}
                        </Text>
                        <Text
                            weight="bold"
                            size="small"
                            margin={{ top: '12px' }}
                        >
                            {request?.dataRichiesta}
                        </Text>
                    </Box>
                </Box>
                <Box
                    flex
                    direction="row"
                    justify="center"
                    align="center"
                    height={{ min: 'small' }}
                >
                    <Box
                        direction="column"
                        style={{ position: 'relative' }}
                        gap="small"
                    >
                        <Box
                            align="center"
                            justify="center"
                            round="full"
                            width={size === 'small' ? '60px' : '70px'}
                            height={size === 'small' ? '60px' : '70px'}
                            border={
                                liv === 1
                                    ? { color: 'lightwarn', size: 'large' }
                                    : { color: 'brand', size: 'small' }
                            }
                            background={liv === 1 ? 'warn' : 'background'}
                        >
                            {liv === 1 ? (
                                <Box align="center" justify="center">
                                    <Text
                                        weight="bold"
                                        size="large"
                                        color="textWhite"
                                    >
                                        1
                                    </Text>
                                </Box>
                            ) : (
                                <FormCheckmark color="brand" size="large" />
                            )}
                        </Box>
                        {liv === 1 ? (
                            <Text
                                style={{
                                    width: 'max-content',
                                    position: 'absolute',
                                    top: 70,
                                    right: -10,
                                }}
                                color={'warn'}
                                weight="bold"
                                size="small"
                            >
                                {strings.presaIncarico}
                            </Text>
                        ) : undefined}
                    </Box>
                    <Box
                        height={size === 'small' ? '60px' : '70px'}
                        justify="center"
                    >
                        <Box
                            background="brand"
                            width={size === 'small' ? 'xxsmall' : 'small'}
                            height="2px"
                            align="center"
                        />
                    </Box>
                    <Box
                        direction="column"
                        style={{ position: 'relative' }}
                        gap="small"
                    >
                        <Box
                            align="center"
                            justify="center"
                            round="full"
                            width={size === 'small' ? '60px' : '70px'}
                            height={size === 'small' ? '60px' : '70px'}
                            border={
                                liv === 1 || liv === 3 || liv === 4
                                    ? { color: 'brand', size: 'small' }
                                    : { color: 'lightwarn', size: 'large' }
                            }
                            background={
                                liv === 1 || liv === 3 || liv === 4
                                    ? 'background-front'
                                    : 'warn'
                            }
                        >
                            {liv === 1 ? (
                                <Box align="center" justify="center">
                                    <Text
                                        weight="bold"
                                        size="large"
                                        color="brand"
                                    >
                                        2
                                    </Text>
                                </Box>
                            ) : liv === 2 ? (
                                <Box align="center" justify="center">
                                    <Text
                                        weight="bold"
                                        size="large"
                                        color="textWhite"
                                    >
                                        2
                                    </Text>
                                </Box>
                            ) : (
                                <FormCheckmark color="brand" size="large" />
                            )}
                        </Box>
                        {liv === 2 ? (
                            <Text
                                style={{
                                    width: 'max-content',
                                    position: 'absolute',
                                    top: 70,
                                    right: -10,
                                }}
                                weight="bold"
                                size="small"
                                color={'warn'}
                            >
                                {strings.inElaborazione}
                            </Text>
                        ) : undefined}
                    </Box>

                    <Box
                        height={size === 'small' ? '60px' : '70px'}
                        justify="center"
                    >
                        <Box
                            background="brand"
                            width={size === 'small' ? 'xxsmall' : 'small'}
                            height="2px"
                            align="center"
                        />
                    </Box>

                    <Box
                        direction="column"
                        style={{ position: 'relative' }}
                        gap="small"
                    >
                        <Box
                            align="center"
                            justify="center"
                            round="full"
                            width={size === 'small' ? '60px' : '70px'}
                            height={size === 'small' ? '60px' : '70px'}
                            background={
                                liv === 1 || liv === 2
                                    ? 'background-front'
                                    : liv === 3
                                    ? 'ok'
                                    : 'ko'
                            }
                            border={
                                liv === 1 || liv === 2
                                    ? { color: 'brand', size: 'small' }
                                    : liv === 3
                                    ? { color: 'lightok', size: 'large' }
                                    : { color: 'lightko', size: 'large' }
                            }
                        >
                            {liv === 1 || liv === 2 ? (
                                <Box align="center" justify="center">
                                    <Text
                                        weight="bold"
                                        size="large"
                                        color="brand"
                                    >
                                        3
                                    </Text>
                                </Box>
                            ) : (
                                <FormCheckmark color="textWhite" size="large" />
                            )}
                        </Box>
                        {liv === 3 ? (
                            <Text
                                style={{
                                    width: 'max-content',
                                    position: 'absolute',
                                    top: 70,
                                    right: 0,
                                }}
                                weight="bold"
                                size="small"
                                color={'ok'}
                            >
                                {strings.completata}
                            </Text>
                        ) : liv === 4 ? (
                            <Text weight="bold" size="small" color={'ko'}>
                                {strings.rifiutata}
                            </Text>
                        ) : undefined}
                    </Box>
                </Box>
                <Box margin={{ top: 'large' }}>
                    <Text weight="bold" size="medium">
                        {strings.dettagliRichiesta}
                    </Text>
                </Box>
                <Box direction="row" wrap width={{ min: 'small' }}>
                    {details
                        ?.filter(
                            (ii) =>
                                ii.valore !== '' &&
                                ii.descrizione !==
                                    strings.elencoRichiesteAssociate &&
                                ii.descrizione !==
                                    strings.elencoIndirizziEmaildaConfermare &&
                                ii.descrizione !== strings.SiglaIBAN &&
                                ii.descrizione !== strings.CodiceIBAN &&
                                ii.descrizione !== strings.BBANCIN &&
                                ii.descrizione !== strings.BBANABI &&
                                ii.descrizione !== strings.BBANCAB &&
                                ii.descrizione !== strings.BBANNumCC &&
                                ii.descrizione !==
                                    strings.MODALITAINVIOMODULORID &&
                                ii.descrizione !== strings.TipologiaSDD &&
                                ii.descrizione !== strings.ComuneRichiedente &&
                                ii.descrizione !== strings.CAPRichiedente &&
                                ii.descrizione !== strings.CivicoRichiedente &&
                                ii.descrizione !== strings.TipoVia &&
                                ii.descrizione !==
                                    strings.ProvinciaRichiedente &&
                                ii.descrizione !==
                                    strings.TipologiaUtenteRichiesta &&
                                ii.descrizione !==
                                    strings.CodiceFiscaleRichiedente &&
                                ii.descrizione !== strings.CodComune &&
                                ii.descrizione !== strings.streetName &&
                                ii.descrizione !== strings.numerocivico &&
                                ii.descrizione !==
                                    strings.nominativoRichiedente &&
                                ii.descrizione !== strings.province &&
                                ii.descrizione !== strings.comune &&
                                ii.descrizione !== strings.cap &&
                                ii.descrizione !== strings.privacy1 &&
                                ii.descrizione !== strings.privacy2 &&
                                ii.descrizione !== strings.privacy3 &&
                                (WEB_NumeroCampiFatturaViaMail === '3'
                                    ? ii.descrizione !== strings.quartaMailSped
                                    : WEB_NumeroCampiFatturaViaMail === '2'
                                    ? ii.descrizione !==
                                          strings.terzaMailSped &&
                                      ii.descrizione !== strings.quartaMailSped
                                    : WEB_NumeroCampiFatturaViaMail === '1'
                                    ? ii.descrizione !==
                                          strings.secondaMailSped &&
                                      ii.descrizione !==
                                          strings.terzaMailSped &&
                                      ii.descrizione !== strings.quartaMailSped
                                    : true),
                        )
                        .map((rdf, idx) => (
                            <BillInfoBox
                                key={idx}
                                margin={{ vertical: 'small' }}
                                width={{ min: '50%' }}
                                flex={{ grow: 0.5 }}
                                title={
                                    rdf.descrizione ===
                                    strings.AnagLegRapNomCogn
                                        ? strings.AnagLegRapNomCognDesc
                                        : rdf.descrizione ===
                                          strings.AnagLegRapCF
                                        ? strings.AnagLegRapCFDesc
                                        : rdf.descrizione ===
                                          strings.privacy1desc
                                        ? strings.privacy1claus
                                        : rdf.descrizione ===
                                          strings.privacy2desc
                                        ? strings.privacy2claus
                                        : rdf.descrizione ===
                                          strings.privacy3desc
                                        ? strings.privacy3claus
                                        : rdf.descrizione
                                }
                                value={
                                    rdf.valore === 'ADESIONESOLOMAIL'
                                        ? 'Adesione'
                                        : rdf.valore === 'MODIFICASOLOMAIL'
                                        ? 'Modifica'
                                        : rdf.valore
                                }
                            />
                        ))}
                </Box>
                {request?.idTipoRichiesta ===
                    TipiRichiesta.DomiciliazioneBancaria &&
                    WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta ===
                        BooleanValueInt.true &&
                    WEB_RichiestaRIDCambioStatoManuale ===
                        BooleanValueInt.true &&
                    getStateLivRequest(request?.idStatoRichiesta) ===
                        LivStatoRichiesta.Completata &&
                    existsSignedDoc && (
                        <Box direction="column">
                            <Box
                                width="100%"
                                direction={size === 'small' ? 'column' : 'row'}
                                gap={size === 'small' ? 'medium' : undefined}
                                margin={{ top: 'small' }}
                                justify="between"
                            >
                                <Box direction="row" flex>
                                    <Box
                                        round="full"
                                        align="center"
                                        justify="center"
                                        background="brand"
                                        height="max-content"
                                        width={{ min: 'fit-content' }}
                                        margin={{ right: '1%' }}
                                    >
                                        <CircleInformation size="medium" />
                                    </Box>
                                    <Text>
                                        {strings.DocumentoFirmatoAttuale}
                                    </Text>
                                </Box>

                                <BRoundButton
                                    style={{ padding: 'small' }}
                                    size="medium"
                                    label={strings.ScaricaDoc}
                                    onClick={async () => {
                                        try {
                                            const data = await dispatch(
                                                getSignedModule({
                                                    idRichiesta:
                                                        request.idRichiesta,
                                                    codiceUtente:
                                                        request.codiceUtente,
                                                }),
                                            )

                                            if (
                                                data !== undefined &&
                                                data.length > 0
                                            ) {
                                                downloadPDF(data, 'RIDFirmato')
                                            }
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                {request?.idTipoRichiesta ===
                    TipiRichiesta.DomiciliazioneBancaria &&
                    WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta ===
                        BooleanValueInt.true &&
                    WEB_RichiestaRIDCambioStatoManuale ===
                        BooleanValueInt.true &&
                    getStateLivRequest(request?.idStatoRichiesta) !==
                        LivStatoRichiesta.Completata &&
                    getStateLivRequest(request?.idStatoRichiesta) !==
                        LivStatoRichiesta.Rifiutata && (
                        <Box direction="column">
                            {existsSignedDoc && (
                                <Box
                                    width="100%"
                                    direction={
                                        size === 'small' ? 'column' : 'row'
                                    }
                                    gap={
                                        size === 'small' ? 'medium' : undefined
                                    }
                                    margin={{ top: 'small' }}
                                    justify="between"
                                >
                                    <Box direction="row" gap="small" flex>
                                        <Box
                                            round="full"
                                            align="center"
                                            justify="center"
                                            background="brand"
                                            height="fit-content"
                                            width={{ min: 'fit-content' }}
                                        >
                                            <CircleInformation size="medium" />
                                        </Box>
                                        <Text>
                                            {strings.DocumentoFirmatoAttuale}
                                        </Text>
                                    </Box>
                                    <BRoundButton
                                        style={{
                                            padding: 'small',
                                            height: 'fit-content',
                                        }}
                                        size="medium"
                                        label={strings.ScaricaDoc}
                                        onClick={async () => {
                                            try {
                                                const data = await dispatch(
                                                    getSignedModule({
                                                        idRichiesta:
                                                            request.idRichiesta,
                                                        codiceUtente:
                                                            request.codiceUtente,
                                                    }),
                                                )
                                                if (
                                                    data !== undefined &&
                                                    data.length > 0
                                                ) {
                                                    downloadPDF(
                                                        data,
                                                        'RIDFirmato',
                                                    )
                                                }
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />
                                </Box>
                            )}

                            <Box
                                margin={{ top: 'medium', bottom: 'small' }}
                                direction="row"
                                gap="small"
                                onClick={() =>
                                    setUploadExpanded(!isUploadExpanded)
                                }
                            >
                                {isUploadExpanded ? (
                                    <UploadOption />
                                ) : (
                                    <DownloadOption />
                                )}
                                <Text weight="bold" size="medium">
                                    {postOrPut
                                        .concat(' ')
                                        .concat(strings.DocumentoFirmato)}
                                </Text>
                            </Box>
                            <Collapsible open={isUploadExpanded}>
                                <Box
                                    pad="small"
                                    round="small"
                                    border={[
                                        {
                                            color: 'lightgrey',
                                            size: 'small',
                                            style: 'solid',
                                            side: 'vertical',
                                        },
                                        {
                                            color: 'lightgrey',
                                            size: 'small',
                                            style: 'solid',
                                            side: 'horizontal',
                                        },
                                    ]}
                                >
                                    <Box
                                        width="100%"
                                        direction={
                                            size === 'small' ? 'column' : 'row'
                                        }
                                        gap={
                                            size === 'small'
                                                ? 'medium'
                                                : undefined
                                        }
                                        margin={{ top: 'small' }}
                                        justify="between"
                                    >
                                        <Box direction="row" gap="small" flex>
                                            <Box
                                                round="full"
                                                align="center"
                                                justify="center"
                                                background="brand"
                                                height="fit-content"
                                                width={{ min: 'fit-content' }}
                                            >
                                                <CircleInformation size="medium" />
                                            </Box>
                                            <Text>{strings.step1}</Text>
                                        </Box>

                                        <BRoundButton
                                            style={{ padding: 'small' }}
                                            size="medium"
                                            label={strings.ScaricaMod}
                                            onClick={async () => {
                                                try {
                                                    const data = await dispatch(
                                                        getRIDModule({
                                                            idRichiesta:
                                                                request.idRichiesta,
                                                            codiceUtente:
                                                                request.codiceUtente,
                                                        }),
                                                    )
                                                    downloadPDF(
                                                        data.value,
                                                        'DocumentoRID',
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        />
                                    </Box>

                                    <Box
                                        width="100%"
                                        direction={
                                            size === 'small' ? 'column' : 'row'
                                        }
                                        gap={
                                            size === 'small'
                                                ? 'medium'
                                                : undefined
                                        }
                                        margin={{ top: 'small' }}
                                        justify="between"
                                    >
                                        <Box direction="row" gap="small" flex>
                                            <Box
                                                round="full"
                                                align="center"
                                                justify="center"
                                                height="fit-content"
                                                width={{ min: 'fit-content' }}
                                                background="brand"
                                            >
                                                <CircleInformation size="medium" />
                                            </Box>
                                            <Text>{strings.step2}</Text>
                                        </Box>

                                        <Box>
                                            <Form
                                                validate="change"
                                                value={fileValue}
                                                onChange={setFileValue}
                                            >
                                                <Box width="medium">
                                                    <FormField
                                                        label=""
                                                        htmlFor="fileInput"
                                                        name="fileInput"
                                                        className={
                                                            fileError
                                                                ? 'error'
                                                                : 'normal'
                                                        }
                                                    >
                                                        <FileInput
                                                            id="fileInput"
                                                            name="fileInput"
                                                            maxSize={
                                                                +WEBSVC_LimiteDimensioniEmail
                                                            }
                                                            messages={{
                                                                dropPrompt:
                                                                    'Trascina qui il documento',

                                                                browse: 'Carica',
                                                            }}
                                                        />
                                                    </FormField>
                                                </Box>
                                            </Form>
                                        </Box>
                                    </Box>

                                    <Box
                                        width="100%"
                                        direction={
                                            size === 'small' ? 'column' : 'row'
                                        }
                                        gap={
                                            size === 'small'
                                                ? 'medium'
                                                : undefined
                                        }
                                        margin={{ top: 'small' }}
                                        justify="between"
                                    >
                                        <Box direction="row" gap="small" flex>
                                            <Box
                                                round="full"
                                                align="center"
                                                justify="center"
                                                height="fit-content"
                                                width={{ min: 'fit-content' }}
                                                background="brand"
                                            >
                                                <CircleInformation size="medium" />
                                            </Box>
                                            <Text>{strings.step3}</Text>
                                        </Box>

                                        <BRoundButton
                                            style={{ padding: 'small' }}
                                            elevation="0"
                                            size="medium"
                                            label={strings.InviaDoc}
                                            onClick={async () => {
                                                try {
                                                    const module = {
                                                        idRichiesta: String(
                                                            request?.idRichiesta,
                                                        ),
                                                        file: fileValue
                                                            .fileInput[0],
                                                        limit: WEBSVC_LimiteDimensioniEmail,
                                                    }
                                                    if (
                                                        module.file ===
                                                        undefined
                                                    ) {
                                                        setFileError(true)
                                                    } else {
                                                        setFileError(false)
                                                    }

                                                    const res = await dispatch(
                                                        sendSignedModule(
                                                            module,
                                                        ),
                                                    )
                                                    if (res) {
                                                        setExistsSignedDoc(true)
                                                        setUploadExpanded(false)
                                                        setPostOrPut(
                                                            strings.sostituisci,
                                                        )
                                                    }
                                                } catch (e) {}
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Collapsible>
                        </Box>
                    )}
            </Box>
        </Box>
    )
}

type RequestDetailsBoxProps = {
    onBackPress: () => void
    request?: RequestModel
    backButton?: boolean
}

export default RequestDetailsBox
