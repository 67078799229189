import { ClaimResponseModel } from '../../../models/models';
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadDettaglioReclamo =
    (values: { idRichiesta: number; idReclamo: number }): AppAction<ClaimResponseModel> =>
    async (dispatch, _, container) => {
        try {
            const claims = await container.api.loadDettaglioReclamoAPI(values)
            return claims
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadDettaglioReclamo
