import { IOCServices } from '..'
import { EnabledChannels } from '../../models/models'

const getEnabledChannelsAPI = async (container?: IOCServices): Promise<EnabledChannels[]> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    try {
    const { data } = await container.restID.get(
        `/Reclami/GetCanaliAbilitati/`,
    )
    const result = [...(data?.value ?? [])]
    return result
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getEnabledChannelsAPI
