import get from "lodash.get"
import useTheme from "../hooks/useTheme"

const BolletteIcon = ({
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string }) => {
    const { global } = useTheme()
    const currentColor = get(global?.colors, color || '')
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8969 0.8421C13.3109 0.8421 13.6469 1.1781 13.6469 1.5921C13.6469 2.0061 13.3109 2.3421 12.8969 2.3421H5.62888C3.12088 2.3421 1.49988 4.0661 1.49988 6.7361V14.8181C1.49988 17.5231 3.08188 19.2031 5.62888 19.2031H14.2329C16.7409 19.2031 18.3619 17.4821 18.3619 14.8181V7.7791C18.3619 7.3651 18.6979 7.0291 19.1119 7.0291C19.5259 7.0291 19.8619 7.3651 19.8619 7.7791V14.8181C19.8619 18.3381 17.5999 20.7031 14.2329 20.7031H5.62888C2.26188 20.7031 -0.00012207 18.3381 -0.00012207 14.8181V6.7361C-0.00012207 3.2111 2.26188 0.8421 5.62888 0.8421H12.8969ZM15.0123 7.6719C15.3403 7.9259 15.4003 8.3969 15.1463 8.7239L12.2163 12.5039C12.0943 12.6619 11.9143 12.7649 11.7163 12.7889C11.5163 12.8159 11.3183 12.7579 11.1603 12.6349L8.34228 10.4209L5.81128 13.7099C5.66328 13.9019 5.44128 14.0029 5.21628 14.0029C5.05628 14.0029 4.89528 13.9519 4.75928 13.8479C4.43128 13.5949 4.36928 13.1239 4.62228 12.7959L7.61528 8.9059C7.73728 8.7469 7.91828 8.6439 8.11628 8.6189C8.31828 8.5929 8.51628 8.6489 8.67328 8.7739L11.4933 10.9889L13.9603 7.8059C14.2143 7.4769 14.6843 7.4159 15.0123 7.6719ZM17.9673 0C19.4413 0 20.6393 1.198 20.6393 2.672C20.6393 4.146 19.4413 5.345 17.9673 5.345C16.4943 5.345 15.2953 4.146 15.2953 2.672C15.2953 1.198 16.4943 0 17.9673 0ZM17.9673 1.5C17.3213 1.5 16.7953 2.025 16.7953 2.672C16.7953 3.318 17.3213 3.845 17.9673 3.845C18.6133 3.845 19.1393 3.318 19.1393 2.672C19.1393 2.025 18.6133 1.5 17.9673 1.5Z"
                fill={currentColor?.toString() ?? '#303972'}
            />
        </svg>
    )
}

export default BolletteIcon
