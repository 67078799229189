import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { getCompanyInformation } from '../../utilities'

const getAPIVersionAPI =
    async (container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const rest = container.rest
        const companyInfo = getCompanyInformation()
        try {
            const res = await rest.get(
                `/Default/GetApiInformation?id=${companyInfo.id}&AppVersion=9.9.9&ObjMode=1`,            
            )
            if (res.status == HttpStates.OK) {
                const data = res.data
                return data
            }
            else {
                return
            }

            
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default getAPIVersionAPI



