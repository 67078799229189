const messages = {
    loginButton: 'LOGIN',
    recoverUsernameButton: 'Recupera Username',
    recoverPasswordButton: 'Recupera Password',
    sendButton: 'Invia',
    recoverCheckEmail: 'Controlla la tua Email per completare i passaggi',
    recoverSendPasswordSuccess:
        'Password modificata con successo, puoi chiudere questa pagina e fare il login',
    recoverInvalidEmail: 'Email non valida',
    recoverPasswordMustMatch: 'Le password devono coincidere',
    recoverPasswordPlaceholder: 'La tua nuova password',
    recoverPasswordRepeatPlaceholder: 'Ripeti la nuova password',
    loginFailedLabel: 'Login non valido',
    userActivation: 'Attiva la tua utenza',
    recoverPassword: 'Recupera la tua password',
    firstTime: 'Prima volta su UserNext?',
    registrati: 'Registrati',

    //Pages Contaier
    welcomeBack: 'Ben arrivato',
    welcomeLabelMorning: 'Buongiorno',
    welcomeLabelAfternoon: 'Buon Pomeriggio',
    welcomeLabelEvening: 'Buonasera',
    profileLabel: 'Anagrafica',
    dashboard: 'Dashboard',
    contracts: 'Contratti',
    importLabel: 'Import',
    export: 'Esportazione',
    usersManagement: 'Gestione utenti',
    logoutButton: 'LOGOUT',

    //Common
    mandatoryField: 'Campo obbligatorio',
    duplicateFields: 'Ci sono dei campi duplicati',
    lightModeLabel: 'Light',
    darkModeLabel: 'Dark',
    add: 'Aggiungi',
    password: 'Password',
    email: 'Email',
    username: 'Username',
    company: 'azienda',
    noResultFound: 'Nessun risultato trovato',
    invalidEmailField: 'Email non valida',
    save: 'Salva',
    send: 'Invia',
    download: 'Scarica',
    history: 'Storico',
    name: 'Nome',
    lastName: 'Cognome',
    details: 'Dettagli',
    confirmDeletion: 'Conferma rimozione',
    operationCantBeUndone: "L'operazione non può essere annullata",
    close: 'CHIUDI',
    remove: 'RIMUOVI',
    passwordCheck:
        '8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale',
    passwordMustMatch: 'Le password devono coincidere',
    insertNewPassword: 'Inserisci la password',
    confirmPassword: 'Conferma password',
    repeatNewPassword: 'Ripeti la password',
    edit: 'MODIFICA',
    discard: 'ANNULLA',
    duplicate: 'Duplica',
    user: 'Utente',
    users: 'Utenti',
    date: 'Data',
    G: 'Gas',
    E: 'LUCE',
    WAIT_FOR_SIGN: 'Attesa firma',
    browse: 'CARICA DAL PC',
    dropDocumentHere: 'Trascina il documento qui',
    dropDocumentsHere: 'Trascina i documenti qui',
    uploadedFilesName: 'ELEMENTI',

    //Notification
    ERROR: 'ERRORE',
    INFO: 'INFORMAZIONE',
    WARNING: 'ATTENZIONE',
    successEditMessage: 'Informazioni aggiornate con successo',
    successRemoveMessage: 'Elemento rimosso correttamente',
    successOperatonMessage: 'Operazione completata con successo',
    errorMessage: 'Operazione fallita',

    //404
    pageNotFoundTitle: 'Ops! Questa pagina non esiste!',
    backToTheDashboard: 'Torna alla dashboard',

    //Contract User data form
    dateOfBirth: 'Data di nascita',
    fiscalCode: 'Codice fiscale',
    phonePrefix: 'Prefisso',
    phone: 'Telefono',
    streetName: 'Via',
    streetNumber: 'N. Civico',
    province: 'Privincia',
    city: 'Città',
    countryCode: 'Codice paese',
    zipCode: 'CAP',

    //Contracts LOG
    contractId: 'ID Dettaglio Contratto',
    serviceType: 'Tipo servizio',
    pod_pdr: 'POD/PDR',
    utilityStatus: 'Stato fornitura',

    //Utility
    legalName: 'Ragione sociale',
    vat: 'P.IVA',
    codiceUtente: 'Codice Utente',
    mailSentToUser: "E' stata inviata una mail all'utente specificato con l'informazione richiesta",
    failedRetry: "Operazione fallita, si prega di riprovare più tardi",

    //UL
    home: "Home",
    bollette: "Bollette",
    forniture: "Forniture",
    richieste: "Richieste",
    statoRichieste: "Stato Richieste",
    hello: "Hello",
    other: "Altro",
}

export default messages
