import * as Yup from 'yup'
import { Box, Button, Spinner } from 'grommet'
import useI18n from '../../hooks/useI18n'
import { SupplyModel, CityProvinceModel, RichiestaDisdettaModel } from '../../models/models'
import {
    TipiFornitura,
} from '../../models/Enums'
import BFormik from '../BFormik'
import useRequiredFields from '../../hooks/useRequiredFields'
import { AppStringsType } from '../../services/stringProvider'
import BTextInput from '../BTextInput'
import { checkValidDate, getCancellationReason, getRequestCancellation, stringDate } from '../../utilities'
import useSize from '../../hooks/useSize'
import BSelect from '../BSelect'
import BDateInput from '../BDateInput'


const mystyle = {
    fontWeight: "bold"
};

const dateTest = (strings: AppStringsType, lastDate: string | Date | undefined) =>
    Yup.string()
        .test('DateCompare', '', (actualDate, { createError, parent }) => {
            try {
                if (actualDate !== undefined && actualDate !== "" && lastDate !== undefined) {
                    if (!checkValidDate(actualDate)) throw Error(strings.dataLetturaNonValida)

                    const DactualDate = new Date(actualDate)
                    const DlastDate = new Date(lastDate!)

                    if (DactualDate < DlastDate) {
                        throw Error(strings.dataLetturaAnteUltima)
                    }
                }
                else throw Error(strings.dataLetturaNonValida)
                return true
            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        })


const generateSchema = (
    strings: AppStringsType,
    lastDate?: string,
) =>
    Yup.object().shape({
        PuntoGas: Yup.string(),
        CodiceUtente: Yup.string(),
        TipoFornitura: Yup.string(),
        UltimaLettura: Yup.string(),
        DataUltimaLettura: dateTest(strings, lastDate),
        TipoVia: Yup.string(),
        Via: Yup.string(),
        NumeroCivico: Yup.string(),
        Barrato: Yup.string(),
        Interno: Yup.string(),
        Piano: Yup.string(),
        Scala: Yup.string(),
        Provincia: Yup.string(),
        Comune: Yup.string(),
        Frazione: Yup.string(),
        DisdettaMotivo: Yup.string(),
        ChiusuraContatore: Yup.string(),
        UltimaLetturaF1: Yup.string(),
        UltimaLetturaF2: Yup.string(),
        UltimaLetturaF3: Yup.string(),
    })

const DisdettaForm = ({
    disabled,
    isLoading,
    lastDate,
    onSubmit,
    onBlur,
    supply,
    cities,
    streetTypes,

}: DisdettaProps) => {
    const strings = useI18n()
    const schema = generateSchema(strings, lastDate)
    const isRequired = useRequiredFields(schema)
    const size = useSize()

    return (
        <Box flex>
            <BFormik
                initialValues={{
                    PuntoGas: supply.puntoGas,
                    CodiceUtente: supply.codiceUtente,
                    TipoFornitura: supply.tipoFornitura.toString(),
                    DataUltimaLettura: '',
                    UltimaLettura: '',
                    TipoVia: '',
                    Via: '',
                    NumeroCivico: '',
                    Barrato: '',
                    Scala: '',
                    Piano: '',
                    Interno: '',
                    Provincia: '',
                    Cap: '',
                    Comune: '',
                    Frazione: '',
                    DisdettaMotivo: '',
                    ChiusuraContatore: '',
                    UltimaLetturaF1: '',
                    UltimaLetturaF2: '',
                    UltimaLetturaF3: '',
                }}
                enableReinitialize
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={true}
                blurCallback={({ values }) => {
                    if (!disabled) {
                        onBlur(values)
                    }
                }}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
                element={({
                    values,
                    errors,
                    validateForm,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                }) => {
                    const cityList = values.Provincia
                        ? cities.find(
                            (it) =>
                                it.codiceProvincia.toLowerCase() ===
                                values?.Provincia?.toLowerCase() ?? '',
                        )?.comune ?? []
                        : []

                    const city = cityList.find(
                        (it) => it.nomeComune === values.Comune,
                    )

                    const capList = cityList.find((it) => it.nomeComune === values?.Comune ?? '')
                        ?.cap ?? ([] as Array<string>)


                    values.Comune = city?.nomeComune ?? ''

                    return (
                        <Box>
                            <Box
                                direction="column"
                                width="100%"
                                pad="small"
                                elevation="medium"
                                round="small"
                            >
                                <Box style={mystyle}>
                                    {strings.dataLetturaPrecedente}
                                </Box>
                                <Box style={mystyle}>
                                    {stringDate(supply.dataLettura)}
                                </Box>
                            </Box>
                            <Box style={{ marginTop: 24 }} flex>
                                {supply.tipoServizio === 'G' ||
                                supply.tipoServizio === 'GPL' ? (
                                    <Box>
                                        <Box
                                            flex
                                            gap="small"
                                            direction="row"
                                            align="end"
                                            wrap
                                        >
                                            <BTextInput
                                                fast
                                                containerProps={{ flex: true }}
                                                name="UltimaLettura"
                                                mandatory={isRequired(
                                                    'UltimaLettura',
                                                )}
                                                label={strings.ultimaLettura}
                                                placeholder={
                                                    strings.ultimaLettura
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                                {supply.tipoServizio === 'E' ? (
                                    <Box>
                                        {supply.tipoFornitura ===
                                        TipiFornitura.MultiFascia ? (
                                            <Box>
                                                <Box
                                                    flex
                                                    gap="small"
                                                    direction="row"
                                                    align="end"
                                                    wrap
                                                >
                                                    <BTextInput
                                                        fast
                                                        containerProps={{
                                                            flex: true,
                                                        }}
                                                        name="UltimaLetturaF1"
                                                        label={
                                                            strings.ultimaLetturaF1
                                                        }
                                                        placeholder={
                                                            strings.ultimaLetturaF1
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        values={values}
                                                        errors={errors}
                                                    />
                                                </Box>
                                                <Box
                                                    flex
                                                    gap="small"
                                                    direction="row"
                                                    align="end"
                                                    wrap
                                                >
                                                    <BTextInput
                                                        fast
                                                        //disabled={disabled}
                                                        containerProps={{
                                                            flex: true,
                                                        }}
                                                        name="UltimaLetturaF2"
                                                        label={
                                                            strings.ultimaLetturaF2
                                                        }
                                                        placeholder={
                                                            strings.ultimaLetturaF2
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        values={values}
                                                        errors={errors}
                                                    />
                                                </Box>
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                        <Box
                                            flex
                                            gap="small"
                                            direction="row"
                                            align="end"
                                            wrap
                                        >
                                            <BTextInput
                                                fast
                                                //disabled={disabled}
                                                containerProps={{ flex: true }}
                                                name="UltimaLetturaF3"
                                                label={strings.ultimaLetturaF3}
                                                placeholder={
                                                    strings.ultimaLetturaF3
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                                <Box
                                    flex
                                    gap="small"
                                    direction="row"
                                    align="end"
                                    wrap
                                >
                                    <BDateInput
                                    
                                        containerProps={{ flex: true }}
                                        name="DataUltimaLettura"
                                        mandatory={isRequired(
                                            'DataUltimaLettura',
                                        )}
                                        format="dd/mm/yyyy"
                                        label={strings.dataUltimaLettura}
                                        placeholder={strings.dataUltimaLettura}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                    />
                                </Box>
                                <BSelect
                                    searchable
                                    containerProps={{ flex: true }}
                                    label={strings.motivoDisdetta}
                                    placeholder={strings.motivoDisdetta}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    name="DisdettaMotivo"
                                    errors={errors}
                                    options={getCancellationReason.map(
                                        (it) => ({
                                            label: it,
                                            value: it,
                                        }),
                                    )}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                                <BSelect
                                    searchable
                                    containerProps={{ flex: true }}
                                    label={strings.richiedeChiusuraContatore}
                                    placeholder={
                                        strings.richiedeChiusuraContatore
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    name="ChiusuraContatore"
                                    mandatory={isRequired('ChiusuraContatore')}
                                    errors={errors}
                                    options={getRequestCancellation.map(
                                        (it) => ({
                                            label: it,
                                            value: it,
                                        }),
                                    )}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                                <Box
                                    direction="row"
                                    margin={{ vertical: 'small' }}
                                    justify="center"
                                >
                                    <Box
                                        margin={{ bottom: 'small' }}
                                        style={mystyle}
                                    >
                                        {strings.datiRecapitoUltimaFattura}
                                    </Box>
                                </Box>
                                <Box gap="medium">
                                    <Box direction="column" justify="between">
                                        <Box
                                            direction="row"
                                            flex
                                            gap="small"
                                            align="flex-start"
                                        >
                                            <BSelect
                                                searchable
                                                containerProps={{ flex: true }}
                                                label={strings.province}
                                                placeholder={strings.province}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                name="Provincia"
                                                errors={errors}
                                                icon={
                                                    isLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        true
                                                    )
                                                }
                                                options={cities.map((it) => ({
                                                    label: it.provincia,
                                                    value: it.codiceProvincia,
                                                }))}
                                                labelKey="label"
                                                valueKey={{
                                                    key: 'value',
                                                    reduce: true,
                                                }}
                                            />
                                            <BSelect
                                                searchable
                                                containerProps={{ flex: true }}
                                                label={strings.city}
                                                placeholder={strings.city}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                name="Comune"
                                                icon={
                                                    isLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        true
                                                    )
                                                }
                                                options={cityList.map(
                                                    (it: any) => ({
                                                        label: it.nomeComune,
                                                        value: it.nomeComune,
                                                    }),
                                                )}
                                                labelKey="label"
                                                valueKey={{
                                                    key: 'value',
                                                    reduce: true,
                                                }}
                                            />
                                            <BSelect
                                                searchable
                                                containerProps={{ flex: true }}
                                                label={strings.cap}
                                                placeholder={strings.cap}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                name="Cap"
                                                icon={
                                                    isLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        true
                                                    )
                                                }
                                                options={capList.map((it) => ({
                                                    label: it,
                                                    value: it,
                                                }))}
                                                labelKey="label"
                                                valueKey={{
                                                    key: 'value',
                                                    reduce: true,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            direction="row"
                                            flex
                                            gap="small"
                                            align="flex-start"
                                            wrap
                                        >
                                            <BSelect
                                                searchable
                                                containerProps={{ flex: true }}
                                                label={strings.streetType}
                                                placeholder={strings.streetType}
                                                name="TipoVia"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                options={streetTypes.map(
                                                    (it) => ({
                                                        label: it.replace(
                                                            /^(\w)(.+)/,
                                                            (_, p1, p2) =>
                                                                p1.toUpperCase() +
                                                                p2.toLowerCase(),
                                                        ),
                                                        value: it,
                                                    }),
                                                )}
                                                labelKey="label"
                                                valueKey={{
                                                    key: 'value',
                                                    reduce: true,
                                                }}
                                            />
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={strings.streetName}
                                                label={strings.streetName}
                                                name="Indirizzo"
                                            />
                                        </Box>
                                        <Box
                                            direction="row"
                                            flex
                                            gap="small"
                                            align="flex-start"
                                            wrap
                                        >
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={strings.scala}
                                                label={strings.scala}
                                                name="Scala"
                                            />
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={strings.piano}
                                                label={strings.piano}
                                                name="Piano"
                                            />
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={strings.interno}
                                                label={strings.interno}
                                                name="Interno"
                                            />
                                        </Box>
                                        <Box
                                            direction="row"
                                            flex
                                            gap="small"
                                            align="flex-start"
                                            wrap
                                        >
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={
                                                    strings.streetNumber
                                                }
                                                label={strings.streetNumber}
                                                name="NumeroCivico"
                                            />
                                            <BTextInput
                                                containerProps={{ flex: true }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                placeholder={
                                                    strings.streetSepatator
                                                }
                                                label={strings.streetSepatator}
                                                name="Barrato"
                                            />
                                        </Box>
                                        <Box
                                            flex
                                            gap="small"
                                            direction="row"
                                            align="end"
                                            wrap
                                        >
                                            <BTextInput
                                                fast
                                                containerProps={{ flex: true }}
                                                name="Frazione"
                                                label={strings.frazione}
                                                placeholder={strings.frazione}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Button
                                primary
                                color="primary_color"
                                focusIndicator={false}
                                disabled={isLoading}
                                margin={
                                    size === 'small'
                                        ? undefined
                                        : { top: 'medium' }
                                }
                                style={{
                                    width: '100%',
                                    height: '50px',
                                }}
                                onClick={async () => {
                                    try {
                                        if (await validateForm()) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.confirmCancellation}
                                icon={isLoading ? <Spinner /> : <span />}
                            />
                        </Box>
                    )
                }}
            />
        </Box>

    )
}

export default DisdettaForm

interface DisdettaProps {
    isLoading?: boolean
    onBlur: (disdetta: RichiestaDisdettaModel) => any
    onSubmit: (
        disdetta: RichiestaDisdettaModel,
    ) => void | Promise<void> | Promise<RichiestaDisdettaModel>
    streetTypes: Array<string>
    cities: Array<CityProvinceModel>
    lastDate?: string
    supply: SupplyModel
    supplies?: SupplyModel[]
    disabled?: boolean
    abilitaAutoletturaEE?: number
}
