import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { ChangeAddressRequestModel } from '../../models/models'

const changeAddressRequestAPI = async (
    address: ChangeAddressRequestModel,
    isOperator: boolean,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const restID = container.restID
    const name = container.store.getState().user.clientInfo.nominativo ?? ''

    try {
        const res = await restID.post(
            '/Richiesta/InsertRichiestaCambioRecapito',
            {
                CodiceUtente: address.user,
                PuntiGas: address.puntiGas,
                SpedBarrato: address.barrato,
                SpedCAP: address.cap,
                SpedComune: address.cityIstat,
                SpedNomeRecapito: address.name,
                SpedProvincia: address.province,
                SpedNumCivico: address.number,
                SpedTipoVia: address.streetType,
                SpedVia: address.address,
                SpedIndicazione: '',
                SpedInterno: '',
                SpedComuneDesc: address.city,
                SpedFrazione: '',
                SpedPiano: '',
                SpedScala: '',
                Nominativo: name,
                IsOperator: isOperator,
            },
        )
        return res.status === HttpStates.OK
    } catch (e) {
        return Promise.reject(e)
    }
}

export default changeAddressRequestAPI
