import {
    Box,
    Heading,
    Spinner,
    Tip,
} from 'grommet'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import useI18n from '../hooks/useI18n'
import { useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import useSize from '../hooks/useSize'
import {
    EventsByDayModel,
} from '../models/models'
import BSeparator from '../components/BSeparator'

import useTheme from '../hooks/useTheme'
import loadSupplies from '../services/store/actions/loadSupplies'

import {
    formatDateFns,
    getColorStatoPagamento,
    getDescStatoPagamento,
    getIconStatoPagamento,
    getAutoletturaTipoServizio
} from '../utilities'
import loadInvoices from '../services/store/actions/loadInvoices'
import loadRanges from '../services/store/actions/loadRangeAutolettura'
import {
    StatiPagamento,
    TipiEventi,
} from '../models/Enums'
import EventBox from '../components/EventBox'
import CalendarBox from '../components/CalendarBox'
import { addMonths, format, parse, startOfDay, startOfMonth } from 'date-fns'
import { it } from 'date-fns/locale'
import { addDays } from 'date-fns/esm'
import { AllRoutes, RoutesEnum } from '../Navigation'

const TODAY = startOfDay(new Date())
const FUTURE_EVENTS_DAYS = 30

const Planner = () => {
    const strings = useI18n()
    const { global } = useTheme()
    const navigate = useNavigate()
    const supply = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const invoice = useAppSelector((state) => state.invoice)
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const [isLoadingInvoices, queryInvoices] = useAppQuery(loadInvoices)
    const [isLoadingRanges, queryRanges] = useAppQuery(loadRanges)
    const size = useSize()
    const [selectedDay, setSelectedDay] = useState<Date>(TODAY)

    const getColorByEventType = (type: number) => {
        switch (type) {
            case TipiEventi.Bolletta:
                return global?.colors?.['fattura']?.toString()
            case TipiEventi.Autolettura:
                return global?.colors?.['autolettura']?.toString()
            default: global?.colors?.['brand']?.toString()
        }
    }

    const loadData = async () => {
        try {
            await querySupplies(user.codiceUtente)
            await queryInvoices({ codiceUtente: supply.selectedSupply?.codiceUtente ?? user.codiceUtente})
            await queryRanges()

        } catch (e) {}
    }

    const eventsByDay = useMemo(() => {
        const events: EventsByDayModel = {}

        for (const it of invoice.invoices) {
            const date = startOfDay(new Date(it.dataScadenza))
            const key = formatDateFns(date)

            if (!(key in events)) {
                events[key] = []
            }

            events[key].push({
                type: TipiEventi.Bolletta,
                title: `${strings.bollettaNr} ${it.nrFattura}`,
                description: [`${strings.importo}: ${it.importoTotaleFattura.toFixed(2,)} €`, `${strings.scadenza}: ${it.dataScadenza}`],
                day: parse(it.dataScadenza, 'yyyy-MM-dd', new Date()),
                payload: it,
            })
        }

        for (const it of supply.ranges) {
            const date = startOfDay(new Date(it.dataInizio))
            const key = formatDateFns(date)
            if (!it.abilitato) {
                continue
            }

            if (!(key in events)) {
                events[key] = []
            }

            events[key].push({
                type: TipiEventi.Autolettura,
                title: getAutoletturaTipoServizio(it.idTipoServizio, strings),
                description: [getAutoletturaTipoServizio(it.idTipoServizio, strings)],
                day: parse(it.dataInizio, 'yyyy-MM-dd', new Date()),
            })
        }

        return events
    }, [invoice.invoices, strings, supply.ranges])

    const nextEvents = useMemo(() => {
        if (! eventsByDay) {
            return []
        }
        const lastNextEventsDay = addDays(TODAY, FUTURE_EVENTS_DAYS)
        const futureEvents = []

        for (const it in eventsByDay) {
            const day = new Date(it)
            if (day < TODAY || day > lastNextEventsDay) {
                continue
            }

            futureEvents.push(...eventsByDay[it])
        }

        return futureEvents
    }, [eventsByDay])


    useEffect(() => {
        loadData()
    }, [])

    return (
        <Box flex direction={size === 'small' ? 'column' : 'row'} align="start">
            {(isLoadingSupplies || isLoadingInvoices || isLoadingRanges) &&
                <Box fill background={'background-back'}>
                    <Box height="100vh" justify="center" align="center">
                        <Spinner />
                    </Box>
                </Box>
            }
            <Box flex fill pad='small'>
                <Box
                    flex
                    direction={size === 'large' ? 'row' : 'column'}
                    gap='small'
                    pad={'small'}
                >
                    <Box
                        direction="column"
                        flex={{ grow: 1 }}
                        width={{ min: 'medium' }}
                        gap="small"
                    >
                        <Box direction="row" justify="between" align='end'>
                            <Heading margin={{ vertical: '2' }} level={3}>
                                {strings.calendario}
                            </Heading>
                        </Box>

                        <Box fill >
                            <CalendarBox
                                round="large"
                                elevation="large"
                                selectedDay={selectedDay}
                                onSelectedDayChange={setSelectedDay}
                                onMonthChange={(direction: boolean) => setSelectedDay(prev => addMonths(startOfMonth(prev), direction ? 1 : -1)) }
                                showWeekNumber
                                events={eventsByDay}
                            />
                        </Box>
                    </Box>

                    {size === 'large' &&
                        <BSeparator
                            size="2px"
                            direction="vertical"
                        />
                    }
                    <Box
                        direction={size === 'medium' ? 'row' : 'column'}
                        height={{min: 'fit-content'}}
                        gap='small'
                        pad={{
                            vertical: size !== 'large' ? 'small' : undefined,
                            horizontal: size === 'large' ? 'small' : undefined,
                        }}
                    >
                        <Box
                            width={size === 'medium' ? '50%' : '100%'}
                            height={size === 'large' ? '50%' : undefined}
                            gap="small"
                        >
                            <Box
                                direction="row"
                                gap="small"
                                align="baseline"
                                width={{ min: 'max-content' }}
                            >
                                <Heading size="small">
                                    {`${strings.eventi} per il ${format(selectedDay, 'dd MMM yyyy', {locale: it})}`}
                                </Heading>
                            </Box>
                            <Box
                                gap="small"
                                direction="column"
                                margin={{ bottom: "small" }}
                                style={{ overflowY: 'scroll' }}
                                pad={{vertical: 'small'}}
                            >
                                {! eventsByDay[formatDateFns(selectedDay)]
                                    ?
                                        <Box
                                            direction="row"
                                            align="baseline"
                                        >
                                            <Heading level={4} size="small">
                                                {strings.noEvents}
                                            </Heading>
                                        </Box>

                                :
                                    eventsByDay[formatDateFns(selectedDay)].map((it, idx) => (
                                        <EventBox
                                            height={{ min: 'fit-content' }}
                                            width="100%"
                                            title={it.title}
                                            description={it.description}
                                            type={it.type}
                                            day={formatDateFns(it.day)}
                                            payload={it.payload}
                                            bookmarkColor={getColorByEventType(it.type)}
                                            icon={
                                                <Tip content={<Box color="textWhite">{getDescStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito, strings)}</Box>}>
                                                    {getIconStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito, getColorStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito))}
                                                </Tip>
                                            }
                                            onClick={() => {
                                                if(it.type === TipiEventi.Autolettura) {
                                                    navigate(`/${AllRoutes[RoutesEnum.SELF_READING]}`)
                                                } else {
                                                    if (! it.payload) {
                                                        return
                                                    }
                                                    navigate(`/${AllRoutes[RoutesEnum.BILL]}/${it.payload?.nrFattura}`)
                                                }
                                            }}
                                            key={idx}
                                        />
                                    ))
                                }
                            </Box>
                        </Box>
                        <BSeparator
                            size="2px"
                            margin={{ horizontal: '5px' }}
                            direction={size === 'medium' ? 'vertical' : 'horizontal'}
                        />
                        <Box
                            width={size === 'medium' ? '50%' : '100%'}
                            height={size === 'large' ? '50%' : undefined}
                            gap="small"
                        >

                            <Box
                                direction="row"
                                gap="small"
                                align="baseline"
                                width={{ min: 'max-content' }}
                            >
                                <Heading size="small">
                                    {`${strings.eventi} ${strings.neiProssimi} ${FUTURE_EVENTS_DAYS} ${strings.giorni}`}
                                </Heading>
                            </Box>
                            <Box
                                gap="small"
                                direction="column"
                                margin={{bottom: "small"}}
                                style={{overflowY: 'scroll'}}
                                pad={{ vertical: 'small' }}
                            >
                                    {!nextEvents.length
                                        ?
                                        <Box
                                            direction="row"
                                            align="baseline"
                                        >
                                            <Heading level={4} size="small">
                                                {strings.noEvents}
                                            </Heading>
                                        </Box>

                                        :
                                        nextEvents.map((it, idx) => (
                                            <EventBox
                                                height={{min: 'fit-content'}}
                                                width="100%"
                                                title={it.title}
                                                description={it.description}
                                                type={it.type}
                                                day={formatDateFns(it.day)}
                                                payload={it.payload}
                                                bookmarkColor={getColorByEventType(it.type)}
                                                icon={
                                                    <Tip content={<Box color="textWhite">{getDescStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito, strings)}</Box>}>
                                                        {getIconStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito, getColorStatoPagamento(it.payload?.statoPagamento || StatiPagamento.NonDefinito))}
                                                    </Tip>
                                                }
                                                onClick={() => {
                                                    if (it.type === TipiEventi.Autolettura) {
                                                        navigate(`/${AllRoutes[RoutesEnum.SELF_READING]}`)
                                                    } else {
                                                        if (!it.payload) {
                                                            return
                                                        }
                                                        navigate(`/${AllRoutes[RoutesEnum.BILL]}/${it.payload?.nrFattura}`)
                                                    }
                                                }}
                                                key={idx}
                                            />
                                        ))
                                    }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Planner
