import { IOCServices } from '..'

const loadRequestFromOperatorAPI = async (
    idRichiesta: number,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID

    try {
        const api = `/Richiesta/GetRichiestaUtenteAsOperatore/${idRichiesta}`
        const res = await rest.get(api)

        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadRequestFromOperatorAPI
