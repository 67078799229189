import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RIDModel, SignedModule, SupplyModel} from '../../models/models'

const sendSignedModuleAPI = async (
    module: SignedModule,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const restID = container.restID
    const restAPI = container.rest
    try {
        const formData = new FormData();
        formData.append("Data", module.file)
        //formData.append("fileName", module.filename)

        const res = await restID.post(`/Richiesta/PostSignedModule/${module.idRichiesta}`, formData)

        if (res.status == HttpStates.OK) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default sendSignedModuleAPI
