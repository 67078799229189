import { TipiPagina } from '../../../models/Enums'
import { formatDate } from '../../../utilities'
import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_CLIENT_INFO,
    SET_TP_COOKIE,
    SET_TP_OFFERCHANGE,
    SET_TP_PRIVACY1,
    SET_TP_PRIVACY1SHORT,
    SET_TP_PRIVACY2,
    SET_TP_PRIVACY2SHORT,
    SET_TP_PRIVACY3,
    SET_TP_PRIVACY3SHORT,
    SET_TP_REGISTRA1,
    SET_TP_REGISTRA2,
    SET_TP_REGISTRA3
} from './types'

const getAnagraficaUtente: AppAsyncAction<any> =
    (codiceUtente: string) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        //const supply = useAppSelector((state) => state.supply)
        try {
            const user = await container.api.getAnagraficaUtenteAPI(codiceUtente)
            user.value.dataNascitaAPI = user.value.dataNascita

            let indirizzo = ''
            indirizzo = indirizzo.concat(user.value.tipoViaResidenza,' ',user.value.viaResidenza,' ',user.value.nrCivicoResidenza,', ',user.value.comuneResidenza,' (',user.value.provinciaResidenza,')')
            if (indirizzo.length > 0) {
                user.value.indirizzo = indirizzo
            }

            if (user.value.dataNascita?.length > 0) {
                const date = new Date(user.value.dataNascita)
                let dataNascita = formatDate(date)

                user.value.dataNascita = dataNascita
            }

            dispatch({ type: SET_CLIENT_INFO, payload: user.value })

            const tp_privacy1 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy1)
            const tp_privacy2 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy2)
            const tp_privacy3 = await container.api.getTipiPaginaAPI(TipiPagina.Privacy3)
            const tp_registra1 = await container.api.getTipiPaginaAPI(TipiPagina.Registra1)
            const tp_registra2 = await container.api.getTipiPaginaAPI(TipiPagina.Registra2)
            const tp_registra3 = await container.api.getTipiPaginaAPI(TipiPagina.Registra3)
            const tp_cookie = await container.api.getTipiPaginaAPI(TipiPagina.Cookie)
            const tp_privacy1short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy1Short)
            const tp_privacy2short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy2Short)
            const tp_privacy3short = await container.api.getTipiPaginaAPI(TipiPagina.Privacy3Short)
            const tp_offerChange = await container.api.getTipiPaginaAPI(
                TipiPagina.CambioOfferta,
            )

            dispatch({type: SET_TP_PRIVACY1, payload: tp_privacy1})
            dispatch({type: SET_TP_PRIVACY2, payload: tp_privacy2})
            dispatch({type: SET_TP_PRIVACY3, payload: tp_privacy3})
            dispatch({type: SET_TP_REGISTRA1, payload: tp_registra1})
            dispatch({type: SET_TP_REGISTRA2, payload: tp_registra2})
            dispatch({type: SET_TP_REGISTRA3, payload: tp_registra3})
            dispatch({type: SET_TP_COOKIE, payload: tp_cookie})
            dispatch({type: SET_TP_PRIVACY1SHORT, payload: tp_privacy1short})
            dispatch({type: SET_TP_PRIVACY2SHORT, payload: tp_privacy2short})
            dispatch({type: SET_TP_PRIVACY3SHORT, payload: tp_privacy3short})
            dispatch({ type: SET_TP_OFFERCHANGE, payload: tp_offerChange })

            return user
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getAnagraficaUtente
