import { useEffect, useState } from 'react'
import {
    Box,
    BoxProps,
    Text,
    TextArea,
    TextAreaExtendedProps,
} from 'grommet'
import get from 'lodash.get'
import BErrorMessage from './BErrorMessage'

const BTextArea = ({
    handleChange,
    handleBlur,
    errors,
    name,
    renderIcon,
    label,
    values,
    containerProps,
    mandatory,
    fast,
    ...rest
}: BTextAreaProps) => {
    const [isFocused, setFocused] = useState(false)
    const initialValue = get(values, name)
    const [value, setValue] = useState(initialValue)
    const textValue = values && fast ? value : initialValue
    useEffect(() => {
        if (fast) {
            setValue(initialValue)
        }
    }, [fast, initialValue])
    return (
        <Box justify="start" {...containerProps}>
            <Box direction="row">
                {label && <Text color={'primary_color'}>{label}</Text>}
                {mandatory && (
                    <Text weight="bold" size="small" margin={{ left: '5px' }}>
                        *
                    </Text>
                )}
            </Box>
            <Box
                direction="row"
                align="center"
                border={{ color: isFocused ? 'brand' : 'control-border' }}
                round={'8px'}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            >
                <TextArea
                    {...rest}
                    plain
                    resize={false}
                    onChange={(e) => {
                        if (handleChange && !fast) {
                            handleChange(name)(e)
                        }
                        if (fast) {
                            setValue(e.currentTarget.value)
                        }
                    }}
                    onBlur={(e) => {
                        if (handleBlur) {
                            handleBlur(name)(e)
                        }
                        if (fast) {
                            handleChange(name)(e)
                        }
                    }}
                    value={textValue}
                />
                {!!renderIcon && renderIcon()}
            </Box>
            <BErrorMessage name={name} errors={errors} />
        </Box>
    )
}

type BTextAreaProps = {
    handleChange: Function
    handleBlur?: Function
    errors: {}
    name: string
    renderIcon?: () => JSX.Element
    label?: string
    values: {}
    containerProps?: BoxProps
    mandatory?: boolean
    fast?: boolean
} & TextAreaExtendedProps

export default BTextArea
