import { Box, BoxExtendedProps, Text } from 'grommet'
import useAssets from '../hooks/useAssets'
import BCard from './BCard'

const ReminderBox = ({
    title,
    tipoServizio,
    ...rest
}: ReminderBoxProps) => {
    const assets = useAssets()
    return (
        <Box {...rest} >
            <BCard pad={"small"} margin={"5px"}>
                <Box direction="row" align="start" justify="between">
                    <Text size="small" weight="bold">
                        {title}
                    </Text>
                    {tipoServizio==="E"?<assets.EnergyIcon ss="20"/>:tipoServizio==="GPL"?<assets.GplIcon ss="20px"/>:<assets.GasIcon ss="20"/>}
                </Box>
            </BCard>
        </Box>
    )
}

type ReminderBoxProps = {
    title: string,
    tipoServizio: string
} & BoxExtendedProps

export default ReminderBox
