import { RegistrationModel, UserModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const insertRichiestaPrivacyRegistration =
    (user: RegistrationModel): AppAction<any> => async (dispatch, getState, container) => {
        try {  
                  
            const editData = await container.api.insertRichiestaPrivacyRegistrationAPI(user)
            const lan = getState().common.language
            const i18n = await container.strings(lan)

            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaPrivacyRegistration
