import React from "react";
import { useEffect, useRef, useState } from "react";
import { SportelloModel } from '../models/models'
// interface MarkerProps {  
//     centX: number
//     centY: number
//     mark: SportelloModel
// }

// const MarkerComponent = (
//     {
//         centX,
//         centY,
//         mark
//     }: MarkerProps

// ) =>{

const MarkerComponent: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
    
    React.useEffect(() => {
        if (!marker) {
        setMarker(new google.maps.Marker());
        }
    
        // remove marker from map on unmount
        return () => {
        if (marker) {
            marker.setMap(null);
        }
        };
    }, [marker]);
    
    React.useEffect(() => {
        if (marker) {
        marker.setOptions(options);
        }
    }, [marker, options]);
    
    return null;
};

// const ref = useRef<HTMLDivElement>(null);
// const [map, setMap] = useState<google.maps.Map>();

// useEffect(() => {

//   if (ref.current && !map) {
//     setMap(new window.google.maps.Map(ref.current,
//         {
//             zoom: 10,
//             center: { lat: centX!, lng: centY! }

//         }
//     ));

//     marks.forEach((it: SportelloModel) => {
//         var marker = new google.maps.Marker({
//             position: { lat: +it.latitudine, lng: +it.longitudine },
//             title: it.nomeAzienda,
//             map: map
//         })
//     });
//   }

// }, []);
// return (


//  <div style={{width: "50%", height: "50vh"}} ref={ref} />


// )
// }

export default MarkerComponent
