import {
    RequestDetailsBoxPropsModel,
    RequestDetailsModel,
    RequestModel,
    RequestTypeModel,
} from '../../../models/models'

import {
    REMOVE_REQUESTS_FILTERS,
    SET_REQUESTS,
    SET_REQUESTS_DETAILS,
    SET_REQUESTTYPES,
    SET_SELECTEDREQUEST,
    SET_SELECTEDREQUESTID
} from '../actions/types'

interface RequestInitialState {
    requests: Array<RequestModel>
    requestsDetails: Array<RequestDetailsModel>
    requestTypes: Array<RequestTypeModel>
    selectedRequest: RequestDetailsBoxPropsModel
    selectedRequestID: string
}

export const INITIAL_STATE: RequestInitialState = {
    requests: [],
    requestsDetails: [],
    requestTypes: [],
    selectedRequest: {
        title: '',
        descRequest: '',
        pod: '',
        utente: '',
        liv: 0,
        descState: '',
        date: '',
        req: undefined,
        reqDetails: undefined,
    },
    selectedRequestID: '',
}

const request = (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_REQUESTS:
            return {
                ...state,
                requests: payload,
            }
        case SET_REQUESTS_DETAILS:
            return {
                ...state,
                requestsDetails: payload,
            }
        case REMOVE_REQUESTS_FILTERS:
            return {
                ...INITIAL_STATE,
            }
        case SET_REQUESTTYPES:
            return {
                ...state,
                requestTypes: payload,
            }
        case SET_SELECTEDREQUEST:
            return {
                ...state,
                selectedRequest: payload,
            }
        case SET_SELECTEDREQUESTID:
            return {
                ...state,
                selectedRequestID: payload,
            }
        default: {
            return state
        }
    }
}

export default request
