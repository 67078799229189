import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RegistrationModel, UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

const registrazioneUtenteEmailAPI =
    async (user: RegistrationModel, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        
        const restID = container.restID
        const restAPI = container.rest
        
        try {
            const res = await restID.post(
                '/Utente/RegistrazioneUtenteEmail',{
                    IDTipoUtente: user.IDTipoUtente,
                    CodiceUtente: user.CodiceUtente,
                    Nominativo: user.Nominativo,
                    UserName: user.UserName,
                    Password: user.Password,
                    IDStatoUtente: user.IDStatoUtente,
                    Email: user.Email,
                    PrivacyGDPR1: user.Privacy1,
                    PrivacyGDPR2: user.Privacy2,
                    PrivacyGDPR3: user.Privacy3,
                    Privacy1: user.Registra1,
                    Privacy2: user.Registra2,
                    Privacy3: user.Registra3,
                    Cellulare: user.Cellulare??'',
                    Telefono: user.Telefono??'',
                    TokenReg: user.TokenReg,
                    idRichiesta: user.IDRichiesta,
                    CodiceConvenzione: 'Nessuna'
            })
            
            if (res.status == HttpStates.OK) {

                return true
            }
            else {
                return false
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default registrazioneUtenteEmailAPI
