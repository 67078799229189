import { IOCServices } from '..'
import { Allegato } from '../../models/models'

const getAllegatiReclamoAPI = async (
    reclamoID: number,
    container?: IOCServices,
): Promise<Allegato[]> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID
    try {
        const res = await rest.get(
            `/Reclami/GetAllegatiReclamo?idReclamo=${reclamoID}`,
        )
        const data = res.data
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getAllegatiReclamoAPI
