import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'

const editDatiAnagraficiAPI = async (
    editItem: UserModel,
    isOperator: boolean,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject('Container is null. API must be called from api service')
    }

    const restID = container.restID

    try {
        const res = await restID.post('/Richiesta/InsertRichiestaCambioDatiAnagrafici/', {
            CodiceUtente: editItem.codiceUtente,
            AnagBarrato: '',
            AnagCAP: editItem.capResidenza,
            AnagCellulare: editItem.cellulare,
            AnagCodFisc: editItem.codiceFiscale,
            AnagComune: editItem.comuneResidenza,
            AnagComuneDesc: '',
            AnagDataNascita: editItem.dataNascitaAPI === '' ? null : editItem.dataNascitaAPI,
            AnagEmailAddress: editItem.email,
            AnagFax: editItem.fax,
            AnagEmailPEC: editItem.pec,
            AnagFrazione: '',
            AnagInterno: '',
            AnagLuogoNascita: editItem.luogoNascita,
            AnagProvincia: editItem.provinciaResidenza,
            AnagNumCivico: editItem.nrCivicoResidenza,
            AnagPiano: '',
            AnagPIVA: '',
            AnagNazioneNascita: '',
            AnagProvNascita: '',
            AnagScala: '',
            AnagTelefono: editItem.telefono,
            AnagTipoVia: editItem.tipoViaResidenza,
            AnagVia: editItem.viaResidenza,
            IsOperator: isOperator
        })
        return res.status === HttpStates.OK
    } catch (e) {
        return Promise.reject(e)
    }
}

export default editDatiAnagraficiAPI
