import {
    Avatar,
    Box,
    BoxExtendedProps,
    Text,
} from 'grommet'
import useI18n from '../../hooks/useI18n'
const HeaderBar = ({
    onClick,
    onProfileClick,
    header,
    name,
    ...rest
}: HeaderBarProps & BoxExtendedProps) => {
    const strings = useI18n()
    return (
        <Box
            tag="header"
            align="center"
            direction="row"
            pad="small"
            background={'sidebar'}
            fill='horizontal'
            {...rest}
            justify='between'
        >
            <Box
                gap="small"
                direction="row"
                align="center"
                onClick={onProfileClick}
            >
                <Avatar
                    round="small"
                    background={'brand'}
                    size='medium'
                    alignSelf="start"
                >
                    {name[0]?.toUpperCase() ?? '-'}
                </Avatar>
                <Box
                    flex
                    direction='column'
                    justify='between'
                >
                    <Text size="small" weight={'bold'}>
                        {strings.hello}
                    </Text>
                    <Text size="small" weight={'bold'}>
                        {`${name}!`}
                    </Text>
                </Box>
            </Box>
            <Box width='xsmall'>
                {header}
            </Box>
        </Box>
    )
}

type HeaderBarProps = {
    header?: JSX.Element
    onClick: (id: string) => void
    onProfileClick: () => void
    name: string
}

export default HeaderBar
