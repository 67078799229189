import get from 'lodash.get'
import useTheme from '../hooks/useTheme'

const OtherMenuIcon = ({
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string }) => {
    const { global } = useTheme()
    const currentColor = get(global?.colors, color || '')
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" {...rest}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0563 0.333328C21.0101 0.333328 23.6666 3.10766 23.6666 7.23533V16.7647C23.6666 20.8923 21.0101 23.6667 17.0551 23.6667H6.94248C2.98865 23.6667 0.333313 20.8923 0.333313 16.7647V7.23533C0.333313 3.10766 2.98865 0.333328 6.94248 0.333328H17.0563ZM17.0563 2.08333H6.94248C3.99081 2.08333 2.08331 4.10516 2.08331 7.23533V16.7647C2.08331 19.8948 3.99081 21.9167 6.94248 21.9167H17.0551C20.008 21.9167 21.9166 19.8948 21.9166 16.7647V7.23533C21.9166 4.10516 20.008 2.08333 17.0563 2.08333ZM16.6062 10.8493C17.2514 10.8493 17.7729 11.3708 17.7729 12.016C17.7729 12.6611 17.2514 13.1826 16.6062 13.1826C15.961 13.1826 15.4337 12.6611 15.4337 12.016C15.4337 11.3708 15.9505 10.8493 16.5945 10.8493H16.6062ZM11.9282 10.8493C12.5734 10.8493 13.0949 11.3708 13.0949 12.016C13.0949 12.6611 12.5734 13.1826 11.9282 13.1826C11.2831 13.1826 10.7569 12.6611 10.7569 12.016C10.7569 11.3708 11.2726 10.8493 11.9177 10.8493H11.9282ZM7.2513 10.8493C7.89646 10.8493 8.41796 11.3708 8.41796 12.016C8.41796 12.6611 7.89646 13.1826 7.2513 13.1826C6.60613 13.1826 6.0788 12.6611 6.0788 12.016C6.0788 11.3708 6.59563 10.8493 7.2408 10.8493H7.2513Z"
                fill={currentColor?.toString() ?? '#303972'}
            />
        </svg>
    )
}

export default OtherMenuIcon
