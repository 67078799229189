import { TipiPagina } from '../../../models/Enums'
import { InvoiceFiltersModel, RequestDetailsModel } from '../../../models/models'
import { formatDate } from '../../../utilities'
import { AppAction, AppAsyncAction, useAppSelector } from '../../storeProvider'
import setNotification from './setNotification'

const getUtenteNonRegistrato: AppAsyncAction<any> =
    (codiceUtente: string, codiceFiscale: string) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        //const supply = useAppSelector((state) => state.supply)
        try {
            
            const user = await container.api.getUtenteNonRegistratoAPI(codiceUtente, codiceFiscale)
            
            return user
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getUtenteNonRegistrato

