import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { SupplyModel } from '../../models/models'

export type checkAutologinTokenValidityType = {
    Token: string
}

const checkAutologinTokenValidityAPI = async (
    item: checkAutologinTokenValidityType,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {    
        const res = await rest.get(
            '/Utente/CheckAutologinTokenValidity?Token='.concat(item.Token)
        )
        if (res.status == HttpStates.OK) {
            const data = res.data.value
            return data
        }
        
    } catch (e) {
        return Promise.reject(e)
    }
}

export default checkAutologinTokenValidityAPI
