import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { useAppSelector } from '../storeProvider'

const getUtenteNonRegistratoAPI =
    async (codiceUtente: string, codiceFiscale: string, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const rest = container.restID
        
        try {
            const res = await rest.get(
                `/Utente/GetUtenteCUeCFNonRegistrato?CodiceUtente=${codiceUtente}&CodiceFiscale=${codiceFiscale}`
            )

            if (res.status == HttpStates.OK) {
                const data = res.data.value
                return data
            }
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default getUtenteNonRegistratoAPI
