import { TipiPagina } from '../../../models/Enums'
import { RecontractingSupplies } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_RECONTRACTING, SET_TP_OFFERCHANGE } from './types'

const loadSuppliesRecontracted =
    (codiceUtente: string): AppAction<RecontractingSupplies[]> =>
    async (dispatch, _, container) => {
        try {
            const recontracting =
                await container.api.loadSuppliesRecontractedAPI(codiceUtente)
            dispatch({ type: SET_RECONTRACTING, payload: recontracting })
            const tp_offerChange = await container.api.getTipiPaginaAPI(
                TipiPagina.CambioOfferta,
            )
            dispatch({ type: SET_TP_OFFERCHANGE, payload: tp_offerChange })

            return recontracting
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadSuppliesRecontracted
