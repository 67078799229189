import { Box, Heading, Spinner } from 'grommet'
import { useNavigate, useLocation } from 'react-router'
import useI18n from '../hooks/useI18n'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import SupplySelector from '../components/SupplySelector'
import DisdettaForm from '../components/forms/DisdettaForm'
import { reverseStringDate } from '../utilities'
import BBackButton from '../components/BBackButton'
import insertRichiestaDisdettaContratto from '../services/store/actions/insertRichiestaDisdettaContratto'
import streetTypes from '../models/streetTypes'
import useAppQuery from '../hooks/useAppQuery'
import getProvinceComuni from '../services/store/actions/getProvinceComuni'
import { useEffect } from 'react'
import useAbilitaTipoUsoContatore from '../hooks/useAbilitaTipoUsoContatore'

const DisdettaContratto = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const supply = useAppSelector((state) => state.supply)
    const [isLoadingProvinces, queryProvinces] = useAppQuery(getProvinceComuni)
    const navigate = useNavigate()
    const location = useLocation()
    const disabilitaDisdetta = useAbilitaTipoUsoContatore()
    const state = location.state as { path?: string }
    const provincesCities = useAppSelector(
        (state) => state.main.provincesCities,
    )

    useEffect(() => {
        queryProvinces()
    }, [queryProvinces])
    if (supply.loading) {
        return (
            <Box fill background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
                <Box pad="xsmall" direction="row" align="center" gap="small">
                    {supply.loading ? <></> : strings.rangeClosed}
                </Box>
            </Box>
        )
    }
    if (disabilitaDisdetta && supply.selectedSupply) {
        return (        
            <Box flex pad="small"> 
                <Box direction="row" gap="small" align="center">
                    <BBackButton
                        size="small"
                        onClick={() => navigate(state?.path ?? '/requests')}
                    />
                    <Heading level={3}>{strings.DisdettaContratto}</Heading>
                </Box>
                <SupplySelector disableInactiveSupply />
                <Box pad="xsmall" direction="row" gap="small">
                    {strings.disabilitaDisdettaContratto}
                </Box>
            </Box>
        )
    }
    return (
        <Box flex pad="small">
            <Box direction="row" flex="grow" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => navigate(state?.path ?? '/requests')}
                />
                <Heading level={3}>{strings.DisdettaContratto}</Heading>
            </Box>
            <Box gap="small" flex="grow">
                <SupplySelector disableInactiveSupply />
                {supply.selectedSupply && (
                    <DisdettaForm
                        disabled={false}
                        isLoading={supply.loading || isLoadingProvinces}
                        onBlur={(values) => {}}
                        onSubmit={async (values) => {
                            try {
                                values.DataUltimaLettura = reverseStringDate(
                                    values.DataUltimaLettura,
                                )
                                await dispatch(
                                    insertRichiestaDisdettaContratto(values),
                                )
                                navigate(state?.path ?? '/requests')
                            } catch (e) {}
                        }}
                        cities={provincesCities}
                        streetTypes={streetTypes}
                        supply={supply.selectedSupply}
                        lastDate={supply.selectedSupply.dataLettura}
                    />
                )}
            </Box>
        </Box>
    )
}

export default DisdettaContratto
