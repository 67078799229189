import { useState } from 'react'
import * as Yup from 'yup'
import { Box, Button, Layer, Spinner } from 'grommet'
import useI18n from '../../hooks/useI18n'
import BFormik from '../BFormik'

import useRequiredFields from '../../hooks/useRequiredFields'
import BTextInput from '../BTextInput'
import { useAppDispatch, useAppSelector } from '../../services/storeProvider'
import { CircleInformation, Hide, UserAdmin, View } from 'grommet-icons'
import { SET_PWDCOMPLEXITYERROR } from '../../services/store/actions/types'
import { editPasswordType } from '../../services/api/editPasswordGuestAPI'
import InfoBox from '../InfoBox'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

const CambioPasswordForm = ({
    disabled,
    loading,
    pwdComp,
    onSubmit,
    onBlur

}: CambioPasswordProps) => {

    const strings = useI18n()
    const dispatch = useAppDispatch()

    const [showModal, setShowModal] = useState(false)
    const [isCheckTutte,setCheckTutte] = useState(false)
    const [getTipoInvio, setTipoInvio] = useState(0)
    const user = useAppSelector((state) => state.user.clientInfo)
    const [reveal1, setReveal1] = useState(false)
    const [reveal2, setReveal2] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { path?: string }

    const schema = Yup.object().shape({
        //Password: Yup.string().required(strings.mandatoryField),
        Password: Yup.string().test('TestPwd', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "") {
                    throw Error(strings.mandatoryField)
                }

                if (user.isPwdComplexityError) {
                    dispatch({
                        type: SET_PWDCOMPLEXITYERROR,
                        payload: false,
                    })
                    throw Error(strings.complessitaPassword)
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
        RepeatPassword: Yup.string().required(strings.mandatoryField).oneOf([Yup.ref('Password'), null], strings.passwordNonCoincidono),
        Token: Yup.string().notRequired(),
    })
    const isRequired = useRequiredFields(schema)

    return (
        <Box flex>
            <BFormik
                initialValues={{
                    Password: '',
                    RepeatPassword: '',
                    Token: ''
                }}

                enableReinitialize
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={true}
                initialErrors={user.isPwdComplexityError ? { Password: strings.complessitaPassword } : undefined}
                blurCallback={({ values }) => {

                    if (!disabled) {
                        onBlur(values)
                    }

                }}
                onSubmit={(values) => {
                    onSubmit(values)

                }}

                element={({
                    values,
                    errors,
                    validateForm,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                }) => {
                   return (

                    <Box
                        gap="small"
                        direction="column"
                        >

                        <Box direction="row">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="Password"
                                mandatory={isRequired('Password')}
                                label={strings.newPassword}
                                placeholder={strings.phNewPassword}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                type={reveal1 ? 'text' : 'password'}
                                renderIcon={() => (
                                    <Button
                                        icon={
                                            reveal1 ? (
                                                <View size="medium" />
                                            ) : (
                                                <Hide size="medium" />
                                            )
                                        }
                                        onClick={() => setReveal1(!reveal1)}
                                    />
                                )}
                            />
                            <Button

                                icon={
                                    <CircleInformation size="30%" />
                                }
                                onClick={() => {
                                    //console.log('modale')
                                    setShowModal(true)
                                }}
                            />

                        </Box>
                        <Box direction="row">
                        <BTextInput
                            fast
                            //disabled={disabled}
                            containerProps={{ flex: true }}
                            name="RepeatPassword"
                            mandatory={isRequired('RepeatPassword')}
                            label={strings.repeatNewPassword}
                            placeholder={strings.phRepeatNewPassword}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            type={reveal2 ? 'text' : 'password'}
                            renderIcon={() => (
                                <Button
                                    icon={
                                        reveal2 ? (
                                            <View size="medium" />
                                        ) : (
                                            <Hide size="medium" />
                                        )
                                    }
                                    onClick={() => setReveal2(!reveal2)}
                                />
                            )}
                        />
                        <Box width="60px" />
                        </Box>
                        <Box direction="row">
                        <Button
                            disabled={loading} //|| supplies?.length == 0
                            //width="20%"
                            //size="medium"
                            style={{
                                width:"100%",
                                //minWidth:"10%",
                                height:"50px",

                            }}

                            onClick={async () => {
                                try {

                                    const res = await validateForm()

                                    const isValid = isEmpty(res)
                                    if (isValid) {
                                        handleSubmit()
                                        //navigate('/login')
                                    }

                                } catch (e) {

                                }
                            }}
                            icon={
                                (loading) ? ( //|| supplies?.length == 0
                                    <Spinner size="small" />
                                ) : (
                                    <UserAdmin />
                                )
                            }
                            label={strings.setNewPassword}
                            primary
                        />
                        <Box width="60px" />
                        </Box>

                        {showModal && (
                                <Layer
                                    position="center"
                                    onClickOutside={() => setShowModal(false)}
                                    onEsc={() => setShowModal(false)}>
                                    <Box
                                        //pad="medium"

                                        gap="small"
                                        width={{ min: 'small' }}
                                        height={{ min: 'small' }}
                                        fill>
                                        <InfoBox
                                            title={strings.complessitaPassword}
                                            content={strings.hintComplessitaPassword}
                                            onBackPress={() =>
                                                setShowModal(false)
                                            }
                                            onDonePress={() =>
                                                setShowModal(false)
                                            }
                                            hideImg={true}
                                        />
                                    </Box>
                                </Layer>
                        )}

                    </Box>

                    )
                }}
            />
        </Box>
    )
}

export default CambioPasswordForm

interface CambioPasswordProps {
    loading?: boolean
    onBlur: (cambio: editPasswordType) => any
    onSubmit: (
        cambio: editPasswordType,
    ) => void | Promise<void> | Promise<editPasswordType>
    pwdComp: boolean
    userName?: string
    Icon?: JSX.Element
    disabled?: boolean
    UserName?: string
    OldPassword?: string
    NewPassword?: string
    RepeatNewPassword?: string
}
