import { ObjectSchema, reach } from 'yup'
import { ObjectShape } from 'yup/lib/object'


/**
 * Returns whether or not a field is required.
 * Pass the current form values as the second argument of to the returned function
 * if you use dynamic validation (for ecample if you use Yup.string().when(...))
 * 
 * @param schema 
 * @returns (field: string, values?: any): boolean
 */
const useRequiredFields = (schema: ObjectSchema<ObjectShape>) => {
    const requiredCheck = (field: string, values?: any): boolean => {
        const newSchema = values
            ? reach(schema, field).resolve({
                  parent: values,
              })
            : reach(schema, field)
        return !!newSchema
            .describe()
            .tests.find((it: any) => it.name === 'required')
    }

    return requiredCheck
}

export default useRequiredFields
