import { RangeAutoletturaAdminModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_RANGES } from './types'

const loadRangeAutolettura =
    (): AppAction<Array<RangeAutoletturaAdminModel>> => async (dispatch, _, container) => {
        try {
            const ranges = await container.api.loadRangeAutoletturaAPI()
            dispatch({ type: SET_RANGES, payload: ranges })
            return ranges
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadRangeAutolettura
