import { Box, BoxProps, Text } from 'grommet'
import get from 'lodash.get'

type BErrorMessagePropsType = {
    errors: {}
    name: string
} & BoxProps

const BErrorMessage = ({ errors, name, ...props }: BErrorMessagePropsType) => {
    return (
        <Box height={{min: '20px'}} {...props}>
            {errors && typeof get(errors, name) === 'string' && (
                <Text color="status-error" size={'small'}>
                    {get(errors, name)}
                </Text>
            )}
        </Box>
    )
}

export default BErrorMessage
