import { Box, Heading, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

import useI18n from '../hooks/useI18n'

import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import { insertRichiestaFatturaViaMail } from '../services/store/actions'
import useSize from '../hooks/useSize'
import { ParamModel } from '../models/models'

import FatturaViaMailForm from '../components/forms/FatturaViaMailForm'
import SupplySelector from '../components/SupplySelector'
import BBackButton from '../components/BBackButton'
import { paramEnum, StatiFornitura, TipoInvio } from '../models/Enums'
import loadPublicParams from '../services/store/actions/loadPublicParams'
import { SET_PUBLIC_PARAMS } from '../services/store/actions/types'
import { getBoolParam } from '../utilities'

const FatturaViaMail = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const main = useAppSelector((state) => state.main)
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { path?: string }
    const [isLoadingParams, queryParams] = useAppQuery(loadPublicParams)
    const [currParams, setParams] = useState<Array<ParamModel>>([])
    const [numeroCampiFatturaViaMail, setNumeroCampiFatturaViaMail] =
        useState(0)
    const tipiInvio = [
        { DeliveryType: strings.Email, DeliveryTypeCode: TipoInvio.SoloMail },
    ]
    const disabledSupply =
        supply.selectedSupply?.idStatoFornitura === StatiFornitura.Cessata

    const loadParams = async () => {
        try {
            const publicParam = await queryParams()
            dispatch({ type: SET_PUBLIC_PARAMS, payload: publicParam })
            setParams(publicParam)
            const pValue = publicParam.find(
                (it: ParamModel) =>
                    it.idTipoParametroAzienda ===
                    paramEnum.WEB_NumeroCampiFatturaViaMail,
            )
            const numberOfMails = parseInt(pValue?.valore || 0)
            setNumeroCampiFatturaViaMail(
                numberOfMails > 0 && numberOfMails <= 4 ? numberOfMails : 4,
            )
        } catch (e) {}
    }

    const TipoInvioMisto = getBoolParam(
        main.publicParam,
        paramEnum.WEB_FatturaViaMail_AbilitaTipoInvioMisto,
    )
    if (TipoInvioMisto) {
        tipiInvio.push({
            DeliveryType: strings.Misto,
            DeliveryTypeCode: TipoInvio.MailEPosta,
        })
    }

    useEffect(() => {
        loadParams()
    }, [])

    if (supply.loading || isLoadingParams) {
        return (
            <Box fill background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }

    return (
        <Box flex pad="small">
            <Box direction="row" flex="grow" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => {
                        navigate(state?.path ?? '/requests')
                    }}
                />
                <Heading level={3}>{strings.fatturaViaMail}</Heading>
            </Box>
            <Box
                gap="small"
                flex="grow"
                width={size !== 'small' ? 'large' : undefined}
            >
                <SupplySelector disableInactiveSupply />
                {supply.selectedSupply && !disabledSupply && (
                    <FatturaViaMailForm
                        loading={false}
                        onSubmit={async (values) => {
                            try {
                                await dispatch(
                                    insertRichiestaFatturaViaMail(values),
                                )
                                navigate(state?.path ?? '/requests')
                            } catch (e) {}
                        }}
                        user={user}
                        supply={supply.selectedSupply}
                        tipiInvio={tipiInvio}
                        supplies={supply.supplies}
                        numeroCampi={numeroCampiFatturaViaMail}
                    />
                )}
                {disabledSupply && (
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small"
                    >
                        <Text>{strings.richiesteCessate}</Text>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default FatturaViaMail
