import {
    Box,
    InfiniteScroll,
    Collapsible,
    Spinner,
    Text,
    TextInput,
    Heading,
} from 'grommet'
import {
    Search,
} from 'grommet-icons'
import { useEffect, useMemo, useState } from 'react'
import useI18n from '../hooks/useI18n'
import useAppQuery from '../hooks/useAppQuery'
import { SupplyModel } from '../models/models'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import loadSupplies from '../services/store/actions/loadSupplies'
import useTheme from '../hooks/useTheme'
import { SET_SUPPLIES_LOADED, SET_SUPPLY } from '../services/store/actions/types'
import SupplyBox from './SupplyBox'
import useAssets from '../hooks/useAssets'
import BSeparator from './BSeparator'
import useSize from '../hooks/useSize'
import { StatiFornitura } from '../models/Enums'
import { getDescStatoFornitura } from '../utilities'

interface ISupplySelectorProps {
    disableInactiveSupply?: boolean
}

const SupplySelector = ({ disableInactiveSupply } : ISupplySelectorProps) => {
    const size = useSize()
    const strings = useI18n()
    const { selectedSupply, addresses} = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const [isLoading, querySupplies] = useAppQuery(loadSupplies)
    const [currSupplies, setSupplies] = useState<Array<SupplyModel>>([])
    const [isExpanded, setExpanded] = useState(false)
    const [filter, setFilter] = useState('')
    const theme = useTheme()
    const assets = useAssets()
    const dispatch = useAppDispatch()
    
    const loadSuppliesData = async () => {
        try {
            const suppliesD = await querySupplies(selectedSupply?.codiceUtente ?? user.codiceUtente)

            setSupplies(suppliesD)

        } catch (e) {}
    }

    useEffect(() => {
        if (! user.codiceUtente) {
            return
        }
        dispatch({ type: SET_SUPPLIES_LOADED, payload: true })
        loadSuppliesData()
        dispatch({ type: SET_SUPPLIES_LOADED, payload: false })
    }, [user])

    const filteredSupplies = useMemo(() => {
        if (!currSupplies) {
            return []
        }

        if (!filter) {
            return currSupplies
        }

        return currSupplies.filter(it => it.puntoGas.includes(filter) || it.pod.includes(filter))

    }, [filter, currSupplies])

    const onChangeFilter = (e:any) => {
        setFilter(e.target.value)
    }

    if (isLoading && ! selectedSupply) {
        return (
            <Box background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }

    if (!selectedSupply) {
        return (
            <Box background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Heading level={3}>{strings.noSelectableSupplies}</Heading>
                </Box>
            </Box>
        )
    }


    return (
        <Box
            width={{
                min: "350px",
                width: size !== 'small' ? 'large' : undefined
            }}
        >
            <SupplyBox
                onClick={()=>{
                    setExpanded(!isExpanded)
                    setFilter('')
                }}
                title={`${selectedSupply.tipoServizio==="E"?strings.pod:strings.pdr} ${selectedSupply.pod}`}
                margin={{ bottom: 'small' }}
                description={`${selectedSupply.tipoVia} ${selectedSupply.via} ${selectedSupply.numeroCivico}, ${selectedSupply.comune} ${selectedSupply.provincia}`}
                bookmarkColor={addresses.find(it => it.puntiGas.indexOf(selectedSupply.puntoGas)>-1)?.color}
                icon={selectedSupply.tipoServizio==="E"?<assets.EnergyIcon />:selectedSupply.tipoServizio==="GPL"?<assets.GplIcon />:<assets.GasIcon />}
                state={selectedSupply.idStatoFornitura}
                direct={isExpanded}
                key={selectedSupply.puntoGas}
                />
            <Collapsible open={isExpanded}>
                <Box
                    pad="small"
                    gap="small"
                    round="small"
                    style={{ zIndex:"1" }}
                    elevation='small'
                >
                    <Box direction="row" align="center" round="small" style={{backgroundColor: theme.global?.colors?.['textWhite']?.toString()}}>
                        <TextInput placeholder={strings.phSupplySelector}
                            icon={<Box margin={{ right: 'small' }}>
                                <Search size="medium" />
                                </Box>}
                            onChange={onChangeFilter}
                        >
                        </TextInput>

                    </Box>
                    <InfiniteScroll items={filteredSupplies}>
                        {(item: SupplyModel) => (
                            <Box>
                                <SupplyBox
                                    elevation='0'
                                    onClick={async () => {
                                        dispatch({ type: SET_SUPPLY, payload: item })
                                        setExpanded(!isExpanded)
                                        setFilter('')

                                    }}
                                    title={`${item?.tipoServizio==="E"?strings.pod:strings.pdr} ${item?.pod}`}
                                    width={{ min: 'small' }}
                                    margin={{ bottom: 'small' }}
                                    description={`${item?.tipoVia} ${item?.via} ${item?.numeroCivico}, ${item?.comune} ${item?.provincia}`}
                                    bookmarkColor={
                                        item.idStatoFornitura !== StatiFornitura.Attiva
                                            ? 'disabled'
                                            : addresses.find(it => it.puntiGas.indexOf(item!.puntoGas)>-1)?.color
                                    }
                                    icon={item?.tipoServizio==="E"?<assets.EnergyIcon />:item?.tipoServizio==="GPL"?<assets.GplIcon />:<assets.GasIcon />}
                                    state={item?.idStatoFornitura}
                                    key={item?.puntoGas}
                                    disabled={disableInactiveSupply && item.idStatoFornitura !== StatiFornitura.Attiva}
                                    disabledMessage={`${strings.nonSelectableSupply} "${getDescStatoFornitura(Number(item.idStatoFornitura), strings)}"`}
                                />
                                <BSeparator />
                            </Box>
                        )}
                    </InfiniteScroll>
                </Box>

            </Collapsible>
        </Box>
    )
}

export default SupplySelector

