import { AxiosError } from 'axios'
import { IOCServices } from '..'
import { APIReturnTypes, HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type ConfirmTokenRegistrazioneType = {
    CodiceUtente: string,
    IdRichiesta: string,
    TokenReg: string
}

const confirmTokenRegistrazioneAPI =
    async (req: ConfirmTokenRegistrazioneType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        
        const restID = container.restID
        const restAPI = container.rest
        
        try {
            const res = await restID.post(
                '/Utente/ConfirmTokenRegistrazione',{
                    CodiceUtente:req.CodiceUtente,
                    IdRichiesta:req.IdRichiesta,
                    TokenReg: req.TokenReg
            })

            if (res.status == HttpStates.OK) {
                return res.data.value
            }
            else {
                return undefined
            }          
        } catch (e) {
            
            return undefined
        }
    }

export default confirmTokenRegistrazioneAPI
