import React from 'react'
import {
    Avatar,
    Box,
    Button,
    Text,
    ThemeType,
} from 'grommet'
import { CaretDownFill, CaretUpFill, Edit, IconProps } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import useTheme from '../hooks/useTheme'
import { getBrandColor } from '../utilities'

const ButtonEditForm = ({
    handleClick,
    activated
}: ButtonEditFormProps) => {
    const theme = useTheme()

    return (

        <Button
                                    
            style={{

                height:"30px",
                width:"30px",
                marginBottom: "20px",
                marginRight: "25px",
                marginTop:"5px",
                border: '0px',
                boxShadow: 'none',
                padding: '0px 0px 0px 0px'
            }}

            
        >
            {({ hover }) => (
            <Box
                elevation="large"
                pad={{vertical:"small",horizontal:"medium"}}
                justify='center'
                align="center"
                round="small"  
                background={getBrandColor(hover||activated,theme)}
                onClick={() => {
                    handleClick()
                }}

            >            
                {
                    <Edit color={getBrandColor(!(hover||activated),theme)} size="medium"/>
                }
                
            </Box>
            )}
        </Button>
        
    )
}

type ButtonEditFormProps = {
    handleClick: Function
    activated: boolean
}

export default ButtonEditForm
