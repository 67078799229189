import { IOCServices } from '..'
import { OperatorModel } from '../../models/models'

const loadAgentsAndOperatorsAPI = async (
    container?: IOCServices,
): Promise<OperatorModel[]> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get(`/Utente/GetListaAgentiEOperatori/`)
        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadAgentsAndOperatorsAPI
