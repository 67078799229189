import { Box, Button, Heading, Layer, Spinner } from 'grommet'
import { useEffect, useState, useMemo } from 'react'
import useI18n from '../hooks/useI18n'
import { useAppDispatch } from '../services/storeProvider'
import {
    loadUsers,
    loadUsersRequests,
    setSelectedUser,
} from '../services/store/actions'
import UserSearch from '../components/UserSearch'
import {
    ExtendedRequestModel,
    KeyValue,
    RequestListResponseModel,
    UserSearchModel,
    UsersSearchModel,
} from '../models/models'
import { RequestSearchModel } from '../models/models'
import { useNavigate } from 'react-router-dom'
import useAppQuery from '../hooks/useAppQuery'
import RequestDetailsBox from '../components/RequestDetailsBox'
import UsersFiltersForm from '../components/forms/UsersFiltersFrom'
import { USERS_PER_PAGE, UsersFilters } from '../services/api/loadUsersAPI'
import { Close, Filter, Trash } from 'grommet-icons'
import BLightButton from '../components/BLightButton'
import ContractDetailsBox from '../components/ContractDetailsBox'
import loadRequest from '../services/store/actions/loadRequestFromOperator'
import RequestListSearch from '../components/RequestListSearch'

const initialFilters = {
    codiceUtente: '',
    puntoGas: '',
    nominativo: '',
    codiceFiscale: '',
    piva: '',
    podPdr: '',
    page: 1,
}

type TitleValue = {
    title: string | number
    value: string | number
}

type FilterTitleValue = Array<TitleValue>

const HomeOperator = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showModalDetails, setShowModalDetails] = useState(false)
    const [users, setUsers] = useState<UsersSearchModel>({
        users: [],
        totalRows: 0,
    })
    const [isSelectingUser, setSelectingUser] = useState<boolean>(false)
    const [requests, setRequests] = useState<RequestListResponseModel>({
        requests: [],
        totalRows: 0,
    })
    const [filters, setFilters] = useState<UsersFilters>(initialFilters)
    const [isLoadingUsers, queryUsers] = useAppQuery(loadUsers)
    const [isLoadingUsersRequests, queryUsersRequests] =
        useAppQuery(loadUsersRequests)
    const [isFilterOpen, setFilterOpen] = useState(false)
    const [isLoadingRequest, queryRequest] = useAppQuery(loadRequest)
    const [selectedRequest, setSelectedRequest] =
        useState<ExtendedRequestModel>()

    useEffect(() => {
        queryUsersRequests({ rowsPerPage: 5, page: 1 }).then(setRequests)
    }, [queryUsersRequests])

    useEffect(() => {
        queryUsers({ rowsPerPage: USERS_PER_PAGE, ...filters }).then(setUsers)
    }, [filters, queryUsers])

    const activeFilters = useMemo(() => {
        const entries = Object.entries(filters)
        const initialValue: FilterTitleValue = []
        const currStrings = strings as KeyValue
        return entries.reduce((prev, curr) => {
            const [key, value] = curr
            if (!value || key === 'page' || key === 'rowsPerPage') {
                return prev
            }
            return [...prev, { title: currStrings[key], value }]
        }, initialValue)
    }, [filters, strings])

    const isActiveFilters = useMemo(() => {
        return activeFilters.length > 0
    }, [activeFilters])

    if (isSelectingUser) {
        return (
            <Box flex direction="row" align="start">
                <Box pad={{ horizontal: 'medium' }} fill="horizontal">
                    <Spinner />
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box pad={{ horizontal: 'medium' }} fill="horizontal">
                <Box  fill="vertical">
                    <Box direction="row" gap="small" align="center">
                        <Heading size="small">{strings.clientsSearch}</Heading>
                        <Button
                            label={strings.filters}
                            primary
                            icon={<Filter size="small" />}
                            size="small"
                            onClick={() => {
                                setFilterOpen(!isFilterOpen)
                            }}
                            tip={
                                isActiveFilters
                                    ? {
                                          plain: true,
                                          content: (
                                              <ContractDetailsBox
                                                  border
                                                  pad={{
                                                      vertical: 'small',
                                                      horizontal: 'small',
                                                  }}
                                                  background={
                                                      'background-front'
                                                  }
                                                  elements={activeFilters}
                                                  title={strings.activeFilters}
                                              />
                                          ),
                                      }
                                    : undefined
                            }
                        />
                        {isActiveFilters && (
                            <Button
                                size="small"
                                icon={<Trash size="small" />}
                                label={strings.removeFilters}
                                onClick={() => setFilters(initialFilters)}
                            />
                        )}
                    </Box>
                    <Box pad={{ bottom: 'medium' }} >
                    <UserSearch
                        onPageChange={({ page }) => {
                            setFilters({ ...filters, page })
                        }}
                        isLoading={isLoadingUsers}
                        users={users}
                        onUserSelect={(user: UserSearchModel) => {
                            setSelectingUser(true)
                            dispatch(setSelectedUser(user.userId))
                            setSelectingUser(false)
                            navigate('/home')
                        }}
                    />
                    </Box>
                </Box>
                <Box pad={{ bottom: 'medium' }}>
                    <Heading size="small">{strings.lastRequests}</Heading>
                    <RequestListSearch
                    fill="vertical"
                        isLoading={isLoadingUsersRequests}
                        requests={requests.requests}
                        hidePagination
                        onRequestSelect={async (data: RequestSearchModel) => {
                            setShowModalDetails(true)
                            const request = await queryRequest(data.idRichiesta)
                            setSelectedRequest(request.request)
                        }}
                        totalRow={10}
                    />
                </Box>
            </Box>
            {isFilterOpen && (
                <Layer
                    modal={false}
                    position="right"
                    onEsc={() => setFilterOpen(false)}
                    style={{ height: '100vh' }}
                >
                    <Box
                        width={{ min: 'medium' }}
                        background="background-front"
                        pad={{ horizontal: 'medium' }}
                        fill="vertical"
                        elevation="large"
                    >
                        <BLightButton
                            boxProps={{
                                alignSelf: 'end',
                                margin: {top: 'small'}
                            }}
                            label={strings.chiudi}
                            onClick={() => setFilterOpen(false)}
                            icon={<Close />}
                        />
                        <UsersFiltersForm
                            onSubmit={(values: UsersFilters) => {
                                setFilters({
                                    ...values,
                                    page: 1,
                                })
                                setFilterOpen(false)
                            }}
                            initialValues={filters}
                            isLoading={isLoadingUsers}
                        />
                    </Box>
                </Layer>
            )}
            {showModalDetails && selectedRequest && (
                <Layer
                    position="center"
                    onClickOutside={() => setShowModalDetails(false)}
                    onEsc={() => setShowModalDetails(false)}
                >
                    {isLoadingRequest ? (
                        <Box pad="medium" justify="center" align="center">
                            <Spinner />
                        </Box>
                    ) : (
                        <Box overflow={'auto'}>
                            <RequestDetailsBox
                                onBackPress={() => setShowModalDetails(false)}
                                request={selectedRequest}
                            />
                        </Box>
                    )}
                </Layer>
            )}
        </Box>
    )
}

export default HomeOperator
