import { Box, Button, Heading, Spinner, Text } from 'grommet'
import { Logout } from 'grommet-icons'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import BHeaderProfile from '../components/BHeaderProfile'
import BSeparator from '../components/BSeparator'
import UserContactsForm from '../components/forms/UserContactsForm'
import UserDataForm from '../components/forms/UserDataForm'
import useAppQuery from '../hooks/useAppQuery'
import useAssets from '../hooks/useAssets'
import useI18n from '../hooks/useI18n'
import useIsAdmin from '../hooks/useIsAdmin'
import useSize from '../hooks/useSize'
import editDatiAnagrafici from '../services/store/actions/editDatiAnagrafici'
import getAnagraficaUtente from '../services/store/actions/getAnagraficaUtente'
import insertRichiestaPrivacy from '../services/store/actions/insertRichiestaPrivacy'
import loadPublicParams from '../services/store/actions/loadPublicParams'
import logout from '../services/store/actions/logout'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'

const UserProfile = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const user = useAppSelector((state) => state.user.clientInfo)
    const [, queryParams] = useAppQuery(loadPublicParams)
    const [isLoadingAnag, queryAnag] = useAppQuery(getAnagraficaUtente)
    const navigate = useNavigate()
    const location = useLocation()
    const assets = useAssets()
    const state = location.state as { path?: string }
    const isAdmin = useIsAdmin()

    useEffect(() => {
        queryParams()
        queryAnag(user.codiceUtente)
    }, [queryAnag, queryParams, user.codiceUtente])

    return (
        <Box
            flex
            direction="column"
            width="100%"
            pad={size !== 'small' ? 'small' : undefined}
            style={{
                position: 'relative',
            }}
        >
            <BHeaderProfile
                height={{
                    max: size === 'small' ? '540px' : 'small',
                    height: size === 'small' ? '540px' : 'small',
                }}
                logo={'url(' + assets.logo_hz + ')'}
                heightLogo={200}
                widthLogo="medium"
                nominativo={user.nominativo}
                codiceUtente={user.codiceUtente}
            />
            <Box
                direction="column"
                margin={{ bottom: 'small', top: '-40px' }}
                style={{
                    zIndex: 1,
                }}
                background="textWhite"
                round={
                    size === 'small'
                        ? { size: 'large', corner: 'top' }
                        : undefined
                }
                pad={{ horizontal: 'small' }}
                fill="vertical"
            >
                <Box
                    fill="vertical"
                    gap="small"
                    style={{
                        overflowY: 'auto',
                    }}
                    margin={{ top: '40px' }}
                >
                    <Heading level={3} margin={{ vertical: '10px' }}>
                        {strings.datiUtente}
                    </Heading>
                    <BSeparator size="2px" margin={{ bottom: '10px' }} />

                    <UserDataForm
                        loading={false}
                        onBlur={() => {}}
                        onSubmit={(values) => {
                            console.log(values)
                        }}
                        user={user}
                        disabled={true}
                    />
                    {isLoadingAnag && !isAdmin ? (
                        <Box background={'background-back'}>
                            <Box flex="grow" justify="center" align="center">
                                <Spinner />
                            </Box>
                        </Box>
                    ) : (
                        !isAdmin && (
                            <UserContactsForm
                                loading={false}
                                onBlur={(e) => {
                                    console.log(e)
                                }}
                                onSubmit={async (values) => {
                                    try {
                                        if (
                                            values.telefono !==
                                                values.orig_telefono ||
                                            values.cellulare !==
                                                values.orig_cellulare ||
                                            values.email !==
                                                values.orig_email ||
                                            values.fax !== values.orig_fax
                                        ) {
                                            await dispatch(
                                                editDatiAnagrafici(values),
                                            )
                                        }

                                        if (
                                            values.privacy1 !==
                                                values.orig_privacy1 ||
                                            values.privacy2 !==
                                                values.orig_privacy2 ||
                                            values.privacy3 !==
                                                values.orig_privacy3
                                        ) {
                                            await dispatch(
                                                insertRichiestaPrivacy(values),
                                            )
                                        }

                                        navigate(state?.path ?? '/home')
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}
                                user={user}
                            />
                        )
                    )}
                </Box>
            </Box>
            <Button
                margin="small"
                plain
                onClick={async () => {
                    await dispatch(logout())
                }}
            >
                <Box
                    flex
                    border={{
                        color: 'ko',
                        size: 'small',
                        side: 'all',
                    }}
                    direction="row"
                    round="xsmall"
                    pad="small"
                    gap="small"
                    justify="center"
                    align="center"
                >
                    <Logout color={'ko'} />
                    <Text color={'ko'}>{strings.logoutButton}</Text>
                </Box>
            </Button>
        </Box>
    )
}

export default UserProfile
