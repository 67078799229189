import { Box, Heading } from 'grommet'
import SupplySelector from '../components/SupplySelector'
import BBackButton from '../components/BBackButton'
import { useAppSelector } from '../services/storeProvider'
import { useNavigate } from 'react-router-dom'
import useI18n from '../hooks/useI18n'
import ContractDetailsBox from '../components/ContractDetailsBox'
import BCard from '../components/BCard'
import useAppQuery from '../hooks/useAppQuery'
import insertReclamo from '../services/store/actions/insertReclamo'
import ReclamiForm from '../components/forms/ReclamiForm'

const Reclami = () => {
    const strings = useI18n()
    const [requesting, sendClaim] = useAppQuery(insertReclamo)
    const supply = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const navigate = useNavigate()

    return (
        <Box flex pad="small">
            <Box flex="grow" direction="row" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => navigate('/requests')}
                />
                <Heading level={3}>{strings.assistenzaEReclami}</Heading>
            </Box>
            <Box
                flex="grow"
                width="xxlarge"
                pad="full"
                gap="center"
                margin={{ vertical: 'small' }}>
                <SupplySelector />
            </Box>
            <BCard flex="grow" margin={{ vertical: 'small' }}>
                <ContractDetailsBox
                    pad={{ vertical: '0', horizontal: '0' }}
                    background={'transparent'}
                    title={strings.datiUtente}
                    elements={[
                        {
                            title: strings.nominativo,
                            value:
                                supply.selectedSupply?.cognomeNomeRecapito! ||
                                '-',
                        },
                        {
                            title: strings.codiceCliente,
                            value: supply.selectedSupply?.codiceUtente ?? user?.codiceUtente ?? '',
                        },
                        {
                            title: strings.codiceFiscale,
                            value: user.codiceFiscale || '-',
                        },
                        {
                            title: strings.cellulare,
                            value: String(user.cellulare) || '-',
                        },
                        {
                            title: strings.telefono,
                            value: String(user.telefono) || '-',
                        },
                        {
                            title: strings.email,
                            value: user.email || '-',
                        },
                    ]}
                />
            </BCard>
            <Box flex="grow">
                <Heading size="24px">{strings.richiediAssistenza}</Heading>
                <ReclamiForm
                    onSubmit={async (claim) => {
                        try {
                            await sendClaim(claim)
                            navigate('/requests')
                        } catch (e) {}
                    }}
                    isLoading={requesting}
                />
            </Box>
        </Box>
    )
}

export default Reclami
