import { IOCServices } from '..'
import {
    RequestEditingModel,
    RequestWithDetailsModel,
} from '../../models/models'

const editRequestAPI = async (
    request: RequestEditingModel,
    container?: IOCServices,
): Promise<RequestWithDetailsModel> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.post(
            `/Richiesta/UpdateRichiestaAsOperatore/`,
            request,
        )
        const data = res.data.value
        return { request: data.richiesta, details: data.dettagli }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default editRequestAPI
