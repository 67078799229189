import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

const insertRichiestaPrivacyAPI =
    async (user: UserModel, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID

        try {
            const res = await restID.post(
                '/Richiesta/InsertRichiestaPrivacy',{
                    CodiceUtente: user.codiceUtente,
                    Privacy1: user.privacy1,
                    Privacy1Desc: user.privacy1 === '1' ? 'ACCETTO' : 'NONACCETTO',
                    Privacy2: user.privacy2,
                    Privacy2Desc: user.privacy2 === '1' ? 'ACCETTO' : 'NONACCETTO',
                    Privacy3: user.privacy3,
                    Privacy3Desc: user.privacy3 === '1' ? 'ACCETTO' : 'NONACCETTO',
            })
            return true
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default insertRichiestaPrivacyAPI
