import { IOCServices } from '..'

const autologinCryptedTokenAPI = async (
    username: string,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const restID = container.restID

    try {
        const formData = new FormData()
        formData.append('Username', username)

        const res = await restID.post(
            '/connect/AutologinCryptedToken',
            formData,
        )
        const data = res.data.content

        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default autologinCryptedTokenAPI
