import { Formik } from 'formik'
import { Box, Button, CheckBox, Spinner } from 'grommet'
import * as Yup from 'yup'
import useI18n from '../../hooks/useI18n'
import { ChangeAddressModel, CityProvinceModel } from '../../models/models'
import BSelect from '../BSelect'
import BTextInput from '../BTextInput'

const ChangeAddressForm = ({
    streetTypes,
    cities,
    initialValues,
    onSubmit,
    isLoading = false,
}: ChangeAddressFormProps) => {
    const strings = useI18n()
    const changeAddressSchema = Yup.object().shape({
        name: Yup.string().required(strings.mandatoryField),
        address: Yup.string().required(strings.mandatoryField),
        cap: Yup.string().required(strings.mandatoryField),
        number: Yup.number()
            .typeError(strings.mustBeNumber)
            .required(strings.mandatoryField),
        barrato: Yup.string().notRequired(),
        province: Yup.string().required(strings.mandatoryField),
        city: Yup.string().required(strings.mandatoryField),
        streetType: Yup.string()
            .required(strings.mandatoryField)
            .test('Valid', strings, (value) => {
                return streetTypes.includes(value || '')
            }),
        applyToAll: Yup.boolean(),
    })

    return (
        <Formik
            initialValues={{
                name: initialValues?.name || '',
                address: initialValues?.address || '',
                cap: initialValues?.cap || '',
                number: initialValues?.number || '',
                barrato: initialValues?.barrato || '',
                province: initialValues?.province || '',
                city: initialValues?.city || '',
                streetType: initialValues?.streetType || '',
                cityIstat: initialValues?.cityIstat || '',
                applyToAll: initialValues?.applyToAll || false,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={changeAddressSchema}
            onSubmit={(values) => onSubmit(values)}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                const cityList = values.province
                    ? cities.find(
                          (it) =>
                              it.codiceProvincia.toLowerCase() ===
                                  values?.province?.toLowerCase() ?? '',
                      )?.comune ?? []
                    : []

                const city = cityList.find(
                    (it) => it.nomeComune === values.city,
                )

                const capList =
                    cityList.find((it) => it.nomeComune == values?.city ?? '')
                        ?.cap ?? ([] as Array<string>)

                values.city = city?.nomeComune ?? ''
                values.cityIstat = city?.codiceIstat ?? ''
                return (
                    <Box gap="small">
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.cognomeNomeRecapito}
                                label={strings.cognomeNomeRecapito}
                                name="name"
                            />
                        </Box>

                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.streetType}
                                placeholder={strings.streetType}
                                name="streetType"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={streetTypes.map((it) => ({
                                    label: it.replace(
                                        /^(\w)(.+)/,
                                        (_, p1, p2) =>
                                            p1.toUpperCase() + p2.toLowerCase(),
                                    ),
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetName}
                                label={strings.streetName}
                                name="address"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetNumber}
                                label={strings.streetNumber}
                                name="number"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetSepatator}
                                label={strings.streetSepatator}
                                name="barrato"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.province}
                                placeholder={strings.province}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                name="province"
                                errors={errors}
                                icon={isLoading ? <Spinner /> : true}
                                options={cities.map((it) => ({
                                    label: it.provincia,
                                    value: it.codiceProvincia,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.city}
                                placeholder={strings.city}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="city"
                                icon={isLoading ? <Spinner /> : true}
                                options={cityList.map((it: any) => ({
                                    label: it.nomeComune,
                                    value: it.nomeComune,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.cap}
                                placeholder={strings.cap}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="cap"
                                icon={isLoading ? <Spinner /> : true}
                                options={capList.map((it) => ({
                                    label: it,
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                        </Box>
                        <Box>
                            <CheckBox
                                name={'applyToAll'}
                                checked={values.applyToAll}
                                onChange={handleChange}
                                label={strings.tutteLeForniture}
                            />
                        </Box>
                        <Box margin={{ top: 'small' }}>
                            <Button
                                disabled={isLoading}
                                color="primary_color"
                                //width="20%"
                                //size="medium"
                                style={{
                                    width: '100%',
                                    //minWidth:"10%",

                                    height: '50px',
                                }}
                                onClick={async () => {
                                    try {
                                        if (await validateForm()) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.save}
                                icon={isLoading ? <Spinner /> : <span />}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

type ChangeAddressFormProps = {
    initialValues?: ChangeAddressModel
    streetTypes: Array<string>
    cities: Array<CityProvinceModel>
    onSubmit: (values: ChangeAddressModel) => any
    isLoading: boolean
}

export default ChangeAddressForm
