import { IOCServices } from '..'

export type ClaimsType = {
    idRichiesta: number
    idReclamo: number
}

//ho il dubbio solo sui parametri che gli passo è corretto ? boh 
// la chiamata deve essere così comunque in modo che legge i parametri idrichiesta e idReclamo. 
// ps. ho aggiornato anche la collections di Postman su utilities_common :D 

const loadDettaglioReclamoAPI = async (
    { idRichiesta, idReclamo }: ClaimsType,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const api = `/Richiesta/GetRichiestaDettaglioReclamo/?idrichiesta=${idRichiesta}&idReclamo=${idReclamo}`
        const res = await rest.get(api)

        return {
            claim: res.data.value.reclamiDTO,
            claimUtilities : res.data.value.richiestaUtenzeReclamo,
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadDettaglioReclamoAPI
