import omit from 'lodash.omit'
import { IOCServices } from '..'
import { DettaglioReclamo, DettaglioReclamoOperatore } from './loadDettaglioReclamoOperatoreAPI'

const editReclamoOperatoreAPI = async (
    claim: DettaglioReclamoOperatore | DettaglioReclamo,
    container?: IOCServices,
): Promise<DettaglioReclamoOperatore> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    try {
        const path = '/Reclami/PutDettaglioReclamo'
        const res = await container.restID.put(
            path,
            omit(claim, ['allegati', 'risposte', 'note']),
            {
                formSerializer: {
                    indexes: null,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default editReclamoOperatoreAPI
