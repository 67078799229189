import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'
import { isOperatorOrAgent } from '../../utilities'

const getConsumiAPI = async (
    codiceUtente: string,
    puntoGas: string,
    dataDa: string,
    dataA: string,
    roles: Roles[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID

    try {
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Consumi/GetConsumiUtenza/${codiceUtente}?PuntoGas=${puntoGas}`

        if (isOperator) {
            api = `/Consumi/GetConsumiUtenzaAsOperatore/${codiceUtente}?PuntoGas=${puntoGas}&dataLetturaDa=2019-01-01&dataLetturaA=${dataA}`
        }
        const res = await rest.get(api)
        if (res.status === HttpStates.OK) {
            const data = res.data
            return data.value
        }
    } catch (e) {
        // console.clear()
        return undefined
    }
}

export default getConsumiAPI
