
import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'

const getSignedPDF: AppAsyncAction<any> =
    ({ idRichiesta, codiceUtente }: { idRichiesta: number; codiceUtente: string }) =>
    async (dispatch, getState, container) => {
        const roles = getState().user.loggedUser.roles
        try {
            const data = await container.api.getSignedPDFAPI(idRichiesta, codiceUtente, roles)

            if (data === undefined) throw new Error()

            return data
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getSignedPDF
