import { AppAction } from '../../storeProvider'
import { getStringFromCode, isOperatorOrAgent } from '../../../utilities'
import setNotification from './setNotification'
import { RIDModel } from '../../../models/models'
import { SET_SELECTEDREQUESTID } from './types'

const insertRichiestaDomiciliazione =
    (auto: RIDModel): AppAction<any> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        const isOperator = isOperatorOrAgent(getState().user.loggedUser.roles)
        try {
            const editData = await container.api.insertRichiestaDomiciliazioneAPI(auto, isOperator)

            dispatch({
                type: SET_SELECTEDREQUESTID,
                payload: editData?.data?.value,
            })

            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )

            return editData
        } catch (e: any) {
            const index = (e.response?.data?.value ?? e.message).indexOf('|')
            const error =
                index >= 0
                    ? (e.response?.data?.value ?? e.message).substring(index + 1)
                    : e.response?.data?.value ?? e.message

            const errorDesc = getStringFromCode(error, getState().main.stringhe, i18n, lan)
            dispatch(
                setNotification({
                    type: 'error',
                    message: errorDesc,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaDomiciliazione
