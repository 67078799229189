import { Formik } from 'formik'
import { Box } from 'grommet'
import { User } from 'grommet-icons'
import { useState } from 'react'
import * as Yup from 'yup'
import useI18n from '../../hooks/useI18n'
import { UserModel } from '../../models/models'
import BTextInput from '../BTextInput'
import { HideNullDate } from '../../utilities'

const UserDataForm = ({
    loading = false,
    onSubmit,
    onBlur,
    user,
    disabled,
}: UserDataFormProps) => {
    const strings = useI18n()
    const Schema = Yup.object().shape({
        nominativo: Yup.string().notRequired(),
        codiceFiscale: Yup.string().notRequired(),
        luogoNascita: Yup.string().notRequired(),
        dataNascita: Yup.string().notRequired(),
        indirizzo: Yup.string().notRequired(),
        capResidenza: Yup.string().notRequired(),
        // telefono: Yup.string()
        //     .matches(
        //         /^\d{6,12}$/,
        //         strings.telCheck,
        //     ).notRequired(),
        // cellulare: Yup.string()
        //     .matches(
        //         /^\d{6,12}$/,
        //         strings.cellCheck,
        //     ),
        // email: Yup.string()
        //     .required(strings.mandatoryField)
        //     .email(strings.invalidEmailField),
    })
    return (
        <Formik
            initialValues={{
                nominativo: user?.nominativo || '',
                codiceFiscale: user?.codiceFiscale || '',
                luogoNascita: user?.luogoNascita || '',
                dataNascita: HideNullDate(user?.dataNascita) || '',
                dataNascitaAPI: user?.dataNascitaAPI || '',
                indirizzo: user?.indirizzo || '',
                fax: user?.fax || '',
                pec: user?.pec || '',
                email: user?.email || '',
                codiceUtente: user?.codiceUtente || '',
                telefono: user?.telefono || '',
                cellulare: user?.cellulare || '',
                tipoViaResidenza: user?.tipoViaResidenza || '',
                viaResidenza: user?.viaResidenza || '',
                nrCivicoResidenza: user?.nrCivicoResidenza || '',
                comuneResidenza: user?.comuneResidenza || '',
                provinciaResidenza: user?.provinciaResidenza || '',
                capResidenza: user?.capResidenza || '',
                privacy1: user?.privacy1 || '',
                privacy2: user?.privacy2 || '',
                privacy3: user?.privacy3 || '',
                orig_telefono: user?.telefono || '',
                orig_cellulare: user?.cellulare || '',
                orig_email: user?.email || '',
                orig_fax: user?.fax || '',
                orig_pec: user?.pec || '',
                orig_privacy1: user?.privacy1 || '',
                orig_privacy2: user?.privacy2 || '',
                orig_privacy3: user?.privacy3 || '',
                isAnagModified: false,
                isPrivacyModified: false,
                idTipoUtente: user?.idTipoUtente || 1,
            }}
            validationSchema={Schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}
            onBlur={onBlur}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                return (
                    <Box gap="xsmall" height={{ min: 'fit-content' }}>
                        <Box flex direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="nominativo"
                                label={strings.nominativo}
                                placeholder={strings.phNominativo}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="codiceFiscale"
                                label={strings.codiceFiscale}
                                placeholder={strings.phCodiceFiscale}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box flex direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="luogoNascita"
                                label={strings.luogoNascita}
                                placeholder={strings.phLuogoNascita}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="dataNascita"
                                label={strings.dataNascita}
                                placeholder={strings.phDataNascita}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box flex direction="row" gap="small">
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="indirizzo"
                                label={strings.indirizzo}
                                placeholder={strings.phIndirizzo}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                name="capResidenza"
                                label={strings.cap}
                                placeholder={strings.phCAP}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                disabled
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User size="medium" />
                                    </Box>
                                )}
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

interface UserDataFormProps {
    loading: boolean
    onSubmit: (user: UserModel) => void | Promise<UserModel> | Promise<void>
    user?: UserModel
    onBlur: (user: UserModel) => any
    disabled: boolean
}

export default UserDataForm
