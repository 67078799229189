import styled, { keyframes } from 'styled-components'
import useTheme from '../hooks/useTheme'

const effect = (color?: string) => {
    const alphaColor = !color
        ? '#fff2'
        : color.length > 4
        ? `${color}20`
        : `${color}2`

    const bkgColor = !color ? '#fff' : color

    return keyframes`
     0% {
        background-color: ${alphaColor};
        box-shadow: 32px 0 ${alphaColor}, -32px 0 ${bkgColor};
    }

    50% {
        background-color: ${bkgColor};
        box-shadow: 32px 0 ${alphaColor}, -32px 0 ${alphaColor};
    }

    100% {
        background-color: ${alphaColor};
        box-shadow: 32px 0 ${bkgColor}, -32px 0 ${alphaColor};
    }
`
}

const StyledSpan = styled.span`
    width: ${(props) => props.style?.width || '16px'};
    height: ${(props) => props.style?.height || '16px'};
    border-radius: 50%;
    background-color: ${(props) => props.color || '#fff'};
    box-shadow: 32px 0 ${(props) => props.color || '#fff'},
        -32px 0 ${(props) => props.color || '#fff'};
    animation: ${(props) => effect(props.color)} 0.5s ease-out infinite
        alternate;
`

const BSpinner = ({ width, height, color }: BSPinnerProps) => {
    const { global } = useTheme()

    return (
        <StyledSpan
            color={color || global?.colors?.bSpinner?.toString()}
            style={{ width, height }}
        />
    )
}

type BSPinnerProps = {
    width?: string
    height?: string
    color?: string
}

export default BSpinner
