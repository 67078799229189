import { RequestDetailsModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadRequestDetails =
    (idRichiesta: number): AppAction<RequestDetailsModel[]> =>
    async (dispatch, getState, container) => {
        const roles = getState().user.loggedUser.roles
        try {
            const dettaglio = await container.api.loadRequestDetailsAPI(
                idRichiesta,
                roles,
            )

            return dettaglio
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadRequestDetails
