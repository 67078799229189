import { Image } from 'grommet'
import { useNavigate } from 'react-router'
import useAssets from '../../hooks/useAssets'
import HeaderBar from './HeaderBar'

const HeaderNavBar = ({
    name,
}: ULNavbarProps) => {
    const navigate = useNavigate()
    const assets = useAssets()

    return (
        <HeaderBar
            height='100px'
            header={
                <Image
                    fit="contain"
                    src={assets.logo_sm}
                    onClick={() => navigate('/home')}
                />
            }
            name={name}
            onProfileClick={() => navigate('/user')}
            onClick={(id) => {navigate(`/${id}`)}}
        />
    )
}

type ULNavbarProps = {
    name: string
}

export default HeaderNavBar
