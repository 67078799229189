import { stat } from 'fs'
import { ConfigModel } from '../../../models/models'
import { SET_CONFIG, SET_TOKENCLIENT, SET_TOKENROP } from '../actions/types'

interface ConfigInitialState {
    apiUrl: string
    identityUrl: string
    appVersioneMinima: string
    bundleID: string
    appleID: string
    auth: number
    clientID: string
    clientSecret: string
    tokenClient: string
    tokenROP: string
}

/* eslint-disable import/no-anonymous-default-export */
export const INITIAL_STATE: ConfigInitialState = {
    apiUrl: '',
    identityUrl: '',
    appVersioneMinima: '5.0.0',
    bundleID: '',
    appleID: '',
    auth: 2,
    clientID: '140',
    clientSecret: '',
    tokenClient: '',
    tokenROP: ''
}

export default (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_CONFIG:
            return {
                ...payload,
            }
        case SET_TOKENCLIENT:
            return {
                ...state,
                tokenClient: payload
            }
        case SET_TOKENROP:
            return {
                ...state,
                tokenROP: payload
            }
        default: {
            return state
        }
    }
}
