import { EnabledChannels } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getEnabledChannels =
    (): AppAction<EnabledChannels[]> => async (dispatch, _, container) => {
        try {
            return await container.api.getEnabledChannelsAPI()
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getEnabledChannels
