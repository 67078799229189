import { NoteStato } from '../../api/getNoteStatoAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getNoteStato =
    (): AppAction<NoteStato> => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const noteStato = await container.api.getNoteStatoAPI()

            return noteStato
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.errorMessage,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getNoteStato
