import {
    AddressModel,
    ConsumoModel,
    RangeAutoletturaAdminModel,
    RangeAutoletturaModel,
    RecontractingSupplies,
    SupplyModel,
} from '../../../models/models'

import {
    SET_REMINDERS,
    SET_SUPPLIES,
    SET_SUPPLY,
    SET_SUPPLIES_LOADED,
    SET_CONSUMPTIONS,
    SET_ADDRESSES,
    SET_LENADDRESSES,
    SET_LENSUPPLIES,
    SET_ADDRESS,
    SET_RANGES,
    SET_RECONTRACTING,
    RESET_SUPPLY,
} from '../actions/types'

interface SupplyInitialState {
    supplies: Array<SupplyModel>
    reminders: Array<RangeAutoletturaModel>
    consumptions: Array<ConsumoModel>
    addresses: Array<AddressModel>
    lenAddresses: number
    lenSupplies: number
    selectedSupply: SupplyModel | undefined
    loading: boolean
    selectedAddress: AddressModel | undefined
    ranges: Array<RangeAutoletturaAdminModel>
    recontracting: Array<RecontractingSupplies>
}
export const INITIAL_STATE: SupplyInitialState = {
    supplies: [],
    reminders: [],
    consumptions: [],
    addresses: [],
    lenAddresses: 1,
    lenSupplies: 1,
    selectedSupply: undefined,
    loading: false,
    selectedAddress: undefined,
    ranges: [],
    recontracting: [],
}

const supply = (
    state = INITIAL_STATE,
    action: { type: string; payload: any },
): typeof INITIAL_STATE => {
    const { type, payload } = action
    switch (type) {
        case SET_SUPPLIES:
            return {
                ...state,
                supplies: payload,
            }
        case SET_ADDRESSES:
            return {
                ...state,
                addresses: payload,
            }
        case SET_LENADDRESSES:
            return {
                ...state,
                lenAddresses: payload,
            }
        case SET_LENSUPPLIES:
            return {
                ...state,
                lenSupplies: payload,
            }
        case SET_SUPPLY:
            return {
                ...state,
                selectedSupply: payload,
            }
        case RESET_SUPPLY:
            return {
                ...state,
                selectedSupply: INITIAL_STATE.selectedSupply,
            }
        case SET_REMINDERS:
            return {
                ...state,
                reminders: payload,
            }
        case SET_SUPPLIES_LOADED:
            return {
                ...state,
                loading: payload,
            }
        case SET_CONSUMPTIONS:
            return {
                ...state,
                consumptions: payload,
            }
        case SET_ADDRESS:
            return {
                ...state,
                selectedAddress: payload,
            }
        case SET_RANGES:
            return {
                ...state,
                ranges: payload,
            }
        case SET_RECONTRACTING:
            return {
                ...state,
                recontracting: payload,
            }
        default: {
            return state
        }
    }
}
export default supply
