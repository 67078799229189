import { Box, BoxExtendedProps, Text } from 'grommet'
import { Deploy, FormCheckmark, FormClose } from 'grommet-icons'
import useAssets from '../hooks/useAssets'
import useSize from '../hooks/useSize'
import useTheme from '../hooks/useTheme'
import { RequestModel, SupplyModel } from '../models/models'
import { getColorStatoRichiesta, getLightColorStatoRichiesta, getStatoRichiestaKO, getStatoRichiestaOK, getStatoRichiestaPresaInCarico } from '../utilities'

const StateRequestButton = ({
    title,
    state,
    request,
    supply,
    ...rest
}: StateRequestButtonProps) => {
    const theme = useTheme()
    const assets = useAssets()
    const size = useSize()
    return (
        <Box {...rest}>

            <Box direction="column" align="center" width={{min: "xsmall"}}>
                <Box
                    round="full"
                    //width="50px"
                    align="center"
                    justify='center'
                    border={{color: getLightColorStatoRichiesta(state), size: "medium"}} //{{ color: theme.global?.colors?.['lightergrey']?.toString(), size: 'medium' }}
                    background={getColorStatoRichiesta(state)} //{theme.global?.colors?.['lightgrey']?.toString()}
                    pad="xsmall"
                    width={{min: '48px'}}
                    height={{min: '48px'}}
                >
                    {
                    getStatoRichiestaOK(state)?
                        <FormCheckmark color={theme.global?.colors?.['textWhite']?.toString()} size="medium"/>:
                    getStatoRichiestaKO(state)?
                        <FormClose color={theme.global?.colors?.['textWhite']?.toString()} size="medium"/>:
                    getStatoRichiestaPresaInCarico(state)?
                        <Deploy color={theme.global?.colors?.['textWhite']?.toString()} size="medium"/>:
                    <assets.TransferIcon color={theme.global?.colors?.['textWhite']?.toString()} width='24px' height='24px'/>
                    }
                </Box>
                <Box align="center" gap="medium" direction="row">

                    <Text size={size === 'small' ? "xsmall" : 'small'} wordBreak='break-word' weight="bold" color={theme.global?.colors?.['textGrey']?.toString()}>
                        {title}
                    </Text>

                </Box>
            </Box>

        </Box>
    )
}

type StateRequestButtonProps = {
    title: string
    state: number,
    supply?: SupplyModel
    request?: RequestModel
} & BoxExtendedProps

export default StateRequestButton
