import { Box, Button, Heading, Text } from 'grommet'
import { useNavigate } from 'react-router'

import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import { useAppSelector } from '../services/storeProvider'

const Page404 = () => {
    const strings = useI18n()
    const user = useAppSelector((state) => state.user.clientInfo)
    const navigate = useNavigate()
    const size = useSize()
    return (
        <Box flex direction={size === 'small' ? 'column' : 'row'} align="start">
            <Box //flex
                //background="url(../assets/backgroundLogin2.jpg)"
                height="100vh"
                width="100vw"
                //fill={true}
                align="center"
                justify="center">
                <Heading size={'medium'}>{strings.pageNotFoundTitle}</Heading>
                <Button hoverIndicator onClick={() => navigate('/home')}>
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small">
                        <Text>{strings.backToTheHome}</Text>
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}

export default Page404
