import { Box, BoxExtendedProps, Button, Text } from 'grommet'
import { FormAdd, FormSubtract } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import '../pages/ContractDetailsBox.css'

const ContractDetailsBox = ({
    elements,
    title,
    visible,
    ...rest
}: ContractDetailsBoxProps) => {
    const strings = useI18n()
    return (
        <Box
            pad="medium"
            round="small"
            gap="medium"
            background={'background-grey'}
            {...rest}>
            {visible && (
                <Box>
                    {visible ? (
                        <Button onClick={rest.onClick} color={'brand'}>
                            <Box direction="row">
                                <Box
                                    round="full"
                                    align="center"
                                    justify="center"
                                    background="brand"
                                    pad="xxsmall"
                                    margin={{ right: 'xsmall' }}>
                                    <FormAdd />
                                </Box>
                                <Text>{strings.visualizzaDatiContratto}</Text>
                            </Box>
                        </Button>
                    ) : (
                        <Button onClick={rest.onClick} color={'brand'}>
                            <Box direction="row" color={'brand'}>
                                <Box
                                    round="full"
                                    align="center"
                                    justify="center"
                                    background="brand"
                                    pad="xxxsmall"
                                    margin={{ right: '1%' }}>
                                    <FormSubtract />
                                </Box>
                                <Text>
                                    {strings.nonVisualizzaDatiContratto}
                                </Text>
                            </Box>
                        </Button>
                    )}{' '}
                </Box>
            )}
            <Text weight="bold">{title}</Text>
            <Box flex direction="row" align="center" wrap>
                {elements.map((it, index) => (
                    <Box
                        key={index}
                        width={{ min: '33%' }}
                        flex={{ grow: 0.33 }}
                        margin={{ bottom: 'medium' }}>
                        <Text size="small" color={'primary_color'}>
                            {it.title}
                        </Text>
                        <Text weight="bold" size="small">
                            {it.value}
                        </Text>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

type ContractDetailsBoxProps = {
    title: string
    visible?: boolean
    elements: Array<{ title: string | number; value: string | number | Date }>
} & BoxExtendedProps

export default ContractDetailsBox
