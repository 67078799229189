import React, { useEffect, useState } from 'react'
import { Box, Text, Select, BoxProps, SelectExtendedProps } from 'grommet'
import get from 'lodash.get'
import BErrorMessage from './BErrorMessage'
import BTextInput from './BTextInput'
import { isObject } from 'lodash'
import { CircleInformation } from 'grommet-icons'
import ReactTooltip from 'react-tooltip'
import useI18n from '../hooks/useI18n'

const BSelect = ({
    handleChange,
    handleBlur,
    errors,
    name,
    values,
    options = [],
    containerProps,
    label,
    searchable = false,
    createOption = false,
    prefix = 'Insert',
    mandatory = false,
    tooltip,
    renderIcon,
    ...rest
}: BSelectProps) => {
    const strings = useI18n()
    const [currentOptions, setCurrentOptions] = useState(options)
    const [isFocused, setFocused] = useState(false)
    const [searchValue, setSearchValue] = useState<string>()
    const selectedOption = get(values, name)
    const option = options.find(
        (it) => selectedOption === (it as any).value || selectedOption === it,
    )
    const textValue = (option as any)?.label ?? option
    if (
        createOption &&
        selectedOption &&
        values &&
        !options.includes(selectedOption)
    ) {
        options.push(selectedOption)
    }

    useEffect(() => {
        setCurrentOptions(options)
    }, [options])

    const onSearch = (text: string) => {
        if (createOption) {
            updateCreateOption(text)
        }
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, 'i')
        setCurrentOptions(
            options.filter((o) => exp.test(isObject(o) ? o.label : o)),
        )
        setSearchValue(text)
    }

    const updateCreateOption = (text: string) => {
        const len = options.length
        const elem = options[len - 1]
        if (!isObject(elem)) {
            if (elem?.includes(prefix)) {
                // remove Create option before adding an updated one
                options.pop()
            }
            options.push(`${prefix} '${text}'`)
            return
        }
    }
    const renderMultiple = () => {
        const values = selectedOption.map((it: any) => {
            const element: any = options.find(
                (el: any) => el === it || el.value === it,
            )
            return element.label || element
        })
        return (
            <Box direction="row">
                {values.map((it: any) => {
                    return (
                        <Box
                            key={it.value || it}
                            align="center"
                            direction="row"
                            gap="xsmall"
                            pad={{
                                vertical: 'xsmall',
                                horizontal: 'small',
                            }}
                            margin="xsmall"
                            background="brand"
                            round="xsmall">
                            <Text size="small">{it?.label ?? it}</Text>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    return (
        <Box justify="start" {...containerProps}>
            {tooltip && <ReactTooltip multiline />}
            <Box direction="row" justify="between">
                <Box direction="row" gap="xsmall" align="center">
                    {label && (
                        <Text color={"primary_color"}>
                            {label}
                        </Text>
                    )}
                    {mandatory && (
                        <Text weight="bold" size="small">
                            *
                        </Text>
                    )}
                    {tooltip && (
                        <p
                            style={{ margin: '0', padding: '0' }}
                            data-tip={tooltip}>
                            <CircleInformation size="small" />
                        </p>
                    )}
                </Box>
            </Box>
            {rest.disabled && (
                <BTextInput
                    handleChange={handleChange}
                    errors={errors}
                    name={name}
                    placeholder={rest.placeholder}
                    values={{
                        [`${name}`]: textValue,
                    }}
                    disabled
                    disabledOutline
                    renderIcon={renderIcon}
                />
            )}
            {!rest.disabled && (
                <Box
                    border={{ color: isFocused ? 'brand' : 'control-border' }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    round="8px">
                    <Select
                        plain
                        clear={{label: strings.clearSelection}}
                        valueLabel={
                            rest.multiple && selectedOption.length > 0
                                ? renderMultiple()
                                : undefined
                        }
                        value={values ? selectedOption : rest.value}
                        onClose={() => setCurrentOptions(options)}
                        onChange={(e) => {
                            if (createOption && e.option?.includes(prefix)) {
                                options.pop()
                                searchValue && options.push(searchValue)
                                const event = {
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: searchValue,
                                    },
                                    option: searchValue,
                                    value: searchValue,
                                }
                                !!handleChange && handleChange(name)(event)
                            } else {
                                if (rest.multiple) {
                                    const event = {
                                        ...e,
                                        target: {
                                            ...e.target,
                                            value: [...e.value],
                                        },
                                    }
                                    !!handleChange && handleChange(name)(event)
                                } else {
                                    const event = {
                                        ...e,
                                        target: {
                                            ...e.target,
                                            value: e.option?.value ?? e.value,
                                        },
                                    }
                                    !!handleChange && handleChange(name)(event)
                                }
                            }
                        }}
                        onBlur={(e) => {
                            const event = {
                                ...e,
                                target: {
                                    ...e.target,
                                    value: selectedOption,
                                },
                            }
                            handleBlur?.(name)(event)
                        }}
                        onSearch={searchable ? onSearch : undefined}
                        {...rest}
                        options={currentOptions}
                    />
                </Box>
            )}
            {!rest.disabled && <BErrorMessage name={name} errors={errors} />}
        </Box>
    )
}

type BSelectProps = {
    handleChange: Function
    handleBlur?: Function
    options?: Array<string | { label: string; value: string | number }>
    errors: {}
    searchable?: boolean
    createOption?: boolean
    prefix?: string
    name: string
    renderIcon?: () => JSX.Element
    label?: string
    values: {}
    containerProps?: BoxProps
    mandatory?: boolean
    tooltip?: string
} & SelectExtendedProps

export default BSelect
