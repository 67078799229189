import { SupplyModel } from '../../../models/models'
import { reverseStringDate } from '../../../utilities'
import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_CONSUMPTIONS } from './types'

const loadConsumptions: AppAsyncAction<any> =
    (puntiGas: Array<SupplyModel>) => async (dispatch, getState, container) => {
        const curr_consumptions = []
        const prevYear = new Date().getFullYear() - 1
        const dataDa = reverseStringDate(String(new Date(prevYear, 0, 1)))
        const dataA = reverseStringDate(String(new Date()))
        const roles = getState().user.loggedUser.roles
        try {
            for (const sup of puntiGas) {
                try {
                    const consumi = await container.api.getConsumiAPI(
                        sup.codiceUtente,
                        sup.puntoGas,
                        dataDa,
                        dataA,
                        roles,
                    )
                    if (!consumi) {
                        continue
                    }
                    for (const c of consumi) {
                        curr_consumptions.push(c)
                    }
                } catch (e) {
                    throw e
                }
            }
            dispatch({ type: SET_CONSUMPTIONS, payload: curr_consumptions })
            return curr_consumptions
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadConsumptions
