import { Box, BoxExtendedProps, Button, Chart, Text, Tip } from 'grommet'
import { ColorType } from 'grommet/utils'
import { max, min } from 'lodash'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import { useAppSelector } from '../services/storeProvider'
import BCard from './BCard'
import useTheme from '../hooks/useTheme'

const BLineChart = ({
    data,
    address,
    sections,
    onBarClick,
    onSectionClick,
    graphColor,
    ...rest
}: BLineChartProps) => {
    const size = useSize()
    const theme = useTheme()
    const values = data.map((it) => it.value)
    const minimum = min(values) || 0
    const maximum = max(values) || Math.max()
    const strings = useI18n()
    const supply = useAppSelector((state) => state.supply)
    const selectedSection = sections?.filter((it) => it.active === true)[0].id

    const isESElected = selectedSection === 'E'


    return (
        <BCard flex={false} {...rest}>
            <Box gap="small">
                <Box direction="row" justify="between">
                    <Box
                        elevation={!!address ? 'xsmall' : undefined}
                        pad={{ vertical: 'xsmall', horizontal: 'small' }}
                        round="xsmall"
                        style={{ maxWidth: '70%', color: 'white', background: theme?.global?.colors?.[supply?.selectedAddress?.color?.toString()??'brand']?.toString()}}>
                        <Text size="small">{address}</Text>
                    </Box>
                    <Box direction="row" gap="small">
                        {sections?.map((it, index) => (
                            <Button
                                key={index}
                                plain
                                onClick={() => onSectionClick?.(it.id)}
                                color={it.active ? graphColor : 'disabled'}>
                                <Text size="small" style={{textTransform: 'capitalize'}}>{it.label}</Text>
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Box gap="xsmall" pad="small" round="xsmall" direction="row">
                    {data.map((it, index) => (
                        <Box flex align="center" key={index}>
                            {(it.value > 0)?(
                                <Tip content=
                                    {
                                        <Text weight="bold" color="textWhite">
                                           {`${it.value} ${isESElected ? strings.KilowattOra : strings.StandardMetroCubo}`}
                                        </Text>
                                    }
                                >
                                    <Chart
                                        bounds={[
                                            [0, 2], //XXX fixed value
                                            [minimum, maximum],
                                        ]}
                                        values={[
                                            {
                                                value: [1, it.value],
                                                opacity:
                                                    it?.value === 0
                                                        ? 0
                                                        : it?.active
                                                        ? 1
                                                        : 0.4,
                                            },
                                        ]}
                                        onClick={() => onBarClick?.(index)}
                                        type="bar"
                                        round
                                        color={graphColor}
                                        size={{ width: 'full', height: '120px' }}
                                        animate
                                    />
                                </Tip>

                            ):(
                                <Chart
                                    bounds={[
                                        [0, 2], //XXX fixed value
                                        [minimum, maximum],
                                    ]}
                                    values={[
                                        {
                                            value: [1, it.value],
                                            opacity:
                                                it?.value === 0
                                                    ? 0
                                                    : it?.active
                                                    ? 1
                                                    : 0.2,
                                        },
                                    ]}
                                    onClick={() => onBarClick?.(index)}
                                    type="bar"
                                    round
                                    color={graphColor}
                                    size={{ width: 'full', height: '120px' }}
                                    animate
                                />
                            )

                        }
                        <Box align='center'>
                            <Text size={size === 'small' ? 'xsmall' : "small"} style={{ textTransform: 'capitalize' }}>
                                {it.label}
                            </Text>
                                {it.sublabel && <Text weight={"bold"} size="9px" style={{ textTransform: 'capitalize' }}>{it.sublabel}</Text>}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </BCard>
    )
}

type BLineChartProps = {
    sections?: Array<{ label: string; id: string; active: boolean }>
    address?: string
    data: Array<{
        label: string
        sublabel?: string
        value: number
        active?: boolean
    }>
    onBarClick?: (index: number) => void
    onSectionClick?: (id: string) => void
    graphColor?: ColorType
} & BoxExtendedProps

export default BLineChart
