import { IOCServices } from '..'
import { ClaimsRequestType } from '../../models/models'

const getClaimsRequestTypesAPI = async (container?: IOCServices): Promise<ClaimsRequestType[]> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    try {
    const { data } = await container.restID.get(
        `/Reclami/GetTipiRichiesteReclami`,
    )
    const result = [...(data?.value ?? [])]
    return result
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getClaimsRequestTypesAPI
