import loginAPI from './loginAPI'
import insertRichiestaDisdettaContrattoAPI from './insertRichiestaDisdettaContrattoAPI'
import loginCryptedAPI from './loginCryptedAPI'
import logoutAPI from './logoutAPI'
import checkTokenAPI from './checkTokenAPI'
import recoverPasswordAPI from './recoverPasswordAPI'
import recoverUsernameAPI from './recoverUsernameAPI'
import loadUsersAPI from './loadUsersAPI'
import getApiVersionAPI from './getApiVersionAPI'
import richiestaTokenClientAPI from './richiestaTokenClientAPI'
import getAnagraficaUtenteAPI from './getAnagraficaUtenteAPI'
import getAnagraficaUtenteByCodiceUtenteAPI from './getAnagraficaUtenteByCodiceUtenteAPI'
import getCodiceUtenteAPI from './getCodiceUtenteAPI'
import editDatiAnagraficiAPI from './editDatiAnagraficiAPI'
import loadSuppliesAPI from './loadSuppliesAPI'
import loadFornituraDetailsAPI from './loadFornituraDetailsAPI'
import loadAutoletturaDetailsAPI from './loadAutoletturaDetailsAPI'
import loadInvoicesAPI from './loadInvoicesAPI'
import loadInvoiceAPI from './loadInvoiceAPI'
import insertRichiestaFatturaViaMailAPI from './insertRichiestaFatturaViaMailAPI'
import getFatturaPDFAPI from './getFatturaPDFAPI'
import loadRequestsAPI from './loadRequestsAPI'
import loadRequestFromOperatorAPI from './loadRequestFromOperatorAPI'
import loadRequestDetailsAPI from './loadRequestDetailsAPI'
import insertRichiestaPrivacyAPI from './insertRichiestaPrivacyAPI'
import insertRichiestaPrivacyRegistrationAPI from './insertRichiestaPrivacyRegistrationAPI'
import getTipiPaginaAPI from './getTipiPaginaAPI'
import getStringheAPI from './getStringheAPI'
import insertRichiestaAutoletturaAPI from './insertRichiestaAutoletturaAPI'
import editPasswordGuestAPI from './editPasswordGuestAPI'
import getAccountUtenteAPI from './getAccountUtenteAPI'
import getSportelliAziendaAPI from './getSportelliAziendaAPI'
import getConsumiAPI from './getConsumiAPI'
import validateFatturaViaMailAPI from './validateFatturaViaMailAPI'
import richiediTokenRegistrazioneAPI from './richiediTokenRegistrazioneAPI'
import validaTokenRegistrazioneAPI from './validaTokenRegistrazioneAPI'
import getUtenteNonRegistratoAPI from './getUtenteNonRegistratoAPI'
import registrazioneUtenteEmailAPI from './registrazioneUtenteEmailAPI'
import getTipiRichiestaAbilitatiAPI from './getTipiRichiestaAbilitatiAPI'
import insertRichiestaAssistenzaAPI from './insertRichiestaAssistenzaAPI'
import getProvinceComuniAPI from './getProvinceComuniAPI'
import getSottoTipiRichiestaAPI from './getSottoTipiRichiestaAPI'
import changeAddressRequestAPI from './changeAddressRequestAPI'
import loadRangeAutoletturaAPI from './loadRangeAutoletturaAPI'
import getCompanyPublicParamsAPI from './getCompanyPublicParamsAPI'
import getCompanyInformationAPI from './getInformazioniAzienda'
import insertRichiestaDomiciliazioneAPI from './insertRichiestaDomiciliazioneAPI'
import getRIDModuleAPI from './getRIDModuleAPI'
import sendSignedModuleAPI from './sendSignedModuleAPI'
import getSignedPDFAPI from './getSignedPDFAPI'
import checkAutologinTokenValidityAPI from './checkAutologinTokenValidityAPI'
import richiediPinUtenteAPI from './richiediPinUtenteAPI'
import confirmTokenRegistrazioneAPI from './confirmTokenRegistrazioneAPI'
import autologinAPI from './autologinAPI'
import getClaimsSubCatogoriesAPI from './getClaimsSubCategoriesAPI'
import getClaimsRequestTypesAPI from './getClaimsRequestTypesAPI'
import getEnabledChannelsAPI from './getEnabledChannelsAPI'
import insertReclamoAPI from './insertReclamoAPI'
import getLoggedUserAPI from './getLoggedUserAPI'
import loadUsersRequestsAPI from './loadUsersRequestsAPI'
import loadAgentsAndOperatorsAPI from './loadAgentsAndOperatorsAPI'
import editRequestAPI from './editRequestAPI'
import loadDettaglioReclamoAPI from './loadDettaglioReclamoAPI'
import getClaimsARERACategoriesAPI from './getClaimsARERACategoriesAPI'
import loadSuppliesRecontractedAPI from './loadSuppliesRecontractedAPI'
import autologinCryptedTokenAPI from './autologinCryptedTokenAPI'
import getUserAdminAPI from './getUserAdminAPI'
import getNoteStatoAPI from './getNoteStatoAPI'
import getStatiRichiestaReclamoAPI from './getStatiRichiestaReclamoAPI'
import getCausaliRitardoReclamoAPI from './getCausaliRitardoReclamoAPI'
import getMotiviAnnullamentoReclamoAPI from './getMotiviAnnullamentoReclamoAPI'
import loadDettaglioReclamoOperatoreAPI from './loadDettaglioReclamoOperatoreAPI'
import downloadAllegatoReclamoAPI from './downloadAllegatoReclamoAPI'
import deleteAllegatoReclamoAPI from './deleteAllegatoReclamoAPI'
import uploadAllegatoReclamoAPI from './uploadAllegatoReclamoAPI'
import editReclamoOperatoreAPI from './editReclamoOperatoreAPI'
import getAllegatiReclamoAPI from './getAllegatiReclamoAPI'
import getListinoPrezziGPLAPI from './getListinoPrezziGPLAPI'

const api = {
    loginAPI,
    loginCryptedAPI,
    logoutAPI,
    checkTokenAPI,
    recoverPasswordAPI,
    loadUsersAPI,
    getApiVersionAPI,
    richiestaTokenClientAPI,
    getAnagraficaUtenteAPI,
    getAnagraficaUtenteByCodiceUtenteAPI,
    getCodiceUtenteAPI,
    recoverUsernameAPI,
    editDatiAnagraficiAPI,
    loadSuppliesAPI,
    loadFornituraDetailsAPI,
    loadAutoletturaDetailsAPI,
    loadInvoicesAPI,
    loadInvoiceAPI,
    insertRichiestaFatturaViaMailAPI,
    getFatturaPDFAPI,
    loadRequestsAPI,
    loadRequestFromOperatorAPI,
    loadRequestDetailsAPI,
    insertRichiestaPrivacyAPI,
    insertRichiestaPrivacyRegistrationAPI,
    getTipiPaginaAPI,
    getStringheAPI,
    insertRichiestaAutoletturaAPI,
    editPasswordGuestAPI,
    getAccountUtenteAPI,
    getSportelliAziendaAPI,
    getConsumiAPI,
    getListinoPrezziGPLAPI,
    validateFatturaViaMailAPI,
    richiediTokenRegistrazioneAPI,
    validaTokenRegistrazioneAPI,
    getUtenteNonRegistratoAPI,
    registrazioneUtenteEmailAPI,
    getTipiRichiestaAbilitatiAPI,
    insertRichiestaAssistenzaAPI,
    getProvinceComuniAPI,
    getSottoTipiRichiestaAPI,
    changeAddressRequestAPI,
    loadRangeAutoletturaAPI,
    getCompanyPublicParamsAPI,
    getCompanyInformationAPI,
    insertRichiestaDomiciliazioneAPI,
    insertRichiestaDisdettaContrattoAPI,
    getRIDModuleAPI,
    getSignedPDFAPI,
    sendSignedModuleAPI,
    checkAutologinTokenValidityAPI,
    richiediPinUtenteAPI,
    confirmTokenRegistrazioneAPI,
    autologinAPI,
    getClaimsSubCatogoriesAPI,
    getClaimsRequestTypesAPI,
    getEnabledChannelsAPI,
    insertReclamoAPI,
    getLoggedUserAPI,
    loadUsersRequestsAPI,
    loadAgentsAndOperatorsAPI,
    editRequestAPI,
    loadDettaglioReclamoAPI,
    getClaimsARERACategoriesAPI,
    loadSuppliesRecontractedAPI,
    autologinCryptedTokenAPI,
    getUserAdminAPI,
    getNoteStatoAPI,
    getStatiRichiestaReclamoAPI,
    getCausaliRitardoReclamoAPI,
    getMotiviAnnullamentoReclamoAPI,
    loadDettaglioReclamoOperatoreAPI,
    downloadAllegatoReclamoAPI,
    deleteAllegatoReclamoAPI,
    uploadAllegatoReclamoAPI,
    editReclamoOperatoreAPI,
    getAllegatiReclamoAPI
}

export type AppAPITypes = typeof api

export default api
