import { Box, Heading, Spinner } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { keepAlive } from '../services/store/actions'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useI18n from '../hooks/useI18n'
import { useContainer } from '../services'
import { TipiPagina } from '../models/Enums'
import useAssets from '../hooks/useAssets'
import useSize from '../hooks/useSize'

const Informativa = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const main = useAppSelector((state) => state.main)
    const container = useContainer()
    const [loaded,setLoaded] = useState(false)
    const [getState, setState] = useState(false)
    const [getTPCookie, setTPCookie] = useState('')
    const assets = useAssets()
    const size = useSize()

    const loadData = async () => {
        try {
        await dispatch(keepAlive())
        const tp = await container.api.getTipiPaginaAPI(TipiPagina.Cookie)
        setTPCookie(tp)

        } catch (e) {}
    }

    useEffect(() => {
        loadData()

    }, [])

    return (
        <Box direction="row"
                justify="start"
                //height="100%"
                >
                <Box
                    width="30%"
                    background={"url("+assets.loginside+")"}
                    align='center'
                    justify='center'
                    pad="small"
                    height="100vh"
                    //height={"min: '100vh'"}
                    //fill="vertical"
                    style={{
                        position: 'sticky',
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                    }}
                    >
                    <Box

                        width="medium"
                        height="medium"

                        //background="url(../assets/logo.png)"
                        background={{
                            //color: "neutral-1",
                            //dark: true,
                            //opacity: true,
                            //position: "bottom",

                            repeat: "no-repeat",
                            size: "contain",
                            image:"url("+assets.logo+")"
                            //image: "url(//my.com/assets/img.png)"
                        }}
                    >
                    </Box>
                </Box>
                {(getTPCookie===''||getTPCookie===undefined)?(
                    <Box background={'background-back'}
                    justify="center" align="center" flex
                    >

                    <Box>
                        <Spinner />
                    </Box>
                </Box>
                ):(


                <Box

                        pad={{horizontal: "5%",vertical:"2%"}}
                        overflow="auto"
                        style={{height: getState?"fit-content":"100vh"}}
                        //style={{height: }//{{height: "fit-content"}}
                        width="100%"
                        animation={
                            ['fadeIn', 'slideLeft']
                        }
                    >
                    <Heading size="small">
                        {strings.NormativaCookie}
                    </Heading>

                    <Box style={{maxWidth: "50%"}} width={size=="small"?"20%":"large"}><div dangerouslySetInnerHTML={{__html: getTPCookie}} /></Box>




                </Box>
                )}


            </Box>
    )

}

export default Informativa
