import { Box, BoxExtendedProps, Text } from 'grommet'
const FooterBar = ({
    menu,
    onClick,
    ...rest
}: FooterBarProps & BoxExtendedProps) => {
    return (
        <Box
            tag="footer"
            direction="row"
            pad="small"
            background={'sidebar'}
            fill="horizontal"
            {...rest}
            justify="between"
        >
            {menu.map((it) => (
                <Box
                    key={it.id}
                    pad={{ vertical: 'xsmall' }}
                    onClick={() => (it.onClick ? it.onClick() : onClick(it.id))}
                    direction="column"
                    align="center"
                    gap="xsmall"
                    width={{ min: '70px' }}
                >
                    {it.icon}
                    <Text
                        size="small"
                        weight={it.active ? 'bold' : 'lighter'}
                        color={it.active ? 'brand' : 'text'}
                    >
                        {it.label}
                    </Text>
                    {/* {it.active ? (
                            <Box
                                width={'8px'}
                                height={'8px'}
                                round="full"
                                background={'brand'}
                            />
                        ) : undefined} */}
                </Box>
            ))}
        </Box>
    )
}

type FooterBarProps = {
    menu: Array<{
        id: string
        children?: string[]
        label: string
        icon: JSX.Element
        active: boolean
        onClick?: Function
    }>
    onClick: (id: string) => void
}

export default FooterBar
