import { Allegato } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getAllegatiReclamo =
    (idReclamo: number): AppAction<Allegato[]> =>
    async (dispatch, getState, container) => {
        try {
            const allegati = await container.api.getAllegatiReclamoAPI(idReclamo)
            return allegati
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getAllegatiReclamo
