import { IOCServices } from '..'

const checkTokenAPI =
    async (container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const rest = container.rest
        try {
            const res = await rest.get(
                '/Account/keepalive',
                {
                    withCredentials: true,
                },
            )
            const data = res.data
            return data
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default checkTokenAPI
