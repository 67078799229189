import {
    Anchor,
    Box,
    Grid,
    Heading,
    Layer,
    Spinner,
    Tab,
    Tabs,
    Text,
} from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import { ClientModel, RequestModel } from '../models/models'
import { useAppSelector } from '../services/storeProvider'

import useTheme from '../hooks/useTheme'
import loadSupplies from '../services/store/actions/loadSupplies'

import React from 'react'
import BRequest from '../components/BRequest'
import BSeparator from '../components/BSeparator'
import RequestDetailsBox from '../components/RequestDetailsBox'
import SupplyRequestBox from '../components/SupplyRequestBox'
import useAssets from '../hooks/useAssets'
import useIsAdmin from '../hooks/useIsAdmin'
import useIsEnabledClaims from '../hooks/useIsEnabledClaims'
import useIsOperator from '../hooks/useIsOperator'
import { TipiRichiesta, TipiVisualizzazioneRichiesta } from '../models/Enums'
import loadRequests from '../services/store/actions/loadRequests'
import {
    getDescTipoRichiesta,
    getIconTipoRichiesta,
    getNavLinkTipoRichiesta,
    stringDate,
} from '../utilities'
import './Richieste.css'
import ReclamoRequestDetails from '../components/ReclamoRequestDetails'
import { useSearchParams } from 'react-router-dom'

const ICON_SIZE = '36px'
const CLAIM_ID_PARAM = 'claim_id'

const Richieste = () => {
    const strings = useI18n()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const supply = useAppSelector((state) => state.supply)
    const { clientInfo: user, loggedUser } = useAppSelector(
        (state) => state.user,
    )
    const request = useAppSelector((state) => state.request)
    const requests = useAppSelector((state) => state.request.requests)
    const [isLoadingRequests, queryRequests] = useAppQuery(loadRequests)
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const assets = useAssets()
    const size = useSize()
    const [currRequests, setRequests] = useState<Array<RequestModel>>(requests)
    const [currRequest, setCurrRequest] = useState<RequestModel>()
    const theme = useTheme()
    const isOperator = useIsOperator()
    const isAdmin = useIsAdmin()
    const isClaimsEnabled = useIsEnabledClaims()
    const roles = loggedUser.roles
    const currentCodiceUtente =
        supply.selectedSupply?.codiceUtente ?? user.codiceUtente
    const isLoading = isLoadingRequests || isLoadingSupplies

    const loadData = useCallback(
        async (codiceUtente: string) => {
            try {
                let requestlist: Array<RequestModel> = await queryRequests(
                    codiceUtente,
                )
                if (supply.supplies.length === 0) {
                    await querySupplies(codiceUtente)
                }
                setRequests(requestlist)
            } catch (e) {}
        },
        [queryRequests, querySupplies, supply.supplies.length],
    )

    useEffect(() => {
        if (!currentCodiceUtente) {
            return
        }

        loadData(currentCodiceUtente)
    }, [currentCodiceUtente, loadData])

    useEffect(() => {
        const claimId = searchParams.get(CLAIM_ID_PARAM)
        if (!claimId || !currRequests) {
            return
        }
        const req = currRequests.find(
            (it) => String(it.idReclamo) === String(claimId),
        )
        if (!req) {
            return
        }
        setCurrRequest(req)
    }, [currRequests, searchParams])

    const removeParam = (paramName: string) => {
        searchParams.delete(paramName)
        setSearchParams(searchParams)
    }

    const closeDetailsModal = () => {
        setCurrRequest(undefined)
    }

    const requestModal = () => (
        <Layer
            position="center"
            onClickOutside={() => {
                removeParam(CLAIM_ID_PARAM)
                closeDetailsModal()
            }}
            onEsc={() => {
                removeParam(CLAIM_ID_PARAM)
                closeDetailsModal()
            }}
        >
            <Box
                round="small"
                gap="small"
                width={{ min: size === 'small' ? 'small' : 'xlarge' }}
                height={{
                    min: 'small',
                    height: size === 'small' ? '100%' : undefined,
                }}
                overflow={{ vertical: 'auto' }}
            >
                {!!currRequest &&
                currRequest.idTipoRichiesta ===
                    TipiRichiesta.AssistenzaEReclami &&
                isClaimsEnabled ? (
                    <ReclamoRequestDetails
                        onBackPress={() => {
                            removeParam(CLAIM_ID_PARAM)
                            closeDetailsModal()
                        }}
                        request={currRequest}
                    />
                ) : (
                    <RequestDetailsBox
                        onBackPress={() => {
                            removeParam(CLAIM_ID_PARAM)
                            closeDetailsModal()
                        }}
                        request={currRequest}
                    />
                )}
            </Box>
        </Layer>
    )

    const fullContent = (user: ClientModel) => {
        return (
            <Box flex direction="row" align="start">
                <Box fill="horizontal">
                    <Grid
                        columns={{
                            count: size === 'large' ? 2 : 1,
                            size: size === 'large' ? ['2/3', '1/3'] : 'auto',
                        }}
                        gap="small"
                        fill
                        margin={{ top: 'small' }}
                    >
                        <Box flex={{ grow: 1 }} pad="small">
                            <Box
                                direction="row"
                                gap="small"
                                pad={{ horizontal: 'medium' }}
                                align="center"
                                justify="start"
                            >
                                <Heading level={3}>
                                    {!isLoading && currRequests.length <= 0
                                        ? strings.noRequests
                                        : strings.statoRichieste}
                                </Heading>
                                {isLoading && <Spinner />}
                            </Box>
                            <Box
                                pad={{ horizontal: 'medium' }}
                                gap="small"
                                fill="vertical"
                                margin={{ top: 'small' }}
                            >
                                {currRequests.map((it) => (
                                    <Box
                                        height={{ min: 'fit-content' }}
                                        key={it.idRichiesta}
                                    >
                                        <SupplyRequestBox
                                            name={getDescTipoRichiesta(
                                                it?.idTipoRichiesta,
                                                strings,
                                            )}
                                            title={`${strings.fornitura} ${
                                                supply.supplies.find(
                                                    (f) =>
                                                        f.puntoGas ===
                                                        it.puntoGas,
                                                )?.pod
                                            }`}
                                            utente={`${strings.codiceUtente} ${
                                                supply.selectedSupply
                                                    ?.codiceUtente ??
                                                user.codiceUtente
                                            }`}
                                            isFornitura={
                                                supply.supplies.find(
                                                    (f) =>
                                                        f.puntoGas ===
                                                        it.puntoGas,
                                                ) !== undefined
                                            }
                                            height={{ min: 'fit-content' }}
                                            margin={{ bottom: 'small' }}
                                            description={`${it.tipoVia} ${it.via} ${it.numeroCivico}, ${it.comune} ${it.provincia}`}
                                            icon={
                                                it.tipoServizio === 'E' ? (
                                                    <assets.EnergyIcon ss="48px" />
                                                ) : it.tipoServizio ===
                                                  'GPL' ? (
                                                    <assets.GplIcon ss="48px" />
                                                ) : (
                                                    <assets.GasIcon ss="48px" />
                                                )
                                            }
                                            onClick={() => {
                                                setCurrRequest(it)
                                            }}
                                            idStatoRichiesta={
                                                it.idStatoRichiesta
                                            }
                                            idTipoRichiesta={it.idTipoRichiesta}
                                            elevation="0"
                                            dataRichiesta={stringDate(
                                                it.dataRichiesta,
                                            )}
                                            round="small"
                                            key={it.idRichiesta}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            width={size === 'large' ? 'medium' : 'large'}
                            gap="small"
                            pad="small"
                        >
                            <Box direction="row" gap="small" align="baseline">
                                <Heading size="small">
                                    {strings.faiUnaRichiesta}
                                </Heading>
                            </Box>
                            <Box direction="row" wrap>
                                {request.requestTypes
                                    .filter(
                                        (it) =>
                                            getNavLinkTipoRichiesta(
                                                it.idTipoRichiesta,
                                                roles,
                                            ) !==
                                            TipiVisualizzazioneRichiesta.NONMOSTRARE,
                                    )
                                    .map((it) => {
                                        const navLink = getNavLinkTipoRichiesta(
                                            it.idTipoRichiesta,
                                            roles,
                                        )
                                        if (
                                            (isOperator || isAdmin) &&
                                            Number(it.idTipoRichiesta) ===
                                                TipiRichiesta.GestionePrivacy
                                        ) {
                                            return <span />
                                        }

                                        return (
                                            <BRequest
                                                margin={{ right: 'small' }}
                                                width={{
                                                    min: 'fit-content',
                                                }}
                                                title={getDescTipoRichiesta(
                                                    it.idTipoRichiesta,
                                                    strings,
                                                )}
                                                icon={getIconTipoRichiesta(
                                                    it.idTipoRichiesta,
                                                    theme.global?.colors
                                                        ?.brand!,
                                                )}
                                                bgcolor={
                                                    !navLink
                                                        ? 'lightgrey'
                                                        : undefined
                                                }
                                                fill="horizontal"
                                                ttip={
                                                    !navLink
                                                        ? strings.WIPFunctionality
                                                        : undefined
                                                }
                                                onClick={
                                                    navLink
                                                        ? () =>
                                                              navigate(navLink)
                                                        : undefined
                                                }
                                                justify="start"
                                                key={it.idTipoRichiesta}
                                            />
                                        )
                                    })}
                            </Box>

                            <Anchor href={`mailto:${strings.infoMail}`}>
                                <Box
                                    width={{ min: '350px' }}
                                    height={{ min: '350px' }}
                                    background={{
                                        repeat: 'no-repeat',
                                        size: 'contain',
                                        image:
                                            'url(' + assets.WIPRequests + ')',
                                    }}
                                />
                            </Anchor>
                        </Box>
                    </Grid>
                </Box>
                {!!currRequest && requestModal()}
            </Box>
        )
    }

    const mobileContent = (user: ClientModel) => {
        return (
            <Box
                fill
                flex
                gap={'small'}
                align="center"
                pad={{ vertical: 'medium' }}
            >
                <Tabs style={{ width: '100%' }}>
                    <Tab
                        title={
                            <Heading margin="0" level={4}>
                                {strings.statoRichieste}
                            </Heading>
                        }
                    >
                        <Box
                            pad="medium"
                            gap="small"
                            fill="vertical"
                            overflow={{ vertical: 'scroll' }}
                            margin={{ top: 'medium' }}
                        >
                            {currRequests.map((it) => (
                                <Box
                                    height={{ min: 'fit-content' }}
                                    key={it.idRichiesta}
                                >
                                    <SupplyRequestBox
                                        name={getDescTipoRichiesta(
                                            it?.idTipoRichiesta,
                                            strings,
                                        )}
                                        title={`${strings.fornitura} ${
                                            supply.supplies.find(
                                                (f) =>
                                                    f.puntoGas === it.puntoGas,
                                            )?.pod
                                        }`}
                                        utente={`${strings.codiceUtente} ${user.codiceUtente}`}
                                        isFornitura={
                                            supply.supplies.find(
                                                (f) =>
                                                    f.puntoGas === it.puntoGas,
                                            ) !== undefined
                                        }
                                        height={{ min: 'fit-content' }}
                                        margin={{ bottom: 'small' }}
                                        description={`${it.tipoVia} ${it.via} ${it.numeroCivico}, ${it.comune} ${it.provincia}`}
                                        icon={
                                            it.tipoServizio === 'E' ? (
                                                <assets.EnergyIcon
                                                    ss={ICON_SIZE}
                                                />
                                            ) : it.tipoServizio === 'GPL' ? (
                                                <assets.GplIcon
                                                    ss={ICON_SIZE}
                                                />
                                            ) : (
                                                <assets.GasIcon
                                                    ss={ICON_SIZE}
                                                />
                                            )
                                        }
                                        onClick={() => {
                                            setCurrRequest(it)
                                        }}
                                        idStatoRichiesta={it.idStatoRichiesta}
                                        idTipoRichiesta={it.idTipoRichiesta}
                                        elevation="0"
                                        dataRichiesta={stringDate(
                                            it.dataRichiesta,
                                        )}
                                    />
                                    <BSeparator />
                                </Box>
                            ))}
                        </Box>
                    </Tab>
                    <Tab
                        title={
                            <Heading margin="0" level={4}>
                                {strings.faiUnaRichiesta}
                            </Heading>
                        }
                    >
                        <Grid
                            columns={{
                                count: 2,
                                size: 'auto',
                            }}
                            gap="medium"
                            pad="medium"
                            fill
                            margin={{ top: 'medium' }}
                        >
                            {request.requestTypes.map(
                                (it, idx) =>
                                    String(
                                        getNavLinkTipoRichiesta(
                                            it.idTipoRichiesta,
                                            roles,
                                        ),
                                    ) !==
                                        TipiVisualizzazioneRichiesta.NONMOSTRARE && (
                                        <Box
                                            direction="column"
                                            align="center"
                                            gap="small"
                                            onClick={() => {
                                                navigate(
                                                    String(
                                                        getNavLinkTipoRichiesta(
                                                            it.idTipoRichiesta,
                                                            roles,
                                                        ),
                                                    ),
                                                )
                                            }}
                                            key={idx}
                                        >
                                            {React.cloneElement(
                                                getIconTipoRichiesta(
                                                    it.idTipoRichiesta,
                                                    theme.global?.colors?.[
                                                        'brand'
                                                    ]!,
                                                ),
                                                {
                                                    size: ICON_SIZE,
                                                    height: ICON_SIZE,
                                                    width: ICON_SIZE,
                                                },
                                            )}
                                            <Text
                                                color="brand"
                                                textAlign="center"
                                                wordBreak="break-word"
                                            >
                                                {getDescTipoRichiesta(
                                                    it.idTipoRichiesta,
                                                    strings,
                                                )}
                                            </Text>
                                        </Box>
                                    ),
                            )}
                        </Grid>
                    </Tab>
                </Tabs>
                {!!currRequest && requestModal()}
            </Box>
        )
    }

    return size !== 'small' ? fullContent(user) : mobileContent(user)
}

export default Richieste
