import React, { useEffect, useState, useCallback } from 'react'
import { ThemeContext, ThemeType } from 'grommet'
import { useContainer } from '../services'

export const useThemeLoader = (): [boolean, ThemeType] => {
    const container = useContainer()
    const customers = container.customers
    const [currentTheme, setCurrentTheme] = useState<ThemeType>(
        customers.defaultCustomer.theme,
    )
    const [loading, setLoading] = useState(true)

    const loadTheme = useCallback(async () => {
        try {
            setLoading(true)
            const id = customers.currentCustomerId
            const { theme } = await customers.loadAssetsAsync(id)
            setCurrentTheme(theme)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [customers])

    useEffect(() => {
        loadTheme()
    }, [loadTheme])

    return [loading, currentTheme]
}

const useTheme = (): ThemeType => {
    const theme = React.useContext<ThemeType>(ThemeContext)

    return theme
}

export const useDimension = (size: string) => {
    let insize = size

    if (insize === 'small') {
        insize = 'medium'
    } else if (insize === 'medium') {
        insize = 'large'
    }
    return insize
}

export default useTheme
