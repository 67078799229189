import { Box, DropButton, Heading, Layer, Spinner, Text } from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

import useI18n from '../hooks/useI18n'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import useSize from '../hooks/useSize'
import {
    getDescTipoRichiesta,
    getIconTipoRichiesta,
    stringDate,
} from '../utilities'
import {
    ConsumoModel,
    Consumptions,
    DataChartItem,
    DataChartValues,
    InvoiceModel,
    MailDetail,
    RequestDetailsModel,
    RequestModel,
    SupplyModel,
} from '../models/models'

import useTheme from '../hooks/useTheme'
import loadSupplies from '../services/store/actions/loadSupplies'
import loadInvoices from '../services/store/actions/loadInvoices'
import BBackButton from '../components/BBackButton'
import MeterReadingHistoryBox from '../components/MeterReadingHistoryBox'
import SupplySelector from '../components/SupplySelector'
import ContractDetailsBox from '../components/ContractDetailsBox'
import { getDescStatoFornitura, yearStringDate } from '../utilities'
import loadRequests from '../services/store/actions/loadRequests'
import {
    BE_TipiFatturazione,
    paramEnum,
    StatiFornitura,
    TipiRichiesta,
} from '../models/Enums'
import loadConsumptions from '../services/store/actions/loadConsumptions'
import DatiContratto from './DatiContratto'
import RequestSelectorPage from '../components/RequestSelectorPage'
import useAssets from '../hooks/useAssets'
import BCloseButton from '../components/BCloseButton'
import { CircleInformation } from 'grommet-icons'
import BRoundButton from '../components/BRoundButton'
import { loadRequestDetails } from '../services/store/actions'
import useParameters from '../hooks/useParameters'
import getGPLListPrice from '../services/store/actions/getGPLListPrice'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

const DettaglioFornitura = () => {
    const strings = useI18n()
    const size = useSize()
    const main = useAppSelector((state) => state.main)
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const request = useAppSelector((state) => state.request)
    const assets = useAssets()
    const parameters = useParameters()
    const [isLoadingRequests, queryRequests] = useAppQuery(loadRequests)
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const [isLoadingInvoices, queryInvoices] = useAppQuery(loadInvoices)
    const [isLoadingDetails, queryRequestDetails] =
        useAppQuery(loadRequestDetails)
    const [isLoadingPrice, queryPrice] = useAppQuery(getGPLListPrice)
    const [isLoadingConsumptions, queryConsumptions] =
        useAppQuery(loadConsumptions)
    const [currRequests, setRequests] = useState<Array<RequestModel>>([])
    const [requestDetails, setRequestDetails] = useState<
        Array<RequestDetailsModel>
    >([])
    const [currRequestsPG, setRequestsPG] = useState<Array<RequestModel>>([])
    const [currSupplies, setSupplies] = useState<Array<SupplyModel>>([])
    const [currInvoices, setInvoices] = useState<Array<InvoiceModel>>([])
    const [requestModalVisible, setRequestModalVisible] = useState(false)
    const [currMailDetails, setMailDetails] = useState<Array<MailDetail>>([])
    const [meterInfoModalVisible, setMeterInfoModalVisible] = useState(false)
    const [GPLPrice, setGPLPrice] = useState('-')
    const [dataChart, setDataChart] = useState<DataChartValues>()

    const WEB_NumeroCampiFatturaViaMail =
        main.publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ==
                paramEnum.WEB_NumeroCampiFatturaViaMail,
        )[0]?.valore ?? 4

    const today = String(new Date())
    const currentYear = +yearStringDate(today)
    const prevYear = currentYear - 1

    const [currConsumi, setCurrConsumi] = useState<Array<ConsumoModel>>([])
    const [currentYearCons, setCurrentYearCons] = useState<Array<Consumptions>>(
        [],
    )
    const [prevYearCons, setPrevYearCons] = useState<Array<Consumptions>>([])
    const [showModalRequest, setShowModalRequest] = useState(false)

    const [showDatiContratto, setShowDatiContratto] = useState(false)

    const [graphSections, setGraphSections] = useState([
        { id: 'EE', label: 'Luce', active: false },
        { id: 'gas', label: 'Gas', active: true },
    ])
    const [graphDataPrev, setGraphDataPrev] = useState([
        {
            label: 'Gen ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Feb ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Mar ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Apr ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Mag ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Giu ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Lug ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Ago ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Set ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Ott ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Nov ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Dic ' + prevYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
    ])
    const getDescStatoFattOnline = (id: boolean) => {
        if (id) {
            return strings.attivo.toUpperCase()
        }

        return strings.nonAttivo.toUpperCase()
    }
    const [graphDataCurrent, setGraphDataCurrent] = useState([
        {
            label: 'Gen ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Feb ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Mar ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Apr ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Mag ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Giu ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Lug ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Ago ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Set ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Ott ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Nov ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
        {
            label: 'Dic ' + currentYear,
            value: { F1: 0, F2: 0, F3: 0 },
            unit: 'mc',
        },
    ])
    const getDescTipoFatt = (id: number | string) => {
        switch (id) {
            case BE_TipiFatturazione.Sintetica:
                return strings.sintetica
            case BE_TipiFatturazione.Dettagliata:
                return strings.dettagliata
            default:
                return ''
        }
    }
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const state = location.state as { path?: string }

    function compare(a: any, b: any) {
        if (a.date < b.date) {
            return -1
        }
        if (a.date > b.date) {
            return 1
        }
        return 0
    }

    const readingSummarizeF1 = (el: any, tipo: string) => {
        //if (tipo.includes("EFFETTIVA")) {
        //return String((el.consumoF1 && el.consumoF1 !== '' ? +el.consumoF1 : 0)) //+
        //(el.consumoF2 && el.consumoF2 !== '' ? +el.consumoF2 : 0) +
        //(el.consumoF3 && el.consumoF3 !== '' ? +el.consumoF3 : 0)
        //}
        //else {
        return String(el.lettura1 && el.lettura1 !== '' ? +el.lettura1 : 0) // +
        // (el.lettura2 && el.lettura2 !== '' ? +el.lettura2 : 0) +
        //(el.lettura3 && el.lettura3 !== '' ? +el.lettura3 : 0))
        //}
    }

    const readingSummarizeF2 = (el: any, tipo: string) => {
        //if (tipo.includes("EFFETTIVA")) {
        //return String((el.consumoF2 && el.consumoF2 !== '' ? +el.consumoF2 : 0)) //+
        //(el.consumoF2 && el.consumoF2 !== '' ? +el.consumoF2 : 0) +
        //(el.consumoF3 && el.consumoF3 !== '' ? +el.consumoF3 : 0)
        //}
        //else {
        return String(el.lettura2 && el.lettura2 !== '' ? +el.lettura2 : 0) // +
        // (el.lettura2 && el.lettura2 !== '' ? +el.lettura2 : 0) +
        //(el.lettura3 && el.lettura3 !== '' ? +el.lettura3 : 0))
        //}
    }

    const readingSummarizeF3 = (el: any, tipo: string) => {
        //if (tipo.includes("EFFETTIVA")) {
        //return String((el.consumoF3 && el.consumoF3 !== '' ? +el.consumoF3 : 0)) //+
        //(el.consumoF2 && el.consumoF2 !== '' ? +el.consumoF2 : 0) +
        //(el.consumoF3 && el.consumoF3 !== '' ? +el.consumoF3 : 0)
        //}
        //else {
        return String(el.lettura3 && el.lettura3 !== '' ? +el.lettura3 : 0) // +
        // (el.lettura2 && el.lettura2 !== '' ? +el.lettura2 : 0) +
        //(el.lettura3 && el.lettura3 !== '' ? +el.lettura3 : 0))
        //}
    }

    const loadConsumptionsData = useCallback(
        async (
            actualConsumptions: Array<ConsumoModel>,
            selectedSupply?: SupplyModel,
        ) => {
            const consumiFilteredAdded: Array<ConsumoModel> = []
            const _consumiGraficati: {
                date: string
                amountF1: number
                amountF2: number
                amountF3: number
            }[] = []
            let consumiFiltered: Array<ConsumoModel>
            if (selectedSupply) {
                consumiFiltered = actualConsumptions.filter(
                    (it: ConsumoModel) =>
                        selectedSupply.puntoGas === it.puntoGas,
                )
            } else {
                consumiFiltered = actualConsumptions
            }
            //const consumiFiltered: Array<ConsumoModel> = actualConsumptions.filter((it: ConsumoModel) => it.puntoGas == supply.selectedSupply?.puntoGas)
            let i = 0

            consumiFiltered.forEach((el) => {
                el = {
                    ...el,
                    id: i,
                }

                i++
                consumiFilteredAdded.push(el)

                const elge = {
                    date: el.dataLettura,
                    amountF1:
                        el.consumoF1 && el.consumoF1 !== '' ? +el.consumoF1 : 0,
                    amountF2:
                        el.consumoF2 && el.consumoF2 !== '' ? +el.consumoF2 : 0,
                    amountF3:
                        el.consumoF3 && el.consumoF3 !== '' ? +el.consumoF3 : 0,
                }

                // const elgs = {
                //     date: el.dataLettura,
                //     amount:
                //         (el.lettura1 && el.lettura1 !== '' ? +el.lettura1 : 0) +
                //         (el.lettura2 && el.lettura2 !== '' ? +el.lettura2 : 0) +
                //         (el.lettura3 && el.lettura3 !== '' ? +el.lettura3 : 0),
                // }
                //if (el.tipoLettura == 'LETTURA EFFETTIVA') {_consumiGraficati.push(elge)} else {_consumiGraficati.push(elgs)}
                _consumiGraficati.push(elge)
            })

            setCurrConsumi(consumiFilteredAdded)
            _consumiGraficati.sort(compare)
            let _currYearCons: Array<Consumptions> = []
            _currYearCons[0] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[1] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[2] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[3] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[4] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[5] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[6] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[7] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[8] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[9] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[10] = { F1: 0, F2: 0, F3: 0 }
            _currYearCons[11] = { F1: 0, F2: 0, F3: 0 }

            let _prevYearCons: Array<Consumptions> = []
            _prevYearCons[0] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[1] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[2] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[3] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[4] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[5] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[6] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[7] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[8] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[9] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[10] = { F1: 0, F2: 0, F3: 0 }
            _prevYearCons[11] = { F1: 0, F2: 0, F3: 0 }

            _consumiGraficati.forEach((el) => {
                const month = +el.date.substring(5, 7) - 1
                if (+el.date.substring(0, 4) == new Date().getFullYear()) {
                    _currYearCons[month].F1 = el.amountF1
                    _currYearCons[month].F2 = el.amountF2
                    _currYearCons[month].F3 = el.amountF3
                } else if (
                    +el.date.substring(0, 4) ==
                    new Date().getFullYear() - 1
                ) {
                    _prevYearCons[month].F1 = el.amountF1
                    _prevYearCons[month].F2 = el.amountF2
                    _prevYearCons[month].F3 = el.amountF3
                }
            })

            setCurrentYearCons(_currYearCons)
            setPrevYearCons(_prevYearCons)
            let gdprev = [
                {
                    label: 'Gen ' + prevYear,
                    value: {
                        F1: _prevYearCons[0].F1,
                        F2: _prevYearCons[0].F2,
                        F3: _prevYearCons[0].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Feb ' + prevYear,
                    value: {
                        F1: _prevYearCons[1].F1,
                        F2: _prevYearCons[1].F2,
                        F3: _prevYearCons[1].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Mar ' + prevYear,
                    value: {
                        F1: _prevYearCons[2].F1,
                        F2: _prevYearCons[2].F2,
                        F3: _prevYearCons[2].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Apr ' + prevYear,
                    value: {
                        F1: _prevYearCons[3].F1,
                        F2: _prevYearCons[3].F2,
                        F3: _prevYearCons[3].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Mag ' + prevYear,
                    value: {
                        F1: _prevYearCons[4].F1,
                        F2: _prevYearCons[4].F2,
                        F3: _prevYearCons[4].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Giu ' + prevYear,
                    value: {
                        F1: _prevYearCons[5].F1,
                        F2: _prevYearCons[5].F2,
                        F3: _prevYearCons[5].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Lug ' + prevYear,
                    value: {
                        F1: _prevYearCons[6].F1,
                        F2: _prevYearCons[6].F2,
                        F3: _prevYearCons[6].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Ago ' + prevYear,
                    value: {
                        F1: _prevYearCons[7].F1,
                        F2: _prevYearCons[7].F2,
                        F3: _prevYearCons[7].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Set ' + prevYear,
                    value: {
                        F1: _prevYearCons[8].F1,
                        F2: _prevYearCons[8].F2,
                        F3: _prevYearCons[8].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Ott ' + prevYear,
                    value: {
                        F1: _prevYearCons[9].F1,
                        F2: _prevYearCons[9].F2,
                        F3: _prevYearCons[9].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Nov ' + prevYear,
                    value: {
                        F1: _prevYearCons[10].F1,
                        F2: _prevYearCons[10].F2,
                        F3: _prevYearCons[10].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Dic ' + prevYear,
                    value: {
                        F1: _prevYearCons[11].F1,
                        F2: _prevYearCons[11].F2,
                        F3: _prevYearCons[11].F3,
                    },
                    unit: 'mc',
                },
            ]
            setGraphDataPrev(gdprev)

            let gdcurrent = [
                {
                    label: 'Gen ' + currentYear,
                    value: {
                        F1: _currYearCons[0].F1,
                        F2: _currYearCons[0].F2,
                        F3: _currYearCons[0].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Feb ' + currentYear,
                    value: {
                        F1: _currYearCons[1].F1,
                        F2: _currYearCons[1].F2,
                        F3: _currYearCons[1].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Mar ' + currentYear,
                    value: {
                        F1: _currYearCons[2].F1,
                        F2: _currYearCons[2].F2,
                        F3: _currYearCons[2].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Apr ' + currentYear,
                    value: {
                        F1: _currYearCons[3].F1,
                        F2: _currYearCons[3].F2,
                        F3: _currYearCons[3].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Mag ' + currentYear,
                    value: {
                        F1: _currYearCons[4].F1,
                        F2: _currYearCons[4].F2,
                        F3: _currYearCons[4].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Giu ' + currentYear,
                    value: {
                        F1: _currYearCons[5].F1,
                        F2: _currYearCons[5].F2,
                        F3: _currYearCons[5].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Lug ' + currentYear,
                    value: {
                        F1: _currYearCons[6].F1,
                        F2: _currYearCons[6].F2,
                        F3: _currYearCons[6].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Ago ' + currentYear,
                    value: {
                        F1: _currYearCons[7].F1,
                        F2: _currYearCons[7].F2,
                        F3: _currYearCons[7].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Set ' + currentYear,
                    value: {
                        F1: _currYearCons[8].F1,
                        F2: _currYearCons[8].F2,
                        F3: _currYearCons[8].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Ott ' + currentYear,
                    value: {
                        F1: _currYearCons[9].F1,
                        F2: _currYearCons[9].F2,
                        F3: _currYearCons[9].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Nov ' + currentYear,
                    value: {
                        F1: _currYearCons[10].F1,
                        F2: _currYearCons[10].F2,
                        F3: _currYearCons[10].F3,
                    },
                    unit: 'mc',
                },
                {
                    label: 'Dic ' + currentYear,
                    value: {
                        F1: _currYearCons[11].F1,
                        F2: _currYearCons[11].F2,
                        F3: _currYearCons[11].F3,
                    },
                    unit: 'mc',
                },
            ]
            setGraphDataCurrent(gdcurrent)

            let datasetvaluesF1: Array<DataChartItem> = []
            let datasetvaluesF2: Array<DataChartItem> = []
            let datasetvaluesF3: Array<DataChartItem> = []

            //let datasetvaluesF1: Array<DataChartItem> = gdprev.map(it => {label: 'F1', data: })
            datasetvaluesF1.push({
                label:
                    selectedSupply?.tipoServizio === 'E' ||
                    selectedSupply?.tipoServizio === undefined
                        ? 'F1'
                        : selectedSupply?.tipoServizio === 'G' ||
                          selectedSupply?.tipoServizio === 'GPL'
                        ? strings.contatore
                        : 'F1',
                data: gdprev
                    .map((it) => it.value.F1)
                    .concat(gdcurrent.map((it2) => it2.value.F1)),
                backgroundColor: '#dc00dc',
                borderColor: '#dc00dc',
                fill: true,
            })

            if (
                selectedSupply?.tipoServizio === 'G' &&
                user.personaGiuFis != 'F'
            ) {
                datasetvaluesF2.push({
                    label: strings.correttore,
                    data: gdprev
                        .map((it) => it.value.F2)
                        .concat(gdcurrent.map((it2) => it2.value.F2)),
                    backgroundColor: '#35a2eb',
                    borderColor: '#35a2eb',
                    fill: true,
                })
            } else if (selectedSupply?.tipoServizio === 'E') {
                datasetvaluesF2.push({
                    label: 'F2',
                    data: gdprev
                        .map((it) => it.value.F2)
                        .concat(gdcurrent.map((it2) => it2.value.F2)),
                    backgroundColor: '#35a2eb',
                    borderColor: '#35a2eb',
                    fill: true,
                })
            }

            if (
                selectedSupply?.tipoServizio === 'E' ||
                selectedSupply?.tipoServizio === undefined
            ) {
                datasetvaluesF3.push({
                    label: 'F3',
                    data: gdprev
                        .map((it) => it.value.F3)
                        .concat(gdcurrent.map((it2) => it2.value.F3)),
                    backgroundColor: '#291694',
                    borderColor: '#291694',
                    fill: true,
                })
            }

            // datasetvaluesF1Curr.push({label: 'F1', data: gdcurrent.map(it=>it.value.F1), borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132, 0.5)'})
            // datasetvaluesF2Curr.push({label: 'F2', data: gdcurrent.map(it=>it.value.F2), borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)'})
            // datasetvaluesF3Curr.push({label: 'F3', data: gdcurrent.map(it=>it.value.F3), borderColor: 'rgb(50, 205, 50)', backgroundColor: 'rgba(50, 205, 50, 0.5)'})

            let dataToGraph: DataChartValues = {
                labels: gdprev
                    .map((it) => it.label)
                    .concat(gdcurrent.map((it2) => it2.label)),
                datasets: datasetvaluesF1
                    .concat(datasetvaluesF2)
                    .concat(datasetvaluesF3),
            }

            setDataChart(dataToGraph)
        },
        [
            currentYear,
            prevYear,
            strings.contatore,
            strings.correttore,
            user.personaGiuFis,
        ],
    )

    const loadData = useCallback(async () => {
        try {
            let supp: SupplyModel
            const queryData = {
                codiceUtente:
                    supply.selectedSupply?.codiceUtente ?? user.codiceUtente,
                puntoGas: supply.selectedSupply?.puntoGas,
            }
            const supplies = await querySupplies(user.codiceUtente)
            if (supplies.find((it: SupplyModel) => it.tipoServizio === 'GPL')) {
                graphSections.push({
                    id: 'GPL',
                    label: `${strings.GPL}`,
                    active: false,
                })
                setGraphSections(graphSections)
            }
            if (supply.selectedSupply === undefined) {
                supp = supplies[0]

                const invoices = await queryInvoices({
                    puntoGas: supp.puntoGas,
                    codiceUtente: supp?.codiceUtente ?? user.codiceUtente,
                })
                const reqtypelist: Array<number> = []
                request.requestTypes.forEach((el) =>
                    reqtypelist.push(el.idTipoRichiesta),
                )

                let requestlist: Array<RequestModel> = await queryRequests(
                    supp.codiceUtente,
                )

                requestlist = requestlist.filter(
                    (it) => it.idTipoRichiesta === TipiRichiesta.Autolettura,
                )
                const details = []
                for (const req of requestlist) {
                    const detail = await queryRequestDetails(req.idRichiesta)
                    details.push(detail)
                }
                setRequests(requestlist)
                setSupplies(supplies)
                setInvoices(invoices)
                setRequestDetails(details.flat(1))
                const consumptions = await queryConsumptions(supplies)
                loadConsumptionsData(consumptions, supp)

                let consumpt_ar: Array<RequestModel> = []
                consumptions.forEach((el: any) => {
                    el = {
                        codiceUtente: el.codiceUtente,
                        puntoGas: el.puntoGas,
                        dataRichiesta: el.dataLettura,
                        lettura1: el.lettura1,
                        lettura2: el.lettura2,
                        lettura3: el.lettura3,
                        consumoF1: el.consumoF1,
                        consumoF2: el.consumoF3,
                        consumoF3: el.consumoF3,
                        tipoLettura: el.tipoLettura,
                        tipoHidden: 'CONSUMO',
                    }
                    consumpt_ar.push(el)
                })
                setRequestsPG(
                    requestlist
                        .concat(consumpt_ar)
                        .filter((it) => it.puntoGas === supp.puntoGas),
                )
            } else {
                const invoices = await queryInvoices(queryData)
                const reqtypelist: Array<number> = []
                request.requestTypes.forEach((el) =>
                    reqtypelist.push(el.idTipoRichiesta),
                )

                let requestlist: Array<RequestModel> = await queryRequests(
                    supply.selectedSupply?.codiceUtente ?? user.codiceUtente,
                )

                requestlist = requestlist.filter(
                    (it) => it.idTipoRichiesta === TipiRichiesta.Autolettura,
                )

                const details = []
                for (const req of requestlist) {
                    const detail = await queryRequestDetails(req.idRichiesta)
                    details.push(detail)
                }

                setRequests(requestlist)
                setSupplies(supplies)
                setInvoices(invoices)
                setRequestDetails(details.flat(1))

                const consumptions = await queryConsumptions(supplies)

                loadConsumptionsData(consumptions, supply.selectedSupply)

                let consumpt_ar: Array<RequestModel> = []
                consumptions.forEach((el: any) => {
                    el = {
                        codiceUtente: el.codiceUtente,
                        puntoGas: el.puntoGas,
                        dataRichiesta: el.dataLettura,
                        lettura1: el.lettura1,
                        lettura2: el.lettura2,
                        lettura3: el.lettura3,
                        consumoF1: el.consumoF1,
                        consumoF2: el.consumoF3,
                        consumoF3: el.consumoF3,
                        tipoLettura: el.tipoLettura,
                        tipoHidden: 'CONSUMO',
                    }
                    consumpt_ar.push(el)
                })

                setRequestsPG(
                    requestlist
                        .concat(consumpt_ar)
                        .filter(
                            (it) =>
                                it.puntoGas === supply.selectedSupply?.puntoGas,
                        ),
                )
            }
        } catch (e) {}
    }, [
        graphSections,
        loadConsumptionsData,
        queryConsumptions,
        queryInvoices,
        queryRequestDetails,
        queryRequests,
        querySupplies,
        request.requestTypes,
        strings.GPL,
        supply.selectedSupply,
        user.codiceUtente,
    ])

    const loadinitialGPLPrice = useCallback(async () => {
        if (!parameters.GPLlistPrice) {
            return
        }
        const price = await queryPrice({
            puntoGas: supply.selectedSupply?.puntoGas ?? '',
            codiceUtente: user.codiceUtente,
        })
        setGPLPrice(`${price.unitàDiMisura} ${price.importo}€`)
    }, [parameters.GPLlistPrice, queryPrice, supply.selectedSupply?.puntoGas, user.codiceUtente])

    useEffect(() => {
        loadinitialGPLPrice()
    }, [loadinitialGPLPrice])

    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        loadData()
    }, [loadData, supply.selectedSupply, user.codiceUtente])

    const loadDataSelSupply = async () => {
        try {
            if (supply.selectedSupply !== undefined) {
                loadConsumptionsData(supply.consumptions, supply.selectedSupply)

                let consumpt_ar: Array<RequestModel> = []
                supply.consumptions.forEach((el: any) => {
                    el = {
                        codiceUtente: el.codiceUtente,
                        puntoGas: el.puntoGas,
                        dataRichiesta: el.dataLettura,
                        lettura1: el.lettura1,
                        lettura2: el.lettura2,
                        lettura3: el.lettura3,
                        consumoF1: el.consumoF1,
                        consumoF2: el.consumoF3,
                        consumoF3: el.consumoF3,
                        tipoLettura: el.tipoLettura,
                        tipoHidden: 'CONSUMO',
                    }
                    consumpt_ar.push(el)
                })

                let mailDetails: Array<MailDetail> = []
                mailDetails.push({
                    title: strings.email1,
                    value: supply.selectedSupply?.email1 || '-',
                })

                if (+WEB_NumeroCampiFatturaViaMail >= 2) {
                    mailDetails.push({
                        title: strings.email2,
                        value: supply.selectedSupply?.email2 || '-',
                    })
                }
                if (+WEB_NumeroCampiFatturaViaMail >= 3) {
                    mailDetails.push({
                        title: strings.email3,
                        value: supply.selectedSupply?.email3 || '-',
                    })
                }
                if (+WEB_NumeroCampiFatturaViaMail >= 4) {
                    mailDetails.push({
                        title: strings.email4,
                        value: supply.selectedSupply?.email4 || '-',
                    })
                }

                setMailDetails(mailDetails)

                setRequestsPG(
                    currRequests
                        .concat(consumpt_ar)
                        .filter(
                            (it) =>
                                it.puntoGas === supply.selectedSupply?.puntoGas,
                        ),
                )
            }
        } catch (e) {}
    }

    useEffect(() => {
        loadDataSelSupply()
    }, [supply.selectedSupply])

    const isLoadingData =
        isLoadingConsumptions ||
        isLoadingInvoices ||
        isLoadingRequests ||
        isLoadingSupplies ||
        isLoadingDetails

    return (
        <Box flex direction="row" align="start">
            {supply.selectedSupply === undefined ? (
                //return (
                <Box fill background={'background-back'}>
                    <Box height="100vh" justify="center" align="center">
                        <Spinner />
                    </Box>
                </Box>
            ) : (
                //)
                <Box flex fill="horizontal">
                    <Box pad="small">
                        <Box direction="row" justify="between" align="center">
                            <BBackButton
                                size="small"
                                onClick={() => navigate('/home')}
                            />
                        </Box>
                        <Box
                            pad="medium"
                            direction="row"
                            justify="between"
                            margin={{ vertical: 'small' }}
                        >
                            <Heading margin={'0'} level={3}>
                                {strings.fornitura}
                            </Heading>
                            {supply.selectedSupply?.idStatoFornitura ===
                                StatiFornitura.Attiva && (
                                <BRoundButton
                                    label={strings.faiUnaRichiesta}
                                    onClick={() => {
                                        setRequestModalVisible(true)
                                    }}
                                />
                            )}
                        </Box>
                        <SupplySelector />
                        {!showDatiContratto ? (
                            <ContractDetailsBox
                                title={strings.dettagliContratto}
                                elements={[
                                    {
                                        title: strings.nominativo,
                                        value:
                                            supply.selectedSupply
                                                ?.cognomeNomeRecapito! || '-',
                                    },
                                    {
                                        title: strings.indirizzo,
                                        value:
                                            `${supply.selectedSupply?.tipoVia} ${supply.selectedSupply?.via} ${supply.selectedSupply?.numeroCivico}, ${supply.selectedSupply?.comune} (${supply.selectedSupply?.provincia})` ||
                                            '-',
                                    },
                                    {
                                        title: strings.statoContratto,
                                        value:
                                            getDescStatoFornitura(
                                                +supply.selectedSupply
                                                    ?.idStatoFornitura!,
                                                strings,
                                            ) || '-',
                                    },
                                    {
                                        title: strings.periodoFatturazione,
                                        value:
                                            supply.selectedSupply
                                                ?.periodoFatturazione! || '-',
                                    },
                                    {
                                        title: strings.podextended,
                                        value:
                                            supply.selectedSupply?.pod! || '-',
                                    },
                                    {
                                        title: strings.codofferta,
                                        value:
                                            supply.selectedSupply
                                                ?.codOfferta! || '-',
                                    },
                                ]}
                                visible={true}
                                onClick={() => {
                                    setShowDatiContratto(true)
                                }}
                            />
                        ) : (
                            <ContractDetailsBox
                                title={strings.dettagliContratto}
                                elements={[
                                    {
                                        title: strings.nominativo,
                                        value:
                                            supply.selectedSupply
                                                ?.cognomeNomeRecapito! || '-',
                                    },
                                    {
                                        title: strings.indirizzo,
                                        value:
                                            `${supply.selectedSupply?.tipoVia} ${supply.selectedSupply?.via} ${supply.selectedSupply?.numeroCivico}, ${supply.selectedSupply?.comune} (${supply.selectedSupply?.provincia})` ||
                                            '-',
                                    },
                                    {
                                        title: strings.statoContratto,
                                        value:
                                            getDescStatoFornitura(
                                                +supply.selectedSupply
                                                    ?.idStatoFornitura!,
                                                strings,
                                            ) || '-',
                                    },
                                    {
                                        title: strings.periodoFatturazione,
                                        value:
                                            supply.selectedSupply
                                                ?.periodoFatturazione! || '-',
                                    },
                                    {
                                        title: strings.podextended,
                                        value:
                                            supply.selectedSupply?.pod! || '-',
                                    },
                                    {
                                        title: strings.codofferta,
                                        value:
                                            supply.selectedSupply
                                                ?.codOfferta! || '-',
                                    },
                                    {
                                        title: strings.matricolaContatore,
                                        value:
                                            supply.selectedSupply
                                                ?.matricolaContatore! || '-',
                                    },
                                    {
                                        title: strings.tipoServizio,
                                        value:
                                            supply.selectedSupply
                                                ?.tipoServizio == 'G'
                                                ? strings.G
                                                : supply.selectedSupply
                                                      ?.tipoServizio == 'GPL'
                                                ? strings.GPL
                                                : strings.E || '-',
                                    },
                                    {
                                        title: strings.tipoUso,
                                        value:
                                            supply.selectedSupply?.tipoUso ||
                                            '-',
                                    },
                                    {
                                        title: strings.consumoPresuntoAnnuo,
                                        value:
                                            supply.selectedSupply?.consumoPresuntoAnnuo.toString() ||
                                            '-',
                                    },
                                    {
                                        title: strings.aliquotaIVA,
                                        value:
                                            supply.selectedSupply
                                                ?.aliquotaIVA || '-',
                                    },
                                    {
                                        title: strings.tipoFatturazione,
                                        value:
                                            getDescTipoFatt(
                                                supply.selectedSupply
                                                    ?.idTipoFatturazione,
                                            ) || '-',
                                    },
                                    {
                                        title: strings.FatturaOnline,
                                        value:
                                            getDescStatoFattOnline(
                                                supply.selectedSupply
                                                    ?.servizioMail,
                                            ) || '-',
                                    },
                                ]
                                    .concat(currMailDetails)
                                    .concat([
                                        {
                                            title: strings.indirizzoRecapito,
                                            value: `${supply.selectedSupply?.tipoViaRecapito} ${supply.selectedSupply?.viaRecapito} ${supply.selectedSupply?.nrCivicoRecapito}, ${supply.selectedSupply?.comuneRecapito} (${supply.selectedSupply?.provinciaRecapito}) - ${supply.selectedSupply?.capRecapito}`,
                                        },
                                        {
                                            title: strings.metodoPagamento,
                                            value:
                                                supply.selectedSupply
                                                    ?.metodoPagamento || '-',
                                        },
                                        {
                                            title: strings.statoDomiciliazione,
                                            value:
                                                supply.selectedSupply
                                                    ?.statoDomiciliazione ||
                                                '-',
                                        },
                                        {
                                            title: strings.ibanPaese,
                                            value:
                                                supply.selectedSupply
                                                    ?.ibanPaese || '-',
                                        },
                                        {
                                            title: strings.descrizioneBanca,
                                            value:
                                                supply.selectedSupply
                                                    ?.descrizioneBanca || '-',
                                        },
                                    ])}
                                visible={false}
                                onClick={() => {
                                    setShowDatiContratto(false)
                                }}
                            />
                        )}
                    </Box>
                    {parameters.GPLlistPrice && (
                        <Box
                            pad="medium"
                            round="small"
                            margin="small"
                            gap="small"
                            direction="row"
                            align="center"
                            background={'background-grey'}
                        >
                            <Text size="xlarge" weight={'lighter'} margin={'0'}>
                                {strings.prezzoListino}:
                            </Text>
                            <Text size="large" weight={'bold'}>
                                {GPLPrice}
                            </Text>
                        </Box>
                    )}
                    {parameters.supplyChartVisible && (
                        <Box
                            pad="medium"
                            round="small"
                            margin="small"
                            gap="small"
                        >
                            <Heading size="small">
                                {strings.iMieiConsumi}
                            </Heading>

                            {dataChart?.labels !== undefined &&
                            dataChart.datasets !== undefined ? (
                                <Box
                                    pad={
                                        size === 'large'
                                            ? { horizontal: '10%' }
                                            : undefined
                                    }
                                >
                                    <Line
                                        options={{
                                            responsive: true,
                                            scales: {
                                                y: {
                                                    min: 0,
                                                    ticks: {
                                                        // Include a dollar sign in the ticks
                                                        callback: function (
                                                            value,
                                                            index,
                                                            ticks,
                                                        ) {
                                                            return (
                                                                value +
                                                                (supply
                                                                    .selectedSupply
                                                                    ?.tipoServizio ===
                                                                    'E' ||
                                                                supply
                                                                    .selectedSupply
                                                                    ?.tipoServizio ===
                                                                    undefined
                                                                    ? ' ' +
                                                                      strings.KilowattOra
                                                                    : ' ' +
                                                                      strings.StandardMetroCubo)
                                                            )
                                                        },
                                                    },
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    position: 'top' as const,
                                                },
                                                title: {
                                                    display: false,
                                                    text: strings.iMieiConsumi,
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (
                                                            context,
                                                        ) {
                                                            let label =
                                                                context.dataset
                                                                    .label || ''

                                                            if (label) {
                                                                label += ': '
                                                            }
                                                            if (
                                                                context.parsed
                                                                    .y !== null
                                                            ) {
                                                                label += String(
                                                                    context
                                                                        .parsed
                                                                        .y,
                                                                )
                                                            }
                                                            label = label + ' '
                                                            label =
                                                                label +
                                                                (supply
                                                                    .selectedSupply
                                                                    ?.tipoServizio ===
                                                                    'E' ||
                                                                supply
                                                                    .selectedSupply
                                                                    ?.tipoServizio ===
                                                                    undefined
                                                                    ? strings.KilowattOra
                                                                    : strings.StandardMetroCubo)
                                                            return label
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                        data={dataChart!}
                                    />
                                </Box>
                            ) : undefined}
                        </Box>
                    )}
                    <Box pad="medium" round="small" margin="small" gap="small">
                        <Box justify="between" direction="row" align="center">
                            <Heading size="small">
                                {strings.storicoAutoletture}
                            </Heading>
                            {size === 'small' && (
                                <DropButton
                                    alignSelf="center"
                                    margin={{ vertical: 'small' }}
                                    dropContent={
                                        <Box
                                            pad="small"
                                            gap="small"
                                            round="medium"
                                        >
                                            <Heading level={3}>
                                                {strings.tipoLetture}
                                            </Heading>
                                            <Box
                                                alignSelf="start"
                                                pad={{
                                                    vertical: '10px',
                                                    horizontal: '10px',
                                                }}
                                                round="large"
                                                background="lightblue"
                                                direction="column"
                                            >
                                                <Text size="small">
                                                    {strings.autolettura}
                                                </Text>
                                            </Box>
                                            <Box
                                                alignSelf="start"
                                                pad={{
                                                    vertical: '10px',
                                                    horizontal: '10px',
                                                }}
                                                round="large"
                                                background="warn"
                                                direction="column"
                                            >
                                                <Text size="small">
                                                    {strings.letturaEffettiva}
                                                </Text>
                                            </Box>
                                            <Box
                                                alignSelf="start"
                                                pad={{
                                                    vertical: '10px',
                                                    horizontal: '10px',
                                                }}
                                                round="large"
                                                background="red"
                                                direction="column"
                                            >
                                                <Text size="small">
                                                    {strings.letturaStimata}
                                                </Text>
                                            </Box>
                                        </Box>
                                    }
                                    dropProps={{ align: { top: 'bottom' } }}
                                >
                                    <CircleInformation size="medium" />
                                </DropButton>
                            )}
                        </Box>
                        {isLoadingData && (
                            <Box fill background={'background-back'}>
                                <Box justify="center" align="center">
                                    <Spinner />
                                </Box>
                            </Box>
                        )}

                        {!isLoadingData &&
                            supply.selectedSupply &&
                            currRequestsPG.length === 0 && (
                                <Box
                                    direction="row"
                                    //gap="small"
                                    align="baseline"
                                >
                                    <Heading level={4} size="small">
                                        {strings.noReads}
                                    </Heading>
                                </Box>
                            )}

                        {!isLoadingData &&
                            supply.selectedSupply &&
                            currRequestsPG.length > 0 && (
                                <MeterReadingHistoryBox
                                    supply={supply.selectedSupply}
                                    rows={currRequestsPG
                                        .filter(
                                            (r) =>
                                                r.puntoGas ===
                                                supply.selectedSupply?.puntoGas,
                                        )
                                        .map((it) => {
                                            return {
                                                date: stringDate(
                                                    it.dataRichiesta,
                                                ),
                                                type:
                                                    it.tipoHidden === 'CONSUMO'
                                                        ? it.tipoLettura
                                                        : requestDetails.find(
                                                              (r) =>
                                                                  r.idRichiesta ===
                                                                      it.idRichiesta &&
                                                                  r.descrizione ===
                                                                      'Tipo Lettura',
                                                          )?.valore ??
                                                          'STIMATA',
                                                typeColor:
                                                    requestDetails.find(
                                                        (r) =>
                                                            r.idRichiesta ===
                                                                it.idRichiesta &&
                                                            r.descrizione ===
                                                                'Tipo Lettura',
                                                    )?.valore === 'AUTOLETTURA'
                                                        ? 'lightblue'
                                                        : it.tipoLettura?.includes(
                                                              'INIZIALE',
                                                          )
                                                        ? 'red'
                                                        : it.tipoLettura?.includes(
                                                              'EFFETTIVA',
                                                          )
                                                        ? 'warn'
                                                        : 'darkorange',
                                                value1:
                                                    it.tipoHidden === 'CONSUMO'
                                                        ? readingSummarizeF1(
                                                              it,
                                                              it.tipoLettura,
                                                          )
                                                        : supply.selectedSupply
                                                              ?.tipoServizio ===
                                                          'E'
                                                        ? requestDetails.find(
                                                              (r) =>
                                                                  r.idRichiesta ===
                                                                      it.idRichiesta &&
                                                                  r.descrizione ===
                                                                      'Lettura Contatore F1',
                                                          )?.valore
                                                            ? `${
                                                                  requestDetails.find(
                                                                      (r) =>
                                                                          r.idRichiesta ===
                                                                              it.idRichiesta &&
                                                                          r.descrizione ===
                                                                              'Lettura Contatore F1',
                                                                  )?.valore
                                                              }`
                                                            : ''
                                                        : requestDetails.find(
                                                              (r) =>
                                                                  r.idRichiesta ===
                                                                      it.idRichiesta &&
                                                                  r.descrizione ===
                                                                      'Lettura Contatore',
                                                          )?.valore
                                                        ? `${
                                                              requestDetails.find(
                                                                  (r) =>
                                                                      r.idRichiesta ===
                                                                          it.idRichiesta &&
                                                                      r.descrizione ===
                                                                          'Lettura Contatore',
                                                              )?.valore
                                                          }`
                                                        : '',
                                                value2:
                                                    it.tipoHidden === 'CONSUMO'
                                                        ? readingSummarizeF2(
                                                              it,
                                                              it.tipoLettura,
                                                          )
                                                        : supply.selectedSupply
                                                              ?.tipoServizio ===
                                                          'E'
                                                        ? requestDetails.find(
                                                              (r) =>
                                                                  r.idRichiesta ===
                                                                      it.idRichiesta &&
                                                                  r.descrizione ===
                                                                      'Lettura Contatore F2',
                                                          )?.valore
                                                            ? `${
                                                                  requestDetails.find(
                                                                      (r) =>
                                                                          r.idRichiesta ===
                                                                              it.idRichiesta &&
                                                                          r.descrizione ===
                                                                              'Lettura Contatore F2',
                                                                  )?.valore
                                                              }`
                                                            : ''
                                                        : '',
                                                value3:
                                                    it.tipoHidden === 'CONSUMO'
                                                        ? readingSummarizeF3(
                                                              it,
                                                              it.tipoLettura,
                                                          )
                                                        : supply.selectedSupply
                                                              ?.tipoServizio ===
                                                          'E'
                                                        ? requestDetails.find(
                                                              (r) =>
                                                                  r.idRichiesta ===
                                                                      it.idRichiesta &&
                                                                  r.descrizione ===
                                                                      'Lettura Contatore F3',
                                                          )?.valore
                                                            ? `${
                                                                  requestDetails.find(
                                                                      (r) =>
                                                                          r.idRichiesta ===
                                                                              it.idRichiesta &&
                                                                          r.descrizione ===
                                                                              'Lettura Contatore F3',
                                                                  )?.valore
                                                              }`
                                                            : ''
                                                        : '',
                                            }
                                        })}
                                />
                            )}
                    </Box>
                </Box>
            )}
            {showModalRequest && (
                <Layer
                    position="center"
                    margin={{ top: 'small', bottom: 'small' }}
                    onClickOutside={() => setShowModalRequest(false)}
                    onEsc={() => setShowModalRequest(false)}
                >
                    <Box pad="small" overflow={{ vertical: 'auto' }}>
                        <BCloseButton
                            size="medium"
                            onClick={() => setShowModalRequest(false)}
                        />
                        <DatiContratto showSide={false} />
                    </Box>
                </Layer>
            )}
            {requestModalVisible && (
                <Layer
                    position="center"
                    onClickOutside={() => setRequestModalVisible(false)}
                    onEsc={() => setRequestModalVisible(false)}
                >
                    <Box
                        //pad="medium"

                        gap="small"
                        width={{ min: 'small' }}
                        height={{ min: 'small' }}
                        fill
                    >
                        <RequestSelectorPage
                            title={strings.selectYourSupply}
                            icon={
                                supply.selectedSupply?.tipoServizio === 'E' ? (
                                    <assets.EnergyIcon />
                                ) : supply.selectedSupply?.tipoServizio ===
                                  'GPL' ? (
                                    <assets.GplIcon />
                                ) : (
                                    <assets.GasIcon />
                                )
                            }
                            fclose={() => setRequestModalVisible(false)}
                            requests={request.requestTypes.map((it) => {
                                return {
                                    title: getDescTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        strings,
                                    ),
                                    icon: getIconTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        theme.global?.colors?.[
                                            'brand'
                                        ]?.toString()!,
                                    ),
                                    activeIcon: getIconTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        theme.global?.colors?.[
                                            'textWhite'
                                        ]?.toString()!,
                                    ),
                                    id: String(it?.idTipoRichiesta),
                                }
                            })}
                            address={`${supply.selectedSupply?.tipoVia} ${supply.selectedSupply?.via} ${supply.selectedSupply?.numeroCivico}, ${supply.selectedSupply?.comune} ${supply.selectedSupply?.provincia}`}
                            addressColor={
                                supply.addresses.find(
                                    (element) =>
                                        element.puntiGas.indexOf(
                                            supply.selectedSupply?.puntoGas!,
                                        ) > -1,
                                )?.color!
                            }
                            pod={`${
                                supply.selectedSupply?.tipoServizio === 'E'
                                    ? strings.pod
                                    : strings.pdr
                            } ${supply.selectedSupply?.pod}`}
                            requestsContainerTitle={
                                strings.richiesteDisponibili
                            }
                        />
                    </Box>
                </Layer>
            )}

            {meterInfoModalVisible && (
                <Layer
                    position="center"
                    onClickOutside={() => setMeterInfoModalVisible(false)}
                    onEsc={() => setMeterInfoModalVisible(false)}
                >
                    <Box
                        gap="small"
                        width={{ min: 'small' }}
                        height={{ min: 'small' }}
                        fill
                    >
                        <BCloseButton
                            size="medium"
                            onClick={() => setMeterInfoModalVisible(false)}
                        />
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default DettaglioFornitura
