import { Box, BoxProps, Text, TextProps } from 'grommet'
import useSize from '../hooks/useSize'
import BSeparator from './BSeparator'
import { PropsWithChildren } from 'react'

const BCard = ({ children, bgcolor, elevation, ...rest }: BCardProps) => {
    return (
        <Box
            flex
            elevation={elevation || "small"}
            round="small"
            pad="medium"
            background={bgcolor??'background-front'}
            {...rest}>
            {children}
        </Box>
    )
}

export const BInfoRow = ({ values, footer, dimensions, elevation,...rest }: BInfoRowProps) => {
    const size = useSize()
    if (size === 'small') {
        return <BInfoCard valuesDirection='column' values={values} footer={footer} {...rest} />
    }
    return (
        <BCard elevation={elevation} {...rest}>
            <Box flex wrap direction={'row'} justify="between" align='center'>
                {values.map(([key, val], index) => {
                    const dim = !!dimensions ? dimensions[index] : 'auto'
                    return (
                        <Box basis={dim} direction="row">
                            <Box>
                                <Text
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                    weight={'bold'}
                                    size="small">
                                    {key}
                                </Text>
                                <Text size="medium" weight={'lighter'}>
                                    {val || '-'}
                                </Text>
                            </Box>
                        </Box>
                    )
                })}
                {footer}
            </Box>
        </BCard>
    )
}

export const BInfoCard = ({
    values,
    footer,
    header,
    valuesDirection = 'row',
    columnDirection = 'column',
    keyTextProps,
    valueTextProps,
    valuesBoxProps,
    ...rest
}: BInfoCardProps) => {
    return (
        <BCard width={{ min: 'small' }} {...rest}>
            <Box flex>
                {header}
                <Box
                    flex
                    wrap
                    direction={columnDirection}
                    gap={columnDirection === 'row' ? 'medium' : undefined}
                >
                    {values
                        .filter(([key, val]) => !!key && !!val)
                        .map(([key, val], idx) => (
                                <Box flex width={{ min: 'small' }} key={idx} {...valuesBoxProps}>
                                    {columnDirection === 'column' && <BSeparator />}
                                    <Box
                                        flex
                                        direction={valuesDirection}
                                        justify="between"
                                        align={
                                            valuesDirection === 'row'
                                                ? 'center'
                                                : 'start'
                                        }
                                        pad={{ vertical: 'small' }}
                                    >
                                        <Text
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                            weight={'bold'}
                                            size="small"
                                            {...keyTextProps}
                                        >
                                            {key}
                                        </Text>
                                        <Text
                                            size="medium"
                                            weight={'lighter'}
                                            {...valueTextProps}
                                        >
                                            {val}
                                        </Text>
                                    </Box>
                                </Box>
                            ))
                    }
                </Box>
                {footer && <BSeparator />}
                {footer}
            </Box>
        </BCard>
    )
}

export default BCard

interface BCardProps extends PropsWithChildren<BoxProps> {
    bgcolor?: string
}

interface BInfoCardProps extends BoxProps {
    values: Array<[string, string | number | JSX.Element]>
    footer?: JSX.Element
    header?: JSX.Element
    valuesBoxProps?: BoxProps
    keyTextProps?: TextProps
    valueTextProps?: TextProps
    valuesDirection?: 'row' | 'column'
    columnDirection?: 'row' | 'column'
}

interface BInfoRowProps extends BoxProps {
    values: Array<[string, string | number | JSX.Element]>
    dimensions?: Array<string>
    footer?: JSX.Element
}
