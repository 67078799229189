import { StatiFornitura } from '../../../models/Enums'
import { AddressModel, SupplyModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_ADDRESSES,
    SET_LENADDRESSES,
    SET_LENSUPPLIES,
    SET_REMINDERS,
    SET_SUPPLIES,
    SET_SUPPLY,
} from './types'

const loadSupplies =
    (codiceUtente: string): AppAction<SupplyModel[]> =>
    async (dispatch, getState, container) => {
        let curr_reminders = []
        let addresses = []
        const roles = getState().user.loggedUser.roles
        try {
            const supplies = await container.api.loadSuppliesAPI(
                codiceUtente,
                roles,
            )
            if (supplies.length > 0) {
                for (const sup of supplies) {
                    //dettaglio

                    const det = await container.api.loadFornituraDetailsAPI(
                        codiceUtente,
                        sup.puntoGas,
                        roles,
                    )
                    sup.dataLettura = det.dataLettura
                    sup.lettura = det.lettura
                    sup.letturaF1 = det.letturaF1
                    sup.letturaF2 = det.letturaF2
                    sup.letturaF3 = det.letturaF3
                    sup.letturaERF1 = det.letturaERF1
                    sup.letturaERF2 = det.letturaERF2
                    sup.letturaERF3 = det.letturaERF3
                    sup.letturaEPF1 = det.letturaEPF1
                    sup.letturaEPF2 = det.letturaEPF2
                    sup.letturaEPF3 = det.letturaEPF3
                    sup.letturaEIF1 = det.letturaEIF1
                    sup.letturaEIF2 = det.letturaEIF2
                    sup.letturaEIF3 = det.letturaEIF3
                    sup.letturaPF1 = det.letturaPF1
                    sup.letturaPF2 = det.letturaPF2
                    sup.letturaPF3 = det.letturaPF3
                    sup.consumoPresuntoAnnuo = det.consumoPresuntoAnnuo
                    sup.pod = det.pod
                    sup.email1 = det.email1
                    sup.email2 = det.email2
                    sup.email3 = det.email3
                    sup.email4 = det.email4
                    sup.interno = det.interno
                    sup.scala = det.scala
                    sup.servizioMail = det.servizioMail
                    sup.idTipoFatturazione = det.idTipoFatturazione
                    sup.metodoPagamento = det.metodoPagamento
                    sup.periodoFatturazione = det.periodoFatturazione
                    sup.tipoUso = det.tipoUso
                    sup.codOfferta = det.codOfferta
                    sup.cognomeNomeRecapito = det.cognomeNomeRecapito
                    sup.tipoViaRecapito = det.tipoViaRecapito
                    sup.viaRecapito = det.viaRecapito
                    sup.nrCivicoRecapito = det.nrCivicoRecapito
                    sup.esponenteCivicoRecapito = det.esponenteCivicoRecapito
                    sup.scalaRecapito = det.scalaRecapito
                    sup.pianoRecapito = det.pianoRecapito
                    sup.internoRecapito = det.internoRecapito
                    sup.provinciaRecapito = det.provinciaRecapito
                    sup.comuneRecapito = det.comuneRecapito
                    sup.capRecapito = det.capRecapito
                    sup.matricolaContatore = det.matricolaContatore
                    sup.aliquotaIVA = det.aliquotaIVA
                    sup.statoDomiciliazione = det.statoDomiciliazione
                    sup.ibanPaese = det.ibanPaese
                    sup.descrizioneBanca = det.descrizioneBanca
                    sup.matricolaCorrettore = det.matricolaCorrettore
                    sup.letturaCorrettore = det.letturaCorrettore
                    sup.tipoInvio = det.tipoInvio
                    sup.dataFineOfferta = det.dataFineOfferta
                    sup.codiceTipoUso = det.codiceTipoUso

                    const newAddress: AddressModel = {
                        comune: sup.comune,
                        tipoVia: sup.tipoVia,
                        via: sup.via,
                        numeroCivico: sup.numeroCivico,
                        cap: sup.cap,
                        provincia: sup.provincia,
                        esponenteCivico: sup.esponenteCivico,
                        color: '',
                        percent: 1,
                        puntiGas: [sup.puntoGas],
                    }

                    const index = addresses.findIndex(
                        (object) =>
                            object.comune === newAddress.comune &&
                            object.tipoVia === newAddress.tipoVia &&
                            object.via === newAddress.via &&
                            //object.numeroCivico === newAddress.numeroCivico &&
                            object.cap === newAddress.cap &&
                            object.provincia === newAddress.provincia,
                        //object.esponenteCivico === newAddress.esponenteCivico
                    )

                    if (index === -1) {
                        addresses.push(newAddress)
                    } else {
                        addresses[index].percent += 1
                        addresses[index].puntiGas.push(sup.puntoGas)
                    }

                    try {
                        const auto =
                            await container.api.loadAutoletturaDetailsAPI(
                                codiceUtente,
                                sup.puntoGas,
                                roles,
                            )
                        if (auto) {
                            auto.puntoGas = sup.puntoGas
                            auto.pod = sup.pod
                            sup.isOpen = true
                            sup.fromDate = auto.dataInizio
                            sup.toDate = auto.dataFine
                        } else {
                            sup.isOpen = true
                        }

                        curr_reminders.push(auto)
                    } catch (ee) {}
                }

                dispatch({ type: SET_REMINDERS, payload: curr_reminders })
            }

            dispatch({ type: SET_SUPPLIES, payload: supplies })

            const supl = supplies.length > 0 ? supplies.length : 1

            for (var i = 0; i < addresses.length; i++) {
                const perc = addresses[i].percent
                const tot = (perc / supl).toFixed(2)

                addresses[i].percent =
                    Math.floor(+tot * 100) > 100 ? 100 : Math.floor(+tot * 100)
            }

            addresses.sort((a, b) => b.percent - a.percent)
            
            const sortedAddress = addresses.map((it, index) => {
                return { ...it, color: `accent-${(index % 7) + 1}` }
            })

            dispatch({ type: SET_ADDRESSES, payload: sortedAddress })
            const lenbox =
                sortedAddress.length / 2 < 1
                    ? 1
                    : Math.round(sortedAddress.length / 2)
            const lensupp =
                supplies.length / 2 < 1 ? 1 : Math.round(supplies.length / 2)

            dispatch({ type: SET_LENADDRESSES, payload: lenbox })
            dispatch({ type: SET_LENSUPPLIES, payload: lensupp })

            if (!getState().supply.selectedSupply) {
                dispatch({
                    type: SET_SUPPLY,
                    payload: supplies.find(
                        (it: SupplyModel) =>
                            it.idStatoFornitura === StatiFornitura.Attiva,
                    ), // Select the first active supply
                })
            }

            return supplies
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

// export type loadSuppliesDataType = {
//     codiceUtente: string,
//     selectedSupply?: SupplyModel
// }

export default loadSupplies
