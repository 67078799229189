import { Allegato } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const downloadAllegatoReclamo =
    (allegato: Allegato): AppAction<boolean> =>
    async (dispatch, getState, container) => {
        try {
            const blob = await container.api.downloadAllegatoReclamoAPI(allegato.idAllegato)
            const file = new Blob([blob], {})
            const fullFileName = allegato.nomeFile

            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            const downloadLink = document.createElement('a')
            downloadLink.href = fileURL
            downloadLink.setAttribute('download', fullFileName)
            downloadLink.click()
            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default downloadAllegatoReclamo
