import { Box, Button, ButtonExtendedProps } from "grommet"
import { FormClose } from "grommet-icons"

const BCloseButton = (props: ButtonExtendedProps) => {
    return (
        <Box round="xsmall" elevation="small" justify="center" align="center">
            <Button style={{padding: "6px"}} secondary icon={<FormClose color="brand" size="medium" />}  {...props}/>
        </Box>
    )
}

export default BCloseButton
