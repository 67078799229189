import {
    Box,
    ColumnConfig,
    DataTable,
    Spinner,
    Text,
    BoxExtendedProps,
    Button,
    Pagination,
} from 'grommet'
import { User } from 'grommet-icons'
import { UserSearchModel, UsersSearchModel } from '../models/models'
import useI18n from '../hooks/useI18n'
import { USERS_PER_PAGE } from '../services/api/loadUsersAPI'

type PaginationProps = {
    page: number
    startIndex: number
    endIndex: number
}

export interface UserSearchProps extends BoxExtendedProps {
    isLoading: boolean
    users: UsersSearchModel
    onUserSelect: (item: UserSearchModel) => void
    onPageChange?: ({ page, startIndex, endIndex }: PaginationProps) => void
}

export default function UserSearch({
    isLoading,
    users,
    onUserSelect,
    onPageChange,
    ...boxProps
}: UserSearchProps) {
    const strings = useI18n()

    const columns: ColumnConfig<UserSearchModel>[] = [
        {
            property: 'id',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.codiceUtente}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.userId}
                </Text>
            ),
        },
        {
            property: 'name',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.nominativo}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.name}
                </Text>
            ),
        },
        {
            property: 'address',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.indirizzo}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.address}
                </Text>
            ),
        },
        {
            property: 'municipality',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.comune}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.municipality}
                </Text>
            ),
        },
        {
            property: 'fiscalCode',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    truncate="tip"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.fiscalCode}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.fiscalCode}
                </Text>
            ),
        },
        {
            property: 'vatNumber',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.partitaIVA}
                </Text>
            ),
            render: (item: UserSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.vatNumber}
                </Text>
            ),
        },
        {
            property: 'action',
            size: 'small',
            render: (item: UserSearchModel) => (
                <Button
                    primary
                    size="small"
                    label={strings.selectClient}
                    onClick={() => onUserSelect(item)}
                    icon={<User size="small" />}
                />
            ),
        },
    ]

    return (
        <Box
            background={'background-front'}
            pad="medium"
            round="small"
            {...boxProps}
        >
            <DataTable
                paginate
                pad="small"
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                background={'background-front'}
                columns={columns}
                data={users?.users}
                sortable
                placeholder={
                    isLoading ? (
                        <Box
                            fill
                            align="center"
                            justify="center"
                            direction="row"
                            pad="large"
                            gap="small"
                            background={{
                                color: 'background-front',
                                opacity: 'strong',
                            }}
                        >
                            <Spinner />
                            <Text weight="bold">{`${
                                strings.loading
                            } ${strings.clients.toLowerCase()}...`}</Text>
                        </Box>
                    ) : undefined
                }
                pin
                fill
            />
            <Pagination
                alignSelf="end"
                step={USERS_PER_PAGE}
                numberItems={users.totalRows}
                onChange={onPageChange}
                margin={{ top: 'xsmall' }}
                size="small"
            />
        </Box>
    )
}
