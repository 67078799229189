import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { useAppSelector } from '../storeProvider'

export type recoverUsernameType = {
    codiceUtente: string
}

const recoverUsernameAPI =
    async ({ codiceUtente }: recoverUsernameType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const restID = container.restID
        const restAPI = container.rest

        try {

            const res = await restID.post(
                '/Utente/RecuperaUsername',{
                    CodiceUtente: codiceUtente,
                    CodiceWeb: ''
            })
            if (res.status == HttpStates.OK) {
                return true
            }
            else {
                return false
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default recoverUsernameAPI
