import { DettaglioReclamo } from '../../api/loadDettaglioReclamoOperatoreAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const insertReclamo =
    (claim: DettaglioReclamo): AppAction<boolean> =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            await container.api.insertReclamoAPI(claim)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return true
        } catch {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.errorMessage,
                    show: true,
                }),
            )
            return false
        }
    }

export default insertReclamo
