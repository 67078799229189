import {
    Box,
    BoxProps,
    Button,
    ButtonExtendedProps,
    ButtonProps,
    Text,
    TextExtendedProps,
} from 'grommet'

const BLightButton = ({
    label,
    onClick,
    boxProps,
    textProps,
    ...rest
}: BLightButtonProps) => {
    return (
        <Box
            align="center"
            pad="xsmall"
            gap="xsmall"
            style={{ cursor: rest.disabled ? 'auto' : 'pointer' }}
            onClick={(e) => !rest.disabled && onClick?.(e)}
            {...boxProps}
        >
            <Button
                plain
                reverse
                style={{ cursor: rest.disabled ? 'auto' : 'pointer' }}
                {...rest}
            />
            <Text
                style={{ textTransform: 'uppercase' }}
                size={'xsmall'}
                weight={'bold'}
                color={rest.icon?.props.color || 'brand'}
                {...textProps}
            >
                {label}
            </Text>
        </Box>
    )
}

export default BLightButton

interface BLightButtonProps extends ButtonProps, ButtonExtendedProps {
    boxProps?: BoxProps
    textProps?: TextExtendedProps
}
