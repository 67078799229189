import { IOCServices } from '..'
import { Allegato, Nota, Risposta } from '../../models/models'

export type DettaglioReclamo = {
    codiceUtente: string
    descrizione: string
    puntiGas: string[]
    idTipoContatto: number
    nominativoContatto: string
    occupazione?: string
    emailMittente: string
    telefonoChiamante: string
    emailPerRicontatto: string
    telefonoPerRicontatto: string
    dataRichiesta: string
    oraRichiesta: string
    idCanale: number
    idSottoCategoria: number
    idTipoRichiesta: number
    idCategoria: number
    allegati?: File[]
}

export type DettaglioReclamoOperatore = {
    codiceUtente: string
    idReclamo: number
    idCategoria: number
    dataLimite: string
    allegati: Allegato[]
    risposte: Risposta[]
    note: Nota[]
    inviareRispostaViaMail?: boolean
    inserireIntestazione?: boolean
    ticketErrato?: boolean
    ticketCaring?: boolean
    ticketRetention?: boolean
    idIncaricato?: number
    idStato: number
    stato: null
    idNotaStato: number
    dataRicezioneRichiesta: string
    oraRicezioneRichiesta: string
    dataChiusuraRichiesta: string
    oraChiusuraRichiesta: string
    idMotivoAnnullamento?: number
    idCausaleRitardo?: number
    dataInizioInvioDatiTecnici?: string
    oraInizioInvioDatiTecnici?: string
    dataFineRicezioneDatiTecnici?: string
    oraFineRicezioneDatiTecnici?: string
    dataUltimaRisposta?: string
    oraUltimaRisposta?: string
    inizioInvioDatiTecnici: string
    fineRicezioneDatiTecnici: string
    chiusuraRichiesta: string
    descrizione: string
    puntiGas: string[]
    idTipoContatto: number
    nominativoContatto: string
    occupazione?: string
    emailMittente: string
    telefonoChiamante: string
    emailPerRicontatto: string
    telefonoPerRicontatto: string
    dataRichiesta: string
    oraRichiesta: string
    idCanale: number
    idSottoCategoria: number
    idTipoRichiesta: number
    testoNoteInput?: string
    testoRispostaInput?: string
    allegatoInput?: File[]
    allegatoRispostaInput?: File[]
}

const loadDettaglioReclamoOperatoreAPI = async (
    idReclamo: number,
    container?: IOCServices,
): Promise<DettaglioReclamoOperatore> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const api = `Reclami/GetDettaglioReclamo?idReclamo=${idReclamo}`
        const res = await rest.get(api)

        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadDettaglioReclamoOperatoreAPI
