import { TipiPagina } from '../../../models/Enums'
import { InvoiceFiltersModel, RequestDetailsModel } from '../../../models/models'
import { formatDate } from '../../../utilities'
import { checkAutologinTokenValidityType } from '../../api/checkAutologinTokenValidityAPI'
import { AppAction, AppAsyncAction, useAppSelector } from '../../storeProvider'
import setNotification from './setNotification'

const checkAutologinTokenValidity: AppAsyncAction<any> =
    (item: checkAutologinTokenValidityType) =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)

        try {
            const data = await container.api.checkAutologinTokenValidityAPI(item)
            
            return data
        } catch (e) {
            return undefined
        }
    }

export default checkAutologinTokenValidity
