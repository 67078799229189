import { Box, BoxExtendedProps, Grid, Text } from 'grommet'
import { ColorType } from 'grommet/utils'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'
import { SupplyModel } from '../models/models'
import { useAppSelector } from '../services/storeProvider'

const MeterReadingHistoryBox = ({
    rows,
    onRowClick,
    supply,
    ...rest
}: MeterReadingHistoryBoxProps) => {
    const strings = useI18n()
    const user = useAppSelector((state) => state.user.clientInfo)
    const size = useSize()
    return (
        <Box gap="large" {...rest}>
            {
                size !== 'small' &&
                    <Box
                        direction="column"
                        gap='small'

                        fill
                    >
                        <Grid

                            columns={{
                                count: supply?.tipoServizio === "E" ? 5 : 4,
                                size: 'auto',
                            }}
                            align='start'
                            justify='start'
                            gap={{
                                column: "small",
                            }}
                            fill='horizontal'


                        >
                            <Text weight={'bold'}>{strings.date}</Text>
                            <Text weight={'bold'}>{strings.Tipo}</Text>
                            {supply?.tipoServizio === "E" && <Text weight={'bold'}>{strings.let1}</Text>}
                            {supply?.tipoServizio === "E" && <Text weight={'bold'}>{strings.let2}</Text>}
                            {supply?.tipoServizio === "E" && <Text weight={'bold'}>{strings.let3}</Text>}
                            {supply?.tipoServizio !== "E" && <Text weight={'bold'}>{strings.letcont}</Text>}
                            {supply?.tipoServizio === "G" && <Text weight={'bold'}>{strings.letcorr}</Text>}
                        </Grid>
                        {rows.map((it, index) => (
                            <Grid
                                columns={{
                                    count: supply?.tipoServizio === "E" ? 5 : 4,
                                    size: 'auto',
                                }}
                                align='center'
                                justify='start'
                                gap={{
                                    column: "small",
                                }}
                                fill='horizontal'
                                key={index}
                            >
                                {/* Second row */}
                                <Text color="brand" weight={'bold'} size="small">{it.date}</Text>
                                <Box
                                    pad={{ vertical: '10px', horizontal: '10px' }}
                                    round="large"
                                    background={it.typeColor}
                                    direction="column"
                                    width={{ max: '200px' }}
                                >
                                    <Text size="small">{it.type}</Text>
                                </Box>
                                {supply?.tipoServizio === "E" && <Text size="small">{it.value1 ?? ''}</Text>}
                                {supply?.tipoServizio === "E" && <Text size="small">{it.value2 ?? ''}</Text>}
                                {supply?.tipoServizio === "E" && <Text size="small">{it.value3 ?? ''}</Text>}
                                {supply?.tipoServizio !== "E" && <Text size="small">{it.value1 ?? ''}</Text>}
                                {supply?.tipoServizio === "G" && user.personaGiuFis === "G" && <Text size="small">{it.value2 ?? ''}</Text>}
                            </Grid>
                        ))}
                    </Box>
            }
            {
                size === 'small' &&
                <Box gap="small" >
                    {rows.map((it, index) => (
                        <Box
                            direction='column'
                            flex='grow'
                            round='small'
                            border={{
                                side: 'right',
                                size:'xlarge',
                                color: it.typeColor
                            }}
                            elevation="small"
                            pad='small'
                            key={index}
                        >
                            <Text color="brand" weight='bold' size="small">
                                {it.date}
                            </Text>
                            {
                                supply?.tipoServizio === "E" &&
                                <Grid

                                    columns={{
                                        count: 3,
                                        size: 'auto',
                                    }}
                                    align='start'
                                    gap={{
                                        column: "small"
                                    }}
                                >
                                    <Text size="small">{strings.let1}</Text>
                                    <Text size="small">{strings.let2}</Text>
                                    <Text size="small">{strings.let3}</Text>
                                    <Text size="small">{it.value1 ?? ''}</Text>
                                    <Text size="small">{it.value2 ?? ''}</Text>
                                    <Text size="small">{it.value3 ?? ''}</Text>
                                </Grid>
                            }
                            {
                                (supply?.tipoServizio === "G" || supply?.tipoServizio === "GPL") &&
                                    <Grid

                                        columns={{
                                            count: 2,
                                            size: 'auto',

                                        }}
                                        align='start'
                                        gap={{
                                            column: "small"
                                        }}
                                    >
                                        <Text size="small">{strings.letcont}</Text>
                                        {user.personaGiuFis === "G" && supply?.tipoServizio === "G" && <Text size="small">{strings.letcorr}</Text>}
                                        <Text size="small">{it.value1 ?? ""}</Text>
                                        {user.personaGiuFis === "G" && supply?.tipoServizio === "G" && <Text size="small">{it.value2 ?? ""}</Text>}
                                    </Grid>
                            }
                        </Box>
                    ))}
                </Box>
            }
        </Box>
    )
}

type MeterReadingHistoryBoxProps = {
    onRowClick?: (index: number) => any
    supply? : SupplyModel,
    rows: Array<{
        date: string
        type: string
        typeColor: ColorType
        value1?: string
        value2?: string
        value3?: string
    }>
} & BoxExtendedProps

export default MeterReadingHistoryBox
