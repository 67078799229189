import { IOCServices } from '..'

const deleteAllegatoReclamoAPI = async (fileId: number, container?: IOCServices) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID
    try {
        const res = await rest.delete(`/Reclami/DeleteAllegatoReclamo?idAllegato=${fileId}`)
        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default deleteAllegatoReclamoAPI
