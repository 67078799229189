import { Box, Button, Heading, Text } from 'grommet'
import { useState } from 'react'
import * as Yup from 'yup'
import useI18n from '../../hooks/useI18n'
import useRequiredFields from '../../hooks/useRequiredFields'
import useTheme from '../../hooks/useTheme'
import { paramEnum, StatiRichiesta, TipiRichiesta } from '../../models/Enums'
import { CityProvinceModel, RequestModel, RIDModel } from '../../models/models'
import { useAppSelector } from '../../services/storeProvider'
import { getBoolParam, getValidateIban } from '../../utilities'
import BFormik from '../BFormik'
import BRadioButtonGroup from '../BRadioButtonGroup'
import BSelect from '../BSelect'
import BTextInput from '../BTextInput'

const RidForm = ({
    streetTypes,
    cities,
    initialValues,
    onSubmit,
    isLoading = false,
    requests,
}: RIDFormProps) => {
    const theme = useTheme()
    const [currRequests, setRequests] = useState<Array<RequestModel>>([])
    if (requests.length > 0 && currRequests.length === 0) {
        setRequests(requests)
    }
    const strings = useI18n()
    const user = useAppSelector((state) => state.user.clientInfo)
    const main = useAppSelector((state) => state.main)

    const publicParam = main.publicParam
    const WEB_PagamentoRID_AbilitaCheckRevoca = publicParam.filter(
        (it) =>
            +it.idTipoParametroAzienda ==
            paramEnum.WEB_PagamentoRID_AbilitaCheckRevoca,
    )

    const WEB_VisibilitaCampiRidForm = getBoolParam(
        publicParam,
        paramEnum.WEB_VisibilitaCampiRidForm,
    )

    const abilitaCheckRevoca =
        WEB_PagamentoRID_AbilitaCheckRevoca[0].valore
            .split(',')
            .filter((el) => el === '1').length === 1
    const existRichiestaRidEvasa =
        currRequests.filter(
            (el) =>
                el.idTipoRichiesta === TipiRichiesta.DomiciliazioneBancaria &&
                el.idStatoRichiesta === StatiRichiesta.Evasa &&
                el.puntoGas === initialValues.codiceUtenza,
        ).length === 1
    const persGiuridica = user.personaGiuFis === 'G'
    const supportSchema = Yup.object().shape({
        nominativo: Yup.string().required(strings.mandatoryField),
        codiceUtente: Yup.string().required(strings.mandatoryField),
        codiceUtenza: Yup.string().notRequired(),
        podPdr: Yup.string().required(strings.mandatoryField),
        codiceFiscale: Yup.string().required(strings.mandatoryField),
        statoFornitura: Yup.string().notRequired(),
        iban: Yup.string()
            .required(strings.mandatoryField)
            .matches(getValidateIban(), strings.ibanNonValido),
        email: Yup.string().notRequired().email(strings.invalidEmailField),
        address: Yup.string().required(strings.mandatoryField),
        cap: Yup.string().required(strings.mandatoryField),
        number: Yup.number()
            .typeError(strings.mustBeNumber)
            .required(strings.mandatoryField),
        barrato: Yup.string().notRequired(),
        frazione: Yup.string().notRequired(),
        scala: Yup.string().notRequired(),
        piano: Yup.string().notRequired(),
        interno: Yup.string().notRequired(),
        province: Yup.string().required(strings.mandatoryField),
        city: Yup.string().required(strings.mandatoryField),
        streetType: Yup.string().required(strings.mandatoryField),

        cityIstat: Yup.string().notRequired(),
        sDD: Yup.string().notRequired(),
        codfiscRich: Yup.string().required(strings.mandatoryField),
        modalitaInvio: WEB_VisibilitaCampiRidForm
            ? Yup.string().required(strings.mandatoryField)
            : Yup.string().notRequired(),
        tipoRichiesta: Yup.string().required(strings.mandatoryField),
        partitaIvaRich: Yup.string().notRequired(),
        partitaIVA: Yup.string().notRequired(),
        nominativoLegRapp: Yup.string().test(
            'CheckMandatoryLegalRepNom',
            '',
            (value, { createError }) => {
                if (!persGiuridica) {
                    return true
                }
                if (!!value && value !== '') {
                    return true
                }
                return createError({ message: strings.mandatoryField })
            },
        ),
        codiceFiscaleLegRapp: Yup.string().test(
            'CheckMandatoryLegalRepFis',
            '',
            (value, { createError }) => {
                if (!persGiuridica) {
                    return true
                }
                if (!!value && value !== '') {
                    return true
                }
                return createError({ message: strings.mandatoryField })
            },
        ),
    })
    const isRequired = useRequiredFields(supportSchema)
    return (
        <BFormik
            initialValues={{
                nominativo: initialValues?.nominativo || '',
                codiceUtente: initialValues?.codiceUtente || '',
                codiceUtenza: initialValues?.codiceUtenza || '',
                podPdr: initialValues?.podPdr || '',
                codiceFiscale: initialValues?.codiceFiscale || '',
                statoFornitura: initialValues?.statoFornitura || '',
                iban: initialValues?.iban || '',
                email: initialValues?.email || '',
                address: initialValues?.address || '',
                cap: initialValues?.cap || '',
                number: initialValues?.number || '',
                barrato: initialValues?.barrato || '',
                frazione: initialValues?.frazione || '',
                scala: initialValues?.scala || '',
                piano: initialValues?.piano || '',
                interno: initialValues?.interno || '',
                province: initialValues?.province || '',
                city: initialValues?.city || '',
                streetType: initialValues?.streetType || '',
                cityIstat: initialValues?.cityIstat || '',
                sDD: 'CORE',
                modalitaInvio: initialValues?.modalitaInvio || '',
                codfiscRich: '',
                tipoRichiesta:
                    abilitaCheckRevoca && existRichiestaRidEvasa
                        ? 'REVOCA'
                        : 'ADESIONE',
                partitaIvaRich: '',
                nominativoLegRapp: '',
                codiceFiscaleLegRapp: '',
                partitaIVA: '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            validationSchema={supportSchema}
            onSubmit={(values) =>
                onSubmit({
                    ...values,
                    email: values.email || '',
                    modalitaInvio: values.modalitaInvio || undefined,
                })
            }
            element={({
                values,
                errors,
                validateForm,
                handleChange,
                handleSubmit,
                handleBlur,
            }) => {
                const tipiInvio = ['EMAIL', 'POSTA']
                const sendModality = tipiInvio.map((invio) => invio)

                const cityList = values.province
                    ? cities.find(
                          (it) =>
                              it.codiceProvincia.toLowerCase() ===
                                  values?.province?.toLowerCase() ?? '',
                      )?.comune ?? []
                    : []
                const city = cityList.find(
                    (it) => it.nomeComune === values.city,
                )
                const capList =
                    cityList.find((it) => it.nomeComune == values?.city ?? '')
                        ?.cap ?? ([] as Array<string>)

                return (
                    <Box gap="small">
                        <Heading
                            level={4}
                            color={'brand'}
                            margin={{ top: '0' }}
                        >
                            {strings.datiRichiesta}
                        </Heading>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            {abilitaCheckRevoca && existRichiestaRidEvasa ? (
                                <BRadioButtonGroup
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errors={{ status: false }}
                                    name="tipoRichiesta"
                                    values={values}
                                    gap="50%"
                                    mandatory
                                    label={strings.tipoRichiesta}
                                    options={[
                                        {
                                            label: strings.adesione,
                                            value: 'ADESIONE',
                                        },
                                        {
                                            label: strings.revoca,
                                            value: 'REVOCA',
                                        },
                                    ]}
                                />
                            ) : (
                                <BRadioButtonGroup
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errors={{ status: false }}
                                    name="tipoRichiesta"
                                    values={values}
                                    gap="50%"
                                    mandatory
                                    label={strings.tipoRichiesta}
                                    options={[
                                        {
                                            label: strings.adesione,
                                            value: 'ADESIONE',
                                        },
                                    ]}
                                />
                            )}
                        </Box>

                        <Heading
                            margin={{ top: 'xxsmall' }}
                            level={4}
                            color={'brand'}
                        >
                            {strings.datiRichiedente}
                        </Heading>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.codfiscRich}
                                label={strings.codfiscRich}
                                name="codfiscRich"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.partitaIVARich}
                                label={strings.partitaIVARich}
                                name="partitaIvaRich"
                            />
                        </Box>
                        {persGiuridica ? (
                            <Box
                                direction="row"
                                flex
                                gap="small"
                                align="flex-start"
                                wrap
                            >
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.nominativoLegRappForm}
                                    label={strings.nominativoLegRappForm}
                                    name="nominativoLegRapp"
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={
                                        strings.codiceFiscaleLegRappForm
                                    }
                                    label={strings.codiceFiscaleLegRappForm}
                                    name="codiceFiscaleLegRapp"
                                />
                            </Box>
                        ) : undefined}
                        <Heading
                            margin={{ top: 'xxsmall' }}
                            level={4}
                            color={'brand'}
                        >
                            {strings.DatiIntestatariodelConto}
                        </Heading>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.CognomeNomeoRagSociale}
                                label={strings.CognomeNomeoRagSociale}
                                name="nominativo"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.partitaIVA}
                                label={strings.partitaIVA}
                                name="partitaIVA"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.codiceFiscale}
                                label={strings.codiceFiscale}
                                name="codiceFiscale"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.province}
                                placeholder={strings.province}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                name="province"
                                errors={errors}
                                options={cities.map((it) => ({
                                    label: it.provincia,
                                    value: it.codiceProvincia,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.city}
                                placeholder={strings.city}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="city"
                                options={cityList.map((it: any) => ({
                                    label: it.nomeComune,
                                    value: it.nomeComune,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.cap}
                                placeholder={strings.cap}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="cap"
                                options={capList.map((it) => ({
                                    label: it,
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.frazione}
                                label={strings.frazione}
                                name="frazione"
                            />
                        </Box>

                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.streetType}
                                placeholder={strings.streetType}
                                name="streetType"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                mandatory={isRequired('streetType')}
                                values={values}
                                errors={errors}
                                options={streetTypes.map((it) => ({
                                    label: it.replace(
                                        /^(\w)(.+)/,
                                        (_, p1, p2) =>
                                            p1.toUpperCase() + p2.toLowerCase(),
                                    ),
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetName}
                                label={strings.streetName}
                                name="address"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetNumber}
                                label={strings.streetNumber}
                                name="number"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetSepatator}
                                label={strings.streetSepatator}
                                name="barrato"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.scala}
                                label={strings.scala}
                                name="scala"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.piano}
                                label={strings.piano}
                                name="piano"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.interno}
                                label={strings.interno}
                                name="interno"
                            />
                        </Box>
                        {WEB_VisibilitaCampiRidForm && (
                            <Box
                                direction="row"
                                flex
                                gap="small"
                                align="flex-start"
                                wrap
                            >
                                <BSelect
                                    searchable
                                    containerProps={{ flex: true }}
                                    label={strings.modalitaInvio}
                                    placeholder={strings.modalitaInvio}
                                    name="modalitaInvio"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    mandatory={isRequired('modalitaInvio')}
                                    values={values}
                                    errors={errors}
                                    options={sendModality.map((it) => ({
                                        label: it,
                                        value: it,
                                    }))}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />

                                <BTextInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.email}
                                    label={strings.email}
                                    name="email"
                                />
                            </Box>
                        )}
                        <Heading
                            margin={{ top: 'xxsmall' }}
                            level={4}
                            color={'brand'}
                        >
                            {strings.CoordinateBancarie}
                        </Heading>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.ibanPaese}
                                label={strings.ibanPaese}
                                name="iban"
                                mandatory={isRequired('iban')}
                            />
                        </Box>
                        <Box>
                            <Text size="small">
                                {strings.regoleIban}
                                {<br />}
                                {strings.regoleIban1}
                                {<br />}
                                {strings.regoleIban2}
                                {<br />}
                                {strings.regoleIban3}
                                {<br />}
                                {strings.regoleIban4}
                                {<br />}
                                {strings.regoleIban5}
                                {<br />}
                                {strings.regoleIban6}
                            </Text>
                        </Box>
                        <Box margin={{ top: 'small', bottom: '10px' }}>
                            <Button
                                disabled={isLoading}
                                style={{
                                    backgroundColor:
                                        theme.global?.colors?.[
                                            'primary_color'
                                        ]?.toString(),
                                    borderColor:
                                        theme.global?.colors?.[
                                            'primary_color'
                                        ]?.toString(),
                                }}
                                onClick={async () => {
                                    try {
                                        if (await validateForm()) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.save}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        />
    )
}

type RIDFormProps = {
    initialValues: RIDModel
    streetTypes: Array<string>
    cities: Array<CityProvinceModel>
    onSubmit: (values: RIDModel) => any
    isLoading: boolean
    requests: Array<RequestModel>
}

export default RidForm
