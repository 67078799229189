import { SET_READ_RECONTRACTING } from './types'

const setReadRecontracting = (values: boolean) => {
    return {
        type: SET_READ_RECONTRACTING,
        payload: values,
    }
}

export default setReadRecontracting
