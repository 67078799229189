import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'
import { isOperatorOrAgent } from '../../utilities'

const loadInvoicesAPI = async (
    codiceUtente: string,
    puntoGas: string | undefined,
    roles: Roles[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    try {
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Fattura/GetfattureUtente//${codiceUtente}${
            puntoGas ? `?PuntoGas=${puntoGas}` : ''
        }`
        if (isOperator) {
            api = `/Fattura/GetFattureUtenteAsOperatore/${codiceUtente}${
                puntoGas ? `?PuntoGas=${puntoGas}` : ''
            }`
        }
        const res = await rest.get(api)

        if (res.status === HttpStates.OK) {
            const data = res.data.value
            return data
        } else if (res.status === HttpStates.NoContent) {
            return []
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadInvoicesAPI
