import { Box, BoxExtendedProps, Text } from 'grommet'
import get from 'lodash.get'
import useTheme from '../hooks/useTheme'

const EventPlannedBox = ({ title, description, bookmarkColor = 'brand', type, ...rest }: EventPlannedBoxProps) => {
    const { global } = useTheme()

    return (
        <Box
            fill='horizontal'
            pad={{horizontal: 'xsmall'}}
            justify='center'
            background={bookmarkColor}
            {...rest}
        >
            <Text
                size="small"
                truncate='tip'
                color={get(global?.colors, 'textWhite')?.toString()}
                weight="bold"
            >
                {title}
            </Text>
        </Box>
    )
}

type EventPlannedBoxProps = {
    title: string
    description: string[]
    bookmarkColor?: string
    type?: number
} & BoxExtendedProps

export default EventPlannedBox
