import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'
import { isAdmin, isOperatorOrAgent } from '../../utilities'

const loadSuppliesAPI = async (
    codiceUtente: string,
    roles: Roles[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    try {
        const isAdministrator = isAdmin(roles)
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Fornitura/GetFornitureUtente/${codiceUtente}`
        if (isAdministrator) {
            api = `/Fornitura/getFornitureAmministratore`
        }
        if (isOperator) {
            api = `/Fornitura/GetFornitureAsOperatore/${codiceUtente}`
        }
        const res = await rest.get(api)
        if (res.status === HttpStates.OK) {
            const data = res.data.value
            return data
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadSuppliesAPI
