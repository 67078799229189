import { Box, Text } from 'grommet'
import { getStateLivRequest } from '../utilities'
import { FormCheckmark } from 'grommet-icons'
import { useMemo } from 'react'
import useI18n from '../hooks/useI18n'
import useTheme from '../hooks/useTheme'

const RequestProgressBox = ({ idStatoRichiesta }: { idStatoRichiesta: number }) => {
    const theme = useTheme()
    const strings = useI18n()
    const progress = useMemo(
        () => getStateLivRequest(idStatoRichiesta),
        [idStatoRichiesta],
    )

    return (
        <Box
            flex
            direction="row"
            justify="center"
            height={{min: '100px'}}
            margin={{ top: 'large', horizontal: 'xlarge' }}
        >
            <Box direction="column" style={{ position: 'relative' }}>
                <Box
                    align="center"
                    justify="center"
                    round="full"
                    width="70px"
                    height="70px"
                    border={
                        progress === 1
                            ? {
                                  color: theme.global?.colors?.[
                                      'lightwarn'
                                  ]?.toString(),
                                  size: 'large',
                              }
                            : {
                                  color: theme.global?.colors?.[
                                      'brand'
                                  ]?.toString(),
                                  size: 'small',
                              }
                    }
                    style={
                        progress === 1
                            ? {
                                  backgroundColor:
                                      theme.global?.colors?.[
                                          'warn'
                                      ]?.toString(),
                              }
                            : {
                                  backgroundColor:
                                      theme.global?.colors?.[
                                          'background-front'
                                      ]?.toString(),
                              }
                    }
                >
                    {progress === 1 ? (
                        <Box align="center" justify="center">
                            <Text weight="bold" size="large" color="textWhite">
                                1
                            </Text>
                        </Box>
                    ) : (
                        <FormCheckmark color="brand" size="large" />
                    )}
                </Box>
                {progress === 1 ? (
                    <Text
                        style={{
                            width: 'max-content',
                            position: 'absolute',
                            top: 70,
                            right: -15,
                        }}
                        weight="bold"
                        size="small"
                        color={theme.global?.colors?.['warn']?.toString()}
                    >
                        {strings.presaIncarico}
                    </Text>
                ) : undefined}
            </Box>
            <Box height="70px" justify="center">
                <Box
                    background="brand"
                    width="small"
                    height="2px"
                    align="center"
                />
            </Box>
            <Box direction="column" style={{ position: 'relative' }}>
                <Box
                    align="center"
                    justify="center"
                    round="full"
                    width="70px"
                    height="70px"
                    border={
                        progress === 1 || progress === 3 || progress === 4
                            ? {
                                  color: theme.global?.colors?.[
                                      'brand'
                                  ]?.toString(),
                                  size: 'small',
                              }
                            : {
                                  color: theme.global?.colors?.[
                                      'lightwarn'
                                  ]?.toString(),
                                  size: 'large',
                              }
                    }
                    style={
                        progress === 1 || progress === 3 || progress === 4
                            ? {
                                  backgroundColor:
                                      theme.global?.colors?.[
                                          'background-front'
                                      ]?.toString(),
                              }
                            : {
                                  backgroundColor:
                                      theme.global?.colors?.[
                                          'warn'
                                      ]?.toString(),
                              }
                    }
                >
                    {
                        progress === 1 ? (
                            <Box align="center" justify="center">
                                <Text weight="bold" size="large" color="brand">
                                    2
                                </Text>
                            </Box>
                        ) : progress === 2 ? (
                            <Box align="center" justify="center">
                                <Text
                                    weight="bold"
                                    size="large"
                                    color="textWhite"
                                >
                                    2
                                </Text>
                            </Box>
                        ) : (
                            <FormCheckmark color="brand" size="large" />
                        )
                        // liv===3?(<FormCheckmark color="textWhite" size='large'/>):
                        // liv===4?(<FormCheckmark color="textWhite" size='large'/>):undefined
                    }
                </Box>
                {progress === 2 ? (
                    <Text
                        style={{
                            width: 'max-content',
                            position: 'absolute',
                            top: 70,
                            right: -15,
                        }}
                        weight="bold"
                        size="small"
                        color={theme.global?.colors?.['warn']?.toString()}
                    >
                        {strings.inElaborazione}
                    </Text>
                ) : undefined}
            </Box>

            <Box height="70px" justify="center">
                <Box
                    background="brand"
                    width="small"
                    height="2px"
                    align="center"
                />
            </Box>

            <Box direction="column" style={{ position: 'relative' }}>
                <Box
                    align="center"
                    justify="center"
                    round="full"
                    width="70px"
                    height="70px"
                    style={
                        progress === 1 || progress === 2
                            ? {
                                  backgroundColor:
                                      theme.global?.colors?.[
                                          'background-front'
                                      ]?.toString(),
                              }
                            : progress === 3
                            ? {
                                  backgroundColor:
                                      theme.global?.colors?.['ok']?.toString(),
                              }
                            : {
                                  backgroundColor:
                                      theme.global?.colors?.['ko']?.toString(),
                              }
                    }
                    border={
                        progress === 1 || progress === 2
                            ? { color: 'brand', size: 'small' }
                            : progress === 3
                            ? {
                                  color: 'lightok',
                                  size: 'large',
                              }
                            : {
                                  color: 'lightko',
                                  size: 'large',
                              }
                    }
                >
                    {progress === 1 || progress === 2 ? (
                        <Box align="center" justify="center">
                            <Text weight="bold" size="large" color="brand">
                                3
                            </Text>
                        </Box>
                    ) : (
                        <FormCheckmark color="textWhite" size="large" />
                    )}
                </Box>
                {progress === 3 ? (
                    <Text
                        style={{
                            width: 'max-content',
                            position: 'absolute',
                            top: 70,
                            right: -5,
                        }}
                        weight="bold"
                        size="small"
                        color={theme.global?.colors?.['ok']?.toString()}
                    >
                        {strings.completata}
                    </Text>
                ) : progress === 4 ? (
                    <Text
                        weight="bold"
                        size="small"
                        color={theme.global?.colors?.['ko']?.toString()}
                    >
                        {strings.rifiutata}
                    </Text>
                ) : undefined}
            </Box>
        </Box>
    )
}

export default RequestProgressBox
