import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, validateYupSchema } from 'formik'
import { Box, Button, Layer, Spinner } from 'grommet'
import { CircleInformation, Key, Mail, User } from 'grommet-icons'
import BTextInput from '../BTextInput'
import useI18n from '../../hooks/useI18n'
import { useAppSelector } from '../../services/storeProvider'
import useTheme from '../../hooks/useTheme'
import InfoBox from '../InfoBox'

const RecoveryForm = (props: {
    // onSubmitUsername: (values: { codiceUtente: string }) => void
    // onSubmitPassword: (values: { codiceUtente: string }) => void
    onSubmit: (values: { codiceUtente: string, type: string }) => void
    loading: boolean
}) => {
    const strings = useI18n()
    const config = useAppSelector((state) => state.config)
    const theme = useTheme()
    const [showModal, setShowModal] = useState(false)
    const recoverySchema = Yup.object().shape({
        codiceUtente: Yup.string()
            .required(strings.mandatoryField)
    })
    return (
        <Formik
            initialValues={{ codiceUtente: '', type: ''}}
            validationSchema={recoverySchema}
            onSubmit={(value) => props.onSubmit && props.onSubmit(value)}

            >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm
            }) => {
                return (
                    <Box gap="xsmall">
                        <BTextInput
                            label={strings.codiceCliente}
                            name="codiceUtente"
                            placeholder={strings.codiceCliente}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            renderIcon={() => (
                                <Button
                                    icon={<CircleInformation size="medium" />}
                                    onClick={() => setShowModal(true)}
                                />
                            )}
                        />
                        <Box
                            gap="medium"
                            direction="column"
                            //justify="between"
                            animation="fadeIn"
                            //pad = "small"
                            margin={{ vertical: 'small' }}>
                            <Button
                                //disabled={values.codiceUtente === '' || !isValid}
                                onClick={async () => {
                                    try {
                                        values.type = 'username'
                                        if (await validateForm()) {
                                            handleSubmit()

                                        }
                                    } catch (e) {}
                                }}
                                icon={
                                    props.loading ? (
                                        <Spinner size="small" />
                                    ) : (
                                        <User color="textWhite"/>
                                    )
                                }
                                style={{color:theme.global?.colors?.['textWhite']?.toString()}}
                                label={strings.recoverUsernameButton}
                                primary
                            />
                            <Button
                                //disabled={values.codiceUtente === '' || !isValid}
                                onClick={async () => {
                                    try {
                                        values.type = 'password'
                                        if (await validateForm()) {
                                            handleSubmit()

                                        }
                                    } catch (e) {}
                                }}
                                icon={
                                    props.loading ? (
                                        <Spinner size="small" />
                                    ) : (
                                        <Key color="textWhite" />
                                    )
                                }
                                label={strings.recoverPasswordButton}
                                style={{color:theme.global?.colors?.['textWhite']?.toString()}}
                                primary
                            />
                        </Box>
                        {showModal && (
                            <Layer
                                position="center"
                                onClickOutside={() => setShowModal(false)}
                                onEsc={() => setShowModal(false)}>
                                <Box
                                    gap="small"
                                    width={{ min: 'small' }}
                                    height={{ min: 'small' }}
                                    fill>
                                    <InfoBox
                                        title={strings.codiceCliente}
                                        content={strings.hintCodiceUtente}
                                        onBackPress={() =>
                                            setShowModal(false)
                                        }
                                        onDonePress={() =>
                                            setShowModal(false)
                                        }
                                    />
                                </Box>
                            </Layer>
                        )}
                    </Box>
                )
            }}
        </Formik>
    )
}

export default RecoveryForm
