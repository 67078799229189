import { Box, Text, FileInput, Form, FormField, Spinner } from 'grommet'
import { useEffect, useState } from 'react'
import { CircleInformation } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import {
    BooleanValueInt,
    LivStatoRichiesta,
    paramEnum,
    TipiRichiesta,
} from '../models/Enums'
import { RequestDetailsModel, RequestModel } from '../models/models'
import getRIDModule from '../services/store/actions/getRIDModule'
import sendSignedModule from '../services/store/actions/sendSignedModule'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import { downloadPDF } from '../utilities'
import BillInfoBox from '../components/BillInfoBox'
import BRoundButton from '../components/BRoundButton'
import useSize from '../hooks/useSize'
import '../components/BUpload.css'
import { useNavigate } from 'react-router'
import useAppQuery from '../hooks/useAppQuery'
import loadRequests from '../services/store/actions/loadRequests'
import { useContainer } from '../services'
import {
    getDescTipoRichiesta,
    getStateLivRequest,
    stringDate,
} from '../utilities'
import RequestProgressBox from '../components/RequestProgressBox'

const RequestDetails = () => {
    const strings = useI18n()
    const size = useSize()
    const navigate = useNavigate()
    const user = useAppSelector((state) => state.user.clientInfo)
    const [isLoadingRequests, queryRequests] = useAppQuery(loadRequests)
    const [fileError, setFileError] = useState(false)
    const [getTitle, setTitle] = useState('')
    const [getDescRequest, setDescRequest] = useState('')
    const [getPod, setPod] = useState('')
    const [getUtente, setUtente] = useState('')
    const [getDate, setDate] = useState('')
    const [getReq, setReq] = useState<RequestModel>()
    const [currRequestsDetails, setRequestsDetails] = useState<
        Array<RequestDetailsModel>
    >([])

    const container = useContainer()
    const dispatch = useAppDispatch()
    const [fileValue, setFileValue] = useState({
        fileInput: [],
    })
    const main = useAppSelector((state) => state.main)
    const request = useAppSelector((state) => state.request)
    const supply = useAppSelector((state) => state.supply)
    const publicParam = main.publicParam
    const WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta,
        )[0]?.valore ?? 0

    const WEB_RichiestaRIDCambioStatoManuale =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEB_RichiestaRIDCambioStatoManuale,
        )[0]?.valore ?? 0

    const WEBSVC_LimiteDimensioniEmail =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.WEBSVC_LimiteDimensioniEmail,
        )[0]?.valore ?? 0

    const loadData = async () => {
        try {
            let requestlist: Array<RequestModel> = await queryRequests(
                user.codiceUtente,
            )
            const reqc = requestlist.find(
                (it) => it.idRichiesta === +request.selectedRequestID,
            )
            setReq(
                requestlist.find(
                    (it) => it.idRichiesta === +request.selectedRequestID,
                ),
            )
            const requestsDetails: Array<RequestDetailsModel> =
                await container.api.loadRequestDetailsAPI(
                    +request.selectedRequestID,
                )
            setRequestsDetails(requestsDetails)
            setTitle(strings.dettagliRichiesta)
            setDescRequest(getDescTipoRichiesta(reqc?.idTipoRichiesta, strings))
            setPod(
                supply.supplies.find((f) => f.puntoGas === reqc?.puntoGas)
                    ?.pod!,
            )
            setUtente(reqc?.codiceUtente!)
            setDate(stringDate(reqc?.dataRichiesta!))
        } catch (e) {}
    }

    useEffect(() => {
        loadData()
    }, [])

    const showGetRIDModule =
        getReq?.idTipoRichiesta === TipiRichiesta.DomiciliazioneBancaria &&
        WEB_PagamentoRID_AbilitaStatoIntermedioRichiesta ===
            BooleanValueInt.true &&
        WEB_RichiestaRIDCambioStatoManuale === BooleanValueInt.true &&
        getStateLivRequest(getReq?.idStatoRichiesta) !==
            LivStatoRichiesta.Completata &&
        getStateLivRequest(getReq?.idStatoRichiesta) !==
            LivStatoRichiesta.Rifiutata

    return (
        <Box flex direction={size === 'small' ? 'column' : 'row'} align="start">
            {isLoadingRequests || getReq === undefined ? (
                //return (
                <Box fill background={'background-back'}>
                    <Box height="100vh" justify="center" align="center">
                        <Spinner />
                    </Box>
                </Box>
            ) : (
                <Box
                    flex
                    //elevation="small"
                    //round="small"
                    pad="medium"
                    background={'background-front'}
                >
                    <Box direction="column">
                        <Box direction="row">
                            <Box
                                margin={{ horizontal: 'medium' }}
                                flex
                                align="center"
                                justify="center"
                                gap="large"
                            >
                                <Text size="large" weight={'bold'}>
                                    {getTitle}
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            justify="between"
                            direction="row"
                            margin={{ top: 'medium' }}
                        >
                            <Box
                                justify="center"
                                direction="column"
                                gap="small"
                            >
                                <Text weight="bold" color="brand" size="medium">
                                    {getDescRequest}
                                </Text>
                                <Text weight="bold" size="small">
                                    {getPod
                                        ? `${strings.fornitura} ${getPod}`
                                        : `${strings.codiceUtente} ${getUtente}`}
                                </Text>
                            </Box>
                            <Box direction="column">
                                <Text weight="bold" size="medium">
                                    {strings.dataRichiesta}
                                </Text>
                                <Text
                                    weight="bold"
                                    size="small"
                                    margin={{ top: '12px' }}
                                >
                                    {getDate}
                                </Text>
                            </Box>
                        </Box>
                        <RequestProgressBox
                            idStatoRichiesta={getReq.idStatoRichiesta}
                        />
                        <Box margin={{ top: 'large' }}>
                            <Text weight="bold" size="medium">
                                {strings.dettagliRichiesta}
                            </Text>
                        </Box>
                        <Box
                            flex
                            direction="row"
                            width={{ min: 'small' }}
                            wrap
                            pad={{ horizontal: 'medium' }}
                        >
                            {currRequestsDetails
                                ?.filter(
                                    (ii) =>
                                        ii.valore !== '' &&
                                        ii.descrizione !==
                                            strings.elencoRichiesteAssociate &&
                                        ii.descrizione !==
                                            strings.elencoIndirizziEmaildaConfermare &&
                                        ii.descrizione !== strings.SiglaIBAN &&
                                        ii.descrizione !== strings.CodiceIBAN &&
                                        ii.descrizione !== strings.BBANCIN &&
                                        ii.descrizione !== strings.BBANABI &&
                                        ii.descrizione !== strings.BBANCAB &&
                                        ii.descrizione !== strings.BBANNumCC &&
                                        ii.descrizione !==
                                            strings.MODALITAINVIOMODULORID &&
                                        ii.descrizione !==
                                            strings.TipologiaSDD &&
                                        ii.descrizione !==
                                            strings.ComuneRichiedente &&
                                        ii.descrizione !==
                                            strings.CAPRichiedente &&
                                        ii.descrizione !==
                                            strings.CivicoRichiedente &&
                                        ii.descrizione !== strings.TipoVia &&
                                        ii.descrizione !==
                                            strings.ProvinciaRichiedente &&
                                        ii.descrizione !==
                                            strings.TipologiaUtenteRichiesta &&
                                        ii.descrizione !==
                                            strings.CodiceFiscaleRichiedente &&
                                        ii.descrizione !== strings.CodComune &&
                                        ii.descrizione !== strings.streetName &&
                                        ii.descrizione !==
                                            strings.numerocivico &&
                                        ii.descrizione !==
                                            strings.nominativoRichiedente &&
                                        ii.descrizione !== strings.province &&
                                        ii.descrizione !== strings.comune &&
                                        ii.descrizione !== strings.cap,
                                    //  ii.descrizione !== strings.AnagLegRapNomCogn&&
                                    //  ii.descrizione !== strings.AnagLegRapCF
                                )
                                .map((rdf, idx) => (
                                    <BillInfoBox
                                        key={idx}
                                        margin={{ vertical: 'small' }}
                                        width={{ min: '50%' }}
                                        flex={{ grow: 0.5 }}
                                        title={
                                            rdf.descrizione ===
                                            strings.AnagLegRapNomCogn
                                                ? strings.AnagLegRapNomCogn
                                                : rdf.descrizione ===
                                                  strings.AnagLegRapCF
                                                ? strings.AnagLegRapCFDesc
                                                : rdf.descrizione
                                        }
                                        value={
                                            rdf.valore === 'ADESIONESOLOMAIL'
                                                ? 'Adesione'
                                                : rdf.valore ===
                                                  'MODIFICASOLOMAIL'
                                                ? 'Modifica'
                                                : rdf.valore
                                        }
                                    />
                                ))}
                        </Box>

                        {showGetRIDModule && (
                            <Box direction="column" height="100%">
                                <Box margin={{ top: 'large' }}>
                                    <Text weight="bold" size="medium">
                                        {strings.CaricaDocumentoFirmato}
                                    </Text>
                                </Box>
                                <Box
                                    width="100%"
                                    direction="row"
                                    margin={{ top: 'small' }}
                                >
                                    <Box direction="row" flex>
                                        <Box
                                            round="full"
                                            align="center"
                                            justify="center"
                                            background="brand"
                                            style={{ height: 'max-content' }}
                                            //pad="xxxsmall"
                                            margin={{ right: '1%' }}
                                        >
                                            <CircleInformation width="small" />
                                        </Box>
                                        <Text>{strings.step1}</Text>
                                    </Box>

                                    <Box width="10vw">
                                        <BRoundButton
                                            style={{ padding: '2%' }}
                                            size="small"
                                            label={strings.ScaricaMod}
                                            onClick={async () => {
                                                try {
                                                    const data = await dispatch(
                                                        getRIDModule({
                                                            idRichiesta:
                                                                getReq.idRichiesta,
                                                            codiceUtente:
                                                                getReq.codiceUtente,
                                                        }),
                                                    )
                                                    downloadPDF(
                                                        data.value,
                                                        'DocumentoRID',
                                                    )
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box
                                    width="100%"
                                    direction="row"
                                    margin={{ top: 'small' }}
                                >
                                    <Box direction="row" flex>
                                        <Box
                                            round="full"
                                            align="center"
                                            justify="center"
                                            style={{ height: 'max-content' }}
                                            background="brand"
                                            //pad="xxxsmall"
                                            margin={{ right: '1%' }}
                                        >
                                            <CircleInformation width="small" />
                                        </Box>
                                        <Text>{strings.step2}</Text>
                                    </Box>

                                    <Box width="20vw">
                                        <Form
                                            validate="change"
                                            value={fileValue}
                                            onChange={setFileValue}
                                        >
                                            <Box width="medium">
                                                <FormField
                                                    label=""
                                                    htmlFor="fileInput"
                                                    name="fileInput"
                                                    className={
                                                        fileError === true
                                                            ? 'error'
                                                            : 'normal'
                                                    }
                                                >
                                                    <FileInput
                                                        id="fileInput"
                                                        name="fileInput"
                                                        //maxSize={+WEBSVC_LimiteDimensioniEmail}
                                                        maxSize={
                                                            +WEBSVC_LimiteDimensioniEmail
                                                        }
                                                        messages={{
                                                            dropPrompt:
                                                                'Trascina qui il documento',
                                                            // when there is already a file, the label should read
                                                            // "Replace File"
                                                            browse: 'Carica',
                                                        }}
                                                    />
                                                </FormField>
                                            </Box>
                                        </Form>
                                    </Box>
                                </Box>

                                <Box
                                    width="100%"
                                    direction="row"
                                    margin={{ top: 'small' }}
                                >
                                    <Box direction="row" flex>
                                        <Box
                                            round="full"
                                            align="center"
                                            justify="center"
                                            style={{ height: 'max-content' }}
                                            background="brand"
                                            //pad="xxxsmall"
                                            margin={{ right: '1%' }}
                                        >
                                            <CircleInformation width="small" />
                                        </Box>
                                        <Text>{strings.step3}</Text>
                                    </Box>

                                    <Box width="10vw">
                                        <BRoundButton
                                            style={{ padding: '2%' }}
                                            size="small"
                                            label={strings.InviaDoc}
                                            onClick={async () => {
                                                try {
                                                    const module = {
                                                        idRichiesta: String(
                                                            getReq?.idRichiesta,
                                                        ),
                                                        file: fileValue
                                                            .fileInput[0],
                                                        limit: WEBSVC_LimiteDimensioniEmail,
                                                    }
                                                    if (
                                                        module.file ===
                                                        undefined
                                                    ) {
                                                        setFileError(true)
                                                    } else {
                                                        setFileError(false)
                                                    }

                                                    await dispatch(
                                                        sendSignedModule(
                                                            module,
                                                        ),
                                                    )

                                                    navigate('/requests')
                                                } catch (e) {}
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default RequestDetails
