import { IOCServices } from '..'

const logoutAPI = async (container?: IOCServices) => {
    try {
        if (!container) {
            return Promise.reject(
                'Container is null. API must be called from api service',
            )
        }
        
        return true
    } catch (e: any) {
        return Promise.reject(e.message)
    }
}

export default logoutAPI
