import { format, parse } from 'date-fns'
import { it } from 'date-fns/locale'
import { ThemeType } from 'grommet'
import {
    ContactInfo,
    Dashboard,
    Deploy,
    Directions,
    FormCheckmark,
    FormClose,
    Globe,
    HelpOption,
    Home,
    Optimize,
    Organization,
    Secure,
    Services,
    StatusCritical,
    StatusGood,
    StatusWarning,
    Support,
    User,
} from 'grommet-icons'
import { Colors } from 'grommet/themes/base'
import {
    BE_TipiFatturazione,
    LivStatoRichiesta,
    Roles,
    StatiFornitura,
    StatiPagamento,
    StatiRichiesta,
    TipiFatturazione,
    TipiRichiesta,
    TipoServizio,
} from './models/Enums'
import { ParamModel, StringModel } from './models/models'
import assets from './services/customerSpecs/assets/defaultAssets'
import { AppStringsType } from './services/stringProvider'
import get from 'lodash.get'
import companyMapping from './services/customerSpecs/companyMapping'

export const appFormikBlur =
    <P extends object>(callBack?: EventCallback<P>) =>
    (
        values: P,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
        setFieldError?: (field: string, message: string | undefined) => void,
    ) =>
    (name: string | any) =>
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
        const value = event.target.value
        setFieldValue(name, value)
        callBack?.({
            values: { ...values, [`${name}`]: value },
            event,
            name,
            setFieldError,
            setFieldValue,
        })
    }

export const appFormikChange =
    <P extends object>(callBack?: EventCallback<P>) =>
    (
        values: P,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
        setFieldError?: (field: string, message: string | undefined) => void,
    ) =>
    (name: string | React.ChangeEvent<any>) =>
    (event: string | React.ChangeEvent<any>) => {
        if (typeof name !== 'string' && typeof event === 'string') {
            return
        }
        const value = (event as React.ChangeEvent<any>)?.target?.value
        setFieldValue(name as string, value)
        callBack?.({
            values: { ...values, [`${name}`]: value },
            setFieldValue,
            setFieldError,
            name: name as string,
            event: event as React.ChangeEvent<any>,
        })
    }

export type EventCallback<P> = (p: {
    values: P
    name?: string
    event?: React.FocusEvent<HTMLInputElement, Element> | React.ChangeEvent<any>
    setFieldValue?: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void
    setFieldError?: (field: string, message: string | undefined) => void
}) => any

export const formatDate = (date: Date) => {
    if (!date.getDate()) {
        return ''
    }
    const newDate = new Date(date)
    const bday = +newDate.getDate() < 10
    const bmonth = +newDate.getMonth() + 1 < 10
    const ret = `${bday ? '0' : ''}${newDate.getDate()}/${bmonth ? '0' : ''}${
        newDate.getMonth() + 1
    }/${newDate.getFullYear()}`

    return ret
}

export const formatDateFns = (date: Date, mode: string = 'yyyy-MM-dd') =>
    format(date, mode, { locale: it })

export const formatTimeFns = (date: Date, mode: string = 'HH:mm') =>
    format(date, mode, { locale: it })

export const stringToDate = (date: string, mode: string = 'dd/MM/yyyy HH:mm') =>
    parse(date, mode, new Date())

export const formatDateString = (
    date?: string,
    parseAs: string = 'dd/MM/yyyy HH:mm',
) => {
    if (!date) {
        return ''
    }
    const newDate = stringToDate(date, parseAs)
    return formatDateFns(newDate, 'dd/MM/yyyy')
}

export const getDescTipoServizio = (id: number, strings: AppStringsType) => {
    if (id === +TipoServizio.Gas) {
        return `${strings.AperturaRange} ${strings.G}`
    } else if (id === +TipoServizio.Gpl) {
        return `${strings.AperturaRange} ${strings.GPL}`
    } else return `${strings.AperturaRange} ${strings.E}`
}

export const getAutoletturaTipoServizio = (
    id: number,
    strings: AppStringsType,
) => {
    if (id === +TipoServizio.Gas) {
        return `${strings.autolettura} ${strings.G}`
    } else if (id === +TipoServizio.Gpl) {
        return `${strings.autolettura} ${strings.GPL}`
    } else return `${strings.autolettura} ${strings.E}`
}

export const getDescTipoServizioPlan = (
    id: number,
    strings: AppStringsType,
) => {
    if (id === +TipoServizio.Gas) {
        return `${strings.Apertura} ${strings.G}`
    } else if (id === +TipoServizio.Gpl) {
        return `${strings.AperturaRange} ${strings.GPL}`
    } else return `${strings.Apertura} ${strings.E}`
}

export const stringDate = (date: string) => {
    if (date) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const bday = +newDate.getDate() < 10
        const bmonth = +newDate.getMonth() + 1 < 10
        const ret = `${bday ? '0' : ''}${newDate.getDate()}/${
            bmonth ? '0' : ''
        }${newDate.getMonth() + 1}/${newDate.getFullYear()}`

        return ret
    } else {
        return ''
    }
}

export const reverseStringDate = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const bday = +newDate.getDate() < 10
        const bmonth = +newDate.getMonth() + 1 < 10
        //const ret = `${bday?'0':''}${newDate.getDate()}/${bmonth?'0':''}${newDate.getMonth() + 1}/${newDate.getFullYear()}`
        const ret = `${newDate.getFullYear()}-${bmonth ? '0' : ''}${
            newDate.getMonth() + 1
        }-${bday ? '0' : ''}${newDate.getDate()}`
        return ret
    } else {
        return ''
    }
}

export const dayStringDate = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const bday = +newDate.getDate() < 10
        if (bday) return '0'.concat(String(+newDate.getDate()))
        return String(+newDate.getDate())
    } else {
        return ''
    }
}

export const dayStringDateMonth1 = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        newDate.setMonth(newDate.getMonth() + 1)
        const bday = +newDate.getDate() < 10
        if (bday) return '0'.concat(String(+newDate.getDate()))
        return String(+newDate.getDate())
    } else {
        return ''
    }
}

export const monthStringDate = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const bmonth = +newDate.getMonth() + 1 < 10

        if (bmonth) return '0'.concat(String(+newDate.getMonth() + 1))
        return String(+newDate.getMonth() + 1)
    } else {
        return ''
    }
}

export const addDays = (startDate: Date, days: number) => {
    const newDate = new Date(startDate)
    newDate.setDate(startDate.getDate() + days)
    return newDate
}

export const rmDays = (startDate: Date, days: number) => {
    const newDate = new Date(startDate)
    newDate.setDate(startDate.getDate() - days)
    return newDate
}

export const getDaysInMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

export const addMonths = (startDate: Date, months: number) => {
    return new Date(startDate.setMonth(startDate.getMonth() + months))
}

export const rmMonths = (startDate: Date, months: number) => {
    return new Date(startDate.setMonth(startDate.getMonth() - months))
}

export const monthStringDateMonth1 = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        newDate.setMonth(newDate.getMonth() + 1)

        const bmonth = +newDate.getMonth() + 1 < 10

        if (bmonth) return '0'.concat(String(+newDate.getMonth() + 1))
        return String(+newDate.getMonth() + 1)
    } else {
        return ''
    }
}

export const yearStringDate = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const byear = newDate.getFullYear()

        return String(byear)
    } else {
        return ''
    }
}

export const yearStringDateMonth1 = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        newDate.setMonth(newDate.getMonth() + 1)

        const byear = newDate.getFullYear()

        return String(byear)
    } else {
        return ''
    }
}

export const monthStringDateName = (
    date: string,
    strings: AppStringsType,
    extended: boolean,
) => {
    if (date.length <= 0) {
        return
    }
    const newDate = new Date(date)
    if (!newDate.getDate()) {
        return ''
    }

    switch (+newDate.getMonth() + 1) {
        case 1:
            return extended ? strings.gennaio : strings.gen
        case 2:
            return extended ? strings.febbraio : strings.feb
        case 3:
            return extended ? strings.marzo : strings.mar
        case 4:
            return extended ? strings.aprile : strings.apr
        case 5:
            return extended ? strings.maggio : strings.mag
        case 6:
            return extended ? strings.giugno : strings.giu
        case 7:
            return extended ? strings.luglio : strings.lug
        case 8:
            return extended ? strings.agosto : strings.ago
        case 9:
            return extended ? strings.settembre : strings.set
        case 10:
            return extended ? strings.ottobre : strings.ott
        case 11:
            return extended ? strings.novembre : strings.nov
        case 12:
            return extended ? strings.dicembre : strings.dic
        default:
            return ''
    }
}

export const monthDateName = (
    month: number,
    strings: AppStringsType,
    extended: boolean,
) => {
    switch (month + 1) {
        case 1:
            return extended ? strings.gennaio : strings.gen
        case 2:
            return extended ? strings.febbraio : strings.feb
        case 3:
            return extended ? strings.marzo : strings.mar
        case 4:
            return extended ? strings.aprile : strings.apr
        case 5:
            return extended ? strings.maggio : strings.mag
        case 6:
            return extended ? strings.giugno : strings.giu
        case 7:
            return extended ? strings.luglio : strings.lug
        case 8:
            return extended ? strings.agosto : strings.ago
        case 9:
            return extended ? strings.settembre : strings.set
        case 10:
            return extended ? strings.ottobre : strings.ott
        case 11:
            return extended ? strings.novembre : strings.nov
        case 12:
            return extended ? strings.dicembre : strings.dic
        default:
            return ''
    }
}

export const monthStringDateNoAdd = (date: string) => {
    if (date.length > 0) {
        const newDate = new Date(date)
        if (!newDate.getDate()) {
            return ''
        }
        const bmonth = +newDate.getMonth() < 10

        if (bmonth) return '0'.concat(String(+newDate.getMonth()))
        return String(+newDate.getMonth())
    } else {
        return ''
    }
}

export const lastDayofMonthYear = (month: number, year: number) => {
    const d = new Date(year, month, 0)
    return d.getDate()
}

export const getInitialDateRange = (future = false, gap = 10) => {
    const today = new Date()
    const yearGap = future
        ? today.getFullYear() + gap
        : today.getFullYear() - gap
    const otherDate = new Date()
    otherDate.setFullYear(yearGap)
    return [today, otherDate]
}

export const getValidStringValue = (val?: string) => {
    if (val === undefined || val === '') return '0'
    return val
}

export const compareDateStrings = (date1: string, date2: string) => {
    const d1 = new Date(date1)
    const d2 = new Date(date2)

    if (d1 > d2) {
        return 1
    }

    if (d1 < d2) {
        return -1
    }

    return 0
}

export const getDescTipoFattura = (id: number, strings: AppStringsType) => {
    switch (id) {
        case TipiFatturazione.Sintetica:
            return strings.sintetica
        case TipiFatturazione.Dettagliata:
            return strings.dettagliata
        default:
            return ''
    }
}

export const getDescTipoFatturaBE = (id: number, strings: AppStringsType) => {
    switch (id) {
        case BE_TipiFatturazione.Sintetica:
            return strings.sintetica
        case BE_TipiFatturazione.Dettagliata:
            return strings.dettagliata
        default:
            return ''
    }
}

export const date_diff_indays = function (date1: Date, date2: Date) {
    return Math.floor(
        Math.ceil(date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24),
    )
}

export const getCancellationReason = [
    'CAMBIO RESIDENZA',
    'CAMBIO VENDITORE',
    'ALTRO',
]

export const getRequestCancellation = ['SI', 'NO']

export const getBrandColor = (hover: boolean, theme: ThemeType) => {
    return hover
        ? theme.global?.colors?.['brand']?.toString()
        : theme.global?.colors?.['textWhite']?.toString()
}

export function downloadPDF(pdf: any, filename: string) {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = `${filename}.pdf`

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
}

export const getStringFromCode = (
    code: string,
    APIStrings: Array<StringModel>,
    LocalStrings: AppStringsType,
    language: string,
) => {
    for (const s of APIStrings) {
        if (s.codice === code) {
            return language === 'it'
                ? s.valorePredefinitoIT
                : s.valorePredefinitoEng
        }
    }

    return (LocalStrings as any)[`${code}`] || code
}

export const getDescStatoPagamento = (id: number, strings: AppStringsType) => {
    switch (id) {
        case StatiPagamento.Pagata:
            return strings.pagata
        case StatiPagamento.DaPagare:
            return strings.daPagare
        case StatiPagamento.PagataParzialmente:
            return strings.pagataParzialmente
        case StatiPagamento.Rateizzata:
            return strings.rateizzata
        default:
            return ''
    }
}

export const getDescStatoFornitura = (id: number, strings: AppStringsType) => {
    switch (id) {
        case StatiFornitura.Attiva:
            return strings.Attiva
        case StatiFornitura.Cessata:
            return strings.Cessata
        case StatiFornitura.Errata:
            return strings.Errata
        case StatiFornitura.InCessazione:
            return strings.InCessazione
        case StatiFornitura.NonDefinita:
            return strings.NonDefinita
        case StatiFornitura.NonFatturabile:
            return strings.NonFatturabile
        case StatiFornitura.RichiestaDisdetta:
            return strings.RichiestaDisdetta
        case StatiFornitura.Sospesa:
            return strings.Sospesa
        default:
            return strings.NonDefinita
    }
}

export const getColorStatoPagamento = (id: number) => {
    switch (id) {
        case StatiPagamento.Pagata:
            return 'ok'
        case StatiPagamento.DaPagare:
            return 'ko'
        case StatiPagamento.PagataParzialmente:
            return 'warn'
        case StatiPagamento.Rateizzata:
            return 'warn'
        default:
            return ''
    }
}

export const getIconStatoPagamento = (id: number, color: string) => {
    switch (id) {
        case StatiPagamento.Pagata:
            return <StatusGood color={color} />
        case StatiPagamento.DaPagare:
            return <StatusCritical color={color} />
        case StatiPagamento.PagataParzialmente:
            return <StatusWarning color={color} />
        case StatiPagamento.Rateizzata:
            return <StatusWarning color={color} />
        default:
            return ''
    }
}

export const getLightColorStatoRichiesta = (id: number) => {
    if (getStatoRichiestaOK(id)) return 'lightok'
    else if (getStatoRichiestaKO(id)) return 'lightko'
    else if (getStatoRichiestaWARN(id)) return 'lightwarn'
    else return 'lightwarn'
}

export const getColorStatoRichiesta = (id: number) => {
    if (getStatoRichiestaOK(id)) return 'ok'
    else if (getStatoRichiestaKO(id)) return 'ko'
    else if (getStatoRichiestaWARN(id)) return 'warn'
    else return 'warn'
}

export const getValidateIban = () => {
    return /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/
}

export const getDescStatoRichiesta = (
    id: number | undefined,
    strings: AppStringsType,
) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return strings.completata
        case StatiRichiesta.Completata:
            return strings.completata
        case StatiRichiesta.ValidataBO:
            return strings.completata
        case StatiRichiesta.Annullata:
            return strings.rifiutata
        case StatiRichiesta.Cancellata:
            return strings.rifiutata
        case StatiRichiesta.Rifiutata:
            return strings.rifiutata
        case StatiRichiesta.Nuova:
            return strings.inElaborazione
        case StatiRichiesta.Bozza:
            return strings.presaIncarico
        case StatiRichiesta.InLavorazione:
            return strings.presaIncarico
        case StatiRichiesta.InEvasione:
            return strings.presaIncarico
        case StatiRichiesta.NuovaDaValidare:
            return strings.presaIncarico
        case StatiRichiesta.Confermata:
            return strings.completata
        case StatiRichiesta.InCompilazione:
            return strings.presaIncarico
        case StatiRichiesta.RigettataBO:
            return strings.rifiutata
        case StatiRichiesta.Assegnata:
            return strings.presaIncarico
        case StatiRichiesta.InAttesaDiModulo:
            return strings.presaIncarico
        case StatiRichiesta.ModuloNonCompleto:
            return strings.rifiutata
        case StatiRichiesta.ModuloOk:
            return strings.inElaborazione
        case StatiRichiesta.Ricevuta:
            return strings.inElaborazione
        case StatiRichiesta.Avviata:
            return strings.presaIncarico
        case StatiRichiesta.Sospesa:
            return strings.inElaborazione
        case StatiRichiesta.Inviata:
            return strings.inElaborazione
        case StatiRichiesta.Inammissibile:
            return strings.rifiutata
        case StatiRichiesta.Ammissibile:
            return strings.inElaborazione
        case StatiRichiesta.EsitoNegativo:
            return strings.rifiutata
        case StatiRichiesta.InammissibileNonAnnullata:
            return strings.rifiutata
        case StatiRichiesta.RichiestaAnnullatamentoSNC:
            return strings.rifiutata
        case StatiRichiesta.AnnullataSNC:
            return strings.rifiutata
        case StatiRichiesta.AnnullataDS:
            return strings.rifiutata
        default:
            return strings.inElaborazione
    }
}
export const getDescStatoRichiestaOperator = (
    id: number | string | Date | undefined,
    strings: AppStringsType,
) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return strings.evasa
        case StatiRichiesta.Completata:
            return strings.completata
        case StatiRichiesta.ValidataBO:
            return strings.validataBo
        case StatiRichiesta.Annullata:
            return strings.annullata
        case StatiRichiesta.Cancellata:
            return strings.cancellata
        case StatiRichiesta.Rifiutata:
            return strings.rifiutata
        case StatiRichiesta.Nuova:
            return strings.nuova
        case StatiRichiesta.Bozza:
            return strings.bozza
        case StatiRichiesta.InLavorazione:
            return strings.inLavorazione
        case StatiRichiesta.InEvasione:
            return strings.inEvasione
        case StatiRichiesta.NuovaDaValidare:
            return strings.nuovaDaValidare
        case StatiRichiesta.Confermata:
            return strings.confermata
        case StatiRichiesta.InCompilazione:
            return strings.inCompilazione
        case StatiRichiesta.RigettataBO:
            return strings.rigettataBo
        case StatiRichiesta.Assegnata:
            return strings.assegnata
        case StatiRichiesta.InAttesaDiModulo:
            return strings.inAttesaDiModulo
        case StatiRichiesta.ModuloNonCompleto:
            return strings.moduloNonCompletato
        case StatiRichiesta.ModuloOk:
            return strings.moduloOK
        case StatiRichiesta.Ricevuta:
            return strings.ricevuta
        case StatiRichiesta.Avviata:
            return strings.avviata
        case StatiRichiesta.Sospesa:
            return strings.Sospesa
        case StatiRichiesta.Inviata:
            return strings.inviata
        case StatiRichiesta.Inammissibile:
            return strings.inammissibile
        case StatiRichiesta.Ammissibile:
            return strings.ammissibile
        case StatiRichiesta.EsitoNegativo:
            return strings.esitoNegativo
        case StatiRichiesta.InammissibileNonAnnullata:
            return strings.inammissibileNonAnnullata
        case StatiRichiesta.RichiestaAnnullatamentoSNC:
            return strings.richiestaAnnullatamento
        case StatiRichiesta.AnnullataSNC:
            return strings.anullataSNC
        case StatiRichiesta.AnnullataDS:
            return strings.annullataDS
        case StatiRichiesta.Chiusa:
            return strings.chiusa
        default:
            return strings.inElaborazione
    }
}

export const getStatoRichiestaPresaInCarico = (id: number | undefined) => {
    switch (id) {
        case StatiRichiesta.Bozza:
            return true
        case StatiRichiesta.InLavorazione:
            return true
        case StatiRichiesta.InEvasione:
            return true
        case StatiRichiesta.NuovaDaValidare:
            return true
        case StatiRichiesta.InCompilazione:
            return true
        case StatiRichiesta.Assegnata:
            return true
        case StatiRichiesta.InAttesaDiModulo:
            return true
        case StatiRichiesta.Avviata:
            return true
        default:
            return false
    }
}

export const getStateLivRequest = (id: number | undefined) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return LivStatoRichiesta.Completata
        case StatiRichiesta.Completata:
            return LivStatoRichiesta.Completata
        case StatiRichiesta.ValidataBO:
            return LivStatoRichiesta.Completata
        case StatiRichiesta.Annullata:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Cancellata:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Rifiutata:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Nuova:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.Bozza:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.InLavorazione:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.InEvasione:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.NuovaDaValidare:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.Confermata:
            return LivStatoRichiesta.Completata
        case StatiRichiesta.InCompilazione:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.RigettataBO:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Assegnata:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.InAttesaDiModulo:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.ModuloNonCompleto:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.ModuloOk:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.Ricevuta:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.Avviata:
            return LivStatoRichiesta.PresaInCarico
        case StatiRichiesta.Sospesa:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.Inviata:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.Inammissibile:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Ammissibile:
            return LivStatoRichiesta.InElaborazione
        case StatiRichiesta.EsitoNegativo:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.InammissibileNonAnnullata:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.RichiestaAnnullatamentoSNC:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.AnnullataSNC:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.AnnullataDS:
            return LivStatoRichiesta.Rifiutata
        case StatiRichiesta.Chiusa:
            return LivStatoRichiesta.Completata
        default:
            return LivStatoRichiesta.PresaInCarico
    }
}

export const getStatoRichiestaOK = (id: number) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return true
        case StatiRichiesta.Completata:
            return true
        case StatiRichiesta.ValidataBO:
            return true
        case StatiRichiesta.Annullata:
            return false
        case StatiRichiesta.Cancellata:
            return false
        case StatiRichiesta.Rifiutata:
            return false
        case StatiRichiesta.Chiusa:
            return true
        default:
            return false
    }
}

export const getStatoRichiestaKO = (id: number) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return false
        case StatiRichiesta.Completata:
            return false
        case StatiRichiesta.ValidataBO:
            return false
        case StatiRichiesta.Annullata:
            return true
        case StatiRichiesta.Cancellata:
            return true
        case StatiRichiesta.Rifiutata:
            return true
        default:
            return false
    }
}

export const getStatoRichiestaWARN = (id: number) => {
    switch (id) {
        case StatiRichiesta.Evasa:
            return false
        case StatiRichiesta.Completata:
            return false
        case StatiRichiesta.ValidataBO:
            return false
        case StatiRichiesta.Annullata:
            return false
        case StatiRichiesta.Cancellata:
            return false
        case StatiRichiesta.Rifiutata:
            return false
        default:
            return true
    }
}

export const getDescTipoFatt = (id: number, strings: AppStringsType) => {
    switch (id) {
        case TipiFatturazione.Sintetica:
            return strings.sintetica
        case TipiFatturazione.Dettagliata:
            return strings.dettagliata
        default:
            return ''
    }
}

export const getNavLinkTipoRichiesta = (
    id: number,
    roles: Roles[] | undefined,
) => {
    const admin = isAdmin(roles)
    switch (id) {
        case TipiRichiesta.RegistrazioneUtente:
            return undefined

        case TipiRichiesta.CambioRecapito:
            return '/cambioRecapito'

        case TipiRichiesta.DomiciliazioneBancaria:
            return '/RIDPayment'

        case TipiRichiesta.AgevolImposte:
            return undefined

        case TipiRichiesta.DisdettaContratto:
            return '/cancellation'

        case TipiRichiesta.Rettifica:
            return undefined

        case TipiRichiesta.PagamentoBolletta:
            return undefined

        case TipiRichiesta.Informazioni:
            return undefined

        case TipiRichiesta.Reclami:
            return '/support'

        case TipiRichiesta.Autolettura:
            return '/selfReading'

        case TipiRichiesta.Preventivo:
            return undefined

        case TipiRichiesta.ModificaDatiAnagrafici:
            return admin ? '/changeUserData' : '/user'

        case TipiRichiesta.DatiCatastali:
            return undefined

        case TipiRichiesta.ContattoGenerico:
            return undefined

        case TipiRichiesta.FatturaOnline:
            return '/billByEmail'

        case TipiRichiesta.ContattoCommerciale:
            return undefined

        case TipiRichiesta.CambioBollettazione:
            return undefined

        case TipiRichiesta.InformazioniAltriUsi:
            return undefined

        case TipiRichiesta.InformazioniGrandiClienti:
            return undefined

        case TipiRichiesta.Repricing:
            return undefined

        case TipiRichiesta.Voltura:
            return undefined

        case TipiRichiesta.ResidenzaSuUtenza:
            return undefined

        case TipiRichiesta.ModificaPotenza:
            return undefined

        case TipiRichiesta.GestionePrivacy:
            return '/user'

        case TipiRichiesta.RichiediPINUtente:
            return undefined

        case TipiRichiesta.RichiediPINNoMail:
            return undefined

        case TipiRichiesta.ConfermaLinkReg:
            return undefined

        case TipiRichiesta.PagamentoRicorrente:
            return undefined

        case TipiRichiesta.PagamentoRicorrenteModificaEmail:
            return undefined

        case TipiRichiesta.AutoletturaGPL:
            return '~'

        case TipiRichiesta.AssistenzaEReclami:
            return '/claims'

        default:
            return undefined
    }
}

export const getDescTipoRichiesta = (
    id: number | undefined,
    strings: AppStringsType,
) => {
    switch (id) {
        case TipiRichiesta.RegistrazioneUtente:
            return strings.RegistrazioneUtente

        case TipiRichiesta.CambioRecapito:
            return strings.CambioRecapito

        case TipiRichiesta.DomiciliazioneBancaria:
            return strings.DomiciliazioneBancaria

        case TipiRichiesta.AgevolImposte:
            return strings.AgevolImposte

        case TipiRichiesta.DisdettaContratto:
            return strings.DisdettaContratto

        case TipiRichiesta.Rettifica:
            return strings.Rettifica

        case TipiRichiesta.PagamentoBolletta:
            return strings.PagamentoBolletta

        case TipiRichiesta.Informazioni:
            return strings.Informazioni

        case TipiRichiesta.Reclami:
            return strings.richiediAssistenza

        case TipiRichiesta.Autolettura:
            return strings.Autolettura

        case TipiRichiesta.Preventivo:
            return strings.Preventivo

        case TipiRichiesta.ModificaDatiAnagrafici:
            return strings.ModificaDatiAnagrafici

        case TipiRichiesta.DatiCatastali:
            return strings.DatiCatastali

        case TipiRichiesta.ContattoGenerico:
            return strings.ContattoGenerico

        case TipiRichiesta.FatturaOnline:
            return strings.FatturaOnline

        case TipiRichiesta.ContattoCommerciale:
            return strings.ContattoCommerciale

        case TipiRichiesta.CambioBollettazione:
            return strings.CambioBollettazione

        case TipiRichiesta.InformazioniAltriUsi:
            return strings.InformazioniAltriUsi

        case TipiRichiesta.InformazioniGrandiClienti:
            return strings.InformazioniGrandiClienti

        case TipiRichiesta.Repricing:
            return strings.Repricing

        case TipiRichiesta.Voltura:
            return strings.Voltura

        case TipiRichiesta.ResidenzaSuUtenza:
            return strings.ResidenzaSuUtenza

        case TipiRichiesta.ModificaPotenza:
            return strings.ModificaPotenza

        case TipiRichiesta.GestionePrivacy:
            return strings.GestionePrivacy

        case TipiRichiesta.RichiediPINUtente:
            return strings.RichiediPINUtente

        case TipiRichiesta.RichiediPINNoMail:
            return strings.RichiediPINNoMail

        case TipiRichiesta.ConfermaLinkReg:
            return strings.ConfermaLinkReg

        case TipiRichiesta.PagamentoRicorrente:
            return strings.PagamentoRicorrente

        case TipiRichiesta.PagamentoRicorrenteModificaEmail:
            return strings.PagamentoRicorrenteModificaEmail

        case TipiRichiesta.AutoletturaGPL:
            return strings.Autolettura

        case TipiRichiesta.AssistenzaEReclami:
            return strings.assistenzaEReclami

        default:
            return ''
    }
}

export const checkValidDate = (dateStr: string) => {
    if (isNaN(+dateStr)) {
        //Checked for numeric
        var dt = new Date(dateStr)
        if (isNaN(dt.getTime())) {
            //Checked for date
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}

export const getIconStatoRichiesta = (id: number, color: string | Colors) => {
    switch (id) {
        case StatiRichiesta.Evasa:
        case StatiRichiesta.Completata:
        case StatiRichiesta.ValidataBO:
            return <FormCheckmark color={color.toString()} size="medium" />
        case StatiRichiesta.Annullata:
        case StatiRichiesta.Cancellata:
        case StatiRichiesta.Rifiutata:
            return <FormClose color={color.toString()} size="medium" />
        case StatiRichiesta.Bozza:
        case StatiRichiesta.InLavorazione:
        case StatiRichiesta.InEvasione:
        case StatiRichiesta.NuovaDaValidare:
        case StatiRichiesta.InCompilazione:
        case StatiRichiesta.Assegnata:
        case StatiRichiesta.InAttesaDiModulo:
        case StatiRichiesta.Avviata:
            return <Deploy color={color.toString()} size="medium" />
        default:
            return (
                <assets.TransferIcon
                    color={color.toString()}
                    width="24px"
                    height="24px"
                />
            )
    }
}

export const getIconTipoRichiesta = (id: number, color: string | Colors) => {
    switch (id) {
        case TipiRichiesta.RegistrazioneUtente:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.CambioRecapito:
            return <Home color={color.toString()} size="medium" />

        case TipiRichiesta.DomiciliazioneBancaria:
            return <Organization color={color.toString()} size="medium" />

        case TipiRichiesta.AgevolImposte:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.DisdettaContratto:
            return <Directions color={color.toString()} size="medium" />

        case TipiRichiesta.Rettifica:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.PagamentoBolletta:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.Informazioni:
            return <HelpOption color={color.toString()} size="medium" />

        case TipiRichiesta.Reclami:
            return <HelpOption color={color.toString()} size="medium" />

        case TipiRichiesta.Autolettura:
            return <Dashboard color={color.toString()} size="medium" />

        case TipiRichiesta.AutoletturaGPL:
            return <Dashboard color={color.toString()} size="medium" />

        case TipiRichiesta.Preventivo:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.ModificaDatiAnagrafici:
            return <User color={color.toString()} size="medium" />

        case TipiRichiesta.DatiCatastali:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.ContattoGenerico:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.FatturaOnline:
            return <Globe color={color.toString()} size="medium" />

        case TipiRichiesta.ContattoCommerciale:
            return <ContactInfo color={color.toString()} size="medium" />

        case TipiRichiesta.CambioBollettazione:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.InformazioniAltriUsi:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.InformazioniGrandiClienti:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.Repricing:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.Voltura:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.ResidenzaSuUtenza:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.ModificaPotenza:
            return <Optimize color={color.toString()} size="medium" />

        case TipiRichiesta.GestionePrivacy:
            return <Secure color={color.toString()} size="medium" />

        case TipiRichiesta.RichiediPINUtente:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.RichiediPINNoMail:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.ConfermaLinkReg:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.PagamentoRicorrente:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.PagamentoRicorrenteModificaEmail:
            return <Services color={color.toString()} size="medium" />

        case TipiRichiesta.AssistenzaEReclami:
            return <Support color={color.toString()} size="medium" />

        default:
            return <Services color={color.toString()} size="medium" />
    }
}

export const getRequestStatusList = (strings: AppStringsType) => {
    return [
        {
            label: strings.nuova,
            value: StatiRichiesta.Nuova,
        },
        {
            label: strings.nuovaDaValidare,
            value: StatiRichiesta.NuovaDaValidare,
        },
        {
            label: strings.inEvasione,
            value: StatiRichiesta.InEvasione,
        },
        {
            label: strings.evasa,
            value: StatiRichiesta.Evasa,
        },
        {
            label: strings.confermata,
            value: StatiRichiesta.Confermata,
        },
        {
            label: strings.completata,
            value: StatiRichiesta.Completata,
        },
        {
            label: strings.validataBo,
            value: StatiRichiesta.ValidataBO,
        },
        {
            label: strings.rigettataBo,
            value: StatiRichiesta.RigettataBO,
        },
        {
            label: strings.rifiutata,
            value: StatiRichiesta.Rifiutata,
        },
        {
            label: strings.cancellata,
            value: StatiRichiesta.Cancellata,
        },
        {
            label: strings.annullata,
            value: StatiRichiesta.Annullata,
        },
    ]
}

export const getParamValue = (
    params: ParamModel[],
    paramId: number,
    defaultValue?: any,
) => {
    const param = params.find((it) => +it.idTipoParametroAzienda === paramId)
    return param?.valore ?? defaultValue
}

export const getBoolParam = (
    params: ParamModel[],
    paramId: number,
    defaultValue?: any,
) => {
    const param = getParamValue(params, paramId, defaultValue)
    return param === '1'
}

export const phoneRegExp = /^(?:\+?\s*\d)+$/ ///^[0-9]+$/

export const claimsFileExtensions = [
    '.pdf',
    '.jpeg',
    '.jpg',
    '.png',
    '.msg',
    '.eml',
]

export const getAllowedClaimsExtensions = () => {
    return claimsFileExtensions.join(',')
}

export const isValidClaimFileType = (fileName = '') => {
    const extension = fileName.split('.').pop() || ''
    return claimsFileExtensions.indexOf(`.${extension}`) > -1
}

export const validateClaimFiles = (files: FileList, required = false) => {
    if (!files || files.length <= 0) {
        return !required
    }
    let valid = true
    Array.from(files).forEach((file) => {
        valid = valid && isValidClaimFileType(file?.name?.toLowerCase())
    })
    return valid
}

export const isAdmin = (roles: Roles[] = []): boolean =>
    roles.includes(Roles.AMMINISTRATORE_CONDOMINIO)

export const isUser = (roles: Roles[] = []): boolean =>
    roles.includes(Roles.UTENTE_WEB)

export const isOperatorOrAgent = (roles: Roles[] = []): boolean =>
    roles?.includes(Roles.OPERATORE) || roles?.includes(Roles.AGENTE)

export const getCompanyInformation = () => {
    const hostname = window.location.hostname

    const currentCustomer = get(companyMapping, hostname, -1)
    return currentCustomer
}
export const HideNullDate = (input: string | Date | undefined): string | Date => {
    let inputDate: string | Date | undefined;
    if (typeof input === 'string') {
        inputDate = input;
        if (inputDate && inputDate === '01/01/1900') {
            return '';
        }
    } else if (input instanceof Date) {
        inputDate = input;
        if (inputDate && !isNaN(inputDate.getTime()) && inputDate.toDateString() === new Date('1900-01-01').toDateString()) {
            return '';
        }
    }
    return inputDate || '';
 };