const LoginIllustration = ({
    width,
    height,
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & {color?: string}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 989.31079 623.83577"
            {...rest}>
            <path
                d="M692.37948,157.22038c71.22-38.107,161.61234-18.12946,209.53632,49.43939l-1.63132,1.15738c-47.3321-66.73477-136.61915-86.46077-206.96283-48.83259a161.36236,161.36236,0,0,0-17.27247,10.69161C603.7117,220.98256,586.60158,321.57356,637.908,393.911l-1.63132,1.15739c-51.94418-73.2367-34.62179-175.07982,38.61514-227.02357A163.74263,163.74263,0,0,1,692.37948,157.22038Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M1069.67463,320.1036,835.42467,301.88a13.77366,13.77366,0,0,1-12.64949-14.78375l10.15509-113.81345a13.77366,13.77366,0,0,1,14.78367-12.64957l234.25,18.22364a13.77368,13.77368,0,0,1,12.64956,14.78376L1084.45839,307.454A13.77368,13.77368,0,0,1,1069.67463,320.1036Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M1014.34182,294.39144q-2.66748,0-5.35352-.2085L857.60061,282.4056a23.11885,23.11885,0,0,1-21.25684-24.84278l3.45215-44.375A23.14622,23.14622,0,0,1,864.63967,191.93l195.76221,15.22949a23.147,23.147,0,0,1,21.25732,24.84327,67.63906,67.63906,0,0,1-67.31738,62.38867Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1055.718,242.43625l-191.90647-14.9295a3.05369,3.05369,0,1,1,.47369-6.089l191.90648,14.9295a3.05369,3.05369,0,1,1-.4737,6.089Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M976.03336,248.34024l-113.15779-8.80319a3.05384,3.05384,0,0,1,.47372-6.08928L976.50708,242.251a3.05384,3.05384,0,1,1-.47372,6.08928Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M1000.96041,262.38258,861.93966,251.56736a3.05384,3.05384,0,1,1,.47372-6.08928l139.02075,10.81522a3.05384,3.05384,0,0,1-.47372,6.08928Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M1053.99956,474.60373l-234.25-18.22364a13.77365,13.77365,0,0,1-12.64948-14.78375l10.15508-113.81345a13.77365,13.77365,0,0,1,14.78368-12.64956l234.25,18.22364a13.77367,13.77367,0,0,1,12.64955,14.78375l-10.15508,113.81345A13.77367,13.77367,0,0,1,1053.99956,474.60373Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M998.60647,448.889q-2.62939,0-5.293-.20606L841.9258,436.9056a23.11892,23.11892,0,0,1-21.25732-24.84278l3.45215-44.37451a23.12057,23.12057,0,0,1,24.84423-21.25781L1044.72658,361.66a23.11975,23.11975,0,0,1,21.25733,24.84326A67.72193,67.72193,0,0,1,998.60647,448.889Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1040.04287,396.93638,848.1364,382.00688a3.05369,3.05369,0,1,1,.4737-6.089l191.90647,14.9295a3.05369,3.05369,0,1,1-.4737,6.089Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M960.35829,402.84038,847.2005,394.03719a3.05384,3.05384,0,1,1,.47372-6.08928L960.832,396.7511a3.05384,3.05384,0,0,1-.47372,6.08928Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M985.28534,416.88272,846.26459,406.0675a3.05384,3.05384,0,0,1,.47372-6.08929l139.02075,10.81522a3.05384,3.05384,0,0,1-.47372,6.08929Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M458.135,415.13921a56.086,56.086,0,1,1,102.62468-45.28514l-1.83021.80783a54.08546,54.08546,0,1,0-98.96426,43.66948Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M1039.4907,727.92063c22.9003-10.85156,39.95754-32.68422,37.29669-56.6777-20.5446,16.636-48.51539,25.92583-77.39066,25.70351-11.24655-.08653-23.84207-1.23067-32.01476,5.08006-5.08533,3.92661-7.3792,10.16173-7.66908,16.37586-.28975,6.21447,1.21678,12.50388,2.71475,18.71122l-.45553,1.47087C988.86,740.29618,1016.59039,738.7722,1039.4907,727.92063Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#f2f2f2"
            />
            <path
                d="M1076.22353,671.18341c-8.857,17.2822-24.21281,31.68626-43.29674,40.11746a45.81232,45.81232,0,0,1-13.20587,3.88,29.85552,29.85552,0,0,1-13.73648-1.79559c-4.14755-1.48827-8.236-3.42649-12.63471-4.01593a16.17055,16.17055,0,0,0-12.21916,3.29446c-4.06455,3.16085-6.489,7.53482-8.686,11.89824-2.43933,4.84475-4.88353,9.9179-9.66006,13.21959-.57875.40006.22724,1.23208.80507.83268,8.31034-5.74439,9.50957-16.13182,16.113-23.08921a15.53855,15.53855,0,0,1,12.49156-5.22171c4.489.331,8.70663,2.3266,12.89446,3.86493a32.04047,32.04047,0,0,0,13.39912,2.19848,42.98153,42.98153,0,0,0,13.36683-3.39871,92.24419,92.24419,0,0,0,25.29083-15.73654,86.23782,86.23782,0,0,0,20.21213-25.65829c.30594-.597-.83018-.98291-1.13412-.38984Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1039.31957,708.86534a17.93179,17.93179,0,0,0,20.16055,5.12463c.66651-.28043.12223-1.25709-.54515-.97629a16.60083,16.60083,0,0,1-18.74573-4.80044c-.46837-.56966-1.33538.08571-.86967.65216Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1007.99856,714.37964a24.11262,24.11262,0,0,1,10.37026-16.81256c.577-.402-.22883-1.23415-.80508-.83268a25.07152,25.07152,0,0,0-10.7494,17.51756c-.08193.66961,1.10276.79365,1.18424.12763Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1064.0962,689.31041a8.15249,8.15249,0,0,1-3.14325-7.28685c.09151-.66765-1.09333-.791-1.18424-.12762a9.12727,9.12727,0,0,0,3.45783,8.06664.67753.67753,0,0,0,.83738.09028.48571.48571,0,0,0,.03228-.74239Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1001.2322,620.51286c-.07061.41446-.14123.82893-.22074,1.24687a90.55831,90.55831,0,0,1-4.47182,16.28874c-.14668.4106-.30248.82425-.46248,1.22926a98.17129,98.17129,0,0,1-22.51731,33.98768A103.18621,103.18621,0,0,1,960.68211,684.036c-6.38133,4.51567-13.74629,8.92742-17.78464,15.01126a16.1598,16.1598,0,0,0-1.14751,1.96529l18.194,36.81186c.08684.0686.16484.14051.25186.20949l.6536,1.494c.25292-.15279.51109-.31635.764-.46915.14717-.088.29032-.18426.43749-.27232.09683-.06131.1935-.123.28531-.17307.03232-.02027.06444-.041.08773-.05827.09173-.05006.17024-.10572.25292-.15279q2.16942-1.36595,4.3223-2.76552c.00911-.00305.00911-.00305.01416-.01425,10.91315-7.13595,21.17708-15.1996,29.60986-24.50316.2536-.28.51653-.56259.7618-.85943a91.84625,91.84625,0,0,0,10.02887-13.49087,78.98494,78.98494,0,0,0,4.26212-8.07457,65.1906,65.1906,0,0,0,5.67234-22.69122c1.02761-15.71224-3.63329-31.95906-15.22091-44.53654C1001.83074,621.14524,1001.538,620.83144,1001.2322,620.51286Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#f2f2f2"
            />
            <path
                d="M1000.76688,620.76382c5.06783,19.43377,3.32168,39.9302-5.25889,57.37036a36.00411,36.00411,0,0,1-7.26062,10.41074,24.3021,24.3021,0,0,1-11.4999,5.88283c-4.11271.97374-8.4857,1.534-12.178,3.40551a12.838,12.838,0,0,0-6.91826,9.38211c-.9034,4.8723.24234,9.88941,1.55126,14.775,1.45329,5.42445,3.05755,11.04523,1.71645,16.42093-.16249.65134,1.00417.92258,1.16643.27221,2.33328-9.35281-3.80049-18.80987-3.56624-28.27134.10931-4.4149,1.71789-8.71712,5.81755-11.16368,3.58494-2.13945,8.09355-2.72068,12.27028-3.67364a26.33537,26.33537,0,0,0,11.52-5.35928,33.61457,33.61457,0,0,0,7.707-10.08933,78.61676,78.61676,0,0,0,8.2824-26.98289,86.81522,86.81522,0,0,0-2.23638-32.66037c-.17505-.67129-1.28687-.38614-1.113.28081Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M998.64545,672.62178c7.48464,2.3447,15.42479-.46045,18.56307-6.52266.30917-.59721-.75944-1.13218-1.069-.53415-2.94,5.67916-10.28788,8.2266-17.2844,6.03476-.73629-.23064-.94174.79267-.20965,1.022Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M978.92853,694.18533a25.60135,25.60135,0,0,1-3.6169-19.85134c.15986-.652-1.00678-.92345-1.16642-.27221a26.64736,26.64736,0,0,0,3.81029,20.65373C978.34746,695.32767,979.31837,694.79417,978.92853,694.18533Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1003.95807,642.67736a10.05858,10.05858,0,0,1-7.28609-4.486c-.38345-.61569-1.354-.08142-.973.53019a11.271,11.271,0,0,0,8.04948,4.97786c.32212.03871.62271-.07474.688-.37494a.58607.58607,0,0,0-.47838-.64711Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1039.85552,466.54817l-13.9186-74.09639L998.61268,356.487l-20.24955,13.239c2.86135,15.51933,24.50545,44.49126,24.50545,44.49126l19.18428,57.30437c-.07659.29793-.14758.59857-.19927.90759a10.68681,10.68681,0,1,0,18.00193-5.881Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ffb8b8"
            />
            <path
                d="M978.907,377.55474l22.9251-18.60728.04354-.09837c4.288-9.71624-5.23206-26.37676-13.9737-38.64189a19.59609,19.59609,0,0,0-26.22333-5.29961h0a19.63933,19.63933,0,0,0-9.13107,18.613l1.92816,18.562Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#3f3d56"
            />
            <polygon
                points="792.539 608.221 777.285 608.22 770.029 549.384 792.542 549.385 792.539 608.221"
                fill="#ffb8b8"
            />
            <path
                d="M901.77481,741.32258l-4.29344-.00016L880.719,734.50558l-8.98411,6.8158h-.00122a19.14478,19.14478,0,0,0-19.14379,19.14351v.62211l49.184.00185Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <polygon
                points="860.222 608.221 844.968 608.22 837.712 549.384 860.225 549.385 860.222 608.221"
                fill="#ffb8b8"
            />
            <path
                d="M969.458,741.32258l-4.29344-.00016-16.76237-6.81684-8.98411,6.8158h-.00123A19.14478,19.14478,0,0,0,920.273,760.46489v.62211l49.184.00185Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <path
                d="M988.82571,492.87747s1.78114,21.37363-2.6717,41.85669-4.45284,78.17012-4.45284,78.17012l-9.79624,112.4114-29.38874-2.6717-8.01511-187.9098-28.49817,63.23031,2.67171,8.90568-3.55877,2.22642s2.6682,17.36607.88706,17.36607-6.234,2.6717-6.234,2.6717l3.56227,91.72848-30.2793-.89057L862.36509,590.84l11.57738-73.91712.89057-14.24909,4.00756.16476,2.0406-5.95345,3.74809-10.24153Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <path
                d="M990.36505,497.36684l.15872-.22264c8.47691-11.86786-4.27238-40.386-6.19528-44.52013l8.099-2.62822-1.82375-15.50275-.864-6.91146,6.19005-6.19006.01739-.15437,3.56227-31.16987,5.17945-20.70271a45.45157,45.45157,0,0,0-12.90063-44.04788l-11.97222-11.28821-14.55-21.52881h-23.4635l-8.04511,15.19618A34.19883,34.19883,0,0,0,907.297,342.6124l1.82332,39.85508-7.138,49.62306-.00435,8.98047-8.03291,8.92742,1.75417,8.77044-6.11352,2.62-2.69953,12.59666c-1.11712,1.47414-8.49083,11.306-8.49083,13.99383,0,.40354.29787.7936.93709,1.22633,5.0251,3.40268,32.79968,10.3398,45.09239,5.42081,13.09413-5.23557,65.14537,2.61865,65.6698,2.69866Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#3f3d56"
            />
            <path
                d="M786.32386,760.72788a1.18647,1.18647,0,0,0,1.18292,1.19h280.29715a1.19,1.19,0,0,0,0-2.38h-280.29a1.18651,1.18651,0,0,0-1.19006,1.18292Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ccc"
            />
            <circle cx="845.15873" cy="116.39341" r="31.07446" fill="#ffb8b8" />
            <path
                d="M947.52645,286.05309a84.51553,84.51553,0,0,0,14.346-1.37542c4.68679-1.07973,15.02744-9.07571,17.84571-13.79946h0c2.09379-3.50965,3.3505-8.9383,4.03626-12.87409a37.77917,37.77917,0,0,0-7.37934-29.8114,11.93566,11.93566,0,0,0-4.92552-3.98885c-.17437-.06306-.35356-.11828-.53442-.16481a14.72657,14.72657,0,0,1-7.44025-4.34241,12.14424,12.14424,0,0,0-1.24975-1.22149,18.13939,18.13939,0,0,0-7.66375-3.60358c-4.52853-1.08756-11.00643.27527-19.25331,4.05408-4.14323,1.89855-8.74174,1.3689-12.28618.69532a1.15375,1.15375,0,0,0-1.07625.40006,8.14428,8.14428,0,0,1-5.47647,2.34861c-1.25845.08784-2.53516,1.86637-4.07409,4.18237-.34918.52573-.75663,1.1393-1.05233,1.5137l-.05914-.70315-.69227.804a9.88285,9.88285,0,0,0,4.87594,15.98064,19.27275,19.27275,0,0,0,3.891.51747c.7949.05088,1.6172.10351,2.40688.21961a14.9001,14.9001,0,0,1,11.31824,9.3801,3.89666,3.89666,0,0,0,5.92827,1.73939,6.4381,6.4381,0,0,1,5.837-1.21931,4.27377,4.27377,0,0,1,1.94377,2.09335,5.56976,5.56976,0,0,0,2.17641,2.41036c3.23527,1.5637,3.64359,8.85047,2.6539,14.95919-.95405,5.88827-3.02958,10.1837-5.04727,10.44547-1.55328.20134-1.72852.31657-1.83419.5949l-.09439.24917.1809.22612A6.36358,6.36358,0,0,0,947.52645,286.05309Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <circle cx="500.67443" cy="246.35355" r="61.78912" fill={color || '#6c63ff'} />
            <path
                d="M940.369,375.24046,919.9873,362.20591c-12.97884,8.9771-30.519,40.60269-30.519,40.60269l-44.39206,41.01177A10.6812,10.6812,0,1,0,856.9144,458.0151l61.86788-43.0998Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ffb8b8"
            />
            <path
                d="M938.426,380.52937l13.57464-32.57965-4.90987-18.00443a19.64141,19.64141,0,0,0-15.241-14.05514h0a19.59507,19.59507,0,0,0-22.536,14.4183c-3.718,14.59609-6.57316,33.57109.93622,41.08048l.0761.07609Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#3f3d56"
            />
            <path
                d="M147.90252,743.06654c-26.572-13.63868-45.85058-39.99525-41.82-68.16091,23.58089,20.36491,56.18438,32.35326,90.20862,33.1697,13.252.318,28.13265-.5594,37.52464,7.18008,5.844,4.81557,8.31341,12.2464,8.42284,19.57765.10923,7.33162-1.90037,14.68452-3.89684,21.941l.48169,1.74977C207.08485,759.53615,174.47452,756.70521,147.90252,743.06654Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#f2f2f2"
            />
            <path
                d="M106.749,674.85654c9.78835,20.6897,27.34009,38.23161,49.50665,48.87653a53.99407,53.99407,0,0,0,15.412,5.06391,35.18759,35.18759,0,0,0,16.24905-1.60229c4.94152-1.59835,9.83016-3.72892,15.03406-4.259a19.05908,19.05908,0,0,1,14.27151,4.33727c4.67007,3.87534,7.36282,9.11855,9.788,14.34087,2.69268,5.79838,5.38257,11.866,10.88616,15.93388.66683.49288-.31371,1.443-.97953.95083-9.5753-7.07745-10.6001-19.35889-18.11941-27.80158-3.50865-3.93951-8.45889-6.872-14.52043-6.61784-5.30055.22228-10.34356,2.41565-15.3344,4.07146A37.76415,37.76415,0,0,1,173.076,730.24a50.65818,50.65818,0,0,1-15.61961-4.50294,108.72177,108.72177,0,0,1-29.20568-19.4826,101.64173,101.64173,0,0,1-22.85228-30.981c-.33812-.71469,1.01467-1.1269,1.35059-.41687Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M148.81575,720.62519c-5.82511,6.64383-16.07736,8.89023-23.94112,5.284-.77469-.35527-.09706-1.48547.67867-1.12973a19.56607,19.56607,0,0,0,22.26231-4.955c.573-.65358,1.5699.15088,1.00014.80073Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M185.5069,728.29093a28.41953,28.41953,0,0,0-11.58861-20.193c-.66466-.4951.31567-1.44533.97953-.95082a29.54992,29.54992,0,0,1,12.00892,21.03765c.07151.79191-1.32872.89375-1.39984.10614Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M120.35841,696.6636a9.60878,9.60878,0,0,0,3.975-8.46678c-.08288-.79,1.3175-.89095,1.39984-.10614a10.75763,10.75763,0,0,1-4.37468,9.37364.79853.79853,0,0,1-.98984.075.57237.57237,0,0,1-.0103-.87577Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M196.9834,617.96571c.0677.49089.13541.98179.21347,1.47707a106.73249,106.73249,0,0,0,4.65963,19.35562c.15747.4892.32555.98231.4989,1.4654a115.70661,115.70661,0,0,0,25.2568,40.87947,121.61573,121.61573,0,0,0,14.76811,13.169c7.34877,5.55789,15.86012,11.03012,20.3902,18.34791a19.04593,19.04593,0,0,1,1.27841,2.35806l-22.80781,42.686c-.10486.07751-.19942.15938-.30453.23736l-.82577,1.73554c-.29224-.18942-.59026-.39177-.8825-.58118-.17009-.10921-.3351-.22794-.50519-.33715-.11178-.07582-.22338-.15214-.32962-.21454-.03733-.02511-.0744-.05069-.10117-.07192-.10625-.0624-.19661-.13091-.29224-.18941q-2.50464-1.69013-4.98851-3.41929c-.01062-.0039-.01062-.0039-.01615-.01732-12.58953-8.8139-24.3796-18.69649-33.96623-29.97124-.28829-.33931-.58745-.68205-.86533-1.04086a108.25169,108.25169,0,0,1-11.31049-16.26717,93.092,93.092,0,0,1-4.71936-9.67129,76.8352,76.8352,0,0,1-5.83478-26.94279c-.62378-18.54784,5.47365-37.51305,19.59394-51.89693C196.25468,618.6883,196.61128,618.32956,196.9834,617.96571Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#f2f2f2"
            />
            <path
                d="M197.5222,618.27869c-6.69582,22.70433-5.40424,46.91492,4.05262,67.78041a42.4349,42.4349,0,0,0,8.16445,12.53534,28.64271,28.64271,0,0,0,13.32756,7.35961c4.80853,1.30069,9.93912,2.124,14.21891,4.46666a15.131,15.131,0,0,1,7.79955,11.31079c.88226,5.7735-.65481,11.641-2.3792,17.34743-1.9146,6.33589-4.01437,12.89744-2.63528,19.28026.1671.77337-1.21741,1.04929-1.38425.27709-2.39938-11.105,5.17955-22.01668,5.257-33.17133.03611-5.205-1.69822-10.33317-6.43634-13.36844-4.14329-2.65422-9.43284-3.50728-14.3176-4.78593a31.03922,31.03922,0,0,1-13.3708-6.74359,39.61775,39.61775,0,0,1-8.70223-12.17333,92.65975,92.65975,0,0,1-8.74924-32.096,102.3226,102.3226,0,0,1,3.85425-38.39137c.23129-.78428,1.53044-.4068,1.30065.37236Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M198.08462,679.44817c-8.90469,2.48263-18.15369-1.1185-21.62428-8.37712-.3419-.71508.93694-1.30536,1.2793-.58933,3.25134,6.80006,11.81221,10.07537,20.13617,7.75465.876-.24423,1.0798.969.20881,1.2118Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M220.50646,705.587a30.17415,30.17415,0,0,0,5.00219-23.25039c-.16395-.77407,1.2205-1.05023,1.38425-.27709a31.40706,31.40706,0,0,1-5.26,24.18842c-.4846.7065-1.60844.04173-1.12647-.66094Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M192.94449,643.97434a11.85526,11.85526,0,0,0,8.75077-5.01261c.47471-.71094,1.59809-.04538,1.12647.66094a13.28423,13.28423,0,0,1-9.66843,5.56346c-.38092.03358-.73079-.11132-.79653-.46735a.69078.69078,0,0,1,.58772-.74444Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <polygon
                points="172.025 265.948 174.027 284.513 172.849 308.857 226.771 302.451 231.838 265.948 225.382 266.011 172.025 265.948"
                fill="#ffb6b6"
            />
            <polygon
                points="144.057 603.005 155.846 603.005 161.457 557.533 144.057 557.533 144.057 603.005"
                fill="#ffb6b6"
            />
            <path
                d="M245.55425,733.01228l18.8344-1.12416v8.06948L282.295,752.3244a5.0405,5.0405,0,0,1-2.86417,9.18836H257.0078l-3.86495-7.98195-1.50908,7.98195h-8.45439Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <polygon
                points="210.278 603.005 222.067 603.005 227.677 557.533 210.278 557.533 210.278 603.005"
                fill="#ffb6b6"
            />
            <path
                d="M311.775,733.01228l18.8344-1.12416v8.06948l17.9064,12.3668a5.0405,5.0405,0,0,1-2.86418,9.18836H323.22851l-3.865-7.98195-1.50907,7.98195H309.4001Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <path
                d="M244.31517,701.87385h.12816l2.26726-73.93578a19.33336,19.33336,0,0,1,.25367-18.26966l.3246-.58759-.84164-3.90138a24.85032,24.85032,0,0,1,1.42011-14.956l2.11513-68.96837c-7.55652-48.97254,28.45558-80.91057,28.45558-80.91057h53.94661l11.58172,39.792c7.65652,74.595,6.26725,147.2855-5.88257,217.71039l.33123.1675c1.19267.41169,1.7042,2.06388,1.14285,3.6904s-1.98335,2.61136-3.176,2.19966l-28.49-.99093c-1.19267-.4116-1.70439-2.06389-1.14285-3.69041.56135-1.62652,1.98335-2.61125,3.176-2.19966l1.93714.9798-4.13125-71.34523.45847-13.03165-.94452-14.78416-4.367-66.499-16.52971,81.77506-16.88428,88.57845a3.10592,3.10592,0,0,1-2.0944,5.4082H244.31517a3.11556,3.11556,0,1,1,0-6.23111Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <circle cx="181.44098" cy="142.01235" r="28.93543" fill="#ffb6b6" />
            <path
                d="M159.992,413.77455a94.20838,94.20838,0,0,1,16.53187-25.78973,63.66241,63.66241,0,0,0-9.66723,30.87,48.78044,48.78044,0,0,0,6.60223,3.4533A63.262,63.262,0,0,0,195.092,427.168a94.39613,94.39613,0,0,1,20.2686-36.03426,63.81439,63.81439,0,0,0-9.71971,35.87685,99.92113,99.92113,0,0,0,24.34122-5.21676c11.80849-4.06211,23.27057-10.12907,31.43682-19.56535,8.838-10.213,13.19395-23.69045,14.6005-37.12587,1.40655-13.42491.084-26.97583-1.24907-40.41125-.62976-6.40279,4.70238-13.77131,4.07262-20.1741h-.41989c-2.80253-3.84173-5.65752-7.77789-7.03255-12.3333-1.36459-4.545-1.00768-9.90864,2.13074-13.488a14.6545,14.6545,0,0,1,6.43431-3.98863c.231-.29387.48286-.57729.74527-.86072a59.84132,59.84132,0,0,1,16.53188-12.36476c-4.461,3.36931-7.442,7.17952-8.82753,11.11568q5.794-1.2753,11.58809-2.54011a55.81526,55.81526,0,0,1,4.07262-3.90464c2.06777-1.79492,2.75-1.93138,5.2167-3.62128a16.64307,16.64307,0,0,0-4.50295,6.4238c4.96479-1.24907,9.92958-3.12791,12.51173-7.4,4.28256-7.06414-.80825-16.59485-7.91429-20.81443a28.31593,28.31593,0,0,0-32.88532,3.62128c-10.276-7.568-24.908-7.442-36.33863-1.78441a53.13391,53.13391,0,0,0-18.95655,16.48992,74.14338,74.14338,0,0,1,8.58607-.59831,26.332,26.332,0,0,0-11.2417,4.45048,77.432,77.432,0,0,0-4.07262,7.127q-1.21227,2.409-2.28821,4.88086c-4.398,10.01357-7.26351,20.678-10.234,31.21637-3.46381,12.28084-7.17952,24.6456-13.66637,35.63539a80.03779,80.03779,0,0,1-44.13747,35.21551C142.59939,390.88184,149.38,404.46422,159.992,413.77455Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#2f2e41"
            />
            <path
                d="M315.92426,328.38l-5.61591-2.10885-7.08159-11.45052-25.22511,2.06119-6.13119,12.27779L257.1003,340.43915l6.45927,34.01838L273.5948,415.7794l67.17714-8.39714-.071-31.1064s12.13924-24.576-7.46982-33.78483Z"
                transform="translate(-105.34461 -138.08211)"
                fill={color || '#6c63ff'}
            />
            <path
                d="M419.47117,436.28008a9.61207,9.61207,0,0,1-12.99127-6.96156l-74.902-45.32293,13.69075-15.796,69.32122,49.51792a9.66415,9.66415,0,0,1,4.88129,18.56253Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ffb6b6"
            />
            <path
                d="M306.61946,362.50338s-3.57954,3.60157,5.4022,9.52372c7.19057,4.74113,39.26372,28.17035,50.63314,31.87613a7.55111,7.55111,0,0,0,5.03313,7.7697l10.29424-22.622c-2.22831-5.17158-5.79254-3.97412-7.66791-2.80155l-12.43279-9.35012-30.52978-30.48581-18.191,13.4406Z"
                transform="translate(-105.34461 -138.08211)"
                fill={color || '#6c63ff'}
            />
            <path
                d="M276.6753,245.14943a40.29481,40.29481,0,0,0-4.848,35.41371"
                transform="translate(-105.34461 -138.08211)"
                fill="#fd6584"
            />
            <path
                d="M129.87567,760.72788a1.18647,1.18647,0,0,0,1.19007,1.19h280.29a1.19,1.19,0,1,0,0-2.38h-280.29A1.18651,1.18651,0,0,0,129.87567,760.72788Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ccc"
            />
            <path
                id="a4a87c0b-578c-404f-b0e7-3c25016717c2-184"
                data-name="Path 3114"
                d="M236.66644,490.25426a2.79673,2.79673,0,0,0-1.711,3.56047l39.05035,112.55038a2.79673,2.79673,0,0,0,3.56046,1.711L504.78648,528.3543a2.79674,2.79674,0,0,0,1.71078-3.561L467.44692,412.24289a2.79677,2.79677,0,0,0-3.561-1.71078Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M294.17873,591.83236a21.649,21.649,0,0,1-20.396-14.41016l-21.44532-60.67578a21.61166,21.61166,0,0,1,13.17383-27.57813l114.83936-40.58886a86.06045,86.06045,0,0,1,109.69824,52.40332A21.61166,21.61166,0,0,1,476.875,528.56087l-.1665-.47168.1665.47168L301.35988,590.596A21.53409,21.53409,0,0,1,294.17873,591.83236Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                id="e6660b72-17a8-4076-a000-8bf222be7673-185"
                data-name="Path 3117"
                d="M446.46874,472.22655,343.64051,508.30461c-1.44882.50834-3.24438-.85007-4.0106-3.03377s-.21283-4.36615,1.236-4.87448l102.82823-36.07807c1.44882-.50834,3.24438.85008,4.01062,3.03378S447.9183,471.72043,446.46874,472.22655Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                id="f689c0b7-f689-4c10-b0b9-25ad5f15d9dd-186"
                data-name="Path 3118"
                d="M454.42209,494.896,351.59385,530.9741c-1.44882.50834-3.24438-.85007-4.0106-3.03377s-.21282-4.36615,1.236-4.87449l102.82824-36.07806c1.44881-.50834,3.24438.85008,4.01062,3.03377S455.8709,494.38769,454.42209,494.896Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                id="f731b694-f804-4551-a9ab-28894637a524-187"
                data-name="Path 3124"
                d="M364.94882,583.573"
                transform="translate(-105.34461 -138.08211)"
                fill="#e6e6e6"
            />
            <path
                d="M329.72689,520.63823a27.57806,27.57806,0,0,1,1.58375,9.697,26.82169,26.82169,0,0,1-.44634,4.50183,27.70611,27.70611,0,0,1-45.32893,16.02119q-.56007-.47928-1.09056-.98926a27.7,27.7,0,1,1,45.28208-29.23076Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#e4e4e4"
            />
            <circle cx="199.41905" cy="390.72235" r="5.29523" fill="#fd6584" />
            <path
                d="M284.44484,549.869c1.35948-3.15467,2.96615-7.96821,6.688-6.2159,1.83878.79715,5.13166,3.21913,6.46874.40231l10.46955-21.916a3.40956,3.40956,0,0,1,4.62657-1.54261l18.613,9.73844a26.82971,26.82971,0,0,1-.44633,4.50182l-45.329,16.02119Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#3f3d56"
            />
            <path
                d="M289.84809,485.14671a9.61206,9.61206,0,0,1-4.72465-13.96117L261.4276,386.90635l20.79013-2.17248,16.75488,83.52682a9.66415,9.66415,0,0,1-9.12452,16.886Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#ffb6b6"
            />
            <path
                d="M257.92214,340.64177s-5.07456.18231-2.56621,10.64423c2.00814,8.37556,9.383,60.917,15.14909,71.39307a7.55112,7.55112,0,0,0-1.63672,9.11163l22.9765-9.47664c1.90826-5.298.43056-10.10815-1.73982-10.53406l-2.68439-15.323L283.4645,345.0526l-21.87656-4.60775Z"
                transform="translate(-105.34461 -138.08211)"
                fill={color || '#6c63ff'}
            />
            <path
                d="M464.90167,562.39093l-31.77646,1.31613a4.50052,4.50052,0,0,1-3.6021-1.55491,4.408,4.408,0,0,1-1.01706-3.70669,86.53527,86.53527,0,0,0-2.07505-39.95275,4.47106,4.47106,0,0,1,2.208-5.22671c13.0155-6.76218,25.211-7.105,36.24566-1.02035a4.52655,4.52655,0,0,1,2.27165,3.06,141.5524,141.5524,0,0,1,2.04182,43.03244A4.49087,4.49087,0,0,1,464.90167,562.39093Z"
                transform="translate(-105.34461 -138.08211)"
                fill={color || '#6c63ff'}
            />
            <path
                d="M463.5569,541.59329l-24.29669,1.00632a2.2811,2.2811,0,1,1-.1888-4.55829l24.2967-1.00633a2.2811,2.2811,0,1,1,.18879,4.5583Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M463.22584,533.60014l-24.2967,1.00633a2.2811,2.2811,0,0,1-.18879-4.5583l24.2967-1.00633a2.2811,2.2811,0,0,1,.18879,4.5583Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1046.05887,313.81819l-31.53364-4.13591a4.50052,4.50052,0,0,1-3.28323-2.14784,4.40807,4.40807,0,0,1-.36837-3.826,86.53533,86.53533,0,0,0,4.786-39.71929,4.47106,4.47106,0,0,1,3.069-4.77228c13.98-4.43743,26.05451-2.69025,35.88647,5.19141a4.52652,4.52652,0,0,1,1.715,3.4033,141.55219,141.55219,0,0,1-5.34527,42.748A4.49086,4.49086,0,0,1,1046.05887,313.81819Z"
                transform="translate(-105.34461 -138.08211)"
                fill={color || '#6c63ff'}
            />
            <path
                d="M1048.28957,293.09684l-24.111-3.16237a2.2811,2.2811,0,1,1,.59329-4.52346l24.111,3.16237a2.2811,2.2811,0,1,1-.59329,4.52346Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M1049.32993,285.16478l-24.111-3.16237a2.28111,2.28111,0,1,1,.59329-4.52347l24.111,3.16237a2.28111,2.28111,0,1,1-.59329,4.52347Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <path
                d="M626.019,374.93566h-10.5v-10.5a9.5,9.5,0,0,0-19,0v10.5h-10.5a9.5,9.5,0,0,0,0,19h10.5v10.5a9.5,9.5,0,0,0,19,0v-10.5h10.5a9.5,9.5,0,0,0,0-19Z"
                transform="translate(-105.34461 -138.08211)"
                fill="#fff"
            />
            <circle cx="860.70939" cy="32.52333" r="10.01145" fill="#3f3d56" />
            <circle cx="245.70939" cy="315.52333" r="10.01145" fill="#3f3d56" />
        </svg>
    )
}

export default LoginIllustration
