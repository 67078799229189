import { UserModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const insertRichiestaPrivacy =
    (user: UserModel): AppAction<any> => async (dispatch, getState, container) => {
        try {         
            const editData = await container.api.insertRichiestaPrivacyAPI(user)
            const lan = getState().common.language
            const i18n = await container.strings(lan)
            //dispatch({ type: ADD_COMPANY, payload: newCompany })
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaPrivacy
