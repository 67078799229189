import { UsersSearchModel } from '../../../models/models'
import { UsersFilters } from '../../api/loadUsersAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadUsers =
    (filters: UsersFilters): AppAction<UsersSearchModel> =>
    async (dispatch, _, container) => {
        try {
            const users = await container.api.loadUsersAPI(filters)
            return users
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadUsers
