import { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Keyboard, Spinner } from 'grommet'
import { Hide, Login, User, UserAdmin, View } from 'grommet-icons'
import BTextInput from '../BTextInput'
import useI18n from '../../hooks/useI18n'
import useTheme from '../../hooks/useTheme'

// const brandColor = useTheme().global?.colors?.txtPrimary?.toString()

const LoginForm = (props: {
    onSubmit: (values: { username: string; password: string }) => void
    onRegisterClick: () => void
    loading: boolean
}) => {
    const strings = useI18n()
    const theme = useTheme()
    const [reveal, setReveal] = useState(false)
    const loginSchema = Yup.object().shape({
        password: Yup.string().required(strings.mandatoryField),
        username: Yup.string().required(strings.mandatoryField)
    })

    return (
        <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={(value) => props.onSubmit && props.onSubmit(value)}>
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                return (
                    <Keyboard target="component" onEnter={() => handleSubmit()}>
                        <Box gap="xsmall">
                            <BTextInput
                                label={strings.username}
                                name="username"
                                placeholder={strings.usernamePlaceholder}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                renderIcon={() => (
                                    <Button
                                        icon={<User size="medium" />}
                                        style={{pointerEvents:'none'}}
                                    />
                                )}
                            />
                            <BTextInput
                                label={strings.password}
                                name="password"
                                placeholder={strings.passwordPlaceholder}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                type={reveal ? 'text' : 'password'}
                                renderIcon={() => (
                                    <Button
                                        icon={
                                            reveal ? (
                                                <View size="medium" />
                                            ) : (
                                                <Hide size="medium" />
                                            )
                                        }
                                        onClick={() => setReveal(!reveal)}
                                    />
                                )}
                            />
                            <Box
                                //direction="row-reverse"
                                justify="between"
                                animation="fadeIn"
                                margin={{ top: 'small' }}>
                                <Button
                                    disabled={props.loading}
                                    onClick={async () => {
                                        try {
                                            if (await validateForm()) {
                                                handleSubmit()
                                            }
                                        } catch (e) {}
                                    }}
                                    icon={
                                        props.loading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <Login />
                                        )
                                    }
                                    label={strings.loginButton}
                                    primary
                                    style={{color:theme.global?.colors?.['textWhite']?.toString()}}

                                />
                                <Box

                                justify="between"
                                animation="fadeIn"
                                margin={{ top: 'small' }}>
                                <Button
                                    secondary
                                    disabled={props.loading}

                                    onClick={props.onRegisterClick}

                                    icon={
                                        props.loading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <UserAdmin color="brand" />
                                        )
                                    }
                                    label={strings.registrati}
                                    style={{color:theme.global?.colors?.['brand']?.toString()}}

                                />
                            </Box>
                            </Box>
                        </Box>
                    </Keyboard>
                )
            }}
        </Formik>
    )
}

export default LoginForm
