import {
    Box,
    Button,
    CheckBox,
    DataTable,
    DropButton,
    DropButtonProps,
    Heading,
    Layer,
    Spinner,
    Text,
    Tip,
} from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import {
    CircleInformation,
    DocumentPdf,
    Download,
    MoreVertical,
    Search,
} from 'grommet-icons'
import './Bollette.css'
import useI18n from '../hooks/useI18n'
import { orderBy } from 'lodash'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import { InvoiceFiltersModel, InvoiceModel, ParamModel } from '../models/models'

import useTheme from '../hooks/useTheme'
import SupplySelector from '../components/SupplySelector'
import {
    getDescTipoRichiesta,
    getIconStatoPagamento,
    getIconTipoRichiesta,
    getInitialDateRange,
} from '../utilities'
import {
    dayStringDate,
    getDescStatoPagamento,
    monthStringDateName,
    yearStringDate,
    getColorStatoPagamento,
    monthStringDate,
} from '../utilities'
import InvoicesFilterForm from '../components/forms/InvoicesFilterForm'
import loadInvoices from '../services/store/actions/loadInvoices'
import {
    BE_TipiFatturazione,
    BooleanValueInt,
    paramEnum,
    StatiFornitura,
    StatiPagamento,
    TipiFatturazione,
} from '../models/Enums'
import getFatturaPDF from '../services/store/actions/getFatturaPDF'
import BRoundButton from '../components/BRoundButton'
import RequestSelectorPage from '../components/RequestSelectorPage'
import useAssets from '../hooks/useAssets'
import loadPublicParams from '../services/store/actions/loadPublicParams'
import useSize from '../hooks/useSize'
import { AllRoutes, RoutesEnum } from '../Navigation'
import BSlidingBox from '../components/BSlidingBox'
import useParameters from '../hooks/useParameters'

function downloadPDF(
    pdf: any,
    tipofatt: string,
    anno: string,
    mese: string,
    giorno: string,
    numero: string,
) {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = `${anno}_${tipofatt}_${anno}${mese}${giorno}_${numero}.pdf`

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
}

const Bollette = () => {
    const [today, fromDate] = getInitialDateRange()
    const initialFilters: InvoiceFiltersModel = {
        invoicesStartDate: fromDate.toJSON(),
        invoicesEndDate: today.toJSON(),
    }
    const strings = useI18n()
    const navigate = useNavigate()
    const parameters = useParameters()
    const supply = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const invoices = useAppSelector((state) => state.invoice.invoices)
    const request = useAppSelector((state) => state.request)
    const [showModalRequest, setShowModalRequest] = useState(false)
    const [showOnly, setShowOnly] = useState(false)
    const [currentFilters, setCurrentFilters] = useState(initialFilters)
    const [actionVisible, setActionVisible] = useState(-1)
    const [isLoadingInvoices, queryInvoices] = useAppQuery(loadInvoices)
    const [, queryParams] = useAppQuery(loadPublicParams)
    const size = useSize()
    const [currInvoices, setInvoices] = useState<Array<InvoiceModel>>([])
    const [showSintetica, setShowSintetica] = useState(false)
    const theme = useTheme()
    const assets = useAssets()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const state = location.state as { path?: string }

    const isActionVisible = (index: number) => actionVisible === index

    const orderRowsBy = (key: string, p_order: 'asc' | 'desc' | boolean) => {
        if (key === 'HBolletta') key = 'dataFattura'
        if (key === 'HScadenza') key = 'dataScadenza'
        if (key === 'HImporto') key = 'importoPagamento'
        if (key === 'HStato') key = 'statoPagamento'
        if (key === 'HNFattura') key = 'nrFattura'
        let newFiltered: Array<InvoiceModel> = []
        let order = p_order

        newFiltered = orderBy(
            showOnly
                ? currInvoices.filter(
                      (it) => it.statoPagamento !== StatiPagamento.Pagata,
                  )
                : currInvoices,
            [key],
            [order],
        )
        setInvoices(newFiltered)
    }

    const getDescTipoFatt = (id: number) => {
        switch (id) {
            case TipiFatturazione.Sintetica:
                return strings.sintetica
            case TipiFatturazione.Dettagliata:
                return strings.dettagliata
            default:
                return ''
        }
    }

    const checkShowSintetica = useCallback(async () => {
        try {
            const publicParam = await queryParams()
            const valueBolletta20 = publicParam.find(
                (it: ParamModel) =>
                    it.idTipoParametroAzienda.toString() ===
                    paramEnum.Bolletta20.toString(),
            )
            setShowSintetica(valueBolletta20?.valore === BooleanValueInt.true)
        } catch (e) {}
    }, [queryParams])

    const filterInvoices = useCallback(
        async (filters: InvoiceFiltersModel = currentFilters) => {
            try {
                let filtinvoices: Array<InvoiceModel> = invoices.filter(
                    (it: InvoiceModel) =>
                        it.puntoGas === supply.selectedSupply?.puntoGas,
                )
                if (showOnly) {
                    filtinvoices = invoices.filter(
                        (it) => it.statoPagamento !== StatiPagamento.Pagata,
                    )
                }
                const filtDateInvoices = filtinvoices.filter(
                    (item) =>
                        new Date(item.dataFattura) >=
                            new Date(filters.invoicesStartDate!) &&
                        new Date(item.dataFattura) <=
                            new Date(filters?.invoicesEndDate!),
                )
                const newFiltered = orderBy(
                    filtDateInvoices,
                    ['dataFattura'],
                    ['desc'],
                )
                setInvoices(newFiltered)
            } catch (e) {}
        },
        [
            currentFilters,
            invoices,
            showOnly,
            supply.selectedSupply?.puntoGas,
            setInvoices,
        ],
    )

    useEffect(() => {
        if (!user.codiceUtente || !supply.selectedSupply?.puntoGas) {
            return
        }
        queryInvoices({
            codiceUtente:
                supply.selectedSupply?.codiceUtente ?? user.codiceUtente,
            puntoGas: supply.selectedSupply?.puntoGas,
        })
        checkShowSintetica()
    }, [
        supply.selectedSupply?.puntoGas,
        queryInvoices,
        checkShowSintetica,
        user.codiceUtente,
        supply.selectedSupply?.codiceUtente,
    ])

    useEffect(() => {
        filterInvoices(currentFilters)
        setActionVisible(-1)
    }, [currentFilters, filterInvoices])

    const dropAlign: DropButtonProps['dropAlign'] = {
        top: 'bottom',
        right: 'right',
    }

    if (supply.loading) {
        return (
            <Box fill background={'background-back'}>
                <Box height="100%" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }

    return (
        <Box flex direction="row" align="start">
            <Box direction="column" width="100%" pad="small" gap="medium">
                <Box direction="column">
                    <Box
                        direction="row"
                        margin={{ vertical: 'medium' }}
                        justify="between"
                    >
                        <Heading margin={{ top: '0' }} level={3}>
                            {strings.bollette}
                        </Heading>

                        {supply.selectedSupply?.idStatoFornitura ===
                            StatiFornitura.Attiva && (
                            <BRoundButton
                                label={strings.faiUnaRichiesta}
                                onClick={() => {
                                    setShowModalRequest(true)
                                }}
                            />
                        )}
                    </Box>

                    <Box
                        direction={'column'}
                        justify={size !== 'large' ? 'start' : 'between'}
                        gap={'medium'}
                        margin={
                            size !== 'large' ? { bottom: 'medium' } : undefined
                        }
                    >
                        <SupplySelector />
                        <CheckBox
                            toggle
                            reverse
                            checked={showOnly}
                            label={strings.mostraSoloDaPagare}
                            onChange={(event) => {
                                setShowOnly(event.target.checked)
                            }}
                        />
                        <InvoicesFilterForm
                            isLoading={isLoadingInvoices}
                            type="edit"
                            filters={initialFilters}
                            onSubmit={setCurrentFilters}
                            onResetFilters={() =>
                                setCurrentFilters(initialFilters)
                            }
                        />
                    </Box>
                    {size === 'small' && (
                        <DropButton
                            alignSelf="end"
                            margin={{ vertical: 'small' }}
                            dropContent={
                                <Box pad="small" gap="small" round="medium">
                                    <Heading level={3}>
                                        {strings.statoPagamento}
                                    </Heading>
                                    <Box
                                        alignSelf="start"
                                        pad={{
                                            vertical: '10px',
                                            horizontal: '10px',
                                        }}
                                        round="large"
                                        background="ok"
                                        direction="column"
                                    >
                                        <Text size="small">
                                            {strings.pagata}
                                        </Text>
                                    </Box>
                                    <Box
                                        alignSelf="start"
                                        pad={{
                                            vertical: '10px',
                                            horizontal: '10px',
                                        }}
                                        round="large"
                                        background="ko"
                                        direction="column"
                                    >
                                        <Text size="small">
                                            {strings.daPagare}
                                        </Text>
                                    </Box>
                                    <Box
                                        alignSelf="start"
                                        pad={{
                                            vertical: '10px',
                                            horizontal: '10px',
                                        }}
                                        round="large"
                                        background="warn"
                                        direction="column"
                                    >
                                        <Text size="small">
                                            {strings.pagataParzialmente}
                                        </Text>
                                    </Box>
                                    <Box
                                        alignSelf="start"
                                        pad={{
                                            vertical: '10px',
                                            horizontal: '10px',
                                        }}
                                        round="large"
                                        background="warn"
                                        direction="column"
                                    >
                                        <Text size="small">
                                            {strings.rateizzata}
                                        </Text>
                                    </Box>
                                </Box>
                            }
                            dropProps={{ align: { top: 'bottom' } }}
                        >
                            <CircleInformation size="medium" />
                        </DropButton>
                    )}
                </Box>
                {supply.selectedSupply && size !== 'small' && (
                    <Box round="small" margin={{ vertical: 'medium' }}>
                        <DataTable
                            sort={{
                                direction: 'desc',
                                external: true,
                                property: 'HBolletta',
                            }}
                            border="horizontal"
                            className="InvoicesGrid"
                            paginate
                            step={5}
                            sortable
                            pad="small"
                            margin={size === 'large' ? 'small' : 'xsmall'}
                            onSort={({ property, direction }) => {
                                orderRowsBy(property, direction)
                            }}
                            placeholder={
                                isLoadingInvoices ? (
                                    <Box
                                        fill
                                        align="center"
                                        justify="center"
                                        direction="row"
                                        pad="large"
                                        gap="small"
                                        background={{
                                            color: 'background-front',
                                            opacity: 'strong',
                                        }}
                                        hoverIndicator={undefined}
                                    >
                                        <Spinner />
                                        <Text weight="bold">{`${
                                            strings.loading
                                        } ${strings.bollette.toLowerCase()}...`}</Text>
                                    </Box>
                                ) : undefined
                            }
                            columns={[
                                {
                                    property: 'HBolletta',
                                    header: (
                                        <Text
                                            weight="bold"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {strings.bolletta}
                                        </Text>
                                    ),
                                    primary: true,
                                    render: (datum) => (
                                        <Text
                                            truncate="tip"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {datum.HBolletta}
                                        </Text>
                                    ),
                                },
                                {
                                    property: 'HImporto',
                                    header: (
                                        <Text
                                            weight="bold"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {strings.importo}
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Text
                                            truncate="tip"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {datum.HImporto}
                                        </Text>
                                    ),
                                },
                                {
                                    property: 'HScadenza',
                                    header: (
                                        <Text
                                            weight="bold"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {strings.scadenza}
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Text
                                            truncate="tip"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {datum.HScadenza}
                                        </Text>
                                    ),
                                },
                                {
                                    property: 'HNFattura',
                                    header: (
                                        <Text
                                            weight="bold"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {strings.fatturaNr}
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Text
                                            truncate="tip"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {datum.HNFattura}
                                        </Text>
                                    ),
                                },
                                {
                                    property: 'HStato',
                                    header: (
                                        <Text
                                            weight="bold"
                                            size={
                                                size === 'large'
                                                    ? 'large'
                                                    : 'medium'
                                            }
                                        >
                                            {strings.stato}
                                        </Text>
                                    ),
                                    size: 'xxxsmall',
                                    align: 'center',
                                    render: (datum) => (
                                        <Tip
                                            content={
                                                <Box color="textWhite">
                                                    {datum.HStato}
                                                </Box>
                                            }
                                        >
                                            {getIconStatoPagamento(
                                                datum.statoPagamento,
                                                getColorStatoPagamento(
                                                    datum.statoPagamento,
                                                ),
                                            )}
                                        </Tip>
                                    ),
                                },
                                {
                                    sortable: false,
                                    property: 'HDownload',
                                    size: 'xxxsmall',
                                    align: 'center',
                                    render: (datum) => (
                                        <DropButton
                                            dropAlign={dropAlign}
                                            dropContent={
                                                <Box
                                                    direction="column"
                                                    gap="small"
                                                    pad="small"
                                                >
                                                    <Button
                                                        primary
                                                        label={strings.details}
                                                        style={{
                                                            padding: '4px 8px',
                                                        }}
                                                        fill
                                                        justify="start"
                                                        onClick={() =>
                                                            navigate(
                                                                `/${
                                                                    AllRoutes[
                                                                        RoutesEnum
                                                                            .BILL
                                                                    ]
                                                                }/${
                                                                    datum.nrFattura
                                                                }`,
                                                            )
                                                        }
                                                        icon={
                                                            <Search
                                                                color={theme.global?.colors?.[
                                                                    'textWhite'
                                                                ]?.toString()}
                                                            />
                                                        }
                                                    />
                                                    {showSintetica &&
                                                        datum.idTipoFatturazione ===
                                                            BE_TipiFatturazione.Sintetica && (
                                                            <Button
                                                                primary
                                                                style={{
                                                                    padding:
                                                                        '4px 8px',
                                                                }}
                                                                fill
                                                                justify="start"
                                                                color="primary_color"
                                                                focusIndicator={
                                                                    false
                                                                }
                                                                label={
                                                                    strings.sintetica
                                                                }
                                                                icon={
                                                                    <DocumentPdf />
                                                                }
                                                                onClick={async () => {
                                                                    try {
                                                                        const data =
                                                                            await dispatch(
                                                                                getFatturaPDF(
                                                                                    {
                                                                                        nrFattura:
                                                                                            datum.nrFattura,
                                                                                        tipoFatturazione:
                                                                                            datum.idTipoFatturazione + '_S',
                                                                                    },
                                                                                ),
                                                                            )
                                                                        downloadPDF(
                                                                            data.value,
                                                                            getDescTipoFatt(
                                                                                TipiFatturazione.Sintetica,
                                                                            ),
                                                                            yearStringDate(
                                                                                datum.dataFattura,
                                                                            ),
                                                                            monthStringDate(
                                                                                datum.dataFattura,
                                                                            ),
                                                                            dayStringDate(
                                                                                datum.dataFattura,
                                                                            ),
                                                                            datum.nrFattura,
                                                                        )
                                                                    } catch (e) {
                                                                        console.log(
                                                                            e,
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    {parameters.downLoadPdfFatture && (
                                                        <Button
                                                            primary
                                                            style={{
                                                                padding:
                                                                    '4px 8px',
                                                            }}
                                                            fill
                                                            justify="start"
                                                            color="primary_color"
                                                            focusIndicator={
                                                                false
                                                            }
                                                            label={
                                                                strings.dettagliata
                                                            }
                                                            icon={
                                                                <DocumentPdf />
                                                            }
                                                            onClick={async () => {
                                                                try {
                                                                    const data =
                                                                        await dispatch(
                                                                            getFatturaPDF(
                                                                                {
                                                                                    nrFattura:
                                                                                        datum!
                                                                                            .nrFattura,
                                                                                    tipoFatturazione:
                                                                                        datum.idTipoFatturazione + '_D',
                                                                                },
                                                                            ),
                                                                        )
                                                                    downloadPDF(
                                                                        data.value,
                                                                        getDescTipoFatt(
                                                                            TipiFatturazione.Dettagliata,
                                                                        ),
                                                                        yearStringDate(
                                                                            datum!
                                                                                .dataFattura,
                                                                        ),
                                                                        monthStringDate(
                                                                            datum!
                                                                                .dataFattura,
                                                                        ),
                                                                        dayStringDate(
                                                                            datum!
                                                                                .dataFattura,
                                                                        ),
                                                                        datum!
                                                                            .nrFattura,
                                                                    )
                                                                } catch (e) {
                                                                    console.log(
                                                                        e,
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            }
                                        >
                                            <MoreVertical color="brand" />
                                        </DropButton>
                                    ),
                                },
                            ]}
                            data={currInvoices.map((item: InvoiceModel) => ({
                                HBolletta: `${monthStringDateName(
                                    item.dataFattura,
                                    strings,
                                    false,
                                )} ${yearStringDate(item.dataFattura)}`,
                                HImporto: `${item.importoTotaleFattura.toFixed(
                                    2,
                                )} €`,
                                HScadenza: `${dayStringDate(
                                    item.dataScadenza,
                                )} ${monthStringDateName(
                                    item.dataScadenza,
                                    strings,
                                    true,
                                )} ${yearStringDate(item.dataScadenza)}`,
                                HStato: `${getDescStatoPagamento(
                                    item.statoPagamento,
                                    strings,
                                )}`,
                                HNFattura: item.nrFattura,
                                ...item,
                            }))}
                        />
                    </Box>
                )}
                {!isLoadingInvoices &&
                    supply.selectedSupply &&
                    size === 'small' && (
                        <Box gap="small">
                            {currInvoices.map((item, index) => (
                                <Box
                                    direction="row"
                                    flex="grow"
                                    round="small"
                                    border={{
                                        side: 'right',
                                        size: 'xlarge',
                                        color: getColorStatoPagamento(
                                            item.statoPagamento,
                                        ),
                                    }}
                                    elevation="small"
                                    key={index}
                                    style={{
                                        position: 'relative',
                                        overflowX: 'hidden',
                                    }}
                                >
                                    <BSlidingBox
                                        direction="row"
                                        fill="horizontal"
                                        show={isActionVisible(index)}
                                        onClick={() =>
                                            setActionVisible((prev) =>
                                                prev === index ? -1 : index,
                                            )
                                        }
                                        main={
                                            <Box
                                                direction="row"
                                                align="start"
                                                pad="small"
                                                margin={{ horizontal: 'small' }}
                                                justify="between"
                                            >
                                                <Box>
                                                    <Text
                                                        color="brand"
                                                        weight="bold"
                                                        size="small"
                                                    >
                                                        {`${monthStringDateName(
                                                            item.dataFattura,
                                                            strings,
                                                            false,
                                                        )} ${yearStringDate(
                                                            item.dataFattura,
                                                        )}`}
                                                    </Text>
                                                    <Text
                                                        color="brand"
                                                        weight="lighter"
                                                        size="small"
                                                    >
                                                        {`${dayStringDate(
                                                            item.dataScadenza,
                                                        )} ${monthStringDateName(
                                                            item.dataScadenza,
                                                            strings,
                                                            true,
                                                        )} ${yearStringDate(
                                                            item.dataScadenza,
                                                        )}`}
                                                    </Text>
                                                </Box>
                                                <Text
                                                    color="brand"
                                                    weight="bold"
                                                    size="medium"
                                                >
                                                    {`${item.importoTotaleFattura.toFixed(
                                                        2,
                                                    )} €`}
                                                </Text>
                                            </Box>
                                        }
                                        sliding={
                                            <Box
                                                direction="row"
                                                fill="vertical"
                                            >
                                                <Button
                                                    primary
                                                    style={{
                                                        width: 'fit-content',
                                                        height: '100%',
                                                        borderRadius: '0',
                                                    }}
                                                    size="small"
                                                    onClick={() =>
                                                        navigate(
                                                            `/${
                                                                AllRoutes[
                                                                    RoutesEnum
                                                                        .BILL
                                                                ]
                                                            }/${
                                                                item.nrFattura
                                                            }`,
                                                        )
                                                    }
                                                    icon={
                                                        <Search
                                                            color={theme.global?.colors?.[
                                                                'textWhite'
                                                            ]?.toString()}
                                                        />
                                                    }
                                                />
                                                <DropButton
                                                    primary
                                                    style={{
                                                        width: 'fit-content',
                                                        height: '100%',
                                                        borderRadius: '0',
                                                    }}
                                                    size="small"
                                                    color="primary_color"
                                                    focusIndicator={false}
                                                    icon={
                                                        <Download
                                                            color={theme.global?.colors?.[
                                                                'textWhite'
                                                            ]?.toString()}
                                                        />
                                                    }
                                                    dropAlign={dropAlign}
                                                    dropContent={
                                                        <Box round="small">
                                                            {showSintetica &&
                                                                item.idTipoFatturazione ===
                                                                    BE_TipiFatturazione.Sintetica && (
                                                                    <BRoundButton
                                                                        style={{
                                                                            padding:
                                                                                '4px',
                                                                            height: '100%',
                                                                            borderRadius:
                                                                                '0',
                                                                        }}
                                                                        size="small"
                                                                        label={
                                                                            strings.sintetica
                                                                        }
                                                                        onClick={async () => {
                                                                            try {
                                                                                const data =
                                                                                    await dispatch(
                                                                                        getFatturaPDF(
                                                                                            {
                                                                                                nrFattura:
                                                                                                    item.nrFattura,
                                                                                                tipoFatturazione:
                                                                                                    item.idTipoFatturazione + '_S',
                                                                                            },
                                                                                        ),
                                                                                    )
                                                                                downloadPDF(
                                                                                    data.value,
                                                                                    getDescTipoFatt(
                                                                                        TipiFatturazione.Sintetica,
                                                                                    ),
                                                                                    yearStringDate(
                                                                                        item.dataFattura,
                                                                                    ),
                                                                                    monthStringDate(
                                                                                        item.dataFattura,
                                                                                    ),
                                                                                    dayStringDate(
                                                                                        item.dataFattura,
                                                                                    ),
                                                                                    item.nrFattura,
                                                                                )
                                                                            } catch (e) {
                                                                                console.log(
                                                                                    e,
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            <BRoundButton
                                                                style={{
                                                                    padding:
                                                                        '4px',
                                                                    height: '100%',
                                                                    borderRadius:
                                                                        '0',
                                                                }}
                                                                size="small"
                                                                label={
                                                                    strings.dettagliata
                                                                }
                                                                onClick={async () => {
                                                                    try {
                                                                        const data =
                                                                            await dispatch(
                                                                                getFatturaPDF(
                                                                                    {
                                                                                        nrFattura:
                                                                                            item!
                                                                                                .nrFattura,
                                                                                        tipoFatturazione:
                                                                                            item.idTipoFatturazione + '_D',
                                                                                    },
                                                                                ),
                                                                            )
                                                                        downloadPDF(
                                                                            data.value,
                                                                            getDescTipoFatt(
                                                                                TipiFatturazione.Dettagliata,
                                                                            ),
                                                                            yearStringDate(
                                                                                item!
                                                                                    .dataFattura,
                                                                            ),
                                                                            monthStringDate(
                                                                                item!
                                                                                    .dataFattura,
                                                                            ),
                                                                            dayStringDate(
                                                                                item!
                                                                                    .dataFattura,
                                                                            ),
                                                                            item!
                                                                                .nrFattura,
                                                                        )
                                                                    } catch (e) {
                                                                        console.log(
                                                                            e,
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                />
                                            </Box>
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
            </Box>
            {showModalRequest && (
                <Layer
                    position="center"
                    onClickOutside={() => setShowModalRequest(false)}
                    onEsc={() => setShowModalRequest(false)}
                >
                    <Box
                        gap="small"
                        width={{ min: 'small' }}
                        height={{ min: 'small' }}
                        fill
                    >
                        <RequestSelectorPage
                            title={strings.selectYourSupply}
                            icon={
                                supply.selectedSupply?.tipoServizio === 'E' ? (
                                    <assets.EnergyIcon />
                                ) : supply.selectedSupply?.tipoServizio ===
                                  'GPL' ? (
                                    <assets.GplIcon />
                                ) : (
                                    <assets.GasIcon />
                                )
                            }
                            fclose={() => setShowModalRequest(false)}
                            requests={request.requestTypes.map((it) => {
                                return {
                                    title: getDescTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        strings,
                                    ),
                                    icon: getIconTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        'brand',
                                    ),
                                    activeIcon: getIconTipoRichiesta(
                                        it?.idTipoRichiesta,
                                        'textWhite',
                                    ),
                                    id: String(it?.idTipoRichiesta),
                                }
                            })}
                            address={`${supply.selectedSupply?.tipoVia} ${supply.selectedSupply?.via} ${supply.selectedSupply?.numeroCivico}, ${supply.selectedSupply?.comune} ${supply.selectedSupply?.provincia}`}
                            addressColor={
                                supply.addresses.find(
                                    (element) =>
                                        element.puntiGas.indexOf(
                                            supply.selectedSupply?.puntoGas!,
                                        ) > -1,
                                )?.color!
                            }
                            pod={`${
                                supply.selectedSupply?.tipoServizio === 'E'
                                    ? strings.pod
                                    : strings.pdr
                            } ${supply.selectedSupply?.pod}`}
                            requestsContainerTitle={
                                strings.richiesteDisponibili
                            }
                        />
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default Bollette
