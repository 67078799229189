import get from 'lodash.get'
import useTheme from '../hooks/useTheme'

const HomeIcon = ({
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string }) => {
    const { global } = useTheme()
    const currentColor = get(global?.colors, color || '')
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3394 5.65929C19.0094 6.17829 19.4204 6.94929 19.4904 7.78829L19.5004 7.98929V16.0983C19.5004 18.1883 17.8494 19.8883 15.7804 19.9983H13.7904C12.8394 19.9793 12.0704 19.2393 12.0004 18.3093L11.9904 18.1683V15.3093C11.9904 14.9983 11.7594 14.7393 11.4504 14.6883L11.3604 14.6783H8.68937C8.37037 14.6883 8.11037 14.9183 8.07037 15.2183L8.06037 15.3093V18.1593C8.06037 18.2183 8.04937 18.2883 8.04037 18.3383L8.03037 18.3593L8.01937 18.4283C7.90037 19.2793 7.20037 19.9283 6.33037 19.9893L6.20037 19.9983H4.41037C2.32037 19.9983 0.610366 18.3593 0.500366 16.2983V7.98929C0.509366 7.13829 0.880366 6.34829 1.50037 5.79829L7.73037 0.788294C9.00037 -0.220706 10.7804 -0.260706 12.0894 0.668294L12.2504 0.788294L18.3394 5.65929ZM18.0994 16.2583L18.1104 16.0983V7.99829C18.0994 7.56929 17.9204 7.16829 17.6104 6.86929L17.4804 6.75829L11.3804 1.87829C10.6204 1.26829 9.54037 1.23929 8.74037 1.76829L8.58937 1.87829L2.50937 6.77929C2.16037 7.03829 1.95037 7.42829 1.90037 7.83829L1.88937 7.99829V16.0983C1.88937 17.4283 2.92937 18.5183 4.25037 18.5983H6.20037C6.42037 18.5983 6.61037 18.4493 6.63937 18.2393L6.66037 18.0593L6.67037 18.0083V15.3093C6.67037 14.2393 7.49037 13.3693 8.54037 13.2883H11.3604C12.4294 13.2883 13.2994 14.1093 13.3804 15.1593V18.1683C13.3804 18.3783 13.5304 18.5593 13.7304 18.5983H15.5894C16.9294 18.5983 18.0194 17.5693 18.0994 16.2583Z"
                fill={currentColor?.toString() ?? '#303972'}
            />
        </svg>
    )
}

export default HomeIcon
