import { IOCServices } from '..'

export type validateFatturaViaMailType = {
    CodiceUtente: string,
    Email: string,
    IDRichiesta: number
}

const validateFatturaViaMailAPI =
    async (item: validateFatturaViaMailType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID

        try {
            const res = await restID.post(
                '/Richiesta/ValidateRichiestaFatturaEmail',{
                    CodiceUtente: item.CodiceUtente,
                    Email: item.Email,
                    IDRichiesta: item.IDRichiesta
            })
            return res.data.value

        } catch (e) {

            return Promise.reject(e)
        }
    }

export default validateFatturaViaMailAPI
