import { IOCServices } from '..'
import {
    RequestFiltersModel,
    RequestListResponseModel,
} from '../../models/models'

const loadUsersRequestsAPI = async (
    filters: RequestFiltersModel,
    container?: IOCServices,
): Promise<RequestListResponseModel> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.post(`/Richiesta/GetRichieste/`, { ...filters })
        const data = res.data.value
        return { requests: data.elencoRichieste, totalRows: data.totalRows }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadUsersRequestsAPI
