import { Box, Heading, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { Organization } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import { Wrapper } from "@googlemaps/react-wrapper";
import { useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import useSize from '../hooks/useSize'
import { SportelloModel } from '../models/models'
import BSeparator from '../components/BSeparator'
import BProfileButton from '../components/BProfileButton'

import loadSportelli from '../services/store/actions/loadSportelli'
import MapComponent from '../components/MapComponent';
import MarkerComponent from '../components/MarkerComponent';

const Sportelli = () => {
    const strings = useI18n()

    const user = useAppSelector((state) => state.user.clientInfo)
  
    const [isLoading, querySportelli] = useAppQuery(loadSportelli)
    const size = useSize()
    const [currSportelli, setCurrSportelli] = useState<Array<SportelloModel>>([])
    const [centX, setCentX] = useState<number>(0)
    const [centY, setCentY] = useState<number>(0)

    const apikey = `${process.env.REACT_APP_Google_APIKEY}`

    const loadInfoData = async () => {
        try {

            const sportelli = await querySportelli()
            setCurrSportelli(sportelli)

            if (sportelli[0]) {
                setCentX(+sportelli[0].latitudine)
                setCentY(+sportelli[0].longitudine)
            }

        } catch (e) {}
    }

    useEffect(() => {
        loadInfoData()

    }, [])



    if (isLoading) {
        return (
            <Box fill background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }
    else {
    return (
        <Box
            direction={size === 'small' ? 'column' : 'row'}
        >
            <Box
                direction="column"
                width="100%"
                pad="small"

            >
                <BProfileButton user={user.nominativo} />
                <Box direction="column">
                    <Heading level={3} margin={{ bottom: '10px' }}>
                        {strings.sportelli}
                    </Heading>
                    <BSeparator size="2px" margin={{ bottom: '10px' }} />

                    <Box align="center">
                        <Wrapper apiKey={apikey}>
                            <MapComponent centX={centX} centY={centY}>
                                {currSportelli.map((item: SportelloModel) => {
                                    return (
                                        <MarkerComponent
                                            title={item.nomeAzienda}
                                            position={{
                                                lat: +item.latitudine,
                                                lng: +item.longitudine,
                                            }}></MarkerComponent>
                                    )
                                })}
                            </MapComponent>
                        </Wrapper>
                    </Box>

                    <Box direction="row" gap="large" margin={{ top: 'small' }}>
                        {currSportelli.map((it) => {
                            return (
                                <Box
                                    margin={{ vertical: 'small' }}
                                    direction="row"
                                    round="medium"
                                    width="large"
                                    height="small"
                                    pad="small"
                                    gap="medium"
                                    style={{ cursor: 'pointer' }}
                                    border
                                    elevation="small"
                                    animation="fadeIn"
                                    onClick={() => {}}>
                                    <Box
                                        direction="row"
                                        align="center"
                                        gap="medium">
                                        <Organization
                                            size="large"
                                            color="brand"
                                        />
                                        <Box direction="column" gap="small">
                                            <Text size="large" weight="bold">
                                                {it.nomeAzienda}
                                            </Text>
                                            <Text size="small">
                                                {it.comune}
                                            </Text>
                                            <Text size="small">
                                                {it.indirizzo}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
    }
}


export default Sportelli


