import { Box, Heading, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import streetTypes from '../models/streetTypes'

import { useLocation, useNavigate } from 'react-router'
import BBackButton from '../components/BBackButton'
import SupplySelector from '../components/SupplySelector'
import RidForm from '../components/forms/RIDForm'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import getProvinceComuni from '../services/store/actions/getProvinceComuni'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'

import { StatiFornitura } from '../models/Enums'
import { CityProvinceModel, RequestModel } from '../models/models'
import insertRichiestaDomiciliazione from '../services/store/actions/insertRichiestaDomiciliazione'
import loadRequests from '../services/store/actions/loadRequests'
import { getDescStatoFornitura } from '../utilities'

const DomiciliazioneBancaria = () => {
    const strings = useI18n()
    const user = useAppSelector((state) => state.user.clientInfo)
    const main = useAppSelector((state) => state.main)
    const supply = useAppSelector((state) => state.supply)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const state = location.state as { path?: string }

    const [currProvince, setCurrProvince] = useState<Array<CityProvinceModel>>(
        [],
    )
    const [currRequests, setRequests] = useState<Array<RequestModel>>([])

    const [isLoadingProvinces, queryProvinces] = useAppQuery(getProvinceComuni)
    const [isLoadingRequests, queryRequests] = useAppQuery(loadRequests)
    const disabledSupply =
        supply.selectedSupply?.idStatoFornitura === StatiFornitura.Cessata

    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        loadData(user.codiceUtente)
    }, [user.codiceUtente])

    const loadData = async (codiceUtente: string) => {
        try {
            let province: Array<CityProvinceModel> = await queryProvinces()
            let requestlist: Array<RequestModel> = await queryRequests(
                codiceUtente,
            )
            setCurrProvince(province)
            setRequests(requestlist)
        } catch (e) {}
    }

    if (isLoadingProvinces || isLoadingRequests || !currRequests) {
        return (
            <Box fill background={'background-back'}>
                <Box height="100vh" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }

    return (
        <Box flex pad="small">
            <Box direction="row" flex="grow" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => navigate(state?.path ?? '/requests')}
                />
                <Heading level={3}>{strings.DomiciliazioneBancaria}</Heading>
            </Box>
            <Box gap="small" flex="grow">
                <SupplySelector disableInactiveSupply />
                {supply.selectedSupply && !disabledSupply && (
                    <RidForm
                        initialValues={{
                            nominativo:
                                supply.selectedSupply.cognomeNomeRecapito || '',
                            codiceUtente:
                                supply.selectedSupply.codiceUtente ??
                                user?.codiceUtente,
                            codiceUtenza: supply.selectedSupply.puntoGas || '',
                            podPdr: supply.selectedSupply.pod || '',
                            codiceFiscale: user?.codiceFiscale || '',
                            statoFornitura:
                                getDescStatoFornitura(
                                    +supply.selectedSupply.idStatoFornitura,
                                    strings,
                                ) || '',
                            iban: supply.selectedSupply.ibanPaese || '',
                            email:
                                supply.selectedSupply.email1 ||
                                supply.selectedSupply.email2 ||
                                supply.selectedSupply.email3 ||
                                supply.selectedSupply.email4 ||
                                '',
                            address: supply.selectedSupply.via || '',
                            cap: supply.selectedSupply.cap!,
                            number:
                                (supply.selectedSupply
                                    .nrCivicoRecapito as string) || '',
                            barrato:
                                supply.selectedSupply.esponenteCivicoRecapito ||
                                '',
                            province:
                                supply.selectedSupply.provinciaRecapito || '',
                            city: supply.selectedSupply.comuneRecapito,
                            cityIstat: '',
                            streetType: supply.selectedSupply.tipoVia || '',
                            sDD: '',
                            modalitaInvio: '',
                            scala: '',
                            piano: '',
                            interno: '',
                            frazione: '',
                            tipoRichiesta: '',
                            partitaIvaRich: '',
                            codfiscRich: '',
                            nominativoLegRapp: '',
                            codiceFiscaleLegRapp: '',
                            partitaIVA: '',
                        }}
                        streetTypes={streetTypes}
                        cities={currProvince}
                        requests={currRequests}
                        onSubmit={async (values) => {
                            try {
                                const cityList = values.province
                                    ? currProvince.find(
                                          (it) =>
                                              it.codiceProvincia.toLowerCase() ===
                                                  values?.province?.toLowerCase() ??
                                              '',
                                      )?.comune ?? []
                                    : []
                                const city = cityList.find(
                                    (it) => it.nomeComune === values.city,
                                )
                                values.city = city?.nomeComune ?? ''
                                values.cityIstat = city?.codiceIstat ?? ''

                                await dispatch(
                                    insertRichiestaDomiciliazione(values),
                                )

                                navigate('/requests')
                            } catch (e) {}
                        }}
                        isLoading={false}
                    />
                )}
                {!supply.selectedSupply && (
                    <Text>{strings.selectYourSupply}</Text>
                )}
                {disabledSupply && (
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small"
                    >
                        <Text>{strings.richiesteCessate}</Text>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default DomiciliazioneBancaria
