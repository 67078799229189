import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { AutoletturaModel } from '../../models/models'

const insertRichiestaAutoletturaAPI = async (auto: AutoletturaModel, isOperator: boolean, container?: IOCServices) => {
    if (!container) {
        return Promise.reject('Container is null. API must be called from api service')
    }

    const restID = container.restID
    const name = container.store.getState().user.clientInfo.nominativo ?? ''
    try {
        const res = await restID.post('/Richiesta/InsertRichiestaAutolettura', {
            ...auto,
            Nominativo: name,
            LetturaCorrettore: auto.LetturaCorr,
            IsOperator: isOperator
        })
        return res.status === HttpStates.OK
    } catch (e) {
        return Promise.reject(e)
    }
}

export default insertRichiestaAutoletturaAPI
