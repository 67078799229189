import {
    Box,
    BoxExtendedProps,
    Button,
    ColumnConfig,
    DataTable,
    DataTableProps,
    Layer,
    Text,
} from 'grommet'
import { Nota } from '../models/models'
import useI18n from '../hooks/useI18n'
import { formatDateString } from '../utilities'
import { View } from 'grommet-icons'
import { useState } from 'react'
import BLightButton from './BLightButton'

type NotesTableProps = {
    boxProps?: BoxExtendedProps
    note: Nota[]
} & DataTableProps<Nota>

const NotesTable = ({
    boxProps = {},
    note = [],
    ...dataTableProps
}: NotesTableProps) => {
    const strings = useI18n()
    const [showNoteItem, setShowNoteItem] = useState<Nota>()
    const columns: ColumnConfig<Nota>[] = [
        {
            property: 'idNotaInterna',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.noteId}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.idNotaInterna}
                </Text>
            ),
        },
        {
            property: 'notaInterna',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.note}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.notaInterna}
                </Text>
            ),
        },
        {
            property: 'codiceUtente',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.operatore}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.codiceUtente}
                </Text>
            ),
        },
        {
            property: 'dataInserimento',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.date}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {formatDateString(item?.dataInserimento, 'yyyy-MM-dd')}
                </Text>
            ),
        },
        {
            property: 'show',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {''}
                </Text>
            ),
            render: (item) => (
                <Box direction="row" gap="small" justify="end">
                    <BLightButton
                        boxProps={{ direction: 'row', align: 'center' }}
                        onClick={() => setShowNoteItem(item)}
                        size="small"
                        label={strings.showNote}
                        icon={<View />}
                    />
                </Box>
            ),
        },
    ]
    return (
        <Box background={'background-front'} {...boxProps}>
            <DataTable
                background={'background-front'}
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                verticalAlign={'middle'}
                columns={columns}
                data={note}
                {...dataTableProps}
            />
            {!!showNoteItem && (
                <Layer
                    position="center"
                    style={{ minWidth: '300px' }}
                    onClickOutside={() => setShowNoteItem(void null)}
                    onEsc={() => setShowNoteItem(void null)}
                >
                    <Box pad="medium">
                        <Text weight={'bold'} size="large">
                            {strings.note} {showNoteItem.idNotaInterna}
                        </Text>
                        <Text weight={'lighter'} size="small">
                            {formatDateString(
                                showNoteItem.dataInserimento,
                                'yyyy-MM-dd',
                            )}
                        </Text>
                        <Box margin={{ vertical: 'medium' }}>
                            <Text weight={'bold'}>
                                {strings.note} {strings.from}{' '}
                                {showNoteItem.codiceUtente}:
                            </Text>
                            <Text>{showNoteItem.notaInterna}</Text>
                        </Box>
                        <Box direction="row-reverse" align="center" gap="small">
                            <Button
                                secondary
                                label={strings.close}
                                onClick={() => setShowNoteItem(void null)}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default NotesTable
