import { Box } from 'grommet'
import { FormDown, FormUp, Logout, User } from 'grommet-icons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AllRoutes, RoutesEnum } from '../Navigation'
import useClientInfo from '../hooks/useClientInfo'
import useI18n from '../hooks/useI18n'
import { ClientModel } from '../models/models'
import BLightButton from './BLightButton'
import BRoundButton from './BRoundButton'

interface IUserSelectedProps {
    client: ClientModel
    onProfileClick?: Function
}

const UserSelected = ({ client, onProfileClick }: IUserSelectedProps) => {
    const strings = useI18n()
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [_, resetClientInfo] = useClientInfo()

    return (
        <Box
            elevation="small"
            round={{ corner: 'right', size: 'medium' }}
            width="full"
            pad="small"
            background={{
                color: 'brand',
            }}
            height={{ min: 'fit-content' }}
            align="center"
        >
            <Box
                direction="row"
                align="center"
                gap="small"
                onClick={() => setOpen((prev) => !prev)}
            >
                <BLightButton
                    boxProps={{
                        hoverIndicator: false,
                    }}
                    label={client.nominativo}
                    icon={<User color="textWhite" />}
                    onClick={(e) => {
                        e.stopPropagation()
                        setOpen((prev) => !prev)
                    }}
                />
                {open ? <FormUp /> : <FormDown />}
            </Box>
            {open && (
                <Box
                    direction="column"
                    pad="xsmall"
                    fill={'horizontal'}
                    gap="small"
                >
                    <BRoundButton
                        size="small"
                        label={strings.profileLabel}
                        icon={<User color="textWhite" />}
                        onClick={() => onProfileClick?.()}
                    />
                    <BRoundButton
                        size="small"
                        color={'ko'}
                        label={strings.deselectClient}
                        icon={<Logout color="textWhite" />}
                        onClick={() => {
                            resetClientInfo()
                            navigate(`/${AllRoutes[RoutesEnum.HOME]}`, {
                                replace: true,
                            })
                        }}
                    />
                </Box>
            )}
        </Box>
    )
}

export default UserSelected
