import { IOCServices } from '..'
import { isOperatorOrAgent } from '../../utilities'

const getAnagraficaUtenteAPI = async (
    userCode: string,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID
    const isOperator = isOperatorOrAgent(container.store.getState().user.loggedUser.roles)
    try {
        const res = await rest.get(`/Utente/GetAnagraficaUtente/${userCode}?isOperator=${isOperator}`)
        const data = res.data
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getAnagraficaUtenteAPI
