import { get, merge } from 'lodash'
import companyMapping from './customerSpecs/companyMapping'
import defaultCustomer from './customerSpecs/customers/defaultCustomer'
import { getCompanyInformation } from '../utilities'

export type CustomerAssetType = typeof defaultCustomer
export type CustomerProviderService = {
    defaultCustomer: CustomerAssetType
    loadAssetsAsync: (clientId: string | number) => Promise<CustomerAssetType>
    currentCustomerId: number
}

const customerProvider = () => {

    const loadAssetsAsync = async (clientId: string | number) => {
        const asset = await import(`./customerSpecs/customers/${clientId}`)
        const result = merge(defaultCustomer, asset?.default)
        return result
    }

    const companyInformation = getCompanyInformation()
    const currentCustomerId = companyInformation.theme

    return {
        defaultCustomer,
        loadAssetsAsync,
        currentCustomerId
    }
}

export default customerProvider
