import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

const getStringheAPI =
    async (container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Stringa/GetStringheAPI',{
            })
            if (res.status == HttpStates.OK) {

                const data = res.data.value
                return data              
            }
         
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default getStringheAPI
