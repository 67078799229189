import { Roles } from "../models/Enums"
import { useAppSelector } from "../services/storeProvider"

const useAuth = () => {
    const isLoading = useAppSelector((state) => state.common.loading > 0)
    const loggedIn = useAppSelector((state) => state.user.loggedIn)
    const { roles } = useAppSelector((state) => state.user.loggedUser)

    return { isLoading, loggedIn: loggedIn || !roles.includes(Roles.NON_LOGGATO), roles }
}

export default useAuth