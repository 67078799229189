import { ClaimsARERACategory } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getClaimsARERACategories =
    ():  AppAction<ClaimsARERACategory[]> => async (dispatch, _, container) => {
        try {
            return await container.api.getClaimsARERACategoriesAPI()
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getClaimsARERACategories
