import { useState } from 'react'
import * as Yup from 'yup'
import { Box, Button } from 'grommet'
import useI18n from '../../hooks/useI18n'
import BDateInput from '../BDateInput'
import { useNavigate } from 'react-router-dom'
import useRequiredFields from '../../hooks/useRequiredFields'
import { useAppDispatch } from '../../services/storeProvider'
import { Formik } from 'formik'
import { InvoiceFiltersModel } from '../../models/models'
import useTheme from '../../hooks/useTheme'

const InvoicesFilterForm = ({
    isLoading,
    onSubmit,
    onResetFilters,
    filters,
    type,
}: InvoicesFilterFormProps) => {
    const strings = useI18n()
    const Schema = Yup.object().shape({
        invoicesStartDate: Yup.string().notRequired(),
        invoicesEndDate: Yup.string().notRequired()
    })
    const dispatch = useAppDispatch()
    const isRequired = useRequiredFields(Schema)
    const [expandFilterDate, setExpandFilterDate] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()
    return (
        <Formik
            initialValues={{
                invoicesStartDate: filters?.invoicesStartDate ?? '',
                invoicesEndDate: filters?.invoicesEndDate ?? '',
            }}
            validationSchema={Schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
                onSubmit?.(values)
            }}>
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                resetForm,
            }) => {
                return (
                    <Box gap="small" margin={{ bottom: 'small' }}>

                        <Box
                            onClick={() => setExpandFilterDate(!expandFilterDate)}
                            margin={{ bottom: 'small' }}
                            flex
                        >

                            <Box>
                                <Box
                                    onClick={(e) => e.stopPropagation()}
                                    flex
                                    direction="row"
                                    gap="small"
                                >
                                    <BDateInput

                                        containerProps={{
                                            flex: true,
                                            width: 'small',
                                        }}
                                        disabled={type === 'disabled'}
                                        name="invoicesStartDate"
                                        format="dd/mm/yyyy"
                                        label={
                                            strings.dataInizio
                                        }
                                        placeholder={
                                            strings.phDataInizio
                                        }
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                    />
                                    <BDateInput
                                        containerProps={{
                                            flex: true,
                                            width: 'small',
                                        }}
                                        disabled={type === 'disabled'}
                                        name="invoicesEndDate"
                                        format="dd/mm/yyyy"
                                        label={
                                            strings.dataFine
                                        }
                                        placeholder={
                                            strings.phDataFine
                                        }
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                    />
                                </Box>
                            </Box>

                        </Box>
                        <Box direction="row" gap="xsmall">
                            <Button
                                primary
                                disabled={isLoading}
                                onClick={() => {
                                    handleSubmit()
                                }}
                                label={strings.load}
                            />
                            <Button
                                primary
                                disabled={isLoading}
                                onClick={() => {
                                    setExpandFilterDate(false)
                                    resetForm()
                                    onResetFilters()
                                }}
                                label={strings.resetFilters}
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

interface InvoicesFilterFormProps {
    isLoading: boolean
    onSubmit: (
        filters: InvoiceFiltersModel,
    ) => void | Promise<InvoiceFiltersModel> | Promise<void>
    onResetFilters: () => void
    filters?: InvoiceFiltersModel
    type?: 'edit' | 'disabled' | undefined
}

export default InvoicesFilterForm
