import { Box, Meter, Stack, Text, BoxProps, TextExtendedProps } from 'grommet'

export const PieChart = ({
    label = '',
    value = 0,
    valueLabel = '',
    total = 100,
    textProps = {},
    color,
    ...rest
}: PieChartProps) => {
    return (
        <Box justify="center" align="center" {...rest}>
            <Text weight={'bold'} size="large">
                {label}
            </Text>
            <Stack anchor="center">
                <Meter
                    type="circle"
                    background={{color: 'light-1', opacity: 0.5}}
                    values={[
                        {
                            value,
                            color: color,
                        },
                    ]}
                    max={100}
                    size="small"
                    thickness="medium"
                />
                <Box align="center">
                    <Box
                        direction="row"
                        align="center"
                        pad={{ bottom: 'xsmall' }}>
                        <Text weight="bold" {...textProps}>
                            {value}%
                        </Text>
                    </Box>
                    <Text weight="lighter" {...textProps}>{valueLabel}</Text>
                </Box>
            </Stack>
        </Box>
    )
}

export default PieChart

type PieChartProps = {
    label?: string
    value?: number
    total?: number
    valueLabel?: string
    color?: string
    textProps?: TextExtendedProps,
} & BoxProps