import { useEffect, useState } from 'react'
import { Text, Box, Button, Spinner, Footer, Tip } from 'grommet'
import {
    Route,
    Routes,
    BrowserRouter as Router,
    Navigate,
    Outlet,
} from 'react-router-dom'
import Home from './pages/Home'
import UserProfile from './pages/UserProfile'

import Login from './pages/Login'
import { keepAlive } from './services/store/actions'
import Page404 from './pages/Page404'
import { useAppDispatch, useAppSelector } from './services/storeProvider'

import Forniture from './pages/Forniture'
import FatturaViaMail from './pages/FatturaViaMail'
import Bollette from './pages/Bollette'
import Richieste from './pages/Richieste'
import DatiContratto from './pages/DatiContratto'
import InformazioniAzienda from './pages/Informazioni'
import Autolettura from './pages/Autolettura'
import DisdettaContratto from './pages/DisdettaContratto'
import Sportelli from './pages/Sportelli'
import DettaglioFornitura from './pages/DettaglioFornitura'
import DettaglioBolletta from './pages/DettaglioBolletta'
import Supporto from './pages/Supporto'
import Planner from './pages/Planner'
import DomiciliazioneBancaria from './pages/DomiciliazioneBancaria'

import ChangeAddress from './pages/ChangeAddress'
import RequestDetails from './pages/RequestDetails'
import useAssets from './hooks/useAssets'
import SideNavbar from './components/navigation/SideNavbar'
import HeaderNavBar from './components/navigation/HeaderNavBar'
import FooterNavBar from './components/navigation/FooterNavBar'
import useSize from './hooks/useSize'
import useCurrentPath from './hooks/useCurrentPath'
import BSpinner from './components/BSpinner'
import useTheme from './hooks/useTheme'
import { FormClose } from 'grommet-icons'
import { useContainer } from './services'
import useI18n from './hooks/useI18n'
import Reclami from './pages/Reclami'
import { BooleanValue, Roles } from './models/Enums'
import useAuth from './hooks/useAuth'
import HomeOperator from './pages/HomeOperator'
import useClientInfo from './hooks/useClientInfo'
import ContractDetailsBox from './components/ContractDetailsBox'
import RequestList from './pages/RequestList'
import RequestDetailsOperator from './pages/RequestDetailsOperator'
import CambioPassword from './pages/CambioPassword'
import MailConfirm from './pages/MailConfirm'
import Registrazione from './pages/Registrazione'
import Informativa from './pages/Informativa'
import Autologin from './pages/Autologin'
import useIsOperator from './hooks/useIsOperator'
import ChangeUserData from './pages/ChangeUserData'

export const enum RoutesEnum {
    AUTO_LOGIN,
    BILL_BY_EMAIL,
    BILL,
    BILL_WITH_ID,
    BILLS,
    CAMBIO_RECAPITO,
    CANCELLATION,
    CHANGE_ADDRESS,
    COMPONENTS,
    DATI_CONTRATTO,
    HOME,
    INFORMATIVA,
    INFORMAZIONI,
    LOGIN,
    MAIL_CONFIRM,
    PASSWORD_CHANGE,
    PLANNER,
    REGISTRAZIONE,
    REQUEST_DETAILS,
    REQUESTS,
    RID_PAYMENT,
    SELF_READING,
    SIGNUP,
    SPORTELLI,
    SUPPLIES,
    SUPPLY,
    SUPPORT,
    USER,
    RECLAMI,
    REQUEST_LIST,
    REQUEST_DETAILS_OPERATOR,
    CHANGE_USER_DATA,
}

export const AllRoutes: {
    [key in RoutesEnum]: string
} = {
    [RoutesEnum.AUTO_LOGIN]: 'autologin',
    [RoutesEnum.BILL_BY_EMAIL]: 'billByEmail',
    [RoutesEnum.BILL]: 'bill',
    [RoutesEnum.BILL_WITH_ID]: 'bill/:id',
    [RoutesEnum.BILLS]: 'bills',
    [RoutesEnum.CAMBIO_RECAPITO]: 'cambioRecapito',
    [RoutesEnum.CANCELLATION]: 'cancellation',
    [RoutesEnum.CHANGE_ADDRESS]: 'changeAddress',
    [RoutesEnum.COMPONENTS]: 'components',
    [RoutesEnum.DATI_CONTRATTO]: 'datiContratto',
    [RoutesEnum.HOME]: 'home',
    [RoutesEnum.INFORMATIVA]: 'informativa',
    [RoutesEnum.INFORMAZIONI]: 'informazioni',
    [RoutesEnum.LOGIN]: 'login',
    [RoutesEnum.MAIL_CONFIRM]: 'MailConfirm',
    [RoutesEnum.PASSWORD_CHANGE]: 'passwordChange',
    [RoutesEnum.PLANNER]: 'planner',
    [RoutesEnum.REGISTRAZIONE]: 'registrazione',
    [RoutesEnum.REQUEST_DETAILS]: 'requestDetails',
    [RoutesEnum.REQUESTS]: 'requests',
    [RoutesEnum.RID_PAYMENT]: 'RIDPayment',
    [RoutesEnum.SELF_READING]: 'selfReading',
    [RoutesEnum.SIGNUP]: 'signup',
    [RoutesEnum.SPORTELLI]: 'sportelli',
    [RoutesEnum.SUPPLIES]: 'supplies',
    [RoutesEnum.SUPPLY]: 'supply',
    [RoutesEnum.SUPPORT]: 'support',
    [RoutesEnum.USER]: 'user',
    [RoutesEnum.RECLAMI]: 'claims',
    [RoutesEnum.REQUEST_LIST]: 'requestList',
    [RoutesEnum.REQUEST_DETAILS_OPERATOR]: 'requestList/:reqId',
    [RoutesEnum.CHANGE_USER_DATA]: 'changeUserData',
}

type PrivateRouteProps = {
    children: JSX.Element
    hiddenTo?: Roles[]
}

const openInNewTab = (url: any) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer')
}

const PrivateRoute = ({ children, hiddenTo = [] }: PrivateRouteProps) => {
    const { isLoading, loggedIn, roles } = useAuth()
    const assets = useAssets()
    const size = useSize()
    const dispatch = useAppDispatch()
    const isOperator = useIsOperator()
    const [selectedClient] = useClientInfo()
    const currentPath = useCurrentPath()
    const unauthorized = hiddenTo.some((it) => roles?.includes(it))

    useEffect(() => {
        dispatch(keepAlive())
    }, [dispatch])

    if (isLoading && !loggedIn) {
        return assets.useLogoAsLanding ? (
            <Box
                fill="vertical"
                align="center"
                justify="center"
                background={`url(${
                    size === 'small' ? assets.logintop : assets.loginside
                })`}
                pad="small"
            >
                <Box
                    width={size === 'small' ? 'small' : 'medium'}
                    height={size === 'small' ? 'small' : 'medium'}
                    background={{
                        repeat: 'no-repeat',
                        size: 'contain',
                        image: 'url(' + assets.logo + ')',
                    }}
                />
                <BSpinner />
            </Box>
        ) : (
            <Box fill="vertical" align="center" justify="center">
                <Spinner />
            </Box>
        )
    }

    if (loggedIn && unauthorized) {
        return <Navigate to={'/home'} />
    }
    if (
        isOperator &&
        !selectedClient &&
        currentPath !== AllRoutes[RoutesEnum.HOME] &&
        currentPath !== AllRoutes[RoutesEnum.REQUEST_LIST] &&
        currentPath !== AllRoutes[RoutesEnum.REQUEST_DETAILS_OPERATOR]
    ) {
        return <Navigate to={'/home'} />
    }

    return loggedIn ? children : <Login {...children.props} />
}

const Main = () => {
    const currentPath = useCurrentPath()
    const size = useSize()
    const strings = useI18n()
    const loggedUser = useAppSelector((state) => state.user.loggedUser)
    const companyInfo = useAppSelector((state) => state.main.companyInfo)
    const clientInfo = useAppSelector((state) => state.user.clientInfo)
    const isOperator = useIsOperator()
    const [selectedClient] = useClientInfo()

    return (
        <Box
            flex
            direction={size === 'small' ? 'column' : 'row'}
            align="start"
            height="100%"
        >
            {size !== 'small' && <SideNavbar name={loggedUser.name} />}
            {size === 'small' && currentPath !== AllRoutes[RoutesEnum.USER] && (
                <HeaderNavBar name={loggedUser.name} />
            )}
            <Box flex fill>
                <Box overflow="auto" fill>
                    {isOperator && selectedClient && (
                        <ContractDetailsBox
                            pad={{
                                top: 'small',
                                horizontal: 'small',
                            }}
                            round="none"
                            title={`${strings.selectedClient}: ${clientInfo.codiceUtente}`}
                            background={{
                                color: 'sidebar',
                            }}
                            elements={[
                                {
                                    title: strings.nominativo,
                                    value: clientInfo.nominativo,
                                },
                                {
                                    title: strings.partitaIVA,
                                    value: clientInfo.partitaIVA || '-',
                                },
                                {
                                    title: strings.codiceFiscale,
                                    value: clientInfo.codiceFiscale,
                                },
                                {
                                    title: strings.indirizzo,
                                    value: clientInfo.indirizzo,
                                },
                                {
                                    title: strings.telefono,
                                    value:
                                        clientInfo.cellulare ||
                                        clientInfo.telefono ||
                                        '-',
                                },
                                {
                                    title: strings.email,
                                    value: clientInfo.email || '-',
                                },
                            ]}
                            visible={false}
                        />
                    )}

                    <Outlet />
                </Box>
                {companyInfo && (
                    <Footer
                        background="footer"
                        justify="center"
                        pad="xsmall"
                        height="24px"
                    >
                        {size !== 'small' ? (
                            <Text textAlign="center" size="small">
                                {`${strings.copyright} ${companyInfo.RagioneSociale?.azienda} | ${companyInfo.Indirizzo?.informazione} ${strings.cf_piva} ${companyInfo.PartitaIVA?.informazione} - ${companyInfo.EMailInfo?.informazione}`}
                            </Text>
                        ) : (
                            <Tip
                                content={
                                    <Box round="xsmall">
                                        <Text textAlign="center" size="small">
                                            {`${companyInfo.Indirizzo?.informazione} ${strings.cf_piva} ${companyInfo.PartitaIVA?.informazione} - ${companyInfo.EMailInfo?.informazione}`}
                                        </Text>
                                    </Box>
                                }
                            >
                                <Text textAlign="center" size="small">
                                    {`${strings.copyright} ${companyInfo.RagioneSociale?.azienda}`}
                                </Text>
                            </Tip>
                        )}
                    </Footer>
                )}
            </Box>
            {size === 'small' && <FooterNavBar />}
        </Box>
    )
}

function Navigation() {
    const container = useContainer()
    const cookieStatus = container.jwt.loadCookieStatus()
    const theme = useTheme()
    const strings = useI18n()
    const size = useSize()
    const [showCookies, setShowCookies] = useState(!cookieStatus) // Show cookies banner only if the user has never viewed them
    const [selectedClient] = useClientInfo()
    const isOperator = useIsOperator()
    const userRoles = Object.values(Roles).filter(
        (it) => it !== Roles.AGENTE && it !== Roles.OPERATORE,
    ) as Roles[]
    const hiddenRolesForOperatorsAndAgents = isOperator ? [] : userRoles
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Navigate
                            replace
                            to={`/${AllRoutes[RoutesEnum.HOME]}`}
                        />
                    }
                />
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Main />
                        </PrivateRoute>
                    }
                >
                    <Route
                        path={AllRoutes[RoutesEnum.HOME]}
                        element={
                            isOperator && !selectedClient ? (
                                <HomeOperator />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.BILLS]}
                        element={<Bollette />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.BILL]}
                        element={
                            <Navigate
                                replace
                                to={`/${AllRoutes[RoutesEnum.BILLS]}`}
                            />
                        }
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.BILL_WITH_ID]}
                        element={<DettaglioBolletta />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.REQUESTS]}
                        element={<Richieste />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.INFORMAZIONI]}
                        element={<InformazioniAzienda />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.SELF_READING]}
                        element={<Autolettura />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.CANCELLATION]}
                        element={<DisdettaContratto />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.CAMBIO_RECAPITO]}
                        element={<ChangeAddress />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.SPORTELLI]}
                        element={<Sportelli />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.SUPPLY]}
                        element={<DettaglioFornitura />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.SUPPLIES]}
                        element={<Forniture />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.DATI_CONTRATTO]}
                        element={<DatiContratto showSide={true} />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.BILL_BY_EMAIL]}
                        element={<FatturaViaMail />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.USER]}
                        element={<UserProfile />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.REQUEST_DETAILS]}
                        element={<RequestDetails />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.SUPPORT]}
                        element={<Supporto />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.RID_PAYMENT]}
                        element={<DomiciliazioneBancaria />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.PLANNER]}
                        element={<Planner />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.CHANGE_ADDRESS]}
                        element={<ChangeAddress />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.RECLAMI]}
                        element={<Reclami />}
                    />

                    <Route
                        path={AllRoutes[RoutesEnum.CHANGE_USER_DATA]}
                        element={<ChangeUserData />}
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.REQUEST_LIST]}
                        element={
                            <PrivateRoute
                                hiddenTo={hiddenRolesForOperatorsAndAgents}
                            >
                                <RequestList />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={AllRoutes[RoutesEnum.REQUEST_DETAILS_OPERATOR]}
                        element={
                            <PrivateRoute
                                hiddenTo={hiddenRolesForOperatorsAndAgents}
                            >
                                <RequestDetailsOperator />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route
                    path={`/${AllRoutes[RoutesEnum.LOGIN]}`}
                    element={
                        <PrivateRoute>
                            <Login />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.SIGNUP]}`}
                    element={
                        <PrivateRoute>
                            <Login forceSignup={true} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.PASSWORD_CHANGE]}`}
                    element={<CambioPassword />}
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.MAIL_CONFIRM]}`}
                    element={<MailConfirm />}
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.REGISTRAZIONE]}`}
                    element={<Registrazione />}
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.INFORMATIVA]}`}
                    element={<Informativa />}
                />
                <Route
                    path={`/${AllRoutes[RoutesEnum.AUTO_LOGIN]}`}
                    element={<Autologin />}
                />
                {/* <Route path={`/${AllRoutes[RoutesEnum.COMPONENTS]}`} element={<Components />} /> */}
                <Route path="*" element={<Page404 />} />
            </Routes>
            {showCookies && (
                <Box
                    direction="column"
                    align="center"
                    height="small"
                    style={{
                        position: 'absolute',
                        zIndex: '1',
                        bottom: '0.5vh',
                        width: '100%',
                    }}
                >
                    <Box
                        border={{ color: 'brand', size: 'small' }}
                        elevation="large"
                        round="medium"
                        background="textWhite"
                    >
                        <Box pad={{ top: 'small', right: 'small' }}>
                            <Button
                                plain
                                icon={<FormClose color="brand" size="medium" />}
                                onClick={() => {
                                    container.jwt.saveCookiesStatus(
                                        BooleanValue.true,
                                    )
                                    setShowCookies(false)
                                }}
                                alignSelf="end"
                            />
                        </Box>
                        <Box
                            align="center"
                            justify="center"
                            pad={{ horizontal: '15vw', vertical: 'small' }}
                            gap="small"
                        >
                            <Text
                                weight="bold"
                                size="small"
                                color={theme.global?.colors?.['textBlack']}
                                margin={
                                    size === 'small'
                                        ? { bottom: '2vh' }
                                        : { bottom: '6vh' }
                                }
                            >
                                {strings.terminiDiServizio}
                            </Text>

                            <Box direction="row">
                                <Button
                                    plain
                                    target="_blank"
                                    onClick={() => {
                                        openInNewTab('/informativa')
                                        container.jwt.saveCookiesStatus(
                                            BooleanValue.true,
                                        )
                                        setShowCookies(false)
                                    }}
                                    color="brand"
                                >
                                    <Text
                                        size="medium"
                                        weight="bold"
                                        color={theme.global?.colors?.['brand']}
                                    >
                                        {strings.leggiInformativa}
                                    </Text>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Router>
    )
}

export default Navigation
