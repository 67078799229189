import { ListinoPrezzi } from '../../api/getListinoPrezziGPLAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const getGPLListPrice =
    ({
        puntoGas,
        codiceUtente,
    }: {
        puntoGas: string
        codiceUtente: string
    }): AppAction<ListinoPrezzi> =>
    async (dispatch, _, container) => {
        try {
            return await container.api.getListinoPrezziGPLAPI(
                puntoGas,
                codiceUtente,
            )
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }
export default getGPLListPrice
