export class LocalJWTService {

    loadTokenClient() {
        return localStorage.getItem("UL_TokenClient");
    }

    saveTokenClient(state: string) {
        localStorage.setItem("UL_TokenClient", state);
    }

    loadTokenROP() {
        return localStorage.getItem("UL_TokenROP");
    }

    saveTokenROP(state: string) {
        localStorage.setItem("UL_TokenROP", state);
    }

    loadCookieStatus() {
        return localStorage.getItem("UN_Cookies");
    }

    saveCookiesStatus(state: string) {
        localStorage.setItem("UN_Cookies", state);
    }
}

const JWTProvider = () => {
    return new LocalJWTService()
}

export default JWTProvider

