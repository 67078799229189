import { AutoletturaModel } from '../../../models/models'
import { getStringFromCode, isOperatorOrAgent } from '../../../utilities'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const insertRichiestaAutolettura =
    (auto: AutoletturaModel): AppAction<any> => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        const isOperator = isOperatorOrAgent(getState().user.loggedUser.roles)
        try {

            await container.api.insertRichiestaAutoletturaAPI(auto, isOperator)
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )
            return true
        } catch (e: any) {
            const index = (e.response?.data?.value ?? e.message).indexOf('|')
            const error = index >= 0?(e.response?.data?.value ?? e.message).substring(index+1):(e.response?.data?.value ?? e.message)

            const errorDesc = getStringFromCode(error,getState().main.stringhe,i18n,lan)
            dispatch(
                setNotification({
                    type: 'error',
                    message: errorDesc,
                    show: true,
                }),
            )
            throw e
        }
    }

export default insertRichiestaAutolettura
