import { Allegato } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const deleteAllegatoReclamo =
    (allegato: Allegato): AppAction<boolean> => async (dispatch, getState, container) => {
        try {
            await container.api.deleteAllegatoReclamoAPI(allegato.idAllegato)
            return true
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default deleteAllegatoReclamo
