import { IOCServices } from '..'
import { Roles } from '../../models/Enums'
import { RequestModel } from '../../models/models'
import { isOperatorOrAgent } from '../../utilities'

const loadRequestsAPI = async (
    codiceUtente: string,
    roles: Roles[],
    container?: IOCServices,
): Promise<Array<RequestModel>> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Richiesta/GetRichiesteUtente/${codiceUtente}`
        if (isOperator) {
            api = `/Richiesta/GetRichiesteUtenteAsOperatore/${codiceUtente}`
        }
        const res = await rest.get(api)

        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadRequestsAPI
