import { InvoiceModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadInvoice =
    ({
        codiceUtente = '',
        invoiceNr = '',
    }): AppAction<InvoiceModel> =>
    async (dispatch, getState, container) => {
        try {
            return await container.api.loadInvoiceAPI(
                codiceUtente,
                invoiceNr,
            )
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadInvoice
