import get from 'lodash.get'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useContainer } from '../services'
import { useAppSelector } from '../services/storeProvider'
import { AppStringsType } from '../services/stringProvider'

const useI18n = () => {
    const container = useContainer()
    const strings = container.strings
    const language = useAppSelector((state) => state.common.language)
    const customers = container.customers
    const initialLanguage = get(customers.defaultCustomer.messages, language)
    const canLoadMessages = useRef(true)
    const [currentMessages, setCurrentMessages] =
        useState<AppStringsType>(initialLanguage)
    const loadMessages = useCallback(async () => {
        const actualMessages = await strings(language)
        if (!canLoadMessages.current) {
            return
        }
        setCurrentMessages(actualMessages)
    }, [language, strings])

    useEffect(() => {
        canLoadMessages.current = true
        loadMessages()
        return () => {
            canLoadMessages.current = false
        }
    }, [language, loadMessages])

    return currentMessages
}

export default useI18n
