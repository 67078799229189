import { useEffect, useState, useRef, useCallback } from 'react'
import { useContainer } from '../services'

const useAssets = () => {
    const container = useContainer()
    const customers = container.customers
    const [currentAssets, setCurrentAssets] = useState(
        customers.defaultCustomer.assets,
    )
    const canLoadAssets = useRef(true)

    const loadAssets = useCallback(async () => {
        try {
            const id = container.customers.currentCustomerId
            const { assets } = await container.customers.loadAssetsAsync(id)
            if (!canLoadAssets.current) {
                return
            }
            setCurrentAssets(assets)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }, [container.customers])

    useEffect(() => {
        canLoadAssets.current = true
        loadAssets()
        return () => {
            canLoadAssets.current = false
        }
    }, [loadAssets])

    return currentAssets
}

export default useAssets
