import { IOCServices } from '..'
import { UserModel } from '../../models/models'

const getUserAdminAPI = async (
    userCode: string,
    container?: IOCServices,
): Promise<UserModel> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get(
            `/Utente/GetAnagraficaAmministratore/${userCode}`,
        )

        const data = res.data
        return data.value
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getUserAdminAPI
