import { Box, Text, FileInput, FileInputExtendedProps, BoxProps } from 'grommet'
import BErrorMessage from './BErrorMessage'
import useI18n from '../hooks/useI18n'

const BFileInput = ({
    handleChange,
    handleBlur,
    errors,
    name,
    label,
    values,
    containerProps,
    mandatory,
    ...rest
}: BFileInputProps) => {
    const strings = useI18n()
    return (
        <Box justify="start" margin={{ bottom: '16px' }} {...containerProps}>
            <Box direction="row" justify="between">
                {label && <Text size="small">{label}</Text>}
                {mandatory && <Text size="small">*</Text>}
            </Box>
            <FileInput
                {...rest}
                messages={{
                    browse: strings.browse,
                    dropPrompt: strings.dropDocumentHere,
                    files: strings.uploadedFilesName,
                    dropPromptMultiple: strings.dropDocumentsHere,
                }}
                onChange={(event) => {
                    if (handleChange) {
                        return handleChange(name)({
                            ...event,
                            target: {
                                ...event?.target,
                                value: event?.target.files,
                            },
                        })
                    }
                }}
                onBlur={handleBlur && handleBlur(name)}
            />
            <BErrorMessage name={name} errors={errors} />
        </Box>
    )
}

type BFileInputProps = {
    handleChange: Function
    handleBlur?: Function
    errors: {}
    name: string
    label?: string
    values: {}
    containerProps?: BoxProps
    mandatory?: boolean
} & FileInputExtendedProps

export default BFileInput
