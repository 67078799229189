import { InvoiceModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_INVOICES } from './types'

type LoadInvoicesType = {
    codiceUtente: string
    puntoGas?: string
}

const loadInvoices =
    ({ codiceUtente, puntoGas }: LoadInvoicesType): AppAction<InvoiceModel[]> =>
    async (dispatch, getState, container) => {
        const roles = getState().user.loggedUser.roles
        try {
            const invoices = await container.api.loadInvoicesAPI(
                codiceUtente,
                puntoGas,
                roles,
            )

            dispatch({ type: SET_INVOICES, payload: invoices })
            return invoices
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadInvoices
