import { Box, BoxExtendedProps } from 'grommet'
import useTheme from '../hooks/useTheme'

const BSeparator = ({ direction = 'horizontal', color, size, margin, containerProps }: BSeparatorProps) => {
    const theme = useTheme()
    const currentColor = color || theme.global?.colors?.separator
    if (direction === 'vertical') {
        return (
            <Box
                background={currentColor}
                height={{ min: '100%' }}
                width={size || '1px'}
                margin = {margin}
                {...containerProps}
            />
        )
    }
    return (
        <Box
            background={currentColor}
            pad={{ horizontal: 'medium' }}
            width={{ min: '100%' }}
            height={size || '1px'}
            margin = {margin}
            {...containerProps}
        />
    )
}

export default BSeparator

interface BSeparatorProps {
    direction?: 'horizontal' | 'vertical'
    color?: string
    size?: string
    margin?: {}
    containerProps?: BoxExtendedProps
}
