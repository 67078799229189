import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'

const recoverPassword: AppAsyncAction<any> =
    (values: { codiceUtente: string }) =>
    async (
        dispatch,
        getState,
        container
    ) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {
            const done = await container.api.recoverPasswordAPI(values)

            if (done) {
                dispatch(
                    setNotification({
                        type: 'info',
                        message: i18n.mailSentToUser,
                        show: true,
                    }),
                )
            }
            else {
                throw new Error
            }
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.mailSentToUser,
                    show: true,
                }),
            )
            throw e
        }
    }

export default recoverPassword

