import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_CONFIG,
    SET_TOKENCLIENT,
    SET_STRINGHE,
    SET_REQUESTTYPES,
    SET_PUBLIC_PARAMS,
    SET_TP_COOKIE,
    SET_COMPANY_INFO,
    SET_CLIENT_INFO,
    LOGIN,
} from './types'
import { formatDate, isOperatorOrAgent } from '../../../utilities'
import { TipiPagina } from '../../../models/Enums'
import logout from './logout'
import setSelectedUser from './setSelectedUsers'

const keepAlive: AppAsyncAction<any> =
    () => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        let tokenClient = container.jwt.loadTokenClient()
        let tokenROP = container.jwt.loadTokenROP()

        const location = window.location.hostname

        try {
            const restID = container.restID
            const restAPI = container.rest

            const apiversion = location === 'localhost' && restAPI.defaults.baseURL?.includes('localhost')
                ? { identityUrl: restAPI.defaults.baseURL }
                : await container.api.getApiVersionAPI()

            restID.defaults.baseURL = apiversion.identityUrl

            dispatch({
                type: SET_CONFIG, payload: {
                    apiUrl: restAPI.defaults.baseURL,
                    identityUrl: restID.defaults.baseURL,
                }
            })

            if (!tokenROP) {
                tokenClient = await container.api.richiestaTokenClientAPI()

                restAPI.defaults.headers.common['Authorization'] = `Bearer ${tokenClient}`
                restID.defaults.headers.common['Authorization'] = `Bearer ${tokenClient}`

                dispatch({
                    type: SET_TOKENCLIENT,
                    payload: tokenClient,
                })

                container.jwt.saveTokenClient(tokenClient!)
            } else {
                restAPI.defaults.headers.common['Authorization'] = `Bearer ${tokenROP}`
                restID.defaults.headers.common['Authorization'] = `Bearer ${tokenROP}`
            }

            const stringhe = await container.api.getStringheAPI()

            dispatch({ type: SET_STRINGHE, payload: stringhe })

            const publicParam = await container.api.getCompanyPublicParamsAPI()
            dispatch({ type: SET_PUBLIC_PARAMS, payload: publicParam })

            const companyInfo = await container.api.getCompanyInformationAPI()
            dispatch({ type: SET_COMPANY_INFO, payload: companyInfo })

            const tp_cookie = await container.api.getTipiPaginaAPI(TipiPagina.Cookie)
            dispatch({type: SET_TP_COOKIE, payload: tp_cookie})

            //From now on if i'm not logged in I can exit
            if (!tokenROP) {
                return
            }

            const loggedUser = await container.api.getLoggedUserAPI()

            if (! loggedUser) {
                throw Error
            }

            dispatch({
                type: LOGIN,
                payload: loggedUser,
            })

            // dispatch({ type: SET_USERNAME, payload: loggedUser.username })

            const reqtypes = await container.api.getTipiRichiestaAbilitatiAPI()
            dispatch({
                type: SET_REQUESTTYPES,
                payload: reqtypes,
            })

            if (isOperatorOrAgent(loggedUser.roles)) {
                const selectedClient = container.localStore.loadSelectedClient()

                if (! selectedClient) {
                    return
                }

                dispatch(setSelectedUser(selectedClient))
                return // XXX Check this part
            }


            const user = await container.api.getAnagraficaUtenteAPI(loggedUser.userId)
            user.value.dataNascitaAPI = user.value.dataNascita
            const indirizzo = `${user.value.tipoViaResidenza} ${user.value.viaResidenza} ${user.value.nrCivicoResidenza} ${user.value.comuneResidenza} ${user.value.provinciaResidenza}`
            if (indirizzo.length > 0) {
                user.value.indirizzo = indirizzo
            }

            if (user.value.dataNascita?.length > 0) {
                const date = new Date(user.value.dataNascita)
                let dataNascita = formatDate(date)
                user.value.dataNascita = dataNascita
            }

            dispatch({
                type: SET_CLIENT_INFO,
                payload: user.value,
            })
        } catch (e) {
            dispatch(logout())

            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.userSessionExpired,
                    show: true,
                }),
            )
            throw e
        }
    }

export default keepAlive
