const companyMapping = {
    localhost: { theme: 'DemoEnergia', id: 100 },
    'test-usernext.dotblue.it': { theme: 'DemoEnergia', id: 100 },
    'test-demoutility-un.dotblue.it': { theme: 'DemoUtility', id: 109 },
    'demoutility-un.dotblue.it': { theme: 'DemoUtility', id: 109 },
    'areaclienti.socoplus.it': { theme: 'socoplus', id: 140 },
    'lab-socoplus.dotblue.it': { theme: 'socoplus', id: 140 },
    'test-socoplus.dotblue.it': { theme: 'socoplus', id: 140 },
    'test-socogas.dotblue.it': { theme: 'socogas', id: 151 },
    'test-ecogas.dotblue.it': { theme: 'ecogas', id: 152 },
    'areaclienti.socogas.com': { theme: 'socogas', id: 151 },
    'areaclienti.ecogasspa.it': { theme: 'ecogas', id: 152 },
}

export default companyMapping
