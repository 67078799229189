import { Box, BoxProps, CheckBox, CheckBoxExtendedProps, Text, TextProps } from 'grommet'
import React from 'react'

const BCheckBox = (props: BCheckBoxProps) => {
    return (
        <Box {...props.containerProps}>
            <Box>
                <Text size={props.textSize}>{props.label}</Text>
            </Box>
            <Box>
                <CheckBox
                    disabled={props.disabled}
                    name={props.name}
                    checked={props.checked}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
            </Box>
        </Box>
    )
}

type BCheckBoxProps = {
    textSize: TextProps['size']
    containerProps?: BoxProps
} & CheckBoxExtendedProps

export default BCheckBox
