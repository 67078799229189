import { Box, Image } from 'grommet'
import { Dashboard, Plan } from 'grommet-icons'
import { useNavigate } from 'react-router'
import useI18n from '../../hooks/useI18n'
import useAssets from '../../hooks/useAssets'
import Sidebar from './Sidebar'
import useCurrentPath from '../../hooks/useCurrentPath'
import React from 'react'
import useSize from '../../hooks/useSize'
import { AllRoutes, RoutesEnum } from '../../Navigation'
import useClientInfo from '../../hooks/useClientInfo'
import useIsOperator from '../../hooks/useIsOperator'

const SideNavbar = ({
    name,
}: ULNavbarProps) => {
    const strings = useI18n()
    const navigate = useNavigate()
    const cAssets = useAssets()
    const assets = useAssets()
    const currentPath = useCurrentPath()
    const size = useSize()
    const isOperator = useIsOperator()
    const [selectedClient] = useClientInfo()


    const menuItems = [
        {
            label: strings.home,
            id: 'home',
            icon: <cAssets.HomeIcon />,
        },
        {
            label: strings.forniture,
            id: 'supplies',
            children: [AllRoutes[RoutesEnum.SUPPLY]],
            icon: <cAssets.FornitureIcon />,
        },
        {
            label: strings.bollette,
            id: 'bills',
            children: [AllRoutes[RoutesEnum.BILL]],
            icon: <cAssets.BolletteIcon />,
        },
        {
            label: strings.richieste,
            id: 'requests',
            children: [
                AllRoutes[RoutesEnum.CAMBIO_RECAPITO],
                AllRoutes[RoutesEnum.RID_PAYMENT],
                AllRoutes[RoutesEnum.BILL_BY_EMAIL],
            ],
            icon: <cAssets.RequestsIcon />,
        },
        {
            label: strings.calendario,
            id: 'planner',
            icon: <Plan />,
        },
        {
            label: strings.Autolettura,
            id: 'selfReading',
            icon: <Dashboard />,
        }
    ]
    const menuItemsOperator = [
        {
            label: strings.home,
            id: 'home',
            icon: <cAssets.HomeIcon />,

        },
        {
            label: strings.elencoRichieste,
            id: 'requestList',
            icon: <cAssets.RequestsIcon />,
            children: [
                AllRoutes[RoutesEnum.REQUEST_DETAILS_OPERATOR]
            ]
        },
    ]
    const menuMapping = (menu:Array<typeof menuItems[0]>) =>{
        return  menu.map(it => ({
            label: it.label,
            id: it.id,
            icon: React.cloneElement(
                it.icon,
                {
                    color: currentPath === it.id ? 'brand' : 'menuitems-colorless',
                    size: size === 'large' ? '24px' : '16px',
                    width: size === 'large' ? '24px' : '16px',
                    height: size === 'large' ? '24px' : '16px'
                }
            ),
            active: currentPath === it.id || Boolean(it.children?.includes(currentPath || ""))
        }))
    }

    return (
        <Sidebar
            width={{
                min: size === 'large' ? '300px' : 'small',
                max: '300px'
            }}
            header={
                <Box style={{maxHeight: size !== 'large' ? '60px' : undefined}}>
                    <Image fit="contain" src={assets.logo_sm} />
                </Box>
            }
            disabled={isOperator}
            name={name}
            profileButtonLabel={strings.showProfile}
            onProfileClick={() => navigate('/user')}
            onClick={(id) => {navigate(`/${id}`)}}           
            menu = {
                isOperator && !selectedClient ? menuMapping(menuItemsOperator) : menuMapping(menuItems)
            }

        />
    )
}

type ULNavbarProps = {
    name: string
}

export default SideNavbar
