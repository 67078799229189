import { Box, BoxExtendedProps, Text } from 'grommet'
import get from 'lodash.get'
import useAssets from '../hooks/useAssets'
import useTheme from '../hooks/useTheme'

const BillInfoBox = ({
    title,
    value,
    bookmarkColor,
    bookmark,
    footer,
    ...rest
}: BillInfoBoxProps) => {
    const { global } = useTheme()
    const cAssets = useAssets()
    return (
        <>
            {!!bookmark && (
                <Box style={{ position: 'relative' }}>
                    <cAssets.BookmarkIcon
                        color={get(
                            global?.colors,
                            bookmarkColor || 'brand',
                        )?.toString()}
                        style={{ position: 'absolute', top: 0, right: 40 }}
                    />
                </Box>
            )}
            <Box {...rest}>
                <Box>
                    <Text color="primary_color">
                        {title}
                    </Text>
                    <Text size="small" wordBreak='break-word'>{value}</Text>
                </Box>
                {footer}
            </Box>
        </>
    )
}

type BillInfoBoxProps = {
    title: string
    value: string
    bookmark?: boolean
    bookmarkColor?: string
    footer?: JSX.Element
} & BoxExtendedProps

export default BillInfoBox
