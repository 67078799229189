import { Box, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import useI18n from '../hooks/useI18n'
import { checkAutologinTokenValidityType } from '../services/api/checkAutologinTokenValidityAPI'
import { keepAlive } from '../services/store/actions'
import checkAutologinTokenValidity from '../services/store/actions/checkAutologinTokenValidity'
import loginCrypted from '../services/store/actions/loginCrypted'
import { useAppDispatch } from '../services/storeProvider'

const Autologin = () => {
    const strings = useI18n()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const state = location.state as { path?: string }
    const [searchParams] = useSearchParams()
    const [getMessage,setMessage] = useState('')
    const Token = searchParams.get('Token')

    const loadData = async (submit:checkAutologinTokenValidityType) => {
        try {

            await dispatch(keepAlive())

            const ret = await dispatch(checkAutologinTokenValidity(submit))

            if (ret === undefined) setMessage(strings.APP_MSG_EMAIL_VALIDATION_TOKEN_ERROR)
            else {
                await dispatch(loginCrypted({username: ret.username, password: ret.password}))
                navigate(state?.path ?? '/home')
            }

        } catch (e) {
            setMessage(strings.APP_MSG_EMAIL_VALIDATION_TOKEN_ERROR)
        }
    }

    useEffect(() => {
        const submit = {
            Token: Token ?? ''
        }
        loadData(submit)
    }, [])

    return (
        <Box >
            {(getMessage === undefined || getMessage === '')?
            (
                <Box background={'background-back'} >
                    <Box justify="center" align="center" width="100vw" height="100vh">
                        <Spinner />
                    </Box>
                </Box>
            ):
            (
            <Box

                    width="100vw"
                    height="100vh"
                    align="center"
                    justify="center">
                    <Box
                        animation={{ type: 'fadeIn' }}
                        fill
                        align="center"
                        justify="center">
                        <Box
                            background={{ opacity: 0.8, color: 'background-front' }}
                            elevation="small"
                            round="xsmall"
                            pad="large">
                            <Box margin={{ bottom: 'small' }} justify="center" align="center">
                                <Text size={'xlarge'} > {getMessage} </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
            }

        </Box>
    )
}

export default Autologin
