import { TipiPagina } from '../../../models/Enums'
import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import {
    SET_PUBLIC_PARAMS,
    SET_TP_COOKIE,
    SET_TP_PRIVACY1,
    SET_TP_PRIVACY1SHORT,
    SET_TP_PRIVACY2,
    SET_TP_PRIVACY2SHORT,
    SET_TP_PRIVACY3,
    SET_TP_PRIVACY3SHORT,
    SET_TP_REGISTRA1,
    SET_TP_REGISTRA2,
    SET_TP_REGISTRA3,
    SET_TP_OFFERCHANGE,
} from './types'

const loadPublicParams: AppAsyncAction<any> =
    () => async (dispatch, _, container) => {
        try {
            const params = await container.api.getCompanyPublicParamsAPI()
            dispatch({ type: SET_PUBLIC_PARAMS, payload: params })

            const [
                tp_privacy1,
                tp_privacy2,
                tp_privacy3,
                tp_registra1,
                tp_registra2,
                tp_registra3,
                tp_cookie,
                tp_privacy1short,
                tp_privacy2short,
                tp_privacy3short,
                tp_offertChange,
            ] = await Promise.all([
                container.api.getTipiPaginaAPI(TipiPagina.Privacy1),
                container.api.getTipiPaginaAPI(TipiPagina.Privacy2),
                container.api.getTipiPaginaAPI(TipiPagina.Privacy3),
                container.api.getTipiPaginaAPI(TipiPagina.Registra1),
                container.api.getTipiPaginaAPI(TipiPagina.Registra2),
                container.api.getTipiPaginaAPI(TipiPagina.Registra3),
                container.api.getTipiPaginaAPI(TipiPagina.Cookie),
                container.api.getTipiPaginaAPI(TipiPagina.Privacy1Short),
                container.api.getTipiPaginaAPI(TipiPagina.Privacy2Short),
                container.api.getTipiPaginaAPI(TipiPagina.Privacy3Short),
                container.api.getTipiPaginaAPI(TipiPagina.CambioOfferta),
            ])

            dispatch({ type: SET_TP_PRIVACY1, payload: tp_privacy1 })
            dispatch({ type: SET_TP_PRIVACY2, payload: tp_privacy2 })
            dispatch({ type: SET_TP_PRIVACY3, payload: tp_privacy3 })
            dispatch({ type: SET_TP_REGISTRA1, payload: tp_registra1 })
            dispatch({ type: SET_TP_REGISTRA2, payload: tp_registra2 })
            dispatch({ type: SET_TP_REGISTRA3, payload: tp_registra3 })
            dispatch({ type: SET_TP_COOKIE, payload: tp_cookie })
            dispatch({ type: SET_TP_PRIVACY1SHORT, payload: tp_privacy1short })
            dispatch({ type: SET_TP_PRIVACY2SHORT, payload: tp_privacy2short })
            dispatch({ type: SET_TP_PRIVACY3SHORT, payload: tp_privacy3short })
            dispatch({ type: SET_TP_OFFERCHANGE, payload: tp_offertChange })

            return params
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadPublicParams
