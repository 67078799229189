import { useMemo } from 'react'
import useParameters from './useParameters'
import { TipoUso } from '../models/Enums'
import { useAppSelector } from '../services/storeProvider'

const useAbilitaTipoUsoContatore = () => {
    const parameters = useParameters()
    const codiceTipoUso = useAppSelector(
        (state) => state.supply.selectedSupply?.codiceTipoUso,
    )
    const abilita = useMemo(() => {
        return (
            (parameters.abilitaTipoUsoContatore &&
                codiceTipoUso === TipoUso.Contatore) ||
            !parameters.abilitaTipoUsoContatore
        )
    }, [parameters.abilitaTipoUsoContatore, codiceTipoUso])

    return abilita
}

export default useAbilitaTipoUsoContatore
