import { useState } from 'react'
import * as Yup from 'yup'
import { Box, Button, Heading, Layer, Spinner, Text } from 'grommet'
import { CircleInformation, FormClose, FormPreviousLink, Hide, User, UserAdmin, View } from 'grommet-icons'
import BTextInput from '../BTextInput'
import BButton from '../BButton'
import useI18n from '../../hooks/useI18n'
import useTheme from '../../hooks/useTheme'
import { RegistrationModel } from '../../models/models'
import BFormik from '../BFormik'
import BRadioButtonGroup from '../BRadioButtonGroup'
import BSeparator from '../BSeparator'
import { useAppDispatch, useAppSelector } from '../../services/storeProvider'
import { BooleanValueInt, paramEnum, TipiStringa } from '../../models/Enums'
import { isEmpty } from 'lodash'
import '../TxtArea.css'
import BCloseButton from '../BCloseButton'
import InfoBox from '../InfoBox'
import useSize from '../../hooks/useSize'
import { SET_PWDCOMPLEXITYERROR } from '../../services/store/actions/types'

const RegistrationForm = ({
    loading = false,
    onSubmit,
    onBlur,
    user,
    disabled
}: RegistrationFormProps) => {
    const strings = useI18n()
    const [reveal, setReveal] = useState(false)
    const [revealConfirm, setRevealConfirm] = useState(false)
    const [checked, setChecked] = useState(false)
    const [showModalInfo, setShowModalInfo] = useState(false)
    const [showModal1, setShowModal1] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [showModal3, setShowModal3] = useState(false)

    //const [accepted, setaccepted] = useState(true)
    const [showTerms, setShowTerms] = useState(false)
    const [showGDPR, setShowGDPR] = useState(true)
    const [showPrivacy, setShowPrivacy] = useState(false)

    const main = useAppSelector((state) => state.main)
    const userStore = useAppSelector((state) => state.user.clientInfo)
    const size = useSize()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const publicParam = main.publicParam
    const RichiestaPrivacyRegistrazione = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.RichiestaPrivacyRegistrazione
    )[0]?.valore ?? 0

    const PrivacyAttivitaMarketingPromoInviti = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.PrivacyAttivitaMarketingPromoInviti
    )[0]?.valore ?? 0

    const PrivacyAttivitaMarketing = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.PrivacyAttivitaMarketing
    )[0]?.valore ?? 0

    const PrivacyAnalisiPreferenze = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.PrivacyAnalisiPreferenze
    )[0]?.valore ?? 0

    const AccountPrivacy1 = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.AccountPrivacy1
    )[0]?.valore ?? 0

    const AccountPrivacy2 = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.AccountPrivacy2
    )[0]?.valore ?? 0

    const AccountPrivacy3 = publicParam.filter(
        (it) => +it.idTipoParametroAzienda == paramEnum.AccountPrivacy3
    )[0]?.valore ?? 0

    const schema = Yup.object().shape({
        IDTipoUtente: Yup.number(),
        IDStatoUtente: Yup.number(),
        CodiceUtente: Yup.string(),
        Nominativo: Yup.string(),
        CodiceFiscale: Yup.string(),
        PartitaIVA: Yup.string(),
        Email: Yup.string(),
        Cellulare: Yup.string().nullable(),
        Telefono: Yup.string().nullable(),
        UserName: (showGDPR && RichiestaPrivacyRegistrazione===BooleanValueInt.true)?Yup.string().notRequired():Yup.string().required(strings.mandatoryField),
        Password: Yup.string().test('TestPwd', '', (actualRead, { createError, parent }) => {
            try {

                if (showGDPR && RichiestaPrivacyRegistrazione===BooleanValueInt.true) return true

                if (actualRead === undefined || actualRead === "") {
                    throw Error(strings.mandatoryField)
                }

                if (actualRead.length < 8) {
                    throw Error(strings.lengthFailed)
                }

                if (userStore.isPwdComplexityError) {
                    dispatch({
                        type: SET_PWDCOMPLEXITYERROR,
                        payload: false,
                    })
                    throw Error(strings.complessitaPassword)
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
        PasswordConfirm: (showGDPR && RichiestaPrivacyRegistrazione===BooleanValueInt.true)?Yup.string().notRequired():
            Yup.string()
               .required(strings.mandatoryField)
               .oneOf([Yup.ref('Password'), null], strings.passwordMustMatch),
        Privacy1: PrivacyAttivitaMarketingPromoInviti === '2' ? Yup.string().test('TestPrivacy1', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {

                    throw Error(strings.acceptedMandatory)

                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }

                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired(),

        Privacy2: PrivacyAttivitaMarketing === '2' ? Yup.string().test('TestPrivacy2', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {

                    throw Error(strings.acceptedMandatory)

                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }


                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired(),

        Privacy3: PrivacyAnalisiPreferenze === '2' ? Yup.string().test('TestPrivacy3', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {
                    throw Error(strings.acceptedMandatory)
                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }

                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired(),

        TokenReg: Yup.string(),
        IDRichiesta: Yup.number(),

        Registra1: AccountPrivacy1 === '2' ? Yup.string().test('TestRegistra1', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {
                    throw Error(strings.acceptedMandatory)
                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }

                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired(),

        Registra2: AccountPrivacy2 === '2' ? Yup.string().test('TestRegistra2', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {
                    throw Error(strings.acceptedMandatory)
                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }

                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired(),

        Registra3: AccountPrivacy3 === '2' ? Yup.string().test('TestRegistra3', '', (actualRead, { createError, parent }) => {
            try {

                if (actualRead === undefined || actualRead === "" || actualRead === "0") {
                    throw Error(strings.acceptedMandatory)
                }

                if (actualRead !== "1") {
                    throw Error(strings.acceptedMandatory)
                }

                return true

            } catch (e) {
                const error = e as Error
                return createError({ message: error?.message })
            }
        }) : Yup.string().notRequired()
    })

    return (
        <Box flex>
            <BFormik
                initialValues={{
                    IDTipoUtente: 1,
                    IDStatoUtente: 1,
                    CodiceUtente: user!.CodiceUtente,
                    Nominativo: user!.Nominativo,
                    CodiceFiscale: user!.CodiceFiscale,
                    PartitaIVA: user!.PartitaIVA,
                    Email: user!.Email,
                    Cellulare: user!.Cellulare,
                    Telefono: user!.Telefono,
                    UserName: user!.UserName,
                    Password: user!.Password,
                    PasswordConfirm: '',
                    Privacy1: user!.Privacy1,
                    Privacy2: user!.Privacy2,
                    Privacy3: user!.Privacy3,
                    TokenReg: user!.TokenReg,
                    IDRichiesta: user!.IDRichiesta,
                    Registra1: user!.Registra1,
                    Registra2: user!.Registra2,
                    Registra3: user!.Registra3
                }}
                enableReinitialize
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={true}
                initialErrors={userStore.isPwdComplexityError ? { Password: strings.complessitaPassword } : undefined}
                blurCallback={({ values }) => {
                    if (!disabled) {
                        onBlur(values)
                    }
                }}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
                element={({
                    values,
                    errors,
                    validateForm,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                }) => {

                    return (
                        <Box>



                            <Heading level={3} margin={{bottom:'10px'}}>{strings.RegistrazioneUtente}</Heading>
                            <BSeparator size="2px" margin={{bottom:'10px'}} />

                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="CodiceUtente"
                                    label={strings.codiceUtente}
                                    placeholder={strings.codiceUtentePlaceHolder}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <User size="medium" />
                                        </Box>
                                    )}
                                />

                            </Box>
                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="Nominativo"
                                    label={strings.nominativo}
                                    placeholder={strings.phNominativo}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <User size="medium" />
                                        </Box>
                                    )}
                                />

                            </Box>
                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="Email"
                                    label={strings.email}
                                    placeholder={strings.phEmail}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <User size="medium" />
                                        </Box>
                                    )}
                                />

                            </Box>
                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="UserName"
                                    label={strings.username}
                                    placeholder={strings.phUsername}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <User size="medium" />
                                        </Box>
                                    )}
                                />

                            </Box>
                            <Box direction="row" style={{ position: 'relative' }}>
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="Password"
                                    label={strings.password}
                                    placeholder={strings.phPassword}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    type={reveal ? 'text' : 'password'}
                                    renderIcon={() => (
                                    <Button
                                        icon={
                                            reveal ? (
                                                <View size="medium" />
                                            ) : (
                                                <Hide size="medium" />
                                            )
                                        }
                                        onClick={() => setReveal(!reveal)}
                                    />
                                    )}
                                />

                                <CircleInformation style={{cursor: "pointer", position: "absolute", top: 0, left: 90}} size="22%" onClick={() => {

                                    setShowModalInfo(true)
                                }}
                                />

                            </Box>
                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="PasswordConfirm"
                                    label={strings.repeatPassword}
                                    placeholder={strings.repeatPassword}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    type={revealConfirm ? 'text' : 'password'}
                                    renderIcon={() => (
                                    <Button
                                        icon={
                                            revealConfirm ? (
                                                <View size="medium" />
                                            ) : (
                                                <Hide size="medium" />
                                            )
                                        }
                                        onClick={() => setRevealConfirm(!revealConfirm)}
                                    />
                                    )}
                                />

                            </Box>
                            {showGDPR&&(<Box>
                                <Heading level={3} margin={{bottom:'10px'}}>{strings.condizioniGenerali}</Heading>
                                <BSeparator size="2px" margin={{bottom:'10px'}} />

                                {AccountPrivacy1!==BooleanValueInt.false?(<Box
                                    flex
                                    direction="column"
                                    gap="small"
                                    margin={{bottom: "medium"}}
                                >
                                    <Box direction="column" justify="start" align="start" gap="small">

                                    <Text>
                                    {
                                        main.stringhe
                                        .filter(
                                            (it) =>
                                                it.codice ==
                                                TipiStringa.Registra1,
                                        )[0].valorePredefinitoIT


                                    }
                                    </Text>

                                    <Box style={{maxWidth: "100%"}}><div className='txtArea' dangerouslySetInnerHTML={{__html: main.TP_Registra1}} /></Box>

                                        <BRadioButtonGroup
                                        handleChange={handleChange}

                                        errors={errors}
                                        name={'Registra1'}
                                        values={values}
                                        gap="30px"

                                        options={[
                                            {
                                                label: strings.accetto,
                                                value: "1"
                                            },
                                            {
                                                label: strings.nonAccetto,
                                                value: "2"
                                            }
                                        ]}
                                    />
                                    </Box>

                                </Box>):undefined}


                                {AccountPrivacy2!==BooleanValueInt.false?(<Box
                                    flex
                                    direction="column"
                                    gap="small"
                                    margin={{bottom: "medium"}}

                                >
                                    <Box direction="column" justify="start" align="start" gap="small">

                                    <Text>
                                    {
                                        main.stringhe
                                        .filter(
                                            (it) =>
                                                it.codice ==
                                                TipiStringa.Registra2,
                                        )[0].valorePredefinitoIT


                                    }
                                    </Text>
                                    <Box style={{width: "100%"}}><div className='txtArea180' dangerouslySetInnerHTML={{__html: main.TP_Registra2}} /></Box>
                                    <BRadioButtonGroup
                                        handleChange={handleChange}
                                        //errors={{ status: false }}
                                        errors={errors}
                                        name={'Registra2'}
                                        values={values}
                                        gap="30px"

                                        options={[
                                            {
                                                label: strings.accetto,
                                                value: "1"
                                            },
                                            {
                                                label: strings.nonAccetto,
                                                value: "2"
                                            }
                                        ]}
                                    />
                                    </Box>

                                </Box>):undefined}

                                {AccountPrivacy3!==BooleanValueInt.false?(<Box
                                    flex
                                    direction="column"
                                    gap="small"
                                    margin={{bottom: "medium"}}
                                >
                                    <Box direction="column" justify="start" align="start" gap="small">
                                    <Text>
                                    {
                                        main.stringhe
                                        .filter(
                                            (it) =>
                                                it.codice ==
                                                TipiStringa.Registra3,
                                        )[0].valorePredefinitoIT


                                    }
                                    </Text>
                                    <Box style={{maxWidth: "100%"}}><div className='txtArea' dangerouslySetInnerHTML={{__html: main.TP_Registra3}} /></Box>

                                    <BRadioButtonGroup
                                        handleChange={handleChange}

                                        errors={errors}
                                        name={'Registra3'}
                                        values={values}
                                        gap="30px"

                                        options={[
                                            {
                                                label: strings.accetto,
                                                value: "1"
                                            },
                                            {
                                                label: strings.nonAccetto,
                                                value: "2"
                                            }
                                        ]}
                                    />
                                    </Box>

                                </Box>):undefined}

                            </Box>
                            )}
                            {showModalInfo && (
                                <Layer
                                position="center"
                                onClickOutside={() => setShowModalInfo(false)}
                                onEsc={() => setShowModalInfo(false)}>
                                <Box
                                    gap="small"
                                    width={{ min: 'small' }}
                                    height={{ min: 'small' }}
                                    fill>
                                    <InfoBox
                                        title={strings.complessitaPassword}
                                        content={strings.hintComplessitaPassword}
                                        onBackPress={() =>
                                            setShowModalInfo(false)
                                        }
                                        onDonePress={() =>
                                            setShowModalInfo(false)
                                        }
                                        hideImg={true}
                                    />
                                </Box>
                            </Layer>
                            )}


                            {showPrivacy&&RichiestaPrivacyRegistrazione==BooleanValueInt.true&&(<Box

                            ><Box
                                flex
                                direction="column"
                                gap="small"
                            >
                                <Heading level={3} margin={{bottom:'10px'}}>{strings.privacy}</Heading>
                                <BSeparator size="2px" margin={{bottom:'10px'}} />
                                {PrivacyAttivitaMarketingPromoInviti !== "0"&&(<Box direction="row" justify="between" align="center" pad={{top: "25px",bottom: '25px'}}>
                                <Box direction="row" style={{maxWidth: "50%"}} width={size=="small"?"60%":"large"} align="center"><div dangerouslySetInnerHTML={{__html: main.TP_Privacy1Short}} />


                                <CircleInformation style={{cursor: "pointer"}} size="30%" onClick={() => {
                                        setShowModal1(true)
                                    }}/>

                                </Box>
                                    <BRadioButtonGroup

                                    handleChange={(name: string) =>

                                        (e: any) => {

                                            handleChange(name)(e)

                                        }
                                    }


                                    errors={errors}
                                    name={'Privacy1'}
                                    values={values}
                                    gap="30px"
                                    containerProps={{ margin: { right: 'large' } }}
                                    options={[
                                        {
                                            label: strings.accetto,
                                            value: "1"
                                        },
                                        {
                                            label: strings.nonAccetto,
                                            value: "2"
                                        }
                                    ]}
                                />
                                </Box>)}
                                {PrivacyAttivitaMarketing !== "0"&&(<Box direction="row" justify="between" align="center" pad={{top: "25px",bottom: '25px'}}>
                                <Box direction="row" style={{maxWidth: "50%"}} width={size=="small"?"60%":"large"}><div dangerouslySetInnerHTML={{__html: main.TP_Privacy2Short}} />
                                    <CircleInformation style={{cursor: "pointer"}} size="30%" onClick={() => {
                                        setShowModal2(true)
                                    }}/>
                                    </Box>
                                    <BRadioButtonGroup

                                        handleChange={(name: string) =>

                                            (e: any) => {

                                                handleChange(name)(e)

                                            }
                                        }

                                        errors={errors}
                                        name={'Privacy2'}
                                        values={values}
                                        gap="30px"
                                        containerProps={{ margin: { right: 'large' } }}
                                        options={[
                                            {
                                                label: strings.accetto,
                                                value: "1"
                                            },
                                            {
                                                label: strings.nonAccetto,
                                                value: "2"
                                            }
                                        ]}
                                    />
                                </Box>)}
                                {PrivacyAnalisiPreferenze !== "0"&&(
                                <Box direction="row" justify="between" align="center" pad={{top: "25px",bottom: '25px'}}>
                                    <Box direction="row" style={{maxWidth: "50%"}} width={size=="small"?"60%":"large"}><div dangerouslySetInnerHTML={{__html: main.TP_Privacy3Short}} />
                                    <CircleInformation style={{cursor: "pointer"}} size="30%" onClick={() => {
                                        setShowModal3(true)
                                    }}/>
                                    </Box>
                                    <BRadioButtonGroup

                                        handleChange={(name: string) =>

                                            (e: any) => {

                                                handleChange(name)(e)

                                            }
                                        }

                                        errors={errors}
                                        name={'Privacy3'}
                                        values={values}
                                        gap="30px"
                                        containerProps={{ margin: { right: "large" } }}
                                        options={[
                                            {
                                                label: strings.accetto,
                                                value: "1"
                                            },
                                            {
                                                label: strings.nonAccetto,
                                                value: "2"
                                            }
                                        ]}
                                    />
                                </Box>)}
                            </Box>
                            {showModal1 && (
                                <Layer
                                    position="center"

                                    onClickOutside={() => setShowModal1(false)}
                                    onEsc={() => setShowModal1(false)}>
                                <Box pad="small" round="small">
                                <Box direction="column">

                                <BCloseButton size="medium" onClick={() => setShowModal1(false)} />


                                <Heading level={3} margin={{horizontal: "0px"}}>{strings.privacy1claus}</Heading>
                                </Box>

                                <Box
                                    flex
                                    direction="column"
                                    gap="small"
                                >
                                    <Box direction="column" justify="start" align="start">

                                        <Box style={{width: "100%"}}><div className='txtArea' dangerouslySetInnerHTML={{__html: main.TP_Privacy1}} /></Box>

                                    </Box>







                                    </Box>
                                    </Box>
                                </Layer>
                        )}
                        {showModal2 && (
                                <Layer
                                    position="center"

                                    onClickOutside={() => setShowModal2(false)}
                                    onEsc={() => setShowModal2(false)}>
                                <Box pad="small" round="small">
                                <Box direction="column">

                                <BCloseButton size="medium" onClick={() => setShowModal2(false)} />

                                <Heading level={3} margin={{horizontal: '0px'}}>{strings.privacy2claus}</Heading>
                                </Box>

                                <Box
                                    flex
                                    direction="column"
                                    gap="small"
                                >
                                    <Box direction="column" justify="start" align="start">

                                        <Box style={{width: "100%"}}><div className='txtArea' dangerouslySetInnerHTML={{__html: main.TP_Privacy2}} /></Box>

                                    </Box>


                                    </Box>
                                    </Box>
                                </Layer>
                        )}
                        {showModal3 && (
                                <Layer
                                    position="center"

                                    onClickOutside={() => setShowModal3(false)}
                                    onEsc={() => setShowModal3(false)}>
                                <Box pad="small" round="small">
                                <Box direction="column">

                                <BCloseButton size="medium" onClick={() => setShowModal3(false)} />

                                <Heading level={3} margin={{horizontal:'0px'}}>{strings.privacy3claus}</Heading>
                                </Box>

                                <Box
                                    flex
                                    direction="column"
                                    gap="small"
                                >
                                    <Box direction="column" justify="start" align="start">

                                        <Box style={{width: "100%"}}><div className='txtArea' dangerouslySetInnerHTML={{__html: main.TP_Privacy3}} /></Box>

                                    </Box>







                                    </Box>
                                    </Box>
                                </Layer>
                        )}

                            </Box>)}


                            {
                            RichiestaPrivacyRegistrazione===BooleanValueInt.false && (
                                <Button
                                primary
                                color="primary_color"
                                focusIndicator={false}
                                style={{
                                    width:"100%",
                                    height:"50px",
                                    marginTop: "20px",
                                }}

                                label={strings.registrati}
                                onClick={async () => {
                                    try {
                                        const res = await validateForm()
                                        const isValid = isEmpty(res)
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    } catch (e) {

                                    }
                                }}
                            />
                            )
                            }




                            {showGDPR && RichiestaPrivacyRegistrazione===BooleanValueInt.true ?(<Box direction="row">

                                <Button
                                    style={{
                                        width:"100%",
                                        height:"50px",
                                        marginTop: "20px",
                                        color: theme.global?.colors?.['textWhite']?.toString(),
                                        cursor: "pointer"
                                    }}

                                    onClick={async () => {//validate gdpr
                                        try {

                                            const res = await validateForm()
                                            delete res.UserName
                                            delete res.Password
                                            delete res.PasswordConfirm
                                            delete res.Privacy1
                                            delete res.Privacy2
                                            delete res.Privacy3

                                            const isValid = isEmpty(res)
                                            if (isValid) {
                                                setShowGDPR(false)
                                                setShowPrivacy(true)
                                            }
                                        } catch (e) {

                                        }
                                    }}
                                    icon={
                                        loading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <UserAdmin color="textWhite"/>
                                        )
                                    }
                                    label={strings.goToPrivacy}
                                    primary
                                />

                            </Box>):undefined}

                            {showPrivacy && RichiestaPrivacyRegistrazione===BooleanValueInt.true ?(<Box direction="row" gap="medium">

                                <Button

                                    style={{
                                        width:"50%",

                                        height:"50px",
                                        marginTop: "20px",
                                        color: theme.global?.colors?.['textWhite']?.toString(),
                                        cursor: "pointer"
                                    }}

                                    onClick={() => {
                                        setShowGDPR(true)
                                        setShowPrivacy(false)

                                    }}
                                    icon={
                                        loading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <FormPreviousLink color="textWhite"/>
                                        )
                                    }
                                    label={strings.backToCondizioniGenerali}
                                    primary
                                />

                                <Button
                                primary
                                color= "primary_color"
                                focusIndicator={false}
                                style={{
                                    width:"70%",
                                    height:"50px",
                                    marginTop: "20px",
                                }}
                                label={strings.registrati}
                                onClick={async () => {
                                    try {
                                        const res = await validateForm()

                                        const isValid = isEmpty(res)
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    } catch (e) {

                                    }
                                }}
                            />
                            </Box>):undefined}



                        {showTerms?(
                            <Layer

                            position="center"

                            onClickOutside={() =>
                                setShowTerms(false)
                            }

                            onEsc={() =>
                                setShowTerms(false)
                            }>


                                <Box

                                    pad="medium"

                                    gap="small"

                                    width={{ min: 'small' }}

                                    height={{ min: 'small' }}

                                    fill
                                    >
                                    <Box
                                        height="30px"
                                        round="small"
                                        pad="small"
                                        style={{
                                            backgroundColor:theme.global?.colors?.['brand']?.toString(),
                                            color: theme.global?.colors?.['textWhite']?.toString()
                                        }}
                                        align="start"
                                        justify='center'

                                    >
                                        {strings.INFO}
                                    </Box>
                                    <Box>
                                        {strings.terminiDiServizio.split('\n').map((it,index) => (
                                                            <Box key={index}>{it}</Box>
                                                        ))}
                                    </Box>
                                    <Box
                                        width="100%"
                                        direction="row-reverse"
                                        margin={{top:"30px"}}
                                        >
                                        <Button
                                            primary
                                            label={strings.chiudi}
                                            icon={<FormClose color={theme.global?.colors?.['textWhite']?.toString()} />}
                                            onClick={() =>
                                                setShowTerms(false)
                                            }

                                        />
                                    </Box>


                                </Box>

                            </Layer>
                        ):(
                            <></>
                        )

                        }
                        </Box>

                    )
                }}
            />
        </Box>
    )

}

interface RegistrationFormProps {
    loading: boolean
    onSubmit: (user: RegistrationModel) => void | Promise<RegistrationModel> | Promise<void>
    user?: RegistrationModel
    onBlur: (user: RegistrationModel) => any
    disabled: boolean
}

export default RegistrationForm
