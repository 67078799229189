import { APIReturnTypes } from '../../../models/Enums'
import { RichiediPinUtenteType } from '../../api/richiediPinUtenteAPI'
import { AppAction } from '../../storeProvider'
import setModal from './setModal'
import setNotification from './setNotification'
import { SET_NEEDDATA_REGISTRATION } from './types'

const richiediPinUtenteEmptyMail =
    (req: RichiediPinUtenteType): AppAction<any> => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        try {

            const outputRichiesta = await container.api.richiediPinUtenteAPI(req)

            dispatch({ type: SET_NEEDDATA_REGISTRATION, payload: outputRichiesta.ApiReturnType === APIReturnTypes.OKButNeedData })

            if (outputRichiesta.ApiReturnType == APIReturnTypes.OK) {
                dispatch(
                    setModal({
                        type: 'info',
                        message: outputRichiesta.ErrorRegistrationNoEmail ? i18n.successRequestRegistrationNoMail : i18n.successRequestRegistration,
                        show: true,
                    }),
                )
                dispatch({ type: SET_NEEDDATA_REGISTRATION, payload: false })
            }
            else {
                dispatch(
                    setNotification({
                        type: 'error',
                        message: i18n.errorMessagePleaseRetry,
                        show: true,
                    }),
                )

            }
        } catch (e: any) {

        }
    }

export default richiediPinUtenteEmptyMail
