import * as Yup from 'yup'
import { Box, Button, Grid, Text } from 'grommet'
import { CircleInformation } from 'grommet-icons';
import useI18n from '../../hooks/useI18n'
import {
    UserModel,
    SupplyModel,
    DeliveryTypeModel,
    FatturaViaMailModel,
} from '../../models/models'
import {
    BooleanValue,
    paramEnum,
    StatiFornitura,
    TipoInvio,
} from '../../models/Enums'
import BFormik from '../BFormik'
import { useAppSelector } from '../../services/storeProvider'
import useRequiredFields from '../../hooks/useRequiredFields'
import { AppStringsType } from '../../services/stringProvider'
import BTextInput from '../BTextInput'
import BSelect from '../BSelect'
import BRadioButtonGroup from '../BRadioButtonGroup'
import useTheme from '../../hooks/useTheme'
import useSize from '../../hooks/useSize'
import { getBoolParam } from '../../utilities'
import BCheckBox from '../BCheckBox'

const generateSchema = (strings: AppStringsType) =>
    Yup.object().shape({
        FatEMail: Yup.string().required(strings.mandatoryField).matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,strings.recoverInvalidEmail),
        FatEMail2: Yup.string().matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,strings.recoverInvalidEmail),
        FatEMail3: Yup.string().matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,strings.recoverInvalidEmail),
        FatEMail4: Yup.string().matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,strings.recoverInvalidEmail),
        tipoInvio: Yup.string(),
        Abilitazione: Yup.string(),
        CodiceUtente: Yup.string(),
        PuntiGas: Yup.array(),
        applyToAll: Yup.boolean(),
    })

const FatturaViaMailForm = ({
    loading = false,
    onSubmit,
    onBlur,
    user,
    supply,
    supplies,
    tipiInvio,
    disabled = false,
    numeroCampi,
}: FatturaViaMailProps) => {
    const strings = useI18n()
    const schema = generateSchema(strings)
    const theme = useTheme()
    const isRequired = useRequiredFields(schema)
    const size = useSize()
    const main = useAppSelector((state) => state.main)
    const visibileMisto = getBoolParam(
        main.publicParam,
        paramEnum.WEB_VisibilitaModalitaInvio,
    )

    return (
        <Box flex>
            <BFormik
                initialValues={{
                    FatEMail: supply?.email1 ?? '',
                    FatEMail2:
                        numeroCampi > 1 && supply?.email2 ? supply?.email2 : '',
                    FatEMail3:
                        numeroCampi > 2 && supply?.email3 ? supply?.email3 : '',
                    FatEMail4:
                        numeroCampi > 3 && supply?.email4 ? supply?.email4 : '',
                    tipoInvio: !supply?.tipoInvio
                        ? TipoInvio.SoloMail
                        : supply.tipoInvio?.toString(), //getValues?.TipoInvio ?? 0,
                    Abilitazione: BooleanValue.true, //Always true because we want to preselect "Edit" if is enabled o "Activate" if is disabled
                    CodiceUtente:
                        supply?.codiceUtente ?? user?.codiceUtente ?? '',
                    PuntiGas: supply ? [supply?.puntoGas] : [],
                    applyToAll: false,
                }}
                enableReinitialize
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={true}
                blurCallback={({ values }) => {
                    if (!disabled) {
                        onBlur?.({
                            FatEMail: values.FatEMail,
                            FatEMail2: values.FatEMail2,
                            FatEMail3: values.FatEMail3,
                            FatEMail4: values.FatEMail4,
                            tipoInvio: values.tipoInvio,
                            CodiceUtente: values.CodiceUtente,
                            PuntiGas: values.PuntiGas,
                            Abilitazione:
                                values.Abilitazione === BooleanValue.true,
                        })
                    }
                }}
                onSubmit={(values) => {
                    onSubmit({
                        FatEMail: values.FatEMail,
                        FatEMail2: values.FatEMail2,
                        FatEMail3: values.FatEMail3,
                        FatEMail4: values.FatEMail4,
                        tipoInvio: values.tipoInvio,
                        CodiceUtente: values.CodiceUtente,
                        PuntiGas: values.PuntiGas,
                        Abilitazione: values.Abilitazione === BooleanValue.true,
                    })
                }}
                element={({
                    values,
                    errors,
                    validateForm,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                }) => {
                    return (
                        <Box gap="small">
                            <Box
                                flex
                                gap="small"
                                direction="row"
                                align="end"
                                wrap
                            >
                                <BRadioButtonGroup
                                    handleChange={handleChange}
                                    errors={{ status: false }}
                                    name="Abilitazione"
                                    values={values}
                                    gap="30px"
                                    options={[
                                        {
                                            label: supply?.servizioMail
                                                ? strings.modifica
                                                : strings.abilitazione,
                                            value: BooleanValue.true,
                                        },
                                        {
                                            label: strings.revoca,
                                            value: BooleanValue.false,
                                        },
                                    ]}
                                />
                            </Box>
                            <Box
                                    direction="row"
                                   
                                 
                                    align="center"
                                >
                            <Text size="medium" weight={'lighter'}>
                            <CircleInformation style={{marginRight:'5px',verticalAlign:'middle'}} size='small'/>
                                {strings.emailPec}                             
                            </Text>
                           </Box>
                            <Grid
                                columns={{
                                    count: 2,
                                    size: 'auto',
                                }}
                                gap="small"
                            >
                                <BTextInput
                                    fast
                                    disabledOutline={
                                        disabled ||
                                        values.Abilitazione ===
                                            BooleanValue.false
                                    }
                                    disabled={
                                        disabled ||
                                        values.Abilitazione ===
                                            BooleanValue.false
                                    }
                                    containerProps={{ flex: true }}
                                    name="FatEMail"
                                    mandatory={
                                        isRequired('FatEMail') &&
                                        values.Abilitazione ===
                                            BooleanValue.true
                                    }
                                    label={strings.email1}
                                    placeholder={strings.phEmail1}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                />

                                {numeroCampi > 1 && (
                                    <BTextInput
                                        fast
                                        disabledOutline={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        disabled={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        containerProps={{ flex: true }}
                                        name="FatEMail2"
                                        mandatory={isRequired('FatEMail2')}
                                        label={strings.email2}
                                        placeholder={strings.phEmail2}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                    />
                                )}
                                {numeroCampi > 2 && (
                                    <BTextInput
                                        fast
                                        disabledOutline={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        disabled={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        containerProps={{ flex: true }}
                                        name="FatEMail3"
                                        mandatory={isRequired('FatEMail3')}
                                        label={strings.email3}
                                        placeholder={strings.phEmail3}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                    />
                                )}

                                {numeroCampi > 3 && (
                                    <BTextInput
                                        fast
                                        disabledOutline={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        disabled={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        containerProps={{ flex: true }}
                                        name="FatEMail4"
                                        mandatory={isRequired('FatEMail4')}
                                        label={strings.email4}
                                        placeholder={strings.phEmail4}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                    />
                                )}
                            </Grid>

                            {visibileMisto && (
                                <Box
                                    flex
                                    gap="small"
                                    direction="row"
                                    align="end"
                                    wrap
                                    width={size === 'small' ? '100%' : '50%'}
                                >
                                    <BSelect
                                        searchable
                                        disabled={
                                            disabled ||
                                            values.Abilitazione ===
                                                BooleanValue.false
                                        }
                                        containerProps={{ flex: true }}
                                        label={strings.modalitaInvio}
                                        placeholder={strings.modalitaInvio}
                                        name="tipoInvio"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        mandatory={isRequired('tipoInvio')}
                                        values={values}
                                        errors={errors}
                                        clear={false}
                                        options={tipiInvio.map((it) => ({
                                            label: it.DeliveryType,
                                            value: it.DeliveryTypeCode,
                                        }))}
                                        labelKey="label"
                                        valueKey={{
                                            key: 'value',
                                            reduce: true,
                                        }}
                                    />
                                </Box>
                            )}

                            <Box margin={{ top: '15px' }}>
                                <BCheckBox
                                    label={strings.tutteLeForniture}
                                    onChange={(event) => {
                                        const { target } = event
                                        if (!supply) {
                                            return
                                        }

                                        const selectedPuntiGas = []
                                        if (target.checked) {
                                            for (const it of supplies || []) {
                                                if (
                                                    it.idStatoFornitura !==
                                                    StatiFornitura.Attiva
                                                ) {
                                                    continue
                                                }
                                                selectedPuntiGas.push(
                                                    it.puntoGas,
                                                )
                                            }
                                        } else {
                                            selectedPuntiGas.push(
                                                supply.puntoGas,
                                            )
                                        }
                                        setFieldValue(
                                            'PuntiGas',
                                            selectedPuntiGas,
                                        )
                                    }}
                                    name="applyToAll"
                                    textSize="medium"
                                    containerProps={{
                                        direction: 'row-reverse',
                                        alignSelf: 'start',
                                        gap: 'small',
                                    }}
                                />
                            </Box>
                            <Button
                                disabled={loading || supplies?.length === 0}
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    color: theme.global?.colors?.[
                                        'textWhite'
                                    ]?.toString(),
                                    backgroundColor:
                                        theme.global?.colors?.[
                                            'primary_color'
                                        ]?.toString(),
                                    borderColor:
                                        theme.global?.colors?.[
                                            'primary_color'
                                        ]?.toString(),
                                }}
                                onClick={async () => {
                                    try {
                                        if (await validateForm()) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.save}
                                primary
                            />
                        </Box>
                    )
                }}
            />
        </Box>
    )
}

export default FatturaViaMailForm

interface FatturaViaMailProps {
    loading?: boolean
    onBlur?: (fattura: FatturaViaMailModel) => any
    onSubmit: (
        fattura: FatturaViaMailModel,
    ) => void | Promise<void> | Promise<FatturaViaMailModel>
    user?: UserModel
    supply?: SupplyModel
    supplies?: SupplyModel[]
    tipiInvio: DeliveryTypeModel[]
    Icon?: JSX.Element
    buttonLabel?: string
    disabled?: boolean
    numeroCampi: number
}
