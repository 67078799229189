import { Box, Button, Heading } from 'grommet'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import useI18n from '../hooks/useI18n'
import BBackButton from '../components/BBackButton'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useSize from '../hooks/useSize'
import { SupplyModel, UserModel } from '../models/models'

import { useDimension } from '../hooks/useTheme'
import SupplySelector from '../components/SupplySelector'
import DatiContrattoForm from '../components/forms/DatiContrattoForm'
import { NavigateFunction } from 'react-router-dom'
import { Location } from 'react-router'
import BRoundButton from '../components/BRoundButton'

export type DatiContrattoPropsType = {
    showSide?: boolean
}

const DatiContratto = (props: DatiContrattoPropsType) => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const dim = useDimension(size)
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const main = useAppSelector((state) => state.main)
    const navigate = useNavigate()
    const location = useLocation()
    const [showModalRequest, setShowModalRequest] = useState(false)
    const state = location.state as { path?: string }

    return (
        <Box direction={size === 'small' && props.showSide ? 'column' : 'row'}>
            {/* {props.showSide ? (
                <ULNavbar id="datiContratto" name={user.nominativo} />
            ) : undefined} */}
            <Box direction="column" width="100%" pad="small" fill>
                <Box
                    direction="row"
                    margin={{ vertical: 'medium' }}
                    justify="between"
                >
                    {props.showSide ? (
                        <Box>
                            <BBackButton
                                size="small"
                                onClick={() => {
                                    navigate(state?.path ?? '/home')
                                }}
                            />
                        </Box>
                    ) : undefined}
                    {props.showSide ? (
                        <Box width="small">
                            <BRoundButton
                                label={strings.faiUnaRichiesta}
                                onClick={() => {
                                    setShowModalRequest(true)
                                }}
                            />
                        </Box>
                    ) : undefined}
                </Box>
                <Box direction="column">
                    <Heading margin={{ top: '0' }} size="24px">
                        {strings.datiContratto}
                    </Heading>
                    {props.showSide ? <SupplySelector /> : undefined}
                    {ShowSpinnerOrContractData(
                        {
                            navigate: navigate,
                            location: location,
                            user: user,
                            supply: supply.selectedSupply,
                        },
                        props,
                    )}
                </Box>
            </Box>
        </Box>
    )
}

const ShowSpinnerOrContractData = (
    props: ShowSpinnerProps,
    showSideValue: DatiContrattoPropsType,
) => {
    const showSpinner = !props.supply
    const state = props.location as { path?: string }
    if (!showSpinner) {
        return (
            <DatiContrattoForm
                loading={false}
                onBlur={() => {}}
                onSubmit={async (values) => {
                    try {
                        props?.navigate(state?.path ?? '/home')
                    } catch (e) {}
                }}
                user={props.user}
                supply={props.supply!}
                showSide={showSideValue.showSide}
            />
        )
    }
}

export default DatiContratto

export interface ShowSpinnerProps {
    navigate: NavigateFunction
    location?: Location
    user?: UserModel
    supply?: SupplyModel
}
