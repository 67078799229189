import { formatDate } from '../../../utilities'
import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_CLIENT_INFO, SET_REQUESTTYPES } from './types'

const setSelectedUser: AppAsyncAction<any> =
    (userId: string) => async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)
        container.localStore.selectClient(userId)

        try {
            const reqtypes = await container.api.getTipiRichiestaAbilitatiAPI()
            dispatch({
                type: SET_REQUESTTYPES,
                payload: reqtypes,
            })

            const { value: user } = await container.api.getAnagraficaUtenteByCodiceUtenteAPI(userId)

            user.dataNascitaAPI = user.dataNascita

            const indirizzo = `${user.tipoViaResidenza} ${user.viaResidenza} ${user.nrCivicoResidenza} ${user.comuneResidenza} ${user.provinciaResidenza}`
            if (indirizzo.length > 0) {
                user.indirizzo = indirizzo
            }

            if (user.dataNascita?.length > 0) {
                const date = new Date(user.dataNascita)
                let dataNascita = formatDate(date)

                user.dataNascita = dataNascita
            }

            dispatch({
                type: SET_CLIENT_INFO,
                payload: user,
            })
        } catch (e) {
            dispatch(
                setNotification({
                    type: 'error',
                    message: i18n.loginFailedLabel,
                    show: true,
                }),
            )
            throw e
        }
    }

export default setSelectedUser
