import { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Heading, Layer } from 'grommet'
import { CircleInformation, Mail, Phone } from 'grommet-icons'
import BTextInput from '../BTextInput'
import useI18n from '../../hooks/useI18n'
import { UserModel } from '../../models/models'
import BSeparator from '../BSeparator'
import BRadioButtonGroup from '../BRadioButtonGroup'
import { useAppSelector } from '../../services/storeProvider'
import ButtonEditForm from '../ButtonEditForm'
import { paramEnum } from '../../models/Enums'
import BCloseButton from '../BCloseButton'
import useSize from '../../hooks/useSize'
import useIsOperator from '../../hooks/useIsOperator'
import useIsAdmin from '../../hooks/useIsAdmin'

const UserContactsForm = ({
    loading = false,
    onSubmit,
    onBlur,
    user,
}: UserContactsFormProps) => {
    const strings = useI18n()
    const main = useAppSelector((state) => state.main)
    const selectedSupply = useAppSelector(
        (state) => state.supply.selectedSupply,
    )
    const publicParam = main.publicParam

    const PrivacyAttivitaMarketingPromoInviti =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.PrivacyAttivitaMarketingPromoInviti,
        )[0]?.valore ?? 0

    const PrivacyAttivitaMarketing =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.PrivacyAttivitaMarketing,
        )[0]?.valore ?? 0

    const PrivacyAnalisiPreferenze =
        publicParam.filter(
            (it) =>
                +it.idTipoParametroAzienda ===
                paramEnum.PrivacyAnalisiPreferenze,
        )[0]?.valore ?? 0

    const [editContacts, setEditContacts] = useState(false)
    const [editPrivacy, setEditPrivacy] = useState(false)
    const [checkForm, setCheckForm] = useState(false)

    const [showModal1, setShowModal1] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [showModal3, setShowModal3] = useState(false)

    const [checkIsDirtyEmail, setIsDirtyEmail] = useState(false)
    const [checkIsDirtyTel, setIsDirtyTel] = useState(false)
    const [checkIsDirtyCel, setIsDirtyCel] = useState(false)
    const [checkIsDirtyFax, setIsDirtyFax] = useState(false)
    const [checkIsDirtyPec, setIsDirtyPec] = useState(false)
    const isOperator = useIsOperator()
    const isAdmin = useIsAdmin()

    const size = useSize()
    const Schema = Yup.object().shape({
        telefono: checkForm
            ? Yup.string()
                  .matches(/^\d{6,12}$/, strings.telCheck)
                  .notRequired()
            : Yup.string().notRequired(),
        cellulare: checkForm
            ? Yup.string()
                  .matches(/^\d{6,12}$/, strings.cellCheck)
                  .notRequired()
            : Yup.string().notRequired(),
        email: checkForm
            ? Yup.string()
                  .required(strings.mandatoryField)
                  .email(strings.invalidEmailField)
            : Yup.string().notRequired(),
        pec: checkForm
            ? Yup.string()
                  .required(strings.mandatoryField)
                  .email(strings.invalidPecField)
            : Yup.string().notRequired(),
        privacy1:
            PrivacyAttivitaMarketingPromoInviti === '2'
                ? Yup.string().test(
                      'TestPrivacy1',
                      '',
                      (actualRead, { createError, parent }) => {
                          try {
                              if (
                                  actualRead === undefined ||
                                  actualRead === '' ||
                                  actualRead === '0'
                              ) {
                                  throw Error(strings.acceptedMandatory)
                              }

                              if (actualRead !== '1') {
                                  throw Error(strings.acceptedMandatory)
                              }

                              return true
                          } catch (e) {
                              const error = e as Error
                              return createError({ message: error?.message })
                          }
                      },
                  )
                : Yup.string().notRequired(),

        privacy2:
            PrivacyAttivitaMarketing === '2'
                ? Yup.string().test(
                      'TestPrivacy2',
                      '',
                      (actualRead, { createError, parent }) => {
                          try {
                              if (
                                  actualRead === undefined ||
                                  actualRead === '' ||
                                  actualRead === '0'
                              ) {
                                  throw Error(strings.acceptedMandatory)
                              }

                              if (actualRead !== '1') {
                                  throw Error(strings.acceptedMandatory)
                              }

                              return true
                          } catch (e) {
                              const error = e as Error
                              return createError({ message: error?.message })
                          }
                      },
                  )
                : Yup.string().notRequired(),

        privacy3:
            PrivacyAnalisiPreferenze === '2'
                ? Yup.string().test(
                      'TestPrivacy3',
                      '',
                      (actualRead, { createError, parent }) => {
                          try {
                              if (
                                  actualRead === undefined ||
                                  actualRead === '' ||
                                  actualRead === '0'
                              ) {
                                  throw Error(strings.acceptedMandatory)
                              }

                              if (actualRead !== '1') {
                                  throw Error(strings.acceptedMandatory)
                              }

                              return true
                          } catch (e) {
                              const error = e as Error
                              return createError({ message: error?.message })
                          }
                      },
                  )
                : Yup.string().notRequired(),
    })

    return (
        <Formik
            initialValues={{
                nominativo: user?.nominativo || '',
                codiceFiscale: user?.codiceFiscale || '',
                luogoNascita: user?.luogoNascita || '',
                dataNascita: user?.dataNascita || '',
                dataNascitaAPI: user?.dataNascitaAPI || '',
                indirizzo: user?.indirizzo || '',
                fax: user?.fax || '',
                pec: user?.pec || '',
                email: user?.email || '',
                codiceUtente:
                    selectedSupply?.codiceUtente || user?.codiceUtente || '',
                telefono: user?.telefono || '',
                cellulare: user?.cellulare || '',
                tipoViaResidenza: user?.tipoViaResidenza || '',
                viaResidenza: user?.viaResidenza || '',
                nrCivicoResidenza: user?.nrCivicoResidenza || '',
                comuneResidenza: user?.comuneResidenza || '',
                provinciaResidenza: user?.provinciaResidenza || '',
                capResidenza: user?.capResidenza || '',
                privacy1: user?.privacy1 || '',
                privacy2: user?.privacy2 || '',
                privacy3: user?.privacy3 || '',
                orig_telefono: user?.telefono || '',
                orig_cellulare: user?.cellulare || '',
                orig_email: user?.email || '',
                orig_fax: user?.fax || '',
                orig_pec: user?.pec || '',
                orig_privacy1: user?.privacy1 || '',
                orig_privacy2: user?.privacy2 || '',
                orig_privacy3: user?.privacy3 || '',
                isAnagModified: false,
                isPrivacyModified: false,
                idTipoUtente: user?.idTipoUtente || 1,
            }}
            validationSchema={Schema}
            validateOnBlur={true}
            validateOnChange={true}
            onBlur={onBlur}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                return (
                    <Box>
                        <Box gap="xsmall" height={{ min: 'fit-content' }}>
                            <Box direction="row" justify="between" align="end">
                                <Heading level={3} margin={{ bottom: '10px' }}>
                                    {strings.contatti}
                                </Heading>

                                <ButtonEditForm
                                    handleClick={() => {
                                        setEditContacts(!editContacts)
                                        if (!editContacts) {
                                            setEditPrivacy(false)
                                            setCheckForm(true)
                                        }
                                    }}
                                    activated={editContacts}
                                />
                            </Box>
                            <BSeparator
                                size="2px"
                                margin={{ bottom: '10px', top: '5px' }}
                            />
                            <Box direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="telefono"
                                    label={strings.phone}
                                    placeholder={strings.userFormPhone}
                                    handleChange={(name: string) =>
                                        (e: any) => {
                                            handleChange(name)(e)
                                            if (
                                                e.target.value !==
                                                values.orig_telefono
                                            ) {
                                                setIsDirtyTel(true)
                                            } else {
                                                setIsDirtyTel(false)
                                            }
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled={!editContacts}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <Phone size="medium" />
                                        </Box>
                                    )}
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="cellulare"
                                    label={strings.cellPhone}
                                    placeholder={strings.userFormCellPhone}
                                    handleChange={(name: string) =>
                                        (e: any) => {
                                            handleChange(name)(e)
                                            if (
                                                e.target.value !==
                                                values.orig_telefono
                                            ) {
                                                setIsDirtyCel(true)
                                            } else {
                                                setIsDirtyCel(false)
                                            }
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled={!editContacts}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <Phone size="medium" />
                                        </Box>
                                    )}
                                />
                            </Box>
                            <Box flex direction="row" gap="small">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="email"
                                    label={strings.email}
                                    placeholder={strings.userFormEmail}
                                    handleChange={(name: string) =>
                                        (e: any) => {
                                            handleChange(name)(e)
                                            if (
                                                e.target.value !==
                                                values.orig_telefono
                                            ) {
                                                setIsDirtyEmail(true)
                                            } else {
                                                setIsDirtyEmail(false)
                                            }
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled={!editContacts}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <Mail size="medium" />
                                        </Box>
                                    )}
                                />
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="fax"
                                    label={strings.fax}
                                    placeholder={strings.phFax}
                                    handleChange={(name: string) =>
                                        (e: any) => {
                                            handleChange(name)(e)
                                            if (
                                                e.target.value !==
                                                values.orig_telefono
                                            ) {
                                                setIsDirtyFax(true)
                                            } else {
                                                setIsDirtyFax(false)
                                            }
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled={!editContacts}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <Mail size="medium" />
                                        </Box>
                                    )}
                                />
                            </Box>
                            <Box flex direction="row" gap="small" width="50%">
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    name="pec"
                                    label={strings.pec}
                                    placeholder={strings.userFormPec}
                                    handleChange={(name: string) =>
                                        (e: any) => {
                                            handleChange(name)(e)
                                            if (
                                                e.target.value !==
                                                values.orig_telefono
                                            ) {
                                                setIsDirtyPec(true)
                                            } else {
                                                setIsDirtyPec(false)
                                            }
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    disabled={!editContacts}
                                    renderIcon={() => (
                                        <Box margin={{ right: 'small' }}>
                                            <Mail size="medium" />
                                        </Box>
                                    )}
                                />
                            </Box>
                            {!isOperator && !isAdmin && (
                                <Box>
                                    <Box
                                        direction="row"
                                        justify="between"
                                        align="end"
                                    >
                                        <Heading
                                            level={3}
                                            margin={{ bottom: '10px' }}
                                        >
                                            {strings.privacy}
                                        </Heading>
                                        <ButtonEditForm
                                            handleClick={() => {
                                                setEditPrivacy(!editPrivacy)
                                                if (!editPrivacy) {
                                                    setEditContacts(false)

                                                    if (
                                                        values.telefono !==
                                                            values.orig_telefono ||
                                                        values.cellulare !==
                                                            values.orig_cellulare ||
                                                        values.email !==
                                                            values.orig_email ||
                                                        values.fax !==
                                                            values.orig_fax ||
                                                        values.pec !==
                                                            values.orig_fax
                                                    ) {
                                                        setCheckForm(true)
                                                    } else {
                                                        setCheckForm(false)
                                                    }
                                                }
                                            }}
                                            activated={editPrivacy}
                                        />
                                    </Box>

                                    <BSeparator
                                        size="2px"
                                        margin={{ bottom: '10px', top: '5px' }}
                                    />

                                    <Box flex direction="column" gap="small">
                                        {PrivacyAttivitaMarketingPromoInviti !==
                                            '0' && (
                                            <Box
                                                direction={
                                                    size === 'small'
                                                        ? 'column'
                                                        : 'row'
                                                }
                                                justify="between"
                                                align="center"
                                                gap="small"
                                                pad={{
                                                    top: '25px',
                                                    bottom: '25px',
                                                }}
                                            >
                                                <Box
                                                    direction="row"
                                                    width={{
                                                        max:
                                                            size !== 'small'
                                                                ? '50%'
                                                                : undefined,
                                                        width: '100%',
                                                    }}
                                                    gap={
                                                        size === 'small'
                                                            ? 'medium'
                                                            : 'small'
                                                    }
                                                    align="center"
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: main.TP_Privacy1Short,
                                                        }}
                                                    />
                                                    <CircleInformation
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        size="30%"
                                                        onClick={() =>
                                                            setShowModal1(true)
                                                        }
                                                    />
                                                </Box>
                                                <BRadioButtonGroup
                                                    disabled={!editPrivacy}
                                                    handleChange={(
                                                            name: string,
                                                        ) =>
                                                        (e: any) =>
                                                            handleChange(name)(
                                                                e,
                                                            )}
                                                    errors={errors}
                                                    name={'privacy1'}
                                                    values={values}
                                                    gap="30px"
                                                    containerProps={{
                                                        margin: {
                                                            right: 'large',
                                                        },
                                                    }}
                                                    options={[
                                                        {
                                                            label: strings.accetto,
                                                            value: '1',
                                                        },
                                                        {
                                                            label: strings.nonAccetto,
                                                            value: '2',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        )}
                                        {PrivacyAttivitaMarketing !== '0' && (
                                            <Box
                                                direction={
                                                    size === 'small'
                                                        ? 'column'
                                                        : 'row'
                                                }
                                                justify="between"
                                                align="center"
                                                gap="small"
                                                pad={{
                                                    top: '25px',
                                                    bottom: '25px',
                                                }}
                                            >
                                                <Box
                                                    direction="row"
                                                    width={{
                                                        max:
                                                            size !== 'small'
                                                                ? '50%'
                                                                : undefined,
                                                        width: '100%',
                                                    }}
                                                    gap="small"
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: main.TP_Privacy2Short,
                                                        }}
                                                    />
                                                    <CircleInformation
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        size="30%"
                                                        onClick={() =>
                                                            setShowModal2(true)
                                                        }
                                                    />
                                                </Box>
                                                <BRadioButtonGroup
                                                    disabled={!editPrivacy}
                                                    handleChange={(
                                                            name: string,
                                                        ) =>
                                                        (e: any) =>
                                                            handleChange(name)(
                                                                e,
                                                            )}
                                                    errors={errors}
                                                    name={'privacy2'}
                                                    values={values}
                                                    gap="30px"
                                                    containerProps={{
                                                        margin: {
                                                            right: 'large',
                                                        },
                                                    }}
                                                    options={[
                                                        {
                                                            label: strings.accetto,
                                                            value: '1',
                                                        },
                                                        {
                                                            label: strings.nonAccetto,
                                                            value: '2',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        )}
                                        {PrivacyAnalisiPreferenze !== '0' && (
                                            <Box
                                                direction={
                                                    size === 'small'
                                                        ? 'column'
                                                        : 'row'
                                                }
                                                justify="between"
                                                align="center"
                                                gap="small"
                                                pad={{
                                                    top: '25px',
                                                    bottom: '25px',
                                                }}
                                            >
                                                <Box
                                                    direction="row"
                                                    width={{
                                                        max:
                                                            size !== 'small'
                                                                ? '50%'
                                                                : undefined,
                                                        width: '100%',
                                                    }}
                                                    gap="small"
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: main.TP_Privacy3Short,
                                                        }}
                                                    />
                                                    <CircleInformation
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        size="30%"
                                                        onClick={() =>
                                                            setShowModal3(true)
                                                        }
                                                    />
                                                </Box>
                                                <BRadioButtonGroup
                                                    disabled={!editPrivacy}
                                                    handleChange={(
                                                            name: string,
                                                        ) =>
                                                        (e: any) =>
                                                            handleChange(name)(
                                                                e,
                                                            )}
                                                    errors={errors}
                                                    name={'privacy3'}
                                                    values={values}
                                                    gap="30px"
                                                    containerProps={{
                                                        margin: {
                                                            right: 'large',
                                                        },
                                                    }}
                                                    options={[
                                                        {
                                                            label: strings.accetto,
                                                            value: '1',
                                                        },
                                                        {
                                                            label: strings.nonAccetto,
                                                            value: '2',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                            <Button
                                primary
                                color="primary_color"
                                focusIndicator={false}
                                disabled={loading}
                                onClick={async () => {
                                    try {
                                        if (
                                            values.telefono !==
                                                values.orig_telefono ||
                                            values.cellulare !==
                                                values.orig_cellulare ||
                                            values.email !==
                                                values.orig_email ||
                                            values.fax !== values.orig_fax ||
                                            values.pec !== values.orig_pec ||
                                            checkIsDirtyEmail ||
                                            checkIsDirtyTel ||
                                            checkIsDirtyCel ||
                                            checkIsDirtyFax ||
                                            checkIsDirtyPec
                                        ) {
                                            setCheckForm(true)
                                        } else {
                                            setCheckForm(false)
                                        }

                                        if (await validateForm()) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.save}
                            />
                        </Box>

                        {showModal1 && (
                            <Layer
                                position="center"
                                onClickOutside={() => setShowModal1(false)}
                                onEsc={() => setShowModal1(false)}
                            >
                                <Box pad="small" round="small">
                                    <Box direction="column">
                                        <BCloseButton
                                            size="medium"
                                            onClick={() => setShowModal1(false)}
                                        />

                                        <Heading
                                            fill
                                            level={3}
                                            margin={{ horizontal: '0px' }}
                                        >
                                            {strings.privacy1claus}
                                        </Heading>
                                    </Box>

                                    <Box flex direction="column" gap="small">
                                        <Box
                                            direction="column"
                                            justify="start"
                                            align="start"
                                        >
                                            <Box style={{ width: '100%' }}>
                                                <div
                                                    className="txtArea"
                                                    dangerouslySetInnerHTML={{
                                                        __html: main.TP_Privacy1,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Layer>
                        )}
                        {showModal2 && (
                            <Layer
                                position="center"
                                onClickOutside={() => setShowModal2(false)}
                                onEsc={() => setShowModal2(false)}
                            >
                                <Box pad="small" round="small">
                                    <Box direction="column">
                                        <BCloseButton
                                            size="medium"
                                            onClick={() => setShowModal2(false)}
                                        />

                                        <Heading
                                            fill
                                            level={3}
                                            margin={{ horizontal: '0px' }}
                                        >
                                            {strings.privacy2claus}
                                        </Heading>
                                    </Box>

                                    <Box flex direction="column" gap="small">
                                        <Box
                                            direction="column"
                                            justify="start"
                                            align="start"
                                        >
                                            <Box style={{ width: '100%' }}>
                                                <div
                                                    className="txtArea"
                                                    dangerouslySetInnerHTML={{
                                                        __html: main.TP_Privacy2,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Layer>
                        )}
                        {showModal3 && (
                            <Layer
                                position="center"
                                onClickOutside={() => setShowModal3(false)}
                                onEsc={() => setShowModal3(false)}
                            >
                                <Box pad="small" round="small">
                                    <Box direction="column">
                                        <BCloseButton
                                            size="medium"
                                            onClick={() => setShowModal3(false)}
                                        />

                                        <Heading
                                            level={3}
                                            fill
                                            margin={{ horizontal: '0px' }}
                                        >
                                            {strings.privacy3claus}
                                        </Heading>
                                    </Box>

                                    <Box flex direction="column" gap="small">
                                        <Box
                                            direction="column"
                                            justify="start"
                                            align="start"
                                        >
                                            <Box style={{ width: '100%' }}>
                                                <div
                                                    className="txtArea"
                                                    dangerouslySetInnerHTML={{
                                                        __html: main.TP_Privacy3,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Layer>
                        )}
                    </Box>
                )
            }}
        </Formik>
    )
}

interface UserContactsFormProps {
    loading: boolean
    onSubmit: (user: UserModel) => void | Promise<UserModel> | Promise<void>
    user?: UserModel
    onBlur: (user: UserModel) => any
}

export default UserContactsForm
