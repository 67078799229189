import { ThemeType, Box } from 'grommet'
import { FormDown } from 'grommet-icons'

const scale = 0.85

const theme: ThemeType = {
    global: {
        spacing: '24px',
        font: {
            family: 'Manrope',
            size: `${18 * scale}px`,
            height: `${20 * scale}px`,
        },
        colors: {
            brand: '#0a8cff',
            'accent-1': '#83C061',
            'accent-2': '#6BC3E7',
            'accent-3': '#FF9939',
            'accent-4': '#FFBE0B',
            'accent-5': '#FF6863',
            'accent-6': '#9D86BE',
            'accent-7': '#F7B5C2',
            'background-front': '#fff',
            'background-back': '#fafafa',
            'background-contrast': '#eaeaea',
            'background-grey': '#F6F6F6',
            'menuitems-colorless': '#303972',
            disabled: '#C3C3C3',
            'control-border': '#C2C9D1',
            'graph-0': '#FF9939',
            sidebar: '#eff5fa',
            menu: '#1c229c',
            footer: '#DADADA',
            separator: '#eaeaea',
            remove: '#600000',
            ee: '#fac000',
            gas: '#0892d0',
            gpl: '#12A04E',
            ok: '#03C04A',
            ko: '#D0312D',
            warn: '#EED202',
            selected: '#D3F0FF',
            icon: {
                light: 'brand',
                dark: '#fff',
            },
            bSpinner : '#FFFFFF', //colore three-dot-spinner al caricamento iniziale
            secondary_color: '#FCC43E',
            lightred: '#FB7D5B',
            primary_color: '#4f5b66',
            menudark: '#10002B',
            lightblue: '#D3F0FF',
            textGrey: '#B4B4B4',
            lightgrey: '#D5D5D5',
            lightergrey: '#EDEDED',
            lightok: '#A7FFA4',
            lightwarn: '#F7F3C2',
            lightko: '#F9BFBF',
            textLink: '#444444',
            red: '#FF3A3A',
            darkorange: '#F89E18',
            textWhite: '#FFF',
            textBlack: '#000',
            autolettura: '#7765a3',
            fattura: '#3b9199',
            bgPlanner: '#fff',
            text: {
                dark: '#fff',
                light: 'hsla(232, 41%, 32%, 1)',
            },
            consumptionsChartG: '#fac000',
            consumptionsChartEE: '#0892d0',
        },
        focus: {
            outline: {
                size: 'none',
            },
            shadow: {
                size: 'none',
            },
        },
        input: {
            weight: 'light',
            extend: () => `
            &:disabled {
                opacity: 0.6
            }
            `,
        },
    },
    heading: {
        level: {
            '1': {
                small: {
                    size: `${26 * scale}px`,
                    height: `${38 * scale}px`,
                    maxWidth: `${816 * scale}px`,
                },
                medium: {
                    size: `${30 * scale}px`,
                    height: `${42 * scale}px`,
                    maxWidth: `${1200 * scale}px`,
                },
                large: {
                    size: `${48 * scale}px`,
                    height: `${58 * scale}px`,
                    maxWidth: `${1968 * scale}px`,
                },
                xlarge: {
                    size: `${50 * scale}px`,
                    height: `${56 * scale}px`,
                    maxWidth: `${1968 * scale}px`,
                },
            },
        },
    },
    text: {
        xsmall: {
            size: `${12 * scale}px`,
            height: `${18 * scale}px`,
        },
        small: {
            size: `${14 * scale}px`,
            height: `${20 * scale}px`,
        },
        medium: {
            size: `${18 * scale}px`,
            height: `${24 * scale}px`,
        },
        large: {
            size: `${22 * scale}px`,
            height: `${28 * scale}px`,
        },
        xlarge: {
            size: `${26 * scale}px`,
            height: `${32 * scale}px`,
        },
        xxlarge: {
            size: `${34 * scale}px`,
            height: `${40 * scale}px`,
        },
    },
    select: {
        extend: () => `
        [disabled] {
                opacity: 0.6
            }
        }
        `,
    },
    button: {
        padding: { horizontal: `${24 * scale}px`, vertical: `${10 * scale}px` },
        extend: () => `
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
            border-radius: 8px;
        }
        &:active {
            border-radius: 8px;
        }
        &:focus {
            border-radius: 8px;
        }
        `,
    },
    dataTable: {
        icons: {
            expand: () => (
                <Box flex="grow" justify="center">
                    <FormDown style={{ alignSelf: 'center' }} />
                </Box>
            ),
        },
        pinned: {
            header: {
                background: {
                    opacity: 'weak',
                },
                extend: `backdrop-filter: blur(8px);`,
            },
        },
    },
    tab: {
        active: {
            background: 'brand',
            color: '#FFF',
        },
        border: undefined,
        hover: undefined,
        extend: () => `
            border-radius: 12px;
            padding: 12px;
        `,
    },
    icon: {
        size: {
            small: `${14 * scale}px`,
            medium: `${18 * scale}px`,
            large: `${22 * scale}px`,
            xlarge: `${26 * scale}px`,
        },
    },
}

export default theme
