import { Box, Button, DataTable, Heading, Text } from 'grommet'
import { Contract } from 'grommet-icons'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import { RecontractingSupplies, SupplyModel } from '../models/models'
import assets from '../services/customerSpecs/assets/defaultAssets'
import { loadSuppliesRecontracted } from '../services/store/actions'
import autologinCryptedToken from '../services/store/actions/autologinCryptedToken'
import setReadRecontracting from '../services/store/actions/setReadRecontracting'
import { useAppSelector } from '../services/storeProvider'
import BCloseButton from './BCloseButton'
import './BUpload.css'
import IFrame from './IFrame'
import SupplyBox from './SupplyBox'
import useSize from '../hooks/useSize'

type RecontractingModel = {
    puntoGas: string
    serviceType?: number
    productType?: number
    url: string
    loginToken: string
}

const SuppliesRecontracting = ({
    onBackPress,
    recontracting,
}: RecontractingSuppliesProps) => {
    const strings = useI18n()
    const dispatch = useDispatch()
    const [iFrameProps, setiFrameProp] = useState<RecontractingModel>()
    const [isLoadingSuppliesRecontracted, queryRecontractedSupplies] =
        useAppQuery(loadSuppliesRecontracted)
    const [showIFrame, setShowIFrame] = useState(false)
    const supplies = useAppSelector((state) => state.supply.supplies)
    const clientInfo = useAppSelector((state) => state.user.clientInfo)
    const offerChange = useAppSelector((state) => state.main.TP_OfferChange)
    const { username } = useAppSelector((state) => state.user.loggedUser)
    const [loadAutologinCrypted, queryAutologinCrypted] = useAppQuery(
        autologinCryptedToken,
    )
    const size = useSize()

    const filteredSupplies = useMemo(() => {
        if (!recontracting) {
            return []
        }
        return supplies.filter((it) =>
            recontracting.some(({ puntoGas }) => it.puntoGas === puntoGas),
        )
    }, [recontracting, supplies])

    const columns = [
        {
            property: 'fornitura',
            size: 'medium',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.fornitura}
                </Text>
            ),
            render: (item: SupplyModel) => (
                <SupplyBox
                    elevation="0"
                    title={`${
                        item?.tipoServizio === 'E' ? strings.pod : strings.pdr
                    } ${item?.pod}`}
                    width={{ min: 'small' }}
                    margin={{ bottom: 'small' }}
                    description={`${item?.tipoVia} ${item?.via} ${item?.numeroCivico}, ${item?.comune} ${item?.provincia}`}
                    icon={
                        item?.tipoServizio === 'E' ? (
                            <assets.EnergyIcon />
                        ) : item?.tipoServizio === 'GPL' ? (
                            <assets.GplIcon />
                        ) : (
                            <assets.GasIcon />
                        )
                    }
                    key={item?.puntoGas}
                />
            ),
        },
        {
            property: 'action',
            size: 'small',
            render: (item: SupplyModel) => {
                const userDataExists =
                    item.via &&
                    item.comune &&
                    item.cap &&
                    item.provincia &&
                    (clientInfo.codiceFiscale || clientInfo.partitaIVA)
                const rec = recontracting.find(
                    (it) => it.puntoGas === item.puntoGas,
                )
                if (!userDataExists) {
                    return <Text> {strings.datiMancanti} </Text>
                }
                return (
                    <Button
                        primary
                        label={
                            rec?.firmato
                                ? strings.cambioOffertaInCorso
                                : strings.cambioOfferta
                        }
                        disabled={rec?.firmato ? true : false}
                        size="small"
                        icon={<Contract color="white" size="small" />}
                        onClick={async () => {
                            const autologin = await queryAutologinCrypted(
                                username,
                            )
                            if (autologin.redirectURL && !!rec) {
                                setShowIFrame(true)
                                setiFrameProp({
                                    puntoGas: item.puntoGas,
                                    serviceType: rec.idTipoServizio,
                                    productType: rec.idTipoProdotto,
                                    url: autologin.redirectURL,
                                    loginToken: autologin.loginToken,
                                })
                            }
                        }}
                    />
                )
            },
        },
    ]

    return (
        <Box flex="grow" background={'background-front'}>
            {showIFrame && (
                <IFrame
                    title={strings.ricontrattualizzazione}
                    visible={showIFrame}
                    onClose={async () => {
                        setShowIFrame(false)
                        await queryRecontractedSupplies(clientInfo.codiceUtente)
                    }}
                    height={size === 'small' ? 730 : 630}
                    width={size === 'small' ? 1160 : 1160}
                    url={`${iFrameProps?.url}?Token=${iFrameProps?.loginToken}&PuntoGas=${iFrameProps?.puntoGas}&TipoServizio=${iFrameProps?.serviceType}&TipoProdotto=${iFrameProps?.productType}`}
                ></IFrame>
            )}
            <Box pad="medium" gap="medium" direction="row" align="center">
                <BCloseButton
                    onClick={() => {
                        onBackPress()
                        dispatch(setReadRecontracting(true))
                    }}
                    size="medium"
                />
                <Heading level={1}>{strings.leMieForniture}</Heading>
            </Box>
            <Box flex>
                <Box
                    flex
                    justify="between"
                    direction="row"
                    margin={{ horizontal: 'medium' }}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: offerChange,
                        }}
                    />
                </Box>
                <Box background={'background-front'} pad="medium" round="small">
                    <DataTable
                        background={'background-front'}
                        columns={columns}
                        data={filteredSupplies}
                    ></DataTable>
                </Box>
            </Box>
        </Box>
    )
}

type RecontractingSuppliesProps = {
    onBackPress: () => void
    recontracting: Array<RecontractingSupplies>
}

export default SuppliesRecontracting
