import {
    Box,
    Heading,
} from 'grommet'
import { useEffect } from 'react'
import SupportForm from '../components/forms/SupportForm'
import streetTypes from '../models/streetTypes'

import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import SupplySelector from '../components/SupplySelector'
import { ShowSpinnerProps } from './DatiContratto'
import { useNavigate, useLocation } from 'react-router'
import BBackButton from '../components/BBackButton'
import useI18n from '../hooks/useI18n'
import insertRichiestaAssistenza from '../services/store/actions/insertiRichiestaAssistenza'
import getProvinceComuni from '../services/store/actions/getProvinceComuni'
import useAppQuery from '../hooks/useAppQuery'
import getSottoTipiRichiesta from '../services/store/actions/getSottoTipiRichiesta'
import useSize from '../hooks/useSize'
import { RichiestaAssistenzaModel } from '../models/models'

const Supporto = () => {
    const user = useAppSelector((state) => state.user.clientInfo)
    const strings = useI18n()
    const supply = useAppSelector((state) => state.supply)
    const navigate = useNavigate()
    const location = useLocation()
    const size = useSize()
    const state = location.state as { path?: string }
    return (
        <Box flex pad="small">
            <Box direction='row' flex="grow" gap='small' align='center'>
                <BBackButton
                    size="small"
                    onClick={() => {
                        navigate(state?.path ?? '/requests')
                    }}
                />
                <Heading level={3}>
                    {strings.richiediAssistenza}
                </Heading>
                </Box>
            <Box gap='small' flex="grow">

                <SupplySelector />
                {ShowSpinnerOrContractData({
                    navigate: navigate,
                    location: location,
                    user: user,
                    supply: supply.selectedSupply,
                })}
            </Box>
        </Box>
    )
}

const ShowSpinnerOrContractData = (props: ShowSpinnerProps) => {

    const [isLoadingProvinces, queryProvinces] = useAppQuery(getProvinceComuni)
    const [isLoadingRequestType, queryRequest] = useAppQuery(
        getSottoTipiRichiesta,
    )

    const provincesCities = useAppSelector(
        (state) => state.main.provincesCities,
    )
    const requestType = useAppSelector((state) => state.main.typeRequests)
    useEffect(() => {
        queryProvinces()
        queryRequest()
    }, [])
    const state = props.location as { path?: string }
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const showSpinner = !props.supply
    if (!showSpinner) {
        const selectedSuply = props.supply!
        return (
            <Box margin={{ top: 'large' }}>
                <SupportForm
                    initialValues={{
                        name: selectedSuply.cognomeNomeRecapito || '',
                        note: '',
                        subrequest: '',
                        description: '',
                        preferredContactTime: '',
                        phone: '',
                        email:
                            selectedSuply.email1 ||
                            selectedSuply.email2 ||
                            selectedSuply.email3 ||
                            selectedSuply.email4,
                        address: selectedSuply.via || '',
                        cap: selectedSuply.cap!,
                        number:
                            (selectedSuply.nrCivicoRecapito as string) || '',
                        barrato: selectedSuply.esponenteCivicoRecapito || '',
                        province: selectedSuply.provinciaRecapito || '',
                        city: selectedSuply.comuneRecapito,
                        cityIstat: '',
                        streetType: selectedSuply.tipoVia || '',
                    }}
                    streetTypes={streetTypes}
                    cities={provincesCities}
                    availableRequests={requestType.map((it) => ({
                        label: it.sottoTipoRichiesta,
                        value: it?.idSottoTipoRichiesta?.toString(),
                    }))}
                    onSubmit={async function (
                        values: RichiestaAssistenzaModel,
                    ) {
                        if (!props.supply) throw new Error('supply null')
                        try {
                            await dispatch(
                                insertRichiestaAssistenza(values, props.supply),
                            )
                            navigate(state?.path ?? '/requests')
                        } catch (e) {}
                    }}
                    isLoading={isLoadingProvinces}
                />
            </Box>
        )
    }
}

export default Supporto
