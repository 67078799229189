import { Box, Collapsible, Spinner, Text } from 'grommet'
import { useEffect, useState, useCallback } from 'react'
import useI18n from '../hooks/useI18n'
import { TipiUtente } from '../models/Enums'
import { Allegato, ClaimResponseModel, RequestModel } from '../models/models'
import assets from '../services/customerSpecs/assets/defaultAssets'
import { useAppSelector } from '../services/storeProvider'
import { formatDateString, getDescTipoRichiesta } from '../utilities'
import BCaretCollapsible from './BCaretCollapsible'
import BCloseButton from './BCloseButton'
import UtilitiesRichiestaReclamo from './UtilitiesRichiestaReclamo'
import BBackButton from './BBackButton'
import RequestProgressBox from './RequestProgressBox'
import FilesTable from './FilesTable'
import useAppQuery from '../hooks/useAppQuery'
import {
    downloadAllegatoReclamo,
    getAllegatiReclamo,
} from '../services/store/actions'

const ReclamoResumeBox = ({
    onBackPress,
    request,
    backButton,
    isLoading = false,
    claim,
    showAttachmentsSection = false,
}: ReclamoResumeBoxProps) => {
    const strings = useI18n()
    const supply = useAppSelector((state) => state.supply)
    const [expandUtility, setExpandUtility] = useState(true)
    const [showAttachments, setShowAttachments] = useState(false)
    const [attachments, setAttachments] = useState<Allegato[]>([])
    const isMultiple = claim?.claimUtilities?.length ?? -1
    const pod = supply.supplies.find(
        (f) => f.puntoGas === request?.puntoGas,
    )?.pod
    const [, queryDownloadAllegatoReclamo] = useAppQuery(
        downloadAllegatoReclamo,
    )
    const [isLoadingAllegati, queryAllegati] = useAppQuery(getAllegatiReclamo)

    const loadAllegati = useCallback(
        async (idReclamo: number) => {
            try {
                const attachments = await queryAllegati(idReclamo)
                setAttachments(attachments)
            } catch {
                //
            }
        },
        [queryAllegati],
    )

    useEffect(() => {
        if (!claim?.claim.idReclamo) {
            return
        }
        loadAllegati(claim?.claim.idReclamo)
    }, [claim?.claim.idReclamo, loadAllegati])

    const renderKeyValue = (
        key: string,
        value: string | number | Date | undefined,
    ) => {
        return (
            <Box width="50%" direction="column">
                <Text weight="bold" size="medium">
                    {key}
                </Text>
                <Text size="small">{value ?? '-'}</Text>
            </Box>
        )
    }

    const BackButton = backButton ? BBackButton : BCloseButton

    return (
        <Box flex="grow" background={'background-front'}>
            <Box pad="medium" gap="medium">
                <Box direction="row" gap="medium" align="center">
                    <BackButton onClick={() => onBackPress()} size="small" />
                    <Text size="xlarge">
                        {getDescTipoRichiesta(
                            request?.idTipoRichiesta,
                            strings,
                        )}
                    </Text>
                    {(isLoading || isLoadingAllegati) && <Spinner />}
                </Box>
                <Box direction="row" gap="small" align="center">
                    <Text size="large" style={{ textTransform: 'uppercase' }}>
                        {claim?.claim?.sottoCategoria}
                    </Text>
                </Box>
                {isMultiple < 2 && (
                    <Box
                        justify="between"
                        direction="row"
                        flex
                        margin={{ top: 'medium' }}
                    >
                        <Box justify="center" direction="column" gap="small">
                            <Text weight="bold" size="medium">
                                {pod ? strings.fornitura : strings.codiceUtente}
                            </Text>
                            <Text size="small">
                                {pod ? pod : request?.codiceUtente}
                            </Text>
                        </Box>
                        <Box justify="center" direction="column" gap="small">
                            <Text weight="bold" size="medium">
                                {strings.puntoGas}
                            </Text>
                            <Text size="small">{request?.puntoGas}</Text>
                        </Box>
                        {/* Non dovrebbe visualizzare i reclami inseriti dall'operatore, quindi penso sia inutile */}
                        {request?.idTipoUtente === TipiUtente.Operatore && (
                            <Box
                                justify="center"
                                direction="column"
                                gap="small"
                            >
                                <Text weight={'bold'}>
                                    {strings.utenteInserimento}
                                </Text>
                                <Text
                                    style={{ textTransform: 'uppercase' }}
                                    size="small"
                                >
                                    {request?.idTipoUtente ===
                                    TipiUtente.Operatore
                                        ? strings.operatore
                                        : ''}
                                </Text>
                            </Box>
                        )}
                        {/* valutare se tenerlo. Testo e icona non sono allineati però.*/}
                        <Box direction="column" gap="small" justify="center">
                            <Text weight="bold" size="medium">
                                {strings.tipoServizio}
                            </Text>
                            <Box
                                direction="row"
                                align="center"
                                pad={{ horizontal: 'medium' }}
                            >
                                <Text
                                    size="small"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {request?.tipoServizio === 'E'
                                        ? strings.E
                                        : request?.tipoServizio === 'GPL'
                                        ? strings.GPL
                                        : strings.G}
                                </Text>
                                {request?.tipoServizio === 'E' ? (
                                    <assets.EnergyIcon ss="30px" />
                                ) : request?.tipoServizio === 'GPL' ? (
                                    <assets.GplIcon ss="30px" />
                                ) : (
                                    <assets.GasIcon ss="30px" />
                                )}
                            </Box>
                        </Box>
                        <Box direction="column">
                            <Text weight="bold" size="medium">
                                {strings.dataRichiesta}
                            </Text>
                            <Text size="small" margin={{ top: '12px' }}>
                                {formatDateString(
                                    request?.dataRichiesta,
                                    'yyyy-MM-dd',
                                )}
                            </Text>
                        </Box>
                    </Box>
                )}
                {isMultiple > 1 && (
                    <Box
                        justify="between"
                        direction="row"
                        flex
                        margin={{ top: 'medium' }}
                    >
                        <Box justify="center" direction="column" gap="small">
                            <Text weight="bold" size="medium">
                                {strings.codiceUtente}
                            </Text>
                            <Text weight="bold" size="small">
                                {request?.codiceUtente}
                            </Text>
                        </Box>
                        <Box direction="column">
                            <Text weight="bold" size="medium">
                                {strings.dataRichiesta}
                            </Text>
                            <Text
                                weight="bold"
                                size="small"
                                margin={{ top: '12px' }}
                            >
                                {formatDateString(
                                    request?.dataRichiesta,
                                    'yyyy-MM-dd',
                                )}
                            </Text>
                        </Box>
                    </Box>
                )}
                <RequestProgressBox
                    idStatoRichiesta={request?.idStatoRichiesta ?? -1}
                />
                <Box margin={{ top: 'small' }}>
                    <Text weight="bold" size="medium">
                        {strings.dettagliRichiesta}
                    </Text>
                </Box>
                <Box
                    direction="row"
                    flex
                    align="start"
                    margin={{ top: 'small' }}
                >
                    {renderKeyValue(
                        strings.tipoRichiesta,
                        claim?.claim.tipoRichiesta,
                    )}
                    {renderKeyValue(
                        strings.contactType,
                        claim?.claim.idTipoContatto === 0
                            ? strings.contactTypeOwner
                            : strings.contactTypeAgent,
                    )}
                </Box>
                <Box direction="row" flex margin={{ top: 'small' }}>
                    {renderKeyValue(
                        strings.nominativo,
                        claim?.claim.nominativoContatto,
                    )}
                    {claim?.claim.occupazione &&
                        renderKeyValue(
                            strings.occupation,
                            claim?.claim.occupazione,
                        )}
                </Box>
                <Box direction="row" flex margin={{ top: 'small' }}>
                    {renderKeyValue(
                        strings.emailForContact,
                        claim?.claim.emailRicontatto,
                    )}
                    {renderKeyValue(
                        strings.phoneForContact,
                        claim?.claim.telefonoRicontatto,
                    )}
                </Box>
                <Box direction="row" flex margin={{ top: 'small' }}>
                    {renderKeyValue(strings.email, claim?.claim.emailMittente)}
                    {renderKeyValue(
                        strings.telefono,
                        claim?.claim.telefonoChiamante,
                    )}
                </Box>
                <Box direction="row" flex margin={{ top: 'small' }}>
                    {renderKeyValue(
                        strings.description,
                        claim?.claim.descrizione,
                    )}
                </Box>
                <Box direction="row" flex margin={{ top: 'small' }}>
                    {renderKeyValue(
                        strings.dataRichiesta,
                        claim?.claim.dataRichiestaReclamo,
                    )}
                </Box>
                <>
                    {showAttachmentsSection && attachments.length > 0 && (
                        <Box gap="small">
                            <BCaretCollapsible
                                isExpanded={showAttachments}
                                expandString={strings.showClaimAttachments}
                                hideString={strings.hideClaimAttachments}
                                onClick={() =>
                                    setShowAttachments(!showAttachments)
                                }
                            />
                            <Collapsible open={showAttachments}>
                                <FilesTable
                                    boxProps={{ margin: { bottom: 'small' } }}
                                    paginate={{ size: 'small' }}
                                    step={5}
                                    files={attachments}
                                    onDownload={(allegato) =>
                                        queryDownloadAllegatoReclamo(allegato)
                                    }
                                />
                            </Collapsible>
                        </Box>
                    )}
                    {isMultiple > 1 && (
                        <Box gap="small">
                            <BCaretCollapsible
                                onClick={() => setExpandUtility(!expandUtility)}
                                isExpanded={!expandUtility}
                                expandString={strings.showUtenzeCoinvolte}
                                hideString={strings.hideUtenzeCoinvolte}
                            />
                            <Collapsible open={!expandUtility}>
                                <UtilitiesRichiestaReclamo
                                    claimUtilities={claim?.claimUtilities ?? []}
                                />
                            </Collapsible>
                        </Box>
                    )}
                </>
            </Box>
        </Box>
    )
}

export default ReclamoResumeBox

type ReclamoResumeBoxProps = {
    onBackPress: () => void
    request?: RequestModel
    claim?: ClaimResponseModel
    isLoading: boolean
    backButton?: boolean
    showAttachmentsSection?: boolean
}
