import { IOCServices } from '..'

export type StatiRichiestaReclamo = {
    idStatoRichiesta: number
    statoRichiesta: string
}[]

const getStatiRichiestaReclamoAPI = async (idStato: number, container?: IOCServices): Promise<StatiRichiestaReclamo> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        //We don't have localization yet 
        const res = await rest.get(`Richiesta/GetStatiRichiesta?idlingua=1&idStato=${idStato}`)
        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getStatiRichiestaReclamoAPI
