import { Formik } from 'formik'
import { Box, Button, Heading, Keyboard, Spinner } from 'grommet'
import { useMemo } from 'react'
import * as Yup from 'yup'
import useI18n from '../../hooks/useI18n'
import { StatiRichiesta } from '../../models/Enums'
import { RequestFiltersModel } from '../../models/models'
import { useAppSelector } from '../../services/storeProvider'
import { getDescTipoRichiesta } from '../../utilities'
import BDateInput from '../BDateInput'
import BSelect from '../BSelect'
import BTextInput from '../BTextInput'

const RequestsFilterForm = ({
    isLoading,
    onSubmit,
    initialValues,
}: RequestsFilterFormProps) => {
    const strings = useI18n()
    const requestTypes = useAppSelector((state) => state.request.requestTypes)

    const RequestsFilterSchema = Yup.object().shape({
        idRichiesta: Yup.number().notRequired().typeError(strings.mustBeNumber),
        nominativoCliente: Yup.string().notRequired(),
        utenteSito: Yup.string().notRequired(),
        codiceCliente: Yup.string().notRequired(),
        dataDa: Yup.date().notRequired(),
        dataA: Yup.date().notRequired(),
        iDTipoRichiesta: Yup.number().notRequired(),
        idStato: Yup.number().notRequired(),

    })

    const requestTypeList = requestTypes.map((it)=>({
        label: getDescTipoRichiesta(it.idTipoRichiesta,strings),
        value: it.idTipoRichiesta
    }))

    const stateRequestList = useMemo(() => {
        return [
            {
                label: strings.nuova,
                value: (StatiRichiesta.Nuova),
            },
            {
                label: strings.nuovaDaValidare,
                value: String(StatiRichiesta.NuovaDaValidare),
            },
            {
                label: strings.inEvasione,
                value: String(StatiRichiesta.InEvasione),
            },
            {
                label: strings.evasa,
                value: String(StatiRichiesta.Evasa),
            },
            {
                label: strings.confermata,
                value: String(StatiRichiesta.Confermata),
            },
            {
                label: strings.completata,
                value: String(StatiRichiesta.Completata),
            },
            {
                label: strings.validataBo,
                value: String(StatiRichiesta.ValidataBO),
            },
            {
                label: strings.rigettataBo,
                value: String(StatiRichiesta.RigettataBO),
            },
            {
                label: strings.rifiutata,
                value: String(StatiRichiesta.Rifiutata),
            },
            {
                label: strings.cancellata,
                value: String(StatiRichiesta.Cancellata),
            },
            {
                label: strings.annullata,
                value: String(StatiRichiesta.Annullata),
            },
        ]
    }, [
        strings.annullata,
        strings.cancellata,
        strings.completata,
        strings.confermata,
        strings.evasa,
        strings.inEvasione,
        strings.nuova,
        strings.nuovaDaValidare,
        strings.rifiutata,
        strings.rigettataBo,
        strings.validataBo,
    ])

    return (
    <>
        <Heading size="small">{strings.requestFilters}</Heading>
        <Formik
            enableReinitialize
            initialValues={
                initialValues ?? {
                    idRichiesta: undefined,
                    dataDa: '',
                    dataA: '',
                    nominativoCliente: '',
                    codiceCliente: '',
                    utenteSito: '',
                    iDTipoRichiesta: undefined,
                    idStato: undefined,
                }
            }
            validationSchema={RequestsFilterSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                validateForm,
                handleBlur,
            }) => {
                return (
                    <Keyboard onEnter={() => onSubmit(values)}>
                        <Box gap="small">
                            <Box flex>
                                <BTextInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.idRichiesta}
                                    label={strings.idRichiesta}
                                    name="idRichiesta"
                                />

                                <BSelect
                                    searchable
                                    name="iDTipoRichiesta"
                                    label={strings.tipoRichiesta}
                                    placeholder={strings.phTipoRichiesta}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={requestTypeList}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                                <Box direction="row" gap="small">
                                    <BDateInput
                                        containerProps={{
                                            flex: true,
                                            width: 'small',
                                        }}
                                        name="dataDa"
                                        format="dd/mm/yyyy"
                                        label={strings.dataInizio}
                                        placeholder={strings.dataInizio}
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                        tooltip={strings.phDataInizio}
                                    />
                                    <BDateInput
                                        containerProps={{
                                            flex: true,
                                            width: 'small',
                                        }}
                                        name="dataA"
                                        format="dd/mm/yyyy"
                                        label={strings.dataFine}
                                        placeholder={strings.dataFine}
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                        tooltip={strings.phDataFine}
                                    />
                                </Box>
                                <BSelect
                                    searchable
                                    label={strings.stato}
                                    placeholder={strings.stato}
                                    name="idStato"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={stateRequestList}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                />
                                <BTextInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.codiceCliente}
                                    label={strings.codiceCliente}
                                    name="codiceCliente"
                                />
                                <BTextInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.nominativo}
                                    label={strings.nominativo}
                                    name="nominativoCliente"
                                />
                                <BTextInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.utente}
                                    label={strings.utente}
                                    name="utenteSito"
                                />
                                <Box margin={{ vertical: 'small' }}>
                                    <Button
                                        disabled={isLoading}
                                        onClick={async () => {
                                            try {
                                                const fields =
                                                    await validateForm()
                                                const errors =
                                                    Object.keys(fields)
                                                if (errors.length > 0) {
                                                    return
                                                }
                                                handleSubmit()
                                            } catch (e) {}
                                        }}
                                        icon={
                                            isLoading ? <Spinner /> : <span />
                                        }
                                        label={strings.sendFilters}
                                        primary
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Keyboard>
                )
            }}
        </Formik>
    </>
    )
}

interface RequestsFilterFormProps {
    isLoading: boolean
    onSubmit: (values: RequestFiltersModel) => any
    initialValues?: RequestFiltersModel
}

export default RequestsFilterForm
