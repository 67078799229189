import { Box, Button, ButtonExtendedProps } from 'grommet'

const BRoundButton = ({ elevation, ...rest }: BRoundButtonProps) => {
    return (
        <Box elevation={elevation ?? 'none'}>
            <Button
                style={{ borderRadius: '50px' }}
                primary
                color="primary_color"
                {...rest}
            />
        </Box>
    )
}

type BRoundButtonProps = {
    elevation?: string
} & ButtonExtendedProps

export default BRoundButton
