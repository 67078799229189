import { IOCServices } from '..'
import { UsersSearchModel } from '../../models/models'

export type UsersFilters = {
    codiceUtente?: string
    puntoGas?: string
    nominativo?: string
    codiceFiscale?: string
    piva?: string
    podPdr?: string
    page?: number
    rowsPerPage?: number
}

export const USERS_PER_PAGE = 20

const loadUsersAPI = async (
    filters: UsersFilters,
    container?: IOCServices,
): Promise<UsersSearchModel> => {
    if (!container) {
        return Promise.reject('Container is null. API must be called from api service')
    }
    const rest = container.restID

    try {
        const res = await rest.post('/Utente/getListaUtenti/', { ...filters })
        const { users: usersList, totalRows } = res.data.value

        return {
            users: usersList.map((it: any) => ({
                userId: it.codiceUtente,
                name: it.nominativo,
                address: it.indirizzo,
                municipality: it.comune,
                fiscalCode: it.codiceFiscale,
                vatNumber: it.partitaIVA,
            })),
            totalRows,
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadUsersAPI
