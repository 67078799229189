import { Box, Heading, Spinner } from 'grommet'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BBackButton from '../components/BBackButton'
import BCard from '../components/BCard'
import ContractDetailsBox from '../components/ContractDetailsBox'
import SupplySelector from '../components/SupplySelector'
import UserContactsForm from '../components/forms/UserContactsForm'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import { UserModel } from '../models/models'
import { getUserAdmin } from '../services/store/actions'
import editDatiAnagrafici from '../services/store/actions/editDatiAnagrafici'
import { useAppSelector } from '../services/storeProvider'

const ChangeUserData = () => {
    const strings = useI18n()
    const selectedSupply = useAppSelector(
        (state) => state.supply.selectedSupply,
    )
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, queryUserSelected] = useAppQuery(getUserAdmin)
    const [selectedUser, setSelectedUser] = useState<UserModel>()

    useEffect(() => {
        if (!selectedSupply?.codiceUtente) {
            return
        }
        queryUserSelected({ userCode: selectedSupply?.codiceUtente }).then(
            setSelectedUser,
        )
    }, [queryUserSelected, selectedSupply?.codiceUtente])

    if (isLoading) {
        return (
            <Box flex direction="row" align="start">
                <Box pad={{ horizontal: 'medium' }} fill="horizontal">
                    <Spinner />
                </Box>
            </Box>
        )
    }
    return (
        <Box flex pad="small">
            <Box flex="grow" direction="row" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => navigate('/requests')}
                />
                <Heading level={3}>{strings.ModificaDatiAnagrafici}</Heading>
            </Box>
            <Box
                flex="grow"
                width="xxlarge"
                pad="full"
                gap="center"
                margin={{ vertical: 'small' }}
            >
                <SupplySelector />
            </Box>
            <BCard flex="grow" margin={{ vertical: 'small' }}>
                <ContractDetailsBox
                    pad={{ vertical: '0', horizontal: '0' }}
                    background={'transparent'}
                    title={strings.datiUtente}
                    elements={[
                        {
                            title: strings.nominativo,
                            value: selectedSupply?.cognomeNomeRecapito! || '-',
                        },
                        {
                            title: strings.codiceCliente,
                            value: selectedSupply?.codiceUtente || '',
                        },
                        {
                            title: strings.puntoGas,
                            value: selectedSupply?.puntoGas || '-',
                        },
                    ]}
                />
            </BCard>
            <Box flex="grow">
                <UserContactsForm
                    loading={false}
                    onBlur={(e) => {
                        console.log(e)
                    }}
                    onSubmit={async (values) => {
                        try {
                            if (
                                values.telefono !== values.orig_telefono ||
                                values.cellulare !== values.orig_cellulare ||
                                values.email !== values.orig_email ||
                                values.fax !== values.orig_fax
                            ) {
                                await dispatch(editDatiAnagrafici(values))
                            }

                            navigate('/requests')
                        } catch (e) {
                            console.log(e)
                        }
                    }}
                    user={selectedUser}
                />
            </Box>
        </Box>
    )
}

export default ChangeUserData
