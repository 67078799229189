import { Box, Button, Heading, Layer } from 'grommet'
import { Close, Download, Filter, Trash } from 'grommet-icons'
import { useEffect, useMemo, useState } from 'react'
import BLightButton from '../components/BLightButton'
import ContractDetailsBox from '../components/ContractDetailsBox'
import RequestListSearch from '../components/RequestListSearch'
import RequestsFilterForm from '../components/forms/RequestsFilterForm'
import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import useStringFromCode from '../hooks/useStringFromCode'
import {
    KeyValue,
    RequestFiltersModel,
    RequestListResponseModel,
    RequestSearchModel,
} from '../models/models'
import { loadUsersRequests } from '../services/store/actions'
import {
    formatDate,
    getDescStatoRichiesta,
    getDescStatoRichiestaOperator,
    getDescTipoRichiesta,
} from '../utilities'
import { useNavigate } from 'react-router-dom'
import { REQUESTS_PER_PAGE } from '../services/store/actions/loadUsersRequests'

type TitleValue = {
    title: string | number
    value: string | number | Date
}

type FilterTitleValue = Array<TitleValue>

const initialFilters = {
    idRichiesta: undefined,
    dataDa: '',
    dataA: '',
    nominativoCliente: '',
    codiceCliente: '',
    utenteSito: '',
    tipo: undefined,
    statoRichiesta: undefined,
    page: 1,
}
const RequestList = () => {
    const strings = useI18n()
    const getString = useStringFromCode()
    const navigate = useNavigate()
    const [requests, setRequests] = useState<RequestListResponseModel>({
        requests: [],
        totalRows: 0,
    })
    const [filters, setFilters] = useState<RequestFiltersModel>(initialFilters)
    const [isLoadingUsersRequests, queryUsersRequests] =
        useAppQuery(loadUsersRequests)
    const [isFilterOpen, setFilterOpen] = useState(false)

    useEffect(() => {
        queryUsersRequests({ rowsPerPage: REQUESTS_PER_PAGE, ...filters }).then(
            setRequests,
        )
    }, [filters, queryUsersRequests])

    const activeFilters = useMemo(() => {
        if (!filters) {
            return []
        }
        const entries = Object.entries(filters)
        const initialValue: FilterTitleValue = []
        const currStrings = strings as KeyValue
        return entries.reduce((prev, curr) => {
            const [key, value] = curr
            if (!value || key === 'page' || key === 'rowsPerPage') {
                return prev
            }
            if (key === 'dataDa' || key === 'dataA') {
                return [
                    ...prev,
                    {
                        title:
                            key === 'dataDa'
                                ? strings.dataInizio
                                : strings.dataFine,
                        value: formatDate(new Date(value)),
                    },
                ]
            }
            if (key === 'iDTipoRichiesta') {
                return [
                    ...prev,
                    {
                        title: strings.tipoRichiesta,
                        value: getDescTipoRichiesta(Number(value), strings),
                    },
                ]
            }
            if (key === 'idStato') {
                return [
                    ...prev,
                    {
                        title: strings.statoRichieste,
                        value: getDescStatoRichiestaOperator(
                            Number(value),
                            strings,
                        ),
                    },
                ]
            }
            return [...prev, { title: currStrings[key], value }]
        }, initialValue)
    }, [filters, strings])

    const isActiveFilters = useMemo(() => {
        return activeFilters.length > 0
    }, [activeFilters])

    const exportTitle = {
        codiceRichiesta: strings.idRichiesta,
        data: strings.dataRichiesta,
        nominativo: strings.nominativo,
        codiceUtente: strings.codiceUtente,
        utenteSito: strings.utente,
        comune: strings.comune,
        agente: strings.agentName,
        idTipo: strings.tipoRichiesta,
        tipo: strings.tipoRichiesta,
        idStato: strings.statoRichieste,
        statoRichiesta: strings.statoRichieste,
        dataScadenza: strings.dataScadenza,
        dataProsContatto: strings.datiRecapitoUltimaFattura,
    }

    return (
        <Box>
            <Box pad={{ horizontal: 'medium' }} fill>
                <Box direction="row" gap="small" align="center">
                    <Heading size="small">{strings.elencoRichieste}</Heading>
                    <Button
                        label={strings.esporta}
                        primary
                        icon={<Download size="small" />}
                        size="small"
                        onClick={async () => {
                            const exportRequests = await queryUsersRequests({
                                rowsPerPage: requests.totalRows,
                                ...filters,
                                page: 1,
                            })
                            const titleKeys = Object.keys(exportTitle)

                            const requestExport = exportRequests.requests.map(
                                (item) => {
                                    const userSplitString =
                                        item.utenteSito.split(' ')
                                    const userType = getString(
                                        userSplitString[0],
                                    )
                                    const userName = userSplitString[1] || ''
                                    const newItem = {
                                        ...item,
                                        tipo: getDescTipoRichiesta(
                                            item.idTipo,
                                            strings,
                                        ),
                                        utenteSito: `${userType} ${userName}`,
                                        stato: getDescStatoRichiesta(
                                            item.idStato,
                                            strings,
                                        ),
                                    }
                                    return Object.values(newItem)
                                },
                            )
                            const refinedData = [titleKeys, ...requestExport]
                            let csvContent = ''
                            refinedData.forEach((row) => {
                                csvContent += row.join(';') + '\n'
                            })
                            const blob = new Blob([csvContent], {
                                type: 'text/csv;charset=utf-8,',
                            })
                            const fileURL = URL.createObjectURL(blob)
                            const downloadLink = document.createElement('a')
                            downloadLink.href = fileURL
                            downloadLink.download = `Elenco Richieste_${formatDate(
                                new Date(),
                            )}.csv`
                            downloadLink.click()
                        }}
                    />
                    <Button
                        label={strings.filters}
                        primary
                        icon={<Filter size="small" />}
                        size="small"
                        onClick={() => {
                            setFilterOpen(!isFilterOpen)
                        }}
                        tip={
                            isActiveFilters
                                ? {
                                      plain: true,
                                      content: (
                                          <ContractDetailsBox
                                              border
                                              pad={{
                                                  vertical: 'small',
                                                  horizontal: 'small',
                                              }}
                                              background={'background-front'}
                                              elements={activeFilters}
                                              title={strings.activeFilters}
                                          />
                                      ),
                                  }
                                : undefined
                        }
                    />
                    {isActiveFilters && (
                        <Button
                            size="small"
                            icon={<Trash size="small" />}
                            label={strings.removeFilters}
                            onClick={() => setFilters(initialFilters)}
                        />
                    )}
                </Box>
                <RequestListSearch
                    fill="vertical"
                    onPageChange={({ page }) => {
                        setFilters({ ...filters, page })
                    }}
                    totalRow={requests.totalRows}
                    isLoading={isLoadingUsersRequests}
                    requests={requests.requests}
                    onRequestSelect={async (data: RequestSearchModel) => {
                        navigate(`/requestList/${data.idRichiesta}`)
                    }}
                />
            </Box>
            {isFilterOpen && (
                <Layer
                    modal={false}
                    position="right"
                    onEsc={() => setFilterOpen(false)}
                    style={{ height: '100vh' }}
                >
                    <Box
                        width={{ min: 'medium' }}
                        background="background-front"
                        pad={{ horizontal: 'medium' }}
                        fill="vertical"
                        elevation="large"
                    >
                        <BLightButton
                            boxProps={{
                                alignSelf: 'end',
                                margin: { top: 'small' },
                            }}
                            label={strings.chiudi}
                            onClick={() => setFilterOpen(false)}
                            icon={<Close />}
                        />
                        <RequestsFilterForm
                            onSubmit={(values: RequestFiltersModel) => {
                                setFilters({
                                    ...values,
                                    page: 1,
                                })
                                setFilterOpen(false)
                            }}
                            initialValues={filters}
                            isLoading={isLoadingUsersRequests}
                        />
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default RequestList
