import { IOCServices } from '..'

export type NoteStato = {
    idNotaStato: number
    notaStato: string
}[]

const getNoteStatoAPI = async (container?: IOCServices): Promise<NoteStato> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get('/Reclami/GetNoteStato')
        return res.data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getNoteStatoAPI
