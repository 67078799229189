import it from '../messages/defaultIt'
import en from '../messages/defaultEn'
import theme from '../themes/defaultTheme'
import assets from '../assets/defaultAssets'
import parameters from '../parameters/defaultParameters'

const defaultAssets = {
    messages: { it, en },
    theme,
    assets,
    parameters
}

export default defaultAssets
