import { RequestWithDetailsModel } from '../../../models/models'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'

const loadRequestFromOperator =
    (idRichiesta: number): AppAction<RequestWithDetailsModel> =>
    async (dispatch, getState, container) => {
        try {
            const request = await container.api.loadRequestFromOperatorAPI(idRichiesta)
            return {
                request: request.richiesta,
                details: request.dettagli
            }
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default loadRequestFromOperator
