import { SET_MODAL } from './types'


export type ModalParams = {
    type: 'error' | 'warn' | 'info'
    show: boolean
    message: string
}

const setModal = (args: ModalParams) => {
    return {
        type: SET_MODAL,
        payload: {
            ...args,
        },
    }
}

export default setModal
