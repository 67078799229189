import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { RichiestaAssistenzaModel, SupplyModel } from '../../models/models'


const insertRichiestaAssistenzaAPI = async (
    auto: RichiestaAssistenzaModel,
    props: SupplyModel,
    isOperator: boolean,
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const restID = container.restID
    const name = container.store.getState().user.clientInfo.nominativo ?? ''

    try {
        const res = await restID.post(`/Richiesta/InsertRichiestaAssistenza/${auto.subrequest}`, {
            CodiceUtente: props.codiceUtente,
            PuntOGas: props.puntoGas,
            AnagTipoVia: auto.streetType,
            AnagNome: auto.name,
            AnagTelefono: auto.phone,
            AnagEmailAddress: auto.email,
            AnagOrario: auto.preferredContactTime,
            AnagVia: auto.address,
            AnagNumCivico: auto.number,
            AnagBarrato: auto.barrato,
            AnagComune: auto.city,
            AnagProvincia: auto.province,
            AnagCAP: auto.cap,
            Note: auto.note,
            Note2: auto.description,
            AnagPiano: '',
            AnagScala: '',
            AnagFrazione: '',
            AnagInterno: '',
            AnagComuneDesc: '',
            ContAnagNome: '',
            Nominativo: name,
            IsOperator: isOperator,
        })

        return res.status === HttpStates.OK
    } catch (e) {
        return Promise.reject(e)
    }
}

export default insertRichiestaAssistenzaAPI
