import { Formik } from 'formik'
import { Box, Button, Keyboard, Layer, Spinner } from 'grommet'
import {
    CircleInformation,
    Mail,
    Phone,
    User,
    UserAdmin
} from 'grommet-icons'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import useI18n from '../../hooks/useI18n'
import useRequiredFields from '../../hooks/useRequiredFields'
import useTheme from '../../hooks/useTheme'
import { SET_CODICEUTENTE_REGISTRATION, SET_NEEDDATA_REGISTRATION } from '../../services/store/actions/types'
import { useAppDispatch, useAppSelector } from '../../services/storeProvider'
import BButton from '../BButton'
import BTextInput from '../BTextInput'
import InfoBox from '../InfoBox'

const RequestRegistrationForm = (props: {
    onSubmit: (values: { CodiceUtente: string; Codice: string, Cellulare: string, Email: string }) => void
    onSubmitStep: (values: { CodiceUtente: string; Codice: string, Cellulare: string, Email: string }) => void
    loading: boolean
}) => {
    const dispatch = useAppDispatch()
    const strings = useI18n()
    const theme = useTheme()
    const user = useAppSelector((state) => state.user.clientInfo)
    const [step, setStep] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [tmpCodiceUtente, setTmpCodiceUtente] = useState('')
    const loginSchema = Yup.object().shape({
        CodiceUtente: Yup.string().test('TestCodiceUtente', '', (actualRead, { createError, parent }) => {
            try {

                if (!step) {
                    if (actualRead === undefined || actualRead === "") {
                        throw Error(strings.mandatoryField)
                    }
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
        Codice: Yup.string().test('TestCodice', '', (actualRead, { createError, parent }) => {
            try {

                if (step) {
                    if (actualRead === undefined || actualRead === "") {
                        throw Error(strings.mandatoryField)
                    }
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
        Cellulare: Yup.string().test('TestCell', '', (actualRead, { createError, parent }) => {
            try {

                if (step) {
                    if (actualRead === undefined || actualRead === "") {
                        throw Error(strings.mandatoryField)
                    }
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
        Email: Yup.string().test('TestEmail', '', (actualRead, { createError, parent }) => {
            try {

                if (step) {
                    if (actualRead === undefined || actualRead === "") {
                        throw Error(strings.mandatoryField)
                    }
                }

                return true

            } catch (e) {

                const error = e as Error
                return createError({ message: error?.message })
            }
        }),
    })
    const isRequired = useRequiredFields(loginSchema)
    useEffect(() => {
        setStep(user.needDataRegistration)

    }, [user.needDataRegistration])

    return (
        <Formik
            initialValues={{ CodiceUtente: '', Codice: '', Cellulare: '', Email: '' }}
            validationSchema={loginSchema}
            onSubmit={(value) => step?(props.onSubmitStep && props.onSubmitStep(value)):(props.onSubmit && props.onSubmit(value))}
            >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                return (
                    <Keyboard target="component" onEnter={() => handleSubmit()}>
                        <Box gap="xsmall">
                            {!step?(<BTextInput
                                label={strings.codiceCliente}
                                name="CodiceUtente"
                                mandatory={isRequired('CodiceUtente')}
                                placeholder={strings.codiceCliente}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                renderIcon={() => (
                                    <Button
                                        icon={<CircleInformation size="medium" />}
                                        onClick={() => setShowModal(true)}
                                    />
                                )}
                            />):(
                            <>
                            <BTextInput
                                label={strings.codfiscpiva}
                                name="Codice"
                                mandatory={isRequired('Codice')}
                                placeholder={strings.phcodfiscpiva}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <User color="textWhite" size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                label={strings.cellulare}
                                name="Cellulare"
                                mandatory={isRequired('Cellulare')}
                                placeholder={strings.phCellulare}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Phone color="textWhite" size="medium" />
                                    </Box>
                                )}
                            />
                            <BTextInput
                                label={strings.email}
                                name="Email"
                                mandatory={isRequired('Email')}
                                placeholder={strings.phEmail}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                renderIcon={() => (
                                    <Box margin={{ right: 'small' }}>
                                        <Mail color="textWhite" size="medium" />
                                    </Box>
                                )}
                            />
                            </>
                            )}
                            <Box

                                justify="between"
                                animation="fadeIn"
                                margin={{ top: 'small' }}>
                                <Button
                                    disabled={props.loading}
                                    onClick={async () => {
                                        try {

                                            const res = await validateForm()
                                            const isValid = isEmpty(res)
                                            if (isValid) {
                                                if (step) {values.CodiceUtente = user.codiceUtenteRegistration}
                                                handleSubmit()
                                                if (step) {
                                                    dispatch({ type: SET_NEEDDATA_REGISTRATION, payload: false })
                                                }
                                                else {

                                                    dispatch({ type: SET_CODICEUTENTE_REGISTRATION, payload: values.CodiceUtente })
                                                }

                                            }

                                        } catch (e) {}
                                    }}
                                    icon={
                                        props.loading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <UserAdmin color="textWhite" />
                                        )
                                    }
                                    label={!step?strings.registrati:strings.proseguiRegistrazione}
                                    primary
                                />
                            </Box>
                            {showModal && (
                                <Layer
                                    position="center"
                                    onClickOutside={() => setShowModal(false)}
                                    onEsc={() => setShowModal(false)}>
                                    <Box
                                        //pad="medium"

                                        gap="small"
                                        width={{ min: 'small' }}
                                        height={{ min: 'small' }}
                                        fill>
                                        <InfoBox
                                            title={strings.codiceCliente}
                                            content={strings.hintCodiceUtente}
                                            onBackPress={() =>
                                                setShowModal(false)
                                            }
                                            onDonePress={() =>
                                                setShowModal(false)
                                            }
                                        />
                                    </Box>
                                </Layer>
                            )}
                        </Box>
                    </Keyboard>
                )
            }}
        </Formik>
    )
}

export default RequestRegistrationForm
