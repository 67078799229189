import { Anchor, Box, Heading, Spinner, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { Globe, MailOption, Organization, Phone } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import { useAppSelector } from '../services/storeProvider'
import useAppQuery from '../hooks/useAppQuery'
import useSize from '../hooks/useSize'
import { StringModel } from '../models/models'
import BSeparator from '../components/BSeparator'
import BProfileButton from '../components/BProfileButton'
import getStringhe from '../services/store/actions/getStringhe'

const InformazioniAzienda = () => {
    const strings = useI18n()
    const user = useAppSelector((state) => state.user.clientInfo)
    const main = useAppSelector((state) => state.main)
    const [isLoading, queryStringhe] = useAppQuery(getStringhe)
    const size = useSize()
    const [currStringhe, setStringhe] = useState<Array<StringModel>>([])


    const loadInfoData = async () => {
        try {
            //const supplies = await querySupplies()

            const stringhe = await queryStringhe()
            setStringhe(stringhe)

        } catch (e) {}
    }

    useEffect(() => {
        loadInfoData()

    }, [])



    if (isLoading) {
        return (
            <Box fill background={'background-back'}>

                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
            </Box>
        )
    }
    else {
    return (
        <Box
            direction={size === 'small' ? 'column' : 'row'}

        >
            <Box
                direction="column"
                width="100%"
                pad="small"

                //overflow={}
                //overflow={'scroll'}
            >
                <BProfileButton user={user.nominativo} />
                <Box direction="column">
                    <Heading level={3} margin={{ bottom: '10px' }}>
                        {strings.Informazioni}
                    </Heading>
                    <BSeparator size="2px" margin={{ bottom: '10px' }} />
                    <Box
                        direction="column"
                        gap="large"
                        margin={{ top: 'large' }}>
                        <Box direction="row" align="center" gap="medium">
                            <Organization size="large" color="brand" />
                            <Box direction="column" margin={{ bottom: '10px' }}>
                                {currStringhe
                                    .filter(
                                        (it) =>
                                            it.codice == 'APP_MSG_NomeAzienda',
                                    )
                                    .map((it) => {
                                        return (
                                            <Text weight="bold">
                                                {it.valorePredefinitoIT}
                                            </Text>
                                        )
                                    })}

                                {main.stringhe
                                    .filter(
                                        (it) =>
                                            it.codice ==
                                            'APP_AZIENDA_Indirizzo',
                                    )
                                    .map((it) => {
                                        return (
                                            <Text>
                                                {it.valorePredefinitoIT}
                                            </Text>
                                        )
                                    })}

                                {main.stringhe
                                    .filter(
                                        (it) => it.codice == 'APP_AZIENDA_PIVA',
                                    )
                                    .map((it) => {
                                        return (
                                            <Text>
                                                {it.valorePredefinitoIT}
                                            </Text>
                                        )
                                    })}
                            </Box>
                        </Box>

                        <Box
                            direction="row"
                            align="center"
                            gap="medium"
                            margin={{ bottom: '10px' }}>
                            <Globe size="large" color="brand" />
                            <Box direction="column">
                                {main.stringhe
                                    .filter(
                                        (it) => it.codice == 'APP_AZIENDA_Site',
                                    )
                                    .map((it) => {
                                        return (
                                            <Anchor
                                                target="_blank"
                                                color="textLink"
                                                weight="normal"
                                                href={it.valorePredefinitoIT}
                                                label={it.valorePredefinitoIT}
                                            />
                                        )
                                    })}
                            </Box>
                        </Box>

                        <Box
                            direction="row"
                            align="center"
                            gap="medium"
                            margin={{ bottom: '10px' }}>
                            <Phone size="large" color="brand" />
                            <Box direction="column">
                                {main.stringhe
                                    .filter((it) => it.codice == 'AZIENDA_Tel')
                                    .map((it) => {
                                        return (
                                            <Text>
                                                {it.valorePredefinitoIT}
                                            </Text>
                                        )
                                    })}
                            </Box>
                        </Box>

                        <Box direction="row" align="center" gap="medium">
                            <MailOption size="large" color="brand" />
                            <Box direction="column">
                                {main.stringhe
                                    .filter((it) => it.codice == 'AZIENDA_Mail')
                                    .map((it) => {
                                        return (
                                            <Text>
                                                {it.valorePredefinitoIT}
                                            </Text>
                                        )
                                    })}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
    }
}


export default InformazioniAzienda


