import { AxiosError } from 'axios'
import { HttpStates } from '../../../models/Enums'
import { editPasswordType } from '../../api/editPasswordGuestAPI'
import { AppAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_PWDCOMPLEXITYERROR } from './types'

const editPasswordGuest =
    (item: editPasswordType): AppAction<any> => async (dispatch, getState, container) => {
        const lan = getState().common.language

        const i18n = await container.strings(lan)
        try {

            const editData = await container.api.editPasswordGuestAPI(item)

            dispatch({
                type: SET_PWDCOMPLEXITYERROR,
                payload: false,
            })
            dispatch(
                setNotification({
                    type: 'info',
                    message: i18n.successOperatonMessage,
                    show: true,
                }),
            )

            return true
        } catch (e) {

            const isAxiosError = (x: any): x is AxiosError => true
            if (!isAxiosError(e)) throw e
            const error = e as AxiosError
            const errorCode = error.response?.status
            if (errorCode === HttpStates.KOPWD) {
                //SET INVALID
                dispatch({
                    type: SET_PWDCOMPLEXITYERROR,
                    payload: true,
                })

            }
            else {
                dispatch({
                    type: SET_PWDCOMPLEXITYERROR,
                    payload: false,
                })
            }
        }
    }

export default editPasswordGuest
