import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { useAppSelector } from '../storeProvider'


const richiestaTokenClientAPI =
    async (container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }
        const rest = container.restID
        

        const formData = new FormData()
        // formData.append('client_id', `${process.env.REACT_APP_Client_id}`)
        // formData.append('client_secret', `${process.env.REACT_APP_Client_secret}`)
        formData.append('grant_type', 'client_credentials')

        try {
            const request = await rest.post(
                `/connect/token`,
                formData,
            )

            if (request.status == HttpStates.OK) {
                const data = request.data

                return data.access_token
            }
          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default richiestaTokenClientAPI
