import { Box, BoxExtendedProps, Heading, Layer, Spinner } from 'grommet'
import BCloseButton from './BCloseButton'
import './BsPrimaryColor.css'
import { useState } from 'react'

type IFrameProps = {
    url: string
    height?: number
    width?: number
    visible?: boolean
    title?: string
    onClose?: () => void
}

const IFrame = ({
    url,
    height,
    width,
    title,
    onClose,
    visible,
}: IFrameProps) => {
    const [isLoading, setLoading] = useState(true)
    if (!visible) {
        return <Box />
    }
    return (
        <Layer position="center" onEsc={() => onClose?.()}>
            <Box flex direction="column">
                <Box pad="small" gap="small" direction="row" align="center">
                    <BCloseButton onClick={() => onClose?.()} size="medium" />
                    {title && <Heading level={3}>{title}</Heading>}
                </Box>
                {isLoading && (
                    <Box align="center">
                        <Spinner />
                    </Box>
                )}
                <Box  direction="row" pad="medium">
                    <iframe
                        title={url}
                        allowFullScreen
                        frameBorder={0}
                        onLoad={() => setLoading(false)}
                        height={height}
                        src={url}
                        width={width}
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export default IFrame
