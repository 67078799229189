import { Box, Grid, Heading, Spinner } from 'grommet'
import assets from '../../services/customerSpecs/assets/defaultAssets'
import { SET_SUPPLY } from '../../services/store/actions/types'
import { AllRoutes, RoutesEnum } from '../../Navigation'
import SupplyBox from '../SupplyBox'
import useI18n from '../../hooks/useI18n'
import useSize from '../../hooks/useSize'
import { useAppDispatch, useAppSelector } from '../../services/storeProvider'
import { StatiFornitura } from '../../models/Enums'
import { useNavigate } from 'react-router-dom'
import { ComponentWithLoader } from '../../models/models'

const Supplies = ({ isLoading }: ComponentWithLoader) => {
    const strings = useI18n()
    const size = useSize()
    const supply = useAppSelector((state) => state.supply)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <Box gap="small" fill>
            <Box direction="row" align="center">
                <Heading size="small">{strings.forniture}</Heading>
                {isLoading && <Spinner />}
            </Box>
            <Grid
                columns={{
                    count: size === 'large' ? 2 : 1,
                    size: 'auto',
                }}
                gap="small"
                fill="horizontal"
                margin={{ top: 'small' }}
            >
                {supply.supplies
                    .filter((f) =>
                        !supply.selectedAddress
                            ? true
                            : supply.selectedAddress.puntiGas.includes(
                                  f.puntoGas,
                              ),
                    )
                    .map((it, idx) => (
                        <SupplyBox
                            key={idx}
                            title={`${
                                it.tipoServizio === 'E'
                                    ? strings.pod
                                    : it.tipoServizio === 'GPL'
                                    ? strings.pdr
                                    : strings.pdr
                            } ${it.pod}`}
                            width={{ min: 'small' }}
                            margin={{
                                bottom: 'small',
                            }}
                            description={`${it.tipoVia} ${it.via} ${it.numeroCivico}, ${it.comune} ${it.provincia}`}
                            bookmarkColor={
                                it.idStatoFornitura !== StatiFornitura.Attiva
                                    ? 'disabled'
                                    : supply.addresses.find(
                                          (element) =>
                                              element.puntiGas.indexOf(
                                                  it.puntoGas,
                                              ) > -1,
                                      )?.color
                            }
                            icon={
                                it.tipoServizio === 'E' ? (
                                    <assets.EnergyIcon />
                                ) : it.tipoServizio === 'GPL' ? (
                                    <assets.GplIcon />
                                ) : (
                                    <assets.GasIcon />
                                )
                            }
                            state={it?.idStatoFornitura}
                            onClick={() => {
                                dispatch({
                                    type: SET_SUPPLY,
                                    payload: it,
                                })
                                navigate(`/${AllRoutes[RoutesEnum.SUPPLY]}`)
                            }}
                        />
                    ))}
            </Grid>
        </Box>
    )
}
export default Supplies
