const messages = {
    AppName: 'User Next',
    AppNameLogin: 'User Next',
    //Login and Recovery
    forgotPasswordButton: 'Non riesci ad accedere? Recupera le tue credenziali',
    cantLogin: 'Non riesci ad accedere? ',
    recoverCredential: 'Recupera le tue credenziali',
    backToLoginButton: 'Torna al login',
    alreadyHasAccount: "Hai già un'account? ",
    signin: 'Accedi',
    usernamePlaceholder: 'La tua username',
    emailPlaceholder: 'La tua Email',
    passwordPlaceholder: 'La tua password',
    acceptConditionCheckbox: 'ACCETTO LE CONDIZIONI DI UTILIZZO',
    loginButton: 'Login',
    recoverUsernameButton: 'Recupera Username',
    recoverPasswordButton: 'Recupera Password',
    sendButton: 'Invia',
    recoverCheckEmail: 'Controlla la tua Email per completare i passaggi',
    recoverSendPasswordSuccess:
        'Password modificata con successo, puoi chiudere questa pagina e fare il login',
    recoverInvalidEmail: 'Email non valida',
    recoverPasswordMustMatch: 'Le password devono coincidere',
    recoverPasswordPlaceholder: 'La tua nuova password',
    recoverPasswordRepeatPlaceholder: 'Ripeti la nuova password',
    loginFailedLabel: 'Login non valido',
    userSessionExpired: 'La sessione è scaduta, effettuare nuovamente il login',
    userActivation: 'Attiva la tua utenza',
    recoverPassword: 'Recupera la tua password',
    setNewPassword: 'Cambia Password',
    firstTime: 'Prima volta su UserNext? ',
    registrati: 'Registrati',
    proseguiRegistrazione: 'Prosegui nella registrazione',
    firstTimeRegistrati: 'Prima volta su UserNext? Registrati',
    from: 'da',

    //homePage
    iMieiIndirizzi: 'I Miei Indirizzi',
    IndirizzoFornitura:"Per esaminare le fatture associate ad un indirizzo, fai click sull'indirizzo stesso",
    vediTutti: 'Vedi tutti',
    tutteForniture: 'Tutte le Forniture',
    inScadenza: 'In Scadenza',
    clientsSearch: 'Ricerca clienti',
    lastRequests: 'Le ultime richieste',
    showProfile: 'Vedi profilo',
    nascondi:'Nascondi',
    //Pages Contaier
    welcomeBack: 'Ben arrivato',
    welcomeLabelMorning: 'Buongiorno',
    welcomeLabelAfternoon: 'Buon Pomeriggio',
    welcomeLabelEvening: 'Buonasera',
    profileLabel: 'Anagrafica',
    dashboard: 'Dashboard',
    contracts: 'Contratti',
    importLabel: 'Import',
    export: 'Esportazione',
    usersManagement: 'Gestione utenti',
    management: 'Gestione',
    logoutButton: 'LOGOUT',
    offers: 'Offerte',
    recoverYourPwd: 'Recupera la tua Password',

    //Common
    Lettura: 'Lettura',
    Tipo: 'Tipologia',
    tipoLetture: 'Tipologie di letture',
    autolettura: 'Autolettura',
    letturaStimata: 'Lettura stimata iniziale',
    letturaEffettiva: 'Lettura effettiva',
    let1: 'Lettura F1',
    let2: 'Lettura F2',
    let3: 'Lettura F3',
    letcont: 'Lettura Contatore',
    contatore: 'Contatore',
    correttore: 'Correttore',
    letcorr: 'Lettura Correttore',
    ScaricaMod: 'Scarica modello',
    ScaricaDoc: 'Scarica documento',
    InviaDoc: 'Invia documento',
    Ok: 'Ok',
    dal: 'dal',
    al: 'al',
    richiedi: 'Richiedi',
    mandatoryField: 'Campo obbligatorio',
    acceptedMandatory: 'Necessario accettare la clausola',
    readLowerPrev: 'Lettura inferiore a quella precedente',
    lengthFailed: 'Formato password errato',
    duplicateFields: 'Ci sono dei campi duplicati',
    lightModeLabel: 'Light',
    darkModeLabel: 'Dark',
    add: 'Aggiungi',
    password: 'Password',
    email: 'Email',
    phEmail: "L'Email dell'utente",
    mail: 'Posta',
    username: 'Username',
    phUsername: 'Inserire Username',
    phPassword: 'Inserire Password',
    repeatPassword: 'Ripeti la password',
    company: 'azienda',
    noResultFound: 'Nessun risultato trovato',
    invalidEmailField: 'Email non valida',
    invalidPecField: 'Pec non valida',
    save: 'Salva',
    send: 'Invia',
    download: 'Download',
    history: 'Storico',
    name: 'Nome',
    lastName: 'Cognome',
    details: 'Dettagli',
    goToDetails: 'Vai al dettaglio',
    confirmDeletion: 'Confermi rimozione?',
    operationCantBeUndone: "L'operazione non può essere annullata",
    close: 'CHIUDI',
    chiudi: 'Chiudi',
    remove: 'RIMUOVI',
    sendFilters: 'Filtra',
    filters: 'Filtri',
    removeFilters: 'Rimuovi filtri',
    activeFilters: 'Filtri attivi',
    clientFilters: 'Filtra i clienti',
    requestFilters: 'Filtra le richieste',
    passwordCheck:
        '8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale',
    passwordMustMatch: 'Le password devono coincidere',
    insertNewPassword: 'Inserisci la password',
    confirmPassword: 'Conferma password',
    edit: 'MODIFICA',
    discard: 'ANNULLA',
    duplicate: 'Duplica',
    user: 'Utente',
    users: 'Utenti',
    date: 'Data',
    G: 'Gas',
    E: 'Luce',
    GPL: 'Gpl',
    GPLS: 'GPL',
    GG: 'G',
    EE: 'E',
    WAIT_FOR_SIGN: 'Attesa firma',
    browse: 'CARICA DAL PC',
    dropDocumentHere: 'Trascina il documento qui',
    dropDocumentsHere: 'Trascina i documenti qui',
    uploadedFilesName: 'ELEMENTI',
    saveAndContinue: 'Salva e continua',
    next: 'Successivo',
    description: 'Descrizione',
    code: 'Codice',
    home: 'Home',
    bollette: 'Fatture',
    bolletta: 'Fattura',
    forniture: 'Forniture',
    leMieForniture: 'Le mie forniture',
    fornitura: 'Fornitura',
    richieste: 'Richieste',
    statoRichieste: 'Stato Richieste',
    statoRichiesta: 'Stato Richiesta',
    fatturaViaMail: 'Fattura Via Email',
    abilitazione: 'Abilita',
    revoca: 'Revoca',
    tutteLeForniture: 'Applica a tutte le forniture',
    hideFilters: 'Nascondi i filtri',
    expandFiltersDetails: 'Espandi i filtri',
    hideFiltersDate: 'Nascondi i filtri sulle date',
    expandFiltersDetailsDate: 'Espandi i filtri sulle date',
    dataInizio: 'Data inizio',
    dataFine: 'Data fine',
    phDataInizio: 'Inserisci la data di inizio',
    phDataFine: 'Inserisci la data di fine',
    load: 'Carica',
    loading: 'Caricamento',
    sostituisci: 'Sostituisci',
    resetFilters: 'Reset dei filtri',
    podpdr: 'Pod/Pdr',
    tutti: 'Tutti',
    dataSottoscrizione: 'Data di sottoscrizione',
    modifica: 'Modifica',
    adesione: 'Adesione',
    revocaup: 'REVOCA',
    modificafvm: 'MODIFICASOLOMAIL',
    utenzeCoinvolte: 'Utenze coinvolte',
    showUtenzeCoinvolte: 'Mostra utenze coinvolte',
    hideUtenzeCoinvolte: 'Nascondi utente coinvolte',
    mustBeNumber: 'Il valore inserito deve essere un numero',
    pod: 'POD',
    pdr: 'PDR',
    phSupplySelector: 'Inserisci POD/PDR',
    selectYourSupply: 'Seleziona la tua fornitura',
    nonSelectableSupply: 'La fornitura non è selezionabile in quanto in stato',
    noSelectedSupply: 'Nessuna fornitura selezionata',
    noSelectableSupplies: 'Non ci sono forniture selezionabili',
    podextended: 'POD (Point of Delivery)',
    codofferta: 'Codice Offerta',
    iMieiConsumi: 'I miei consumi',
    tipologia: 'Tipologia',
    clearSelection: 'Elimina selezione',
    WIPFunctionality: 'Funzionalità non ancora disponibile',
    elencoRichiesteAssociate: 'Elenco Richieste Associate',
    elencoIndirizziEmaildaConfermare: 'Elenco indirizzi Email da confermare',
    noRequests: 'Nessuna richiesta inserita',
    noReads: 'Nessuna lettura disponibile',
    noExpiredInvoices: 'Non hai fatture in scadenza',
    noEvents: 'Nessun evento',
    noFutureEvents: 'Nessun evento nei prossimi giorni',
    eventi: 'Eventi',
    altri: 'Altri',
    prossimiEventi: 'Prossimi Eventi',
    calendario: 'Calendario',
    leggiInformativa: 'Leggi informativa',
    NormativaCookie: 'Normativa Cookie',
    oggi: 'Oggi',
    neiProssimi: 'nei prossimi',
    giorni: 'giorni',
    leTueProssimeScadenze: 'Le tue prossime scadenze',
    scadraIl: 'scadrà il',
    rangeAutolettura: 'Range Autolettura',
    rangePerUtenza: "Il range per l'utenza",
    perUtenza: "per l'utenza",
    laFattura: 'La fattura',
    latuaOfferta: 'La tua offerta',
    leTueScadenze: 'Le tue scadenze',
    reclami: 'Reclami',
    operatore: 'Operatore',
    esporta: 'Esporta',
    prezzoListino: "L' attuale tuo prezzo di riferimento è",
    //Footer
    copyright: 'Copyright ©',
    cf_piva: 'C.F. e P.IVA:',

    //Mesi
    gen: 'Gen',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    mag: 'Mag',
    giu: 'Giu',
    lug: 'Lug',
    ago: 'Ago',
    set: 'Set',
    ott: 'Ott',
    nov: 'Nov',
    dic: 'Dic',

    //Company details
    companyDetails: 'Dettagli azienda',
    addUser: 'Aggiungi utente',

    //Notification
    ERROR: 'ERRORE',
    INFO: 'INFORMAZIONE',
    WARNING: 'ATTENZIONE',
    successEditMessage: 'Informazioni aggiornate con successo',
    successRemoveMessage: 'Elemento rimosso correttamente',
    successOperatonMessage: 'Operazione completata con successo',
    errorMessage: 'Operazione fallita',
    errorMessagePleaseRetry:
        'Operazione fallita, controllare la validità dei dati inseriti e riprovare',
    invalidPassword: 'La Password non rispetta i criteri di complessità',

    //Modal
    successRequestRegistration:
        "Processo iniziato correttamente, verifica il tuo l'indirizzo email per proseguire con la registrazione",
    successRequestRegistrationNoMail:
        "Il Pin è stato richiesto correttamente ma non è presente un indirizzo mail associato all'utente, verrà contattato da un nostro agente",

    //Registrazione
    registrationSuccessful: 'Registrazione completata!',
    unsuccessfulValidation: 'Link di registrazione non valido',
    creatingmeans: "Creare un account significa che sei d'accordo con i nostri",
    termsofservice: 'termini di servizio',
    hintCodiceUtente:
        'Il tuo codice cliente, di 6 cifre, si trova nella prima pagina della tua fattura nel riquadro in alto a destra.',
    hintComplessitaPassword:
        "• Deve contenere almeno 8 caratteri\n• Non mettere una password uguale alla precedente\n• Non può contenere il nome dell'account dell'utente o parte del nome completo dell'utente che superi tre caratteri consecutivi\n• Deve contenere caratteri di almeno 3 delle seguenti 4 categorie:\n$$i➢ Caratteri minuscoli(dalla a alla z)\n$$i➢ Caratteri maiuscoli(dalla A alla Z)\n$$i➢ Caratteri numerici(da 0 a 9)\n$$i➢ Caratteri speciali non alfabetici (ad esempio,!, $, #,%)",
    //404
    pageNotFoundTitle: 'Ops! Questa pagina non esiste!',
    backToTheDashboard: 'Torna alla dashboard',
    backToTheHome: 'Torna alla home',

    //Contracts LOG
    contractId: 'ID Dettaglio Contratto',
    serviceType: 'Tipo servizio',
    pod_pdr: 'POD/PDR',
    utilityStatus: 'Stato fornitura',
    addContract: 'Aggiungi Contratto',

    //Utility
    legalName: 'Ragione sociale',
    vat: 'P.IVA',
    removeCompany: 'Rimuovi azienda',

    //Offer
    validity: 'Range Validità',
    deliveryType: 'Tipo invio',
    domestic: 'Domestico',
    otherUses: 'Altri usi',

    //Form
    userFormName: "Il nome dell'utente",
    userFormSurname: "Il cognome dell'utente",
    userFormEmail: "Una email valida dell'utente",
    userFormPec:"Una pec valida dell'utente",
    userFormUsers: 'Utenti',
    userFormCompany: 'Azienda di appartenenza',
    userFormSelectCompany: "Scegli dalla lista l'azienda",
    userFormPhone: "Il telefono fisso dell'utente",
    userFormCellPhone: "Il cellulare dell'utente",
    telCheck: 'Numero di telefono non valido',
    cellCheck: 'Numero di cellulare non valido',
    nominativo: 'Nominativo',
    phNominativo: "Il nominativo dell'utente",
    codiceFiscale: 'Codice Fiscale',
    phCodiceFiscale: "Il codice fiscale dell'utente",
    telefono: 'Telefono',
    phTelefono: "Il Telefono fisso dell'utente",
    cellulare: 'Cellulare',
    phCellulare: "Il Cellulare dell'utente",
    AnagLegRapNomCogn: 'AnagLegRapNomCogn',
    AnagLegRapCF: 'AnagLegRapCF',
    partitaIVA: 'Partita IVA',
    piva: 'Partita IVA',
    partitaIVARich: 'Partita IVA del richiedente',
    phPartitaIVA: "La partita IVA dell'azienda",
    codfiscpiva: 'Codice Fiscale/Partita IVA',
    codfiscRich: 'Codice Fiscale del richiedente',
    phcodfiscpiva: 'Codice fiscale o Partita IVA',
    luogoNascita: 'Luogo di Nascita',
    phLuogoNascita: "Il luogo di nascita dell'utente",
    dataNascita: 'Data Nascita',
    phDataNascita: "La Data di nascita dell'utente",
    indirizzo: 'Indirizzo',
    phIndirizzo: "L'indirizzo dell'utente",
    cap: 'CAP',
    phCAP: "Il CAP dell'utente",
    fax: 'Fax',
    phFax: "Il Fax dell'utente",
    email1: 'Email',
    email2: 'Email 2',
    email3: 'Email 3',
    email4: 'Email 4',
    phEmail1: 'La tua Email',
    phEmail2: 'La seconda Email',
    phEmail3: 'La terza Email',
    phEmail4: 'La quarta Email',
    secondaMailSped: 'Seconda mail di spedizione',
    terzaMailSped: 'Terza mail di spedizione',
    quartaMailSped: 'Quarta  mail di spedizione',
    bthome: 'Torna alla Home',
    attivo: 'Attivo',
    nonAttivo: 'Non Attivo',
    datiFornitura: 'Dati Fornitura',
    datiAggiuntivi: 'Dati Aggiuntivi',
    datiSpedizione: 'Dati Spedizione',
    datiDiPagamento: 'Dati di Pagamento',
    datiUtente: 'Dati Utente',
    CognomeNomeoRagSociale: 'Cognome Nome o Rag.Sociale',
    modalitaInvio: 'Modalità Invio',
    CoordinateBancarie: 'Coordinate Bancarie',
    frazione: 'Frazione',
    scala: 'Scala',
    piano: 'Piano',
    interno: 'Interno',
    contatti: 'Contatti',
    //Contract Data
    contractDataTitle: 'Creazione Contratto',
    contractDataSubtitle: 'Iniziamo con qualche dato preliminare',
    agentName: 'Nome agente',
    contractNumber: 'Numero contratto',
    contractPlace: 'Luogo stipula contratto',
    contractCategory: 'Categoria contrattuale',
    contractType: 'Tipologia contratto',
    insertChannel: 'Canale di inserimento',
    clientType: 'Tipo cliente',
    codiceCliente: 'Codice Cliente',
    codiceUtenza: 'Codice Utenza',
    puntoGas: 'Codice Utenza',
    statoContratto: 'Stato Contratto',
    domiciliazioneLabelIniziale:
        "L'indirizzo di fornitura dell''impianto per il quale vuoi richiedere o revocare la domiciliazione delle fattura è:",
    disdettaLabelIniziale:
        "L'indirizzo di fornitura dell''impianto per il quale vuoi chiedere la disdetta del contratto è:",
    nominativoLegRapp: 'NominativoLegRapp',
    codiceFiscaleLegRapp: 'CodiceFiscaleLegRapp',
    nominativoLegRappForm: 'Nominativo Legale Rappresentante',
    codiceFiscaleLegRappForm: 'Codice Fiscale Legale Rappresentante',

    //Contract Personale data
    userDataTitle: 'Dati anagrafici',
    userDataSubtitle: 'Compila i dati personali',
    dateOfBirth: 'Data di nascita',
    fiscalCode: 'Codice fiscale',
    fiscalCodeInvalid: 'Il codice fiscale non è valido',
    fiscalCodeNotMatch: 'Il codice fiscale non corrisponde ai dati anagrafici',
    cellPhone: 'Cellulare',
    phone: 'Telefono',
    streetName: 'Via',
    streetNumber: 'N. Civico',
    streetExtension: 'Barrato',
    province: 'Provincia',
    city: 'Città',
    pec: 'Pec',
    countryCode: 'Codice paese',
    zipCode: 'CAP',
    provinceOfBirth: 'Provincia di nascita',
    cityOfBirth: 'Luogo di nascita',
    gender: 'Sesso',
    documentType: 'Tipo di documento',
    documentNumber: 'Numero del documento',
    documentPlaceOfIssue: 'Comune di rilascio',
    documentDateOfIssue: 'Data di rilascio',
    documentExpiration: 'Scadenza documento',
    ouCode: 'Codice OU',
    country: 'Paese',
    codiceUtente: 'Codice Utente',
    complessitaPassword: 'Regole di complessità della Password non rispettate',
    codiceUtentePlaceHolder: 'Codice Utente',
    mailSentToUser:
        "E' stata inviata una mail all'utente specificato con l'informazione richiesta",
    failedRetry: 'Operazione fallita, si prega di riprovare più tardi',

    //FatturaViaMail
    soloMail: 'Ricezione fattura solo per email',
    mailEPosta: 'Ricezione fattura via email e cartacea',
    receiptNext: 'Dalla prossima fatturazione riceverai la fattura',
    byMail: 'via email',
    cartacea: 'cartacea',
    receiptNextByMail:
        "L'indirizzo Email per la fatturazione è stato modificato.  Attenzione! Al fine di confermare la richiesta fatta verrà inviata un'email di validazione all'indirizzo indicato. Vi preghiamo di seguire le istruzioni della mail.",
    receiptNextCartacea:
        'Dalla prossima fatturazione riceverai la fattura cartacea',
    emailPec: 'Si prega di inserire indirizzi email standard, non pec',    
    //Bollette
    elencoBollette: 'Elenco Fatture',
    nrFattura: 'Numero Fattura',
    importo: 'Importo',
    dataScadenza: 'Data Scadenza',
    statoPagamento: 'Stato Pagamento',
    statoBolletta: 'Stato fattura',
    totaleBolletta: 'Totale fattura',
    pagata: 'Pagata',
    daPagare: 'Da Pagare',
    pagataParzialmente: 'Pagata Parzialmente',
    rateizzata: 'Rateizzata',
    sintetica: 'Sintetica',
    dettagliata: 'Dettagliata',
    dsintetica: 'Scarica Sintetica',
    ddettagliata: 'Scarica Dettagliata',
    fatturaNr: 'Fattura n°',
    bollettaNr: 'Fattura n°',
    fattura: 'Fattura',
    scadenza: 'Scadenza',
    utente: 'Utente',
    stato: 'Stato',
    pdf: 'PDF',
    mostraSoloDaPagare: 'Mostra solo fatture da pagare',
    pagamentiRegolari: 'Tutti i tuoi pagamenti risultano regolari.',
    rimangono: 'Rimangono',
    bolletteDaPagare: 'Fatture Da Pagare',
    consultaBollette: 'Consulta le fatture relative a questa fornitura',
    pagaOra: 'Paga Ora',
    tipoFattura: 'Tipo Fattura',
    periodoRiferimentoDal: 'Periodo di riferimento dal ',

    //Richieste
    elencoRichieste: 'Elenco Richieste',
    inCorso: 'In Corso',
    inElaborazione: 'In Elaborazione',
    presaIncarico: 'Presa in Carico',
    completate: 'Completate',
    completata: 'Completata',
    rifiutata: 'Rifiutata',
    tipoRichiesta: 'Tipologia Richiesta',
    phTipoRichiesta: 'Filtra per Tipologia Richiesta',
    RegistrazioneUtente: 'Registrazione Utente',
    CambioRecapito: 'Cambio Recapito',
    DomiciliazioneBancaria: 'Domiciliazione Bancaria',
    AgevolImposte: 'Agevolazione Imposte',
    DisdettaContratto: 'Disdetta Contratto',
    Rettifica: 'Rettifica',
    PagamentoBolletta: 'Pagamento Fattura',
    Informazioni: 'Informazioni',
    Reclami: 'Reclami',
    Autolettura: 'Autolettura',
    Offerta: 'Offerta',
    Apertura: 'Apertura',
    AperturaRange: 'Apertura range',
    Preventivo: 'Preventivo',
    ModificaDatiAnagrafici: 'Modifica Dati Anagrafici',
    DatiCatastali: 'Dati Catastali',
    ContattoGenerico: 'Contatto Generico',
    FatturaOnline: 'Fattura via Email',
    ContattoCommerciale: 'Contatto Commerciale',
    CambioBollettazione: 'Cambio Fatturazione',
    InformazioniAltriUsi: 'Informazioni Altri Usi',
    InformazioniGrandiClienti: 'Informazioni Grandi Clienti',
    Repricing: 'Repricing',
    Voltura: 'Voltura',
    ResidenzaSuUtenza: 'Residenza Su Utenza',
    ModificaPotenza: 'Modifica Potenza',
    GestionePrivacy: 'Gestione Privacy',
    RichiediPINUtente: 'Richiedi PIN Utente',
    RichiediPINNoMail: 'Richiedi PIN No Mail',
    ConfermaLinkReg: 'Conferma Link Registrazione',
    PagamentoRicorrente: 'Pagamento Ricorrente',
    PagamentoRicorrenteModificaEmail: 'Pagamento Ricorrente Modifica Email',
    PresaInCarico: 'PRESA IN CARICO',
    InLavorazione: 'IN LAVORAZIONE',
    ConclusaPositivamente: 'CONCLUSA POSITIVAMENTE',
    requestDetails: 'DETTAGLI DELLA RICHIESTA',
    dataRichiesta: 'Data richiesta',
    faiUnaRichiesta: 'Fai una richiesta',
    dettagliRichiesta: 'Dettagli richiesta',
    richiesteDisponibili: 'Richieste disponibili',
    datiRichiesta: 'Dati Richiesta',
    datiRichiedente: 'Dati Richiedente',
    DatiIntestatariodelConto: 'Dati Intestatario del Conto',
    SiglaIBAN: 'SiglaIBAN',
    CodiceIBAN: 'CodiceIBAN',
    BBANCIN: 'BBAN CIN',
    BBANABI: 'BBAN ABI',
    BBANCAB: 'BBAN CAB',
    BBANNumCC: 'BBAN Num.CC',
    MODALITAINVIOMODULORID: 'MODALITA INVIO MODULO RID',
    TipologiaSDD: 'TipologiaSDD',
    ComuneRichiedente: 'Comune Richiedente',
    CAPRichiedente: 'CAP Richiedente',
    CivicoRichiedente: 'Civico Richiedente',
    TipoVia: 'Tipo via',
    ProvinciaRichiedente: 'Provincia Richiedente',
    TipologiaUtenteRichiesta: 'Tipologia utente richiesta',
    CodiceFiscaleRichiedente: 'Codice Fiscale Richiedente',
    CodComune: 'Cod. Comune',
    numerocivico: 'Numero Civico',
    nominativoRichiedente: 'Nominativo Richiedente',
    comune: 'Comune',
    AnagLegRapNomCognDesc: 'Nominativo legale rappresentante',
    AnagLegRapCFDesc: 'Codice Fiscale legale rappresentante',
    DocumentoFirmatoAttuale: 'Vedi ultimo documento caricato',
    CaricaDocumentoFirmato: 'Carica o sostituisci il documento firmato',
    DocumentoFirmato: 'il documento firmato',
    step1: 'Step 1: Scarica il modello precompilato, stampalo e firmalo',
    step2: 'Step 2: Carica il documento firmato',
    step3: 'Step 3: Invia il documento firmato',
    utenteInserimento: 'Utente Inserimento',
    idRichiesta: 'Codice Richiesta',
    richiesteCessate: 'Non è possibile effettuare richieste per utenze cessate',
    //Privacy
    privacy1: 'Privacy 1',
    privacy2: 'Privacy 2',
    privacy3: 'Privacy 3',
    privacy1desc: 'Privacy 1 Descrizione',
    privacy2desc: 'Privacy 2 Descrizione',
    privacy3desc: 'Privacy 3 Descrizione',
    privacy1claus: 'Trattamento dei dati da parte della società di Vendita',
    privacy2claus:
        'Trattamento dei dati da parte dalle altre società del Gruppo',
    privacy3claus: 'Trattamento automatizzato dei miei dati personali',
    condizioniGenerali: 'Condizioni generali',
    backToCondizioniGenerali: 'Torna alla sezione Condizioni generali',
    goToPrivacy: 'Prosegui alla sezione Privacy',
    privacy: 'Privacy',
    accetto: 'Accetto',
    nonAccetto: 'Non accetto',
    terminiDiServizio:
        "Utilizziamo cookie proprietari e di terze parti al fine di migliorare i nostri servizi.\nContinuando la navigazione, acconsenti all'uso degli stessi.",

    //Disdetta Contratto
    dataLetturaPrecedente: 'Data Lettura Precedente',
    ultimaLetturaF1: 'Ultima Lettura F1',
    ultimaLetturaF2: 'Ultima Lettura F2',
    ultimaLetturaF3: 'Ultima Lettura F3',
    motivoDisdetta: 'Richiede la disdetta del contratto per',
    richiedeChiusuraContatore: 'Richiede la chiusura del contatore *',
    datiRecapitoUltimaFattura:
        'DATI RECAPITO ULTIMA FATTURA (se diverso da attuale)',
    letturaPrecedente: 'Lettura Precedente',
    dataUltimaLettura: 'Data Ultima Lettura',
    viaDefault: 'VIALE',
    barrato: 'Barrato',
    confirmCancellation: 'Conferma Disdetta',

    //Dati Contratto
    datiContratto: 'Dati Contratto',
    nomeOfferta: 'Nome Offerta',
    phNomeOfferta: "L'offerta attualmente attiva",
    matricolaContatore: 'Matricola Contatore',
    phMatricolaContatore: 'La matricola del contatore',
    phIndirizzoFornitura: "L'indirizzo della fornitura",
    phCAPfornitura: 'Il CAP della fornitura',
    tipoServizio: 'Tipo Servizio',
    phTipoServizio: 'La tipologia del servizio della fornitura',
    statoFornitura: 'Stato Fornitura',
    phStatoFornitura: 'Lo stato della fornitura',
    phPodPdr: 'Il POD/PDR della fornitura',
    tipoUso: 'Tipo Uso',
    phTipoUso: 'Il tipo uso della fornitura',
    consumoPresuntoAnnuo: 'Consumo Presunto',
    phConsumoPresuntoAnnuo: 'Il Consumo presunto della fornitura',
    aliquotaIVA: 'Aliquota IVA',
    phAliquotaIVA: 'Aliquota IVA della fornitura',
    periodoFatturazione: 'Periodo Fatturazione',
    phPeriodoFatturazione: 'Il Periodo di fatturazione della fornitura',
    tipoFatturazione: 'Tipo Fatturazione',
    phTipoFatturazione: 'Il Tipo di fatturazione della fornitura',
    servizioFatturaONline: 'Servizio Fattura Online',
    phServizioFatturaOnline: 'Lo stato del servizio Fattura Online',
    cognomeNomeRecapito: 'Nominativo di Recapito',
    phCognomeNomeRecapito: 'Nominativo di Recapito della fornitura',
    indirizzoRecapito: 'Indirizzo di Recapito',
    phIndirizzoRecapito: 'Indirizzo di Recapito della fornitura',
    metodoPagamento: 'Metodo di pagamento',
    phMetodoDiPagamento: 'Metodo di pagamento della fornitura',
    statoDomiciliazione: 'Stato della domiciliazione Bancaria',
    phStatoDomiciliazione:
        'Stato della domiciliazione Bancaria della fornitura',
    ibanPaese: 'IBAN',
    phIbanPaese: 'IBAN',
    descrizioneBanca: 'Descrizione Banca',
    phDescrizioneBanca: 'Descrizione Banca',
    dettagliContratto: 'Dettagli contratto',
    visualizzaDatiContratto: 'Dati Contratto Estesi',
    nonVisualizzaDatiContratto: 'Dati Contratto Estesi',
    tipologiaSSD: 'Tipologia SSD',
    statoUtenza: 'Stato Utenza',
    regoleIban: '[AA(1)][00(2)][A(3)][00000(4)][00000(5)][000000000000(6)]',
    regoleIban1: '1) Sigla Internazionale, 2 caratteri alfabetici',
    regoleIban2: '2) Codice controllo, 2 caratteri numerici',
    regoleIban3: '3) Codice Controllo CIN, 1 carattere alfabetico',
    regoleIban4: '4) Codice ABI, 5 caratteri numerici',
    regoleIban5: '5) Codice CAB, 5 caratteri numerici',
    regoleIban6: '6) Numero di conto corrente, 12 caratteri alfanumerici',
    ibanNonValido: 'IBAN Non Valido',
    //Forniture
    NonDefinita: 'Non Definita',
    Attiva: 'Attiva',
    Cessata: 'Cessata',
    Sospesa: 'Sospesa',
    InCessazione: 'In Cessazione',
    RichiestaDisdetta: 'Richiesta Disdetta',
    NonFatturabile: 'Non Fatturabile',
    Errata: 'Errata',

    //Autolettura
    letturaCorrettore: 'Lettura Correttore',
    phLetturaCorrettore: 'Lettura Correttore',
    letturaContatore: 'Lettura Contatore',
    phLetturaContatore: 'Lettura Contatore',
    phUltimaLettura: 'Ultima Lettura',
    dataLettura: 'Data Lettura',
    dataLetturaPrecendente: 'Data Lettura Precedente',
    letturaContatoreF1: 'Lettura Contatore F1',
    phLetturaContatoreF1: 'Lettura Contatore F1',
    letturaContatoreF2: 'Lettura Contatore F2',
    phLetturaContatoreF2: 'Lettura Contatore F2',
    letturaContatoreF3: 'Lettura Contatore F3',
    phLetturaContatoreF3: 'Lettura Contatore F3',
    dataLetturaNonValida: 'Data lettura non valida',
    dataLetturaAnteUltima:
        "La data lettura non può essere antecedente all'ultima lettura effettuata",
    valoreLetturaAnteUltima:
        "La lettura attuale non può essere minore all'ultima lettura effettuata",
    valoreLetturaNonValida: 'Il valore inserito non è una lettura valida',
    rangeClosed:
        "Servizio di autolettura per questa fornitura è al momento non disponibile, attendere l'apertura del prossimo range",
    rangeEEdisabled:
        'Servizio di autolettura disabilitato per forniture di Energia Elettrica',
    multifascia: 'Multifascia',
    monofascia: 'Monofascia',
    storicoAutoletture: 'Storico letture consumi',
    reale: 'Reale',
    cliente: 'Cliente',
    ultimaLettura: 'Ultima Lettura',
    rangeOpenDate: 'Il range autolettura sarà aperto',
    tipoFornitura: 'Tipo Fornitura',
    matricolaCorrettore: 'Matricola Correttore',
    autoletturaDisabilitataPerTipoUso:"L'autolettura non è abilitata per la tipologia di fornitura selezionata",
    disabilitaDisdettaContratto: 'Non è possibile proseguire con la richiesta in quanto la tipologia di fornitura selezionata non è abilitata',
    //CambioPassword
    oldPassword: 'Vecchia Password',
    newPassword: 'Nuova Password',
    repeatNewPassword: 'Ripeti nuova Password',
    phOldPassword: 'La vecchia Password',
    phNewPassword: 'La nuova Password',
    phRepeatNewPassword: 'Ripeti nuova Password',
    passwordNonCoincidono: 'Le password non coincidono',

    //Sportelli
    sportelli: 'Sportelli',

    //Consumi
    consumi: 'Consumi',
    consumiLasyYear: 'Consumi degli ultimi 12 mesi',
    consumo: 'Consumo',
    gennaio: 'Gennaio',
    febbraio: 'Febbraio',
    marzo: 'Marzo',
    aprile: 'Aprile',
    maggio: 'Maggio',
    giugno: 'Giugno',
    luglio: 'Luglio',
    agosto: 'Agosto',
    settembre: 'Settembre',
    ottobre: 'Ottobre',
    novembre: 'Novembre',
    dicembre: 'Dicembre',
    annoPrecedente: 'Anno Precedente',
    annoInCorso: 'Anno In Corso',
    StandardMetroCubo: 'Smc',
    MetroCubo: 'Mc',
    KilowattOra: 'Kwh',
    //Codici Esiti
    APP_MSG_EMAIL_VALIDATION_SUCCESS: 'Email correttamente validata',
    APP_MSG_EMAIL_VALIDATION_ERROR: "Errore durante la validazione dell'Email",
    APP_MSG_EMAIL_VALIDATION_TOKEN_ERROR:
        'Errore durante la validazione del link',

    //Assistenza
    contactNotes: 'Note di contatto',
    contactTime: 'Orario indicativo di contatto',
    streetType: 'Tipo via',
    streetSepatator: 'Barrato',
    sendSupportRequest: 'Invia richiesta di assistenza',
    richiediAssistenza: 'Richiedi Assistenza',

    //Change Address
    sendChangeAddressRequest: 'Invia richiesta di cambio recapito',

    //InformazioniAzienda
    infoMail: 'info@dotblue.it',

    //UL
    other: 'Altro',
    hello: 'Ciao',

    //FatturaViaMail
    Email: 'Email',
    Misto: 'Email+Posta',

    //Reclami
    assistenzaEReclami: 'Assistenza e Reclami',
    amministratore: 'AMM. CONDOMINIO',
    category: 'Categoria',
    canaleAbilitato: 'Canale',
    claimsRequestTypes: 'Tipo richiesta',
    contactTypeOwner: 'Titolare',
    contactTypeAgent: 'Rappresentante',
    contactType: 'Tipo Contatto',
    contactName: 'Nominativo Contatto',
    occupation: 'Occupazione',
    emailSender: 'Email Mittente',
    phoneSender: 'Telefono Chiamante',
    emailForContact: 'Email Per Ricontatto',
    phoneForContact: 'Telefono Per Ricontatto',
    claimDate: 'Data Ricezione',
    claimTime: 'Ora Ricezione',
    areraCategory: 'Categoria ARERA',
    editClaim: 'Modifica Reclamo',

    //Ricerca clienti
    clients: 'Clienti',
    selectClient: 'Seleziona cliente',
    deselectClient: 'Deseleziona',
    selectedClient: 'Codice Utente Selezionato',

    //Ricerca richieste
    nominativoCliente: 'Nominativo Cliente',
    utenteSito: 'Utente',

    //Stati Richiesta
    nuova: 'Nuova',
    inLavorazione: 'In Lavorazione',
    inEvasione: 'In Evasione',
    evasa: 'Evasa',
    nuovaDaValidare: 'Nuova Da Validare',
    confermata: 'Confermata',
    validataBo: 'Validata BO',
    rigettataBo: 'Rigettata BO',
    cancellata: 'Cancellata',
    annullata: 'Annullata',
    bozza: 'Bozza',
    inCompilazione: 'In Compilazione',
    assegnata: 'Assegnata',
    inAttesaDiModulo: 'In Attesa di Modulo',
    moduloNonCompletato: 'Modulo non completato',
    moduloOK: 'Modulo OK',
    ricevuta: 'Ricevuta',
    avviata: 'Avviata',
    inviata: 'Inviata',
    inammissibile: 'Inammissibile',
    ammissibile: 'Ammissibile',
    esitoNegativo: 'Esito Negativo',
    inammissibileNonAnnullata: 'Inammissibile Non Annullata',
    richiestaAnnullatamento: 'Richiesta Annullatamento',
    anullataSNC: 'Annullata SNC',
    annullataDS: 'Annullata DS',
    chiusa: 'Chiusa',

    //Edit Richiesta Da Operatore
    assignee: 'Incaricato',
    assignedFrom: 'Incaricato da',
    nextContactDay: 'Data prossimo contatto',
    nextContactTime: 'Ora prossimo contatto',
    requestStatus: 'Stato attività',
    notes: 'Note',

    //Ricontrattualizzazione
    ricontrattualizzazione: 'Ricontrattualizzazione',
    cambioOfferta: 'Cambio Offerta!',
    cambioOffertaInCorso: 'Cambio Offerta in corso!',
    datiMancanti:
        'Dati obbligatori mancanti, la preghiamo di recarsi allo sportello',
    claimRepliesNew: 'Nuova risposta',
    claimRepliesList: 'Elenco Risposte',
    claimRepliesListEmpty: 'Nessuna risposta',
    showClaimReplies: 'Mostra sezione risposte',
    hideClaimReplies: 'Nascondi sezione risposte',
    claimNotesNew: 'Nuova nota',
    claimNotesList: 'Elenco note',
    claimNotesListEmpty: 'Nessuna nota',
    showClaimNotes: 'Mostra sezione note',
    hideClaimNotes: 'Nascondi sezione note',
    claimAttachmentsNew: 'Carica nuovo allegato',
    claimAttachmentsList: 'Elenco allegati',
    claimAttachmentsListEmpty: 'Nessun allegato',
    showClaimAttachments: 'Mostra sezione allegati',
    hideClaimAttachments: 'Nascondi sezione allegati',
    sendReplyByEmail: 'Inviare risposta via mail',
    insertClaimHeader: 'Inserire intestazione',
    wrongTicket: 'Ticket errato',
    ticketRetention: 'Ticket retention',
    ticketCaring: 'Ticket caring',
    statusNote: 'Nota Stato',
    cancellationReason: 'Motivo annullamento',
    cancellationDate: 'Data chiusura',
    dateRequestSent: 'Data invio richiesta dati tecnici',
    timeRequestSent: 'Ora invio richiesta dati tecnici',
    dateRequestReceived: 'Data ricevimento richiesta dati tecnici',
    timeRequestReceived: 'Ora ricevimento richiesta dati tecnici',
    outgoingData: 'Dati evasione attività',
    claimLimitDate: 'Data limite',
    delayReason: 'Causale ritardo',

    //BRichTextEditor
    showHtml: 'Mostra HTML',
    hideHtml: 'Nascondi HTML',

    //File
    fileId: 'ID',
    fileName: 'Nome file',
    deletionConfirmMessage: 'Sei sicuro di voler eliminare il file?',
    deleteFile: 'Elimina file',
    discardDelete: 'Annulla',
    fileUnsupported: 'File non supportato',

    //Risposte
    replyId: 'ID',
    reply: 'Risposta',
    insertAttachments: 'Allega file alla risposta',
    showReplyAttachments: 'Mostra Allegati',
    withHeader: 'Intestazione',
    showReply: 'Mostra risposta',

    //Note
    noteId: 'ID',
    note: 'Nota',
    showNote: 'Mostra nota completa',
}

export default messages
