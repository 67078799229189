import * as Yup from 'yup'
import { Box, Button } from 'grommet'
import useI18n from '../../hooks/useI18n'
import { UserModel, SupplyModel, AutoletturaModel } from '../../models/models'
import { TipiFornitura } from '../../models/Enums'
import BFormik from '../BFormik'

import { AppStringsType } from '../../services/stringProvider'
import BTextInput from '../BTextInput'
import BDateInput from '../BDateInput'
import { CircleInformation } from 'grommet-icons'
import { checkValidDate, stringDate } from '../../utilities'
import BSeparator from '../BSeparator'
import useSize from '../../hooks/useSize'
import { isEmpty } from 'lodash'

const mystyle = {
    fontWeight: 'bold',
}

const dateTest = (
    strings: AppStringsType,
    lastDate: string | Date | undefined,
) =>
    Yup.string().test('DateCompare', '', (actualDate, { createError }) => {
        if (!actualDate) {
            return createError({ message: strings.mandatoryField })
        }
        if (!checkValidDate(actualDate)) {
            return createError({ message: strings.dataLetturaNonValida })
        }
        const DactualDate = new Date(actualDate)
        const DlastDate = new Date(lastDate!)

        if (DactualDate < DlastDate) {
            return createError({ message: strings.dataLetturaAnteUltima })
        }

        return true
    })

const readTest = (
    strings: AppStringsType,
    lastRead: number | undefined,
    toTest: boolean,
) =>
    Yup.string().test('ReadCompareGG', '', (actualRead, { createError }) => {
        if (!toTest) {
            return true
        }
        if (!actualRead) {
            return createError({ message: strings.mandatoryField })
        }

        if (isNaN(Number(actualRead))) {
            return createError({ message: strings.mustBeNumber })
        }

        if (lastRead && Number(actualRead) < Number(lastRead)) {
            return createError({ message: strings.valoreLetturaAnteUltima })
        }

        return true
    })

const generateSchema = (
    strings: AppStringsType,
    supply: SupplyModel,
    lastDate?: string,
    lastCorr?: number | undefined,
    lastCont?: number | undefined,
    lastContF1?: number | undefined,
    lastContF2?: number | undefined,
    lastContF3?: number | undefined,
) =>
    Yup.object().shape({
        PuntoGas: Yup.string(),
        CodiceUtente: Yup.string(),
        LetturaCont: readTest(strings, lastCont, supply.tipoServizio !== 'E'),
        LetturaCorr: readTest(
            strings,
            lastCorr,
            supply?.tipoServizio === 'G' &&
                Boolean(supply?.matricolaCorrettore),
        ),
        DataLettura: dateTest(strings, lastDate),
        LetturaContF1: readTest(
            strings,
            lastContF1,
            supply.tipoServizio === 'E',
        ),
        LetturaContF2: readTest(
            strings,
            lastContF2,
            supply.tipoServizio === 'E',
        ),
        LetturaContF3: readTest(
            strings,
            lastContF3,
            supply.tipoServizio === 'E',
        ),
        LetturaContEIF1: Yup.number().typeError(strings.mustBeNumber),
        LetturaContEIF2: Yup.number().typeError(strings.mustBeNumber),
        LetturaContEIF3: Yup.number().typeError(strings.mustBeNumber),
        LetturaContEPF1: Yup.number().typeError(strings.mustBeNumber),
        LetturaContEPF2: Yup.number().typeError(strings.mustBeNumber),
        LetturaContEPF3: Yup.number().typeError(strings.mustBeNumber),
        LetturaContERF1: Yup.number().typeError(strings.mustBeNumber),
        LetturaContERF2: Yup.number().typeError(strings.mustBeNumber),
        LetturaContERF3: Yup.number().typeError(strings.mustBeNumber),
        LetturaContPF1: Yup.number().typeError(strings.mustBeNumber),
        LetturaContPF2: Yup.number().typeError(strings.mustBeNumber),
        LetturaContPF3: Yup.number().typeError(strings.mustBeNumber),
        TipoLettura: Yup.string(),
        TipoFornitura: Yup.string(),
    })

const AutoletturaForm = ({
    disabled,
    loading,
    onSubmit,
    onBlur,
    Icon,
    user,
    supply,
    lastDate,
    lastCorr,
    lastCont,
    lastContF1,
    lastContF2,
    lastContF3,
    tipoServizio,
    fromDate,
    toDate,
}: AutoletturaProps) => {
    const strings = useI18n()
    const schema = generateSchema(
        strings,
        supply,
        lastDate,
        lastCorr,
        lastCont,
        lastContF1,
        lastContF2,
        lastContF3,
    )
    const size = useSize()

    return (
        <Box flex>
            <BFormik
                initialValues={{
                    PuntoGas: supply.puntoGas,
                    CodiceUtente: supply.codiceUtente,
                    DataLettura: String(new Date()),
                    LetturaCont: '',
                    LetturaCorr: '',
                    LetturaContF1: '',
                    LetturaContF2: '',
                    LetturaContF3: '',
                    LetturaContEIF1: '',
                    LetturaContEIF2: '',
                    LetturaContEIF3: '',
                    LetturaContEPF1: '',
                    LetturaContEPF2: '',
                    LetturaContEPF3: '',
                    LetturaContERF1: '',
                    LetturaContERF2: '',
                    LetturaContERF3: '',
                    LetturaContPF1: '',
                    LetturaContPF2: '',
                    LetturaContPF3: '',
                    TipoLettura: 'AUTOLETTURA',
                    TipoFornitura: '',
                }}
                enableReinitialize
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={true}
                blurCallback={({ values }) => {
                    if (!disabled) {
                        onBlur?.(values)
                    }
                }}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
                element={({
                    values,
                    errors,
                    validateForm,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                }) => {
                    return (
                        <Box>
                            {fromDate && toDate && (
                                <Box
                                    direction="row"
                                    margin={{ bottom: 'small' }}
                                    gap="small"
                                    align="center"
                                >
                                    <CircleInformation color="brand" />
                                    {`${strings.rangeOpenDate} ${
                                        strings.dal
                                    } ${stringDate(fromDate)} ${
                                        strings.al
                                    } ${stringDate(toDate)}`}
                                </Box>
                            )}
                            <Box
                                align={size !== 'small' ? 'start' : 'none'}
                                flex
                                gap="medium"
                                direction={size === 'small' ? 'column' : 'row'}
                            >
                                <Box
                                    flex
                                    direction="column"
                                    pad="medium"
                                    elevation="medium"
                                    round="small"
                                >
                                    <Box style={mystyle}>
                                        {strings.ultimaLettura}
                                    </Box>
                                    <BSeparator
                                        size="2px"
                                        margin={{ vertical: '10px' }}
                                    />
                                    <Box gap="medium">
                                        <Box direction="row" justify="between">
                                            <Box>{strings.tipoServizio}</Box>
                                            <Box style={mystyle}>
                                                {supply?.tipoServizio === 'E'
                                                    ? strings.E
                                                    : supply?.tipoServizio ===
                                                      'GPL'
                                                    ? strings.GPL
                                                    : strings.G}
                                            </Box>
                                        </Box>
                                        <Box direction="row" justify="between">
                                            <Box>
                                                {strings.dataLetturaPrecendente}
                                            </Box>
                                            <Box style={mystyle}>
                                                {stringDate(
                                                    supply?.dataLettura!,
                                                )}
                                            </Box>
                                        </Box>
                                        {supply?.tipoServizio === 'E' ? (
                                            <Box gap="medium">
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {
                                                            strings.letturaContatoreF1
                                                        }
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {supply?.letturaF1}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {
                                                            strings.letturaContatoreF2
                                                        }
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {supply?.letturaF2}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {
                                                            strings.letturaContatoreF3
                                                        }
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {supply?.letturaF3}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {strings.tipoFornitura}
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {supply?.tipoFornitura ===
                                                        TipiFornitura.MultiFascia
                                                            ? strings.multifascia
                                                            : strings.monofascia}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box gap="medium">
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {
                                                            strings.matricolaContatore
                                                        }
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {
                                                            supply?.matricolaContatore
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box
                                                    direction="row"
                                                    justify="between"
                                                >
                                                    <Box>
                                                        {
                                                            strings.letturaContatore
                                                        }
                                                    </Box>
                                                    <Box style={mystyle}>
                                                        {supply?.lettura}
                                                    </Box>
                                                </Box>
                                                {supply?.tipoServizio === 'G' &&
                                                supply?.matricolaCorrettore !==
                                                    '' ? (
                                                    <Box gap="medium">
                                                        <Box
                                                            direction="row"
                                                            justify="between"
                                                        >
                                                            <Box>
                                                                {
                                                                    strings.matricolaCorrettore
                                                                }
                                                            </Box>
                                                            <Box
                                                                style={mystyle}
                                                            >
                                                                {
                                                                    supply?.matricolaCorrettore
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            direction="row"
                                                            justify="between"
                                                        >
                                                            <Box>
                                                                {
                                                                    strings.matricolaCorrettore
                                                                }
                                                            </Box>
                                                            <Box
                                                                style={mystyle}
                                                            >
                                                                {
                                                                    supply?.letturaCorrettore
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ) : undefined}
                                            </Box>
                                        )}
                                        <Box direction="row" justify="between">
                                            <Box>
                                                {supply?.tipoServizio === 'E'
                                                    ? strings.pod
                                                    : strings.pdr}
                                            </Box>
                                            <Box style={mystyle}>
                                                {supply?.pod}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={size !== 'small'}>
                                    <Box
                                        onClick={(e) => e.stopPropagation()}
                                        direction="row"
                                        gap="small"
                                        margin={{ bottom: '12px' }}
                                    >
                                        <BDateInput
                                            //style={{ Height:"50px" }}
                                            containerProps={{
                                                flex: true,
                                                width: 'small',
                                            }}
                                            mandatory
                                            //disabled={type === 'disabled'}
                                            name="DataLettura"
                                            format="dd/mm/yyyy"
                                            label={strings.dataLettura}
                                            placeholder={strings.dataLettura}
                                            handleChange={handleChange}
                                            values={values}
                                            errors={errors}
                                        />
                                    </Box>
                                    {(supply?.tipoServizio === 'G' ||
                                        supply?.tipoServizio === 'GPL') && (
                                        <Box>
                                            <Box
                                                gap="small"
                                                direction="row"
                                                align="end"
                                                wrap
                                            >
                                                <BTextInput
                                                    fast
                                                    //disabled={disabled}
                                                    containerProps={{
                                                        flex: true,
                                                    }}
                                                    name="LetturaCont"
                                                    mandatory
                                                    label={
                                                        strings.letturaContatore
                                                    }
                                                    placeholder={
                                                        strings.phLetturaContatore
                                                    }
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                />
                                            </Box>
                                            {supply?.tipoServizio === 'G' &&
                                                supply?.matricolaCorrettore !==
                                                    undefined &&
                                                supply?.matricolaCorrettore !==
                                                    '' && (
                                                    <Box
                                                        gap="small"
                                                        direction="row"
                                                        align="end"
                                                        wrap
                                                    >
                                                        <BTextInput
                                                            fast
                                                            //disabled={disabled}
                                                            containerProps={{
                                                                flex: true,
                                                            }}
                                                            name="LetturaCorr"
                                                            mandatory
                                                            label={
                                                                strings.letturaCorrettore
                                                            }
                                                            placeholder={
                                                                strings.phLetturaCorrettore
                                                            }
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            values={values}
                                                            errors={errors}
                                                        />
                                                    </Box>
                                                )}
                                        </Box>
                                    )}
                                    {supply?.tipoServizio === 'E' && (
                                        <Box>
                                            {supply?.tipoFornitura ===
                                                TipiFornitura.MultiFascia && (
                                                <Box>
                                                    <BTextInput
                                                        fast
                                                        //disabled={disabled}
                                                        containerProps={{
                                                            flex: true,
                                                        }}
                                                        name="LetturaContF1"
                                                        mandatory
                                                        label={
                                                            strings.letturaContatoreF1
                                                        }
                                                        placeholder={
                                                            strings.phLetturaContatoreF1
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        values={values}
                                                        errors={errors}
                                                    />
                                                    <BTextInput
                                                        fast
                                                        //disabled={disabled}
                                                        containerProps={{
                                                            flex: true,
                                                        }}
                                                        name="LetturaContF2"
                                                        mandatory
                                                        label={
                                                            strings.letturaContatoreF2
                                                        }
                                                        placeholder={
                                                            strings.phLetturaContatoreF2
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        values={values}
                                                        errors={errors}
                                                    />
                                                </Box>
                                            )}
                                            <BTextInput
                                                fast
                                                //disabled={disabled}
                                                containerProps={{ flex: true }}
                                                name="LetturaContF3"
                                                label={
                                                    strings.letturaContatoreF3
                                                }
                                                placeholder={
                                                    strings.phLetturaContatoreF3
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Button
                                color="primary_color"
                                focusIndicator={false}
                                disabled={loading}
                                margin={
                                    size === 'small'
                                        ? undefined
                                        : { top: 'medium' }
                                }
                                style={{
                                    width: '100%',
                                    height: '50px',
                                }}
                                onClick={async () => {
                                    try {
                                        const res = await validateForm()

                                        if (isEmpty(res)) {
                                            handleSubmit()
                                        }
                                    } catch (e) {}
                                }}
                                label={strings.save}
                                primary
                            />
                        </Box>
                    )
                }}
            />
        </Box>
    )
}

export default AutoletturaForm

interface AutoletturaProps {
    loading?: boolean
    onBlur?: (autolettura: AutoletturaModel) => any
    onSubmit: (
        autolettura: AutoletturaModel,
    ) => void | Promise<void> | Promise<AutoletturaModel>
    user: UserModel
    supply: SupplyModel
    supplies?: SupplyModel[]
    Icon?: JSX.Element
    disabled?: boolean
    lastDate?: string
    lastCorr?: number | undefined
    lastCont?: number | undefined
    lastContF1?: number | undefined
    lastContF2?: number | undefined
    lastContF3?: number | undefined
    tipoServizio?: string
    fromDate?: string
    toDate?: string
    abilitaAutoletturaEE?: number
}
