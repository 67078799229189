import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import useI18n from '../../hooks/useI18n'
import BTextInput from '../BTextInput'
import { Box, Button, CheckBox, Collapsible, Spinner, Text } from 'grommet'
import BSelect from '../BSelect'
import { useEffect, useState } from 'react'
import useAppQuery from '../../hooks/useAppQuery'
import getClaimsRequestTypes from '../../services/store/actions/getClaimsRequestTypes'
import getEnabledChannels from '../../services/store/actions/getEnabledChannels'
import getClaimsSubCatogories from '../../services/store/actions/getClaimsSubCatogories'
import getClaimsARERACategories from '../../services/store/actions/getClaimsARERACatogories'
import {
    compareDateStrings,
    getAllowedClaimsExtensions,
    phoneRegExp,
    validateClaimFiles,
} from '../../utilities'
import BDateInput from '../BDateInput'
import BTimeInput from '../BTimeInput'
import { ClaimsSubCategories } from '../../models/models'
import BTextArea from '../BTextArea'
import { CanaliAbilitati, ClaimContactType } from '../../models/Enums'
import useIsOperator from '../../hooks/useIsOperator'
import useRequiredFields from '../../hooks/useRequiredFields'
import BRichTextEditor from '../BRichTextEditor'
import BFileInput from '../BFileInput'
import loadAgentsAndOperators from '../../services/store/actions/loadAgentsAndOperators'
import {
    deleteAllegatoReclamo,
    downloadAllegatoReclamo,
    getCausaliRitardoReclamo,
    getMotiviAnnullamentoReclamo,
    getNoteStato,
    getStatiRichiestaReclamo,
} from '../../services/store/actions'
import { DettaglioReclamoOperatore } from '../../services/api/loadDettaglioReclamoOperatoreAPI'
import FilesTable from '../FilesTable'
import BCaretCollapsible from '../BCaretCollapsible'
import RepliesTable from '../RepliesTable'
import NotesTable from '../NotesTable'

type SelectNumberType = { label: string; value: number }[]

const CLAIM_REJECTED = 130
const CLAIM_CLOSED = 211

const EditReclamoOperatorForm = ({
    onSubmit,
    isLoading = false,
    initialValues,
    disabled,
}: ReclamiFormProps) => {
    const strings = useI18n()
    const [claimsTypes, setClaimsTypes] = useState<SelectNumberType>([])
    const [claimsSubTypes, setClaimsSubTypes] = useState<SelectNumberType>([])
    const [claimChannels, setClaimChannels] = useState<SelectNumberType>([])
    const [ARERACategories, setARERACategories] = useState<SelectNumberType>([])
    const [subCategories, setSubCategories] = useState<ClaimsSubCategories[]>(
        [],
    )
    const [noteStato, setNoteStato] = useState<SelectNumberType>([])
    const [statiRichiesta, setStatiRichiesta] = useState<SelectNumberType>([])
    const [motiviAnnullamento, setMotiviAnnullamento] =
        useState<SelectNumberType>([])
    const [causaliRitardo, setCausaliRitardo] = useState<SelectNumberType>([])
    const [availableOperators, setAvailableOperators] =
        useState<SelectNumberType>([])
    const [showNotes, setShowNotes] = useState(false)
    const [showReplies, setShowReplies] = useState(false)
    const [showAttachments, setShowAttachments] = useState(false)
    const [loadingTypes, queryClaimTypes] = useAppQuery(getClaimsRequestTypes)
    const [loadingChannels, queryChannels] = useAppQuery(getEnabledChannels)
    const [loadingSubTypes, querySubTypes] = useAppQuery(getClaimsSubCatogories)
    const [loadingARERACategories, queryARERACategories] = useAppQuery(
        getClaimsARERACategories,
    )
    const [loadingOperators, queryOperators] = useAppQuery(
        loadAgentsAndOperators,
    )
    const [loadingNoteStato, queryNoteStato] = useAppQuery(getNoteStato)
    const [loadingStatiRichiesta, queryStatiRichiesta] = useAppQuery(
        getStatiRichiestaReclamo,
    )
    const [, queryMotiviAnnullamento] = useAppQuery(
        getMotiviAnnullamentoReclamo,
    )
    const [, queryCausaliRitardo] = useAppQuery(getCausaliRitardoReclamo)
    const [, queryDownloadAllegatoReclamo] = useAppQuery(
        downloadAllegatoReclamo,
    )
    const [, queryDeleteAllegatoReclamo] = useAppQuery(deleteAllegatoReclamo)
    const isOperator = useIsOperator()
    const isClaimClosedOrRejected = (idStatoRichiesta: number) => {
        return (
            idStatoRichiesta === CLAIM_CLOSED ||
            idStatoRichiesta === CLAIM_REJECTED
        )
    }

    const isClaimClosed = (idStatoRichiesta: number) => {
        return idStatoRichiesta === CLAIM_CLOSED
    }

    const isClaimRejected = (idStatoRichiesta: number) => {
        return idStatoRichiesta === CLAIM_REJECTED
    }

    const isClaimResolutionLate = (
        closingDate?: string,
        limitDate?: string,
    ) => {
        if (!closingDate || !limitDate) {
            return false
        }

        return compareDateStrings(closingDate, limitDate) > 0
    }

    const RequestClaimsSchema = useMemo(
        () =>
            Yup.object().shape({
                nominativoContatto: Yup.string().required(
                    strings.mandatoryField,
                ),
                idTipoContatto: Yup.number().required(strings.mandatoryField),
                descrizione: Yup.string().required(strings.mandatoryField),
                occupazione: Yup.string()
                    .nullable()
                    .when('contactType', {
                        is: ClaimContactType.RAPPRESENTANTE,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                idTipoRichiesta: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idSottoCategoria: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idCanale: Yup.number()
                    .required(strings.mandatoryField)
                    .positive(strings.mandatoryField),
                idCategoria: isOperator
                    ? Yup.number().positive(strings.mandatoryField).required()
                    : Yup.number(),
                telefonoChiamante: Yup.string()
                    .nullable()
                    .matches(phoneRegExp, strings.mandatoryField)
                    .min(7, strings.mandatoryField)
                    .notRequired(),
                emailMittente: Yup.string()
                    .nullable()
                    .email(strings.mandatoryField),
                telefonoPerRicontatto: Yup.string()
                    .nullable()
                    .matches(phoneRegExp, strings.mandatoryField)
                    .min(7, strings.mandatoryField)
                    .when('idCanale', {
                        is: CanaliAbilitati.PHONE,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                emailPerRicontatto: Yup.string()
                    .nullable()
                    .email(strings.mandatoryField)
                    .when('idCanale', {
                        is: CanaliAbilitati.EMAIL,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                dataRichiesta: Yup.string().required(strings.mandatoryField),
                oraRichiesta: Yup.string().required(strings.mandatoryField),
                dataLimite: Yup.string(),
                testoNoteInput: Yup.string().nullable().notRequired(),
                testoRispostaInput: Yup.string().nullable().notRequired(),
                allegatoInput: Yup.mixed()
                    .nullable()
                    .test(
                        'is-valid-claim-type',
                        strings.fileUnsupported,
                        (value: FileList) => {
                            return validateClaimFiles(value, false)
                        },
                    ),
                allegatoRispostaInput: Yup.mixed()
                    .nullable()
                    .test(
                        'is-valid-reply-type',
                        strings.fileUnsupported,
                        (value: FileList) => {
                            return validateClaimFiles(value, false)
                        },
                    ),
                inviareRispostaViaMail: Yup.boolean(),
                inserireIntestazione: Yup.boolean(),
                ticketErrato: Yup.boolean().nullable(),
                ticketRetention: Yup.boolean().nullable(),
                ticketCaring: Yup.boolean().nullable(),
                idIncaricato: Yup.number().nullable(),
                idStato: Yup.number().required(strings.mandatoryField),
                idNotaStato: Yup.number().nullable(),
                idMotivoAnnullamento: Yup.number()
                    .nullable()
                    .when('idStato', {
                        is: (stato: number) => isClaimRejected(stato),
                        then: Yup.number().required(strings.mandatoryField),
                    }),
                dataChiusuraRichiesta: Yup.string().when('idStato', {
                    is: (stato: number) => isClaimClosedOrRejected(stato),
                    then: Yup.string().required(strings.mandatoryField),
                }),
                idCausaleRitardo: Yup.number()
                    .nullable()
                    .when(['dataChiusuraRichiesta', 'dataLimite'], {
                        is: (date: string, limit: string) =>
                            isClaimResolutionLate(date, limit),
                        then: Yup.number().required(strings.mandatoryField),
                    }),
                dataInizioInvioDatiTecnici: Yup.string()
                    .nullable()
                    .when('oraInizioInvioDatiTecnici', {
                        is: (value: string) => !!value,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                oraInizioInvioDatiTecnici: Yup.string().nullable(),
                dataFineRicezioneDatiTecnici: Yup.string()
                    .nullable()
                    .when('oraFineRicezioneDatiTecnici', {
                        is: (value: string) => !!value,
                        then: Yup.string().required(strings.mandatoryField),
                    }),
                oraFineRicezioneDatiTecnici: Yup.string().nullable(),
            }),
        [isOperator, strings.fileUnsupported, strings.mandatoryField],
    )

    const isRequired = useRequiredFields(RequestClaimsSchema)
    const loadInitialData = useCallback(async () => {
        const [
            types,
            cat,
            channels,
            operators,
            noteStato,
            statiRichiesta,
            motiviAnnullamento,
            causaliRitardo,
        ] = await Promise.all([
            queryClaimTypes(),
            querySubTypes(),
            queryChannels(),
            queryOperators(),
            queryNoteStato(),
            queryStatiRichiesta(),
            queryMotiviAnnullamento(),
            queryCausaliRitardo(),
        ])
        const typeOptions = types.map((it) => ({
            label: it.tipoRichiesta,
            value: it.idTipoRichiesta,
        }))
        const defaultCategoryOptions = isOperator ? [] : cat
        const categoryOptions = !initialValues?.idCategoria
            ? defaultCategoryOptions
            : cat.filter((it) => it.idCategoria === initialValues.idCategoria)
        const subTypes = categoryOptions.map((it) => ({
            label: it.sottocategoria,
            value: it.idSottoCategoriaUtility,
        }))
        const channelOptions = channels.map((it) => ({
            label: it.canale,
            value: it.idCanale,
        }))
        const operatorOptions = operators.map((it) => ({
            label: it.nominativo,
            value: it.idUtente,
        }))
        const noteStatoOptions = noteStato.map((it) => ({
            label: it.notaStato,
            value: it.idNotaStato,
        }))
        const statiRichiestaOptions = statiRichiesta.map((it) => ({
            label: it.statoRichiesta,
            value: it.idStatoRichiesta,
        }))
        const motiviAnnullamentoOptions = motiviAnnullamento.map((it) => ({
            label: it.motiviAnnullamento,
            value: it.idMotivoAnnullamento,
        }))
        const causaliRitardoOptions = causaliRitardo.map((it) => ({
            label: it.causaleRitardo,
            value: it.idCausaleRitardo,
        }))
        setClaimsTypes(typeOptions)
        setClaimsSubTypes(subTypes)
        setClaimChannels(channelOptions)
        setSubCategories(cat)
        setAvailableOperators(operatorOptions)
        setNoteStato(noteStatoOptions)
        setStatiRichiesta(statiRichiestaOptions)
        setMotiviAnnullamento(motiviAnnullamentoOptions)
        setCausaliRitardo(causaliRitardoOptions)
        if (isOperator) {
            const arera = await queryARERACategories()
            setARERACategories(
                arera.map((it) => ({
                    value: it.idCategoria,
                    label: it.descrizioneCategoria,
                })),
            )
        }
    }, [
        queryClaimTypes,
        querySubTypes,
        queryChannels,
        queryOperators,
        queryNoteStato,
        queryStatiRichiesta,
        queryMotiviAnnullamento,
        queryCausaliRitardo,
        queryARERACategories,
        isOperator,
        initialValues?.idCategoria,
    ])

    useEffect(() => {
        loadInitialData()
    }, [loadInitialData])

    const contactTypeList = useMemo(() => {
        return [
            { label: strings.contactTypeOwner, value: 0 },
            {
                label: strings.contactTypeAgent,
                value: 1,
            },
        ]
    }, [strings.contactTypeAgent, strings.contactTypeOwner])

    if (!initialValues) {
        return <Box />
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={RequestClaimsSchema}
            onSubmit={async (values) => {
                try {
                    await onSubmit(values)
                    // reset collapsible sections if its all good
                    setShowNotes(false)
                    setShowAttachments(false)
                    setShowReplies(false)
                } catch (e) {}
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
                setFieldValue,
                initialValues,
            }) => {
                return (
                    <Box gap="small">
                        <Box direction="row" flex gap="small" align="flex-end">
                            <BSelect
                                searchable
                                mandatory={isRequired('idCategoria')}
                                containerProps={{ flex: true }}
                                label={strings.areraCategory}
                                placeholder={strings.areraCategory}
                                name="idCategoria"
                                handleChange={(key: string) => (e: any) => {
                                    const value = e.option?.value ?? e.value
                                    const subtypeMapper = (
                                        it: ClaimsSubCategories,
                                    ) => ({
                                        label: it.sottocategoria,
                                        value: it.idSottoCategoriaUtility,
                                    })
                                    const subtypeClaims = subCategories
                                        .filter(
                                            (it) => it.idCategoria === value,
                                        )
                                        .map(subtypeMapper)
                                    setClaimsSubTypes(subtypeClaims)
                                    handleChange(key)(e)
                                }}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={ARERACategories}
                                labelKey="label"
                                icon={
                                    loadingARERACategories ? <Spinner /> : true
                                }
                                valueKey={{ key: 'value', reduce: true }}
                                disabled={disabled}
                            />
                            <BSelect
                                searchable
                                mandatory={isRequired('idSottoCategoria')}
                                containerProps={{ flex: true }}
                                label={strings.category}
                                placeholder={strings.category}
                                name="idSottoCategoria"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimsSubTypes}
                                labelKey="label"
                                icon={loadingSubTypes ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                                disabled={disabled}
                            />
                            <BSelect
                                searchable
                                mandatory={isRequired('idCanale')}
                                containerProps={{ flex: true }}
                                label={strings.canaleAbilitato}
                                placeholder={strings.canaleAbilitato}
                                name="idCanale"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimChannels}
                                labelKey="label"
                                icon={loadingChannels ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                                disabled
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BSelect
                                searchable
                                mandatory={isRequired('idTipoRichiesta')}
                                containerProps={{ flex: true }}
                                label={strings.claimsRequestTypes}
                                placeholder={strings.claimsRequestTypes}
                                name="idTipoRichiesta"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={claimsTypes}
                                labelKey="label"
                                icon={loadingTypes ? <Spinner /> : true}
                                valueKey={{ key: 'value', reduce: true }}
                                disabled
                            />
                            <BSelect
                                searchable
                                mandatory={isRequired('idTipoContatto')}
                                containerProps={{ flex: true }}
                                label={strings.contactType}
                                placeholder={strings.contactType}
                                name="idTipoContatto"
                                handleChange={(field: string) => (e: any) => {
                                    const value = e.option?.value ?? e.value
                                    if (
                                        value !==
                                        ClaimContactType.RAPPRESENTANTE
                                    ) {
                                        setFieldValue(
                                            'occupazione',
                                            initialValues?.occupazione ?? '',
                                        )
                                    }
                                    handleChange(field)(e)
                                }}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={contactTypeList}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                                disabled
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired('nominativoContatto')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.contactName}
                                label={strings.contactName}
                                name="nominativoContatto"
                                disabled
                            />
                            {values.idTipoContatto ===
                                ClaimContactType.RAPPRESENTANTE && (
                                <BTextInput
                                    containerProps={{ flex: true }}
                                    mandatory={isRequired('occupazione')}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    placeholder={strings.occupation}
                                    label={strings.occupation}
                                    name="occupazione"
                                    disabled
                                />
                            )}
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired('emailMittente', values)}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.emailSender}
                                label={strings.emailSender}
                                name="emailMittente"
                                disabled
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired(
                                    'telefonoChiamante',
                                    values,
                                )}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.phoneSender}
                                label={strings.phoneSender}
                                name="telefonoChiamante"
                                disabled
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired(
                                    'emailPerRicontatto',
                                    values,
                                )}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.emailForContact}
                                label={strings.emailForContact}
                                name="emailPerRicontatto"
                                disabled={disabled}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired(
                                    'telefonoPerRicontatto',
                                    values,
                                )}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.phoneForContact}
                                label={strings.phoneForContact}
                                name="telefonoPerRicontatto"
                                disabled={disabled}
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BTextArea
                                containerProps={{ flex: true }}
                                mandatory={isRequired('descrizione')}
                                resize={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.description}
                                label={strings.description}
                                name="descrizione"
                                disabled
                            />
                        </Box>
                        <Box direction="row" flex align="center">
                            <BDateInput
                                containerProps={{
                                    flex: true,
                                    margin: { right: 'small' },
                                }}
                                mandatory={isRequired('dataRichiesta')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.claimDate}
                                label={strings.claimDate}
                                name="dataRichiesta"
                                format="dd/mm/yyyy"
                                disabled
                            />
                            <BTimeInput
                                containerProps={{ flex: true }}
                                mandatory={isRequired('oraRichiesta')}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                label={strings.claimTime}
                                name="oraRichiesta"
                                disabled
                            />
                            <BDateInput
                                containerProps={{
                                    flex: true,
                                    margin: { right: 'small' },
                                }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.claimLimitDate}
                                label={strings.claimLimitDate}
                                name="dataLimite"
                                format="dd/mm/yyyy"
                                disabled
                            />
                        </Box>
                        <Box gap="none">
                            <Box gap="medium">
                                <BCaretCollapsible
                                    isExpanded={showNotes}
                                    expandString={strings.showClaimNotes}
                                    hideString={strings.hideClaimNotes}
                                    onClick={() => setShowNotes(!showNotes)}
                                />
                                <Collapsible open={showNotes}>
                                    <Box gap="medium">
                                        <Text weight="bold" size="large">
                                            {strings.claimNotesList}
                                        </Text>
                                        {initialValues.note.length > 0 ? (
                                            <NotesTable
                                                note={initialValues.note}
                                                paginate={{ size: 'small' }}
                                                step={5}
                                            />
                                        ) : (
                                            <Text>
                                                {strings.claimNotesListEmpty}
                                            </Text>
                                        )}
                                        <Text weight="bold">
                                            {strings.claimNotesNew}
                                        </Text>
                                        <BTextArea
                                            fast
                                            containerProps={{ flex: true }}
                                            name="testoNoteInput"
                                            placeholder={strings.notes}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                            errors={errors}
                                            disabled={disabled}
                                        />
                                    </Box>
                                </Collapsible>
                            </Box>
                            <Box gap="medium">
                                <BCaretCollapsible
                                    isExpanded={showReplies}
                                    expandString={strings.showClaimReplies}
                                    hideString={strings.hideClaimReplies}
                                    onClick={() => setShowReplies(!showReplies)}
                                />
                                <Collapsible open={showReplies}>
                                    <Box gap="medium">
                                        <Text weight="bold" size="large">
                                            {strings.claimRepliesList}
                                        </Text>
                                        {initialValues.risposte.length > 0 ? (
                                            <RepliesTable
                                                replies={initialValues.risposte}
                                                paginate={{ size: 'small' }}
                                                step={5}
                                                onDownload={(allegato) =>
                                                    queryDownloadAllegatoReclamo(
                                                        allegato,
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Text>
                                                {strings.claimRepliesListEmpty}
                                            </Text>
                                        )}
                                        <Box
                                            gap="small"
                                            margin={{ vertical: 'medium' }}
                                            height={{ min: 'medium' }}
                                        >
                                            <Text weight="bold">
                                                {strings.claimRepliesNew}
                                            </Text>
                                            <BRichTextEditor
                                                name="testoRispostaInput"
                                                handleChange={handleChange}
                                                values={values}
                                                disabled={disabled}
                                            />
                                            <CheckBox
                                                name={'inserireIntestazione'}
                                                checked={
                                                    values.inserireIntestazione
                                                }
                                                onChange={handleChange}
                                                label={
                                                    strings.insertClaimHeader
                                                }
                                                disabled={disabled}
                                            />
                                            <CheckBox
                                                name={'inviareRispostaViaMail'}
                                                checked={
                                                    values.inviareRispostaViaMail
                                                }
                                                onChange={handleChange}
                                                label={strings.sendReplyByEmail}
                                                disabled={disabled}
                                            />
                                            <Text weight="bold">
                                                {strings.insertAttachments}
                                            </Text>
                                            <BFileInput
                                                accept={getAllowedClaimsExtensions()}
                                                handleChange={handleChange}
                                                errors={errors}
                                                multiple
                                                name="allegatoRispostaInput"
                                                values={values}
                                                disabled={disabled}
                                            />
                                        </Box>
                                    </Box>
                                </Collapsible>
                            </Box>
                            <Box gap="medium">
                                <BCaretCollapsible
                                    isExpanded={showAttachments}
                                    expandString={strings.showClaimAttachments}
                                    hideString={strings.hideClaimAttachments}
                                    onClick={() =>
                                        setShowAttachments(!showAttachments)
                                    }
                                />
                                <Collapsible open={showAttachments}>
                                    <Box gap="medium">
                                        <Text weight="bold" size="large">
                                            {strings.claimAttachmentsList}
                                        </Text>
                                        {values.allegati?.length > 0 ? (
                                            <FilesTable
                                                files={values.allegati}
                                                onDownload={(allegato) =>
                                                    queryDownloadAllegatoReclamo(
                                                        allegato,
                                                    )
                                                }
                                                paginate={{ size: 'small' }}
                                                step={5}
                                                onRemove={async (allegato) => {
                                                    try {
                                                        await queryDeleteAllegatoReclamo(
                                                            allegato,
                                                        )
                                                        const newAttachments =
                                                            values.allegati.filter(
                                                                (it) =>
                                                                    it.idAllegato !==
                                                                    allegato.idAllegato,
                                                            )
                                                        setFieldValue(
                                                            'allegati',
                                                            newAttachments,
                                                        )
                                                    } catch {}
                                                }}
                                            />
                                        ) : (
                                            <Text>
                                                {
                                                    strings.claimAttachmentsListEmpty
                                                }
                                            </Text>
                                        )}
                                        <Text weight="bold">
                                            {strings.claimAttachmentsNew}
                                        </Text>
                                        <BFileInput
                                            accept={getAllowedClaimsExtensions()}
                                            handleChange={handleChange}
                                            errors={errors}
                                            multiple
                                            name="allegatoInput"
                                            values={values}
                                            disabled={disabled}
                                        />
                                    </Box>
                                </Collapsible>
                            </Box>
                        </Box>
                        <Text weight="bold" size="xlarge">
                            {strings.outgoingData}
                        </Text>
                        <Box gap="medium">
                            <CheckBox
                                name={'ticketErrato'}
                                checked={values.ticketErrato ?? false}
                                onChange={(e) =>
                                    setFieldValue(
                                        'ticketErrato',
                                        e.target.checked,
                                    )
                                }
                                label={strings.wrongTicket}
                                disabled={disabled}
                            />
                            <CheckBox
                                name={'ticketRetention'}
                                checked={values.ticketRetention}
                                onChange={(e) =>
                                    setFieldValue(
                                        'ticketRetention',
                                        e.target.checked,
                                    )
                                }
                                label={strings.ticketRetention}
                                disabled={disabled}
                            />
                            <CheckBox
                                name={'ticketCaring'}
                                checked={values.ticketCaring ?? false}
                                onChange={(e) =>
                                    setFieldValue(
                                        'ticketCaring',
                                        e.target.checked,
                                    )
                                }
                                label={strings.ticketCaring}
                                disabled={disabled}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.assignee}
                                placeholder={strings.assignee}
                                name="idIncaricato"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={availableOperators}
                                icon={loadingOperators ? <Spinner /> : true}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                                disabled={disabled}
                            />
                            <Box
                                direction="row"
                                wrap
                                flex
                                align="center"
                                gap="small"
                            >
                                <BSelect
                                    searchable
                                    mandatory={isRequired('idStato')}
                                    containerProps={{ flex: true }}
                                    label={strings.statoRichiesta}
                                    placeholder={strings.statoRichiesta}
                                    name="idStato"
                                    handleChange={(field: string) =>
                                        (e: any) => {
                                            const value =
                                                e.option?.value ?? e.value
                                            if (
                                                !isClaimClosedOrRejected(value)
                                            ) {
                                                setFieldValue(
                                                    'idMotivoAnnullamento',
                                                    initialValues?.idMotivoAnnullamento,
                                                )
                                                setFieldValue(
                                                    'dataChiusuraRichiesta',
                                                    initialValues?.dataChiusuraRichiesta,
                                                )
                                                setFieldValue(
                                                    'idCausaleRitardo',
                                                    initialValues.idCausaleRitardo,
                                                )
                                            }
                                            if (isClaimClosed(value)) {
                                                setFieldValue(
                                                    'idMotivoAnnullamento',
                                                    initialValues?.idMotivoAnnullamento,
                                                )
                                            }
                                            handleChange(field)(e)
                                        }}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={statiRichiesta}
                                    icon={
                                        loadingStatiRichiesta ? (
                                            <Spinner />
                                        ) : (
                                            true
                                        )
                                    }
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                    disabled={disabled}
                                />
                                <BSelect
                                    searchable
                                    containerProps={{ flex: true }}
                                    label={strings.statusNote}
                                    placeholder={strings.statusNote}
                                    name="idNotaStato"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    options={noteStato}
                                    icon={loadingNoteStato ? <Spinner /> : true}
                                    labelKey="label"
                                    valueKey={{ key: 'value', reduce: true }}
                                    disabled={disabled}
                                />
                            </Box>
                            {isClaimClosedOrRejected(values.idStato) && (
                                <Box
                                    direction="row"
                                    wrap
                                    flex
                                    align="center"
                                    gap="small"
                                >
                                    {isClaimRejected(values.idStato) && (
                                        <BSelect
                                            searchable
                                            mandatory={isRequired(
                                                'idMotivoAnnullamento',
                                                values,
                                            )}
                                            containerProps={{ flex: true }}
                                            label={strings.cancellationReason}
                                            placeholder={
                                                strings.cancellationReason
                                            }
                                            name="idMotivoAnnullamento"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                            errors={errors}
                                            options={motiviAnnullamento}
                                            icon={
                                                loadingStatiRichiesta ? (
                                                    <Spinner />
                                                ) : (
                                                    true
                                                )
                                            }
                                            labelKey="label"
                                            valueKey={{
                                                key: 'value',
                                                reduce: true,
                                            }}
                                            disabled={disabled}
                                        />
                                    )}
                                    <Box
                                        direction="row"
                                        wrap
                                        flex
                                        align="center"
                                        gap="small"
                                    >
                                        <BDateInput
                                            containerProps={{
                                                flex: true,
                                            }}
                                            mandatory={isRequired(
                                                'dataChiusuraRichiesta',
                                                values,
                                            )}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                            errors={errors}
                                            label={strings.cancellationDate}
                                            name="dataChiusuraRichiesta"
                                            format="dd/mm/yyyy"
                                            disabled={disabled}
                                        />
                                        {isClaimResolutionLate(
                                            values.dataChiusuraRichiesta,
                                            values.dataLimite,
                                        ) && (
                                            <BSelect
                                                searchable
                                                containerProps={{
                                                    flex: true,
                                                }}
                                                label={strings.delayReason}
                                                placeholder={
                                                    strings.delayReason
                                                }
                                                mandatory={isRequired(
                                                    'idCausaleRitardo',
                                                    values,
                                                )}
                                                name="idCausaleRitardo"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                options={causaliRitardo}
                                                labelKey="label"
                                                valueKey={{
                                                    key: 'value',
                                                    reduce: true,
                                                }}
                                                disabled={disabled}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                            <Box
                                direction="row"
                                wrap
                                flex
                                align="center"
                                gap="small"
                            >
                                <BDateInput
                                    containerProps={{
                                        flex: true,
                                    }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    label={strings.dateRequestSent}
                                    name="dataInizioInvioDatiTecnici"
                                    format="dd/mm/yyyy"
                                    disabled={disabled}
                                />
                                <BTimeInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    label={strings.timeRequestSent}
                                    name="oraInizioInvioDatiTecnici"
                                    disabled={disabled}
                                />
                            </Box>
                            <Box direction="row" flex align="center">
                                <BDateInput
                                    containerProps={{
                                        flex: true,
                                        margin: { right: 'small' },
                                    }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    label={strings.dateRequestReceived}
                                    name="dataFineRicezioneDatiTecnici"
                                    format="dd/mm/yyyy"
                                    disabled={disabled}
                                />
                                <BTimeInput
                                    containerProps={{ flex: true }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    label={strings.timeRequestReceived}
                                    name="oraFineRicezioneDatiTecnici"
                                    disabled={disabled}
                                />
                            </Box>
                        </Box>
                        <Box margin={{ vertical: 'small' }}>
                            <Button
                                disabled={isLoading}
                                onClick={async () => {
                                    try {
                                        const fields = await validateForm()
                                        const errors = Object.keys(fields)
                                        if (errors.length > 0) {
                                            console.log(fields)
                                            return
                                        }
                                        handleSubmit()
                                    } catch (e) {}
                                }}
                                icon={isLoading ? <Spinner /> : <span />}
                                label={strings.send}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

type ReclamiFormProps = {
    onSubmit: (values: DettaglioReclamoOperatore) => Promise<any> | any
    isLoading: boolean
    disabled: boolean
    initialValues?: DettaglioReclamoOperatore
}

export default EditReclamoOperatorForm
