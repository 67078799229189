import { IOCServices } from '..'
import { HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type ValidateTokenType = {
    CodiceUtente: string,
    IdRichiesta: string,
    TokenReg: string
}

const validaTokenRegistrazioneAPI =
    async (val: ValidateTokenType, container?: IOCServices) => {
        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Utente/ValidaTokenRegistrazione',{
                    CodiceUtente:val.CodiceUtente,
                    IdRichiesta:val.IdRichiesta,
                    TokenReg:val.TokenReg
            })
            if (res.status == HttpStates.OK) {

                return res.data.value
            }
            else {

                return undefined
            }          
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default validaTokenRegistrazioneAPI
