import { Box, Heading, Spinner } from 'grommet'
import { useNavigate, useLocation } from 'react-router'
import useI18n from '../hooks/useI18n'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useSize from '../hooks/useSize'
import SupplySelector from '../components/SupplySelector'
import AutoletturaForm from '../components/forms/AutoletturaForm'
import insertRichiestaAutolettura from '../services/store/actions/insertRichiestaAutolettura'
import { reverseStringDate } from '../utilities'
import BBackButton from '../components/BBackButton'
import { BooleanValueInt, paramEnum } from '../models/Enums'
import useAbilitaTipoUsoContatore from '../hooks/useAbilitaTipoUsoContatore'

const Autolettura = () => {
    const strings = useI18n()
    const dispatch = useAppDispatch()
    const size = useSize()
    const user = useAppSelector((state) => state.user.clientInfo)
    const supply = useAppSelector((state) => state.supply)
    const params = useAppSelector((state) => state.main.publicParam)
    const navigate = useNavigate()
    const location = useLocation()
    const abilitaAutolettura = useAbilitaTipoUsoContatore()
    const state = location.state as { path?: string }

    const WEB_AbilitaAutoletturaEE =
        params.filter(
            (it) =>
                +it.idTipoParametroAzienda === paramEnum.AbilitaAutoletturaEE,
        )[0]?.valore ?? 1


    if (supply.loading) {
        return (
            <Box fill background={'background-back'}>
                <Box flex="grow" justify="center" align="center">
                    <Spinner />
                </Box>
                <Box pad="xsmall" direction="row" align="center" gap="small">
                    {supply.loading ? <></> : strings.rangeClosed}
                </Box>
            </Box>
        )
    }
    if (!abilitaAutolettura) {
        return (
            <Box flex pad="medium">
                <Box direction="row" flex={false} align="center" gap="medium">
                    <BBackButton
                        size="small"
                        onClick={() => navigate(state?.path ?? '/requests')}
                    />
                    <Heading level={3}>{strings.Autolettura}</Heading>
                </Box>
                <Box flex="grow">
                    <SupplySelector disableInactiveSupply />
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small"
                        margin={
                            size === 'small'
                                ? { top: 'small' }
                                : { top: 'medium' }
                        }
                    >
                        {strings.autoletturaDisabilitataPerTipoUso}
                    </Box>
                </Box>
            </Box>
        )
    }
    return (
        <Box flex pad="medium">
            <Box direction="row" flex={false} align="center" gap="medium">
                <BBackButton
                    size="small"
                    onClick={() => navigate(state?.path ?? '/requests')}
                />
                <Heading level={3}>{strings.Autolettura}</Heading>
            </Box>
            <Box flex="grow">
                <SupplySelector disableInactiveSupply />
                {supply.selectedSupply?.tipoServizio === 'E' &&
                    WEB_AbilitaAutoletturaEE === BooleanValueInt.false && (
                        <Box
                            pad="xsmall"
                            direction="row"
                            align="center"
                            gap="small"
                            margin={
                                size === 'small'
                                    ? { top: 'small' }
                                    : { top: 'medium' }
                            }
                        >
                            {strings.rangeEEdisabled}
                        </Box>
                    )}
                {supply.selectedSupply?.isOpen ? (
                    <AutoletturaForm
                        loading={supply.loading}
                        onSubmit={async (values) => {
                            try {
                                values.DataLettura = reverseStringDate(
                                    values.DataLettura,
                                )
                                await dispatch(
                                    insertRichiestaAutolettura(values),
                                )
                                navigate(state?.path ?? '/requests')
                            } catch (e) {}
                        }}
                        supply={supply.selectedSupply}
                        user={user}
                        lastDate={supply.selectedSupply.dataLettura}
                        lastCorr={supply.selectedSupply.letturaCorrettore}
                        lastCont={supply.selectedSupply.lettura}
                        lastContF1={supply.selectedSupply.letturaF1}
                        lastContF2={supply.selectedSupply.letturaF2}
                        lastContF3={supply.selectedSupply.letturaF3}
                        tipoServizio={supply.selectedSupply.tipoServizio}
                        fromDate={supply.selectedSupply.fromDate}
                        toDate={supply.selectedSupply.toDate}
                    />
                ) : (
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small"
                        margin={
                            size === 'small'
                                ? { top: 'small' }
                                : { top: 'medium' }
                        }
                    >
                        {supply.selectedSupply &&
                            !(
                                supply.selectedSupply?.tipoServizio === 'E' &&
                                WEB_AbilitaAutoletturaEE ===
                                    BooleanValueInt.false
                            ) &&
                            strings.rangeClosed}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Autolettura
