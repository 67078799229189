import { Box, Heading, Text } from 'grommet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import BBackButton from '../components/BBackButton'
import SupplySelector from '../components/SupplySelector'
import ChangeAddressForm from '../components/forms/ChangeAddressForm'

import useAppQuery from '../hooks/useAppQuery'
import useI18n from '../hooks/useI18n'
import { ChangeAddressModel } from '../models/models'
import streetTypes from '../models/streetTypes'
import { changeAddressRequest } from '../services/store/actions'
import getProvinceComuni from '../services/store/actions/getProvinceComuni'
import { useAppSelector } from '../services/storeProvider'
import { StatiFornitura } from '../models/Enums'

const ChangeAddress = () => {
    const selectedSupply = useAppSelector(
        (state) => state.supply.selectedSupply,
    )
    const puntiGas = useAppSelector((state) =>
        state.supply.supplies?.map((it) => it.puntoGas),
    )
    const navigate = useNavigate()
    const [requesting, requestChangeAddress] = useAppQuery(changeAddressRequest)
    const strings = useI18n()
    const [isLoading, queryProvinces] = useAppQuery(getProvinceComuni)
    const location = useLocation()
    const state = location.state as { path?: string }
    const provincesCities = useAppSelector(
        (state) => state.main.provincesCities,
    )
    const disabledSupply =
        selectedSupply?.idStatoFornitura === StatiFornitura.Cessata

    useEffect(() => {
        queryProvinces()
    }, [queryProvinces])
    return (
        <Box flex pad="small">
            <Box flex="grow" direction="row" gap="small" align="center">
                <BBackButton
                    size="small"
                    onClick={() => navigate(state?.path ?? '/requests')}
                />
                <Heading level={3}>{strings.CambioRecapito}</Heading>
            </Box>
            <Box gap="small" flex="grow">
                <SupplySelector disableInactiveSupply />
            {selectedSupply && !disabledSupply && (
                    <ChangeAddressForm
                        streetTypes={streetTypes}
                        cities={provincesCities}
                        onSubmit={async (values: ChangeAddressModel) => {
                            await requestChangeAddress({
                                ...values,
                                user: selectedSupply.codiceUtente,
                                puntiGas: values.applyToAll
                                    ? puntiGas
                                    : [selectedSupply.puntoGas],
                            })
                            navigate(state?.path ?? '/requests')
                        }}
                        isLoading={requesting || isLoading}
                    />
                )}
                {disabledSupply && (
                    <Box
                        pad="xsmall"
                        direction="row"
                        align="center"
                        gap="small"
                    >
                        <Text>{strings.richiesteCessate}</Text>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default ChangeAddress
