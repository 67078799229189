import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_SUPPORT_REQUESTS } from './types'

const getSottoTipiRichiesta: AppAsyncAction<any> =
    () =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)

        try {
            const requestType  = await container.api.getSottoTipiRichiestaAPI()
            dispatch({type: SET_SUPPORT_REQUESTS, payload: requestType})
            return requestType
        } catch (e) {
            const error = e as Error
            dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default getSottoTipiRichiesta
