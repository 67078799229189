import {
    Box,
    BoxExtendedProps,
    Button,
    ColumnConfig,
    DataTable,
    DataTableProps,
    Layer,
    Text,
} from 'grommet'
import { useState } from 'react'
import { Allegato, Risposta } from '../models/models'
import useI18n from '../hooks/useI18n'
import { Checkmark, Clear, FolderOpen, View } from 'grommet-icons'
import FilesTable from './FilesTable'
import BLightButton from './BLightButton'
import { formatDateString } from '../utilities'

type RepliesTableProps = {
    boxProps?: BoxExtendedProps
    replies: Risposta[]
    isDownloading?: boolean
    onDownload?: (allegato: Allegato) => Promise<void | boolean>
} & DataTableProps<Risposta>

const RepliesTable = ({
    boxProps = {},
    replies = [],
    onDownload,
    isDownloading,
    ...dataTableProps
}: RepliesTableProps) => {
    const [showFilesItem, setShowFilesItem] = useState<Risposta>()
    const [showNoteItem, setShowNoteItem] = useState<Risposta>()
    const strings = useI18n()
    const columns: ColumnConfig<Risposta>[] = [
        {
            property: 'idTestoRisposta',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.replyId}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.idTestoRisposta}
                </Text>
            ),
        },
        {
            property: 'codiceUtente',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.operatore}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.codiceUtente}
                </Text>
            ),
        },
        {
            property: 'dataInserimento',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.date}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.dataInserimento}
                </Text>
            ),
        },
        {
            property: 'invioConIntestazione',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.withHeader}
                </Text>
            ),
            render: (item) =>
                item.invioConIntestazione ? (
                    <Checkmark color="green" />
                ) : (
                    <Clear color="gray" />
                ),
        },
        {
            property: 'invioPerEmail',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.email}
                </Text>
            ),
            render: (item) => (
                <Text truncate="tip" size="small">
                    {item.invioPerEmail ? item.email : <Clear color="gray" />}
                </Text>
            ),
        },
        {
            property: 'crud',
            size: 'small',
            header: (
                <Text
                    size="small"
                    weight="bold"
                    style={{ textTransform: 'uppercase' }}
                >
                    {''}
                </Text>
            ),
            render: (item) => (
                <Box direction="row" gap="small" justify="end">
                    <BLightButton
                        disabled={!item.allegati || item.allegati?.length === 0}
                        boxProps={{ direction: 'row', align: 'center' }}
                        onClick={() => setShowFilesItem(item)}
                        size="small"
                        label={strings.showReplyAttachments}
                        icon={<FolderOpen />}
                    />
                    <BLightButton
                        boxProps={{ direction: 'row', align: 'center' }}
                        onClick={() => setShowNoteItem(item)}
                        size="small"
                        label={strings.showReply}
                        icon={<View />}
                    />
                </Box>
            ),
        },
    ]
    return (
        <Box background={'background-front'} {...boxProps}>
            <DataTable
                background={'background-front'}
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                verticalAlign={'middle'}
                columns={columns}
                data={replies}
                {...dataTableProps}
            />
            {!!showFilesItem && (
                <Layer
                    position="center"
                    onClickOutside={() => setShowFilesItem(void null)}
                    onEsc={() => setShowFilesItem(void null)}
                >
                    <Box pad="medium" gap="medium">
                        <Text weight={'bold'} size="large">
                            {showFilesItem.idTestoRisposta}:
                            {strings.claimAttachmentsList}
                        </Text>
                        <FilesTable
                            onDownload={onDownload}
                            files={showFilesItem?.allegati ?? []}
                        />
                        <Box direction="row-reverse" align="center" gap="small">
                            <Button
                                secondary
                                label={strings.close}
                                onClick={() => setShowFilesItem(void null)}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
            {!!showNoteItem && (
                <Layer
                    position="center"
                    style={{ minWidth: '300px' }}
                    onClickOutside={() => setShowNoteItem(void null)}
                    onEsc={() => setShowNoteItem(void null)}
                >
                    <Box pad="medium">
                        <Text weight={'bold'} size="large">
                            {showNoteItem.idTestoRisposta}: {strings.reply}
                        </Text>
                        <Text weight={'lighter'} size="small">
                            {formatDateString(
                                showNoteItem.dataInserimento,
                                'yyyy-MM-dd',
                            )}
                        </Text>
                        <Box margin={{ vertical: 'medium' }}>
                            <Text weight={'bold'}>
                                {strings.reply} {strings.from}{' '}
                                {showNoteItem.codiceUtente}:
                            </Text>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: showNoteItem.testoRisposta,
                                }}
                            />
                        </Box>
                        <Box direction="row-reverse" align="center" gap="small">
                            <Button
                                secondary
                                label={strings.close}
                                onClick={() => setShowNoteItem(void null)}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
}

export default RepliesTable
