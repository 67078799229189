import { Box, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { keepAlive } from '../services/store/actions'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import useI18n from '../hooks/useI18n'
import useTheme from '../hooks/useTheme'
import CambioPasswordForm from '../components/forms/CambioPasswordForm'
import editPasswordGuest from '../services/store/actions/editPasswordGuest'
import useAssets from '../hooks/useAssets'
import { useSearchParams } from 'react-router-dom'
import useSize from '../hooks/useSize'
import useAuth from '../hooks/useAuth'

const CambioPassword = () => {
    const brandColor = useTheme().global?.colors?.brand?.toString()
    const dispatch = useAppDispatch()
    const config = useAppSelector((state) => state.config)
    const user = useAppSelector((state) => state.user.clientInfo)
    const { isLoading, loggedIn } = useAuth()
    const [showRecovery, setShowRecovery] = useState(false)
    const [setToken, getToken] = useState('')
    const strings = useI18n()
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { path?: string }
    const size = useSize()
    const { username } = useAppSelector((state) => state.user.loggedUser)
    const assets = useAssets()
    const [searchParams] = useSearchParams()

    //const [getMessage,setMessage] = useState('')
    const Token = searchParams.get('Token')

    const loadData = async () => {
        try {

            await dispatch(keepAlive())

        } catch (e) {
            //setMessage(strings.APP_MSG_EMAIL_VALIDATION_ERROR)
        }
    }

    useEffect(() => {

        loadData()
    }, [])
    return (
        <Box direction="row" justify="start" height="100%">
            <Box
                width="30%"
                background={"url("+assets.loginside+")"}
                align="center"
                justify="center"
                pad="small"
                //fill={'vertical'}
                //justify="center"
            >
                <Box
                    width="medium"
                    height="medium"
                    //background="url(../assets/logo.png)"
                    background={{
                        //color: "neutral-1",
                        //dark: true,
                        //opacity: true,
                        //position: "bottom",

                        repeat: 'no-repeat',
                        size: 'contain',
                        image: "url("+assets.logo+")",
                        //image: "url(//my.com/assets/img.png)"
                    }}></Box>
            </Box>
            <Box
                flex
                style={{position: "relative"}}
                background={{ opacity: 0.97, color: 'background-front' }}
                round="xsmall"
                pad={'medium'}>
                <Box margin={{ bottom: 'medium' }}>
                    <Text weight={'lighter'} size="xxlarge">
                        {strings.welcomeBack}
                    </Text>
                    <Text weight={'lighter'} size="large">
                        {strings.setNewPassword}
                    </Text>
                </Box>
                <Box
                    flex
                    direction="row"
                    gap="large"
                    justify="center"
                    align="center"
                    wrap>

                        <Box
                            width={size !== 'small' ? 'medium' : '100%'}
                            height={{ min: 'small' }}
                            animation={
                                ['fadeIn', 'slideRight']

                            }>
                            <CambioPasswordForm
                                loading={isLoading}
                                pwdComp={user.isPwdComplexityError}
                                onSubmit={async (values) => {
                                    try {
                                        values = {
                                            ...values,
                                            Token: Token!
                                        }


                                    const res = await dispatch(editPasswordGuest(values))

                                    if (res === true) navigate('/login')

                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}

                                disabled={false}
                                onBlur={(values) => {

                                }}

                                Icon={undefined}
                                userName={username}


                            />
                        </Box>



                </Box>




            </Box>

        </Box>
    )
}

export default CambioPassword
