import { IOCServices } from '..'
import { LoggedUserModel } from '../../models/models'

const getLoggedUserAPI = async (
    container?: IOCServices,
): Promise<LoggedUserModel> => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get('/Utente/GetLoggedUser')
        return res.data?.value
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getLoggedUserAPI
