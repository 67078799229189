import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'

const loadInvoiceAPI = async (
    codiceUtente: string,
    nrFattura: string,
    container?: IOCServices,
  
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }
    const rest = container.restID

    try {
        const res = await rest.get(`/Fattura/GetFatturaUtente/${nrFattura}?utente=${codiceUtente}`)
        if (res.status === HttpStates.OK) {
            const data = res.data.value
            return data
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export default loadInvoiceAPI
