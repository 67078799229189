import { AppAsyncAction } from '../../storeProvider'
import setNotification from './setNotification'
import { SET_PROVINCES_CITY } from './types'

const GetProvinceComuni: AppAsyncAction<any> =
    () =>
    async (dispatch, getState, container) => {
        const lan = getState().common.language
        const i18n = await container.strings(lan)

        try {
            const provinceComuni  = await container.api.getProvinceComuniAPI()
            dispatch({type: SET_PROVINCES_CITY, payload: provinceComuni})
            return provinceComuni
        } catch (e) {
            const error = e as Error
                    dispatch(
                setNotification({
                    type: 'error',
                    message: error.message,
                    show: true,
                }),
            )
            throw e
        }
    }

export default GetProvinceComuni
