import {
    Avatar,
    Box,
    BoxExtendedProps,
    BoxProps,
    Text,
} from 'grommet'
import useI18n from '../hooks/useI18n'
import useSize from '../hooks/useSize'

const BHeaderProfile = ({
    nominativo,
    codiceUtente,
    back,
    logo,
    heightLogo,
    widthLogo,
    ...rest
}: BHeaderProfileProps) => {
    const size = useSize()
    const strings = useI18n()

    return (
        <Box
            width="100%"
            direction="column"
            justify={size === 'small' ? 'start' : 'center' }
            align={size === 'small' ? 'center' : 'start'}
            style={{
                position: 'relative',
            }}
            {...rest}
        >
            <Box
                fill
                direction={size === 'small' ? 'column' : "row"}
                background="brand"
                round={size !== 'small' ? { size: "large", corner: "top" } : undefined}
                justify={size === 'small' ? 'end' : "between"}
                style={{
                    position: 'relative',
                }}
            >
                <Box
                    direction={size === 'small' ? 'column' : "row"}
                    align={size === 'small' ? 'center' : "end"}
                    fill={size === 'small' ? 'horizontal': 'vertical'}
                    margin={{
                        left: size !== 'small' ? 'medium' : undefined,
                    }}
                >
                    <Box
                        background='background-front'
                        pad="small"
                        round={size === 'small' ? 'large' : "full" }
                        style={{zIndex:3}}
                    >
                        <Avatar
                            size={size!="small"?'xlarge':'large'}
                            background={'brand'}
                            round={size === 'small' ? 'large' : "full"}
                        >
                            {nominativo![0]?.toUpperCase() ?? '-'}
                        </Avatar>
                    </Box>
                    {size=="small"
                        ?
                            <Box
                                round="small"
                                direction="column"
                                pad={{left: "70px", right:"medium", vertical: "medium"}}
                                margin={{left:"-70px"}}
                            >
                                <Text color='textWhite' weight="bold">{nominativo}</Text>
                            </Box>
                        :
                            <Box
                                round="small"
                                direction="column"
                                background='background-front'
                                pad={{left: "70px", right:"medium", vertical: "medium"}}
                                margin={{ left:"-70px"}}
                                style={{ zIndex: 2 }}
                            >
                                <Text weight="bold">{nominativo}</Text>
                                <Text color="brand" size='small'>{strings.codiceUtente} {codiceUtente}</Text>
                            </Box>
                    }
                </Box>
                <Box
                    style={{
                        opacity: size === 'small' ? '0.5' : undefined
                    }}
                    alignSelf='end'
                    height={`${heightLogo * 0.7}px`}
                    width={widthLogo}
                    background={{
                        repeat: "no-repeat",
                        size: "contain",
                        image: logo
                    }}
                />
            </Box>
        </Box>


    )
}

type BHeaderProfileProps = {
    nominativo: string,
    codiceUtente: string,
    back?: string
    logo?: string
    heightLogo: number
    widthLogo: BoxProps['width']
} & BoxExtendedProps

export default BHeaderProfile
