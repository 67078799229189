import { Box, Button, Grid, Heading, Layer, Spinner, Text } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'
import AddressBox from '../AddressBox'
import { DocumentText } from 'grommet-icons'
import BTextButton from '../BtextButton'
import useI18n from '../../hooks/useI18n'
import { SET_ADDRESS } from '../../services/store/actions/types'
import { useAppDispatch, useAppSelector } from '../../services/storeProvider'
import { paramEnum } from '../../models/Enums'
import { useEffect, useState } from 'react'
import setReadRecontracting from '../../services/store/actions/setReadRecontracting'
import useSize from '../../hooks/useSize'
import SuppliesRecontracting from '../RecontractingSupplies'
import useAppQuery from '../../hooks/useAppQuery'
import { loadSuppliesRecontracted } from '../../services/store/actions'
import { ComponentWithLoader } from '../../models/models'
import BRoundButton from '../BRoundButton'

const Address = ({ isLoading }: ComponentWithLoader) => {
    const strings = useI18n()
    const size = useSize()
    const dispatch = useAppDispatch()
    const supply = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const publicParam = useAppSelector((state) => state.main.publicParam)
    const [showModalSupplies, setShowModalSupplies] = useState(false)
    const [, queryRecontractedSupplies] = useAppQuery(loadSuppliesRecontracted)
    const suppliesRecontracted = useAppSelector(
        (state) => state.supply.recontracting,
    )
    const offerChange = publicParam.filter(
        (it) => +it.idTipoParametroAzienda === paramEnum.WEB_AlertCambioOfferta,
    )
    const showOfferChange = offerChange?.[0]?.valore === '1'
    const readingRecontracting = useAppSelector(
        (state) => state.main.readingRecontracting,
    )
    const [showAllAddresses, setShowAllAddresses] = useState(false)

    const maxVisibleAddresses = showAllAddresses
        ? supply.addresses.length
        : Math.min(6, supply.addresses.length)
    const visibleAddresses = supply.addresses.slice(0, maxVisibleAddresses)
    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        queryRecontractedSupplies(user.codiceUtente)
        setShowModalSupplies(true)
    }, [queryRecontractedSupplies, user.codiceUtente])

    return (
        <Box align="baseline" fill="horizontal">
            <Box>
                <Box direction="row" gap="small" align="center">
                    <Heading size="small">{strings.iMieiIndirizzi}</Heading>
                    {supply.selectedAddress && (
                        <BTextButton
                            label="Vedi tutti"
                            onClick={() => {
                                try {
                                    dispatch({
                                        type: SET_ADDRESS,
                                        payload: undefined,
                                    })
                                } catch (e) {}
                            }}
                        />
                    )}
                    {!!suppliesRecontracted &&
                        showOfferChange &&
                        suppliesRecontracted.length > 0 && (
                            <Button
                                label={strings.ricontrattualizzazione}
                                primary
                                size="small"
                                onClick={() => {
                                    setShowModalSupplies(true)
                                    dispatch(setReadRecontracting(false))
                                }}
                                icon={<DocumentText />}
                            ></Button>
                        )}
                    {isLoading && <Spinner />}
                </Box>
                <Text size="medium" weight={'lighter'}>
                    {strings.IndirizzoFornitura}
                </Text>
            </Box>
            <Grid
                columns={{
                    count: size === 'small' ? 1 : 2,
                    size: 'auto',
                }}
                gap="small"
                fill="horizontal"
                margin={{ top: 'small' }}
            >
                {visibleAddresses.map((it, idx) => (
                    <AddressBox
                        key={idx}
                        margin={{ bottom: 'small' }}
                        width={{ min: 'small' }}
                        value={it.percent}
                        title={it.comune}
                        description1={`${it.tipoVia} ${it.via}`}
                        description2={`${it.cap} ${it.comune} ${it.provincia}`}
                        background={it.color}
                        active={it === supply.selectedAddress}
                        onClick={() => {
                            try {
                                dispatch({
                                    type: SET_ADDRESS,
                                    payload:
                                        !supply.selectedAddress ||
                                        supply.selectedAddress !== it
                                            ? it
                                            : undefined,
                                })
                            } catch (e) {}
                        }}
                    />
                ))}
            </Grid>
            <Box>
                {supply.addresses.length > 5 && (
                    <BRoundButton
                        size="small"
                        label={
                            showAllAddresses
                                ? strings.nascondi
                                : strings.vediTutti
                        }
                        icon={
                            showAllAddresses ? (
                                <FormUp color="white" size="xlarge" />
                            ) : (
                                <FormDown color="white" size="xlarge" />
                            )
                        }
                        onClick={() => setShowAllAddresses(!showAllAddresses)}
                    />
                )}
            </Box>
            {!!suppliesRecontracted &&
                showOfferChange &&
                suppliesRecontracted.length > 0 &&
                showModalSupplies &&
                !readingRecontracting && (
                    <Layer position="center" onEsc={() => false}>
                        <Box overflow={'auto'}>
                            <SuppliesRecontracting
                                onBackPress={() => setShowModalSupplies(false)}
                                recontracting={suppliesRecontracted}
                            />
                        </Box>
                    </Layer>
                )}
        </Box>
    )
}
export default Address
