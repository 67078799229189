const BookmarkIcon = ({
    color,
    ...rest
}: React.SVGAttributes<SVGSVGElement> & { color?: string }) => {
    return (
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" {...rest}>
            <path
                d="M0.199951 2C0.199951 0.89543 1.09538 0 2.19995 0H17.1333C18.2378 0 19.1333 0.895431 19.1333 2V20.6229C19.1333 22.1429 17.5048 23.1073 16.1719 22.3767L10.628 19.3378C10.0291 19.0095 9.30412 19.0095 8.70526 19.3378L3.1613 22.3767C1.82846 23.1073 0.199951 22.1429 0.199951 20.6229V2Z"
                fill={color || "#83C061"}
            />
        </svg>
    )
}

export default BookmarkIcon
