import { IOCServices } from '..'
import { HttpStates, Roles } from '../../models/Enums'
import { isOperatorOrAgent } from '../../utilities'

const getSignedPDFAPI = async (
    idRichiesta: number,
    codiceUtente: string,
    roles: Roles[],
    container?: IOCServices,
) => {
    if (!container) {
        return Promise.reject(
            'Container is null. API must be called from api service',
        )
    }

    const rest = container.restID
    try {
        const isOperator = isOperatorOrAgent(roles)
        let api = `/Richiesta/GetSignedModule/${idRichiesta}`
        if (isOperator) {
            api = `/Richiesta/GetSignedModuleAsOperatore/${idRichiesta}?utente=${codiceUtente}`
        }
        const res = await rest.get(api)
        if (res.status === HttpStates.NoContent) {
            return ''
        }

        if (res.status !== HttpStates.OK) {
            return undefined
        }
        const data = res.data.value
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export default getSignedPDFAPI
