import {
    Box,
    BoxExtendedProps,
    Button,
    ColumnConfig,
    DataTable,
    Pagination,
    Spinner,
    Text,
} from 'grommet'
import { Search } from 'grommet-icons'
import useI18n from '../hooks/useI18n'
import useStringFromCode from '../hooks/useStringFromCode'
import { RequestSearchModel } from '../models/models'
import {
    formatDate,
    getDescStatoRichiestaOperator,
    getDescTipoRichiesta,
} from '../utilities'
import { REQUESTS_PER_PAGE } from '../services/store/actions/loadUsersRequests'

type PaginationProps = {
    page: number
    startIndex: number
    endIndex: number
}

export interface IRequestSearchProps extends BoxExtendedProps {
    isLoading: boolean
    requests: RequestSearchModel[]
    totalRow: number
    hidePagination?: boolean
    onRequestSelect: (item: RequestSearchModel) => void
    onPageChange?: ({ page, startIndex, endIndex }: PaginationProps) => void
}

export default function RequestListSearch({
    isLoading,
    requests,
    totalRow,
    hidePagination = false,
    onRequestSelect,
    onPageChange,
    ...boxProps
}: IRequestSearchProps) {
    const strings = useI18n()
    const getString = useStringFromCode()
    const columns: ColumnConfig<RequestSearchModel>[] = [
        {
            property: 'idRichiesta',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    truncate="tip" 
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.idRichiesta}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.idRichiesta}
                </Text>
            ),
        },
        {
            property: 'dataRichiesta',
            size: 'small',

            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.date}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.dataRichiesta &&
                        formatDate(new Date(item.dataRichiesta))}
                </Text>
            ),
        },

        {
            property: 'utenteSito',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.utente}
                </Text>
            ),
            render: (item: RequestSearchModel) => {
                const userSplitString = item.utenteSito.split(' ')
                const userType = getString(userSplitString[0])
                const userName = userSplitString[1]
                return (
                    <Text truncate="tip" size="small">
                        {userType} : {userName}
                    </Text>
                )
            },
        },
        {
            property: 'codiceCliente',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.codiceCliente}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.codiceCliente}
                </Text>
            ),
        },
        {
            property: 'nominativoCliente',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.nominativo}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {item.nominativoCliente}
                </Text>
            ),
        },
        {
            property: 'idTipo',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.tipologia}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {getDescTipoRichiesta(item.idTipo, strings)}
                </Text>
            ),
        },
        {
            property: 'idStato',
            size: 'small',
            header: (
                <Text
                    wordBreak="break-word"
                    weight="bold"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                >
                    {strings.stato}
                </Text>
            ),
            render: (item: RequestSearchModel) => (
                <Text truncate="tip" size="small">
                    {getDescStatoRichiestaOperator(item.idStato, strings)}
                </Text>
            ),
        },
        {
            property: 'action',
            size: 'small',
            render: (item: RequestSearchModel) => (
                <Button
                    primary
                    label={strings.dettagliRichiesta}
                    size="small"
                    onClick={() => onRequestSelect(item)}
                    icon={<Search color="white" size="small" />}
                />
            ),
        },
    ]

    return (
        <Box background={'background-front'} round="small" {...boxProps}>
            <DataTable
                paginate
                pad="small"
                border={{
                    body: {
                        color: 'separator',
                        side: 'top',
                    },
                }}
                background={'background-front'}
                columns={columns}
                data={requests}
                sortable
                placeholder={
                    isLoading ? (
                        <Box
                            fill
                            align="center"
                            justify="center"
                            direction="row"
                            pad="large"
                            gap="small"
                            background={{
                                color: 'background-front',
                                opacity: 'strong',
                            }}
                        >
                            <Spinner />
                            <Text weight="bold">{`${
                                strings.loading
                            } ${strings.lastRequests.toLowerCase()}...`}</Text>
                        </Box>
                    ) : undefined
                }
                pin
                fill
            />
            {!hidePagination && (
                <Pagination
                    alignSelf="end"
                    step={REQUESTS_PER_PAGE}
                    numberItems={totalRow}
                    onChange={onPageChange}
                    margin={{ top: 'xsmall' }}
                    size="small"
                />
            )}
        </Box>
    )
}
