import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../services/storeProvider'
import { useContainer } from '../services'
import {
    REMOVE_CLIENT_INFO,
    RESET_SUPPLY,
} from '../services/store/actions/types'
import { ClientModel } from '../models/models'
import useIsOperator from './useIsOperator'

const useClientInfo = (): [ClientModel | undefined, () => void] => {
    const clientInfo = useAppSelector((state) => state.user.clientInfo)
    const isOperator = useIsOperator()
    const dispatch = useAppDispatch()
    const container = useContainer()

    const reset = useCallback(() => {
        container.localStore.deselectClient()
        dispatch({ type: REMOVE_CLIENT_INFO })
        dispatch({ type: RESET_SUPPLY })
    }, [dispatch, container])

    const isOperatorActing =
        isOperator &&
        clientInfo.codiceUtente &&
        container.localStore.loadSelectedClient()

    const data = isOperatorActing ? clientInfo : undefined

    return [data, reset]
}

export default useClientInfo
