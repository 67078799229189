import { Box, Text, RadioButtonGroup, RadioButtonGroupProps, BoxProps } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import { get } from 'lodash'
import ReactTooltip from 'react-tooltip'
import BErrorMessage from './BErrorMessage'

const BRadioButtonGroup = ({
    handleChange,
    handleBlur,
    errors,
    name,
    label,
    values,
    containerProps,
    mandatory,
    tooltip,
    gap,
    ...rest
}: BRadioButtonGroups) => {
    return (
        <Box justify="start" {...containerProps}>
            {tooltip && <ReactTooltip multiline />}
            <Box margin={{bottom: '4px'}} direction="row" justify="between">
                <Box direction="row" gap="xsmall" align="center">
                    {label && <Text size="small">{label}</Text>}
                    {mandatory && <Text weight="bolder" size="small">*</Text>}
                    {tooltip && (
                        <p
                            style={{ margin: '0', padding: '0' }}
                            data-tip={tooltip}>
                            <CircleInformation size="small" />
                        </p>
                    )}
                </Box>
            </Box>
            <Box direction="row" align="center" justify='between'>
                <RadioButtonGroup
                    direction="row"
                    gap={gap}
                    //height="0px"
                    name={name}
                    value={get(values, name)}
                    onChange={(e) => handleChange && handleChange(name)(e)}
                    onBlur={(e) => handleBlur && handleBlur(name)(e)}
                    {...rest}
                />
            </Box>
            <BErrorMessage name={name} errors={errors} />
        </Box>
    )
}

type BRadioButtonGroups = {
    handleChange: Function
    handleBlur?: Function
    errors: {}
    name: string
    label?: string
    values: {}
    containerProps?: BoxProps
    mandatory?: boolean
    tooltip?: string
    gap: string
} & RadioButtonGroupProps

export default BRadioButtonGroup
