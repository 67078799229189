import { AxiosError } from 'axios'
import { IOCServices } from '..'
import { APIReturnTypes, HttpStates } from '../../models/Enums'
import { UserModel } from '../../models/models'
import { useAppSelector } from '../storeProvider'

export type RichiediPinUtenteType = {
    CodiceUtente: string,
    Codice: string,
    Cellulare: string,
    Email: string
}

export type OutputRichiediPinUtenteType = {
    ApiReturnType: number,
    ErrorRegistrationNoEmail: boolean
}

const richiediPinUtenteAPI =
    async (req: RichiediPinUtenteType, container?: IOCServices)  => {
        let outputRichiediPinUtenteType: OutputRichiediPinUtenteType = {
            ApiReturnType: APIReturnTypes.KO,
            ErrorRegistrationNoEmail: false
        };

        if (!container) {
            return Promise.reject("Container is null. API must be called from api service")
        }

        const restID = container.restID
        const restAPI = container.rest

        try {
            const res = await restID.post(
                '/Utente/RichiediPinUtente',{
                    CodiceUtente:req.CodiceUtente,
                    Codice:req.Codice,
                    Cellulare: req.Cellulare,
                    Email: req.Email,
                    FromFrontend: true
            })

            if (res.status == HttpStates.OK) {
                
                outputRichiediPinUtenteType.ApiReturnType = res.data?.value?.needData ? APIReturnTypes.OKButNeedData : APIReturnTypes.OK;
                outputRichiediPinUtenteType.ErrorRegistrationNoEmail = res.data?.value == "41|APP_ERROR_REGISTRATION_NOEMAIL";

                return outputRichiediPinUtenteType;
            }
            else {

                return outputRichiediPinUtenteType;
            }
        } catch (e) {

            return outputRichiediPinUtenteType;
        }
    }

export default richiediPinUtenteAPI
