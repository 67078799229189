import axios, { Axios } from 'axios'
import { IOCServices } from '.'
import { startLoading, stopLoading } from './store/actions'

type RestOptionsType = {
    baseURL?: string
}

const restIdentityProvider = (container: IOCServices) => {
    const axiosInstance = rest({
        baseURL: '',
    })

    axiosInstance.defaults.headers.common['Source'] = String("USERNEXT")

    addInterceptorRequest(axiosInstance, container)
    addInterceptorResponse(axiosInstance, container)
    return axiosInstance
}

const addInterceptorRequest = (_axios: Axios, container: IOCServices) => {
    _axios.interceptors.request.use(
        (req) => {
            const store = container.store
            store.dispatch(startLoading())

            return req
        },
        (error) => {
            const store = container.store
            store.dispatch(stopLoading())
            return Promise.reject(error)
        },
    )
}

const addInterceptorResponse = (_axios: Axios, container: IOCServices) => {
    // Add a response interceptor
    _axios.interceptors.response.use(
        (response) => {
            const store = container.store
            const isLoading = store.getState().common.loading > 0
            if (isLoading) {
                store.dispatch(stopLoading())
            }
            return response
        },
        (error) => {
            const store = container.store
            store.dispatch(stopLoading())
            return Promise.reject(error)
        },
    )
}

const rest = (options: RestOptionsType) => {
    return axios.create({
        baseURL: options?.baseURL,
    })
}

export default restIdentityProvider
