import { Formik, FormikConfig, FormikProps } from 'formik'
import { appFormikBlur, appFormikChange, EventCallback } from '../utilities'

function BFormik<T extends object>(props: BFormikProps<T>) {
    return (
        <Formik {...props}>
            {({ handleBlur, handleChange, ...rest }) => {
                const handleCustomBlur = appFormikBlur<T>(props.blurCallback)
                const handleCustomChange = appFormikChange<T>(
                    props.changeCallback,
                )
                return props.element({
                    ...rest,
                    handleBlur: props.blurCallback
                        ? handleCustomBlur(
                              rest.values,
                              rest.setFieldValue,
                              rest.setFieldError,
                          )
                        : handleBlur,
                    handleChange: props.changeCallback
                        ? handleCustomChange(
                              rest.values,
                              rest.setFieldValue,
                              rest.setFieldError,
                          )
                        : handleChange,
                })
            }}
        </Formik>
    )
}

export default BFormik

interface BFormikProps<P> extends FormikConfig<P> {
    element: (params: FormikProps<P>) => JSX.Element
    changeCallback?: EventCallback<P>
    blurCallback?: EventCallback<P>
}
